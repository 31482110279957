import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData } from "../../config/axiosFunctions";

// get claim by claim number
export const getClaimReviewAction = createAsyncThunk(
  "getClaimReview",
  async () => {
    try {
      const response = await getData(`${path}/reviewClaim`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error?.response);
    }
  }
);
export const postClaimReviewAction = createAsyncThunk(
  "postClaimReview",
  async (data) => {
    try {
      const response = await postData(`${path}/reviewClaim`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error?.response);
    }
  }
);
