import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack,Typography } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import ICDProcedureCodeInfo from "../icd-procedures/ICDProcedureCodesInfo";
import { handleCancel } from "../../../utils/customFunctions";
import { icdProcedureInitVals, icdProcedureValues } from "../../../utils/formikInitValues";
import { newIcdProcedureAction } from "../../../features/actions/icdProcedureAction";
import { icdProcedureSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";


const NewICDProcedureCodes = () => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: icdProcedureInitVals,
    validationSchema : icdProcedureSchema,
    onSubmit: (values, action) => {
      console.log(values, "checkicdProcedureVals");
      dispatch(newIcdProcedureAction([values]));
      // action.resetForm();
      navigate("/icd-procedures");
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/icd-procedures")
  };
  return (
   <>
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      {/* <MainHeading mainheading={"New ICD Procedures Code"} /> */}

      <form onSubmit={formik.handleSubmit}>
      <Stack marginTop="15px" direction="row" alignItems="center" gap={2}>
        <CustomButton
          type="submit"
          isBlue={true}
          isSave={true}
        >
          Save
        </CustomButton>
        {/* <CustomButton isBlue={false} isCancel={true}  
        handleClick={() => {
                handleCancel(
                  // formik,
                   "/icd-procedures", navigate);
              }}>
          Cancel
        </CustomButton> */}
        <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
      </Stack>
        <ICDProcedureCodeInfo formik={formik}/>
        </form>
    </Box>
    <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
 isCancel={true}
/>

</>
  );
};

export default NewICDProcedureCodes;
