import React from "react";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import { Grid, Box, Stack } from "@mui/material";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { useState, useEffect } from "react";
import { getData, postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import { useFormik } from "formik";
import { DiagnosisCodeMasterList } from "./DiagnosisCodeMasterList";

const SearchDiagnosisCodeMasterCom = ({ handleClose }) => {
  const [codeType, setCodeType] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMultipleDiagnosisTable, setopenMultipleDiagnosisTable] =
    useState(false);
  const [multiDiagnosis, setMultiDiagnosis] = useState([]);
  const [filteredDiagnosis, setFilteredDiagnosis] = useState([]);
  // search list
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ans = multiDiagnosis.filter((value) => {
      return value.diagnosisCode.toLowerCase().includes(search) ;
    });
    if (ans.length === 0) {
      // return multiDiagnosis;
      setFilteredDiagnosis(multiDiagnosis);
    } else {
      setFilteredDiagnosis(ans);
     
    }
    
  };

  const [page, setPage] = useState(1);
  const formik = useFormik({
    initialValues: {
      codeId: 1,
      searchValue: "",
      orderby: 1,
    },
    onSubmit: async (val, act) => {
      console.log(val);


      try {
        setLoading(true);
        const response = await postData(
          `${path}/masterDiagnosisCodes/SearchMasterDiagnosisCodeByCategory?page=${page}&pageSize=20`,

          val
        );
        console.log(response);

        let rowsData =
          response.result.masterDiagnosisCode.length > 0
            ? response.result.masterDiagnosisCode.map((val, ind) => {
                return {
                  id: val?.masterDiagnosisCodesId,
                  ...val,
                };
              })
            : [];
        setMultiDiagnosis(rowsData);
        setopenMultipleDiagnosisTable(true);
        setLoading(false);
        setPage(response.result.masterDiagnosisCode.page || 1)
      } catch (error) {
        console.log("error", error);
      }
    },
  });
  // console.log(rowsData);

  const fetchData = async (endPoint, setState) => {
    try {
      let response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error", error);
    }
  };

  const dataSource = {
    icdCodeType: "/CTICDcodetype",
    orderByList: "/ct-masterListOrderBy",
  };

  useEffect(() => {
    fetchData(dataSource.icdCodeType, setCodeType);
    fetchData(dataSource.orderByList, setOrderBy);
  }, []);
  return (
    <>
      <CustomModal
        open={openMultipleDiagnosisTable}
        handleClose={() => setopenMultipleDiagnosisTable(false)}
        heading={"Diagnosis Master List"}
      >
        <Grid item md={12}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box
              component={"form"}
              sx={{
                display: "flex",
                alignItems: "end",
                margin: "10px",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <CustomField
                type="text"
                size="small"
                label={"Search By Code Description"}
                // value={search}
                name="searchTerm"
                handleChange={(e) => {
                  handleChangeSearch(e);
                }}
              />
              <CustomButton
                type="submit"
                isBlue={true}
                padding="5px 30px"
                margin={"0 10px 0"}
                isSearch={true}
              >
                Search
              </CustomButton>
            </Box>
          </Stack>
        </Grid>
        <DiagnosisCodeMasterList
          loading={loading}
          filteredDiagnosis={filteredDiagnosis}
          search={search}
          multiDiagnosis={multiDiagnosis}
          setopenMultipleDiagnosisTable={setopenMultipleDiagnosisTable}
          handleClose={handleClose}
        />
      </CustomModal>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="end" padding="18px">
          <Grid item md={12}>
            <CustomSelectBox2
              value={formik.values.codeId}
              showDefaultOpt={true}
              name="codeId"
              dropdownOptions={codeType?.map((opt) => ({
                value: opt?.cticDcode,
                id: opt?.cticDcodetypeId,
              }))}
              label="Select ICD Version to Display"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
          </Grid>
          <Grid item md={12}>
            <CustomSelectBox2
              value={formik.values.orderby}
              showDefaultOpt={true}
              name="orderby"
              dropdownOptions={orderBy?.map((opt) => ({
                value: opt?.masterListOrderByName,
                id: opt?.masterListOrderById,
              }))}
              label="Order Results By"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
          </Grid>
          <Grid item md={12}>
            <CustomField
              type="text"
              label="Enter Code to Search For"
              value={formik.values.searchValue}
              name="searchValue"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Grid>
          <hr style={{ color: "grey" }} />
          <Grid item lg={3}>
            <CustomButton
              type="submit"
              isBlue={true}
              isSearch={true}
              //   handleClick={formik.handleSubmit}
            >
              Search
            </CustomButton>
          </Grid>
        </Grid>
      </form>
      {/* modals */}
      {/* <CustomModal
        open={openMasterMod}
        handleClose={() => setOpenMasterMod(false)}
        showAddButton={true}
        height={"20%"}
      >
        <MasterTable masterListData={masterListData} />
      </CustomModal> */}
    </>
  );
};

export default SearchDiagnosisCodeMasterCom;
