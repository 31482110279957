import { Box, Stack,Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import {
  getAdjustmentAction,
  getAdjustmentByIdAction,
  updateAdjustmentAction,
} from "../../../features/actions/adjustmentAction";
import { adjustmentInitVals } from "../../../utils/formikInitValues";
import AdjustmentInfo from "./AdjustmentInfo";
import { adjustmentSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";
import {getSingleAdjustmentAction} from "../../../features/actions/adjustmentAction"
const UpdateAdjustment = () => {
  let flage = false
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const { getAdjustmentCodes, loading } = useSelector(
  //   (state) => state.adjustments
  // );

  // const findAdjustment = getAdjustmentCodes?.result?.adjustmentCodes.find(
  //   (adjustment) => adjustment.adjustmentCodeId === Number(id)
  // );
  // const formik = useFormik({
  //   initialValues: findAdjustment || adjustmentInitVals,
  //   validationSchema : adjustmentSchema,
  //   onSubmit: (values) => {
  //     console.log(values, "checkUpdatedVals");
  //     try {
  //       dispatch(
  //         updateAdjustmentAction({
  //           adjustmentCodeId: findAdjustment?.adjustmentCodeId,
  //           ...values,
  //         })
  //       );
  //     } catch (err) {
  //       console.log(err, "create adjustment error");
  //       throw err;
  //     }
  //     navigate("/adjustment");
  //   },
  // });

  const {adjustmentByIdData } = useSelector((state) => state.adjustments);
  console.log("adjustmentByIdData",adjustmentByIdData)
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   useEffect(() => {
//     dispatch(getAdjustmentByIdAction(id));
//   }, [dispatch, id]);

//   const findAdjustment = adjustmentByIdData?.result;
  
//   console.log("findAdjustment",findAdjustment);
  const { getSingleAdjustmentCodes, loading } = useSelector(
    (state) => state.adjustments
  );
  useEffect(()=>{
if(!flage){
flage = true
  dispatch(getSingleAdjustmentAction(id))
}
  },[id , dispatch])
  // console.log(getSingleAdjustmentCodes);
  // const findAdjustment = getAdjustmentCodes.result.adjustmentCodes.find(
  //   (adjustment) => adjustment.adjustmentCodeId === Number(id)
  // );

// useEffect(()=>{
//   dispatch(getSingleAdjustmentAction(id))
// },[])

console.log(getSingleAdjustmentCodes?.result?.adjustmentCodeId);
// console.log(adjustmentInitVals);
  const formik = useFormik({
//     initialValues: findAdjustment || adjustmentInitVals,
//     validationSchema: adjustmentSchema,
//     enableReinitialize: true,
//     onSubmit: (values,action) => {
//       console.log("Submitting form with values:", values); 
//       dispatch(
//         updateAdjustmentAction({
//           adjustmentCodeId: findAdjustment?.adjustmentCodeId,
//           ...values,
//         })
//       ).then((response) => {
//         console.log("Update response:", response);
//         if (response.meta.requestStatus === "fulfilled") {
//           navigate("/adjustment");
//         } else {
//           console.error("Update failed:", response.error.message);
//         }
//       });

    initialValues:  getSingleAdjustmentCodes.result || adjustmentInitVals,
    enableReinitialize: true,
    validationSchema : adjustmentSchema,
    enableReinitialize: true,
    onSubmit:  (values) => {
      console.log(values, "checkUpdatedVals");
      try {
        dispatch(
          updateAdjustmentAction({
            adjustmentCodeId: getSingleAdjustmentCodes?.result?.adjustmentCodeId,
            ...values,
          })
        );
      } catch (err) {
        console.log(err, "create adjustment error");
        throw err;
      }
      navigate("/adjustment");
    },
  });
  return (
    <>
          <AdjustmentInfo formik={formik} />
    
    </>
  );
};

export default UpdateAdjustment;
