import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Box,
  FormControlLabel,
  Typography,
  Radio,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomDialog from "../../../components/CustomDialog";
import CustomField from "../../../components/CustomField";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomPreview from "../../../layout/CustomPreview";
import CustomModal from "../../../components/CustomModal";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { useSelector } from "react-redux";
import { providerCols } from "../../../utils/gridCols";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";
import { useTheme } from "@emotion/react";

const columns = [
  {
    field: "creditCodeId",
    headerName: "Code",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "creditCode",
    headerName: "Credit Code",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const CreditAccount = ({ formik }) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [openCreditCode, setOpenCreditCode] = useState(false);
  const [creditCodeList, setCreditCodeList] = useState([]);
  const [ProviderModal, setProviderModal] = useState(false);
  // const insSelectBox = [{ id: "1", name: "UTAH MEDICAID (#125748)" }];
  // const officeLocation = [
  //   { id: "1", location: "BRUCE L.FELDMAN,MD PO BOX 290725" },
  // ];

  const fetchCreditList = async () => {
    try {
      const { result } = await getData(`${path}/ct-creditCode`);
      let list = result.map((item) => {
        return { id: item.creditCodeId, ...item };
      });
      setCreditCodeList(list);
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    fetchCreditList();
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} padding="18px">
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                label={
                  <Typography fontSize={"1.5rem"} fontWeight="600">
                    Patient
                  </Typography>
                }
                control={
                  <Radio
                    value="claim"
                    variant="soft"
                    name="claim"
                    checked={formik.values.paymentByTypeId === 1}
                    onChange={() => {
                      formik.setFieldValue("paymentByTypeId", 1);
                    }}
                    style={{
                      color: theme.palette.radioButtons.primary,
                    }}
                  />
                }
              />
              <FormControlLabel
                label={
                  <Typography fontSize={"1.5rem"} fontWeight="600">
                    Insurance
                  </Typography>
                }
                control={
                  <Radio
                    value="payer"
                    variant="soft"
                    name="payer"
                    checked={formik.values.paymentByTypeId === 2}
                    onChange={() => {
                      formik.setFieldValue("paymentByTypeId", 2);
                    }}
                    style={{
                      color: theme.palette.radioButtons.primary,
                    }}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <CustomSearchField
              label="Credit Code"
              fieldVal={formik.values.codeType}
              error={formik.errors}
              handleModalOpen={() => setOpenCreditCode(true)}
              isRequired={true}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="codeType"
              formik={formik}
              resetField={{
                codeType: "",
              }}
            />

            <CustomField
              value={formik.values.amount}
              name="amount"
              handleChange={(e) => {
                formik.setFieldValue("amount", e.target.value);
                formik.setFieldValue("remaining", e.target.value);
              }}
              handleBlur={formik.handleBlur}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              label="Amount"
              type="number"
              isDecimal={true}
            />
            <CustomDatePicker
              formik={formik}
              name="receivedDate"
              dateValue={formik.values.receivedDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
              dateLabel="Received"
            />
            <CustomField
              value={formik.values.memo}
              name="memo"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              label="Memo"
              type="text"
            />
            <CustomSearchField
              label="Provider"
              fieldVal={formik.values.providerName}
              error={formik.errors}
              isRequired={true}
              name="providerName"
              handleModalOpen={() => setProviderModal(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              resetField={{
                providerName: "",
              }}
            />
            <CustomSearchField
              label="Office Location"
              fieldVal={formik.values.practiceAddress}
              // error={formik.errors}
              // isRequired={true}
              disable={true}
              name="practiceAddress"
              handleModalOpen={() => setProviderModal(true)}
              isRequired={true}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              resetField={{
                practiceAddress: "",
              }}
              
            />
            {/* <CustomSelectBox2
              showDefaultOpt={true}
              label="Office Location"
              disable={true}
              formik={formik}
              value={formik.values.practiceAddress}
              name="practiceId"
              dropdownOptions={officeLocation.map((opt) => ({
                value: opt.location,
                id: opt.id,
              }))}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            /> */}
          </Grid>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CustomButton type={"submit"} isBlue={true}>
                Done
              </CustomButton>
              {/* <CustomButton
                isCancel={true}
                margin={1.5}
                handleClick={() => formik.resetForm()}
              >
                Cancel
              </CustomButton> */}
               <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
            </Stack>
          </Grid>

          <CustomModal
            heading={"Credit Code"}
            open={openCreditCode}
            handleClose={() => setOpenCreditCode(false)}
          >
            <CustomDataGrid
              columns={columns}
              rows={creditCodeList}
              handleCellClick={(e) => {
                formik.setFieldValue("creditCodeId", e?.creditCodeId);
                formik.setFieldValue("codeType", e?.creditCodeType);
                formik.setFieldValue("memo", e?.creditCodeType);
                setOpenCreditCode(false);
              }}
            />
          </CustomModal>
          <CustomModal
            open={ProviderModal}
            handleClose={() => setProviderModal(false)}
          >
            <CustomPreview
              rows={
                getProviders?.result &&
                getProviders?.result?.providers.length > 0
                  ? getProviders.result?.providers.map((el) => ({
                      id: el.providerId,
                      ...el,
                    }))
                  : []
              }
              paginationDetail={getProviders}
              columns={providerCols}
              loading={providerLoading}
              handleGetAll={getProviderAction}
              isModal={true}
              handleBySearch={getProviderBySearchTermAction}
              searchLabel="Search for provider by name, sequence #"
              handleCell={(e) => {
                formik.setFieldValue("practiceId", e?.practiceId);
                formik.setFieldValue("practiceAddress", e?.practiceAddress);
                formik.setFieldValue("providerId", e?.providerId);
                formik.setFieldValue(
                  "providerName",
                  `${e?.providerFirstName} ${e?.providerLastName} (${e?.providerSequenceNo})`
                );
                setProviderModal(false);
              }}
            />
          </CustomModal>
        </Grid>
        <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
     isCancel={true}
    />

      </form>
    </>
  );
};


