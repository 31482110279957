import { Box, Stack, Typography,useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect} from "react";
import CustomButton from "../../../components/CustomButton";

const ArSearch = ({
  searchedClaimData,
  selectedRow,
  setSelectedRow,
  handleClose,
  isDefaultSelection,
  setIsDefaultSelection,
  setSelectedClaimsLength,
  patientName
}) => {
  const rows = searchedClaimData.map((item) => ({
    id: item.claimChargesId,
    balance: item.balance || 0,
    ...item
  }));
  const reducedCellHeight = 35;
  const theme=useTheme()

  const columns = [
    {
      field: "claimNo",
      headerName: "Claim #",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "dateOfService",
      headerName: "DOS",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "procedureCode",
      headerName: "Procedure",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "claimStatus",
      headerName: "Status",
      minWidth: 100,
      flex: 3,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];
  //   handle row selection change
  const handleSelectRowChange = (rowIds) => {
    if (isDefaultSelection) {
      setIsDefaultSelection(false);
    }
    setSelectedRow(rowIds);
  };

  const handleDone = () => {
    // setSelectedRow(selectedRow);
    handleClose();
  };

  // initially all rows are selected
  useEffect(() => {
    if (isDefaultSelection) {
      const allRowIds = searchedClaimData.map((item) => item.claimChargesId);
      setSelectedRow(allRowIds);
    }
  }, [isDefaultSelection, searchedClaimData, setSelectedRow]);

  console.log(searchedClaimData, "searchedClaimData")
  return (
    <>
      <Box>
        <Typography
          component="h2"
          sx={{
            margin: "13px 8px",
            fontWeight: "600",
            borderBottom: "0.5px solid grey",
          }}
        >
          Charges for {patientName} (#{searchedClaimData[0].accountNo})
        </Typography>
        <DataGrid
          rows={rows}
          
          columns={columns}
          autoHeight
          rowHeight={reducedCellHeight}
          checkboxSelection
          selectionModel={selectedRow}
          onSelectionModelChange={handleSelectRowChange}
          disableSelectionOnClick
          sx={{
            // background: "#fff",
            "& .header-bg": {
              background: theme.palette.arCharges.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          components={{
            NoRowsOverlay: () => (
              <h3
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {rows.length === 0 && "No Data Is Added"}
              </h3>
            ),
          }}
        />
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            margin: "12px",
          }}
        >
          <CustomButton isBlue={true} handleClick={handleDone}>
            Done
          </CustomButton>
        </Stack>
      </Box>
    </>
  );
};

export default ArSearch;
