import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  Stack,
  Paper,
  FormGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import MainHeading from "../../../components/MainHeading";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomAccordion from "../../../components/CustomAccordion";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import SearchNpi from "../practices/npi/SearchNpi";
import CustomPreview from "../../../layout/CustomPreview";
import CustomAddress from "../../../components/CustomAddress";
import CustomButton from "../../../components/CustomButton";
import CustomDialog from "../../../components/CustomDialog";
import { posCodesCols } from "../../../utils/gridCols";
import { getAllPosCodesActios } from "../../../features/actions/posCodeAction";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/CheckBox";
import { Done } from "@mui/icons-material";
import { handleIsActive } from "../../../utils/customFunctions";

const FacilityInfo = ({ formik }) => {
  const navigate = useNavigate();
  const [practicesOpt, setPracticesOpt] = useState([]);
  const [openTaxonomyListModal, setOpenTaxonomyListModal] = useState(false);
  const [openPosModal, setOpenPosModal] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [npiResultData, setNpiResultData] = useState([]);
  const [openNpiPaper, setOpenNpiPaper] = useState(false);

  const { getAllPos } = useSelector((state) => state.posCodes);

  const theme = useTheme();

  // taxonomy
  const handleSelectTaxonomy = (taxonomyCode) => {
    formik.setFieldValue("taxonomySpecialty", taxonomyCode);
  };
  // handle npi
  const handleSelectNpi = (npiNum) => {
    formik.setFieldValue("facilityNpiId", npiNum.id);
    formik.setFieldValue("facilityNpiNo", npiNum.npiNo);
  };
  // handle pos code
  const handlePosCodes = (val) => {
    console.log(val, ":posCode");
    formik.setFieldValue("posCodeId", val.id);
    formik.setFieldValue("posCode", val.posCode);
    setOpenPosModal(false);
  };

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/facility");
  };

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const response = await getData(`${path}/practice/getPracticeList`);
        if (response.result.length > 0) {
          setPracticesOpt(response.result);
        }
      } catch (e) {
        console.log(e, ": Error in fetching practices list!");
        throw e;
      }
    };
    fetchPractices();
  }, []);

  // npi
  const handleNpiChange = async (e) => {
    const npiValue = e.target.value;
    formik.setFieldValue("facilityNpiNo", npiValue);
    if (formik.values.facilityNpiNo?.length >= 4) {
      try {
        const response = await getData(
          `${path}/npi/searchNPI?npiNo=${npiValue}`
        );
        if (response.result.length > 0) {
          setNpiResultData(response.result);
          setOpenNpiPaper(true);
        }
      } catch (e) {
        console.log(e, ": Error in feting NPI");
        throw e;
      }
    } else {
      setOpenNpiPaper(false);
    }
  };
  return (
    <>
      {/* taxonomy modal */}
      <CustomModal
        open={openTaxonomyListModal}
        handleClose={() => setOpenTaxonomyListModal(false)}
      >
        <TaxonomyCategories
          handleSelectTaxonomy={handleSelectTaxonomy}
          handleClose={() => setOpenTaxonomyListModal(false)}
        />
      </CustomModal>
      {/* npi modal */}
      <CustomModal
        open={searchNpiModal}
        handleClose={() => setSearchNpiModal(false)}
        heading={"NPI Registry"}
        width={"60%"}
      >
        <SearchNpi
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setSearchNpiModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      {/* POS modal */}
      <CustomModal
        open={openPosModal}
        handleClose={() => setOpenPosModal(false)}
        heading={"Place Of Service"}
      >
        <CustomPreview
          isModal={true}
          rows={
            getAllPos?.result?.data
              ? getAllPos?.result?.data?.map((el) => ({
                  id: el?.posCodeId,
                  ...el,
                }))
              : []
          }
          columns={posCodesCols}
          handleGetAll={getAllPosCodesActios}
          handleCell={handlePosCodes}
          // searchLabel="Search by Pos Code"
        />
      </CustomModal>

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
          }}
        >
          <Stack direction={"row"} gap={"15px"} alignItems={"center"}>
            <CustomButton
              type="submit"
              isBlue={true}
              isSave={!formik.values.facilityId && true}
              isEdit={formik.values.facilityId && true}
            >
              {formik.values.facilityId ? "Update" : "Save"}
            </CustomButton>
            <CustomButton isCancel={true} handleClick={handleCancel}>
              Cancel
            </CustomButton>
          </Stack>

          {formik.values.facilityId && (
            <Stack>
              <CheckBox
                CheckedValue={!formik.values.isActive}
                handleChange={() =>
                  // formik.setFieldValue("isActive", !formik.values.isActive)
                  handleIsActive(formik)
                }
                label="Make this facility inactive"
              />
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            background: theme.palette.accordiansBodyBackground.primary,
            padding: "15px",
            margin: "15px",
            border: "1px solid #D3D3D3",
            borderRadius: "4px",
          }}
        >
          <Box
            display="grid"
            gap="10px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              type="text"
              label="Name"
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
              value={formik.values.facilityName}
              name="facilityName"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
            <FormGroup
              sx={{
                position: "relative",
              }}
            >
              <CustomSearchField
                isRequired={true}
                fieldVal={formik.values?.facilityNpiNo}
                name="facilityNpiNo"
                handleChange={handleNpiChange}
                handleBlur={formik.handleBlur}
                handleModalOpen={() => setSearchNpiModal(true)}
                label="NPI"
                formik={formik}
                resetField={{
                  facilityNpiNo: null,
                }}
                isTypeAble={true}
              />
              {openNpiPaper && (
                <Paper
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    zIndex: 111,
                    boxShadow: 3,
                    width: "100%",
                  }}
                >
                  {npiResultData?.map((item) => (
                    <Stack margin="5px" width="100%" key={item?.npiId}>
                      <CustomButton
                        isBlue={false}
                        handleClick={() => {
                          formik.setFieldValue("facilityNpiNo", item?.npiNo);
                          formik.setFieldValue("facilityNpiId", item?.npiId);
                          setOpenNpiPaper(false);
                        }}
                      >
                        <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                        {item?.npiNo}
                      </CustomButton>
                    </Stack>
                  ))}
                </Paper>
              )}
            </FormGroup>
            <CustomSearchField
              label="Taxonomy Speciality"
              fieldVal={formik.values.taxonomySpecialty}
              name="taxonomySpecialty"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              handleModalOpen={() => setOpenTaxonomyListModal(true)}
              formik={formik}
              resetField={{
                taxonomySpecialty: "",
              }}
            />
          </Box>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              disable={true}
              type="text"
              label="Sequence #"
              value={formik.values.sequenceNo || null}
            />
            <CustomField
              type="text"
              label="Reference #"
              value={formik.values.referenceNo}
              name="referenceNo"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
            {/* <CustomSelectBox2
              label="Practice"
              value={formik.values.practiceId}
              name="practiceId"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              dropdownOptions={practicesOpt?.map((opt) => ({
                value: opt.practiceName,
                id: opt.practiceId,
              }))}
            /> */}
          </Box>
        </Box>
        <Box sx={{ margin: "15px" }}>
          <CustomAccordion heading="Contact Information" maxHeight="300px">
            <Box
              display="grid"
              gap="10px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="text"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                label="Address"
                value={formik.values.facilityAddress}
                name="facilityAddress"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              <CustomField
                type="text"
                label="Phone"
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                value={formik.values.phoneNumber}
                name="phoneNumber"
                mask="(999) 999-9999"
              />
              <CustomField
                type="text"
                label="Fax"
                value={formik.values.faxNumber}
                name="faxNumber"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                mask="(999) 999-9999"
              />
              <CustomField
                type="text"
                label="Email"
                value={formik.values.email}
                name="email"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </Box>
            <Box
              display="grid"
              gap="10px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomAddress
                formik={formik}
                fieldValues={{
                  countryId: formik.values.facilityCountryId,
                  country: formik.values.facilityCountry,
                  countryName: "facilityCountry",
                  stateId: "facilityStateId",
                  stateName: "facilityState",
                  state: formik.values.facilityState,
                  cityId: "facilityCityId",
                  cityName: "facilityCity",
                  city: formik.values.facilityCity,
                  zipCode: formik.values.facilityZipCode,
                  zipCodeId: "facilityZipCodeId",
                  zipName: "facilityZipCode",
                }}
                isRequired={true}
              />
            </Box>
            {/* ID Numbers */}
            <div>
              <Typography
                variant="h2"
                component="h3"
                sx={{
                  // bgcolor: "#DBE8FC",
                  background: theme.palette.accordInnerHeading.primary,
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                ID Numbers
              </Typography>
              <Box
                display="grid"
                gap="10px"
                margin={"20px 0"}
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(4, minmax(0, 1fr))",
                  },
                }}
              >
                <CustomField
                  type="number"
                  label="Tax ID"
                  value={formik.values.taxId}
                  name="taxId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="CLIA ID"
                  value={formik.values.cliaId}
                  name="cliaId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="Location Provider ID"
                  value={formik.values.locationProviderId}
                  name="locationProviderId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="Site ID"
                  value={formik.values.siteId}
                  name="siteId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />

                <CustomField
                  type="text"
                  label="BlueCross ID"
                  value={formik.values.blueCrossId}
                  name="blueCrossId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="BlueShield ID"
                  value={formik.values.blueShieldId}
                  name="blueShieldId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="Medicare ID"
                  value={formik.values.medicareId}
                  name="medicareId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
                <CustomField
                  type="text"
                  label="Medicaid ID"
                  value={formik.values.medicaidId}
                  name="medicaidId"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Box>
              <Box
                display="grid"
                gap="30px"
                margin={"20px 0"}
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(1, minmax(0, 1fr))",
                  },
                }}
              >
                <CustomField
                  type="text"
                  label="Locator Code"
                  value={formik.values.locatorCode}
                  name="locatorCode"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Box>
            </div>

            {/* claim default */}
            <div>
              <Typography
                variant="h2"
                component="h3"
                sx={{
                  // bgcolor: "#DBE8FC",
                  background: theme.palette.accordInnerHeading.primary,
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                Claim Defaults
              </Typography>
            </div>

            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 1fr))",
                },
              }}
            >
              <CustomSearchField
                label="Place of Service"
                fieldVal={formik.values.posCode}
                name="posCode"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                handleModalOpen={() => setOpenPosModal(true)}
                formik={formik}
                resetField={{
                  posCode: null,
                }}
              />
            </Box>
          </CustomAccordion>
        </Box>
      </form>
    </>
  );
};

export default FacilityInfo;
