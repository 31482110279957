// import {
//   Alert,
//   Box,
//   Button,
//   FormControlLabel,
//   Grid,
//   Radio,
//   RadioGroup,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import axios from "axios";
// import * as yup from "yup";
// import { useFormik } from "formik";
// import React, { useEffect } from "react";
// import path from "../../../../config/apiUrl";
// import { useState } from "react";
// import CustomModal from "../../../../components/CustomModal";
// import SearchedNpiData from "./SearchedNpiData";
// import CustomField from "../../../../components/CustomField";
// import CustomSearchField from '../../../../components/CustomSearchField'
// import TaxonomyCategories from "../taxonomy/TaxonomyCategories";
// import CustomButton from "../../../../components/CustomButton";
// import { getData } from "../../../../config/axiosFunctions";

// const SearchNpi = ({ setSearchNpiModal, handleSelectNpi }) => {
//   const [taxonomyListModal, setTaxonomyListModal] = useState(false);
//   const [taxonomyName, setTaxonomyName] = useState("");
//   const [searchedData, setSearchedData] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const [emptyField, setEmptyField] = useState(false);
//   const [openSearchDataModal, setOpenSearchDataModal] = useState(false);
//   const [providerTypeOpts, setProviderTypeOpts] = useState([]);
//   const [providerType, setProviderType] = useState(1);
//   const initialValues = {
//     // first_name: "",
//     // last_name: "",
//     // organization_name: "",
//     // state: "",
//     // city: "",
//     // state: "",
//     // taxonomy: "",
//     // postal_code: "",
//     firstName: "",
//     lastName: "",
//     organizationName: "",
//     cityName: "",
//     stateName: "",
//     taxonomyCode: "",
//   };
//   const requiredMessageKey = "at-least-one-field";
//   // Custom validation function
//   const validateAtLeastOneField = (values) => {
//     const hasValue = Object.values(values).some(
//       (value) => value != null && value.trim() !== ""
//     );
//     if (!hasValue) {
//       return { [requiredMessageKey]: "At least one field must be filled" };
//     }
//     return null;
//   };

//   const {
//     values,
//     handleBlur,
//     handleSubmit,
// setFieldValue, 
//     handleChange,
//     submitCount,
//     errors,
//   } = useFormik({
//     initialValues: initialValues,
//     validate: validateAtLeastOneField,
//     validateOnChange:false,
//     validateOnBlur:false,
//     onSubmit: async (values) => {
//       const queryParams = new URLSearchParams();
//       Object.keys(values).forEach((key) => {
//         if (values[key].trim() !== "") {
//           queryParams.append(key, values[key].trim());
//         }
//       });
//       // const url = `https://npiregistry.cms.hhs.gov/api/?version=2.1&${queryParams.toString()}`;
//       const url = `http://192.168.3.73:86/api/npi/search?${queryParams.toString()}`;
//       try {
//         const response = await axios.get(url);
//         if (response) {
//           console.log(response, "response search");
//           const getSearchData = response.data;
//           setSearchedData(getSearchData.result);
//           setOpenSearchDataModal(true);
        
//         }

       
//       } catch (error) {
//         throw new Error(error);
//       }
//     },
//   });

//   useEffect(() => {
//     const fetchTypes = async () => {
//       try {
//         const response = await getData(`${path}/ct-provideType`);
//         if (response) {
//           setProviderTypeOpts(response.result);
//         }
//       } catch (err) {
//         console.log(err, "Error in fetching provider types");
//       }
//     };

//     fetchTypes();
//   }, [setProviderTypeOpts]);
//   console.log(errors, "checkErrors");

//   useEffect(() => {
//     if (submitCount > 0 && errors[requiredMessageKey]) {
//       setShowAlert(true);
//       const timer = setTimeout(() => {
//         setShowAlert(false);
//       }, 3000);
//       return () => clearTimeout(timer);
//     }
//   }, [submitCount, errors]);
//   const handleSelectTaxonomy = (taxonomyCode) => {
//     console.log(taxonomyCode, "taxCode");
//     setFieldValue("taxonomySpecialty", taxonomyCode);
//   };
//   return (
//     <>
//         <Grid padding={"20px"} container spacing={2} component={"form"} onSubmit={handleSubmit}>
//           {showAlert && errors[requiredMessageKey] && (
//             <Grid item md={12}>
//               <Alert severity="error" sx={{
//                 fontSize:"1.4rem"
//               }}>{errors[requiredMessageKey]}</Alert>
//             </Grid>
//           )}
//           <Grid item md={12}>
//             <RadioGroup row>
//               {providerTypeOpts.map((option) => (
//                 <FormControlLabel
//                   checked={providerType === option?.providerTypeId}
//                   control={
//                     <Radio
//                       onChange={() => setProviderType(option?.providerTypeId)}
//                     />
//                   }
//                   label={option.providerType}
//                 />
//               ))}
//             </RadioGroup>
//           </Grid>

//           {providerType === 1 ? (
//             <>
//               <Grid item md={4}>
//                 <CustomField
//                   type="text"
//                   label="First Name"
//                   handleBlur={handleBlur}
//                   handleChange={handleChange}
//                   value={values.firstName}
//                   name="firstName"
//                 />
//               </Grid>
//               <Grid item md={4}>
//                 <CustomField
//                   type="text"
//                   label="Last Name"
//                   handleBlur={handleBlur}
//                   handleChange={handleChange}
//                   value={values.lastName}
//                   name="lastName"
//                 />
//               </Grid>
//             </>
//           ) : (
//             <Grid item md={8}>
//               <CustomField
//                 type="text"
//                 label="Organization Name"
//                 handleBlur={handleBlur}
//                 handleChange={handleChange}
//                 value={values.organizationName}
//                 name="organizationName"
//               />
//             </Grid>
//           )}

//           <Grid item md={4}>
//             {/* <CustomField
//               type="text"
//               label="Taxonomy"
//               handleBlur={handleBlur}
//               handleChange={handleChange}
//               value={values.taxonomyCode}
//               name="taxonomyCode"
//             /> */}
//              <CustomSearchField
//               fieldVal={values.taxonomyCode}
//               // resetField={{
//               //   taxonomySpecialty: "",
//               // }}
//               name="taxonomyCode"
//               handleChange={handleChange}
//               handleBlur={handleBlur}
//               label="Taxonomy"
//               handleModalOpen={() => setTaxonomyListModal(true)}
//             />
//           </Grid>
//           <Grid item md={4}>
//             <CustomField
//               type="text"
//               label="City"
//               handleBlur={handleBlur}
//               handleChange={handleChange}
//               value={values.cityName}
//               name="cityName"
//             />
//           </Grid>
//           <Grid item md={4}>
//             <CustomField
//               type="text"
//               label="State"
//               handleBlur={handleBlur}
//               handleChange={handleChange}
//               value={values.stateName}
//               name="stateName"
//             />
//           </Grid>
//           <Grid item md={4}>
//             <CustomField
//               type="text"
//               label="Zipcode"
//               handleBlur={handleBlur}
//               handleChange={handleChange}
//               value={values.postal_code}
//               name="postal_code"
//             />
//           </Grid>

//           <Grid item md={12} textAlign={"right"}>
//             <CustomButton
//               variant="contained"
//               isBlue={true}
//               isSearch={true}
//               type="submit"
//               color="primary"
//             >
//               Search
//             </CustomButton>
//           </Grid>
//         </Grid>

//       <CustomModal
//         open={openSearchDataModal}
//         handleClose={() => setOpenSearchDataModal(false)}
//       >
//         <SearchedNpiData
//           searchedData={searchedData}
//           handleSelectNpi={handleSelectNpi}
//           handleClose={() => setOpenSearchDataModal(false)}
//           setSearchNpiModal={setSearchNpiModal}
//         />
//       </CustomModal>
//       <CustomModal
//         open={taxonomyListModal}
//         handleClose={() => setTaxonomyListModal(false)}
//         width={"50%"}
//       >
//         <TaxonomyCategories
//           handleSelectTaxonomy={handleSelectTaxonomy}
//           setTaxonomyName={setTaxonomyName}
//           handleClose={() => setTaxonomyListModal(false)}
//         />
//       </CustomModal>
//     </>
//   );
// };

// export default SearchNpi;




// import {
//   Alert,
//   Box,
//   Button,
//   FormControlLabel,
//   Grid,
//   Radio,
//   RadioGroup,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import axios from "axios";
// import * as yup from "yup";
// import { useFormik } from "formik";
// import React, { useEffect } from "react";
// import path from "../../../../config/apiUrl";
// import { useState } from "react";
// import CustomModal from "../../../../components/CustomModal";
// import SearchedNpiData from "./SearchedNpiData";
// import CustomField from "../../../../components/CustomField";
// import CustomSearchField from '../../../../components/CustomSearchField';
// import TaxonomyCategories from "../taxonomy/TaxonomyCategories";
// import CustomButton from "../../../../components/CustomButton";
// import { getData } from "../../../../config/axiosFunctions";

// const SearchNpi = ({ setSearchNpiModal, handleSelectNpi }) => {
//   const [taxonomyListModal, setTaxonomyListModal] = useState(false);
//   const [taxonomyName, setTaxonomyName] = useState("");
//   const [searchedData, setSearchedData] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const [openSearchDataModal, setOpenSearchDataModal] = useState(false);
//   const [providerTypeOpts, setProviderTypeOpts] = useState([]);
//   const [providerType, setProviderType] = useState(1);
  
//   const initialValues = {
//     firstName: "",
//     lastName: "",
//     orgName: "",
//     cityName: "",
//     stateName: "",
//     taxonomy: "",
//     postal_code: "",
//   };

//   const requiredMessageKey = "at-least-one-field";

//   // Custom validation function
//   const validateAtLeastOneField = (values) => {
//     const hasValue = Object.values(values).some(
//       (value) => value != null && value.trim() !== ""
//     );
//     if (!hasValue) {
//       return { [requiredMessageKey]: "At least one field must be filled" };
//     }
//     return null;
//   };


//   const {
//     values,
//     handleBlur,
//     handleSubmit,
//     handleChange,
//     setFieldValue,
//     submitCount,
//     errors,
//   } = useFormik({
//     initialValues: initialValues,
//     validate: validateAtLeastOneField,
//     validateOnChange: false,
//     validateOnBlur: false,
//     onSubmit: async (values) => {
//       console.log(values,"values")
//       const queryParams = new URLSearchParams();
//       Object.keys(values).forEach((key) => {
//         if (values[key].trim() !== "") {
//           queryParams.append(key, values[key].trim());
//         }
//       });
//       const url = `${path}/npi/search?${queryParams.toString()}`;
//       try {
//         const response = await axios.get(url,{
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         if (response) {
//           console.log(response, "response search");
//           const getSearchData = response.data;
//           setSearchedData(getSearchData.result);
//           setOpenSearchDataModal(true);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     },
//   });

//   useEffect(() => {
//     const fetchTypes = async () => {
//       try {
//         const response = await getData(`${path}/ct-provideType`);
//         if (response) {
//           setProviderTypeOpts(response.result);
//         }
//       } catch (err) {
//         console.log(err, "Error in fetching provider types");
//       }
//     };

//     fetchTypes();
//   }, []);

//   useEffect(() => {
//     if (submitCount > 0 && errors[requiredMessageKey]) {
//       setShowAlert(true);
//       const timer = setTimeout(() => {
//         setShowAlert(false);
//       }, 3000);
//       return () => clearTimeout(timer);
//     }
//   }, [submitCount, errors]);

//   const handleSelectTaxonomy = (taxonomy) => {
//     console.log(taxonomy, "taxonomy");
//     setFieldValue("taxonomy", taxonomy);
//   };

//   return (
//     <>
//       <Grid padding={"20px"} container spacing={2} component={"form"} onSubmit={handleSubmit}>
//         {showAlert && errors[requiredMessageKey] && (
//           <Grid item md={12}>
//             <Alert severity="error" sx={{ fontSize: "1.4rem" }}>
//               {errors[requiredMessageKey]}
//             </Alert>
//           </Grid>
//         )}
//         <Grid item md={12}>
//           <RadioGroup row>
//             {providerTypeOpts.map((option) => (
//               <FormControlLabel
//                 key={option.providerTypeId} // Added key prop
//                 checked={providerType === option.providerTypeId}
//                 control={<Radio onChange={() => setProviderType(option.providerTypeId)} />}
//                 label={option.providerType}
//               />
//             ))}
//           </RadioGroup>
//         </Grid>

//         {providerType === 1 ? (
//           <>
//             <Grid item md={4}>
//               <CustomField
//                 type="text"
//                 label="First Name"
//                 handleBlur={handleBlur}
//                 handleChange={handleChange}
//                 value={values.firstName}
//                 name="firstName"
//               />
//             </Grid>
//             <Grid item md={4}>
//               <CustomField
//                 type="text"
//                 label="Last Name"
//                 handleBlur={handleBlur}
//                 handleChange={handleChange}
//                 value={values.lastName}
//                 name="lastName"
//               />
//             </Grid>
//           </>
//         ) : (
//           <Grid item md={8}>
//             <CustomField
//               type="text"
//               label="Organization Name"
//               handleBlur={handleBlur}
//               handleChange={handleChange}
//               value={values.orgName}
//               name="orgName"
//             />
//           </Grid>
//         )}

//         <Grid item md={4}>
//           <CustomSearchField
//             fieldVal={values.taxonomy}
//             name="taxonomy"
//             handleChange={handleChange}
//             handleBlur={handleBlur}
//             label="Taxonomy"
//             handleModalOpen={() => setTaxonomyListModal(true)}
//           />
//         </Grid>
//         <Grid item md={4}>
//           <CustomField
//             type="text"
//             label="City"
//             handleBlur={handleBlur}
//             handleChange={handleChange}
//             value={values.cityName}
//             name="cityName"
//           />
//         </Grid>
//         <Grid item md={4}>
//           <CustomField
//             type="text"
//             label="State"
//             handleBlur={handleBlur}
//             handleChange={handleChange}
//             value={values.stateName}
//             name="stateName"
//           />
//         </Grid>
//         <Grid item md={4}>
//           <CustomField
//             type="text"
//             label="Zipcode"
//             handleBlur={handleBlur}
//             handleChange={handleChange}
//             value={values.postal_code}
//             name="postal_code"
//           />
//         </Grid>

//         <Grid item md={12} textAlign={"right"}>
//           <CustomButton
//             variant="contained"
//             isBlue={true}
//             isSearch={true}
//             type="submit"
//             color="primary"
//           >
//             Search
//           </CustomButton>
//         </Grid>
//       </Grid>

//       <CustomModal
//         open={openSearchDataModal}
//         handleClose={() => setOpenSearchDataModal(false)}
//         width={"70%"}
//       >
//         <SearchedNpiData
//           searchedData={searchedData}
//           handleSelectNpi={handleSelectNpi}
//           handleClose={() => setOpenSearchDataModal(false)}
//           setSearchNpiModal={setSearchNpiModal}
//         />
//       </CustomModal>
//       <CustomModal
//         open={taxonomyListModal}
//         handleClose={() => setTaxonomyListModal(false)}
//         width={"50%"}
//       >
//         <TaxonomyCategories
//           handleSelectTaxonomy={handleSelectTaxonomy}
//           setTaxonomyName={setTaxonomyName}
//           handleClose={() => setTaxonomyListModal(false)}
//         />
//       </CustomModal>
//     </>
//   );
// };

// export default SearchNpi;



import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Grid, Alert, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import CustomField from "../../../../components/CustomField";
import CustomSearchField from "../../../../components/CustomSearchField";
import CustomModal from "../../../../components/CustomModal";
import CustomButton from "../../../../components/CustomButton";
import { getData } from "../../../../config/axiosFunctions";
import { getTokenFromCookie } from "../../../../utils/customFunctions";
import TaxonomyCategories from "../taxonomy/TaxonomyCategories";
import path from "../../../../config/apiUrl";
import SearchedNpiData from "./SearchedNpiData";


const SearchNpi = ({ setSearchNpiModal, handleSelectNpi }) => {
  const [taxonomyListModal, setTaxonomyListModal] = useState(false);
  const [taxonomyName, setTaxonomyName] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [openSearchDataModal, setOpenSearchDataModal] = useState(false);
  const [providerTypeOpts, setProviderTypeOpts] = useState([]);
  const [providerType, setProviderType] = useState(1);

  const initialValues = {
    firstName: "",
    lastName: "",
    orgName: "",
    cityName: "",
    stateName: "",
    taxonomy: "",
    postal_code: "",
  };

  const requiredMessageKey = "at-least-one-field";


  const validateAtLeastOneField = (values) => {
    const hasValue = Object.values(values).some(
      (value) => value != null && value.trim() !== ""
    );
    if (!hasValue) {
      return { [requiredMessageKey]: "At least one field must be filled" };
    }
    return null;
  };


  // const token = getTokenFromCookie();
  const token = localStorage.getItem("token");

  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    submitCount,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validate: validateAtLeastOneField,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      console.log(values, "values");

      const queryParams = new URLSearchParams();
      Object.keys(values).forEach((key) => {
        if (values[key].trim() !== "") {
          queryParams.append(key, values[key].trim());
        }
      });
      const url = `${path}/npi/search?${queryParams.toString()}`;
      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` }, 
        });
        if (response) {
          console.log(response, "response search");
          const getSearchData = response.data;
          setSearchedData(getSearchData.result);
          setOpenSearchDataModal(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await getData(`${path}/ct-provideType`);
        if (response) {
          setProviderTypeOpts(response.result);
        }
      } catch (err) {
        console.log(err, "Error in fetching provider types");
      }
    };

    fetchTypes();
  }, []);

  useEffect(() => {
    if (submitCount > 0 && errors[requiredMessageKey]) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitCount, errors]);

  const handleSelectTaxonomy = (taxonomy) => {
    console.log(taxonomy, "taxonomy");
    setFieldValue("taxonomy", taxonomy);
  };
  console.log(providerType,"providerType")

  return (
    <>
      <Grid padding={"20px"} container spacing={2} component={"form"} onSubmit={handleSubmit}>
        {showAlert && errors[requiredMessageKey] && (
          <Grid item md={12}>
            <Alert severity="error" sx={{ fontSize: "1.4rem" }}>
              {errors[requiredMessageKey]}
            </Alert>
          </Grid>
        )}
        <Grid item md={12}>
          <RadioGroup row>
            {providerTypeOpts.map((option) => (
              <FormControlLabel
                key={option.providerTypeId}
                checked={providerType === option.providerTypeId}
                control={<Radio onChange={() => setProviderType(option.providerTypeId)} />}
                label={option.providerType}
              />
            ))}
          </RadioGroup>
        </Grid>

        {/* {providerType === 1 ? (
          <>
            <Grid item md={4}>
              <CustomField
                type="text"
                label="First Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.firstName}
                name="firstName"
              />
            </Grid>
            <Grid item md={4}>
              <CustomField
                type="text"
                label="Last Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.lastName}
                name="lastName"
              />
            </Grid>
          </>
        ) : (
          <Grid item md={8}>
            <CustomField
              type="text"
              label="Organization Name"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.orgName}
              name="orgName"
            />
          </Grid>
        )} */}
 <Grid item md={4}>
              <CustomField
                type="text"
                label="First Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.firstName}
                name="firstName"
              />
            </Grid>
            <Grid item md={4}>
              <CustomField
                type="text"
                label="Last Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.lastName}
                name="lastName"
              />
            </Grid>
     
        <Grid item md={4}>
          <CustomSearchField
            fieldVal={values.taxonomy}
            name="taxonomy"
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Taxonomy"
            handleModalOpen={() => setTaxonomyListModal(true)}
          />
        </Grid>

        <Grid item md={12}>
            <CustomField
              type="text"
              label="Organization Name"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.orgName}
              name="orgName"
            />
          </Grid>
        <Grid item md={4}>
          <CustomField
            type="text"
            label="City"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.cityName}
            name="cityName"
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            type="text"
            label="State"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.stateName}
            name="stateName"
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            type="text"
            label="Zipcode"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.postal_code}
            name="postal_code"
          />
        </Grid>

        <Grid item md={12} textAlign={"right"}>
          <CustomButton variant="contained" isBlue={true} isSearch={true} type="submit" color="primary">
            Search
          </CustomButton>
        </Grid>
      </Grid>

      <CustomModal
        open={openSearchDataModal}
        handleClose={() => setOpenSearchDataModal(false)}
        width={"70%"}
      >
        <SearchedNpiData
          searchedData={searchedData}
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setOpenSearchDataModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      <CustomModal
        open={taxonomyListModal}
        handleClose={() => setTaxonomyListModal(false)}
        width={"50%"}
      >
        <TaxonomyCategories
          handleSelectTaxonomy={handleSelectTaxonomy}
          setTaxonomyName={setTaxonomyName}
          handleClose={() => setTaxonomyListModal(false)}
        />
      </CustomModal>
    </>
  );
};

export default SearchNpi;
