import React, { useState } from "react";
import {FormControlLabel, Checkbox, Grid,Paper,Typography, Box} from "@mui/material";
import { useSelector } from "react-redux";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import CustomDialog from "../../../components/CustomDialog";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";
import CustomPreview from "../../../layout/CustomPreview";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "../../../features/actions/procedureCodeAction";
import { procedureModal } from "../../../utils/gridCols";
import { useNavigate } from "react-router-dom";
const InventoryCodeForm = ({ formik }) => {
  const navigate=useNavigate();
  const { getProcedureCodes } = useSelector((state) => state.procedureCodes);
  const procedureLoading = useSelector((state) => state.procedureCodes.loading);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openProcedureModal, setOpenProcedureModal] = useState(false);
  const handleProcedureCode = (e) => {
    formik.setValues((prev) => ({
      ...prev,
      procedureCodeId: e?.id,
      procedureCode: e?.procedureCode,
    }));
    setOpenProcedureModal(false);
  };
  const rowsProcedure =
    getProcedureCodes && getProcedureCodes?.result?.procedureCodes?.length > 0
      ? getProcedureCodes?.result?.procedureCodes?.map((el) => ({
          id: el.procedureCodeId,
          units: el.units === null ? 1 : el.units,
          ...el,
        }))
      : [];

      const handleCancel = () => {
        setOpenCancelDialog(true);
      };
    
      const handleConfirmCancel = () => {
        formik.resetForm();
        navigate("/inventorycode");
      };
  return (
    <>
     <form onSubmit={formik.handleSubmit} id="inventoryForm">
    <Box sx={{display:'flex',justifyContent:'flex-start',gap:'10px',margin:'8px'}}>
    <CustomButton
          formId={"inventoryForm"}
          type={"submit"}
         isSave={true}
          isBlue={true}
          children={"Save"}
        />
         <CustomButton isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
    </Box>
    <Paper elevation={3} style={{ padding: 20, margin: 7 }}>
    <Grid container padding={'10px'} spacing={1}>
      <Grid item md={4}>
          <CustomField
            error={formik.errors}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            isRequired={true}
            label={"Code"}
            name={"code"}
            value={formik.values.code}
            type={"text"}
            touched={formik.touched}
          />
    </Grid>
    <Grid item md={4}>
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label={"Qunatity"}
            name={"quantity"}
            value={formik.values.quantity}
            type={"number"}
          />
        </Grid>
        <Grid item md={4}>
          <CustomSearchField
            fieldVal={formik.values.procedureCode}
            label={"Procedure Code"}
            name={"procedureCodeId"}
            type="text"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            handleModalOpen={() => setOpenProcedureModal(true)}
            formik={formik}
            resetField={{
              procedureCode: null,
            }}
          />
        </Grid>
        <Grid item md={6}>
        <CustomField
            label={"Code Description"}
            type={"text"}
            value={formik.values.codeDescription}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name={"codeDescription"}
          />
        </Grid>
        <Grid item md={6}>
        <CustomField
            label={"Billing Description"}
            type={"text"}
            value={formik.values.billingDescription}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name={"billingDescription"}
            touched={formik.touched}
            error={formik.errors}
          />
        </Grid>
        <Grid item md={12}>
        <FormControlLabel
          control={
            <Checkbox
              value={formik.values.alert}
              onChange={(e) =>
                formik.setFieldValue("alert", !formik.values.alert)
              }
            />
          }
          label="Use Alert"
        />
        </Grid>    
    </Grid>
    <Grid container spacing={1} padding={'5px'}>
        {formik.values.alert && (
        <>
        <Grid item md={6}>
        <CustomField
            label={"Alert Description"}
            type={"text"}
            value={formik.values.alertDescription}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name={"alertDescription"}
            isRequired={true}
            touched={formik.touched}
            error={formik.errors}

          />
        </Grid>
        <Grid item md={6}>
        <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label={"Display alert when quantity falls below"}
              name={"alertQuantity"}
              value={formik.values.alertQuantity}
              type={"number"}
            />
        </Grid>        
          <CustomModal
        open={openProcedureModal}
        handleClose={() => setOpenProcedureModal(false)}
        heading={"All Procedure"}
      >
       
        <CustomPreview
          isModal={true}
          rows={rowsProcedure}
          columns={procedureModal}
          handleGetAll={getProcedureCodeAction}
          handleCell={handleProcedureCode}
          handleBySearch={getProcedureCodesBySearchTermAction}
          searchLabel="Search By Procedure Code and Procedure Description"
        />
      </CustomModal>
        </>
      )}
        </Grid>
        </Paper>

        {/* custom modal */}
        <CustomDialog
   open={openCancelDialog}
   isWarning={true}
   handleClose={() => setOpenCancelDialog(false)}
   handleConfirm={handleConfirmCancel}
   handleCancel={() => setOpenCancelDialog(false)}
   confirmButtonTitle="OK"
   cancelButtonTitle="Cancel"
   isCancel={true}
  />
   
     </form>
    </>
  );
};

export default InventoryCodeForm;
