// import React from "react";
// import { followUpManagementCols } from "../../../utils/gridCols";
// import { searchFollowUpDataAction } from "../../../features/actions/followUpManagementAction";
// import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
// import { Box,Stack} from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import CustomButton from "../../../components/CustomButton";
// import { handleCancel } from "../../../utils/customFunctions";

// const FollowUpSearchedData = ({data,formik}) => {

//   const dispatch = useDispatch();
//  const rows = data.map(item => ({
//       id: item.claimnumber, 
//       claimnumber: item.claimnumber,
//       patientName: item.patientName,
//       dos: item.dos ,
//       currentPayer: item.currentPayer,
//       lastBilledDate: item.lastBilledDate ,
//       balance: item.balance,
//       claimFollowupDate: item.claimFollowupDate ,
//       lastNote: item.lastNote
//     }));


//   return (
//     <>
//   <Box>
//      <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
    
//        <CustomButton
//                   handleClick={() => {
//                     dispatch(handleCancel());
//                     formik.resetForm();
//                   }}
//                   children={"Cancel"}
//                   isCancel={true}
//                 />
//       </Stack>
//     <MultiSelectCustomPreview
//     rows={rows}
//     isModal={true}
//     columns={followUpManagementCols}
//     handleGetAll={searchFollowUpDataAction}
//     handleCell={handleCellClick}
    
//   />
//   </Box>
//     </>
//   );
// };

// export default FollowUpSearchedData;




// import React, { useState } from "react";
// import { followUpManagementCols } from "../../../utils/gridCols";
// import { searchFollowUpDataAction } from "../../../features/actions/followUpManagementAction";
// import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
// import { Box, Stack } from "@mui/material";
// import { useDispatch } from "react-redux";
// import CustomButton from "../../../components/CustomButton";
// import { handleCancel } from "../../../utils/customFunctions";
// import CustomModal from "../../../components/CustomModal";


// const FollowUpSearchedData = ({ data, formik, procedureFormik, setClaimChargesDto }) => {
//   const [openAddNoteModal,setOpenAddNoteModal]=useState([])
//   const dispatch = useDispatch();

//   const rows = data.map(item => ({
//     id: item.claimnumber,
//     claimnumber: item.claimnumber,
//     patientName: item.patientName,
//     dos: item.dos,
//     currentPayer: item.currentPayer,
//     lastBilledDate: item.lastBilledDate,
//     balance: item.balance,
//     claimFollowupDate: item.claimFollowupDate,
//     lastNote: item.lastNote
//   }));

//   const handleCellClick = (selectedRow) => {
//     setSelectedCellData(selectedRow);
//     setEditProcedure(true);  
//     setOpenAddNoteModal(true);
//   };

//   const handleAddNewClick = () => {

//     setOpenAddNoteModal(true);
//   };



//   return (
//     <>
//       <Box>
//         <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
//           <CustomButton
//             handleClick={() => {
//               dispatch(handleCancel());
//               formik.resetForm();
//             }}
//             children={"Cancel"}
//             isCancel={true}
//           />
//           <CustomButton
//             handleClick={handleAddNewClick}
//             children={"Add New"}
//           />
//         </Stack>
//         <MultiSelectCustomPreview
//           rows={rows}
//           isModal={true}
//           columns={followUpManagementCols}
//           handleGetAll={searchFollowUpDataAction}
//           handleCell={handleCellClick} 
//         />
//       </Box>
//       {openAddNoteModal && (
//         <CustomModal
//           open={openAddNoteModal}
//           handleClose={() => setOpenAddNoteModal(false)}
//           height={"20%"}
//         >
//          hghg
//         </CustomModal>
//       )}
//     </>
//   );
// };

// export default FollowUpSearchedData;








// import React, { useState } from "react";
// import { followUpManagementCols } from "../../../utils/gridCols";
// import { searchFollowUpDataAction } from "../../../features/actions/followUpManagementAction";
// import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
// import { Box, Stack } from "@mui/material";
// import { useDispatch } from "react-redux";
// import CustomButton from "../../../components/CustomButton";
// import { handleCancel } from "../../../utils/customFunctions";
// import CustomModal from "../../../components/CustomModal";

// const FollowUpSearchedData = ({ data, formik, procedureFormik, setClaimChargesDto }) => {
//   const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
//   const [selectedCellData, setSelectedCellData] = useState(null);
//   const [editProcedure, setEditProcedure] = useState(false);
//   const dispatch = useDispatch();

//   const rows = data.map(item => ({
//     id: item.claimnumber,
//     claimnumber: item.claimnumber,
//     patientName: item.patientName,
//     dos: item.dos,
//     currentPayer: item.currentPayer,
//     lastBilledDate: item.lastBilledDate,
//     balance: item.balance,
//     claimFollowupDate: item.claimFollowupDate,
//     lastNote: item.lastNote
//   }));

//   const handleCellClick = (selectedRow) => {
//     setSelectedCellData(selectedRow);
//     setEditProcedure(true);
//     setOpenAddNoteModal(true);
//   };

//   const handleModalClose = () => {
//     setOpenAddNoteModal(false);
//     setEditProcedure(false);
//   };

//   return (
//     <>
//       <Box>
//         <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
//           <CustomButton
//             handleClick={() => {
//               dispatch(handleCancel());
//               formik.resetForm();
//             }}
//             children={"Cancel"}
//             isCancel={true}
//           />
//         </Stack>
//         <MultiSelectCustomPreview
//           rows={rows}
//           isModal={true}
//           columns={followUpManagementCols}
//           handleGetAll={searchFollowUpDataAction}
//           handleCell={handleCellClick}
//         />
//       </Box>
//       {openAddNoteModal && (
//         <CustomModal
//           open={openAddNoteModal}
//           handleClose={handleModalClose}
//           height={"20%"}
//         >
//           {editProcedure ? (
//             <div>
//               <h2>Edit Procedure</h2>
//               <p>Data: {JSON.stringify(selectedCellData)}</p>
//             </div>
//           ) : (
//             <div>
//               <h2>Add Procedure</h2>
//             </div>
//           )}
//         </CustomModal>
//       )}
//     </>
//   );
// };

// export default FollowUpSearchedData;
import React, { useState } from "react";
import { followUpManagementCols } from "../../../utils/gridCols";
import { searchFollowUpDataAction } from "../../../features/actions/followUpManagementAction";
import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
import { Box, Stack,Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import { handleCancel } from "../../../utils/customFunctions";
import CustomModal from "../../../components/CustomModal";
import CustomDialog from "../../../components/CustomDialog";
import { useNavigate } from "react-router-dom";


const FollowUpSearchedData = ({ data, formik, procedureFormik, setClaimChargesDto, handleClose }) => {
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [editProcedure, setEditProcedure] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rows = data.map(item => ({
    id: item.claimnumber,
    claimnumber: item.claimnumber,
    patientName: item.patientName,
    dos: item.dos,
    currentPayer: item.currentPayer,
    lastBilledDate: item.lastBilledDate,
    balance: item.balance,
    claimFollowupDate: item.claimFollowupDate,
    lastNote: item.lastNote
  }));

  const handleCellClick = (selectedRow) => {
    setSelectedCellData(selectedRow);
    setEditProcedure(true);
    setOpenAddNoteModal(true);
    console.log("selected",selectedRow)
  };


  const handleAddNewClick = () => {
    setSelectedCellData(null);
    setEditProcedure(false);
    setOpenAddNoteModal(true);
  };

  const handleModalClose = () => {
    setOpenAddNoteModal(false);
    setEditProcedure(false);
  };


  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    handleClose()
  };

  return (
    <>
      <Box>
        <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
          <CustomButton
            // handleClick={() => {
            //   dispatch(handleCancel());
            //   formik.resetForm();
            // }}
            handleClick={handleCancel}
            children={"Cancel"}
            isCancel={true}
          />
          <CustomButton
            handleClick={handleAddNewClick}
            children={"Add New"}
          />
        </Stack>
        <MultiSelectCustomPreview
          rows={rows}
          isModal={true}
          columns={followUpManagementCols}
          handleGetAll={searchFollowUpDataAction}
          handleCell={handleCellClick}
        />
      </Box>
      {openAddNoteModal && (
        <CustomModal
          open={openAddNoteModal}
          handleClose={handleModalClose}
        >
          {editProcedure ? (
            <div>
              <h2>Edit Procedure</h2>
              <p>Data: {JSON.stringify(selectedCellData)}</p>
            </div>
          ) : (
            <div>
              <h2>Add Procedure</h2>
            </div>
          )}
        </CustomModal>
      )}
       <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
   
    </>
  );
};

export default FollowUpSearchedData;
