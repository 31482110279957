import { createSlice } from "@reduxjs/toolkit";
import { getCompanyAction, newCompanyAction,getCompanyByIdAction,updateCompanyAction } from "../actions/companiesAction";
import { toast } from "react-toastify";

const initialState = {
  getCompaniesData: [],
  createCompanyData: {},
 companyByIdData : {},
  loading: {
    getAllLoading: false,
  },
};

const companySlice = createSlice({
  name: "companies",
  initialState: initialState,
  extraReducers: {
    // [getCompanyAction.pending]: (state) => {
    //     state.loading.getAllLoading = true
    // },
    // [getCompanyAction.fulfilled]: (state, action) => {
    //     state.loading.getAllLoading = false
    //     state.getCompaniesData = action.payload
    // },
    // [getCompanyAction.rejected]: (state, action) => {
    //     state.loading.getAllLoading = false
    //     state.error = action.payload
    // },
    [getCompanyAction.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCompaniesData = action.payload;
    },
    [getCompanyAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [newCompanyAction.pending]: (state) => {
      state.loading = true;
    },
    [newCompanyAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createCompanyData = action.payload;
      toast.success("Company has been created successfully!");
    },
    [newCompanyAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Company Creation Failed.");
    },
    [getCompanyByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyByIdData = action.payload;
    },
    [getCompanyByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateCompanyAction.pending]: (state) => {
      state.loading = true;
    },
    [updateCompanyAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Company Updated Successfully!");
    },
    [updateCompanyAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "companyErr");
      toast.error("Company Updation Failed.");
    },
  },
});

export default companySlice.reducer;
