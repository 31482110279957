import { configureStore } from "@reduxjs/toolkit";
import patientSlice from "../features/slice/patientSlice";
import payerSlice from "../features/slice/payerSlice";
import practiceSlice from "../features/slice/practiceSlice";
import taxonomySlice from "../features/slice/taxonomySlice";
import providerSlice from "../features/slice/providerSlice";
import claimSlice from "../features/slice/claimSlice";
import facilitySlice from "../features/slice/facilitySlice";
import PaymentSlice from "../features/slice/PaymentSlice";
import diagnosisSice from "../features/slice/diagnosisSice";
import userSlice from "../features/slice/userSlice";
import remittanceSlice from "../features/slice/remittanceSlice";
import adjustmentSlice from "../features/slice/adjustmentSlice";
import revenueSlice from "../features/slice/revenueSlice";
import inventoryCodeSlice from "../features/slice/inventoryCodeSlice";
import procedureCodeSlice from "./../features/slice/procedureSlice";
import payerInfoSlice from "../features/slice/payerInfoSlice";
import tosCodeSlice from "../features/slice/tosCodeSlice";
import posCodeSlice from "../features/slice/posCodeSlice";
import renderingProviderSlice from "../features/slice/renderingProviderSlice";
import billingProviderSlice from "../features/slice/billingProviderSlice";
import chargePanelSlice from "../features/slice/chargePanelSlice";
import insuredPartySlice from "../features/slice/insuredPartySlice";
import icdProcedureSlice from "../features/slice/icdProcedureSlice";
import eraSlice from "../features/slice/eraSlice";
import statementBatchSlice from "./../features/slice/StatementBatchSlice";
import followUpManagementSlice from "../features/slice/followUpManagementSlice";
import statusControlSlice from "../features/slice/statusControlSlice";
import claimTrackerSlice from "../features/slice/claimTrackerSlice";
import modifierSlice from "../features/slice/modifierSlice";
import claimBatchPrintSlice from "../features/slice/claimBatchPrintSlice";
import feeScheduleSlice from "../features/slice/feeScheduleSlice";
import companySlice from "../features/slice/companySlice";
import referringProviderSlice from "../features/slice/referringProviderSlice";
import guarantorSlice from "../features/slice/guarantorSlice";
import employerSlice from "../features/slice/employerSlice";
import claimSubmissionSlice from './../features/slice/claimSubmissionSlice';

const store = configureStore({
  reducer: {
    patient: patientSlice,
    payer: payerSlice,
    practices: practiceSlice,
    taxonomy: taxonomySlice,
    provider: providerSlice,
    claim: claimSlice,
    claimSubmission: claimSubmissionSlice,
    facility: facilitySlice,
    insuredParty: insuredPartySlice,
    referringProvider: referringProviderSlice,
    users: userSlice,
    payment: PaymentSlice,
    diagnosis: diagnosisSice,
    remittance: remittanceSlice,
    adjustments: adjustmentSlice,
    revenue: revenueSlice,
    inventoryCode: inventoryCodeSlice,
    procedureCodes: procedureCodeSlice,
    payerInfo: payerInfoSlice,
    tosCodes: tosCodeSlice,
    posCodes: posCodeSlice,
    renderingProvider: renderingProviderSlice,
    billingProvider: billingProviderSlice,
    chargePanel: chargePanelSlice,
    icdProcedure: icdProcedureSlice,
    era: eraSlice,
    statementBatch: statementBatchSlice,
    followUpManagement: followUpManagementSlice,
    statusControl: statusControlSlice,
    claimTracker: claimTrackerSlice,
    modifier: modifierSlice,
    claimBatchPrint: claimBatchPrintSlice,
    feeSchedule: feeScheduleSlice,
    companies: companySlice,
    guarantor: guarantorSlice,
    employer: employerSlice
  },
});
export default store;
