import React, { useState } from "react";
import {
  Stack,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import CustomField from "../../../components/CustomField";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomPreview from "../../../layout/CustomPreview";
import CustomModal from "../../../components/CustomModal";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { practiceCols, providerCols } from "../../../utils/gridCols";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";
import {
  getPracticeAction,
  getPracticeBySearchTermAction,
} from "../../../features/actions/practiceAction";

const RefundAccount = ({ formik, availableCredit, handleClose }) => {
  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);
  const { getPractices, loading } = useSelector((state) => state.practices);

  const [providerModal, setProviderModal] = useState(false);
  const [practiceModal, setPracticeModal] = useState(false);
  const theme = useTheme();
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} padding="18px">
          <Grid item md={6} sm={12} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                label={
                  <Typography fontSize={"1.5rem"} fontWeight="600">
                    Patient
                  </Typography>
                }
                control={
                  <Radio
                    value="claim"
                    variant="soft"
                    name="claim"
                    checked={formik.values.paymentByTypeId === 1}
                    onChange={() => {
                      formik.setFieldValue("paymentByTypeId", 1);
                    }}
                    style={{
                      color: theme.palette.radioButtons.primary,
                    }}
                  />
                }
              />
              <FormControlLabel
                label={
                  <Typography fontSize={"1.5rem"} fontWeight="600">
                    Insurance
                  </Typography>
                }
                control={
                  <Radio
                    value="payer"
                    variant="soft"
                    name="payer"
                    checked={formik.values.paymentByTypeId === 2}
                    onChange={() => {
                      formik.setFieldValue("paymentByTypeId", 2);
                    }}
                    style={{
                      color: theme.palette.radioButtons.primary,
                    }}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography marginTop={"5px"} variant="h4" fontWeight={600}>
              Available Credit: {availableCredit}
            </Typography>

            <CustomField
              value={formik.values.amount}
              name="amount"
              handleChange={(e) => {
                formik.setFieldValue("amount", parseFloat(e.target.value));
                formik.setFieldValue("remaining", parseFloat(e.target.value));
              }}
              handleBlur={formik.handleBlur}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              label="Amount"
              type="number"
              isDecimal={true}
            />
            <CustomDatePicker
              formik={formik}
              name="refundDate"
              dateValue={formik.values.refundDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
              dateLabel="Refund Date"
            />
            <CustomField
              value={formik.values.checkNumber}
              name="checkNumber"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              label="Check #/Refrence #"
              type="text"
            />
            <CustomField
              value={formik.values.memo}
              name="memo"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              label="Memo"
              type="text"
            />
            <CustomSearchField
              label="Provider"
              fieldVal={formik.values.providerName}
              handleModalOpen={() => setProviderModal(true)}
              name="providerName"
              isRequired={true}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              resetField={{
                providerName: "",
              }}
            />
            <CustomSearchField
              label="Practice"
              fieldVal={formik.values.practiceName}
              handleModalOpen={() => setPracticeModal(true)}
              isRequired={true}
              name="practiceName"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              resetField={{
                practiceName: "",
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CustomButton type={"submit"} isBlue={true}>
                Apply
              </CustomButton>
              <CustomButton
                handleClick={() => handleClose()}
                isCancel={true}
                margin={1.5}
              >
                Cancel
              </CustomButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <CustomModal
        open={providerModal}
        heading={"Providers"}
        handleClose={() => setProviderModal(false)}
      >
        <CustomPreview
          rows={
            getProviders?.result && getProviders?.result?.providers.length > 0
              ? getProviders.result?.providers.map((el) => ({
                  id: el.providerId,
                  ...el,
                }))
              : []
          }
          paginationDetail={getProviders}
          columns={providerCols}
          loading={providerLoading}
          handleGetAll={getProviderAction}
          isModal={true}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search for provider by name, sequence #"
          handleCell={(e) => {
            formik.setFieldValue("providerId", e?.providerId);
            formik.setFieldValue(
              "providerName",
              `${e?.providerFirstName} ${e?.providerLastName} (${e?.providerSequenceNo})`
            );
            formik.setFieldValue("practiceId", null);
            formik.setFieldValue("practiceName", "");
            setProviderModal(false);
          }}
        />
      </CustomModal>
      <CustomModal
        open={practiceModal}
        heading={"Practices"}
        handleClose={() => setPracticeModal(false)}
      >
        <CustomPreview
          rows={
            getPractices && getPractices?.result?.practice?.length > 0
              ? getPractices?.result?.practice?.map((el) => ({
                  id: el.practiceId,
                  ...el,
                }))
              : []
          }
          columns={practiceCols}
          paginationDetail={getPractices}
          searchLabel="Search for practices by name, phone #, sequence #"
          loading={loading}
          isModal={true}
          handleGetAll={getPracticeAction}
          handleBySearch={getPracticeBySearchTermAction}
          handleCell={(e) => {
            console.log("e", e);
            formik.setFieldValue("practiceId", e?.practiceId);
            formik.setFieldValue(
              "practiceName",
              `${e?.practiceName} (${e?.sequenceNo})`
            );
            formik.setFieldValue("providerId", null);
            formik.setFieldValue("providerName", ``);
            setPracticeModal(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default RefundAccount;
