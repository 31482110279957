import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getUserByIdAction,
  newUserAction,
  updateUserAction,
  updateUserPasswordAction,
} from "../actions/userAction";
import { toast } from "react-toastify";
const initialState = {
  allUsers: [],
  createUser: {},
  singleUserData: {},
  practiceSeq: 0,
  error: null,
  loading: false,
};

const userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    handleSetPracticeSeq: (state, action) => {
      state.practiceSeq = action.payload;
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.allUsers = action.payload;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "user error");
    },
    [getUserByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getUserByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleUserData = action.payload;
    },
    [getUserByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "user error");
    },
    [newUserAction.pending]: (state) => {
      state.loading = true;
    },
    [newUserAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createUser = action.payload;
      toast.success("User has been created successfully!");
    },
    [newUserAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "userErr");
      toast.error("User Creation Failed.");
    },
    [updateUserAction.pending]: (state) => {
      state.loading = true;
    },
    [updateUserAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("User Updated Successfully!");
    },
    [updateUserAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "UserErr");
      toast.error("User Updation Failed.");
    },
    [updateUserPasswordAction.pending]: (state) => {
      state.loading = true;
    },
    [updateUserPasswordAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Password Updated Successfully!");
    },
    [updateUserPasswordAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "UserErr");
      toast.error("Password Updation Failed.");
    },
  },
});

export const { handleSetPracticeSeq } = userSlice.actions;
export default userSlice.reducer;
