import { Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDownSharp } from "@mui/icons-material";
import React from "react";

const MultiButton = ({ multiOpts, title, onSelect, multiSection,margin}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedVal) => {
    if (onSelect && selectedVal) {
      onSelect(selectedVal);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="customized-multi-btn"
        aria-controls={open ? "multiBtn" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownSharp />}
        sx={{
          backgroundColor: '#1976d2',
          color: '#fff',
          '&:hover': {
            // backgroundColor: '#1565c0',
            backgroundColor:' #2fa8ee'
           
          },
          textTransform: 'none',
          padding: '5px 9px',
          fontSize: '14px',
          margin:margin,
        }}
      >
        {title}
      </Button>
      <Menu
        elevation={3}
        id="multiBtn"
        MenuListProps={{
          "aria-labelledby": "customized-multi-btn",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            minWidth: '150px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.2) 0px 2px 10px',
          },
          '& .MuiMenuItem-root': {
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            },
          },
        }}
      >
        {multiOpts?.map((opt) => (
          <MenuItem
            onClick={() => handleClose(opt)}
            key={opt.id}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#e3f2fd',
              },
            }}
          >
            {opt.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MultiButton;



// import React from 'react';
// import { Menu, MenuItem, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';

// const MultiButton = ({ title, multiOpts, multiSection, radioGroupName, selectedValue, onRadioChange, onClaimStatusClick }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <Button
//         aria-controls="simple-menu"
//         aria-haspopup="true"
//         onClick={handleClick}
//       >
//         {title}
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         keepMounted
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//       >
//         {multiOpts.map((opt) => (
//           <MenuItem
//             key={opt.id}
//             onClick={() => {
//               onClaimStatusClick(opt.id);
//               handleClose();
//             }}
//           >
//             {opt.value}
//           </MenuItem>
//         ))}
//         <RadioGroup name={radioGroupName} value={selectedValue} onChange={onRadioChange}>
//           {multiSection.map((opt) => (
//             <FormControlLabel
//               key={opt.id}
//               value={opt.id}
//               control={<Radio />}
//               label={opt.value}
//             />
//           ))}
//         </RadioGroup>
//       </Menu>
//     </div>
//   );
// };

// export default MultiButton;






