import { Grid, Typography, useTheme, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuredPartyAction } from '../../features/actions/insuredPartyActions';

const InsuredPartyDetails = ({ insuredPartyId }) => {
  let flag = false
  const duspatch = useDispatch()
  const { getInsuredPartyData, loading } = useSelector((state) => state.insuredParty);
  console.log(getInsuredPartyData, "checkINsuredPartyData")
  const findDetails = getInsuredPartyData?.result?.find((val) => val?.insuredId === insuredPartyId);
  console.log(findDetails, "findedDetails");

  const theme = useTheme();

  useEffect(() => {
    if(!flag){
      flag = true;
      duspatch(getInsuredPartyAction())
    }
  }, [insuredPartyId])
  return (
    
    <Box sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius:'5px', padding:'10px', margin:'15px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
          Name:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredFirstName} {findDetails?.insuredLastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Address:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Gender:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredGenderIdentityName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Email:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredEmail}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Country:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredCountryName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            State:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredStateName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            City:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredCityName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Insured Phone No:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredZipCode}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Home Phone No:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredHomePhone}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Work Phone No:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredWorkPhone}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h4" fontWeight={600}>
            Cell Phone No:
          </Typography>
          <Typography fontSize={'1.3rem'} sx={{ color: "#8f7c7c" }}>
            {findDetails?.insuredCellPhone}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuredPartyDetails;

