import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomField from "./CustomField";
import CustomSelectBox2 from "./CustomSelectBox2";
import { getData } from "../config/axiosFunctions";
import path from "../config/apiUrl";
import CustomButton from "./CustomButton";
import { Close, Done } from "@mui/icons-material";
import { toast } from "react-toastify";

const CustomAddress = ({ fieldValues, formik, isRequired }) => {
  const [countryOpt, setCountryOpt] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [addressDetail, setAddressDetail] = useState([]);
  const [notFound, setNotFound] = useState(false);
  console.log(addressDetail, "addressDetail");
  const handleZipcodeChange = async (e) => {
    const newZipCode = e.target.value;
    formik.setFieldValue(fieldValues?.zipName, newZipCode);
    if (newZipCode?.length > 4) {
      try {
        const response = await getData(
          `${path}/ZipCode/SearchZipCode?zipcode=${newZipCode}`
        );
        if (response.result.length > 1) {
          setAddressDetail(response.result);
          setNotFound(false);
          setOpenDetail(true);
        } else if (response.result.length === 0) {
          setNotFound(true);
          setOpenDetail(true);
        } else {
          setOpenDetail(false);
          setNotFound(false);
          formik.setFieldValue(
            fieldValues?.zipName,
            response.result[0]?.zipCode
          );
          formik.setFieldValue(
            fieldValues?.zipCodeId,
            response.result[0]?.zipCodeId
          );
          formik.setFieldValue(
            fieldValues?.stateId,
            response.result[0]?.stateId
          );
          formik.setFieldValue(
            fieldValues?.stateName,
            response.result[0]?.stateName
          );
          formik.setFieldValue(fieldValues?.cityId, response.result[0]?.cityId);
          formik.setFieldValue(
            fieldValues?.cityName,
            response.result[0]?.cityName
          );
        }
      } catch (e) {
        console.error(e, ": error in getting zip code details");
        setOpenDetail(false);
        toast.error("something went wrong!");
        return;
      }
    }
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await getData(`${path}/country`);
        setCountryOpt(response.result);
      } catch (e) {
        console.error(e, ": error in getting country options");
      }
    };
    fetchCountry();
    // cleanup function
    return () => {
      setCountryOpt([]);
    };
  }, [setCountryOpt]);

  //   handle select address
  const handleSelectAddress = (address) => {
    formik.setFieldValue(fieldValues?.zipName, address?.zipCode);
    formik.setFieldValue(fieldValues?.zipCodeId, address?.zipCodeId);
    formik.setFieldValue(fieldValues?.stateId, address?.stateId);
    formik.setFieldValue(fieldValues?.stateName, address?.stateName);
    formik.setFieldValue(fieldValues?.cityId, address?.cityId);
    formik.setFieldValue(fieldValues?.cityName, address?.cityName);
    setOpenDetail(false);
  };
  return (
    <>
    {/* <Grid container spacing={2} padding={'5px'}> */}
      <Grid item xs={12} sm={6} md={3}>
        <CustomSelectBox2
          dropdownOptions={countryOpt?.map((opt) => ({
            id: opt?.countryId,
            value: opt?.countryName,
          }))}
          value={fieldValues?.countryId}
          name={fieldValues?.countryName}
          label="Country"
          showDefaultOpt={true}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormGroup
          sx={{
            position: "relative",
          }}
        >
          <CustomField
            type="text"
            label="Zip Code"
            value={fieldValues?.zipCode}
            name={fieldValues?.zipName}
            handleBlur={formik.handleBlur}
            handleChange={handleZipcodeChange}
            min={"5"}
            max={"9"}
            error={formik.errors}
            touched={formik.touched}
            isRequired={isRequired}
          />
          {openDetail && (
            <Paper
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                zIndex: 111,
                boxShadow: 3,
              }}
            >
              <Box
                sx={{
                  margin: "10px",
                }}
              >
                <Typography component="h5" margin="0 0 10px">
                  {notFound
                    ? "The zip code does not match with any city or state."
                    : `   The entered ZIP code doesn't correspond to the city/state.
                  Please correct it or choose a suggested match from the list
                  below:`}
                </Typography>
                {!notFound &&
                  addressDetail?.map((addr, ind) => (
                    <Stack key={ind} marginTop="5px">
                      <CustomButton
                        isBlue={false}
                        handleClick={() => handleSelectAddress(addr)}
                      >
                        <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                        Change to {addr?.cityName}, {addr?.stateCode}
                      </CustomButton>
                    </Stack>
                  ))}
                <Stack marginTop="5px">
                  <CustomButton
                    isBlue={false}
                    handleClick={() => setOpenDetail(false)}
                  >
                    <Close fontSize="large" sx={{ marginRight: "5px" }} /> Keep
                  </CustomButton>
                </Stack>
              </Box>
            </Paper>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CustomField
          type="text"
          label="State"
          value={fieldValues?.state}
          name={fieldValues?.stateName}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          error={formik.errors}
          touched={formik.touched}
          isRequired={isRequired}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CustomField
          type="text"
          label="City"
          value={fieldValues?.city}
          name={fieldValues?.cityName}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          error={formik.errors}
          touched={formik.touched}
          isRequired={isRequired}
        />
      </Grid>
    {/* </Grid> */}
    </>
  );
};

export default CustomAddress;
