import React, { useEffect, useState } from "react";
import { Grid, useTheme, MenuItem, Stack,Typography} from "@mui/material";
import { getData } from "../../../config/axiosFunctions";
import CustomField from "../../../components/CustomField";
import "../../../components/Custom-styling/CustomField.css";

import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import CustomModal from "./../../../components/CustomModal";
import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getPracticeAction,
  getPracticeListAction,
  getPracticeBySearchTermAction,
} from "../../../features/actions/practiceAction";
import { practiceCols } from "../../../utils/gridCols";
import { useDispatch, useSelector } from "react-redux";
import path from "../../../config/apiUrl";
const UserForm = ({ formik }) => {
  console.log(formik, "formik");
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openPracticeMod, setOpenPracticeMod] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

  const [practiceSelectedRow, setPracticeSelectedRow] = useState([]);
  const [userTypeOpt, setUserTypeOpt] = useState([]);
  const userId = localStorage.getItem("userId");
  const { values, errors, touched, handleChange, handleBlur } = formik;

  const modifiedCols = practiceCols?.map(item => ({
    ...item,
    field: item?.field === "sequenceNo" ? "practiceSeqNo" : item?.field
  }))
  console.log(modifiedCols, "checkModifiedCOls")
  // use selector here
  // const {getPractices, loading } = useSelector((state) => state.practices);
  // console.log(getPractices,"practicelist")
  const {getPracticeListData, loading } = useSelector((state) => state.practices);
  console.log(getPracticeListData,"practicelist")

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };
  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/users");
  };

  const dataFetchUrls = {
    userType: `${path}/ct-userType`,
  };
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataOptions(dataFetchUrls.userType, setUserTypeOpt);
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      formik.setFieldValue("createdById", userId);
    }
  }, [userId]);

  // const handleClick = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleVisibilityToggle = (fieldName) => {
    if (fieldName === "password") {
      setShowPassword(!showPassword);
    } else if (fieldName === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} columnGap={"10px"}>
        <CustomButton isBlue={true} isAdd={true} type="submit">
          Save
        </CustomButton>
        <CustomButton isCancel={true} handleClick={handleCancel}>
          Cancel
        </CustomButton>
        <CustomButton
          isSetting={true}
          handleClick={() => setOpenPracticeMod(true)}
        >
          Practice Access
        </CustomButton>
      </Stack>
      <Grid
        container
        spacing={2}
        alignItems={"start"}
        sx={{
          background: theme.palette.innerBox.primary,
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Grid item md={9} xs={12}>
          <CustomField
            fontFormat="uppercase"
            label="User Name"
            type="text"
            value={formik.values.username}
            name="username"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomSelectBox2
            dropdownOptions={userTypeOpt?.map((opt) => ({
              id: opt?.userTypeId,
              value: opt?.userType,
            }))}
            value={formik.values.userTypeId}
            name="userTypeId"
            label="User Type"
            showDefaultOpt={true}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            formik={formik}
            // disable={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            label="First Name"
            type="text"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.firstName}
            name="firstName"
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            label="Last Name"
            type="text"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.lastName}
            name="lastName"
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            label="MI"
            type="text"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.mi}
            name="mi"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomField
            label="Title"
            type="text"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.title}
            name="title"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomField
            type="email"
            label="Email"
            value={formik.values.email}
            name="email"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {/* <CustomField
            type="password"
            label="Password"
            value={formik.values.password}
            name="password"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          /> */}
             <Typography
                sx={{  
                  fontSize: '1.4rem',
                  fontWeight:'bold',
                  fontFamily: 'Inter, sans-serif',
                  color: theme.palette.Labeltext.primary
                }}
               
              >
                Password
              </Typography>
          <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="passwordField"
                  id="outlined"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  style={{
                    border: formik.errors.password && formik.touched.password ? '2px solid #e84a3f' : '1px solid #6A6A6A',
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  // onClick={handleClick}
                  onClick={() => handleVisibilityToggle("password")} 
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
             
        </Grid>
        <Grid item md={6} xs={12}>
          {/* <CustomField
            type="password"
            label="Confirm Password"
            value={formik.values.confirmPassword}
            name="confirmPassword"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            error={formik.errors}
            touched={formik.touched}
            isRequired={true}
          /> */}
              <Typography
                sx={{  
                  fontSize: '1.4rem',
                  fontWeight:'bold',
                  fontFamily: 'Inter, sans-serif',
                  color: theme.palette.Labeltext.primary
                }}
               
              >
               Confirm Password
              </Typography>
          <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className="passwordField"
                  id="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  style={{
                    border: formik.errors.confirmPassword && formik.touched.confirmPassword ? '2px solid #e84a3f' : '1px solid #6A6A6A',
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  // onClick={handleClick}
                  onClick={() => handleVisibilityToggle("confirmPassword")} 
                >
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="text"
            label="Phone 1"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.phone1}
            name="phone1"
            mask="(999) 999-9999"
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <CustomField
            value={formik.values.ext1}
            name="ext1"
            label="Ext"
            type="number"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="text"
            label="Phone 2"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.phone2}
            name="phone2"
            mask="(999) 999-9999"
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <CustomField
            value={formik.values.ext2}
            name="ext2"
            label="Ext"
            type="number"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
      {/* practice modal here */}
      <CustomModal
        open={openPracticeMod}
        handleClose={() => setOpenPracticeMod(false)}
      >
        <MultiSelectCustomPreview
          isModal={true}
          paginationDetail={getPracticeListData}
          // searchLabel="Search for practices by name, phone #, sequence #"
          rows={
            getPracticeListData?.result?.length > 0
              ? getPracticeListData?.result?.map((el) => ({
                  id: el?.practiceId,
                  ...el,
                }))
              : []
          }
          columns={modifiedCols}
          handleGetAll={getPracticeListAction}
          handleBySearch={getPracticeBySearchTermAction}
          setState={setPracticeSelectedRow}
          state={practiceSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              practiceId: el?.practiceId,
              practiceName: el?.practiceName,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              userPracticeDto: [...setRowData],
            }));
            setOpenPracticeMod(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default UserForm;
