import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { handleIsActive } from "../../../utils/customFunctions";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "./taxonomy/TaxonomyCategories";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import SearchNpi from "./npi/SearchNpi";
import { Done, ExpandMore } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton";
import CustomAddress from "../../../components/CustomAddress";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/CheckBox";
import CustomDialog from "../../../components/CustomDialog";
import OtherOfiice from "./OtherOfiice";

const PracticeInfo = ({ formik }) => {
  console.log(formik, "practice formik");
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openAddOfficeMod, setOpenAddOfficeMod] = useState(false);
  const [taxonomyListModal, setTaxonomyListModal] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [organizationType, setOrganizationType] = useState([]);
  const [taxonomyName, setTaxonomyName] = useState("");
  const [timeZoneOpts, setTimeZoneOpts] = useState([]);
  const [npiResultData, setNpiResultData] = useState([]);
  const [openNpiPaper, setOpenNpiPaper] = useState(false);
  const userId = localStorage.getItem("userId");
  // Define data fetching URLs
  const dataFetchUrls = {
    organizationType: `${path}/ct-organizationType`,
    timeZoneType: `${path}/CTTimeZone`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.organizationType, setOrganizationType);
    fetchDataOptions(dataFetchUrls.timeZoneType, setTimeZoneOpts);
  }, []);

  const handleSelectTaxonomy = (taxonomyCode) => {
    console.log(taxonomyCode, "taxCode");
    formik.setFieldValue("taxonomySpecialty", taxonomyCode);
  };

  const handleSelectNpi = (npiNum) => {
    formik.setFieldValue("practiceNpiId", npiNum.npiId);
    formik.setFieldValue("practiceNpi", npiNum.npiNo);
  };

  //   handle checkbox change
  const handleCheckboxChange = () => {
    formik.setFieldValue(
      "isPayToAddressSame",
      !formik.values.isPayToAddressSame
    );

    if (formik.values.isPayToAddressSame === true) {
      formik.setFieldValue("payToAddress", "");
      formik.setFieldValue("payToCityId", 0);
      formik.setFieldValue("payToCity", "");
      formik.setFieldValue("payToStateId", 0);
      formik.setFieldValue("payToState", "");
      formik.setFieldValue("payToZipCodeId", 0);
      formik.setFieldValue("payToZipCode", "");
    } else {
      formik.setFieldValue("payToAddress", formik.values.address);
      formik.setFieldValue("payToCityId", formik.values.cityId);
      formik.setFieldValue("payToCity", formik.values.city);
      formik.setFieldValue("payToState", formik.values.state);
      formik.setFieldValue("payToStateId", formik.values.stateId);
      formik.setFieldValue("payToZipCode", formik.values.zipCode);
      formik.setFieldValue("payToZipCodeId", formik.values.zipCodeId);
    }
  };

  // handle inactive
  const handleInactiveOffice = (ind) => {
    const updatedOffice = formik.values.practiceOffice?.map((office, i) => {
      if (i === ind) {
        return { ...office, isActive: !office.isActive };
      }
      return office;
    });

    formik.setFieldValue("practiceOffice", updatedOffice);
  };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/practice");
  };

  useEffect(() => {
    if (userId) {
      formik.setFieldValue("createdById", userId);
    }
  }, [userId]);

  // npi
  const handleNpiChange = async (e) => {
    const npiValue = e.target.value;
    formik.setFieldValue("practiceNpi", npiValue);
    if (formik.values.practiceNpi.length >= 4) {
      try {
        const response = await getData(
          `${path}/npi/searchNPI?npiNo=${npiValue}`
        );
        if (response.result.length > 0) {
          setNpiResultData(response.result);
          setOpenNpiPaper(true);
        }
      } catch (e) {
        console.log(e, ": Error in feting NPI");
        throw e;
      }
    } else {
      setOpenNpiPaper(false);
    }
  };
  return (
    <>
      <form id="practice-form" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: "15px",
            padding: "10px",
            flexWrap: "wrap",
          }}
        >
          <CustomButton
            isBlue={true}
            type="submit"
            formId="practice-form"
            isSave={true}
          >
            Save
          </CustomButton>

          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>

          <CustomButton
            isAdd={true}
            handleClick={() => setOpenAddOfficeMod(true)}
          >
            Add Other Office
          </CustomButton>

          {formik.values.sequenceNo && (
            <Box sx={{ marginLeft: "auto" }}>
              <CheckBox
                CheckedValue={!formik.values.isActive}
                handleChange={() =>
                  // formik.setFieldValue("isActive", !formik.values.isActive)
                  handleIsActive(formik)
                }
                label="Make this Practice inactive"
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            background: theme.palette.accordiansBodyBackground.primary,
            padding: "15px",
            margin: "0 0 20px",
            border: "1px solid #D3D3D3",
            borderRadius: "20px !important",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <CustomField
                label="Name"
                type="text"
                value={formik.values.practiceName}
                isRequired={true}
                touched={formik.touched}
                error={formik.errors}
                name="practiceName"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={8}>
              <CustomField
                label="Business Name As"
                type="text"
                value={formik.values.businessNameAs}
                name="businessNameAs"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={4}>
              <CustomSelectBox2
                value={formik.values.organizationTypeId}
                name="organizationTypeId"
                dropdownOptions={organizationType?.map((opt) => ({
                  value: opt.organizationTypeName,
                  id: opt.organizationTypeId,
                }))}
                label="Organization Type"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                showDefaultOpt={true}
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                type="text"
                label="Phone"
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                value={formik.values.phone}
                name="phone"
                mask="(999) 999-9999"
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                type="text"
                label="Fax"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values.fax}
                name="fax"
                mask="(999) 999-9999"
              />
            </Grid>
            <Grid item md={3}>
              <FormGroup
                sx={{
                  position: "relative",
                }}
              >
                <CustomSearchField
                  isRequired={true}
                  fieldVal={formik.values?.practiceNpi}
                  name="practiceNpi"
                  handleChange={handleNpiChange}
                  handleBlur={formik.handleBlur}
                  handleModalOpen={() => setSearchNpiModal(true)}
                  label="NPI"
                  formik={formik}
                  resetField={{
                    practiceNpi: null,
                  }}
                  isTypeAble={true}
                />
                {openNpiPaper && (
                  <Paper
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 111,
                      boxShadow: 3,
                      width: "100%",
                    }}
                  >
                    {npiResultData?.map((item) => (
                      <Stack margin="5px" width="100%" key={item?.npiId}>
                        <CustomButton
                          isBlue={false}
                          handleClick={() => {
                            formik.setFieldValue("practiceNpi", item?.npiNo);
                            setOpenNpiPaper(false);
                          }}
                        >
                          <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                          {item?.npiNo}
                        </CustomButton>
                      </Stack>
                    ))}
                  </Paper>
                )}
              </FormGroup>
            </Grid>
            <Grid item md={3}>
              <CustomSearchField
                fieldVal={formik.values.taxonomySpecialty}
                isRequired={true}
                formik={formik}
                resetField={{
                  taxonomySpecialty: "",
                }}
                name="taxonomySpecialty"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label="Taxonomy"
                handleModalOpen={() => setTaxonomyListModal(true)}
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                disable={true}
                type="text"
                label={"Sequence #"}
                value={formik.values.sequenceNo || null}
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                label="Refrence#"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                type="number"
                value={formik.values.referenceNo}
                name="referenceNo"
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                type="text"
                label="TCN Prefix"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values.tcnPrefix}
                name="tcnPrefix"
              />
            </Grid>
            <Grid item md={3}>
              <CustomField
                type="text"
                label="Code"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values.code}
                name="code"
              />
            </Grid>
          </Grid>
        </Box>

        {/* <CustomAccordion defaultExpanded={true} heading={"Primary Office"}>
        <Box
          display="grid"
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(1, minmax(0, 1fr))",
            },
          }}
        >
          <TextArea
            placeholder="Address"
            value={formik.values.primaryAddress}
            isRequired={true}
            formik={formik}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="primaryAddress"
            minRows={"5"}
          />
        </Box>

        <CustomAddress
          formik={formik}
          fieldValues={{
            countryId: formik.values.primaryCountryId,
            country: formik.values.primaryCountry,
            countryName: "primaryCountry",
            stateId: "primaryStateId",
            stateName: "primaryState",
            state: formik.values.primaryState,
            cityId: "primaryCityId",
            cityName: "primaryCity",
            city: formik.values.primaryCity,
            zipCode: formik.values.primaryZipCode,
            zipCodeId: "primaryZipCodeId",
            zipName: "primaryZipCode",
          }}
        />

        <Box
          display="grid"
          margin={"20px 0"}
          gap="10px"
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(2, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <CustomField
            type="number"
            label="Phone"
            handleBlur={formik.handleBlur}
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
            handleChange={formik.handleChange}
            value={formik.values.primaryPhone}
            name="primaryPhone"
          />

          <CustomField
            type="text"
            label="Fax"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            value={formik.values.primaryFax}
            name="primaryFax"
          />
        </Box>
      </CustomAccordion> */}
        {/* Address */}

        <Box
          sx={{
            margin: "0 0 20px",
            border: "1px solid #D3D3D3",
            borderRadius: "10px !important",
            background: theme.palette.accordiansBodyBackground.primary,
          }}
        >
          {/* <Grid item md={12}> */}
          <Typography
            component={"h2"}
            sx={{
              background: theme.palette.sectionHeadingBg.primary,
              padding: "8px 10px",
              borderRadius: "10px",
              fontWeight: "600",
            }}
          >
            Address
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              padding: " 15px",
            }}
          >
            <Grid item md={9}>
              <CustomField
                type="text"
                label={"Address"}
                value={formik.values.address}
                name="address"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                isRequired={true}
                touched={formik.touched}
                error={formik.errors}
              />
            </Grid>

            <Grid item md={3}>
              <CustomSelectBox2
                value={formik.values.timeZoneId}
                name="timeZoneId"
                dropdownOptions={timeZoneOpts?.map((opt) => ({
                  value: opt.timeZoneName,
                  id: opt.timeZoneId,
                }))}
                label="Time Zone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                showDefaultOpt={true}
              />
            </Grid>

            <CustomAddress
              formik={formik}
              fieldValues={{
                countryId: formik.values.countryId,
                country: formik.values.country,
                countryName: "country",
                stateId: "stateId",
                stateName: "state",
                state: formik.values.state,
                cityId: "cityId",
                cityName: "city",
                city: formik.values.city,
                zipCode: formik.values.zipCode,
                zipCodeId: "zipCodeId",
                zipName: "zipCode",
              }}
              isRequired={true}
            />

            <Grid item md={12}>
              <CheckBox
                label={
                  "Pay-To address is the same as the primary office address"
                }
                checked={formik.values.isPayToAddressSame}
                CheckedValue={formik.values.isPayToAddressSame}
                handleChange={handleCheckboxChange}
              />
            </Grid>
            {!formik.values.isPayToAddressSame && (
              <>
                <Grid item md={12}>
                  <Typography component="h3" fontWeight={"600"}>
                    {" "}
                    Pay-to Address
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <CustomField
                    label="Address"
                    value={formik.values.payToAddress}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    name="payToAddress"
                    isRequired={true}
                    touched={formik.touched}
                    error={formik.errors}
                  />
                </Grid>
                <CustomAddress
                  formik={formik}
                  fieldValues={{
                    countryId: formik.values.payToCountryId,
                    country: formik.values.payToCountry,
                    countryName: "payToCountry",
                    stateId: "payToStateId",
                    stateName: "payToState",
                    state: formik.values.payToState,
                    cityId: "payToCityId",
                    cityName: "payToCity",
                    city: formik.values.payToCity,
                    zipCode: formik.values.payToZipCode,
                    zipCodeId: "payToZipCodeId",
                    zipName: "payToZipCode",
                  }}
                  isRequired={true}
                />
              </>
            )}
          </Grid>
        </Box>

        {formik.values.practiceOffice.length > 0 && (
          <Box
            sx={{
              margin: "0 0 20px",
              border: "1px solid #D3D3D3",
              borderRadius: "10px !important",
              background: theme.palette.accordiansBodyBackground.primary,
            }}
          >
            <Typography
              component={"h2"}
              sx={{
                background: theme.palette.sectionHeadingBg.primary,
                padding: "8px 10px",
                borderRadius: "10px",
                fontWeight: "600",
              }}
            >
              Other Offices
            </Typography>
            <Grid container spacing={2} padding={"25px"}>
              {formik.values.practiceOffice.map((item, i) => (
                <Grid item md={4} key={i}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      border: "2px solid grey",
                      padding: "10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      background: theme.palette.background.default,
                    }}
                  >
                    <Stack width={"80%"}>
                      <Typography component={"h3"}>
                        {item?.practiceOfficeName}
                      </Typography>
                      <Typography component={"h5"} fontWeight={500}>
                        {item?.address}, {item?.city}, {item?.state}{" "}
                        {item?.zipCode}
                      </Typography>
                    </Stack>

                    <Stack>
                      <CustomButton
                        variant={"text"}
                        handleClick={() => handleInactiveOffice(i)}
                      >
                        {item?.isActive ? "Inactive" : "Reactive"}
                      </CustomButton>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* modals here */}
        <CustomModal
          open={taxonomyListModal}
          handleClose={() => setTaxonomyListModal(false)}
          width={"50%"}
        >
          <TaxonomyCategories
            handleSelectTaxonomy={handleSelectTaxonomy}
            setTaxonomyName={setTaxonomyName}
            handleClose={() => setTaxonomyListModal(false)}
          />
        </CustomModal>
        {/* npi modal */}
        <CustomModal
          open={searchNpiModal}
          handleClose={() => setSearchNpiModal(false)}
          heading={"NPI Search"}
          width={"60%"}
        >
          <SearchNpi
            handleSelectNpi={handleSelectNpi}
            handleClose={() => setSearchNpiModal(false)}
            setSearchNpiModal={setSearchNpiModal}
          />
        </CustomModal>
        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />

        {/* Other offices */}
        <CustomModal
          height={"auto"}
          heading={"Add Office"}
          open={openAddOfficeMod}
          handleClose={() => setOpenAddOfficeMod(false)}
        >
          <OtherOfiice
            formik={formik}
            handleClose={() => setOpenAddOfficeMod(false)}
          />
        </CustomModal>
      </form>
    </>
  );
};

export default PracticeInfo;
