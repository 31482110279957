import { Box, Stack } from "@mui/material";
import React from "react";
import CustomPreview from "../../../layout/CustomPreview";
import { chargePanelCols } from "../../../utils/gridCols";
import { useSelector } from "react-redux";
import { getChargePanelAction } from "../../../features/actions/chargePanelAction";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const ChargePanel = () => {
  const navigate = useNavigate();
  const {getChargePanelData, loading } = useSelector((state) => state.chargePanel);

  const rows =
  getChargePanelData?.result && getChargePanelData.result?.chargePanel?.length > 0
    ? getChargePanelData.result?.chargePanel?.map((row) => ({
      id: row?.chargerPanelId,
        ...row,
      }))
    : [];

  return (
    <Box>
      <CustomPreview
        columns={chargePanelCols}
        rows={rows}
        handleGetAll={getChargePanelAction}
        paginationDetail={getChargePanelData}
        moduleHeading="Charge Panels"
        loading={loading}
        searchLabel="Search for charge by title or code"
        handleCell={(param) => navigate(`/charge-panel/update/${param.id}`)}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomButton
            isBlue={true}
            isAdd={true}
            handleClick={() => navigate("/charge-panel/new")}
          >
            Add Panel
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default ChargePanel;
