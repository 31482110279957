import React, { useEffect } from "react";
import { Box,Typography,Stack } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getPracticeAction,
  getPracticeByIdAction,
  getPracticeListAction,
  updatePracticeAction,
} from "../../../features/actions/practiceAction";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import PracticeInfo from "./PracticeInfo";
import MainHeading from "../../../components/MainHeading";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";
import OtherOfiice from "./OtherOfiice";
import CustomDialog from "../../../components/CustomDialog";
import CustomModal from "../../../components/CustomModal";
import { toast } from "react-toastify";
import { useState } from "react";


const UpdatePractice = () => {
  let flag = false
  const [openAddOfficeMod, setOpenAddOfficeMod] = useState(false)
  const dispatch = useDispatch();
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/practice");
  //   }
  // };
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("idscheck",id)
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { practiceById, loading } = useSelector((state) => state.practices);
  const findPractice = practiceById?.result;

  const formik = useFormik({
    initialValues: findPractice || practiceInitVals,
    validationSchema: practiceSchema,
    enableReinitialize: true,
    onSubmit:  (values, action) => {
      try {
        if(!flag){
          flag = true
          dispatch(updatePracticeAction({
            practiceId: findPractice?.practiceId,
            ...values,
          }))
          dispatch(getPracticeListAction())
        }
        toast.success("Practice update successfully");
        navigate("/practice");
      } catch (error) {
        toast.error("Error creating Practice:", error);
      }
    },
  });

  useEffect(() => {
    dispatch(getPracticeByIdAction(id));
  }, [dispatch]);
  return (
    <>
          <PracticeInfo formik={formik} />
    </>
  );
};

export default UpdatePractice;
