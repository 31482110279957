// import { Box, Grid,Typography } from "@mui/material";
// import { useState } from "react";
// import React from "react";
// import MainHeading from "../../../components/MainHeading";
// import CustomButton from "../../../components/CustomButton";
// import RevenueInfo from "./RevenueInfo";
// import { useFormik } from "formik";
// import { revenueInitVals } from "../../../utils/formikInitValues";
// import { useDispatch } from "react-redux";
// import { newRevenueAction } from "../../../features/actions/revenueAction";
// import { useNavigate } from "react-router-dom";
// import { revenueSchema } from "../../../schemas/patientSchema";
// import CustomDialog from "../../../components/CustomDialog";

// const NewRevenue = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const formik = useFormik({
//     initialValues: revenueInitVals,
//     validationSchema: revenueSchema,
//     onSubmit: (values) => {
//       console.log(values, "revenueValues");
//       try {
//         dispatch(newRevenueAction([values]));
//       } catch (err) {
//         console.log(err, "Error in creating error:");
//         throw err;
//       }
//       navigate("/revenues");
//     },
//   });

//   // handle cancel
//   // const handleCancel = () => {
//   //   const conform = window.confirm("Are you sure you want to cancel?");
//   //   if (conform) {
//   //     formik.resetForm();
//   //     navigate("/revenues");
//   //   }
//   // };
//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/revenues");
//   };
//   return (
//     <>
//       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
//         <Grid container>
//           <Grid item md={12}>
//             {/* <MainHeading mainheading={"Add new Revenue Code"} /> */}
//           </Grid>

//           <Grid item md={12}>
//             <CustomButton
//               formId="revenueForm"
//               type="submit"
//               isBlue={true}
//               isSave={true}
//             >
//               Save
//             </CustomButton>
//             {/* <CustomButton
//               handleClick={handleCancel}
//               isBlue={false}
//               margin={"5px"}
//               isCancel={true}
//             >
//               Cancel
//             </CustomButton> */}
//                <CustomButton isCancel={true} handleClick={handleCancel}>
//             Cancel
//           </CustomButton>
//           </Grid>
//         </Grid>
//         <form id="revenueForm" onSubmit={formik.handleSubmit}>
//           <RevenueInfo formik={formik} />
//         </form>
//       </Box>
//       <CustomDialog
//      open={openCancelDialog}
//      isWarning={true}
//      handleClose={() => setOpenCancelDialog(false)}
//      handleConfirm={handleConfirmCancel}
//      handleCancel={() => setOpenCancelDialog(false)}
//      confirmButtonTitle="OK"
//      cancelButtonTitle="Cancel"
//     >
//      <Typography component="h3">Are you sure you want to cancel?</Typography>
//     </CustomDialog>
//     </>
//   );
// };

// export default NewRevenue;



import { Box, Grid,Typography } from "@mui/material";
import { useState } from "react";
import React from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import RevenueInfo from "./RevenueInfo";
import { useFormik } from "formik";
import { revenueInitVals } from "../../../utils/formikInitValues";
import { useDispatch } from "react-redux";
import { newRevenueAction } from "../../../features/actions/revenueAction";
import { useNavigate } from "react-router-dom";
import { revenueSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const NewRevenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const formik = useFormik({
    initialValues: revenueInitVals,
    validationSchema: revenueSchema,
    onSubmit: (values) => {
      console.log(values, "revenueValues");
      try {
        dispatch(newRevenueAction([values]));
      } catch (err) {
        console.log(err, "Error in creating error:");
        throw err;
      }
      navigate("/revenues");
    },
  });


  return (
    <>
          <RevenueInfo formik={formik} />
    </>
  );
};

export default NewRevenue;
