import {
  Box,
  FormControl,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { useFormik } from "formik";
// import CustomField from "../../../components/CustomField";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
// import CustomModal from "../../../components/CustomModal";
import SearchedTable from "./SearchedTable";
import Loading from "../../../components/Loading";

const ARControl = () => {
  const [arFilterOpt, setArFilterOpt] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchedArData, setSearchedArData] = useState([]);
  const [isDataFound, setIsDataFound] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [openLoaderModal, setOpenLoaderModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fetchFilterData = async () => {
    try {
      const response = await getData(`${path}/arControl/Filters`);
      if (response) {
        setFilterData(response.result);
      } else {
        setFilterData([]);
      }
      setLoading(false);
    } catch (err) {
      console.log("filter data check error:", err);
      throw err;
    }
  };

  //   formik work here
  let initialValues = {
    filterTypeId: 1,
    accountNo: null,
    otherFilters: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      try {
        const sendQuery = objectToQueryString(values);
        console.log(sendQuery, "sendQuery");

        const response = await getData(sendQuery);
        if (response) {
          formik.setValues({
            ...initialValues,
          });
          setSearchedArData(response.result);
          setIsDataFound(true);
        }
      } catch (error) {
        console.log("Ar Search Error: " + error);
        throw error;
      }
    },
  });
  // convert object to query string
  const objectToQueryString = (objValues) => {
    console.log("objValues", objValues.otherFilters);
    const filterParams = objValues?.otherFilters?.map((v) => {
      if (formik.values.filterTypeId === 1) {
        return `filterKey=${v.filterKey}&arFilterId=${v.arFilterId}${
          v.value1 !== ("" || 0) ? `&value1=${v.value1}` : ""
        }${v.value2 !== ("" || 0) ? `&value2=${v.value2}` : ""}`;
      } else {
        let value1 = Math.round(
          (new Date().getTime() - new Date(v?.value1).getTime()) /
            (1000 * 3600 * 24)
        );

        let value2 =
          v?.value2 !== "" && v?.value2 !== 0
            ? Math.round(
                (new Date().getTime() - new Date(v?.value2).getTime()) /
                  (1000 * 3600 * 24)
              )
            : "";
        return `filterKey=${v.filterKey}&arFilterId=${v.arFilterId}${
          value1 !== "" ? `&value1=${value1}` : ""
        }${value2 !== "" ? `&value2=${value2}` : ""}`;
      }
    });
    const queryString = filterParams ? filterParams.join("&") : "";
    let url = `${path}/arControl/filterData?filterTypeId=${
      objValues?.filterTypeId
    }&${
      objValues?.accountNo !== null ? `&accountNo=${objValues?.accountNo}` : ""
    }${queryString}`;
    return url;
  };

  const dataFetchUrl = {
    arFilters: `${path}/ct-arFilters`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.log("Fetching Dropdown Option Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrl.arFilters, setArFilterOpt);
  }, []);
  useEffect(() => {
    fetchFilterData();
  }, [setFilterData]);

  //   handle select change
  const handleSelectChange = (event, field) => {
    const selectedValue = Number(event.target.value);
    let previousValue = formik.values.otherFilters;
    let findIndex = previousValue.findIndex(
      (value) => value?.filterKey === field?.filterKey
    );

    if (findIndex === -1) {
      previousValue.push({
        filterKey: field.filterKey,
        arFilterId: selectedValue,
        value1: 0,
        value2: 0,
      });
    } else {
      previousValue.splice(findIndex, 1);
      if (selectedValue !== 1) {
        previousValue.push({
          filterKey: field.filterKey,
          arFilterId: selectedValue,
          value1: 0,
          value2: 0,
        });
      }
    }
    formik.setFieldValue("otherFilters", previousValue);
  };

  const handleChangeValue = (field, type, event) => {
    let previousValue = formik.values.otherFilters;
    let findIndex = previousValue.findIndex(
      (value) => value?.filterKey === field?.filterKey
    );
    let inputValue =
      formik.values.filterTypeId === 2
        ? event.target.value.split("-").join("/")
        : event.target;
    console.log("event.target", inputValue);
    if (findIndex !== -1) {
      previousValue[findIndex][type] = event.target.value;
      formik.setFieldValue("otherFilters", previousValue);
    }
  };

  const theme = useTheme();
  let patientBalance = {
    filterId: 1,
    filterName: "Patient Balance",
    filterKey: "patientBalance",
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box margin="20px" paddingBottom="10px">
          {/* <MainHeading mainheading={"A/R Control"} /> */}
          {isDataFound ? (
            <SearchedTable
              searchedData={searchedArData}
              handleClose={() => {
                formik.resetForm();
                setIsDataFound(false);
              }}
            />
          ) : (
            <>
              <Stack direction="row" justifyContent="right" marginTop="15px">
                <CustomButton
                  isBlue={true}
                  type="submit"
                  formId="ar-form"
                  isSearch={true}
                >
                  Search
                </CustomButton>
              </Stack>
              <Box sx={{background:theme.palette.accordiansBodyBackground.primary,borderRadius:"10px",padding:"15px",margin:"20px 0 20px"}}>
              <form id="ar-form" onSubmit={formik.handleSubmit}>
                <Box>
                  <Typography component="h1" fontWeight={700}>
                    Date Search Option
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "grid",
                      gap: "30px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(1, minmax(0, 1fr))",
                        md: "repeat(1, minmax(0, 1fr))",
                      },
                    // background:theme.palette.accordiansBodyBackground.primary
                    }}
                  >
                    <Stack direction="row" alignItems="flex-end" gap="15px">
                      <FormControl sx={{ width: "300px" }}>
                        <label
                          className="customLabel"
                          style={{
                            color: theme.palette.Labeltext.primary,
                          }}
                        >
                          Patient Balance
                          {/* {field.filterName} */}
                        </label>
                        <Select
                          sx={{
                            "& fieldset": {
                              border: "none",
                            },
                            "& .MuiNativeSelect-icon": {
                              fontSize: "35px !important",
                            },
                            // background:
                            //   theme.palette.accordiansBackground.primary,
                            // color: theme.palette.textMainHeading.primary,
                          }}
                          className="customSelectBox"
                          style={{
                            background: theme.palette.customFieldsBackground.primary,
                            border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                            color: theme.palette.fieldsText.primary,
                          }}
                          value={
                            formik.values?.otherFilters.find(
                              (v) => v.filterKey === "patientBalance"
                            )?.arFilterId || 1
                          }
                          type="text"
                          onChange={(event) =>
                            handleSelectChange(event, patientBalance)
                          }
                          native
                        >
                          {arFilterOpt.map((opt) => (
                            <>
                              <option
                                style={{
                                  background: theme.palette.fieldsDDown.primary,
                                }}
                                key={opt.arFilterId}
                                value={opt.arFilterId}
                              >
                                {opt.arFilterName}
                              </option>
                            </>
                          ))}
                        </Select>
                      </FormControl>
                      {
                        <Stack direction="row" alignItems="center" gap="15px">
                          {formik.values.otherFilters.find(
                            (v) => v?.filterKey === "patientBalance"
                          )?.arFilterId === 2 ||
                          formik.values.otherFilters.find(
                            (v) => v?.filterKey === "patientBalance"
                          )?.arFilterId === 3 ||
                          formik.values.otherFilters.find(
                            (v) => v?.filterKey === "patientBalance"
                          )?.arFilterId === 4 ? (
                            <>
                              <input
                                type="number"   
                      className="customField"
                                style={{
                                  background: theme.palette.customFieldsBackground.primary,
                                  color: theme.palette.fieldsText.primary,
                                  border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                }}
                                onChange={(e) =>
                                  handleChangeValue(patientBalance, "value1", e)
                                }
                                min={0}
                                value={
                                  formik.values?.otherFilters?.find(
                                    (v) => v?.filterKey === "patientBalance"
                                  )?.value1
                                }
                              />
                            </>
                          ) : formik.values.otherFilters.find(
                              (v) => v?.filterKey === "patientBalance"
                            )?.arFilterId === 5 ? (
                            <>
                              <input
                                type="number"
                                className="customField"
                                style={{
                                  background: theme.palette.customFieldsBackground.primary,
                                  color: theme.palette.fieldsText.primary,
                                  border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                }}
                                onChange={(e) =>
                                  handleChangeValue(patientBalance, "value1", e)
                                }
                                min={0}
                                value={
                                  formik.values?.otherFilters?.find(
                                    (v) => v?.filterKey === "patientBalance"
                                  )?.value1
                                }
                              />
                              <input
                                type="number"
                                className="customField"
                                style={{
                                  background: theme.palette.customFieldsBackground.primary,
                                  color: theme.palette.fieldsText.primary,
                                  border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                }}
                                onChange={(e) =>
                                  handleChangeValue(patientBalance, "value2", e)
                                }
                                min={0}
                                value={
                                  formik.values?.otherFilters?.find(
                                    (v) => v?.filterKey === "patientBalance"
                                  )?.value2
                                }
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>
                      }
                    </Stack>
                  </Box>

                  {/* <Stack direction="row" alignItems="center" marginTop="25px">
                    <Typography variant="h2" component="h3">
                      Filter Search by:
                    </Typography>

                    <Tabs
                      sx={{
                        padding: "0",
                        marginLeft: "15px",
                        minHeight: "1rem !important",
                        // order: { xs: 2, sm: 2, md: 1 },
                      }}
                      value={formik.values.filterTypeId}
                      onChange={(e, v) => {
                        formik.setFieldValue("filterTypeId", v);
                        formik.setFieldValue("otherFilters", []);
                      }}
                      indicatorColor="transparent"
                    >
                      {" "}
                      <Tab
                        style={{
                          borderRadius: "10px 0 0 10px",
                          background: "#b8b4b4",
                        }}
                        className="paymenttabstwo"
                        label="By # of days"
                        value={1}
                      />
                      <Tab
                        style={{
                          background: "#b8b4b4",
                          borderRadius: "0 10px 10px 0",
                        }}
                        className="paymenttabstwo"
                        label="By date range"
                        value={2}
                      />{" "}
                    </Tabs>
                  </Stack> */}

                  <Stack
                    direction={{ xs: "column", sm: "column", md: "row" }} // XS and SM pe "column", MD and up pe "row"
                    // alignItems="center"
                    spacing={1}
                    marginTop="25px"
                  >
                    <Typography variant="h2" component="h3">
                      Filter Search By:
                    </Typography>

                    <Tabs
                      sx={{
                        padding: "0",
                        marginLeft: { md: "15px" },
                        minHeight: "1rem !important",
                      }}
                      value={formik.values.filterTypeId}
                      onChange={(e, v) => {
                        formik.setFieldValue("filterTypeId", v);
                        formik.setFieldValue("otherFilters", []);
                      }}
                      indicatorColor="transparent"
                    >
                      <Tab
                        style={{
                          borderRadius: "10px 0 0 10px",
                          background: "#b8b4b4",
                        }}
                        className="paymenttabstwo"
                        label="By # of days"
                        value={1}
                      />
                      <Tab
                        style={{
                          background: "#b8b4b4",
                          borderRadius: "0 10px 10px 0",
                        }}
                        className="paymenttabstwo"
                        label="By date range"
                        value={2}
                      />
                    </Tabs>
                  </Stack>

                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "grid",
                      gap: "30px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(2, minmax(0, 300px))",
                        md: "repeat(4, minmax(0, 1fr))",
                      },
                    }}
                  >
                    {filterData.map((field) => (
                      <>
                        {field.filterId > 1 && (
                          <div key={field.filterId}>
                            <FormControl fullWidth>
                              <label
                                className="customLabel"
                                style={{
                                  color: theme.palette.Labeltext.primary,
                                }}
                              >
                                {field.filterName}
                              </label>
                              <Select
                                sx={{
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "& .MuiNativeSelect-icon": {
                                    fontSize: "35px !important",
                                  },
                                  // background:
                                  //   theme.palette.accordiansBackground.primary,
                                  // color: theme.palette.textMainHeading.primary,
                                }}
                                className="customSelectBox"
                                style={{
                                  background: theme.palette.customFieldsBackground.primary,
                                  border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                  color: theme.palette.fieldsText.primary,
                                }}
                                value={
                                  formik.values?.otherFilters.find(
                                    (v) => v.filterKey === field.filterKey
                                  )?.arFilterId || 1
                                }
                                type="text"
                                onChange={(event) =>
                                  handleSelectChange(event, field)
                                }
                                // onBlur={handleBlur}
                                native
                              >
                                {arFilterOpt.map((opt) => (
                                  <>
                                    <option
                                      style={{
                                        background:
                                          theme.palette.fieldsDDown.primary,
                                      }}
                                      key={opt.arFilterId}
                                      value={opt.arFilterId}
                                    >
                                      {opt.arFilterName}
                                    </option>
                                  </>
                                ))}
                              </Select>
                            </FormControl>
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              gap="5px"
                              marginTop={"9px"}
                            >
                              {formik.values.filterTypeId === 1 ? (
                                formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 2 ||
                                formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 3 ||
                                formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 4 ? (
                                  <>
                                    <input
                                      type="number"
                                      className="customField"
                                      style={{
                                        background: theme.palette.customFieldsBackground.primary,
                                        color: theme.palette.fieldsText.primary,
                                        border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                      }}
                                      onChange={(e) =>
                                        handleChangeValue(field, "value1", e)
                                      }
                                      min={0}
                                      value={
                                        formik.values?.otherFilters?.find(
                                          (v) =>
                                            v?.filterKey === field?.filterKey
                                        )?.value1
                                      }
                                    />
                                  </>
                                ) : formik.values.otherFilters.find(
                                    (v) => v?.filterKey === field?.filterKey
                                  )?.arFilterId === 5 ? (
                                  <>
                                    <input
                                      type="number"
                                      className="customField"
                                      style={{
                                        background: theme.palette.customFieldsBackground.primary,
                                        color: theme.palette.fieldsText.primary,
                                        border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                      }}
                                      onChange={(e) =>
                                        handleChangeValue(field, "value1", e)
                                      }
                                      min={0}
                                      value={
                                        formik.values?.otherFilters?.find(
                                          (v) =>
                                            v?.filterKey === field?.filterKey
                                        )?.value1
                                      }
                                    />
                                    <input
                                      type="number"
                                      className="customField"
                                style={{
                                  background: theme.palette.customFieldsBackground.primary,
                                  color: theme.palette.fieldsText.primary,
                                  border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                }}
                                      onChange={(e) =>
                                        handleChangeValue(field, "value2", e)
                                      }
                                      min={0}
                                      value={
                                        formik.values?.otherFilters?.find(
                                          (v) =>
                                            v?.filterKey === field?.filterKey
                                        )?.value2
                                      }
                                    />
                                  </>
                                ) : (
                                  ""
                                )
                              ) : formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 2 ||
                                formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 3 ||
                                formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 4 ? (
                                <>
                                  <input
                                    type="date"
                                    className="customField"
                                    style={{
                                      background: theme.palette.customFieldsBackground.primary,
                                      color: theme.palette.fieldsText.primary,
                                      border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                    }}
                                    onChange={(e) =>
                                      handleChangeValue(field, "value1", e)
                                    }
                                    value={
                                      formik.values?.otherFilters?.find(
                                        (v) => v?.filterKey === field?.filterKey
                                      )?.value1
                                    }
                                  />
                                </>
                              ) : formik.values.otherFilters.find(
                                  (v) => v?.filterKey === field?.filterKey
                                )?.arFilterId === 5 ? (
                                <>
                                  <input
                                    type="date"
                                    className="customField"
                                    style={{
                                      background: theme.palette.customFieldsBackground.primary,
                                      color: theme.palette.fieldsText.primary,
                                      border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                    }}
                                    onChange={(e) =>
                                      handleChangeValue(field, "value1", e)
                                    }
                                    value={
                                      formik.values?.otherFilters?.find(
                                        (v) => v?.filterKey === field?.filterKey
                                      )?.value1
                                    }
                                  />
                                  <input
                                    type="date"
                                    className="customField"
                                    style={{
                                      background: theme.palette.customFieldsBackground.primary,
                                      color: theme.palette.fieldsText.primary,
                                      border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                                    }}
                                    onChange={(e) =>
                                      handleChangeValue(field, "value2", e)
                                    }
                                    value={
                                      formik.values?.otherFilters?.find(
                                        (v) => v?.filterKey === field?.filterKey
                                      )?.value2
                                    }
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </Stack>
                          </div>
                        )}
                      </>
                    ))}
                  </Box>
                </Box>
              </form>
              </Box>
            </>
          )}
        </Box>
      )}
      {/* <Box margin="20px" paddingBottom="10px">
        <MainHeading mainheading={"A/R Control"} />
        {isDataFound ? (
          <SearchedTable
            searchedData={searchedArData}
            handleClose={() => {
              formik.resetForm();
              setIsDataFound(false);
            }}
          />
        ) : (
          <>
            <Stack direction="row" justifyContent="right" marginTop="15px">
              <CustomButton
                isBlue={true}
                type="submit"
                formId="ar-form"
                isSearch={true}
              >
                Search
              </CustomButton>
            </Stack>
            <form id="ar-form" onSubmit={formik.handleSubmit}>
              <Box>
                <Typography component="h1" fontWeight={700}>
                  Date Search Option
                </Typography>

                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gap: "30px",
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(1, minmax(0, 1fr))",
                    },
                  }}
                >
                  <Stack direction="row" alignItems="flex-end" gap="15px">
                    <FormControl sx={{ width: "300px" }}>
                      <CustomSelectBox2
                        showDefaultOpt={true}
                        label="Patient Balance"
                        value={formik.values.patientBalanceArId}
                        formik={formik}
                        name="patientBalanceArId"
                        handleChange={formik.handleChange}
                        dropdownOptions={arFilterOpt.map((opt) => ({
                          id: opt.arFilterId,
                          value: opt.arFilterName,
                        }))}
                      />
                    </FormControl>
                    {formik.values.patientBalanceArId > 1 && (
                      <Stack direction="row" alignItems="center" gap="15px">
                        <CustomField
                          type="number"
                          isDecimal={true}
                          value={formik.values.patientBalanceval1}
                          name="patientBalanceval1"
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                        />
                        {formik.values.patientBalanceArId === 5 && (
                          <CustomField
                            type="number"
                            isDecimal={true}
                            value={formik.values.patientBalanceval2}
                            name="patientBalanceval2"
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                          />
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Box>

                <Stack direction="row" alignItems="center" marginTop="25px">
                  <Typography variant="h2" component="h3">
                    Filter Search by:
                  </Typography>

                  <Tabs
                    sx={{
                      padding: "0",
                      marginLeft: "15px",
                      minHeight: "1rem !important",
                      // order: { xs: 2, sm: 2, md: 1 },
                    }}
                    value={formik.values.filterTypeId}
                    onChange={(e, v) => {
                      formik.setFieldValue("filterTypeId", v);
                    }}
                    indicatorColor="transparent"
                  >
                    {" "}
                    <Tab
                      style={{
                        borderRadius: "10px 0 0 10px",
                        background: "#b8b4b4",
                      }}
                      className="paymenttabstwo"
                      label="By # of days"
                      value={1}
                    />
                    <Tab
                      style={{
                        background: "#b8b4b4",
                        borderRadius: "0 10px 10px 0",
                      }}
                      className="paymenttabstwo"
                      label="By date range"
                      value={2}
                    />{" "}
                  </Tabs>
                </Stack>

                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gap: "30px",
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(2, minmax(0, 300px))",
                      md: "repeat(4, minmax(0, 1fr))",
                    },
                  }}
                >
                  {filterData.map((field) => (
                    <>
                      {field.filterId > 1 && (
                        <div key={field.filterId}>
                          <FormControl fullWidth>
                            <label className="customLabel" style={{ color: theme.palette.Labeltext.primary, }}>
                              {field.filterName}
                           
                            </label>
                            <Select
                              sx={{
                                "& fieldset": {
                                  border: "none",
                                },
                                "& .MuiNativeSelect-icon": {
                                  fontSize: "35px !important",
                                },
                                background:theme.palette.accordiansBackground.primary, 
                                color:theme.palette.textMainHeading.primary
                              }}
                              className="customSelectBox"
                              value={formik.values[field.filterKey + "ArId"]}
                              name={field.filterKey + "Ard"}
                              type="text"
                              onChange={(event) =>
                                handleSelectChange(event, field)
                              }
                              // onBlur={handleBlur}
                              native
                            >
                              {arFilterOpt.map((opt) => (
                                <>
                                  <option
                                  style={{background:theme.palette.fieldsDDown.primary,}}
                                    key={opt.arFilterId}
                                    value={opt.arFilterId}
                                  >
                                    {opt.arFilterName}
                                  </option>
                                </>
                              ))}
                            </Select>
                          </FormControl>
                          {formik.values[field.filterKey + "ArId"] > 1 && (
                            <>
                              <Stack
                                // direction="row"
                                // alignItems="center"
                                // gap="15px"
                                sx={{
                                  display:
                                    !formik.values.filterTypeId === 1
                                      ? "none"
                                      : "block",
                                  // background: "#EDEDED",
                                  padding: "8px",
                                  borderBottomRightRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }}
                              >
                                {formik.values[field.filterKey + "ArId"] > 1 &&
                                formik.values[field.filterKey + "ArId"] ===
                                  5 ? (
                                  formik.values.filterTypeId === 1 ? (
                                    // value fields
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="12px"
                                    >
                                      <CustomField
                                        type="number"
                                        // label="Value1"
                                        value={
                                          formik.values[
                                            field.filterKey + "val1"
                                          ] || 0
                                        }
                                        name={field.filterKey + "val1"}
                                        handleChange={formik.handleChange}
                                      />
                                      <Typography component="h3">&</Typography>
                                      <CustomField
                                        type="number"
                                        // label="Value2"
                                        value={
                                          formik.values[
                                            field.filterKey + "val2"
                                          ] || 0
                                        }
                                        name={field.filterKey + "val2"}
                                        handleChange={formik.handleChange}
                                      />
                                    </Stack>
                                  ) : (
                                    // field.filterId > 1 && (
                                    // date fields
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="12px"
                                    >
                                      <input
                                        type="date"
                                        className="customDatePicker"
                                        onChange={formik.handleChange}
                                        value={
                                          formik.values[
                                            field.filterKey + "val1"
                                          ]
                                        }
                                        name={field.filterKey + "val1"}
                                      />
                                      <input
                                        type="date"
                                        className="customDatePicker"
                                        onChange={formik.handleChange}
                                        value={
                                          formik.values[
                                            field.filterKey + "val2"
                                          ]
                                        }
                                        name={field.filterKey + "val2"}
                                      />
                                    </Stack>
                                    // )
                                  )
                                ) : formik.values.filterTypeId === 1 ? (
                                  <>
                                    <FormControl fullWidth>
                                      <CustomField
                                        type="number"
                                        // label="Value1"
                                        value={
                                          formik.values[
                                            field.filterKey + "val1"
                                          ] || 0
                                        }
                                        name={field.filterKey + "val1"}
                                        handleChange={formik.handleChange}
                                      />
                                    </FormControl>
                                  </>
                                ) : (
                                  // field.filterId > 1 && (
                                  <FormControl fullWidth>
                                    <input
                                      type="date"
                                      className="customDatePicker"
                                      onChange={formik.handleChange}
                                      value={
                                        formik.values[field.filterKey + "val1"]
                                      }
                                      name={field.filterKey + "val1"}
                                    />
                                  </FormControl>
                                  // )
                                )}
                              </Stack>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </form>
          </>
        )}
      </Box> */}
    </>
  );
};

export default ARControl;
