import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData,postData,updateData  } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";



export const newIcdProcedureAction = createAsyncThunk(
    "create icd procedure",
    async (data) => {
      try {
        const response = await postData(`${path}/ICDProcedureCode`, data);
        if (response) {
          return response;
        } 
      } catch (error) {
        console.log(error, "check Error");
        throw new Error(error);
      }
    }
  );


  export const getICDProcedureCodeAction = createAsyncThunk(
    "get icd procedure",
    async (page) => {
      try {
        const response = await getData(`${path}/ICDProcedureCode?page=${page}&pageSize=10`);
        if (response) {
          return response;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );

  export const getICDProcedureByIdAction = createAsyncThunk(
    "getIcdProcedureById",
    async (id) => {
      try {
  
        const response = await getData( `${path}/ICDProcedureCode/${id}`);
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );
  export const getICDProcedureCodesBySearchTermAction = createAsyncThunk(
    "get icd procedure",
    async ({searchTerm,page}) => {
      try {
        const response = await getData(
          `${path}/ICDProcedureCode/SearchICDProcedure?searchValue=${searchTerm}&page=${page}&pageSize=10`
        );
        console.log(response, "action icd procedure codes by search term");
        if (response) {
          return response;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );
  
  // export const updateICDProcedureAction = createAsyncThunk(
  //   "updateICDProcedure",
  //   async (data) => {
  //     try {
  //       const response = await updateData(
  //         `${path}/ICDProcedureCode/${data.icdProcedureCodesId}`,
  //         data
  //       );
  //       console.log(response, "updateicd procedure action response");
  //       return response;
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
  // );

  export const updateICDProcedureAction = createAsyncThunk(
    "updateICDProcedure",
    async (data) => {
      try {
        const response = await updateData(
          `${path}/ICDProcedureCode/${data.icdProcedureCodesId}`,
          data
        );
        console.log(response, "update icd procedure action response");
        return response;
      } catch (error) {
        throw error;
      }
    }
  );
  