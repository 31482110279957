import * as React from "react";
import { useTheme, Box, InputLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./Custom-styling/CustomDatePicker.css";
import { getIn } from "formik";
const CustomMuiDatePicker = ({
  dateValue,
  handleDateChange,
  handleDateBlur,
  disable = false,
  dateLabel,
  formik,
  name,
  isRequired,
  isNested = false,
  isFromTo = false,
  dateBySelectPrev,
  currentDate = false,
}) => {
  const hasError =
    !disable &&
    formik &&
    getIn(formik.touched, name) &&
    getIn(formik.errors, name);
  const theme = useTheme();

  const value = dayjs(dateValue);

  // Format function for the selected date
  const formatDate = (date) => {
    if (date) {
      return dayjs(date).format("MM/DD/YYYY"); // Change the format as needed
    }
    return "";
  };
  const today = dayjs();
  const futureDate = today.add(20, "year");
  const oldDate = today.subtract(20, "year");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {/* Custom Label */}
        <label
          htmlFor={name}
          className="customLabel"
          style={{ color: theme.palette.Labeltext.primary }}
        >
          {dateLabel}
          {isRequired && (
            <span style={{ color: "red", fontSize: "1.5rem" }}> *</span>
          )}
        </label>

        {/* DatePicker */}
        <DatePicker
          format="MM/DD/YYYY"
          className="customDatePicker"
          name={name}
          disabled={disable}
          // value={dateValue}
          value={value.isValid() ? value : ""}
          onChange={(newValue) => {
            // Ensure the newValue is correctly formatted
            const formattedValue = newValue
              ? dayjs(newValue).format("MM/DD/YYYY")
              : "";
            // Update Formik field value
            handleDateChange(formattedValue);
            formik.setFieldValue(name, formattedValue);
          }}
          onBlur={handleDateBlur}
          maxDate={isFromTo ? futureDate : today}
          minDate={
            currentDate
              ? today
              : dateBySelectPrev
              ? dayjs(dateBySelectPrev)
              : isFromTo
              ? oldDate
              : undefined
          }
          sx={{
            "& .css-cwhad8-MuiDateCalendar-root": {
              width: "270px !important",
              fontSize: "1.4rem !important",
              color: "#000 !important",
            },
            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "4px 14px !important",
              fontSize: "1.6rem !important",
              //   color: "#000 !important",
            },
            "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: "7px",
              border: hasError
                ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                : `1px solid ${theme.palette.fieldsBorder.primary}`,
            },
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .css-i4bv87-MuiSvgIcon-root": {
              color: "#000 !important",
            },
          }}
          renderInput={(params) => (
            <TextField {...params} id={name} value={formatDate(dateValue)} />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomMuiDatePicker;
