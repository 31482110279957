
// making new modal
import { Box, Stack, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import InsuranceInfo from "./InsuranceInfo";
import { useFormik } from "formik";
import { insuranceInfoValues } from "../../utils/formikInitValues";
import PayerInfo from "./PayerInfo";
import { insuranceSchema } from "../../schemas/patientSchema";

const InsuredModalForm = ({
  handleAddInsurance,
  formik,
  editMode,
  openInsModal,
  editIndex,
  priorityId,
}) => {
  const insuranceFormik = useFormik({
    enableReinitialize: true,
    initialValues: editMode
      ? formik.values.insurancePoliciesDtos[editIndex]
      : insuranceInfoValues,
    validationSchema: insuranceSchema,
    onSubmit: (values) => {
      let newValue = {};
      if (values.insuredRelationShipToPatientId === 8) {
        newValue = {
          ...values,
          insuredFirstName: formik.values.firstName,
          insuredLastName: formik.values.lastName,
        };
      } else {
        newValue = { ...values };
      }
      const existingWithPriorityId = formik.values.insurancePoliciesDtos.filter(
        (policy) => policy.payerInfoPriorityId === values.payerInfoPriorityId
      );
      console.log(existingWithPriorityId, "existingWithPriorityId")

      // If no existing entry with the same `payerInfoPriorityId`, set `isDefault` to `true`
      if (existingWithPriorityId.length === 0) {
        newValue.isDefault = true;
      } else {
        newValue.isDefault = false;
      }
      handleAddInsurance(newValue);
    },
  });

  useEffect(() => {
    if (priorityId && !editMode) {
      console.log(priorityId);

      insuranceFormik.setFieldValue("payerInfoPriorityId", priorityId);
    } else {
      insuranceFormik.setFieldValue(
        "payerInfoPriorityId",
        insuranceFormik?.values?.payerInfoPriorityId
      );
    }
  }, [priorityId]);

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <form
            onSubmit={insuranceFormik.handleSubmit}
            id="insuranceForm"
            className="formContent"
          >
            <PayerInfo
              formik={formik}
              insuranceFormik={insuranceFormik}
              priorityId={priorityId}
            />
          </form>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            position: "fixed",
            bottom: 0,
            // left: 0,
            // textAlign: "right",
            right: 0,
            background: "#fff",
            // background:'red'
          }}
        >
          <CustomButton
            type="submit"
            isBlue={true}
            padding="5px 15px"
            margin="5px 5px"
            // handleClick={handleAddInsurance}
            formId="insuranceForm"
          >
            {editMode ? "Update" : "Add"}
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
};

export default InsuredModalForm;
