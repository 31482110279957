import React, { useCallback, useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import { useState } from "react";
// import ClaimTable from "../claim-dir/claim/ClaimTable";
// import PayerList from "../patient/PayerList";
import { Button, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSearchField from "../../components/CustomSearchField";
import CustomButton from "../../components/CustomButton";
import { useFormik } from "formik";
import { paymentInitVal3 } from "../../utils/formikInitValues";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectBox from "../../components/CustomSelectBox";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PostPayment from "../payment/PostPayment";
import { createPaymentAction } from "../../features/actions/PaymentAction";
// import MainHeading from "../../components/MainHeading";
import { useSpring, animated } from "react-spring";
import {
  paymentSchemaForInsurance,
  paymentSchemaForPatient,
} from "../../schemas/patientSchema";
import path from "../../config/apiUrl";
import CustomPreview from "../../layout/CustomPreview";
import {
  getClaimAction,
  getClaimBySearchTermAction,
} from "../../features/actions/claimAction";
import {
  claimCols,
  patientColsForPayment,
  payerCol,
} from "../../utils/gridCols";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../features/actions/payerAction";
// import { getData } from "../../config/axiosFunctions";
import {
  getPatientAction,
  getPatientBySearchTermAction,
} from "../../features/actions/createPatientAction";
import fingerTouchLogo from "../../assets/finger_touch.png";
import AccountSummary from "../../components/AccountSummary";
import { useLocation } from "react-router-dom";

const NewPayment = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openAccountSummary, setOpenAccountSummary] = useState(false);
  const [openPayerModal, setOpenPayerModal] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [showPostPay, setShowPostPay] = useState(false);
  const [applyEob, setApplyEob] = useState(false);
  const [creditCardOptions, setCreditCardOptions] = useState([]);
  // useSelectors
  const { getClaims, loading } = useSelector((state) => state.claim);
  const { getAllPayer } = useSelector((state) => state.payer);
  const payerLoading = useSelector((state) => state.payer.loading);
  const { getAllPatients } = useSelector((state) => state.patient);
  const patientLoading = useSelector((state) => state.patient.loading);
  const sumAppliedValue = () => {
    let total = 0;
    if (formik.values?.paymentClaimDto) {
      for (let i = 0; i < formik.values?.paymentClaimDto?.length; i++) {
        const element = formik.values?.paymentClaimDto[i];
        let totalofSingleClaim = element?.paymentDetailDto?.reduce(
          (sum, current) => sum + current?.allowed,
          0
        );
        total += totalofSingleClaim;
      }
    }
    return total;
  };

  //   formik logic here
  const formik = useFormik({
    initialValues: paymentInitVal3,
    onSubmit: (values) => {
      let totalAllowed = sumAppliedValue();
      if (totalAllowed > formik.values?.paymentAmount) {
        toast.info(
          `Amount is exceeded by ${formik.values.paymentAmount - totalAllowed}`
        );
        return;
      }
      dispatch(createPaymentAction(formik.values));
      formik.resetForm();
      setApplyEob(false);
      setShowPostPay(false);
    },
  });

  //   handling payer type
  const handlePaymentBy = async (selectedRow) => {
    console.log(selectedRow, "paymentSelectedRow");
    //  payment by insurance claim
    if (formik.values.isClaim && formik.values.paymentByTypeId === 2) {
      console.log(selectedRow, "paymentSelectedRow");
      // const response = await getData(
      //   `${path}/claim/${selectedRow?.claimNumber}`
      // );
      // const { result } = response;
      // console.log(result, "paymentSelectedRow");

      // if (result) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        paymentBy: `${selectedRow?.primaryPayerInsuranceName} (${selectedRow?.payerSequenceNo})`,
        paymentFromName: selectedRow?.primaryPayerInsuranceName,
        paymentFrom: selectedRow?.payerId,
        payerId: selectedRow?.payerId,
        payerSequenceNo: selectedRow?.payerSequenceNo,
        claimNumber: selectedRow?.claimNumber,
        paymentClaimDto: [
          // {
          //   claimId: result?.claimChargesDto[0].claimInfoId,
          //   patientFirstName: result?.patientFirstName,
          //   patientLastName: result?.patientLastName,
          //   patientAccountNo: result?.patientAccountNo,
          //   claimNumber: result?.claimNumber,
          //   claimChargesDto: result?.claimChargesDto,
          //   ClaimStatusId: null,
          //   PaymentActionId: null,
          //   TCNId: null,
          //   ClaimControlNo: null,
          //   paymentDetailDto: [],
          // },
        ],
      }));
      setOpenClaimModal(false);
      // }
    }
    /// payment by patient
    else if (formik.values.isClaim && formik.values.paymentByTypeId === 1) {
      console.log(selectedRow, "paymentSelectedRow");
      formik.setValues((prevValues) => ({
        ...prevValues,
        paymentBy: ` ${selectedRow?.patientName} (${selectedRow?.accountNo})`,
        accountNo: selectedRow?.accountNo,
        patientId: selectedRow?.patientId,
        paymentClaimDto: [],
      }));
      setOpenPatientModal(false);
    }

    /// payment by insurance payer
    else if (!formik.values.isClaim && formik.values.paymentByTypeId === 2) {
      if (selectedRow.payerName) {
        formik.setValues((prevValues) => ({
          ...prevValues,
          paymentBy: `${selectedRow.payerName} (${selectedRow.payerSequenceNo})`, // extra
          paymentFromName: selectedRow.payerName, //extra
          paymentFrom: selectedRow.id,
          payerId: selectedRow.id,
          payerSequenceNo: selectedRow.payerSequenceNo,
          paymentClaimDto: [],
        }));
        setOpenPayerModal(false);
      }
    }
  };

  const renderSourceOptions = () => {
    if (formik.values.paymentMethodId === 1) {
      return (
        <>
          <CustomField
            value={formik.values.checkNumber}
            type="text"
            label="Check/EFT #"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="checkNumber"
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 3) {
      return (
        <>
          <CustomSelectBox
            value={formik.values.creditCardTypeId || 5}
            name="creditCardTypeId"
            dropdownOptions={creditCardOptions?.map((opt) => ({
              value: opt?.creditCardType,
              id: opt?.creditCardTypeId,
            }))}
            formik={formik}
            label="Credit Card Type"
            //  handleChange={formik.handleChange}
            handleChange={(e) => {
              formik.setFieldValue("creditCardTypeId", Number(e.target.value));
            }}
            handleBlur={formik.handleBlur}
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 4) {
      return (
        <CustomField
          value={formik.values.checkNumber}
          type="text"
          label="Check/EFT #"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name="checkNumber"
        />
      );
    } else {
      return null;
    }
  };

  // apply eob handle
  const handleApplyEOB = () => {
    validatePayment(
      formik.values,
      formik.values.paymentByTypeId === 1
        ? paymentSchemaForPatient
        : paymentSchemaForInsurance
    ).then((error) => {
      let toastError;
      if (error) {
        const { checkNumber, ...remaining } = error;
        toastError =
          formik.values.paymentMethodId !== 1 &&
          formik.values.paymentMethodId !== 4
            ? remaining
            : formik.values.paymentByTypeId === 1
            ? remaining
            : error;

        if (Object.keys(toastError).length > 0) {
          toast.info(Object.values(toastError).join("\n"));
        }
        console.log("JSON.stringify(error)", JSON.stringify(toastError));
        if (
          error["checkNumber"] &&
          Object.keys(error).length === 1 &&
          formik.values.paymentByTypeId === 1
        ) {
          const loadingBtn = setTimeout(() => {
            setShowPostPay(true);
          }, 1500);
          loadingBtn && setApplyEob(true);
        } else if (Object.keys(toastError).length === 0) {
          const loadingBtn = setTimeout(() => {
            setShowPostPay(true);
          }, 1500);
          loadingBtn && setApplyEob(true);
        }
      } else {
        const loadingBtn = setTimeout(() => {
          setShowPostPay(true);
        }, 1500);
        loadingBtn && setApplyEob(true);
      }
    });
  };

  const validatePayment = async (values, schema) => {
    try {
      // This will throw an error if validation fails
      await schema.validate(values, { abortEarly: false });
      // If validation passes, return null
      return null;
    } catch (error) {
      // If validation fails, return an object containing errors
      return error.inner.reduce((errors, err) => {
        return {
          ...errors,
          [err.path]: err.message,
        };
      }, {});
    }
  };

  const fetchCreditCardOptions = async () => {
    try {
      let response = await axios.get(`${path}/ct-creditCardType`);
      setCreditCardOptions(response.data?.result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchCreditCardOptions();
    if (state) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        paymentBy: ` ${state?.patientName} (${state?.accountNo})`,
        accountNo: state?.accountNo,
        patientId: state?.patientId,
        paymentClaimDto: [],
      }));
    }
  }, []);
  const handleAccountSummary = useCallback(() => {
    setOpenAccountSummary(true);
  }, []);

  const accountSummaryProps = useSpring({
    width: openAccountSummary ? 450 : 0,
    right: openAccountSummary ? 0 : -70, // Start off-screen
    from: { right: -30 },
    config: { duration: 600 }, // Adjust duration as needed
  });

  return (
    <>
      <Box>
        <CustomModal
          open={openClaimModal}
          handleClose={() => setOpenClaimModal(false)}
          heading="All Claims"
          width='70%'
        >
          <CustomPreview
            searchLabel="Search by Claim Id,Patient Name,Dos"
            rows={
              getClaims && getClaims?.result?.claims.length > 0
                ? getClaims?.result?.claims?.map((el) => ({
                    id: el?.claimId,
                    fromDate: el?.getClaimChargesDto
                      ? new Date(el?.getClaimChargesDto?.fromDate)?.toString()
                      : "",
                    ...el,
                  }))
                : []
            }
            columns={claimCols}
            handleGetAll={getClaimAction}
            loading={loading}
            handleBySearch={getClaimBySearchTermAction}
            isModal={true}
            handleCell={handlePaymentBy}
            paginationDetail={getClaims}
          />
        </CustomModal>
        {/* payer modal */}
        <CustomModal
          open={openPayerModal}
          handleClose={() => setOpenPayerModal(false)}
          heading="Payers"
          width='70%'
        >
          <CustomPreview
            isModal={true}
            rows={
              getAllPayer && getAllPayer?.result?.payers?.length > 0
              ? getAllPayer?.result?.payers?.map((el) => ({
                  id: el?.payerId,
                  ...el,
                }))
              : []
            }
            columns={payerCol}
            handleGetAll={getPayerAction}
            handleBySearch={getPayerBySearchTermAction}
            paginationDetail={getAllPayer}
            loading={payerLoading}
            handleCell={handlePaymentBy}
            searchLabel="Search by name or address #"
          />
        </CustomModal>
        {/* patient modal */}
        <CustomModal
          open={openPatientModal}
          handleClose={() => setOpenPatientModal(false)}
          heading="Patients"
          width='70%'
        >
          <CustomPreview
            columns={patientColsForPayment}
            rows={
              getAllPatients && getAllPatients?.result?.patients?.length > 0
                ? getAllPatients.result?.patients?.map((el) => ({
                    id: el.accountNo,
                    insuredName: el?.getInsuredParty?.insuredName,
                    ...el,
                  }))
                : []
            }
            handleGetAll={getPatientAction}
            paginationDetail={getAllPatients}
            handleBySearch={getPatientBySearchTermAction}
            loading={patientLoading}
            handleCell={handlePaymentBy}
            isModal={true}
            searchLabel="Search by Account #, Name, Account Type and Date Of Birth"
          />
        </CustomModal>

        {!openAccountSummary &&
        formik.values.paymentByTypeId === 1 &&
        formik.values.accountNo !== null ? (
          <Button
            style={{ background: theme.palette.accSummary.primary, zIndex: 9 }}
            className="accountSummaryBtn"
            onClick={handleAccountSummary}
          >
            <span>
              <img
                src={fingerTouchLogo}
                alt="finger-touch-logo"
                style={{ marginRight: "5px" }}
              />
            </span>
            <span>Account Summary</span>
          </Button>
        ) : openAccountSummary ? (
          <animated.div
            style={{
              width: "30%",
              position: "fixed",
              top: "40%",
              overflow: "hidden",
              zIndex: 9,
              ...accountSummaryProps, // Apply animated styles
            }}
          >
            <AccountSummary
              formik={formik}
              accountNo={Number(formik.values.accountNo)}
              handleClose={() => setOpenAccountSummary(false)}
            />
          </animated.div>
        ) : null}
        <br />
        <br />
        <br />
        <Box>
          {showPostPay ? (
            <PostPayment
              formik={formik}
              setShowPostPay={setShowPostPay}
              setApplyEob={setApplyEob}
            />
          ) : (
            <>
              <Box sx={{ width: "95%", margin: "0 12px 0" }}>
                {/* <MainHeading mainheading={"New Payment"} /> */}
                <CustomButton
                  variant="contained"
                  padding={"7px"}
                  margin="10px 20px 0"
                  isBlue={true}
                  handleClick={handleApplyEOB}
                  isAdd={true}
                >
                  {applyEob ? (
                    <CircularProgress />
                  ) : formik.values.paymentByTypeId === 2 ? (
                    "Apply As EOB"
                  ) : (
                    "Apply Manually"
                  )}
                </CustomButton>
              </Box>

              <Stack
                sx={{
                  width: { xs: "95%", sm: "75%", md: "95%" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  padding: "15px",
                  margin: "15px",
                  // afte theme
                  // background: "white",
                }}
              >
                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(1, minmax(0, 1fr))",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="h2"
                      fontWeight={"600"}
                      sx={{
                        marginLeft: "10px",
                        marginBottom: "10px",
                        marginTop: "-10px",
                      }}
                    >
                      Add Payment
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <FormControlLabel
                        label={
                          <Typography fontSize={"1.5rem"} fontWeight="600">
                            Patient Payment
                          </Typography>
                        }
                        control={
                          <Radio
                            value="claim"
                            variant="soft"
                            name="claim"
                            checked={formik.values.paymentByTypeId === 1}
                            onChange={() => {
                              formik.resetForm();
                              formik.setFieldValue("paymentByTypeId", 1);
                            }}
                            style={{
                              color: theme.palette.radioButtons.primary,
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography fontSize={"1.5rem"} fontWeight="600">
                            Insurance Payment
                          </Typography>
                        }
                        control={
                          <Radio
                            value="payer"
                            variant="soft"
                            name="payer"
                            checked={formik.values.paymentByTypeId === 2}
                            onChange={() => {
                              if (formik.values.paymentBy) {
                                formik.resetForm();
                              }
                              formik.setFieldValue("paymentByTypeId", 2);
                              formik.setFieldValue("isClaim", true);
                            }}
                            style={{
                              color: theme.palette.radioButtons.primary,
                            }}
                          />
                        }
                      />
                    </Box>
                    {formik.values.paymentByTypeId === 2 && (
                      <Tabs
                        sx={{
                          padding: "0",
                          minHeight: "1rem !important",
                          order: { xs: 2, sm: 2, md: 1 },
                        }}
                        value={formik.values.isClaim}
                        onChange={(e, v) => {
                          formik.resetForm();
                          formik.setFieldValue("paymentByTypeId", 2);
                          formik.setFieldValue("isClaim", v);
                        }}
                        indicatorColor="transparent"
                      >
                        {" "}
                        <Tab
                          style={{
                            borderRadius: "10px 0 0 10px",
                            background: "#b8b4b4",
                          }}
                          className="paymenttabstwo"
                          label="Claim"
                          value={true}
                        />
                        <Tab
                          style={{
                            background: "#b8b4b4",
                            borderRadius: "0 10px 10px 0",
                          }}
                          className="paymenttabstwo"
                          label="Payer"
                          value={false}
                        />{" "}
                      </Tabs>
                    )}
                  </Box>

                  </Box>
                  <Box
                  display="grid"
                  gap="30px"
                  margin={"5px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  <CustomSearchField
                    type="text"
                    label="Payment By"
                    // touched={formik.touched}
                    // isRequired={true}
                    // error={formik.errors}
                    handleModalOpen={() => {
                      if (
                        !formik.values.isClaim &&
                        formik.values.paymentByTypeId === 2
                      ) {
                        setOpenPayerModal(true);
                      } else if (formik.values.paymentByTypeId === 1) {
                        setOpenPatientModal(true);
                      } else {
                        setOpenClaimModal(true);
                      }
                    }}
                    name="paymentBy"
                    fieldVal={formik.values.paymentBy}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    formik={formik}
                    resetField={{
                      paymentBy: "",
                    }}
                  />
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(4, minmax(0, 1fr))",
                    },
                  }}
                >
                  {formik.values.paymentByTypeId === 2 && (
                    <CustomField
                      type="text"
                      label="Payment From"
                      disable={true}
                      value={formik.values.paymentFromName}
                      name="paymentFromName"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                    />
                  )}
                  <CustomField
                    type="number"
                    name="paymentAmount"
                    isRequired={true}
                    error={formik.errors}
                    touched={formik.touched}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    label="Payment Amount"
                    value={formik.values.paymentAmount}
                  />
                  <div className="mt-4">
                    <CustomDatePicker
                      formik={formik}
                      name="checkDate"
                      dateValue={formik.values.checkDate}
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </div>
                </Box>

                <Stack flexDirection={"row"} alignItems={"center"}>
                  {formik.values.paymentByTypeId === 1 && (
                    <Box>
                      {" "}
                      <Stack flexDirection={"row"} alignItems={"center"}>
                        <Typography
                          fontSize={"1.3rem"}
                          fontWeight={"600"}
                          marginRight={"20px"}
                        >
                          Type:
                        </Typography>
                        <FormControlLabel
                          label={
                            <Typography fontSize={"1.3rem"} fontWeight="600">
                              Copay
                            </Typography>
                          }
                          control={
                            <Radio
                              value="1"
                              variant="soft"
                              name="paymentTypeId"
                              checked={formik.values.paymentTypeId === 1}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "paymentTypeId",
                                  Number(e.target.value)
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography fontSize={"1.3rem"} fontWeight="600">
                              Payment
                            </Typography>
                          }
                          control={
                            <Radio
                              value="2"
                              variant="soft"
                              name="paymentTypeId"
                              checked={formik.values.paymentTypeId === 2}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "paymentTypeId",
                                  Number(e.target.value)
                                );
                              }}
                            />
                          }
                        />
                      </Stack>
                      {formik.values.paymentTypeId === 1 && (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale="en"
                        >
                          <CustomDatePicker
                            name="copayServiceDate"
                            dateValue={formik.values.copayServiceDate}
                            handleDateChange={formik.handleChange}
                            handleDateBlur={formik.handleBlur}
                            formik={formik}
                            dateLabel="Copay Date of Service"
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  )}
                </Stack>
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Typography
                    sx={{ fontSize: "1.3rem", fontWeight: "600" }}
                    marginRight={"10px"}
                  >
                    Source:
                  </Typography>
                  <FormControlLabel
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Check
                      </Typography>
                    }
                    control={
                      <Radio
                        value="1"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 1}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 1 && (
                    <FormControlLabel
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          Cash
                        </Typography>
                      }
                      control={
                        <Radio
                          value="2"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 2}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                          style={{ color: theme.palette.radioButtons.primary }}
                        />
                      }
                    />
                  )}
                  <FormControlLabel
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Credit Card
                      </Typography>
                    }
                    control={
                      <Radio
                        value="3"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 3}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 2 && (
                    <FormControlLabel
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          Electronic Fund Transfer
                        </Typography>
                      }
                      control={
                        <Radio
                          value="4"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 4}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                          style={{ color: theme.palette.radioButtons.primary }}
                        />
                      }
                    />
                  )}
                </Stack>

                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(2, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  {renderSourceOptions()}
                </Box>
              </Stack>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewPayment;
