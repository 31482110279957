import { useTheme } from "@mui/material";
import React from "react";
import "./Custom-styling/CustomField.css";
import { getIn } from "formik";
import ReactInputMask from "react-input-mask";

const CustomField = ({
  type,
  label,
  value,
  handleChange,
  disable,
  handleBlur,
  name,
  error,
  touched,
  isRequired,
  isDecimal,
  max,
  min,
  mask,
  fontFormat
}) => {
  const theme = useTheme();
  const formattedValue = isDecimal ? parseFloat(value).toFixed(2) : value;
  const hasError = isRequired && getIn(touched,name) && getIn(error,name);
  console.log(touched, "touched")
  return (
    <div>
      {/* <label
        className="customLabel"
        style={{color: theme.palette.Labeltext.primary}}
      >
        {label}
      </label> */}
        <label
        className="customLabel"
        style={{ color: theme.palette.Labeltext.primary}}
      >
        {label}
        {isRequired && <span style={{ color: "red",fontSize:'1.5rem' }}> *</span>}
      </label>

      <div
        style={{
          pointerEvents: disable && "none",
          opacity: disable && "0.6",
        }}
      >
        {/* <input
          style={{
            background: theme.palette.customFieldsBackground.primary,
            color: theme.palette.fieldsText.primary,
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
          }}
          className="customField"
          type={type}
          value={formattedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          disabled={disable}
          spellCheck={false}
          min={min}
          pattern={pattern}
          max={max}
        /> */}
        {mask ? (
          <ReactInputMask
            mask={mask}
            alwaysShowMask={false}
            value={formattedValue}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disable}
            maskChar={null}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                style={{
                  textTransform:`uppercase !important`,
                  background: theme.palette.customFieldsBackground.primary,
                  color: theme.palette.fieldsText.primary,
                  border: hasError
                    ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                    : `1px solid ${theme.palette.fieldsBorder.primary}`,
                }}
                className="customField"
                type={type}
                name={name}
                spellCheck={false}
                min={min}
                max={max}
              />
            )}
          </ReactInputMask>
        ) : (
          <input
            style={{
              textTransform:`uppercase !important`,
              background: theme.palette.customFieldsBackground.primary,
              color: theme.palette.fieldsText.primary,
              border: hasError
                ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                : `1px solid ${theme.palette.fieldsBorder.primary}`,
            }}
            className="customField"
            type={type}
            value={formattedValue}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            disabled={disable}
            spellCheck={false}
            min={min}
            max={max}
          />
        )}
      </div>
    </div>
  );
};

export default CustomField;