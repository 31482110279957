import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getDiagnosisAction = createAsyncThunk("getDiagnosis", async (page) => {
  try {
    // let search = searchCode ? `/${searchCode}` : ''
    const newPage = page?.page+1
    const response = await getData(`${path}/ct-diagnosisCode?page=${newPage}&pageSize=10`);
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const newDiagnosisAction = createAsyncThunk(
  "createDiagnosis" ,
  async (data) => {
    console.log(data);
    const dataintoArray =[]
    dataintoArray.push(data)
    console.log(dataintoArray);
    
    try {
      const response = await postData(`${path}/ct-diagnosisCode`, dataintoArray);
      console.log(response);
      
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "check Error");
      throw new Error(error);
    }
  }
);

export const getDiagnosisBySearchTermAction = createAsyncThunk("getDiagnosis", async ({searchTerm,page}) => {
  try {
    
    const response = await getData(`${path}/ct-diagnosisCode/SearchDiagnosisCode?searchValue=${searchTerm}&page=${page}&pageSize=10`);
    console.log(response, "action diagnosis by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});

export const getDiagnosisByIdAction = createAsyncThunk(
  "getDiagnosisById",
  async (id) => {
    try {

      const response = await getData( `${path}/ct-diagnosisCode/${id}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateDiagnosisAction = createAsyncThunk(
  "updateDiagnosis",
  async (data) => {
    console.log("data " , data)
    try {
      const response = await updateData(
        `${path}/ct-diagnosisCode/${data.diagnosisCodeId}`,
        data
      );
      console.log(response, "update Diagnosis action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
