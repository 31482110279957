import { createSlice } from "@reduxjs/toolkit";
import { getClaimTracker } from "../actions/claimTrackerAction";

const claimTrackerSlice = createSlice({
  name: "claimTracker",
  initialState: {
    claimTrackerData: [],
    dataExits: false,
    loading: false,
    error: null,
  },
  reducers: {
    handleArrowChange: (state, action) => {
      state.claimTrackerData = action.payload;
    },
    handleTrackerCancel: (state, action) => {
      state.claimTrackerData = [];
      state.dataExits = false;
    },
  },
  extraReducers: {
    [getClaimTracker.pending]: (state, action) => {
      state.loading = true;
      state.dataExits = false;
    },
    [getClaimTracker.fulfilled]: (state, action) => {
      state.loading = false;
      state.claimTrackerData = action?.payload?.claimTrackerList || [];
      state.dataExits =
        action.payload?.claimTrackerList.length > 0 ? true : false;
    },
    [getClaimTracker.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.dataExits = false;
    },
  },
});

export const { handleArrowChange, handleTrackerCancel } = claimTrackerSlice.actions;
export default claimTrackerSlice.reducer;
