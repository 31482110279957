import React from 'react'
import LabelBatchPrintInfo from './LabelBatchPrintInfo'
// import MainHeading from '../../../components/MainHeading'
import { Box } from '@mui/material'
import CustomButton from '../../../components/CustomButton'

const LabelBatchPrint = () => {
  return (
    <>
         <Box margin="20px">
        {/* <MainHeading mainheading={"Label Batch Print"} /> */}
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
            <CustomButton type="submit" isBlue={true} isSave={true}>
              Save
            </CustomButton>
            {/* <CustomButton
              isCancel={true}
            >
              Cancel
            </CustomButton> */}
          </Box>
          <LabelBatchPrintInfo/>
      </Box>
    </>
  )
}

export default LabelBatchPrint