import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const newProviderAction = createAsyncThunk(
  "createProvider",
  async (data) => {
    try {
      const response = await postData(`${path}/provider`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getProviderAction = createAsyncThunk(
  "getProvider",
  async (page) => {
    try {
      const newPage = page?.page+1
      const response = await getData(
        `${path}/provider?page=${newPage}&pageSize=10`
      );
      if (response) {
        console.log(response,"==========");
        
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getProviderByIdAction = createAsyncThunk(
  "getProviderById",
  async (seqNo) => {
    try {

      const response = await getData( `${path}/provider/${seqNo}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateProviderAction = createAsyncThunk(
  "updateProvider",
  async (data) => {
    console.log(data, "update data provider action response");
    try {
      const response = await updateData(
        `${path}/provider/${data.providerSequenceNo}`,
        data
      );
      console.log(response, "update prac action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// search
export const getProviderBySearchTermAction = createAsyncThunk(
  "getProvider",
  async ({searchTerm, page,showAll}) => {
    try {
      const response = await getData(`${path}/provider/SearchProvider?searchValue=${searchTerm}&page=${page}&pageSize=10&showAll=${showAll}`);
      console.log(response, "action provider by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
// provider sequence
export const getProviderSeqAction = createAsyncThunk(
  "getProviderSeqAction",
  async (seqNo) => {
    try {
      const response = await getData(`${path}/provider/${seqNo}`);
      console.log(response, "action provider by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
