import React, { useEffect, useState } from "react";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import {
  Box,
  // FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CheckBox from "../../../components/CheckBox";
import CustomSelectBox2 from "./../../../components/CustomSelectBox2";
import CustomDatePicker from "./../../../components/CustomDatePicker";
import CustomField from "./../../../components/CustomField";
import CustomSearchField from "./../../../components/CustomSearchField";
// import MainHeading from "../../../components/MainHeading";

const PaymentTracker = () => {
  const [paymentTypeOpt, setPaymentTypeOpt] = useState([]);
  const [dosTypeOpt, setDosType] = useState([]);
  console.log(paymentTypeOpt, "paymentType");
  const dataFetchUrls = {
    paymentType: `${path}/ct-paymentByType`,
    dosType: `${path}/ct-dOS`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataOptions(dataFetchUrls.paymentType, setPaymentTypeOpt);
    fetchDataOptions(dataFetchUrls.dosType, setDosType);
  }, []);
  return (
    <Box margin="20px" paddingBottom={"25px"} >
      {/* <MainHeading mainheading="Payment Tracker" /> */}

      <Grid container spacing={2} padding={"7px"}>
        <Grid item md={12} margin={"0 10px 0 "}>
          <Stack direction="column">
            {paymentTypeOpt?.map((item) => (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CheckBox
                      // CheckedValue={formik?.values?.EPSDT_DetailDto?.some(
                      //   (val) =>
                      //     val?.epsdT_CertificationCodeId ===
                      //     v?.epsdT_CertificationCodeId
                      // )}
                      // handleChange={() => checkboxHandleChange(v)}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        {item?.paymentByType}
                      </Typography>
                    }
                  />
                </FormGroup>
              </>
            ))}
          </Stack>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <CustomSearchField label="Patient" />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <CustomSearchField label="Payer" />
        </Grid>

        <Grid item md={12}>
          <Typography component="h3">Payment Tracking Date Range</Typography>
        </Grid>
<Grid container spacing={2} padding={"15px"}>
        <Grid item md={3} sm={12} xs={12}>
     
            <CustomSelectBox2
              dropdownOptions={dosTypeOpt?.map((el) => ({
                id: el?.dosId,
                value: el?.dos,
              }))}
              label="Payment Received Date"
              showDefaultOpt={true}
            />
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
            <CustomDatePicker dateLabel="From" />
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
            <CustomDatePicker dateLabel="To" />
        </Grid>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
         
            <CustomSelectBox2
              dropdownOptions={dosTypeOpt?.map((el) => ({
                id: el?.dosId,
                value: el?.dos,
              }))}
              label="Payment Entered Date"
              showDefaultOpt={true}
            />
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
            <CustomDatePicker dateLabel="From" />
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
            <CustomDatePicker dateLabel="To" />
        </Grid>
        <Grid item md={12}>
            <CustomField label="Check #" />
            <CheckBox label="Exact Matches Only" />
        </Grid>
        <Grid item md={12}>
          <Stack>
            <CustomField label="Receipt #" />
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Stack>
            <CustomSearchField label="Claim #" />
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Stack>
            <CustomSearchField label="Payment Plan" />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentTracker;
