import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import MainHeading from "../../../components/MainHeading";
import FollowUpManagementInfo from "./FollowUpManagementInfo";
import { followUpManagementVal } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import FollowUpSearchedData from "./FollowUpSearchedData";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { searchFollowUpDataAction } from "../../../features/actions/followUpManagementAction";
import { useConfirm } from "material-ui-confirm";

const FollowUpManagement = () => {
  const confirm = useConfirm();
  const [isSearched, setIsSearched] = useState(false);
  const [claimStatusOptions, setClaimStatusOptions] = useState([]);
  const dispatch = useDispatch();
  const { followUpSearchedData, loading, error } = useSelector(
    (state) => state.followUpManagement
  );
  console.log(isSearched, "isSearchedData");
  const formik = useFormik({
    initialValues: followUpManagementVal,
    onSubmit: (values) => {
      const claimStatusIdDto = values.claimStatusId?.map((item) => item.value);
      const payerTypeIds = values.payerTypeId?.map((item) => item.value);
      const payerIdDto = values.payerId?.map((item) => item.value);
      const practiceIdDto = values.practiceId?.map((item) => item.value);
      const providerIdDto = values.providerId?.map((item) => item.value);
      const facilityIdDto = values.facilityId?.map((item) => item.value);

      const updatedValues = {
        ...values,
        claimStatusId: claimStatusIdDto,
        payerId: payerIdDto,
        practiceId: practiceIdDto,
        facilityId: facilityIdDto,
        providerId: providerIdDto,
        payerTypeId: payerTypeIds,
      };

      dispatch(searchFollowUpDataAction(updatedValues))
        .then(({ payload }) => {
          if (payload?.result?.length === 0) {
            confirm({
              title: payload.message,
              confirmationText: "Ok",
            });
          } else {
            setIsSearched(true);
          }
        })
        .catch((error) => {
          console.error("Error searching follow-up data:", error);
        });
    },
  });

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  const dataFetchUrls = {
    claimStatus: `${path}/ct-claimStatus`,
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.claimStatus, setClaimStatusOptions);
  }, []);

  return (
    <>
      <Box margin="20px">
        {/* <MainHeading mainheading={"Follow Up Management"}/> */}
        <form onSubmit={formik.handleSubmit}>
          {isSearched &&
          followUpSearchedData.result &&
          followUpSearchedData.result.length > 0 ? (
            <FollowUpSearchedData
              data={followUpSearchedData.result}
              formik={formik}
              handleClose={() => setIsSearched(false)}
            />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "20px",
                  borderBottom: "1px solid black",
                }}
              >
                <CustomButton
                  type="submit"
                  children={"Search"}
                  isSearch={true}
                  isBlue={true}
                />
              </div>

              <FollowUpManagementInfo
                formik={formik}
                claimStatusOptions={claimStatusOptions}
              />
            </>
          )}
        </form>
      </Box>
    </>
  );
};

export default FollowUpManagement;
