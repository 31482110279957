// import { useTheme } from "@mui/material";
// import styled from "styled-components";
// import { MultiSelect } from "react-multi-select-component";

// const CustomMultiSelect = ({ options, value, handleChange,handleSelectAll }) => {
//   const theme = useTheme();

//   const CustomDiv = styled.div`
//     .multiselect .dropdown-container {
//       background-color: ${theme.palette.customFieldsBackground.primary};
//       color: ${theme.palette.fieldsText.primary};
//     }
//     .multiselect .dropdown-content .panel-content {
//       background-color: ${theme.palette.customFieldsBackground.primary};
//       color: ${theme.palette.fieldsText.primary};
//     }
//     .multiselect .dropdown-content .panel-content .select-panel .search input {
//       background-color: ${theme.palette.customFieldsBackground.primary};
//       color: ${theme.palette.fieldsText.primary};
//     }
//     .multiselect
//       .dropdown-content
//       .panel-content
//       .select-panel
//       .options
//       .select-item {
//       background-color: ${theme.palette.customFieldsBackground.primary};
//       color: ${theme.palette.fieldsText.primary};
//     }
//   `;

//   return (
//     <CustomDiv>
//       <MultiSelect
//         className="multiselect"
//         options={options}
//         value={value}
//         onChange={(e) => handleChange(e)}
//         // hasSelectAll={false}
//         hasSelectAll={handleSelectAll}

//       />
//     </CustomDiv>
//   );
// };
// export default CustomMultiSelect;




import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import { MultiSelect } from "react-multi-select-component";

const CustomMultiSelect = ({ options, value, handleChange, handleSelectAll }) => {
  const theme = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const CustomDiv = styled.div`
    .multiselect .dropdown-container {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect .dropdown-content .panel-content {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect .dropdown-content .panel-content .select-panel .search input {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect .dropdown-content .panel-content .select-panel .options .select-item {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
  `;

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = useCallback((event) => {
    if (!event.target.closest('.multiselect')) {
      setIsDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <CustomDiv>
      <div onClick={handleDropdownToggle} className="multiselect">
        <MultiSelect
          options={options}
          value={value}
          onChange={(e) => {
            handleChange(e);
            setIsDropdownOpen(true);
          }}
          hasSelectAll={handleSelectAll}
          isOpen={isDropdownOpen}
          labelledBy="Select"
        />
      </div>
    </CustomDiv>
  );
};

export default CustomMultiSelect;





