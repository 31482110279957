import React from "react";
import { useEffect } from "react";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomDataGrid from "../../../components/CustomDataGrid";

const Diagnosis = ({
  formik,
  handleClose,
  icdIdentifier,
  diagnoseTable,
  handleInsDiagnosis,
  handleCellClick,
  temp
}) => {
  let flage = false
  const [diagnosisData, setDiagnosisData] = useState([]);
  console.log(diagnosisData, "checkDiagnosis");
  const fetchAllDiagnosis = async () => {
    try {
      const response = await axios.get(`${path}/ct-diagnosisCode?page=1&pageSize=10`);
      console.log(response, "response");
      if (response.status === 200) {
        const data = response.data?.result;
        console.log("formik.values?.insClaimInfoDetailDto?", formik);
        if (formik) {
          let usedDiagnose = formik.values?.insClaimInfoDetailDto?.filter(
            (e) => e.insClaimInfoCodeId === diagnoseTable
          );
          for (let i = 0; i < usedDiagnose.length; i++) {
            const element = usedDiagnose[i];
            let findIndex = data?.diagnosisCode?.findIndex(
              (e) => e.diagnosisCodeId === element.insClaimInfoCTId
            );
            if (findIndex !== -1) {
              data?.diagnosisCode?.splice(findIndex, 1);
            }
          }
        }
        setDiagnosisData(data?.diagnosisCode);
      } else {
        setDiagnosisData([]);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const rows = diagnosisData.map((el) => ({
    id: el.diagnosisCodeId,
    diagnosisDescription: el.diagnosisDescription,
    codeType: el.codeType,
    diagnosisCode: el.diagnosisCode,
  }));

  const columns = [
    {
      field: "codeType",
      headerName: "Code Type",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "diagnosisDescription",
      headerName: "Description",
      minWidth: 100,
      flex: 3,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "diagnosisCode",
      headerName: "Description",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  useEffect(() => {
    if(!flage){
      flage = true
    fetchAllDiagnosis();
    }
  }, []);

  // const handleDiagnosis = (val, field) => {
  //   console.log(val, "diagnosis values");
  //   const fieldId = field;
  //   const descriptionFieldName = `icd_Description_${icdIdentifier}`;
  //   const icdCode = `icD_Code_${icdIdentifier}`;
  //   formik.setValues({
  //     ...formik.values,
  //     [fieldId]: val.id,
  //     [descriptionFieldName]: val.diagnosisDescription,
  //     [icdCode]: val.diagnosisCode,
  //   });

  //   handleClose();
  // };

  return (
    <>
      <Box sx={{ height: "400px", width: "100%" }}>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          handleCellClick={(params) => handleCellClick(params , diagnoseTable)}
        />
      </Box>
    </>
  );
};

export default Diagnosis;
