// import React from "react";
// import { useTheme } from "@mui/material";
// import "./Custom-styling/CustomDatePicker.css";
// import dayjs from "dayjs";
// import { getIn } from "formik";
// const CustomDatePicker = ({
//   dateValue,
//   handleDateChange,
//   handleDateBlur,
//   disable = false,
//   dateLabel,
//   formik,
//   name,
//   isNested =false,
// isFromTo = false

// }) => {
//   const theme = useTheme();

//   // adding variable for nasted form field
// // const fieldName = isNested ? name.split('.').shift() : name;
// // const hasError = formik && formik.touched[fieldName] && formik.errors[fieldName];
// const hasError = !disable &&  formik && getIn(formik.touched,name) && getIn(formik.errors,name);

//   const dateValue2 = dayjs(dateValue).format("YYYY-MM-DD")
//   const today =  new Date().toISOString().split("T")[0];
//   const futureDate = dayjs(today).add(20,"year")
//   const oldDate = dayjs(today).subtract(20,"year")

//   return (
//     <>
//       <div>
//         <label
//           className="customLabel"
//           htmlFor={name}
//           style={{ color: theme.palette.Labeltext.primary }}
//         >
//           {dateLabel}
//         </label>

//         <input
//           style={{
//             background: theme.palette.customFieldsBackground.primary,
//             border: hasError
//               ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//               : `1px solid ${theme.palette.fieldsBorder.primary}`,
//             color: theme.palette.fieldsText.primary,
//           }}
//           type="date"
//           className="customDatePicker"
//           name={name}
//           id={name}
//           disabled={disable}
//           value={ dateValue2 || ""}
//           max={ isFromTo ? futureDate : today}
//           min={isFromTo ? oldDate : undefined}
//           onChange={(e) => {
//             handleDateChange(e)
//             formik.setFieldValue(
//               name,
//               e.target.value === "" ? null : dayjs(e.target.value).format("MM/DD/YYYY")
//             );
//             console.log(e.target.value);
//             console.log( dayjs(e.target.value).format("MM/DD/YYYY"));
//           }}
//           onBlur={handleDateBlur}
//         />
//       </div>
//     </>
//   );
// };

// export default CustomDatePicker;

// mui date picker

import * as React from "react";
import { useTheme, Box, InputLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./Custom-styling/CustomDatePicker.css";
import { getIn } from "formik";
const CustomMuiDatePicker = ({
  dateValue,
  handleDateChange,
  handleDateBlur,
  disable = false,
  dateLabel,
  formik,
  name,
  isRequired,
  isNested = false,
  isFromTo = false,
}) => {
  //   const [value, setValue] = React.useState(null);
  //   console.log(value);
  const hasError =
    !disable &&
    formik &&
    getIn(formik.touched, name) &&
    getIn(formik.errors, name);
  const theme = useTheme();

 const value = dayjs(dateValue);

  // Format function for the selected date
  const formatDate = (date) => {
    console.log(date);

    if (date) {
      return dayjs(date).format("MM/DD/YYYY"); // Change the format as needed
    }
    return "";
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {/* Custom Label */}
        <label
          htmlFor={name}
          className="customLabel"
          style={{ color: theme.palette.Labeltext.primary }}
        >
          {dateLabel}

          {isRequired && (
            <span style={{ color: "red", fontSize: "1.5rem" }}> *</span>
          )}
        </label>

        {/* DatePicker */}
        <DatePicker
          format="MM/DD/YYYY"
          className="customDatePicker"
          name={name}
          disabled={disable}
          // value={dateValue}
          value={value.isValid() ? value : null} 
          onChange={(newValue) => {
            // Ensure the newValue is correctly formatted
            const formattedValue = newValue
              ? dayjs(newValue).format("MM/DD/YYYY")
              : "";
            // Update Formik field value
            handleDateChange(formattedValue);
            formik.setFieldValue(name, formattedValue);
            // Optionally update local state
            // setValue(formattedValue);
          }}
          onBlur={handleDateBlur}
          maxDate={isFromTo ? undefined : dayjs()}
          sx={{
            "& .css-cwhad8-MuiDateCalendar-root": {
              width: "250px !important",
              fontSize: "1.4rem !important",
              color: "#000 !important",
            },
            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "4px 14px !important",
              fontSize: "1.6rem !important",
              //   color: "#000 !important",
            },
            "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: "7px",
              border: hasError
                ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                : `1px solid ${theme.palette.fieldsBorder.primary}`,
            },
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .css-i4bv87-MuiSvgIcon-root": {
              color: "#000 !important",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              //   id="custom-date-picker"
              id={name}
              value={formatDate(dateValue)}
              // Adjust input field appearance if needed
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomMuiDatePicker;
