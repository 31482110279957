import * as yup from "yup";
// import { parse, isDate } from "date-fns";
// import {
//   createFieldsValidation,
//   createSelectBoxValidation,
// } from "./createValidation";

const minLength = (lengthNum) => {
  return `Minimum ${lengthNum} characters are required!`;
};

const maxLength = (lengthNum) => {
  return `Maximum ${lengthNum} characters are required!`;
};

const requireMsg = (field) => {
  return `${field} is Required!`;
};

export const patientSchema = yup.object().shape({
  // fields
  firstName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength())
    .required(requireMsg("First Name")),
  lastName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength(20))
    .required(requireMsg("Last Name")),
  address: yup.string().trim().required(requireMsg("Address")),
  zipCode: yup.string().trim().required(requireMsg("Zipcode")),
  cellPhone: yup.string().required(requireMsg("Cellphone")),
  // selectboxes
  genderIdentityStatusId: yup
    .number()
    .nullable()
    .required("Gender is required"),
  stateName: yup.string().trim().required("State is required"),
  cityName: yup.string().trim().required("City is required"),
  dateOfBirth: yup
    .date("Invalid Date")
    .nullable()
    .required("Date of Birth is required"),
});

export const insuranceSchema = yup.object().shape({
  payerInfoMemberId: yup.string().required(requireMsg("Member Id")),
  insuranceDescriptionName: yup.string().required(requireMsg("search field ")),
});

export const insuredPartySchema = yup.object().shape({
  insuredFirstName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength(20))
    .required(requireMsg("First Name")),
  insuredLastName: yup.string().required(requireMsg("Last Name")),
  insuredAddress: yup.string().required(requireMsg("Address")),
  insuredZipCode: yup.string().required(requireMsg("Zip code")),
  insuredStateId: yup.number().nullable().required("State is required"),
  insuredCityId: yup.number().nullable().required("City is required"),
});
// export const insuranceSchema = yup.object().shape({
//   insuranceInfoValues: yup.object().shape({
//     insuredFirstName: yup
//     .string()
//     .trim()
//     .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
//     .min(3, minLength(3))
//     .max(20, maxLength(20))
//     .required(requireMsg("First Name")),
//     insuredLastName:yup.string().required(requireMsg("Last Name")),
//     insuredAddress:yup.string().required(requireMsg("Address")),
//     insuredCellPhone:yup.number().required(requireMsg("Cell Number")),
//     insuredDateOfBirth:yup.date("Invalid date").required("Date Of Birth"),
//     insuredZipCode:yup.string().required(requireMsg("Zip code")),
//     insuredGenderIdentityId: yup.number().nullable().required(requireMsg("Gender")),
//     insuredCountryId: yup.number().nullable().required("Country is required"),
//     insuredStateId: yup.number().nullable().required("State is required"),
//     insuredCityId: yup.number().nullable().required("City is required"),

//   }),
//   payerDetailVals:yup.object().shape({
//     payerInfoMemberId:yup.string().required(requireMsg("Member Id")),
//     payerInfoPayerName:yup.string().required(requireMsg("search field "))
//   })

// });

export const remittanceSchema = yup.object().shape({
  remittanceCodeName: yup.string().required(requireMsg("Code Name")),
  remittanceTypeId: yup.number().nullable().required(requireMsg("Type")),
  remittanceInfoLevelId: yup
    .number()
    .nullable()
    .required(requireMsg("Information Level")),
  reportDescription: yup.string().required(requireMsg("Report Description")),
});

export const paymentSchemaForPatient = yup.object().shape({
  paymentBy: yup.string().required(requireMsg("Payment By")),
  paymentAmount: yup
    .number()
    .nullable()
    .typeError(requireMsg("Amount"))
    .required(requireMsg("Amount"))
    .min(1, "Amount must be greater than 0"),
  paymentTypeId: yup.number().nullable().required(requireMsg("Payment Type")),
  checkNumber: yup.string().nullable().required("Enter Check #"),
});

export const paymentSchemaForInsurance = yup.object().shape({
  paymentBy: yup.string().required(requireMsg("Payment By")),
  paymentFromName: yup.string().required(requireMsg("Payment From")),
  paymentAmount: yup
    .number()
    .nullable()
    .typeError(requireMsg("Amount"))
    .required(requireMsg("Amount"))
    .min(1, "Amount must be greater than 0"),
  checkNumber: yup.string().nullable().required("Enter Check #"),
  // paymentDate: yup

  // .date('Invalid Date')
  // .nullable()
  // .required(requireMsg("Date")),
  // paymentModeId: yup.number().nullable().required(requireMsg("Mode")),
});

export const practiceSchema = yup.object().shape({
  practiceName: yup.string().required(requireMsg("Name")),
  practiceNpi: yup.number().nullable().required(requireMsg("NPI")),
  taxonomySpecialty: yup.string().required(requireMsg("Taxonomy")),
  address: yup.string().required(),
  state:yup.string().required(),
  city:yup.string().required(),
  zipCode: yup.string().required(requireMsg("ZIP Code")),
  phone: yup.string().required(requireMsg("Phone")),
  payToAddress: yup.string().required(),
  payToCity: yup.string().required(),
  payToState: yup.string().required(),
  payToZipCode: yup.string().required(),

});

export const providerSchema = yup.object().shape({
  providerTypeId: yup.number(),
  providerFirstName: yup.string().required(requireMsg("First Name")),
  providerLastName: yup.string().when("providerTypeId", {
    is: 1,
    then: yup.string().nullable().required("Last Name is required"),
    otherwise: yup.string().nullable(),
  }),
  providerNPINo: yup.string().required(requireMsg("NPI")),
  practiceName: yup.string().required(requireMsg("Practice")),
  providerTaxonomySpecialtyCode: yup
    .string()
    .nullable()
    .required(requireMsg("Taxonomy")),
  id_NoValue: yup.string().nullable().required(requireMsg("#")),
  claimBillingId: yup.number().nullable().required(requireMsg("Bill")),
});

export const refProviderSchema = yup.object().shape({
  referringProviderFirstName: yup
    .string()
    .nullable()
    .required(requireMsg("First Name")),
  referringProviderLastName: yup
    .string()
    .nullable()
    .required(requireMsg("Last Name")),
  referringProviderTaxonomySpecialtyCode: yup.string().nullable().required(),
  address: yup.string().nullable().required(requireMsg("Address")),
  zipCode: yup.string().required(requireMsg("ZIP Code")),
});

export const facilitySchema = yup.object().shape({
  facilityName: yup.string().nullable().required(requireMsg("Name")),
  facilityAddress: yup.string().nullable().required(requireMsg("Address")),
  facilityStateId: yup.number().nullable().required(requireMsg("State")),
  facilityCityId: yup.number().nullable().required(requireMsg("City")),
  facilityZipCode: yup.string().required(requireMsg("ZIP Code")),
});

export const procedureSchema = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
  procedureDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const diagnosisSchema = yup.object().shape({
  diagnosisCode: yup.string().nullable().required(requireMsg("Code")),
  codeTypeId: yup.number().nullable().required(requireMsg("State is required")),
  diagnosisDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const adjustmentSchema = yup.object().shape({
  adjustmentCode: yup.string().nullable().required(requireMsg("Code")),
  description: yup.string().nullable().required(requireMsg("Description")),
  adjustmentCodeTypeId: yup
    .number()
    .nullable()
    .required(requireMsg("Adjustment Code Type "))
    .min(1),
});

export const revenueSchema = yup.object().shape({
  revenueCode: yup.string().nullable().required(requireMsg("Code")),
  revenueCodePrice:yup.number().nullable().required(requireMsg("Price")),
  revenueCodeDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const inventoryScehma = yup.object().shape({
  code: yup.string().nullable().required(requireMsg("Code")),
  alertDescription: yup
  .string()
  .when("alert", {
    is: true,
    then: yup.string().required("Billing Description is required when alert is true"),
    otherwise: yup.string().nullable(), // or any other validation for the case when alert is not true
  }),
  // alertDescription : yup.string().nullable().required(requireMsg("Alert Description")),
});

export const chargePanelSchema = yup.object().shape({
  chargePanelTitle: yup.string().required(),
  chargePanelCode: yup.string().required()
})

export const claimSchema = yup.object().shape({
    patientFirstName: yup.string().nullable().required(),
    billingProviderFirstName: yup.string().nullable().required(),
    renderingProviderFirstName: yup.string().nullable().required(),
    ambulanceInfoDto: yup.object().shape({
      ambulanceClaimId: yup.number(),
      pickupAddress: yup.string().nullable().when("ambulanceClaimId", {
        is:1,
        then: (schema) => schema.required(),
        otherwise:(schema) => schema.nullable()
      }),
      pickupCity: yup.string().nullable().when("ambulanceClaimId", {
        is:1,
        then: (schema) => schema.required(),
        otherwise:(schema) => schema.nullable()
      }),
      pickupState: yup.string().nullable().when("ambulanceClaimId", {
        is:1,
        then: (schema) => schema.required(),
        otherwise:(schema) => schema.nullable()
      }),
    pickupZipCode: yup.string().nullable().when("ambulanceClaimId", {
      is:1,
      then: (schema) => schema.required(),
      otherwise:(schema) => schema.nullable()
    }),
    dropOffAddress: yup.string().nullable().when("ambulanceClaimId", {
      is:1,
      then: (schema) => schema.required(),
      otherwise:(schema) => schema.nullable()
    }),
    dropOffCity: yup.string().nullable().when("ambulanceClaimId", {
      is:1,
      then: (schema) => schema.required(),
      otherwise:(schema) => schema.nullable()
    }),
    dropOffState: yup.string().nullable().when("ambulanceClaimId", {
      is:1,
      then: (schema) => schema.required(),
      otherwise:(schema) => schema.nullable()
    }),
    dropOffZipCode: yup.string().nullable().when("ambulanceClaimId", {
      is:1,
      then: (schema) => schema.required(),
      otherwise:(schema) => schema.nullable()
    }),
  }),
  icD_DiagnosisDetailDto: yup.array().of(
    yup.object().shape({
      dxPointerLabel: yup.string().nullable(),
      diagnosisCode: yup.string().nullable().when("dxPointerLabel", {
        is:"A",
        then:yup.string().required(),
        otherwise: yup.string().nullable()
      })
    })
  ).test("has-ICD-A", "Diagnosis Code for ICD A is required.", function (value) {
    const hasICDA = value?.some((item) => item.dxPointerLabel === "A" && item.diagnosisCode?.trim() !== "");
    if (!hasICDA) {
      return false; 
    }
    return true; 
  })
});

export const payerSchema = yup.object().shape({
  insuranceDescriptionName: yup
    .string()
    .nullable()
    .required(requireMsg("Name")),
  planName: yup.string().nullable().required(requireMsg("Plan Name")),
  insuranceTypeId: yup
    .number()
    .nullable()
    .required("Insurance Type is Required"),
});

export const payerAddressSchema = yup.object().shape({
  address: yup.string().trim().required(requireMsg("Address")),
  zipCode: yup.string().trim().required(requireMsg("Zipcode")),
});

export const icdProcedureSchema = yup.object().shape({
  icdCode: yup.string().nullable().required(requireMsg("Codes")),
  description: yup.string().nullable().required(requireMsg("Description")),
});

export const proceduresSchemaForProfessionalClaim = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
  posCode: yup.string().nullable().required(requireMsg("Pos Code")),
  tosCode: yup.string().nullable().required(requireMsg("Tos Code")),
  claimStatusId: yup.number().nullable().required("Claim Status is required"),
});

export const proceduresSchemaForInsitutionalClaim = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
});

export const debitSchemaForDebit = yup.object().shape({
  debitCodeId: yup.number().nullable().required(),
  providerId: yup.number().nullable().required(),
  amount: yup.number().min(1, "").required(),
  debitDate: yup.date().nullable().required(),
});

export const alertSchema = yup.object().shape({
  alertEndDate: yup.date().nullable().required("Alert End Date is required"),
  alertStartDate: yup
    .date()
    .nullable()
    .required("Alert Start Date is required"),
});
