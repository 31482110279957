import { createSlice } from "@reduxjs/toolkit";
import {
  getDiagnosisAction,
  newDiagnosisAction,
  updateDiagnosisAction,
  getDiagnosisByIdAction,
} from "../actions/diagnosisAction";
import { toast } from "react-toastify";

const initialState = {
  getDiagnosisData: [],
  createDiagnosisData: {},
  diagnosisByIdData:{},
  error: null,
  loading: false,
};
const diagnosisSlice = createSlice({
  name: "diagnosis",
  initialState: initialState,

  extraReducers: {
    [getDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [getDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getDiagnosisData = action.payload;
    },
    [getDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "getDiagnosisDataErr");
    },
    [getDiagnosisByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getDiagnosisByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.diagnosisByIdData = action.payload;
      console.log(action.paylaod, "diagnosis slice get payload");
    },
    [getDiagnosisByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [newDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [newDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createDiagnosisData = action.payload;
      toast.success("Diagnosis has been created successfully!");
    },
    [newDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Diagnosis Creation Failed.");
    },
    [updateDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [updateDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Diagnosis Updated Successfully!");
    },
    [updateDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "DiagnosisErr");
      toast.error("Diagnosis Updation Failed.");
    },
  },
});

export default diagnosisSlice.reducer;
