import React, { useState } from "react";
import CustomDataGrid from "./../../../components/CustomDataGrid";
import {  Button, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
// import { getData } from "../../../config/axiosFunctions";
// import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import Loading from "../../../components/Loading";
import ArSearch from "./ArSearch";

const SearchedTable = ({ searchedData, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [searchedClaimData, setSearchedClaimData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isDefaultSelection, setIsDefaultSelection] = useState(true);
  const [patientName, setPatientName] = useState(null)
  // const [selectedClaimsLength, setSelectedClaimsLength] = useState(0);
  
  console.log(searchedData, "searchedData")
  console.log(isDefaultSelection, "isDefaultSelection");
  console.log(searchedClaimData, "checkSearchedData");
  const rows = searchedData.map((item) => ({
    id: item.patientId,
    ...item
    // patientFirstName: item.patientFirstName,
    // patientLastName: item.patientLastName,
    // accountNo: item.accountNo,
    // balance: item.balance,
    // lastStatement: new Date(item.lastStatement).toLocaleDateString(),
    // lastSeenDate: new Date(item.lastSeenDate).toLocaleDateString(),
    // charges: item.charges || 0,
    // clamChargesDetailDto: item.clamChargesDetailDto || [],
  }));
  const columns = [
    {
      field: "accountNo",
      headerName: "Account#",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      minWidth: 100,
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      valueGetter: (params) =>
        `${params.row.patientFirstName} ${params.row.patientLastName}`,
    },
    {
      field: "lastSeenDate",
      headerName: "Last Seen",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "lastStatement",
      headerName: "Last Statement",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "charges",
      headerName: "Charges",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Stack>0 of {params.row.clamChargesDetailDto.length || 0}</Stack>
      ),
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Button style={{fontSize:"1.1rem"}} variant="text" onClick={() => handleViewEdit(params.row)}>
          View
        </Button>
      ),
    },
  ];

  // handle view/edit
  const handleViewEdit = (data) => {
    console.log(data, "dataAr");
    setSearchedClaimData(data?.clamChargesDetailDto);
    setPatientName(`${data?.patientFirstName}, ${data?.patientLastName}`)
    setOpenViewModal(true);
    // setLoading(true);
    // try {
    //   const response = await getData(
    //     `${path}/arControl/searchPatientEdit/${accNum}`
    //   );
    //   console.log(response, "arResponse");
    //   if (response) {
    //     setSearchedClaimData(response.result);
    //     setOpenViewModal(true);
    //   } else {
    //     alert("Something Went Wrong!");
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log("view edit ar search error: ", error);
    //   throw error;
    // }
  };
  return (
    <>
      <CustomModal open={loading} handleClose={() => setLoading(false)}>
        <Loading />
      </CustomModal>
      {/* view edit modal */}
      <CustomModal
        open={openViewModal}
        handleClose={() => setOpenViewModal(false)}
      >
        <ArSearch
          searchedClaimData={searchedClaimData}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          handleClose={() => setOpenViewModal(false)}
          isDefaultSelection={isDefaultSelection}
          setIsDefaultSelection={setIsDefaultSelection}
          patientName={patientName}
        />
      </CustomModal>
      <Stack margin="10px 0" direction="row" alignItems="center">
        {/* <CustomButton></CustomButton> */}
        <CustomButton isCancel={true} handleClick={handleClose}>
          Close
        </CustomButton>
      </Stack>
      <CustomDataGrid
        rows={rows}
        columns={columns}
        handleCellClick={(params) => console.log(params, "arTableParams")}
      />
    </>
  );
};

export default SearchedTable;
