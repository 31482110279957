import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers , newUserAction , updateUserAction, updateUserPasswordAction } from "../actions/userAction";
import { toast } from "react-toastify";
import { getAllTosCodesActios } from "../actions/tosAction";

const initialState = {
    getAllTos: [],
    createTos: {},
    error: null,
    loading: false,
  };

  const tosCodeSlice = createSlice({
    name: "tosCodes",
    initialState: initialState,
  
    extraReducers: {
      [getAllTosCodesActios.pending]: (state) => {
        state.loading = true;
      },
      [getAllTosCodesActios.fulfilled]: (state, action) => {
        state.loading = false;
        state.getAllTos = action.payload;
      },
      [getAllTosCodesActios.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log(state.error, "user error");
      },
     
    }})

    export default tosCodeSlice.reducer