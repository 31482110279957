import React from "react";
import { useTheme } from "@mui/material";

const TextArea = ({
  name,
  placeholder,
  handleChange,
  isRequired,
  formik,
  handleBlur,
  value,
  minRows,
}) => {
    console.log("name" , name)
  const theme = useTheme();
  const hasError = isRequired && formik.touched[name] && formik.errors[name];

  return (
    <div>
      <textarea
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        rows={minRows}
        style={{
          background: theme.palette.customFieldsBackground.primary,
          color: theme.palette.textMainHeading.primary,
          borderRadius:'6px',
          resize: "none",
          width: "100%",
          fontSize: "1.5rem",
          padding:"5px"
        }}
      />
      {hasError && (
        <div
          style={{
            color: "red",
            // border: "1px solid red",
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
            fontSize: "1.3rem",
            marginTop: "8px",
            display: "block",
          }}
        >
          {formik.errors[name]}
        </div>
      )}
    </div>
  );
};

export default TextArea;
