// CustomAlerts.js
// amm
// import React, { useState } from 'react';
// import AddAlerts from './AddAlerts';
// import CustomButton from './CustomButton';
// import { Grid,Typography } from '@mui/material';
// import CustomModal from './CustomModal';
// import { useFormik } from 'formik';
// import { alertInitVals, patientInitValues } from '../utils/formikInitValues';

// import React, { useState, useEffect } from 'react';
// import AddAlerts from './AddAlerts';
// import CustomButton from './CustomButton';
// import { Grid, Typography, Box, IconButton } from '@mui/material';
// import CustomModal from './CustomModal';
// import { useFormik } from 'formik';
// import { alertInitVals } from '../utils/formikInitValues';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ReplayIcon from '@mui/icons-material/Replay';

// const CustomAlerts = ({ formik }) => {
//   const [openAlertForm, setOpenAlertForm] = useState(false);
//   const [alerts, setAlerts] = useState(formik.values.alertMain.alertDto);
//   const userName = localStorage.getItem('userName');
//   const userId = localStorage.getItem('userId');

//   const alertFormik = useFormik({
//     initialValues: alertInitVals,
//     onSubmit: (values) => {
//       const updatedAlertDto = [...alerts, values];
//       setAlerts(updatedAlertDto);
//       formik.setFieldValue('alertMain.alertDto', updatedAlertDto);
//       updateActiveCount(updatedAlertDto);
//       setOpenAlertForm(false);
//     },
//   });

//   const updateActiveCount = (updatedAlerts) => {
//     const activeAlerts = updatedAlerts.filter(alert => !alert.isDeleted);
//     console.log(activeAlerts,"activeAlerts")
//     formik.setFieldValue('alertMain.activeCount', activeAlerts.length);
//   };

//   useEffect(() => {
//     const updatedAlerts = formik.values.alertMain.alertDto;
//     setAlerts(updatedAlerts);
//     updateActiveCount(updatedAlerts);
//   }, [formik.values.alertMain.alertDto]);

//   const handleOpenAlert = () => {
//     alertFormik.setValues({
//       ...alertInitVals,
//       createdDate: new Date().toLocaleDateString(),
//       userId: userId,
//       userName: userName,
//     });
//     setOpenAlertForm(true);
//   };

//   const handleDelete = (index) => {
//     const updatedAlerts = alerts.map((alert, i) =>
//       i === index ? { ...alert, isDeleted: true } : alert
//     );
//     setAlerts(updatedAlerts);
//     formik.setFieldValue('alertMain.alertDto', updatedAlerts);
//     updateActiveCount(updatedAlerts);
//   };

//   const handleRestore = (index) => {
//     const updatedAlerts = alerts.map((alert, i) =>
//       i === index ? { ...alert, isDeleted: false } : alert
//     );
//     setAlerts(updatedAlerts);
//     formik.setFieldValue('alertMain.alertDto', updatedAlerts);
//     updateActiveCount(updatedAlerts);
//   };

//   return (
//     <>
//       <Grid container>
//         <Grid item md={4}>
//           <CustomButton
//             isAdd={true}
//             isBlue={true}
//             handleClick={handleOpenAlert}
//             margin={'5px'}
//           >
//             Add Alerts ({formik.values.alertMain.activeCount || 0})
//           </CustomButton>
//         </Grid>
//         <Grid container>
//           <Grid item md={12}>
//             {alerts.length > 0 ? (
//               alerts.map((alert, index) => (
//                 <Box key={index} sx={{display:'flex',width:'100%',margin:'5px'}}>
//                   <Box
//                     sx={{
//                       position: 'relative',
//                       border: '1px solid #ccc',
//                       borderRadius: '8px',
//                       padding: '16px',
//                       width:'100%',
//                       marginBottom: '16px',
//                       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                       transition: 'background-color 0.3s, transform 0.3s',
//                       backgroundColor: alert.isDeleted ? '#f8d7da' : 'inherit',
//                       pointerEvents: alert.isDeleted ? 'none' : 'auto',
//                       opacity: alert.isDeleted ? 0.5 : 1,
//                       '&:hover': {
//                         backgroundColor: alert.isDeleted ? '#f8d7da' : '#e3f2fd',
//                         transform: alert.isDeleted ? 'none' : 'scale(1.02)',
//                       },
//                     }}
//                   >
//                     {/* Alert content */}
//                     <Typography   sx={{ marginBottom: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                       <strong style={{ fontSize: '1.3rem',color:'black' }}>Alert Message:</strong>
//                       {alert.message}
//                     </Typography>
//                     <Typography   sx={{ marginBottom: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                       <strong style={{ fontSize: '1.3rem',color:'black' }}>Start Date:</strong> {alert.alertStartDate}
//                     </Typography>
//                     <Typography   sx={{ marginBottom: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                       <strong style={{ fontSize: '1.3rem',color:'black' }}>End Date:</strong> {alert.alertEndDate}
//                     </Typography>
//                     <Typography  sx={{ marginBottom: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                       <strong style={{ fontSize: '1.3rem',color:'black' }}>Created Date:</strong> {alert.createdDate}
//                     </Typography>
//                     <Typography  sx={{ marginBottom: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                       <strong style={{ fontSize: '1.3rem',color:'black' }}>User:</strong>
//                          {alert.userName}
//                     </Typography>
//                     <Typography   sx={{ marginTop: '4px',fontSize:'1.15rem',color:'#585858' }}>
//                     <strong style={{ fontSize: '1.3rem',color:'black' }}>Show On:</strong>
//                     {alert.displayOptions['Patient Section'] && ' Patient '}
//                       {alert.displayOptions['Claim Section'] && ' Claim '}
//                       {alert.displayOptions['Appointment Section'] && ' Appointment '}
//                       {alert.displayOptions['Payment Section'] && ' Payment '}
//                     </Typography>
//                   </Box>
//                   <Box
//                     sx={{
//                       position: 'relative',
//                       marginBottom: '16px',
//                     }}
//                   >
//                     {/* Icons */}
//                     <IconButton
//                       onClick={() => handleDelete(index)}
//                       sx={{
//                         position: 'absolute',
//                         top: '8px',
//                         right: '8px',
//                         color: alert.isDeleted ? '#6c757d' : 'inherit',
//                         zIndex: 1,
//                       }}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                     {alert.isDeleted && (
//                       <IconButton
//                         onClick={() => handleRestore(index)}
//                         sx={{
//                           position: 'absolute',
//                           top: '8px',
//                           right: '40px',
//                           color: '#007bff',
//                           zIndex: 1,
//                         }}
//                       >
//                         <ReplayIcon/>
//                       </IconButton>
//                     )}
//                   </Box>
//                 </Box>
//               ))
//             ) : (
//               <Typography>No alerts available</Typography>
//             )}
//           </Grid>
//         </Grid>
//         <form id='alertForm' onSubmit={alertFormik.handleSubmit}>
//           <Grid item md={12}>
//             <CustomModal
//               open={openAlertForm}
//               handleClose={() => setOpenAlertForm(false)}
//               heading="Creating Alert For Patient"
//             >
//               <AddAlerts alertFormik={alertFormik} />
//               <Grid
//                 item
//                 md={12}
//                 sx={{
//                   position: 'sticky',
//                   bottom: 0,
//                   left: 0,
//                   textAlign: 'right',
//                   background: '#fff',
//                   margin: '10px',
//                 }}
//               >
//                 <CustomButton type="submit" formId="alertForm" isBlue={true} padding="5px 15px">
//                   Done
//                 </CustomButton>
//                 <CustomButton
//                   isBlue={false}
//                   padding="5px 15px"
//                   onClick={() => setOpenAlertForm(false)}
//                 >
//                   Close
//                 </CustomButton>
//               </Grid>
//             </CustomModal>
//           </Grid>
//         </form>
//       </Grid>
//     </>
//   );
// };

// export default CustomAlerts;

import React, { useState, useEffect } from "react";
import AddAlerts from "./AddAlerts";
import CustomButton from "./CustomButton";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import CustomModal from "./CustomModal";
import { useFormik } from "formik";
import { alertInitVals } from "../utils/formikInitValues";
import { alertSchema } from "../schemas/patientSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import NotificationsIcon from "@mui/icons-material/Notifications";
import parse from "html-react-parser";
const CustomAlerts = ({ formik }) => {
  const [openAlertForm, setOpenAlertForm] = useState(false);
  const [alerts, setAlerts] = useState(formik.values.alertMain.alertDto);
  const [editIndex, setEditIndex] = useState(null);
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const alertFormik = useFormik({
    initialValues: alertInitVals,
    validationSchema:alertSchema,
    onSubmit: (values) => {
      let updatedAlerts;
      if (editIndex !== null) {
        updatedAlerts = alerts?.map((alert, i) =>
          i === editIndex ? { ...alert, ...values } : alert
        );
      } else {
        updatedAlerts = [...alerts, values];
      }
      console.log(alerts, "checkAllAlerts");
      setAlerts(updatedAlerts);
      formik.setFieldValue("alertMain.alertDto", updatedAlerts);
      setEditIndex(null); 
      setOpenAlertForm(false);
    },
  });

  // const handleOpenAlert = (alertToEdit = null) => {
  //   if (alertToEdit) {
  //     // Editing an existing alert
  //     setEditIndex(alerts.indexOf(alertToEdit));
  //     alertFormik.setValues({
  //       ...alertToEdit,
  //       createdDate: alertToEdit.createdDate, // Preserve existing created date
  //       userId: alertToEdit.userId,
  //       userName: alertToEdit.userName,
  //     });
  //   } else {
  //     // Creating a new alert
  //     alertFormik.setValues({
  //       ...alertInitVals,
  //       createdDate: new Date().toLocaleDateString(),
  //       userId: userId,
  //       userName: userName,
  //     });
  //   }
  //   setOpenAlertForm(true);
  //  };

  //    // handle delete
  // const handleDeleteUndo = (isDeleted, index) => {
  //   const updatedList = alerts?.map((el, i) =>
  //     i === index ? { ...note, isDeleted: !isDeleted } : note
  //   );
  //   formik.setValues((preVals) => ({
  //     ...preVals,
  //     noteDetailDto: updatedList,
  //   }));
  // };
  return (
    <>
      <Grid container>
        <Grid item md={4}>
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => {
              setEditIndex(null);
              alertFormik.setValues({
                ...alertInitVals,
                createdDate: new Date().toLocaleDateString(),
                userId: userId,
                userName: userName,
              });
              setOpenAlertForm(true);
            }}
            margin={"5px"}
          >
            Add Alerts
          </CustomButton>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            {alerts.length > 0 &&
              alerts.map((alert, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", width: "100%", margin: "5px" }}
                >
                  <Box>
                    <IconButton
                      onClick={() => {
                        setEditIndex(index);
                        setOpenAlertForm(true);
                      }}
                      sx={{
                        marginTop: "4rem",
                        height: "10px",
                      }}
                    >
                      <NotificationsIcon
                        sx={{ fontSize: "1.7rem", color: "black" }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "16px",
                      width: "100%",
                      marginBottom: "16px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      transition: "background-color 0.3s, transform 0.3s",
                      backgroundColor: alert.isDeleted ? "#f8d7da" : "inherit",
                      pointerEvents: alert.isDeleted ? "none" : "auto",
                      opacity: alert.isDeleted ? 0.5 : 1,
                      "&:hover": {
                        backgroundColor: alert.isDeleted
                          ? "#f8d7da"
                          : "#e3f2fd",
                        transform: alert.isDeleted ? "none" : "scale(1.02)",
                      },
                    }}
                    onClick={() => {
                      setEditIndex(index);
                      setOpenAlertForm(true);
                    }}
                  >
                    {/* Alert content */}
                    <Typography
                      sx={{
                        marginBottom: "4px",
                        fontSize: "1.8rem",
                        color: "#585858",
                      }}
                    >
                      {parse(alert.message)}
                    </Typography>
                    {/* <Typography sx={{ marginBottom: '4px', fontSize: '1.15rem', color: '#585858' }}>
                      <strong style={{ fontSize: '1.3rem', color: 'black' }}>Start Date:</strong> {alert.alertStartDate}
                    </Typography> */}
                    <Typography
                      sx={{
                        marginBottom: "4px",
                        fontSize: "1.15rem",
                        color: "#585858",
                      }}
                    >
                      <strong style={{ fontSize: "1.3rem", color: "black" }}>
                        Created By{" "}
                      </strong>
                      {alert.userName}
                      <strong style={{ fontSize: "1.3rem", color: "black" }}>
                        {" "}
                        on{" "}
                      </strong>
                      {alert.createdDate}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: "4px",
                        fontSize: "1.15rem",
                        color: "#585858",
                      }}
                    >
                      <strong style={{ fontSize: "1.3rem", color: "black" }}>
                        Show On:
                      </strong>
                      {alert?.displayOptions
                        ?.map(
                          (desc, ind) => desc?.displayOptionsName?.split(" ")[0]
                        )
                        ?.join(",")}
                    </Typography>
                  </Box>
                  {/* <CustomButton
                    // isDelete={!note?.isDeleted}
                    // isUndo={note?.isDeleted && true}
                    isBlue={false}
                    // handleClick={() => handleDeleteUndo(note?.isDeleted, index)}
                  /> */}
                </Box>
              ))}
          </Grid>
        </Grid>
        <form id="alertForm" onSubmit={alertFormik.handleSubmit}>
          <Grid item md={12}>
            <CustomModal
              open={openAlertForm}
              handleClose={() => setOpenAlertForm(false)}
              heading={
                editIndex !== null ? "Edit Alert" : "Creating Alert For Patient"
              }
            >
              <AddAlerts alertFormik={alertFormik} />
              <Grid
                item
                md={12}
                sx={{
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  textAlign: "right",
                  background: "#fff",
                  margin: "10px",
                }}
              >
                <CustomButton
                  type="submit"
                  formId="alertForm"
                  isBlue={true}
                  padding="5px 15px"
                >
                  Done
                </CustomButton>
              </Grid>
            </CustomModal>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CustomAlerts;
