// import { Box, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import CustomButton from "../../../components/CustomButton";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   getRemittanceAction,
//   getRemittanceBySearchTermAction,
// } from "../../../features/actions/remittanceAction";
// import CustomModal from "./../../../components/CustomModal";
// import RemarkTable from "./RemarkTable";
// import AdjTable from "./AdjTable";
// import CustomPreview from "../../../layout/CustomPreview";
// import { useEffect, useState } from "react";
// import { remittanceCols } from "../../../utils/gridCols";
// import { ButtonGroup } from "react-bootstrap";
// import { getData } from "../../../config/axiosFunctions";
// import path from "../../../config/apiUrl";

// const Remittance = () => {
//   const navigate = useNavigate();
//   const [alignment, setAlignment] = useState(2)
//   const [rows, setRows] = useState([])
//   const [openRemarkTable, setOpenRemarkTable] = useState(false);
//   const [openAdjTableModal, setOpenAdjTableModal] = useState(false);
//   // const { getRemittance, loading, tabNo } = useSelector((state) => state.remittance);
//   const { getRemittance, loading, tabNo } = useSelector((state) => state.remittance);
//   const handleCell = (params) => {
//     navigate(`/remittance/update/${params.id}`);
//   };

//   const handleRemTypeChange = async (e) => {
//     const newAlignment = Number(e.target.value);
//     setAlignment(newAlignment)
//   }

//   useEffect(() => {
//     const fetchRemittanceByType = async () => {
//       try{
//         const response = await getData(`${path}/remittanceCode/remittanceTypeId?remittanceTypeId=${alignment}&page=1&pageSize=20`)
//         if(response.result.length > 0) {
//           setRows(response.result)
//         }
//       }catch (e) {
//         console.log(e, "Error")
//       }
//     }

//     fetchRemittanceByType()
//   }, [alignment, setAlignment])
//   return (
//     <>
//       <Box>
//       <ToggleButtonGroup
  
//       sx={{
    
//         '& .Mui-selected': {
//           color:"#fff !important",
//           background:"#1768C2 !important",

//         }
//       }}
//       value={alignment}
//       exclusive
//       onChange={handleRemTypeChange}
//       aria-label="Platform"
//     >
//       <ToggleButton value={2}>Adjustment</ToggleButton>
//       <ToggleButton value={1}>Remark</ToggleButton>
//     </ToggleButtonGroup>
//         <CustomPreview
//           rows={ 
//               rows && rows?.length > 0
//                 ? rows?.map((item) => ({
//                     id: item.remittanceCodeId,
//                     ...item,
//                   }))
//                 : []}
//           columns={remittanceCols}
//           moduleHeading="All Remittance"
//           searchLabel="Search by Code, or Description"
//           paginationDetail={getRemittance}
//           handleGetAll={getRemittanceAction}
//           handleBySearch={getRemittanceBySearchTermAction}
//           loading={loading}
//           handleCell={handleCell}
//           showNew={true}
//           navPath={"/remittance/new"}
//         />
//       </Box>

//       {/* custom modals here */}
//       <CustomModal
//         open={openRemarkTable}
//         handleClose={() => setOpenRemarkTable(false)}
//       >
//         <RemarkTable handleClose={() => setOpenRemarkTable(false)} />
//       </CustomModal>

//       <CustomModal
//         open={openAdjTableModal}
//         handleClose={() => setOpenAdjTableModal(false)}
//       >
//         <AdjTable handleClose={() => setOpenAdjTableModal(false)} />
//       </CustomModal>
//     </>
//   );
// };

// export default Remittance;




// static...
// import { Box, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import CustomButton from "../../../components/CustomButton";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   getRemittanceAction,
//   getRemittanceBySearchTermAction,
// } from "../../../features/actions/remittanceAction";
// import CustomModal from "./../../../components/CustomModal";
// import RemarkTable from "./RemarkTable";
// import AdjTable from "./AdjTable";
// import CustomPreview from "../../../layout/CustomPreview";
// import { useEffect, useState } from "react";
// import { remittanceCols } from "../../../utils/gridCols";
// import { ButtonGroup } from "react-bootstrap";
// import { getData } from "../../../config/axiosFunctions";
// import { fetchRemittanceByTypeAction } from "../../../features/actions/remittanceAction";
// import path from "../../../config/apiUrl";

// const Remittance = () => {
//   const navigate = useNavigate();
//   const [alignment, setAlignment] = useState(2)
//   // const [rows, setRows] = useState([])
//   const [openRemarkTable, setOpenRemarkTable] = useState(false);
//   const [openAdjTableModal, setOpenAdjTableModal] = useState(false);
//   // const { getRemittance, loading, tabNo } = useSelector((state) => state.remittance);
//   const { getRemittance, loading} = useSelector((state) => state.remittance);
//   console.log(getRemittance,"getRemitance")
//   const handleCell = (params) => {
//     navigate(`/remittance/update/${params.id}`);
//   };

//   const handleRemTypeChange = async (e) => {
//     const newAlignment = Number(e.target.value);
//     setAlignment(newAlignment)
//   }


//   return (
//     <>
//       <Box>
//       <ToggleButtonGroup
  
//       sx={{
    
//         '& .Mui-selected': {
//           color:"#fff !important",
//           background:"#1768C2 !important",

//         }
//       }}
//       value={alignment}
//       exclusive
//       onChange={handleRemTypeChange}
//       aria-label="Platform"
//     >
//       <ToggleButton value={2}>Adjustment</ToggleButton>
//       <ToggleButton value={1}>Remark</ToggleButton>
//     </ToggleButtonGroup>
//         <CustomPreview
//           rows={ 
//             getRemittance && getRemittance?.result?.length > 0
//                 ? getRemittance?.result?.map((item) => ({
//                     id: item.remittanceCodeId,
//                     ...item,
//                   }))
//                 : []}
//           // rows={rows}
//           columns={remittanceCols}
//           moduleHeading="All Remittance"
//           searchLabel="Search by Code, or Description"
//           paginationDetail={getRemittance}
//           // handleGetAll={getRemittanceAction}
//           handleGetAll={fetchRemittanceByTypeAction}
//           handleBySearch={getRemittanceBySearchTermAction}
//           loading={loading}
//           handleCell={handleCell}
//           showNew={true}
//           navPath={"/remittance/new"}
//         />
//       </Box>

//       {/* custom modals here */}
//       <CustomModal
//         open={openRemarkTable}
//         handleClose={() => setOpenRemarkTable(false)}
//       >
//         <RemarkTable handleClose={() => setOpenRemarkTable(false)} />
//       </CustomModal>

//       <CustomModal
//         open={openAdjTableModal}
//         handleClose={() => setOpenAdjTableModal(false)}
//       >
//         <AdjTable handleClose={() => setOpenAdjTableModal(false)} />
//       </CustomModal>
//     </>
//   );
// };

// export default Remittance;




import { Box, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getRemittanceAction,
  getRemittanceBySearchTermAction,
} from "../../../features/actions/remittanceAction";
import CustomModal from "./../../../components/CustomModal";
import RemarkTable from "./RemarkTable";
import AdjTable from "./AdjTable";
import CustomPreview from "../../../layout/CustomPreview";
import { useEffect, useState } from "react";
import { remittanceCols } from "../../../utils/gridCols";
import { ButtonGroup } from "react-bootstrap";
import { getData } from "../../../config/axiosFunctions";
import { fetchRemittanceByTypeAction } from "../../../features/actions/remittanceAction";
import path from "../../../config/apiUrl";

const Remittance = () => {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const [alignment, setAlignment] = useState(2)
  const [openRemarkTable, setOpenRemarkTable] = useState(false);
  const [openAdjTableModal, setOpenAdjTableModal] = useState(false);
  const { getRemittance, loading} = useSelector((state) => state.remittance);
  console.log(getRemittance,"getRemitance")
  const handleCell = (params) => {
    navigate(`/remittance/update/${params.id}`);
  };

  const handleRemTypeChange = async (e) => {
    const newAlignment = Number(e.target.value);
    setAlignment(newAlignment)
  }

  return (
    <>
      <Box>
      {/* <ToggleButtonGroup
  
      sx={{
    
        '& .Mui-selected': {
          color:"#fff !important",
          background:"#1768C2 !important",

        }
      }}
      value={alignment}
      exclusive
      onChange={handleRemTypeChange}
      aria-label="Platform"
    >
      <ToggleButton value={2}>Adjustment</ToggleButton>
      <ToggleButton value={1}>Remark</ToggleButton>
    </ToggleButtonGroup> */}
        <CustomPreview
          rows={ 
            getRemittance && getRemittance?.result?.remittanceCode?.length > 0
                ? getRemittance?.result?.remittanceCode?.map((item) => ({
                    id: item.remittanceCodeId,
                    ...item,
                  }))
                : []}

          columns={remittanceCols}
          moduleHeading="All Remittance"
          searchLabel="Search by Code, or Description"
          paginationDetail={getRemittance}
          handleGetAll={fetchRemittanceByTypeAction}
          handleBySearch={getRemittanceBySearchTermAction}
          loading={loading}
          handleCell={handleCell}
          showNew={true}
          navPath={"/remittance/new"}
          isType={true}
        />
      

      </Box>

      {/* custom modals here */}
      <CustomModal
        open={openRemarkTable}
        handleClose={() => setOpenRemarkTable(false)}
      >
        <RemarkTable handleClose={() => setOpenRemarkTable(false)} />
      </CustomModal>

      <CustomModal
        open={openAdjTableModal}
        handleClose={() => setOpenAdjTableModal(false)}
      >
        <AdjTable handleClose={() => setOpenAdjTableModal(false)} />
      </CustomModal>
    </>
  );
};

export default Remittance;