import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkDuplicatePatAction,
  getPatientByAccountNoAction,
  updatePatientAction,
} from "../../features/actions/createPatientAction";
import { Dropdown } from "react-bootstrap";
import PrintIcon from "@mui/icons-material/Print";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { useFormik } from "formik";
import { patientInitValues } from "../../utils/formikInitValues";
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  useTheme,
  Typography,
  Paper,
} from "@mui/material";
import PatientInfo from "./PatientInfo";
import MainInsurance from "./MainInsurance";
import BillingInfo from "./BillingInfo";
import ClaimDefaults from "./ClaimDefaults";
import CustomButton from "../../components/CustomButton";
// import MainHeading from "../../components/MainHeading";
import { useSpring, animated } from "react-spring";
// import AccountSummary from "../../components/AccountSummary";
import fingerTouchLogo from "../../assets/finger_touch.png";
// import { handleCancel } from "./../../utils/customFunctions";
import { toast } from "react-toastify";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loading from "../../components/Loading";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
import { patientSchema } from "../../schemas/patientSchema";
import CustomModal from "../../components/CustomModal";
// import { getClaimAcivityActionSingle } from "../../features/actions/claimAction";
import { getPatientClaimsAction } from "../../features/actions/createPatientAction";
import { patientMultipleClaimCols } from "../../utils/gridCols";
import CustomPreview from "../../layout/CustomPreview";
// import { Link } from "react-router-dom";
// import { useConfirm } from "material-ui-confirm";
import CustomDialog from "../../components/CustomDialog";
import DuplicatePat from "./DuplicatePat";
import CustomSummary from "../../components/CustomSummary";
import CheckBox from "../../components/CheckBox";
import parse from "html-react-parser";

const UpdatePatient = () => {
  let flage = false;

  const { accountNo } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const confirm = useConfirm();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [openAccountSummary, setOpenAccountSummary] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openDuplicateMod, setOpenDuplicateMod] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [ openAlertDialog,setOpenAlertDialog] = useState(false)

  const {
    singlePatientData,
    singlePatientLoading,
    duplicateData,
    duplicateLoading,
  } = useSelector((state) => state.patient);
  const { patientClaimsData } = useSelector((state) => state.patient);
  //   formik here
  const formik = useFormik({
    initialValues: singlePatientData?.result || patientInitValues,
    validationSchema: patientSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        dispatch(
          updatePatientAction({
            accountNo: singlePatientData?.result?.accountNo,
            ...values,
          })
        );
        // toast.success("Patient updated successfully");
        navigate(`/patients`);
      } catch (error) {
        toast.error("Patient updation failed");
        console.error("Error updating patient:", error);
      }
    },
  });

  //   handle tab change
  const handleTabChange = (event, newValue) => {
    setIsFormVisible(false);
    setTimeout(() => {
      setTabValue(newValue);
      setIsFormVisible(true);
    }, 400);
  };
  // handleAccountSummary
  const handleAccountSummary = useCallback(() => {
    setOpenAccountSummary(true);
  }, []);

  const accountSummaryProps = useSpring({
    width: openAccountSummary ? 450 : 0,
    right: openAccountSummary ? 0 : -70, // Start off-screen
    from: { right: -30 },
    config: { duration: 600 }, // Adjust duration as needed
  });

  // handle duplicate
  const handleDuplicate = (args) => {
    dispatch(checkDuplicatePatAction(args)).then(({ payload }) => {
      if (typeof payload.result === "string") {
        setOpenDuplicateMod(false);
        toast.info(payload.result);
      } else {
        setOpenDuplicateMod(true);
      }
    });
  };

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    navigate("/patients");
  };

  useEffect(() => {
    try {
    if (!flage) {
        flage = true;
        dispatch(getPatientByAccountNoAction(accountNo)).then(({payload}) => {
          if(payload.result.alertMain.alertDto.length > 0) {
            setOpenAlertDialog(true);
          }else {
            setOpenAlertDialog(false);
          }
        });
      }
    }catch (e) {
      console.log(e, ": error in fetching single patients")
    }
  }, []);
 
  return (
    <>
      <Box margin="20px" >
        {/* <MainHeading mainheading="Edit Patient">
          <Link
            to={`/patient/update/${formik?.values?.accountNo}`}
            style={linkStyle}
          >
            {formik?.values?.firstName} {formik?.values?.lastName} (
            {formik?.values?.accountNo})
          </Link>
        </MainHeading> */}
        <Box>

    
        </Box>
        <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
          <CustomButton
            type="submit"
            isBlue={true}
            formId="patientForm"
            isEdit={true}
            // width='70%'
            // height='10%'
            // padding={"20px"}
          >
            Update
          </CustomButton>
          <Dropdown className="print">
            <Dropdown.Toggle
              // className="printDropDown"
              size="medium"
              style={{
                fontSize: "1.3rem",
                fontWeight: "600",
                fontFamily: "Inter",
                letterSpacing: "1.24px",
                wordSpacing: "3px",
                textTransform: "inherit",
                padding: "6px 5px",
                wordBreak: "keep-all",
                wordWrap: "normal",
              }}
              // style={{ padding: "13px",fontSize:"20px",width:"70%"}}
            >
              <PrintIcon sx={{ margin: "0 5px 0 0", fontSize: "20px" }} />
              Print
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Patient Info Sheet & Ledger
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Patient Statment
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Final Demand Notice
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Superbill
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Label
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="print">
            <Dropdown.Toggle
              size="medium"
              style={{
                fontSize: "1.3rem",
                fontWeight: "600",
                fontFamily: "Inter",
                letterSpacing: "1.24px",
                wordSpacing: "3px",
                textTransform: "inherit",
                padding: "6px 5px",
              }}
            >
              <MergeTypeIcon sx={{ margin: "0 5px 0 0", fontSize: "18px" }} />
              Merge
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Merge Patients
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Undo Merge
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="print">
            <Dropdown.Toggle
              size="medium"
              style={{
                fontSize: "1.3rem",
                fontWeight: "600",
                fontFamily: "Inter",
                letterSpacing: "1.24px",
                wordSpacing: "3px",
                textTransform: "inherit",
                padding: "6px 5px",
              }}
            >
              <PersonIcon />
              Eligibility
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                New Eligibility Check
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <CustomButton
            // type="submit"
            handleClick={() => {
              if (formik?.values?.patientId) {
                navigate(
                  `/patients/acitvity/${formik?.values?.patientId}/multipleclaim`
                );
              }
            }}
            isBlue={true}
            // width='70%'
            // height='10%'
            // padding={"20px"}
          >
            Activity
          </CustomButton>
          {/* <CustomButton
              // type="submit"
              isBlue={true}
              width="fit-content"

              // height='10%'
              // padding={"20px"}
              
            >
              Appointments
            </CustomButton> */}
          <Dropdown className="print">
            <Dropdown.Toggle
              size="medium"
              style={{
                fontSize: "1.3rem",
                fontWeight: "600",
                fontFamily: "Inter",
                letterSpacing: "1.24px",
                wordSpacing: "3px",
                textTransform: "inherit",
                padding: "6px 5px",
              }}
            >
              <MoreVertIcon />
              More
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  handleDuplicate({
                    first: singlePatientData?.result?.firstName,
                    last: singlePatientData?.result?.lastName,
                    pacctno: singlePatientData?.result?.accountNo,
                    birthdate: singlePatientData?.result?.dateOfBirth,
                  })
                }
              >
                Check for Duplicate Patients
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Update Claim w/Current Insurance
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOpenClaimModal(true)}>
                View All Claims
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Track Clam Submission History
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  navigate(`/claims/new`, {
                    state: {
                      accountNo: accountNo,
                      claimType: 1,
                    },
                  })
                }
              >
                Create Professional Claim for Patient
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  navigate(`/claims/new`, {
                    state: {
                      accountNo: accountNo,
                      claimType: 2,
                    },
                  })
                }
              >
                Create Institutional Claim for Patient
              </Dropdown.Item>
              <Dropdown.Item
              // onClick={handleSaveAndPreviewClick}
              >
                Create Appointment for Patient
              </Dropdown.Item>
              {console.log("singlePatientData?.result", formik.values)}
              <Dropdown.Item
                onClick={() =>
                  navigate(`/payment`, {
                    state: {
                      accountNo: accountNo,
                      patientId: formik.values?.patientId,
                      patientName:
                        formik.values?.firstName +
                        " " +
                        formik.values?.lastName,
                    },
                  })
                }
              >
                Post Payment for Patient
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate(`/patients/payment-plans/${accountNo}`)}
              >
                Create Payment Plan
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  navigate(`/patients/manage-account/${accountNo}`)
                }
              >
                Open in Manage Account
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Stack
          sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
          // alignItems="center"
          justifyContent="space-between"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              order: { xs: 2, sm: 2, md: 1 },
              marginBottom: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Patient Info"
              value={0}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Insurance Information"
              value={1}
            />

            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Billing Info"
              value={2}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Claim Defaults"
              value={3}
            />
          </Tabs>

          <Stack sx={{order:"2"}}>
          <CheckBox  CheckedValue={!formik.values.isActive} handleChange={() => formik.setFieldValue("isActive", !formik.values.isActive)} label="Make this patient inactive"/>
        </Stack>
        </Stack>
        <form
         id="patientForm"
         onSubmit={formik.handleSubmit}
         className={`formContainer ${!isFormVisible ? "hidden" : ""}`}
        >

          {singlePatientLoading ? (
            <Loading />
          ) : (
            <>
              {tabValue === 0 && <PatientInfo formik={formik} />}
              {tabValue === 1 && <MainInsurance formik={formik} />}
              {tabValue === 2 && <BillingInfo formik={formik} />}
              {tabValue === 3 && <ClaimDefaults formik={formik} />}
            </>
          )}
        </form>

        {/* {openAccountSummary ? (
          <animated.div
            style={{
              width: "30%",
              position: "fixed",
              top: "40%",
              overflow: "hidden",
              zIndex: 1,
              ...accountSummaryProps, // Apply animated styles
            }}
          > */}
            {/* <AccountSummary
              formik={formik}
              accountNo={Number(accountNo)}
              handleClose={() => setOpenAccountSummary(false)}
            /> */}
            {/* <CustomSummary
              formik={formik}
              handleClose={() => setOpenAccountSummary(false)}
              isPatientSummary={true}
              isNotes={true}
            />
          </animated.div>
        ) : (
          <Button
            style={{ background: theme.palette.accSummary.primary }}
            className="accountSummaryBtn"
            onClick={handleAccountSummary}
          >
            <span>
              <img
                src={fingerTouchLogo}
                alt="finger-touch-logo"
                style={{ marginRight: "5px" }}
              />
            </span>
            <span>Account Summary</span>
          </Button>
        )} */}

        <CustomModal
          open={openClaimModal}
          handleClose={() => setOpenClaimModal(false)}
          heading={"Claim"}
        >
          <CustomPreview
            isModal={true}
            rows={
              patientClaimsData &&
              patientClaimsData.result &&
              patientClaimsData.result.length > 1
                ? patientClaimsData.result.map((el) => ({
                    id: el?.claimId,
                    ...el,
                  }))
                : []
            }
            columns={patientMultipleClaimCols}
            handleGetAll={() => getPatientClaimsAction(accountNo)}
            handleCell={(params) => {
              console.log(params.claimNumber, "ClaimNumber");
              navigate(`/claims/update/${params.claimNumber}`);
            }}
          />
        </CustomModal>
        {/* duplicate */}
        {duplicateLoading && (
          <CustomModal isLoading={true} open={duplicateLoading}>
            <Loading />
          </CustomModal>
        )}
        <CustomModal
          open={openDuplicateMod}
          heading="Duplicate Patients"
          handleClose={() => setOpenDuplicateMod(false)}
        >
          <DuplicatePat
            handleClose={() => setOpenDuplicateMod(false)}
            duplicateData={duplicateData}
          />
        </CustomModal>
      </Box>

      {/* summary */}
      {openAccountSummary ? (
        <animated.div
          style={{
            width: "30%",
            position: "fixed",
            top: "40%",
            overflow: "hidden",
            zIndex: 1,
            ...accountSummaryProps, // Apply animated styles
          }}
        >
          <CustomSummary
            formik={formik}
            handleClose={() => setOpenAccountSummary(false)}
            isAlert={true}
            isNotes={true}
          />
        </animated.div>
      ) : (
        <Button
          style={{ background: theme.palette.accSummary.primary }}
          className="accountSummaryBtn"
          onClick={handleAccountSummary}
        >
          <span>
            <img
              src={fingerTouchLogo}
              alt="finger-touch-logo"
              style={{ marginRight: "5px" }}
            />
          </span>
          <span>Account Summary</span>
        </Button>
      )}

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
       

      <CustomDialog title={`Alerts for patient ${singlePatientData?.result?.firstName}, ${singlePatientData?.result?.lastName}`} open={openAlertDialog} handleClose={() => setOpenAlertDialog(false)} confirmButtonTitle={"Ok"} handleConfirm={() => setOpenAlertDialog(false)}>
        <Stack gap="5px">
          {
            singlePatientData?.result?.alertMain?.alertDto.map(alertData => (
              <Paper elevation={3} key={alertData?.alertId} sx={{padding:"8px 12px", minWidth:"400px"}}>
                <Typography component={"h4"}>{parse(alertData?.message)}</Typography>

                <Stack>
                  <Typography component="p" fontSize={"1.3rem"} fontWeight={300}>By: {alertData?.userName} on {alertData?.createdDate}</Typography>
                  <Typography component="p" fontSize={"1.3rem"} fontWeight={300}>Patient: {singlePatientData?.result?.firstName}, {singlePatientData?.result?.lastName}</Typography>
                </Stack>
              </Paper>
            ))
          }
        </Stack>
      </CustomDialog>
    </>
  );
};

export default UpdatePatient;
