export const medicareCarrierAndLocality = [
  { id: 1, ctOption: "Use Your Zip Code" },
  { id: 2, ctOption: "Enter The Carrier And Locality" },
];

export const pricingMethod = [
  { id: 1, ctOption: "Non Facility Pricing" },
  { id: 2, ctOption: "Facility Pricing" },
];

export const adjustPrices = [
  { id: 1, ctOption: "Do not adjust the prices of the new fee schedule " },
  { id: 2, ctOption: "Increase prices by" },
  { id: 3, ctOption: "Decrease prices by" },
];

export const scheduleConfiguration = [
  {
    id: 1,
    configType: "Apply based on payer, payer type or patient",
    typesList: [
      {
        typeId: 1,
        typeName: "Payer(based on the primary payer)",
      },
      {
        typeId: 2,
        typeName: "Payer Type(based on the primary payer)",
      },
      {
        typeId: 3,
        typeName: "Patient Account Type",
      },
    ],
  },
  {
    id: 2,
    configType: "Apply based on location or provider",
    typesList: [
      {
        typeId: 4,
        typeName: "Provider(based on the Rendering provider)",
      },
      {
        typeId: 5,
        typeName: "Practice",
      },
      {
        typeId: 6,
        typeName: "Facility",
      },
    ],
  },
];
