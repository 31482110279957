import { createSlice } from "@reduxjs/toolkit";
import { getClaimReviewAction, postClaimReviewAction } from "../actions/claimSubmissionAction";


const initialState = {
    claimSubmissionData: [],
    postClaimReview: {},
    error: null,
    loading: false,
}
const claimSubmissionSlice = createSlice({
    name: "submission",
    initialState: initialState,
    extraReducers: {
        [getClaimReviewAction.pending] : (state) => {
            state.loading = true;
            state.error = null;
        },
        [getClaimReviewAction.fulfilled] : (state, action) => {
            state.loading = false;
            state.claimSubmissionData = action.payload
            state.error = null;
        },
        [getClaimReviewAction.rejected] : (state, action) => {
            state.loading = false;
            console.log(action.payload, "checkPayload");
        },
        [postClaimReviewAction.pending] : (state) => {
            state.loading = true;
            state.error = null;
        },
        [postClaimReviewAction.fulfilled] : (state, action) => {
            state.loading = false;
            state.postClaimReview = action.payload
            state.error = null;
        },
        [postClaimReviewAction.rejected] : (state, action) => {
            state.loading = false;
            console.log(action.payload, "checkPayload");
        },
    }
})

export default claimSubmissionSlice.reducer