import { Box, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { claimCols } from "../../../utils/gridCols";
import {
  getClaimReviewAction,
  postClaimReviewAction,
} from "../../../features/actions/claimSubmissionAction";
import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
import { toast } from "react-toastify";
import { CheckCircle } from "@mui/icons-material";
import CustomModal from "./../../../components/CustomModal";
import StatusError from "./StatusError";

const ClaimSubmission = () => {
  const dispatch = useDispatch();
  const [selectedClaims, setSelectedClaims] = useState([]);
  const { claimSubmissionData, loading } = useSelector(
    (state) => state.claimSubmission
  );
  const [columns, setColumns] = useState([...claimCols]);
  const [currentStatusRow, setCurrentStatusRow] = useState(null);
  const [openErrModal, setOpenErrModal] = useState(false);
  const rows =
    claimSubmissionData && claimSubmissionData.result?.length > 0
      ? claimSubmissionData.result?.map((el) => ({
          id: el.claimId,
          ...el,
        }))
      : [];
  // handle detect error
  const handleDetectError = (params, errorList) => {
    const findSelectedErr = errorList?.find(
      (el) => el?.claimNo === params?.claimId
    );
    if (findSelectedErr) {
      setCurrentStatusRow(findSelectedErr);
      setOpenErrModal(true);
    } else {
      setCurrentStatusRow(null);
      setOpenErrModal(false);
    }
  };

  // handle review claim
  const handlePostReviewClaim = async () => {
    const data = selectedClaims?.map((el) => ({ claimId: el }));
    try {
      if (data.length > 0) {
        dispatch(postClaimReviewAction(data)).then(({ payload }) => {
          console.log(payload, "checkPayload");

          // Filter only reviewed claims
          const reviewedClaims = payload.result.errorList.filter((err) =>
            selectedClaims.includes(err.claimNo)
          );

          if (reviewedClaims.length > 0) {
            setColumns((prevColumns) => {
              const existingReviewColIndex = prevColumns.findIndex(
                (col) => col.headerName === "Review Status"
              );
              if (existingReviewColIndex !== -1) {
                const updatedColumns = [...prevColumns];
                updatedColumns[existingReviewColIndex] = {
                  ...updatedColumns[existingReviewColIndex],
                  renderCell: (params) => {
                    const findErrClaim = reviewedClaims.find(
                      (er) => er.claimNo === params.row.claimId
                    );

                    return findErrClaim?.isError ? (
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDetectError(params.row, reviewedClaims)
                        }
                      >
                        Errors Detected
                      </Link>
                    ) : selectedClaims.includes(params.row.claimId) ? (
                      <Typography component={"h5"}>
                        <CheckCircle
                          sx={{ color: "green", fontSize: "20px" }}
                        />{" "}
                        No issue found
                      </Typography>
                    ) : null;
                  },
                };
                return updatedColumns;
              }

              // If column doesn't exist, add it
              return [
                ...prevColumns,
                {
                  field: "",
                  headerName: "Review Status",
                  minWidth: 150,
                  flex: 2,
                  headerAlign: "left",
                  align: "left",
                  headerClassName: "header-bg",
                  cellClassName: "cell-content",
                  renderCell: (params) => {
                    const findErrClaim = reviewedClaims.find(
                      (er) => er.claimNo === params.row.claimId
                    );

                    return findErrClaim?.isError ? (
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDetectError(params.row, reviewedClaims)
                        }
                      >
                        Errors Detected
                      </Link>
                    ) : selectedClaims.includes(params.row.claimId) ? (
                      <Typography component={"h5"}>
                        <CheckCircle
                          sx={{ color: "green", fontSize: "20px" }}
                        />{" "}
                        No issue found
                      </Typography>
                    ) : null;
                  },
                },
              ];
            });
          }
        });
      } else {
        toast.info("Please select a claim.");
      }
    } catch (e) {
      console.log(e, "handlePostReviewClaimError");
    }
  };

  return (
    <Box>
      <Stack marginLeft="20px" direction={"row"} alignItems={"center"} gap={"15px"}>
        <CustomButton isBlue={false} handleClick={handlePostReviewClaim}>
          Review
        </CustomButton>
        {/* <CustomButton isBlue={true}>Submit Claims</CustomButton> */}
      </Stack>

      <MultiSelectCustomPreview
        rows={rows}
        columns={columns}
        paginationDetail={claimSubmissionData}
        moduleHeading="All Claims"
        loading={loading}
        handleGetAll={getClaimReviewAction}
        setState={setSelectedClaims}
        state={selectedClaims}
      />

      {/* custom modal */}
      <CustomModal
        heading="Claim Review Result"
        open={openErrModal}
        handleClose={() => setOpenErrModal(false)}
      >
        <StatusError errData={currentStatusRow} />
      </CustomModal>
    </Box>
  );
};

export default ClaimSubmission;
