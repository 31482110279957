import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getRevenueAction,
  newRevenueAction,
  updateRevenueAction,
  getRevenueyByIdAction,
} from "../actions/revenueAction";

const initialState = {
  getRevenueData: [],
  createRevenueData: {},
  revenueById:{},
  error: null,
  loading: false,
};
const revenueSlice = createSlice({
  name: "revenue",
  initialState: initialState,

  extraReducers: {
    [getRevenueAction.pending]: (state) => {
      state.loading = true;
    },
    [getRevenueAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getRevenueData = action.payload;
    },
    [getRevenueAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getRevenueyByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getRevenueyByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.revenueById = action.payload;
      console.log(action.paylaod, "revenue slice get payload");
    },
    [getRevenueyByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [newRevenueAction.pending]: (state) => {
      state.loading = true;
    },
    [newRevenueAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createRevenueData = action.payload;
      toast.success("Revenue created successfully!");
    },
    [newRevenueAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Revenue creation failed");
    },
    [updateRevenueAction.pending]: (state) => {
      state.loading = true;
    },
    [updateRevenueAction.fulfilled]: (state, action) => {
      state.loading = false;
      // const findRevenueIndex = state.getRevenueData.result?.findIndex(
      //   (val) => val.revenueCodeId === action.payload.revenueCodeId
      // );
      // console.log(findRevenueIndex, "checkRevenueIndex");
      // if (findRevenueIndex !== -1) {
      //   state.getRevenueData.result[findRevenueIndex] = {
      //     ...state.getRevenueData.result[findRevenueIndex],
      //     ...action.payload,
      //   };
      // }
      toast.success("Revenue Updated Successfully!");
    },
    [updateRevenueAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Revenue Updation failed");
    },
  },
});

export default revenueSlice.reducer;
