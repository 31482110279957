import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, useTheme, Checkbox, FormControl, FormControlLabel, Radio, Stack, FormGroup } from "@mui/material";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import CustomField from '../../../components/CustomField';
import CustomAddress from "../../../components/CustomAddress";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomModal from "../../../components/CustomModal";
import CustomSearchField from '../../../components/CustomSearchField';
import SearchNpi from "../practices/npi/SearchNpi";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CheckBox from "../../../components/CheckBox";
import { Done } from "@mui/icons-material";
import { handleIsActive } from "../../../utils/customFunctions";

const ReferringProviderInfo = ({ formik }) => {
  console.log(formik,"refformik")
  const theme = useTheme();
  const navigate = useNavigate();
  const [taxIdTypeOpt, setTaxIdTypeOpt] = useState([]);
  const [referringTypeOpt, setReferringTypeOpt] = useState([]);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [taxonomyListModal, setTaxonomyListModal] = useState(false);
  const [providerTypes, setProviderType] = useState([]);
  const [npiResultData, setNpiResultData] = useState([]);
  const [openNpiPaper, setOpenNpiPaper] =  useState(false)

  const DateSource = {
    taxIdType: "/ct-taxIDType",
    referringType: "/ct-refferingType",
    providerType: `/ct-provideType`,
  };

  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectTaxonomy = (taxonomyCode) => {
    console.log(taxonomyCode, "checking taxonomy Code");
    formik.setFieldValue("referringProviderTaxonomySpecialtyCode", taxonomyCode);
  };

  useEffect(() => {
    fetchData(DateSource.taxIdType, setTaxIdTypeOpt);
    fetchData(DateSource.referringType, setReferringTypeOpt);
    fetchData(DateSource.providerType, setProviderType);
  }, []);
  const handleSelectNpi = (npiNum) => {
    formik.setFieldValue("practiceNpiId", npiNum.npiId);
    formik.setFieldValue("practiceNpi", npiNum.npiNo);
  };

  const handleNpiChange = async (e) => {
    const npiValue = e.target.value;
    formik.setFieldValue("referringProviderNpi", npiValue);
    if (formik.values.referringProviderNpi.length >= 4) {
      try {
        const response = await getData(
          `${path}/npi/searchNPI?npiNo=${npiValue}`
        );
        if (response.result.length > 0) {
          setNpiResultData(response.result);
          setOpenNpiPaper(true)
        }
      } catch (e) {
        console.log(e, ": Error in feting NPI");
        throw e;
      }
    }else {
      setOpenNpiPaper(false)
    }
  };

  console.log(formik.values.referringProviderNpi, "checkNPI")
  return (
    <>
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{display:'flex',justifyContent:'start',padding:'15px',gap:'10px'}}>
            <CustomButton type="submit" isBlue={true} isSave={true}>
                Save
              </CustomButton>
              <CustomButton
                type="submit"
                isBlue={false}
                isCancel={true}
                handleClick={() => {
                  formik.resetForm();
                  navigate("/referringProvider");
                }}
              >
                Cancel
              </CustomButton>
              {formik.values.referringProviderSequenceNo && (
    <Box sx={{ marginLeft: 'auto' }}> 
      <CheckBox
        CheckedValue={!formik.values.isActive}
        handleChange={() =>
          // formik.setFieldValue("isActive",!formik.values.isActive)
          handleIsActive(formik)
        }
        label="Make this Referring Provider inactive"
      />
    </Box>
  )}
              </Box>
    <Paper elevation={3} style={{ padding: 20, margin: 7 }}>
      <Grid container spacing={2}>
        {formik.values.providerTypeId === 1 ? (
          <>
                <Grid item md={3} xs={12}>
          <CustomField
            type="text"
            label="First Name"
            value={formik.values.referringProviderFirstName}
            name="referringProviderFirstName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}

          />
        
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomField
            type="text"
            label="Last Name"
            value={formik.values.referringProviderLastName}
            name="referringProviderLastName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}

          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomField
            type="text"
            label="MI"
            value={formik.values.referringProviderMI}
            name="referringProviderMI"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomField
            type="text"
            label="Credentials"
            value={formik.values.referringProviderCredential}
            name="referringProviderCredential"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
          </>
        ):(
          <Grid item md={12} xs={12}>
          <CustomField
            type="text"
            label="Organization"
            value={formik.values.referringProviderFirstName}
            name="referringProviderFirstName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isRequired={true}

          />
        </Grid>
        )}


        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {providerTypes.map((val) => (
              <FormControlLabel
                key={val.providerTypeId}
                label={val.providerType}
                control={
                  <Radio
                    value={val.providerType}
                    checked={formik.values.providerTypeId === val.providerTypeId}
                    onChange={() => {
                      formik.setFieldValue("referringProviderFirstName", "");
                      formik.setFieldValue("referringProviderLastName", "");
                      formik.setFieldValue("referringProviderMI", "");
                      formik.setFieldValue("referringProviderCredential", "");
                      formik.setFieldValue("providerTypeId", val.providerTypeId);
                      formik.setFieldValue("ProviderType", val.ProviderType);

                    }}
                  />
                }
              />
            ))}
          </Stack>
        </Grid>

     
        <Grid item xs={12}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isReferrerSendOnClaims}
                  onChange={() => formik.setFieldValue("isReferrerSendOnClaims", !formik.values.isReferrerSendOnClaims)}
                />
              }
              label="Do not send referrer on claims"
            />
          </FormControl>
        </Grid>

        {/* Referring Type */}
        <Grid item md={4} xs={12}>
          <CustomSelectBox2
            dropdownOptions={referringTypeOpt.map((val) => ({
              value: val.referringType,
              id: val.referringTypeId,
            }))}
            label="Referring Type"
            name="referringTypeId"
            value={formik.values.referringTypeId}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            showDefaultOpt={true}
          />
        </Grid>

        {/* NPI and Taxonomy Specialty */}
        <Grid item md={4} xs={12}>
        <FormGroup
                sx={{
                  position: "relative",
                }}
              >
                <CustomSearchField
                  isRequired={true}
                  fieldVal={formik.values?.referringProviderNpi}
                  name="referringProviderNpi"
                  handleChange={handleNpiChange}
                  handleBlur={formik.handleBlur}
                  handleModalOpen={() => setSearchNpiModal(true)}
                  label="NPI"
                  formik={formik}
                  resetField={{
                    referringProviderNpi: null,
                  }}
                  isTypeAble={true}
                />
                {openNpiPaper && (
                  <Paper
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 111,
                      boxShadow: 3,
                      width:"100%"
                    }}
                  >
                    {npiResultData?.map((item) => (
                      <Stack margin="5px" width="100%" key={item?.npiId}>
                        <CustomButton
                          isBlue={false}
                          handleClick={() => {
                            formik.setFieldValue("referringProviderNpi", item?.npiNo)
                            setOpenNpiPaper(false)
                          }}
                        >
                          <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                          {item?.npiNo}
                        </CustomButton>
                      </Stack>
                    ))}
                  </Paper>
                )}
              </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
        <CustomSearchField
          name="referringProviderTaxonomySpecialtyCode"
          label="Taxonomy"
          isRequired={true}
          fieldVal={formik.values?.referringProviderTaxonomySpecialtyCode}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleModalOpen={() => setTaxonomyListModal(true)}
          formik={formik}
          resetField={{
            referringProviderTaxonomySpecialtyCode:null
          }}
         
        />
        </Grid>


        <Grid item md={6} xs={12}>
          <CustomField
            type="number"
            label="Sequence #"
            value={formik.values.referringProviderSequenceNo}
            name="referringProviderSequenceNo"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            disable={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomField
            type="number"
            label="Reference #"
            value={formik.values.referringProviderReferenceNo}
            name="referringProviderReferenceNo"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12} margin='35px 0 0'>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" }}>
            <Box sx={{ background: theme.palette.revenueStatement.primary, height: "33px" }}>
              <Typography variant="h5" fontWeight="600" padding="3px">Contact Information</Typography>
            </Box>
            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item md={12}>
                <CustomField
                  type="text"
                  name="address"
                  label="Address"
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  value={formik.values.address}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={6}>
                <CustomField
                  type="text"
                  name="homePhone"
                  label="Home Phone"
                  value={formik.values.homePhone}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={6}>
                <CustomField
                  type="text"
                  name="cellPhone"
                  label="Cell Phone"
                  value={formik.values.cellPhone}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                 type="text"
                  name="faxNo"
                  label="Fax"
                  value={formik.values.faxNo}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  mask="(999) 999-9999"

                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  name="pagerNo"
                  label="Pager"
                  value={formik.values.pagerNo}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  mask="(999) 999-9999"
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
          
                <CustomAddress
                  formik={formik}
                  fieldValues={{
                    countryId: formik.values.countryId,
                    country: formik.values.countryName,
                    countryName: "countryName",
                    stateId: "stateId",
                    stateName: "stateName",
                    state: formik.values.stateName,
                    cityId: "cityId",
                    cityName: "cityName",
                    city: formik.values.cityName,
                    zipCode: formik.values.zipCode,
                    zipCodeId: "zipCodeId",
                    zipName: "zipCode",
                  }}
                  isRequired={true}
                />
           
            </Grid>
          </Box>
        </Grid>

        {/* ID Numbers */}
        <Grid item xs={12} margin='35px 0 0'>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px"}}>
            <Box sx={{ background: theme.palette.revenueStatement.primary, height: "33px" }}>
              <Typography variant="h5" fontWeight="600" padding="3px">ID Numbers</Typography>
            </Box>
            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  name="taxID"
                  label="Tax ID"
                  value={formik.values.taxID}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  dropdownOptions={taxIdTypeOpt.map(val => ({
                    value: val.taxIDType,
                    id: val.taxIDTypeId,
                  }))}
                  label="Tax Id Type"
                  name="taxIDTypeId"
                  value={formik.values.taxIDTypeId}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  showDefaultOpt={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="upin"
                  label="UPIN"
                  value={formik.values.upin}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="bcbsid"
                  label="BCBS ID"
                  value={formik.values.bcbsid}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="medicareId"
                  label="Medicare ID"
                  value={formik.values.medicareId}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="medicaidId"
                  label="Medicaid ID"
                  value={formik.values.medicaidId}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="champusId"
                  label="Champus ID"
                  value={formik.values.champusId}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  name="specialityLicence"
                  label="Speciality License #"
                  value={formik.values.specialityLicence}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  name="stateLicence"
                  label="State Licence #"
                  value={formik.values.stateLicence}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  name="anesthesiaLicence"
                  label="Anesthesia License #"
                  value={formik.values.anesthesiaLicence}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item md={4}>
                <CustomField
                  type="text"
                  name="marketer"
                  label="Marketer"
                  value={formik.values.marketer}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
     <CustomModal
     open={taxonomyListModal}
     handleClose={() => setTaxonomyListModal(false)}
   >
     <TaxonomyCategories
       handleSelectTaxonomy={handleSelectTaxonomy}
       handleClose={() => setTaxonomyListModal(false)}
     />
   </CustomModal>
   <CustomModal
        open={searchNpiModal}
        handleClose={() => setSearchNpiModal(false)}
        heading={"NPI Search"}
        width={"60%"}
      >
        <SearchNpi
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setSearchNpiModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      </form>
    </>
  );
};

export default ReferringProviderInfo;
