import React from "react";
import {
  AccordionDetails,
  useTheme,
  Grid,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomField from "../../../components/CustomField";
import { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import TypeOfBill from "./TypeOfBill";
import CustomPreview from "../../../layout/CustomPreview";
import {
  facilityCols,
  patientClaimDetailCols,
  providerCols,
  referringProviderCol,
} from "../../../utils/gridCols";
import {
  getPatientClaimBySearchTerm,
  getPatientClaimsDetailAction,
} from "../../../features/actions/claimAction";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../../features/actions/facilityAction";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";
import {
  setInsurances,
  setPrimaryPayer,
  setSecondaryPayer,
  setTertiaryPayer,
} from "../../../features/slice/claimSlice";
import { payerPolicyTypeInClaim } from "../../../utils/formikInitValues";
import ClaimPayer from "./ClaimPayer";
import {
  getReferringProviderAction,
  getReferringProviderBySearchTermAction,
} from "../../../features/actions/referringProviderAction";

const ClaimInfo = ({ formik, location }) => {
  const dispatch = useDispatch();
  let patientAccNo = location?.state?.accountNo;
  const theme = useTheme();
  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);
  // const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  // const [openTypeOfBillModal, setOpenTypeOfBillModal] = useState(false);
  const [openFacilityModal, setOpenFacilityModal] = useState(false);
  const [openInsuredParty, setOpenInsuredParty] = useState(false);
  const [openBillingProviderMod, setOpenBillingProviderMod] = useState(false);
  const [openSupProviderModal, setOpenSupProviderModal] = useState(false);
  const [openRefProviderModal, setOpenRefProviderModal] = useState(false);
  const [openRenderingProviderMod, setOpenRenderingProviderMod] =
    useState(false);
  const [openOrderingProv, setOpenOrderingProv] = useState(false);
  const [frequencyOpt, setFrequencyOpt] = useState([]);
  const [policyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [priorityType, setPriorityType] = useState([]);
  const [referralTypeOpt, setReferralTypeOpt] = useState([]);
  // use selectors
  const { patientClaimsData, getPatientClaimDetailLoad, loading } = useSelector(
    (state) => state.claim
  );
  const { getFacilityData } = useSelector((state) => state.facility);
  const facilityLoading = useSelector((state) => state.facility.loading);
  // referring provider
  const { getReferringProviderData } = useSelector(
    (state) => state.referringProvider
  );
  const referringProvLoading = useSelector(
    (state) => state.referringProvider.loading
  );
  console.log(getReferringProviderData, "checkDataOfReferring");
  // const handleAccordionChange = () => {
  //   setIsAccordionOpen(!isAccordionOpen);
  // };

  const dataFetchUrls = {
    frequencyType: `${path}/ct-claimFrequency`,
    policyType: `${path}/ct-policyType`,
    priorityType: `${path}/ct-priorityType`,
    referralType: `${path}/ct-referralType`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataOptions(dataFetchUrls.frequencyType, setFrequencyOpt);
    fetchDataOptions(dataFetchUrls.policyType, setPolicyTypeOpt);
    fetchDataOptions(dataFetchUrls.priorityType, setPriorityType);
    fetchDataOptions(dataFetchUrls.referralType, setReferralTypeOpt);
  }, [dispatch]);

  const getPayerData = (priorityTypeId) => {
    return (
      formik.values?.policyTypeDto?.find(
        (item) => item.priorityId === priorityTypeId
      ) || {}
    );
  };
  const handleAddPatientClaim = (val) => {
    const { payerPolicyDetailDto } = val;
    dispatch(setInsurances(payerPolicyDetailDto));
    const policyTypeDto = payerPolicyDetailDto
      ?.filter((pol) => pol?.isDefault === true)
      .map((item) => ({
        insuranceId: item?.insuranceId || 0,
        insuranceName: item?.insuranceName || "",
        insuranceSequenceNo: item?.insuranceSequenceNo || 0,
        payerInfoId: item?.payerInfoId || 0,
        priorityId: item?.payerInfoPriorityTypeId,
        insuredPartyId: item?.insuredId,
        priorityName: item?.payerInfoPriorityType || "",
        memberId: item?.payerInfoMemberId || "",
        groupId: item?.payerInfoGroupId || "",
        policyTypeId: item?.payerInfoPolicyTypeId,
        policyTypeName: item?.payerInfoPolicyType,
        copayAmount: 0,
        claimControlNumber: "",
        authorizationNumber: "",
        referralTypeId: 1,
        referralType: "",
        claimNumber: 0,
        claimInfoId: 0,
      }));

    formik.setValues((prevValues) => ({
      ...prevValues,
      patientId: val?.patientId,
      patientFirstName: val?.patientFirstName,
      patientLastName: val?.patientLastName,
      patientAccountNo: val?.patientAccountNo,
      billingProviderId: val?.billingProviderId,
      billingProviderFirstName: val?.billingProviderFirstName,
      billingProviderLastName: val?.billingProviderLastName,
      billingProviderSeqNo: val?.billingProviderSequence,
      renderingProviderFirstName: val?.renderingProviderFirstName,
      renderingProviderLastName: val?.renderingProviderLastName,
      renderingProviderSeqNo: val?.renderingProviderSequence,
      renderingProviderId: val?.renderingProviderId,
      practiceId: val?.practiceId,
      practiceSeqNo: val?.practiceSequenceNo,
      practiceName: val?.practiceName,
      practiceAddress: val?.practiceAddress,
      policyTypeDto: policyTypeDto,
    }));
    setOpenPatientModal(false);
  };

  // useEffect(() => {
  //   if (!formik.values?.typeOfBill && formik.values.claimTypeId === 2) {
  //     formik.setFieldValue(
  //       "typeOfBill",
  //       `${formik.values?.facilityTypeId}${formik.values?.careTypeId}${formik.values?.frequencyTypeId}`
  //     );
  //   }
  // }, [formik.values.claimTypeId]);
  // set claim values directly from patient
  // useEffect(() => {
  //   if (patientAccNo !== undefined) {
  //     try {
  //       dispatch(getPatientClaimsDetailAction()).then(() => {
  //         const findPatient = patientClaimsData?.find(
  //           (item) => item?.patientAccountNo === Number(patientAccNo)
  //         );
  //         formik.setValues((preVals) => ({
  //           ...preVals,
  //           claimTypeId: location?.state?.claimType || 1,
  //           patientFirstName: findPatient?.patientFirstName,
  //           patientLastName: findPatient?.patientLastName,
  //           patientAccountNo: findPatient?.patientAccountNo,
  //           patientId: findPatient?.patientId,
  //           renderingProviderId: findPatient?.renderingProviderId,
  //           renderingProviderFirstName: findPatient?.renderingProviderFirstName,
  //           renderingProviderLastName: findPatient?.renderingProviderLastName,
  //           renderingProviderSequence: findPatient?.renderingProviderSequence,
  //           billingProviderFirstName: findPatient?.billingProviderFirstName,
  //           billingProviderLastName: findPatient?.billingProviderLastName,
  //           billingProviderSequence: findPatient?.billingProviderSequence,
  //           billingProviderId: findPatient?.billingProviderId,
  //           providerId: findPatient?.providerId,
  //           payerSequenceNo:
  //             findPatient?.claimInsuredDetailDtos[0]?.payerInfoSequenceNo,
  //           primaryPayerInsuranceName: `${findPatient?.claimInsuredDetailDtos[0]?.payerInfoName}`,
  //           payerInfoId: findPatient.claimInsuredDetailDtos[0]?.payerInfoId,
  //           primaryPayerMemberId:
  //             findPatient.claimInsuredDetailDtos[0]?.payerInfoMemberId,
  //           primaryPayerPolicyId:
  //             findPatient.claimInsuredDetailDtos[0]?.payerInfoPolicyTypeId,
  //           practiceAddress: findPatient?.practiceAddress,
  //           practiceId: findPatient?.practiceId,
  //           practiceSequenceNo: findPatient?.practiceSequenceNo,
  //           practiceName: findPatient?.practiceName,
  //         }));
  //       });
  //     } catch (e) {
  //       console.log(e, ": fetching patient calim detail in claim");
  //       alert("Something went wrong");
  //     }
  //   }
  // }, [dispatch, formik, location, patientAccNo, patientClaimsData]);

  // const handlePayer = (params, isNew) => {
  //   let allPayer = formik.values.policyTypeDto;
  //   let payer = {
  //     ...payerPolicyTypeInClaim,
  //     ...params,
  //     payerId: params?.payerId,
  //     payerName: params?.payerName,
  //     payerSequenceNo: params?.payerSequenceNo,
  //     priorityId: openInsuredParty.payerPriorityId,
  //   };
  //   let findPayer = formik.values?.policyTypeDto?.findIndex(
  //     (e) => e?.priorityId === openInsuredParty.payerPriorityId
  //   );
  //   if (findPayer !== -1) {
  //     allPayer[findPayer] = payer;
  //   } else {
  //     allPayer.splice(openInsuredParty.payerPriorityId - 1, 0, payer);
  //   }
  //   formik.setValues((preVals) => ({
  //     ...preVals,
  //     policyTypeDto: allPayer,
  //   }));
  //   setOpenInsuredParty({
  //     openModal: false,
  //     payerPriorityId: null,
  //   });
  // };
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: "20px",
          background: theme.palette.innerBox.primary,
          padding: "20px",
        }}
      >
        <Grid item md={4} xs={12}>
          <CustomField
            value={formik.values.claimNumber || null}
            label="Claim#"
            name="claimNumber"
            disable={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="text"
            value={formik.values.referenceNumber || ""}
            handleChange={formik.handleChange}
            name="referenceNumber"
            handleBlur={formik.handleBlur}
            label="Reference #"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          {formik.values.claimTypeId === 1 ? (
            <CustomSelectBox2
              showDefaultOpt={true}
              value={formik.values.claimFrequencyId}
              name="claimFrequencyId"
              dropdownOptions={frequencyOpt?.map((opt) => ({
                value: opt.claimFrequencyName,
                id: opt.claimFrequencyId,
              }))}
              label="Frequncy Type"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              isRequired={true}
            />
          ) : null}
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Patient"
            name="patientFirstName"
            isRequired={true}
            fieldVal={
              formik.values.patientFirstName &&
              `${formik.values.patientFirstName} ${formik.values.patientLastName} (${formik.values.patientAccountNo})`
            }
            // error={formik.errors}
            // touched={formik.touched}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            handleModalOpen={() => setOpenPatientModal(true)}
            resetField={{
              patientFirstName: "",
              patientLastName: "",
              patientAccountNo: null,
              patientId: 0,
            }}
            formik={formik}
            disable={formik.values.claimNumber}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label={
              "Billing Provider"
              // formik.values.claimTypeId === 1
              //   ? "Biling Provider"
              //   : "Attending Provider"
            }
            isRequired={true}
            type="text"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            fieldVal={
              formik.values.billingProviderFirstName &&
              `${formik?.values?.billingProviderFirstName} ${formik?.values?.billingProviderLastName} (${formik?.values?.billingProviderSeqNo})`
            }
            name="billingProviderFirstName"
            resetField={{
              billingProviderFirstName: "",
              billingProviderLastName: "",
              billingProviderSeqNo: null,
              billingProviderId: 0,
            }}
            handleModalOpen={() => setOpenBillingProviderMod(true)}
            formik={formik}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Rendering Provider"
            type="text"
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            fieldVal={
              formik.values.renderingProviderFirstName &&
              `${formik.values?.renderingProviderFirstName} ${formik.values?.renderingProviderLastName} (${formik.values?.renderingProviderSeqNo})`
            }
            name="renderingProviderFirstName"
            resetField={{
              renderingProviderId: 0,
              renderingProviderFirstName: "",
              renderingProviderLastName: "",
              renderingProviderSeqNo: null,
            }}
            handleModalOpen={() => setOpenRenderingProviderMod(true)}
            formik={formik}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Supervisor Provider"
            type="text"
            formik={formik}
            fieldVal={
              formik.values.supervisingProviderFirstName &&
              `${formik.values?.supervisingProviderFirstName} ${formik.values?.supervisingProviderLastName} (${formik.values?.supervisingProviderSeqNo})`
            }
            resetField={{
              supervisingProviderId: 0,
              supervisingProviderFirstName: "",
              supervisingProviderLastName: "",
              supervisingProviderSeqNo: null,
            }}
            handleModalOpen={() => setOpenSupProviderModal(true)}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="supervisorProvider"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Ordering Provider"
            type="text"
            formik={formik}
            handleModalOpen={() => setOpenOrderingProv(true)}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="orderingProvider"
            fieldVal={
              formik.values.orderingProviderFirstName &&
              `${formik.values?.orderingProviderFirstName} ${formik.values?.orderingProviderLastName} (${formik.values?.orderingProviderSeqNo})`
            }
            resetField={{
              orderingProviderId: 0,
              orderingProviderFirstName: "",
              orderingProviderLastName: "",
              orderingProviderSeqNo: null,
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Referring Provider"
            type="text"
            formik={formik}
            handleModalOpen={() => setOpenRefProviderModal(true)}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="referringProvider"
            fieldVal={
              formik.values.referringProviderFirstName &&
              `${formik.values?.referringProviderFirstName} ${formik.values?.referringProviderLastName} (${formik.values?.referringProviderSeqNo})`
            }
            resetField={{
              referringProviderId: 0,
              referringProviderFirstName: "",
              referringProviderLastName: "",
              referringProviderSeqNo: null,
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Facility"
            type="text"
            isRequired={true}
            formik={formik}
            handleModalOpen={() => setOpenFacilityModal(true)}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            fieldVal={
              formik.values.facilityName &&
              `${formik.values.facilityName} (${formik.values.facilitySeqNo})`
            }
            resetField={{
              facilityId: 0,
              facilityName: "",
              facilitySeqNo: null,
            }}
            name="facilityName"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomField
            label="Office Location"
            type="text"
            disable={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.practiceAddress}
            name="practiceAddress"
          />
        </Grid>
        {priorityType.map((v, i) => {
          const payerData = getPayerData(v.priorityTypeId);
          return (
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid red",
                mt: 2,
                ml: 2,
                border: "1px solid #b0b0b0",
                borderRadius: "4px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                padding: "15px",
              }}
            >
              <CustomSearchField
                label={`${v ? v?.priorityType : ""} Insurance`}
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                fieldVal={
                  payerData?.insuranceName
                    ? `${payerData?.insuranceName} (${payerData?.insuranceSequenceNo})`
                    : ""
                }
                name="primaryPayerInsuranceName"
                handleModalOpen={() => setOpenInsuredParty(true) }
                resetField={() => {
                  let updatedPayer = formik.values?.policyTypeDto?.filter(
                    (el) => el?.priorityId !== v?.priorityTypeId
                  );
                  return {
                    policyTypeDto: updatedPayer,
                  };
                }}
                formik={formik}
              />
              {formik.values?.policyTypeDto &&
                formik.values?.policyTypeDto.find(
                  (val, ind) => val.priorityId === v.priorityTypeId
                ) && (
                  <Grid container spacing={2} marginY={2}>
                    <Grid item md={4}>
                      <CustomField
                        type="text"
                        label="Member ID"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        value={formik.values?.policyTypeDto[i]?.memberId || ""}
                        name={`policyTypeDto[${i}].memberId`}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <CustomSelectBox2
                        value={
                          formik.values?.policyTypeDto[i]?.policyTypeId || null
                        }
                        name={`policyTypeDto[${i}].policyTypeId`}
                        dropdownOptions={policyTypeOpt?.map((opt) => ({
                          value: opt.policyType,
                          id: opt.policyTypeId,
                        }))}
                        label="Policy Type"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        formik={formik}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <CustomField
                        type="number"
                        label="Copay Due"
                        isDecimal={true}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        value={
                          formik.values?.policyTypeDto[i]?.copayAmount || 0
                        }
                        name={`policyTypeDto[${i}].copayAmount`}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <CustomField
                        type="text"
                        label="Control Number"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        name={`policyTypeDto[${i}].claimControlNumber`}
                        value={
                          formik.values?.policyTypeDto[i]?.claimControlNumber || ""
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <CustomField
                        type="text"
                        label="Authorization Number"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        name={`policyTypeDto[${i}].authorizationNumber`}
                        value={
                          formik.values?.policyTypeDto[i]?.authorizationNumber || ""
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                       <CustomSelectBox2
                        value={
                          formik.values?.policyTypeDto[i]?.referralTypeId || null
                        }
                        name={`policyTypeDto[${i}].referralTypeId`}
                        dropdownOptions={referralTypeOpt?.map((opt) => ({
                          value: opt.referralType,
                          id: opt.referralTypeId,
                        }))}
                        label="Referral Type"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        formik={formik}
                        showDefaultOpt={true}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          );
        })}
      </Grid>
      {/* patients modal */}
      <CustomModal
        open={openPatientModal}
        handleClose={() => setOpenPatientModal(false)}
        heading="Patients"
      >
        <CustomPreview
          searchLabel="Search by Claim Id,Patient Name,Dos"
          rows={
            patientClaimsData &&
            patientClaimsData?.result?.patientClaim?.length > 0
              ? patientClaimsData?.result?.patientClaim?.map((row) => ({
                  id: row?.patientId,
                  ...row,
                }))
              : []
          }
          columns={patientClaimDetailCols}
          loading={getPatientClaimDetailLoad}
          handleGetAll={getPatientClaimsDetailAction}
          handleBySearch={getPatientClaimBySearchTerm}
          paginationDetail={patientClaimsData}
          handleCell={handleAddPatientClaim}
          isModal={true}
        />
      </CustomModal>
      {/* <CustomModal
          open={openTypeOfBillModal}
          handleClose={() => setOpenTypeOfBillModal(false)}
          heading={"Type Of Bill"}
        >
          <TypeOfBill
            handleClose={() => setOpenTypeOfBillModal(false)}
            setOpenTypeOfBillModal={setOpenTypeOfBillModal}
            formik={formik}
          />
        </CustomModal> */}
      <CustomModal
        open={openFacilityModal}
        handleClose={() => setOpenFacilityModal(false)}
        heading={"Facility"}
      >
        <CustomPreview
          isModal={true}
          rows={
            getFacilityData?.result &&
            getFacilityData.result?.facilities?.length > 0
              ? getFacilityData.result?.facilities?.map((el) => ({
                  id: el.facilityId,
                  ...el,
                }))
              : []
          }
          columns={facilityCols}
          loading={facilityLoading}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          searchLabel="Search by First Name, Address, NPI, Phone ,or Sequence Number"
          handleCell={(params) => {
            formik.setFieldValue("facilityId", params.id);
            formik.setFieldValue("facilityName", params.facilityName);
            formik.setFieldValue("facilitySeqNo", params.sequenceNo);
            setOpenFacilityModal(false);
          }}
        />
      </CustomModal>
      {/* bolling provider modal */}
      <CustomModal
        open={openBillingProviderMod}
        handleClose={() => setOpenBillingProviderMod(false)}
        heading={"Billing Provider"}
      >
        <CustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          loading={providerLoading}
          searchLabel="Search By First Name and Sequence No"
          moduleHeading={"Billing Provider"}
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              billingProviderFirstName: params?.providerFirstName,
              billingProviderLastName: params?.providerLastName,
              billingProviderSeqNo: params?.providerSequenceNo,
              billingProviderId: params?.id,
            }));
            setOpenBillingProviderMod(false);
          }}
        />
      </CustomModal>
      {/* renderin provider modal */}
      <CustomModal
        open={openRenderingProviderMod}
        handleClose={() => setOpenRenderingProviderMod(false)}
        heading={"Rendering Provider"}
      >
        <CustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          loading={providerLoading}
          searchLabel="Search By First Name and Sequence No"
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              renderingProviderId: params?.id,
              renderingProviderFirstName: params?.providerFirstName,
              renderingProviderLastName: params?.providerLastName,
              renderingProviderSeqNo: params?.providerSequenceNo,
            }));
            setOpenRenderingProviderMod(false);
          }}
        />
      </CustomModal>
      {/* select payer modal */}
      <CustomModal
        open={openInsuredParty}
        handleClose={() =>
          setOpenInsuredParty(false)
        }
        height={!formik.values?.patientAccountNo ? "200px" : "auto"}
        heading={"Patients Payers"}
      >
        {!formik.values?.patientAccountNo && !formik.values?.patientId ? (
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
              height: "100% !important",
            }}
          >
            <Typography component={"h2"} fontWeight={700}>
              Select Patient First
            </Typography>
          </Stack>
        ) : (
          <ClaimPayer
            // openInsuredParty={openInsuredParty}
            formik={formik}
            // handlePayer={handlePayer}
            handleClosePayer={() => setOpenInsuredParty(false)}
          />
        )}
      </CustomModal>
      {/* referring provider */}
      <CustomModal
        open={openRefProviderModal}
        handleClose={() => setOpenRefProviderModal(false)}
        heading={"Referring Provider"}
      >
        <CustomPreview
          rows={
            getReferringProviderData?.result?.referringProviders?.length > 1
              ? getReferringProviderData?.result?.referringProviders?.map(
                  (el) => ({
                    id: el?.referringProviderId,
                    ...el,
                  })
                )
              : []
          }
          columns={referringProviderCol}
          handleGetAll={getReferringProviderAction}
          paginationDetail={getReferringProviderData}
          handleBySearch={getReferringProviderBySearchTermAction}
          isModal={true}
          loading={referringProvLoading}
          searchLabel="Search By First Name and Sequence No"
          moduleHeading={"Referring Provider"}
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              referringProviderFirstName: params?.referringProviderFirstName,
              referringProviderLastName: params?.referringProviderLastName,
              referringProviderSeqNo: params?.referringProviderSequenceNo,
              referringProviderId: params?.referringProviderId,
            }));
            setOpenRefProviderModal(false);
          }}
        />
      </CustomModal>
      {/* supervising provider modal */}
      <CustomModal
        open={openSupProviderModal}
        handleClose={() => setOpenSupProviderModal(false)}
        heading={"Supervisor Provider"}
      >
        <CustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          loading={providerLoading}
          searchLabel="Search By First Name and Sequence No"
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              supervisingProviderId: params?.id,
              supervisingProviderFirstName: params?.providerFirstName,
              supervisingProviderLastName: params?.providerLastName,
              supervisingProviderSeqNo: params?.providerSequenceNo,
            }));
            setOpenSupProviderModal(false);
          }}
        />
      </CustomModal>
      {/* ordering provider */}
      <CustomModal
        open={openOrderingProv}
        handleClose={() => setOpenOrderingProv(false)}
        heading={"Ordering Provider"}
      >
        <CustomPreview
          rows={
            getReferringProviderData?.result?.referringProviders?.length > 1
              ? getReferringProviderData?.result?.referringProviders?.map(
                  (el) => ({
                    id: el?.referringProviderId,
                    ...el,
                  })
                )
              : []
          }
          columns={referringProviderCol}
          handleGetAll={getReferringProviderAction}
          paginationDetail={getReferringProviderData}
          handleBySearch={getReferringProviderBySearchTermAction}
          isModal={true}
          loading={referringProvLoading}
          searchLabel="Search By First Name and Sequence No"
          moduleHeading={"Referring Provider"}
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              orderingProviderFirstName: params?.referringProviderFirstName,
              orderingProviderLastName: params?.referringProviderLastName,
              orderingProviderSeqNo: params?.referringProviderSequenceNo,
              orderingProviderId: params?.referringProviderId,
            }));
            setOpenOrderingProv(false);
          }}
        />
      </CustomModal>
    </>
  );
};

export default ClaimInfo;
