import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData} from "../../config/axiosFunctions";

export const getInsuredPartyAction = createAsyncThunk("getInsuredParty", async () => {
  try {
    const response = await getData(`${path}/insuredParty`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

