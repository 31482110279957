import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData,postData,updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const getCompanyAction = createAsyncThunk("getCompany", async (page) => {
    try {
      console.log(page);
      const newPage = page?.page + 1
      const response = await getData(`${path}/company?page=${newPage}&pageSize=20`);
      console.log(response, "action companies response");
      if (response) {
        return response;
      } 
    } catch (error) {
      throw new Error(error);
    }
  });

  export const newCompanyAction = createAsyncThunk(
    "createCompany",
    async (data) => {
      try {
        const response = await postData(`${path}/company`, data);
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.log(error, "check Error");
        throw new Error(error);
      }
    }
  );
  
export const getCompanyBySearchTermAction = createAsyncThunk("getCompany", async ({searchTerm, page}) => {
    try {
      const response = await getData(`${path}/company/SearchCompanies?searchValue=${searchTerm}&page=${page}&pageSize=20`);
      console.log(response, "action search companies response");
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  });
  export const getCompanyByIdAction = createAsyncThunk(
    "getCompanyById",
    async (Code) => {
      try {
  
        const response = await getData( `${path}/company/${Code}`);
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );
 
  export const updateCompanyAction = createAsyncThunk(
    "updateCompany",
    async (data) => {
      try {
        const response = await updateData(
          `${path}/company/${data.companyCode}`,data);
        console.log(response, "update company action response");
        return response;
      } catch (error) {
        throw error;
      }
    }
  );