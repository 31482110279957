import {
  Box,
  FormControl,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import { getData } from "../config/axiosFunctions";
import path from "../config/apiUrl";
import CustomSelectBox2 from "./CustomSelectBox2";
import CustomModal from "./CustomModal";
import { useFormik } from "formik";
import TextArea from "./TextArea";
const CustomNotes = ({ formik, moduleId }) => {
  const theme = useTheme();
  const [notesTypeOpt, setNotesTypeOpt] = useState([]);
  const [noteId, setNoteId] = useState(null);
  const [openNoteForm, setOpenNoteForm] = useState(false);
  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("userId");
  const notesList = formik?.values?.noteDetailDto;
  const moduleData = notesTypeOpt?.find(item => item?.noteTypeId === moduleId)
  const noteTypeName = moduleData?.noteType
  const noteFormik = useFormik({
    initialValues: {
      message: "",
      noteTypeId: null,
      noteType: "",
      accountNo: null,
      claimNumber: null,
      claimInfoId: null,
      userId: "",
      userName: "",
      dated: null,
      isDeleted: false,
    },
    onSubmit: (values, action) => {
      formik.setValues((preVals) => ({
        ...preVals,
        noteDetailDto: [...notesList, values],
      }));
      action.resetForm();
      setOpenNoteForm(false);
    },
  });
  const fetchNotesType = async () => {
    const response = await getData(`${path}/ct-noteType`);
    if (response) {
      setNotesTypeOpt(response.result);
    }
  };
  useEffect(() => {
    fetchNotesType();

  }, [setNotesTypeOpt]);
  // handle delete
  const handleDeleteUndo = (isDeleted, index) => {
    const updatedList = notesList?.map((note, i) =>
      i === index ? { ...note, isDeleted: !isDeleted } : note
    );
    formik.setValues((preVals) => ({
      ...preVals,
      noteDetailDto: updatedList,
    }));
  };
  return (
    <>
      <Box>
        <Stack direction="row" gap="20px">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => {
              noteFormik?.setValues((preVals) => ({
                ...preVals,
                noteType: noteTypeName,
                noteTypeId: moduleId,
                userName: userName,
                userId: userId,
                dated: new Date().toLocaleDateString(),
              }));
              setOpenNoteForm(true);
            }}
          >
            Add Note
          </CustomButton>
          {/* <FormControl>
            <Select
              sx={{
                "& fieldset": {
                  border: "yellow",
                },
                "& .MuiNativeSelect-icon": {
                  fontSize: "35px !important",
                },
              }}
              className="customSelectBox"
              style={{
                background: theme.palette.customFieldsBackground.primary,
        
              }}
              value={noteId}
              name="noteId"
              onChange={(e) => setNoteId(Number(e.target.value))}
              native
              id="dropdowns"
            >
              {notesTypeOpt?.map((opt) => {
                return (
                  <option
                    style={{ background: theme.palette.fieldsDDown.primary }}
                    itemType="number"
                    key={opt.noteTypeId}
                    value={opt.noteTypeId}
                  >
                    {opt.noteType}
                  </option>
                );
              })}
            </Select>
          </FormControl> */}
        </Stack>

        {notesList?.length > 0
          ? notesList?.map((note, index) => {
              return (
                <Stack
                  direction="row"
                  gap={"20px"}
                  marginTop="10px"
                  key={index}
                  alignItems="center"
                  padding="3px 5px"
                  sx={{
                    background: note?.isDeleted && "pink",
                  }}
                >
                  <Stack direction="column" width={"100%"} >

                  <Typography
                    // variant="h5"
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "4px",
                      ":hover": {
                        border: "0.5px solid rgb(0, 113, 197)",
                      },
                    }}
                    component="h3"
                  >
                    {note?.message}
                  </Typography>
                  <Typography component="p" fontSize="1.4rem">{note?.noteType} by <span style={{fontWeight:600}}>{note?.userName}</span> on {note?.dated}</Typography>
                  </Stack>
                  <CustomButton
                    isDelete={!note?.isDeleted}
                    isUndo={note?.isDeleted && true}
                    isBlue={false}
                    handleClick={() => handleDeleteUndo(note?.isDeleted, index)}
                  />
                </Stack>
              );
            })
          : []}
      </Box>

      <CustomModal
        open={openNoteForm}
        handleClose={() => setOpenNoteForm(false)}
        heading="Notes"
        handleClick={noteFormik?.handleSubmit}
        showButton={true}
        btnLabel={"Done"}
        
      >
        <Box
          sx={{
            padding: "12px",
          }}
          component="form"
        >
          <TextArea
            minRows={6}
            placeholder="Message..."
            value={noteFormik?.values?.message}
            name="message"
            handleChange={noteFormik.handleChange}
            handleBlur={noteFormik.handleBlur}
          />

          <Typography component="h5" fontWeight={600}>
            <span style={{ fontWeight: "700" }}>User:</span> {noteFormik?.values?.userName}
          </Typography>
          <Typography component="h5" fontWeight={600}>
            <span style={{ fontWeight: "700" }}>Note Type:</span> {noteFormik?.values?.noteType}
          </Typography>
        </Box>
      </CustomModal>
    </>
  );
};
export default CustomNotes;
