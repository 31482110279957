import { createSlice } from "@reduxjs/toolkit";
import { getEmployerAction } from "../actions/employerAction";

const initialState = {
  getEmployerData: [],
  error: null,
  loading: {
    getEmployerLoading: false,
  },
};
const emplyerSlice = createSlice({
  name: "employer",
  initialState: initialState,

  extraReducers: {
    [getEmployerAction.pending]: (state) => {
      state.loading.getEmployerLoading = true;
    },
    [getEmployerAction.fulfilled]: (state, action) => {
      state.loading.getEmployerLoading = false;
      state.getEmployerData = action.payload;
    },
    [getEmployerAction.rejected]: (state, action) => {
      state.loading.getEmployerLoading = false;
      state.error = action.payload;
    },
  },
});
export default emplyerSlice.reducer

