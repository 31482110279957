import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
export const newRemittanceAction = createAsyncThunk(
  "createRemittance",
  async (data) => {
    try {
      const response = await postData(`${path}/remittanceCode`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getRemittanceAction = createAsyncThunk(
  "getRemittance",
  async (page) => {
    try {
      // const response = await getData(`${path}/remittanceCode`);
      const response = await getData(`${path}/remittanceCode?page=${page}&pageSize=5`);
      if (response) {
     console.log(response);
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchRemittanceByTypeAction = createAsyncThunk(
  "getRemmitanceByType",
  async ({type,page}) => {
    try {
      const newPage = page+1
      console.log(type,page);
      
      const response = await getData(`${path}/remittanceCode/remittanceTypeId?remittanceType=${type}&page=${newPage}&pageSize=20`);
      if (response) {
     console.log(response);
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
)



// export const fetchRemittanceByTypeAction = createAsyncThunk(
//   "getRemmitanceByType",
//   async ({ page, type}) => {
//     try {
//       const response = await getData(`${path}/remittanceCode/remittanceTypeId?remittanceTypeId=${type}&page=${page}&pageSize=20`);
//       if (response) {
//         console.log(response);
//         return response;
//       }
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );



// get remarks remittance
export const getRemarkRemittanceAction = createAsyncThunk(
  "getRemarkRemittance",
  async () => {
    try {
      const response = await getData(`${path}/remittanceRemark`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
// get adjustment remittance
export const getAdjustmentRemittanceAction = createAsyncThunk(
  "getAdjustmentRemittance",
  async () => {
    try {
      const response = await getData(`${path}/adjustmentRemark`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search
export const getRemittanceBySearchTermAction = createAsyncThunk(
  "getRemittance",
  async ({searchTerm, page}) => {
    try {
      // const response = await getData(`${path}/remittanceCode/SearchRemittanceCode?searchValue=${val}`);
const response = await getData(`${path}/remittanceCode/SearchRemittanceCode?searchValue=${searchTerm}&page=${page}&pageSize=5`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateRemittanceAction = createAsyncThunk(
  "updateRemittance",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/remittanceCode/${data.remittanceCodeId}`,
        data
      );
      console.log(response, "update remittance action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);


// get single remittance 
export const getSingleRemittanceAction = createAsyncThunk(
  "getSingleRemittance",
  async (id) => {
    try {
      // const response = await getData(`${path}/remittanceCode`);
      const response = await getData(`${path}/remittanceCode/GetRemittanceCodeById?Id=${id}`);
      
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);