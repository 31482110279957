import { Box, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomButton from "../../components/CustomButton";
import { useState } from "react";
// import { useDispatch } from "react-redux";
// import {
//   addSelectedClaim,
//   setPaymentDataForApi,
// } from "../../features/slice/PaymentSlice";

const MultipleClaims = ({ multipleClaimData, handleClose, formik }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const SumFromClaimChargesDto = (id, key) => {
    if (multipleClaimData) {
      let findClaim = multipleClaimData?.find((val) => val.claimId === id);
      if (findClaim && findClaim?.claimChargesPatientDto) {
        const sum = findClaim.claimChargesPatientDto.reduce(
          (accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue[key]);
          },
          0
        );
        return sum;
      }
      return 0;
    }
    return 0;
  };
  console.log("multipleClaimData", multipleClaimData);
  const rows = multipleClaimData
    .filter((e) => {
      let findPrev = formik.values.paymentClaimDto.find(
        (v, i) =>
          v?.claimId === (e?.claimId || e.paymentId) &&
          (e?.claimId || e?.paymentId)
      );
      return !findPrev;
    })
    .map((el, index) => ({
      id: el?.claimId || el?.paymentId,
      patientFirstName: el?.patientFirstName,
      patientLastName: el?.patientLastName,
      patientAccountNo: el?.patientAccountNo,
      claimNumber: el.claimNumber,
      billed: SumFromClaimChargesDto(el.claimId || el.id, "amount") || 0,
      adjusted: 0,
      balance: SumFromClaimChargesDto(el.claimId || el.id, "amount") || 0,
      claimChargesDto: el.claimChargesPatientDto || [],
      paymentDetailDto: el?.paymentDetailDto || [],
    }));
  const columns = [
    {
      field: "claimNumber",
      headerName: "Claim #",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "patientAccountNo",
      headerName: "Account #",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "patientLastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "patientFirstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "billed",
      headerName: "Charges",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    // {
    //   field: "payments",
    //   headerName: "Payments",
    //   flex: 1,
    //   minWidth: 100,
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "header-bg",
    //   cellClassName: "cell-content"
    // },
    {
      field: "adjusted",
      headerName: "Adjustments",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  // select button logic
  const handleSelectClick = () => {
    // let formikintialData = formik.values;
    const selectedModelRow = selectedRows.map((rowId) =>
      rows.find((el) => el.id === rowId)
    );
    console.log("selectedModelRow", selectedModelRow);
    let selectedRowForData = selectedModelRow.map((val) => ({
      claimId: val.id,
      claimNumber: val.claimNumber,
      claimChargesDto: val.claimChargesDto,
      patientFirstName: val?.patientFirstName,
      patientLastName: val?.patientLastName,
      patientAccountNo: val?.patientAccountNo,
      ClaimStatusId: null,
      PaymentActionId: null,
      TCNId: null,
      ClaimControlNo: null,
      paymentDetailDto: [],
    }));

    // let updatedInputData = {
    //   ...formikintialData,
    //   paymentClaimDto: selectedRowForData,
    // };
    formik.setValues((prev) => ({
      ...prev,
      paymentClaimDto: [...prev.paymentClaimDto, ...selectedRowForData],
    }));

    // dispatch(setPaymentDataForApi(updatedInputData));
    // dispatch(addSelectedClaim([...selectedModelRow]));
    handleClose();
  };
  return (
    <Box sx={{ minHeight: "200px", padding: "15px" }}>
      <Typography
        variant="h4"
        component="h4"
        letterSpacing="1.24px"
        margin="15px 0"
      >
        Search Results
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          "& .header-bg": {
            backgroundColor: "lightgrey",
            color: "black",
            fontSize: "14px",
          },
          "& .cell-content": {
            fontSize: "12.5px",
          },
        }}
        checkboxSelection
        onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
        autoHeight
        disableSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSize={5}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{ width: "100%", textAlign: "center", padding: "16px" }}
            >
              {rows.length === 0 && "No Data Is Added"}
            </div>
          ),
        }}
      />

      <Stack sx={{ margin: "17px 0" }}>
        <CustomButton
          margin="0 20px 0"
          variant="contained"
          width="150px"
          handleClick={handleSelectClick}
        >
          Select
        </CustomButton>
      </Stack>
    </Box>
  );
};

export default MultipleClaims;
