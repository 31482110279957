import { Box, FormControl, Select, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import CustomMultiSelect from "../../../components/CustomMultiSelect/CustomMultiSelect";
import { MultiSelect } from "react-multi-select-component";

export const FinalDemandNotice = ({ formik }) => {
  const theme = useTheme();
  const [arFilterOpt, setArFilterOpt] = useState([]);
  const [chargesToIncludeList, setChargesToIncludeList] = useState([]);
  const [seperateStatementsList, setSeperateStatementsList] = useState([]);

  const filterData = [
    {
      filterId: 1,
      filterName: "Patient set to Send FDN",
      filterKey: "patientSettoSendFDN",
    },
    {
      filterId: 2,
      filterName: "Patient Balance",
      filterKey: "patientBalance",
    },
    {
      filterId: 3,
      filterName: "Electronic Statement Sent",
      filterKey: "electronicStatementSent",
    },
    {
      filterId: 4,
      filterName: "Paper Statement Sent",
      filterKey: "paperStatementSent",
    },
    {
      filterId: 5,
      filterName: "Total Statement Sent",
      filterKey: "totalStatementSent",
    },
    {
      filterId: 6,
      filterName: "Day Since Last Statement",
      filterKey: "daySinceLastStatement",
    },
    {
      filterId: 7,
      filterName: "Day Since Last FDN",
      filterKey: "daySinceLastFDN",
    },
  ];
  const dataFetchUrl = {
    arFilters: `${path}/ct-arFilters`,
    chargesToInclude: `${path}/ct-includedCharges`,
    seperateStatement: `${path}/ct-seperateStatement`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.log("Fetching Dropdown Option Error:", error);
      throw error;
    }
  };

  const handleSelectChange = () => {};
  const handleChangeValue = () => {};
  useEffect(() => {
    fetchDataOptions(dataFetchUrl.arFilters, setArFilterOpt);
    fetchDataOptions(dataFetchUrl.chargesToInclude, setChargesToIncludeList);
    fetchDataOptions(dataFetchUrl.seperateStatement, setSeperateStatementsList);
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "grid",
          marginTop: "10px",
          padding: "0px 0px 20px 20px",

          gap: "30px",
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 300px))",
            sm: "repeat(1, minmax(0, 300px))",
            md: "repeat(1, minmax(0, 300px))",
          },
        }}
      >
        {filterData.map((field) => (
          <>
            <div key={field.filterId}>
              <FormControl fullWidth>
                <label
                  className="customLabel"
                  style={{
                    color: theme.palette.Labeltext.primary,
                  }}
                >
                  {field.filterName}
                </label>
                <Select
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiNativeSelect-icon": {
                      fontSize: "35px !important",
                    },
                    background: theme.palette.accordiansBackground.primary,
                    color: theme.palette.textMainHeading.primary,
                  }}
                  className="customSelectBox"
                  // value={
                  //   formik.values?.otherFilters?.find(
                  //     (v) => v?.filterKey === field?.filterKey
                  //   )?.arFilterId || 1
                  // }
                  type="text"
                  onChange={(event) => handleSelectChange(event, field)}
                  // onBlur={handleBlur}
                  native
                >
                  {arFilterOpt.map((opt) => (
                    <>
                      <option
                        style={{
                          background: theme.palette.fieldsDDown.primary,
                        }}
                        key={opt.arFilterId}
                        value={opt.arFilterId}
                      >
                        {opt.arFilterName}
                      </option>
                    </>
                  ))}
                </Select>
              </FormControl>
              <Stack
                direction="row"
                alignItems="flex-end"
                gap="5px"
                marginTop={"9px"}
              >
                {/* {formik.values?.filterTypeId === 1 ? (
                  formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 2 ||
                  formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 3 ||
                  formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 4 ? (
                    <>
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) => handleChangeValue(field, "value1", e)}
                        min={0}
                        value={
                          formik.values?.otherFilters?.find(
                            (v) => v?.filterKey === field?.filterKey
                          )?.value1
                        }
                      />
                    </>
                  ) : formik.values.otherFilters.find(
                      (v) => v?.filterKey === field?.filterKey
                    )?.arFilterId === 5 ? (
                    <>
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) => handleChangeValue(field, "value1", e)}
                        min={0}
                        value={
                          formik.values?.otherFilters?.find(
                            (v) => v?.filterKey === field?.filterKey
                          )?.value1
                        }
                      />
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) => handleChangeValue(field, "value2", e)}
                        min={0}
                        value={
                          formik.values?.otherFilters?.find(
                            (v) => v?.filterKey === field?.filterKey
                          )?.value2
                        }
                      />
                    </>
                  ) : (
                    ""
                  )
                ) : formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 2 ||
                  formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 3 ||
                  formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 4 ? (
                  <>
                    <input
                      type="date"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value1", e)}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value1
                      }
                    />
                  </>
                ) : formik.values?.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 5 ? (
                  <>
                    <input
                      type="date"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value1", e)}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value1
                      }
                    />
                    <input
                      type="date"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value2", e)}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value2
                      }
                    />
                  </>
                ) : (
                  ""
                )} */}
              </Stack>
            </div>
          </>
        ))}
        <FormControl fullWidth>
          <label
            className="customLabel"
            style={{
              color: theme.palette.Labeltext.primary,
            }}
          >
            Account Type
          </label>
          {/* <MultiSelect
            options={[]}
            value={[]}
            //   onChange={}
            style={{
              control: (base) => ({
                ...base,
                backgroundColor: "red",
              }),
              option: (provided) => ({
                ...provided,
                color: "blue",
              }),
            }}
          /> */}
          <CustomMultiSelect
            className="custom-multi-select"
            options={[]}
            value={[]}
            // handleChange={}
           handleSelectAll={true}
         />
        </FormControl>
      </Box>
    </>
  );
};
