// import React, { useState, useEffect } from 'react';
// import { Box, Stack, Radio, FormControlLabel, Typography } from '@mui/material';
// import path from '../../../config/apiUrl';
// import { getData } from '../../../config/axiosFunctions';
// import AnotherFeeSchedule from './new-fee-schedule/AnotherFeeSchedule';
// import MedicareFeeSchedule from './new-fee-schedule/MedicareFeeSchedule';
// import ContractBasedPrices from './new-fee-schedule/ContractBasedPrices';
// import ChargesEntered from './new-fee-schedule/ChargesEntered';

// const CustomNewFeeScheduleFunction = ({ formik }) => {
//   const [feeScheduleCtList, setFeeScheduleCtList] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(formik.values.ctFeesScheduleId);
//   const [selectedIndex, setSelectedIndex] = useState(null);

//   const dataFetchUrls = {
//     feeSchedule: `${path}/CTFeesSchedule`,
//   };

//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.feeSchedule, setFeeScheduleCtList);
//   }, []);

//   useEffect(() => {
//     const selectedIndex = feeScheduleCtList.findIndex(item => item.ctFeesScheduleId === formik.values.ctFeesScheduleId);
//     setSelectedOption(formik.values.ctFeesScheduleId);
//     setSelectedIndex(selectedIndex);
//   }, [formik.values.ctFeesScheduleId, feeScheduleCtList]);

//   const handleRadioChange = (value, index) => {
//     formik.setFieldValue('ctFeesScheduleId', value);
//     setSelectedOption(value);
//     setSelectedIndex(index);
//   };

//   const renderSelectedOption = (index) => {
//     if (selectedIndex !== index) return null;
//     switch (selectedOption) {
//       case 1:
//         return <Typography variant="body1">You have selected: Create an empty fee schedule</Typography>;
//       case 2:
//         return <AnotherFeeSchedule />;
//       case 3:
//         return <MedicareFeeSchedule />;
//       case 4:
//         return <ContractBasedPrices />;
//       case 5:
//         return <ChargesEntered />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <Stack>
//       <Box>
//         <Stack flexDirection="column" alignItems="left" marginLeft="20px">
//           {feeScheduleCtList.map((value, index) => (
//             <Box key={value.ctFeesScheduleId}>
//               <FormControlLabel
//                 label={value.ctFeesScheduleOption}
//                 control={
//                   <Radio
//                     value={value.ctFeesScheduleId}
//                     checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
//                     onChange={() => handleRadioChange(value.ctFeesScheduleId, index)}
//                     name="radio-buttons"
//                   />
//                 }
//               />
//               {renderSelectedOption(index)}
//             </Box>
//           ))}
//         </Stack>
//       </Box>
//     </Stack>
//   );
// };

// export default CustomNewFeeScheduleFunction;

import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import MedicareFeeSchedule from "./new-fee-schedule/MedicareFeeSchedule";
import { useFormik } from "formik";
import CustomButton from "../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { postFeeScheduleTypeAction } from "../../../features/actions/feeSchedulesAction";
import { useNavigate } from "react-router-dom";
import { setFetchedSchedule } from "../../../features/slice/feeScheduleSlice";

const FeeScheduleType = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [feeScheduleTypeOpts, setFeeScheduleTypeOpts] = useState([]);
  const [selectedType, setSelectedType] = useState(1);

  // formik for the schedule type here
  const scheduleTypeFormik = useFormik({
    initialValues: {
      medicareYearId:0, 
      isZipCode: true,
      zipCode: "",
      carrier: "",
      locality: "",
      isFacilityMethod: false,
      adjustPriceId: 1,
      adjustedPriceValue: 0,
    }, 
    onSubmit: (values) => {
      dispatch(postFeeScheduleTypeAction(values)).then(({payload}) => {
        if(payload.result.length > 0 && selectedType === 3) {
          dispatch(setFetchedSchedule(payload.result))
          navigate("/fee-schedule/new")
        }else if (selectedType === 1) {
          navigate("/fee-schedule/new")
        }
      })
    },
  });
  // render UI conditionally
  const renderSelectedOptUi = (typeId) => {
    if (typeId === 2) {
      return <Typography component={"h2"}>Page 1</Typography>;
    } else if (typeId === 3) {
      return <MedicareFeeSchedule scheduleTypeFormik={scheduleTypeFormik} />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchScheduleType = async () => {
      const response = await getData(`${path}/CTFeesSchedule`);
      setFeeScheduleTypeOpts(response.result);
    };

    fetchScheduleType();
  }, [setFeeScheduleTypeOpts]);
  return (
    <Stack
      margin={"20px"}
      component={"form"}
      onSubmit={scheduleTypeFormik.handleSubmit}
    >
      {feeScheduleTypeOpts.map((type) => (
        <Box key={type.ctFeesScheduleId}>
          <FormControlLabel
            label={type.ctFeesScheduleOption}
            control={
              <Radio
                value={type.ctFeesScheduleId}
                checked={selectedType === type.ctFeesScheduleId}
                onChange={() => setSelectedType(type?.ctFeesScheduleId)}
                name="radio-buttons"
              />
            }
          />
          {selectedType === type.ctFeesScheduleId &&
            renderSelectedOptUi(type.ctFeesScheduleId)}
        </Box>
      ))}
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{
          position: "sticky", 
          bottom: 0, 
          left: 0, 
          right: 0,
          background: "#f0f5f7",
          zIndex: "666",
          padding: "10px 20px", 
        }}
      >
        <CustomButton type={"submit"} isBlue={true}>Show Preview</CustomButton>
      </Stack>
    </Stack>
  );
};

export default FeeScheduleType;