import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AccountSummary from "./AccountSummary";
import CloseIcon from "@mui/icons-material/Close";
import SummaryIcon from "./SummaryIcon";
import CustomNotes from "./CustomNotes";
import { getData } from "../config/axiosFunctions";
import path from "../config/apiUrl";
import CustomAlerts from "./CustomAlerts";

const CustomSummary = ({
  isPatientSummary = false,
  isNotes = false,
  isAlert = false,
  handleClose,
  formik,
}) => {
  console.log("formikdd", formik);
  const theme = useTheme();
  const [noteTypeOpts, setNoteTypeOpts] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const handleAccordionChange = useCallback(
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    },
    [setExpanded]
  );
  //   useEffect(() => {

  //   }, [])
  return (
    <Box
      sx={{
        maxHeight: "80vh",
        overflowY: "auto",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <Button
          className="closeButton"
          style={{ background: "#002F7A", border: "1px solid #002F7A" }}
          size="small"
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: "24px" }} />
        </Button>
      </Stack>

      {isPatientSummary && (
        <Accordion
          elevation={0}
          disableGutters
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
          sx={{
            margin: "0px!important",
          }}
        >
          <AccordionSummary
            sx={{
              fontSize: "1.5rem",
              // background:'#216FED',
              // color:'white'

              background: theme.palette.accSummaryAccord.primary,
              color: theme.palette.btnSaveText.primary,
            }}
            expandIcon={
              <SummaryIcon
                sx={{ background: theme.palette.accordiansHeadingText.primary }}
              />
            }
          >
            Account Summary
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: theme.palette.accordiansBodyBackground.primary,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            <AccountSummary formik={formik} />
          </AccordionDetails>
        </Accordion>
      )}
      {isNotes && (
        <Accordion
          elevation={0}
          disableGutters
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
          sx={{
            margin: "0px!important",
          }}
        >
          <AccordionSummary
            sx={{
              fontSize: "1.5rem",
              background: theme.palette.accSummaryAccord.primary,
              color: theme.palette.btnSaveText.primary,
            }}
            expandIcon={
              <SummaryIcon
                sx={{ background: theme.palette.accordiansHeadingText.primary }}
              />
            }
          >
               <Typography component="h3">Notes</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: theme.palette.accordiansBodyBackground.primary,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            <CustomNotes formik={formik} moduleId={2} />
          </AccordionDetails>
        </Accordion>
      )}

      {/* for alerts */}
      {isAlert && (
        <Accordion
          elevation={0}
          disableGutters
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}
          sx={{
            margin: "0px!important",
          }}
        >
          <AccordionSummary
            sx={{
              fontSize: "1.5rem",
              background: theme.palette.accSummaryAccord.primary,
              color: theme.palette.btnSaveText.primary,
            }}
            expandIcon={
              <SummaryIcon
                sx={{ background: theme.palette.accordiansHeadingText.primary }}
              />
            }
          >
            <Stack direction="row" alignItems="center" gap="20px">

              <Typography component="h3">Alerts</Typography>
            <Badge color="error" badgeContent={formik.values.alertMain.alertDto.length}>
            </Badge>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: theme.palette.accordiansBodyBackground.primary,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {/* <CustomNotes formik={formik} moduleId={2} /> */}
            <CustomAlerts formik={formik} />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default CustomSummary;
