import { createSlice } from "@reduxjs/toolkit";
import { getInsuredPartyAction } from "../actions/insuredPartyActions";
const initialState = {
  getInsuredPartyData: [],
  error: null,
  loading: false,
};
const insuredPartySlice = createSlice({
  name: "insuredParty",
  initialState: initialState,

  extraReducers: {
    [getInsuredPartyAction.pending]: (state) => {
      state.loading = true;
    },
    [getInsuredPartyAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInsuredPartyData = action.payload;

    },
    [getInsuredPartyAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default insuredPartySlice.reducer;
