import React, { useCallback, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Custom-styling/CustomSelectBox.css";
import { useTheme } from "@mui/material";
import { getIn } from "formik";
const CustomSelectBox2 = ({
  value,
  handleChange,
  handleBlur,
  label,
  dropdownOptions,
  disable,
  name,
  formik,
  showDefaultOpt = false,
  isSetName = false,
  setValName,
  isRequired = false,
}) => {
  const handleSelectChange = useCallback(
    (e) => {
      const selectedValue = e.target.value;
      const numericValue =
        selectedValue === "" ? null || 0 : Number(selectedValue);
      formik.setFieldValue(name, numericValue);
      if (isSetName) {
        const findValName = dropdownOptions?.find(
          (el) => el.id === numericValue
        );
        formik.setFieldValue(setValName, findValName?.value);
      }
      handleChange();
    },
    [formik, name, dropdownOptions]
  );

  const hasError =
    formik && getIn(formik.touched, name) && getIn(formik.errors, name);
  const theme = useTheme();
  return (
    <div>
      <FormControl fullWidth>
        <label
          className="customLabel"
          style={{ color: theme.palette.Labeltext.primary }}
          htmlFor=""
        >
          {label}
          {isRequired && (
            <span style={{ color: "red", fontSize: "1.5rem" }}> *</span>
          )}
        </label>
        <Select
          sx={{
            "& fieldset": {
              border: "yellow",
            },
            "& .MuiNativeSelect-icon": {
              fontSize: "35px !important",
            },
          }}
          className="customSelectBox"
          style={{
            background: theme.palette.customFieldsBackground.primary,
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
            color: theme.palette.fieldsText.primary,
          }}
          disabled={disable}
          value={value}
          name={name}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          native
          id="dropdowns"
        >
          {!showDefaultOpt && (
            <option
              key={0}
              value={null}
              style={{ color: "lightgray !important" }}
            >
              Select
            </option>
          )}
          {dropdownOptions.map((opt) => {
            return (
              <option
                style={{ background: theme.palette.fieldsDDown.primary }}
                itemType="number"
                key={opt.id}
                value={opt.id}
              >
                {opt.value}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectBox2;