import { Box, FormControlLabel, Radio, Stack,useTheme } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { Typography } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { OutstandingChargesStatement } from "./OutstandingCharges";
import { PaymentPlanStatement } from "./PaymentPlan";
import { FinalDemandNotice } from "./FinalDemand";
import { statementBatchPrintVal } from "../../../utils/formikInitValues";
import { useDispatch } from "react-redux";
import { searchStatementPrintAction } from "../../../features/actions/StatementPrintAction";
import { toast } from "react-toastify";
import BatchSearchedData from "./BatchSearchedData";
import MainHeading from "../../../components/MainHeading";

export const BatchStatmentPrint = () => {
  const theme=useTheme()
  const dispatch = useDispatch();
  const moduleName = "Statement_Batch_Print";
  const [searchedData, setSearchedData] = useState([])
  const [statementBatchPrintTypeList, setStatementBatchPrintTypeList] =
    useState([]);
  const [isSearchedStatement, setIsSearchedStatement] = useState(false);
  const formik = useFormik({
    initialValues: statementBatchPrintVal,
    onSubmit: (values, action) => {
      console.log(values, "CheckValues");
      
      const provSequenceNumberDto = formik.values.providerSequenceNumbers?.map(
        (item) => item.sequenceNumber
      );
      const pracSequenceNumberDto = formik.values.practiceSequenceNumbers?.map(
        (item) => item.sequenceNumber
      );
      const accountIdDto = formik.values.accountTypeIds?.map(
        (item) => item.value
      );
      const updatedValues = {
        ...values,
        providerSequenceNumbers: provSequenceNumberDto,
        practiceSequenceNumbers: pracSequenceNumberDto,
        accountTypeIds: accountIdDto,
      };
      dispatch(searchStatementPrintAction(updatedValues))
        .then(({ payload }) => {
          if (payload.result.statementBatchPrintList.length > 0) {
            setIsSearchedStatement(true);
            setSearchedData(payload.result.statementBatchPrintList)
          } else {
            setIsSearchedStatement(false);
            toast.info(payload.result.message);
          }
        })
        .catch(() => {});
    },
  });
  console.log(formik.values, "formikValues")
  const dataFetchUrl = {
    statmentBatchPrintType: "/ct-statementBatchPrintType",
    accountType: "/ct-accountType",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };
  useEffect(() => {
    fetchData(
      dataFetchUrl.statmentBatchPrintType,
      setStatementBatchPrintTypeList
    );
    // fetchData(dataFetchUrl.accountType, setAccountType);
  }, []);
  return (
    <Box margin="20px">
        {/* <MainHeading mainheading={"Statement Batch Print"} /> */}
      <form onSubmit={formik.handleSubmit}>
      
        {isSearchedStatement ? (
          <BatchSearchedData handleClose={() => setIsSearchedStatement(false)} searchedData={searchedData} />
        ) : (
          <Stack sx={{width: { sx: "95%", md: "95%", lg: "60%" }}}>
               <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
          }}
        >
          <CustomButton
            type="submit"
            children={"Search"}
            isSearch={true}
            isBlue={true}
          />
        </Box>
            <Box sx={{ borderBottom: "1px solid black" }}>
              <Typography style={{color:theme.palette.textMainHeading.primary}} >Type</Typography>
              <Stack
                flexDirection={"column"}
                alignItems={"left"}
                marginLeft={"20px"}
              >
                {statementBatchPrintTypeList.map((value) => {
                  return (
                    <FormControlLabel
                      label={value.statementBatchPrintTypeName}
                      control={
                        <Radio
                          value={value.statementBatchPrintTypeName}
                          variant="soft"
                          name="radio-buttons"
                          checked={
                            formik.values.batchPrintTypeId ===
                            value?.statementBatchPrintTypeId
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "batchPrintTypeId",
                              value?.statementBatchPrintTypeId
                            )
                          }
                        />
                      }
                    />
                  );
                })}
              </Stack>
            </Box>
            {formik.values.batchPrintTypeId === 1 && (
              <OutstandingChargesStatement
                formik={formik}
                moduleName={moduleName}
              />
            )}
            {formik.values.batchPrintTypeId === 2 && (
              <PaymentPlanStatement formik={formik} />
            )}
            {formik.values.batchPrintTypeId === 3 && (
              <FinalDemandNotice formik={formik} />
            )}
          </Stack>
        )}
      </form>
    </Box>
  );
};
