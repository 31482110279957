// import { Box,Typography } from "@mui/material";
// import { useState } from "react";
// import React from "react";
// import MainHeading from "../../../components/MainHeading";
// import RemittanceInfo from "./RemittanceInfo";
// import CustomButton from "../../../components/CustomButton";
// import { useFormik } from "formik";
// import { remittanceValues } from "../../../utils/formikInitValues";
// import { useDispatch, useSelector } from "react-redux";
// import { newRemittanceAction } from "../../../features/actions/remittanceAction";
// import { useNavigate } from "react-router-dom";
// import { remittanceSchema } from "../../../schemas/patientSchema";
// import CustomDialog from "../../../components/CustomDialog";

// const NewRemittance = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const { loading } = useSelector((state) => state.remittance);
//   const formik = useFormik({
//     initialValues: remittanceValues,
//     validationSchema: remittanceSchema,
//     onSubmit: (values, action) => {
//       try {
//         dispatch(newRemittanceAction([values]));
//       } catch (e) {
//         console.log(e, "creating remittance error");
//         throw e;
//       }
//       action.resetForm();
//       navigate("/remittance");
//     },
//   });

//   // handle cancel
//   // const handleCancel = () => {
//   //   console.log("object");
//   //   const conform = window.confirm("Are you sure you want to cancel?");
//   //   if (conform) {
//   //     formik.resetForm();
//   //     navigate("/remittance");
//   //   }
//   // };
//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/remittance");
//   };
//   return (
//     <>
//       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
//         {/* <MainHeading mainheading={"Add New Remittance"} /> */}

//         <Box
//           display="grid"
//           gap="30px"
//           margin={"20px 0"}
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 150px))",
//               sm: "repeat(1, minmax(0, 150px))",
//               md: "repeat(2, minmax(0, 150px))",
//             },
//           }}
//         >
//           <CustomButton
//             type="submit"
//             formId="remittanceForm"
//             isBlue={true}
//             isSave={true}
//           >
//             {loading ? "Saving..." : "Save"}
//           </CustomButton>
//           <CustomButton isCancel={true} handleClick={handleCancel}>
//             Cancel
//           </CustomButton>
//         </Box>
//         <form id="remittanceForm" onSubmit={formik.handleSubmit}>
//           <RemittanceInfo formik={formik} />
//         </form>
//       </Box>
//       <CustomDialog
//      open={openCancelDialog}
//      isWarning={true}
//      handleClose={() => setOpenCancelDialog(false)}
//      handleConfirm={handleConfirmCancel}
//      handleCancel={() => setOpenCancelDialog(false)}
//      confirmButtonTitle="OK"
//      cancelButtonTitle="Cancel"
//     >
//      <Typography component="h3">Are you sure you want to cancel?</Typography>
//     </CustomDialog>
//     </>
//   );
// };

// export default NewRemittance;

// import { Box, Typography } from "@mui/material";
// import { useState } from "react";
import React from "react";
// import MainHeading from "../../../components/MainHeading";
import RemittanceInfo from "./RemittanceInfo";
// import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { remittanceValues } from "../../../utils/formikInitValues";
import { useDispatch
  //  useSelector
   } from "react-redux";
import { newRemittanceAction } from "../../../features/actions/remittanceAction";
import { useNavigate } from "react-router-dom";
import { remittanceSchema } from "../../../schemas/patientSchema";
// import CustomDialog from "../../../components/CustomDialog";

const NewRemittance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: remittanceValues,
    validationSchema: remittanceSchema,
    onSubmit: (values, action) => {
      console.log(values);
      
      try {
        dispatch(newRemittanceAction([values]));
      } catch (e) {
        console.log(e, "creating remittance error");
        throw e;
      }
      action.resetForm();
      navigate("/remittance");
    },
  });
  return (
    <>
      <RemittanceInfo formik={formik} />
    </>
  );
};

export default NewRemittance;
