import { Box, FormControl, useTheme, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";
import CustomField from "../../../components/CustomField";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import RevCode from "../../custom-setup/rev-code/RevCodes";
import CustomDatePicker from "../../../components/CustomDatePicker";
import DxPointer from "../../custom-setup/dxPointer/DxPointer";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { getModifiers } from "../../../features/actions/modifierAction";
import CustomPreview from "../../../layout/CustomPreview";
import { useSelector } from "react-redux";
import {
  procedureCodesCols,
  tosCodesCols,
  posCodesCols,
  modifierCol,
} from "../../../utils/gridCols";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "../../../features/actions/procedureCodeAction";
import { getAllTosCodesActios } from "../../../features/actions/tosAction";
import { getAllPosCodesActios } from "../../../features/actions/posCodeAction";
import { getModifierBySearchTermAction } from "../../../features/actions/modifierAction";
import CustomButton from "../../../components/CustomButton";

const ProcedureClaim = ({
  formik,
  handleClose,
  setClaimChargesDto,
  editProcedure,
  procedureFormik,
}) => {
  const [openProcedureCodeMod, setOpenProcedureCodeMod] = useState(false);
  const [openRevCodeMod, setOpenRevCodeMod] = useState(false);
  const [openTosModal, setOpenTosModal] = useState(false);
  const [openPosModal, setOpenPosModal] = useState(false);
  const [openModifierModal, setOpenModifierModal] = useState(false);
  const [openDxPointerModal, setOpenDxPointerModal] = useState(false);
  const [modIdentifier, setModIdentifier] = useState("");
  const [claimStatusOpt, setClaimStatusOpt] = useState([]);
  // use selectors
  const { getProcedureCodes, loading } = useSelector(
    (state) => state.procedureCodes
  );
  // const {claimType} = useSelector((state) => state.claim);
  // console.log(claimType,"claimType")
  const { getAllTos } = useSelector((state) => state.tosCodes);
  console.log(getAllTos, "getAllTos");

  const tosLoading = useSelector((state) => state.tosCodes.loading);
  const { modifierData } = useSelector((state) => state.modifier);
  const { getAllPos } = useSelector((state) => state.posCodes);
  const claimRows =
    getProcedureCodes && getProcedureCodes?.result?.procedureCodes?.length > 0
      ? getProcedureCodes?.result?.procedureCodes?.map((el) => ({
          id: el.procedureCodeId,
          units: el.units === null ? 1 : el.units,
          ...el,
        }))
      : [];

  // const procedureBoxStyle = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   borderBottom: "1px solid lightgrey",
  //   padding: "7px 14px 10px",
  //   position: "sticky",
  //   top: "0",
  //   zIndex: "111",
  //   color: theme.palette.textMainHeading.primary,
  //   background: theme.palette.accordiansBackground.primary,
  // };
  const getDxPointer = () => {
    let value = "";

    (formik.values?.icD_DiagnosisDetailDto || []).map((v, i) => {
      if (i < 4) {
        value += v?.dxPointerLabel;
      }
    });
    return value;
  };
  // // formik work here
  // const procedureFormik = useFormik({
  //   initialValues: {
  //     procedureCodeId: null,
  //     tosCodeId: null,
  //     posCodeId: null,
  //     procedureCode: "",
  //     toDate: null,
  //     fromDate: null,
  //     posCode: "",
  //     tosCode: "",
  //     unitPrice: 0,
  //     description: "",
  //     revCodeId: null,
  //     dxPointer: getDxPointer(),
  //     units: 0,
  //     claimStatus: "",
  //     claimStatusId: null,
  //     amountBilled: 0,
  //     amountPaid: 0,
  //     isDeleted: false,
  //     mod_Code1: "",
  //     mod_Code2: "",
  //     mod_Code3: "",
  //     mod_Code4: "",
  //     mod_1: null,
  //     mod_2: null,
  //     mod_3: null,
  //     mod_4: null,
  //   },
  //   onSubmit: (values) => {
  //     console.log(values, "procedureValues7");
  //     setClaimChargesDto((prevVals) => [...prevVals, procedureFormik.values]);
  //     formik.setValues((prevVals) => ({
  //       ...prevVals,
  //       claimChargesDto: [...prevVals.claimChargesDto, procedureFormik.values],
  //     }));
  //     handleClose();
  //   },
  // });
  const handleOpenModifierModal = (identifier) => {
    setOpenModifierModal(true);
    setModIdentifier(identifier);
  };

  // handle setProcedure
  const handleProcedureDetail = (val) => {
    console.log("val", val);

    procedureFormik.setValues((prevValues) => ({
      ...prevValues,
      procedureCodeId: val.id,
      description: val?.procedureDescription,
      procedureCode: val.procedureCode,
      unitPrice: val.price || val.unitPrice,
      units: Number(val.units),
      amountBilled: val.price * val.units,
      posCode: val?.posCode || "",
      tosCode: val?.posCode || "",
    }));
    setOpenProcedureCodeMod(false);
  };
  // handle POS Codes
  const handlePoscode = (val) => {
    procedureFormik.setValues((prevVals) => ({
      ...prevVals,
      posCodeId: val.id,
      posCode: val.posCode,
    }));
    setOpenPosModal(false);
  };

  const handleTosCode = (v) => {
    procedureFormik.setValues((prevVals) => ({
      ...prevVals,
      tosCodeId: v.id,
      tosCode: v.tosCode,
    }));
    setOpenTosModal(false);
  };

  // Define data fetching URLs
  const dataFetchUrls = {
    claimStatus: `${path}/ct-claimStatus`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.claimStatus, setClaimStatusOpt);
  }, []);

  useEffect(() => {
    let dxPointer = getDxPointer();
    if (procedureFormik.values?.dxPointer === "") {
      procedureFormik.setFieldValue("dxPointer", dxPointer);
    }
  }, []);

  const handleModifier = (val, identifier) => {
    const fieldId = `mod_${identifier}`;
    const fieldCode = `mod_Code${identifier}`;
    procedureFormik.setValues((prevVals) => ({
      ...prevVals,
      [fieldId]: val.id,
      [fieldCode]: val.modifierCode,
    }));
    setOpenModifierModal(false);
  };

  return (
    <>
      <Grid container spacing={2} padding={"30px"}>
        <Grid item md={6} xs={12}>
          <CustomDatePicker
            formik={procedureFormik}
            dateValue={procedureFormik.values.fromDate}
            name="fromDate"
            dateLabel={formik.values.claimTypeId === 2 ? "Service" : "From"}
            handleDateChange={procedureFormik.handleChange}
            handleDateBlur={procedureFormik.handleBlur}
            isFromTo={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {formik.values.claimTypeId === 1 && (
            <CustomDatePicker
              formik={procedureFormik}
              dateValue={procedureFormik.values.toDate}
              name="toDate"
              dateLabel="To"
              handleDateChange={procedureFormik.handleChange}
              handleDateBlur={procedureFormik.handleBlur}
              isFromTo={true}
            />
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <CustomSearchField
              type="text"
              label={formik.values.claimTypeId === 2 ? "HCPCS" : "Procedure"}
              disable={
                editProcedure ||
                (!procedureFormik.values.fromDate &&
                  !procedureFormik.values.toDatez)
              }
              handleBlur={procedureFormik.handleBlur}
              handleChange={procedureFormik.handleChange}
              fieldVal={procedureFormik.values.procedureCode}
              resetField={{
                procedureCodeId: null,
                description: "",
                procedureCode: "",
                unitPrice: 0,
                units: 0,
                amountBilled: 0,
                posCode: "",
                tosCode: "",
              }}
              isRequired={true}
              name="procedureCode"
              handleModalOpen={() => setOpenProcedureCodeMod(true)}
              formik={procedureFormik}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          {formik.values.claimTypeId === 1 && (
            <FormControl fullWidth>
              <CustomSearchField
                type="text"
                label="Pos"
                handleChange={procedureFormik.handleChange}
                fieldVal={procedureFormik.values.posCode}
                isRequired={true}
                name="posCode"
                handleModalOpen={() => setOpenPosModal(true)}
                resetField={{
                  posCodeId: null,
                  posCode: "",
                }}
                handleBlur={procedureFormik.handleBlur}
                formik={procedureFormik}
                disable={
                  !procedureFormik.values.fromDate &&
                  !procedureFormik.values.toDate
                }
              />
            </FormControl>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          {formik.values.claimTypeId === 1 && (
            <FormControl fullWidth>
              <CustomSearchField
                type="text"
                label="Tos"
                handleChange={procedureFormik.handleChange}
                handleBlur={procedureFormik.handleBlur}
                fieldVal={procedureFormik.values.tosCode}
                isRequired={true}
                resetField={{
                  tosCodeId: null,
                  tosCode: "",
                }}
                name="tosCode"
                handleModalOpen={() => setOpenTosModal(true)}
                formik={procedureFormik}
                disable={
                  !procedureFormik.values.fromDate &&
                  !procedureFormik.values.toDate
                }
              />
            </FormControl>
          )}

          {formik.values.claimTypeId === 2 && (
            <FormControl fullWidth>
              <CustomSearchField
                type="text"
                label={"Rev Code"}
                handleModalOpen={() => setOpenRevCodeMod(true)}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                setFieldValue={procedureFormik.setFieldValue}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="number"
            label="Units"
            name="units"
            value={procedureFormik.values.units}
            handleChange={procedureFormik.handleChange}
            handleBlur={procedureFormik.handleBlur}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="number"
            label="Price"
            name="unitPrice"
            disable={true}
            value={procedureFormik.values.unitPrice}
            handleChange={procedureFormik.handleChange}
            handleBlur={procedureFormik.handleBlur}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="number"
            label="Amount"
            name="amountBilled"
            disable={true}
            value={
              procedureFormik.values.units * procedureFormik.values.unitPrice
            }
            handleChange={procedureFormik.handleChange}
            handleBlur={procedureFormik.handleBlur}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {formik.values.claimTypeId === 1 && (
            <CustomSearchField
              type="text"
              label="DX Pointer"
              fieldVal={procedureFormik.values.dxPointer}
              name="dxPointer"
              handleModalOpen={() => setOpenDxPointerModal(true)}
              resetField={{
                dxPointer: "",
              }}
              isRequired={true}
              formik={procedureFormik}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomSelectBox2
            name="claimStatusId"
            value={procedureFormik.values.claimStatusId}
            formik={procedureFormik}
            dropdownOptions={claimStatusOpt?.map((opt) => ({
              value: opt.claimStatusType,
              id: opt.claimStatusId,
            }))}
            label="Status"
            handleChange={procedureFormik.handleChange}
            handleBlur={procedureFormik.handleBlur}
            isSetName={true}
            setValName={"claimStatus"}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Mod 1"
            fieldVal={procedureFormik.values.mod_Code1}
            handleChange={procedureFormik.handleChange}
            name="mod_Code1"
            handleModalOpen={() => handleOpenModifierModal(1)}
            resetField={{
              mod_1: null,
              mod_Code1: "",
            }}
            formik={procedureFormik}
            handleBlur={procedureFormik.handleBlur}
            isRequired={true}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Mod 2"
            fieldVal={procedureFormik.values.mod_Code2}
            handleChange={procedureFormik.handleChange}
            name="mod_Code2"
            handleModalOpen={() => handleOpenModifierModal(2)}
            resetField={{
              mod_2: null,
              mod_Code2: "",
            }}
            formik={procedureFormik}
            handleBlur={procedureFormik.handleBlur}
            isRequired={true}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Mod 3"
            fieldVal={procedureFormik.values.mod_Code3}
            handleChange={procedureFormik.handleChange}
            name="mod_Code3"
            handleModalOpen={() => handleOpenModifierModal(3)}
            resetField={{
              mod_3: null,
              mod_Code3: "",
            }}
            formik={procedureFormik}
            handleBlur={procedureFormik.handleBlur}
            isRequired={true}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomSearchField
            label="Mod 4"
            fieldVal={procedureFormik.values.mod_Code4}
            handleChange={procedureFormik.handleChange}
            name="mod_Code4"
            handleModalOpen={() => handleOpenModifierModal(4)}
            resetField={{
              mod_4: null,
              mod_Code4: "",
            }}
            formik={procedureFormik}
            handleBlur={procedureFormik.handleBlur}
            isRequired={true}
          />
        </Grid>
        {/* <Box
          sx={{
            position: "fixed",
            bottom: "0",
            textAlign: "end",
            // border:'1px solid red',
            // width:'100%'
          }}
        >
          <CustomButton
            isBlue={true}
            handleClick={procedureFormik.handleSubmit}
          >
            Add
          </CustomButton>
        </Box> */}
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          background: "#f0f5f7",
          width: "100%",
          padding: "5px",
          textAlign: "right",
          zIndex: 1001,
        }}
      >
        <CustomButton isBlue={true} handleClick={procedureFormik.handleSubmit}>
          Add
        </CustomButton>
      </Box>

      {/* procedure modal */}
      <CustomModal
        open={openProcedureCodeMod}
        handleClose={() => setOpenProcedureCodeMod(false)}
        heading="Search Procedure Codes"
      >
        <CustomPreview
          isModal={true}
          rows={claimRows}
          columns={procedureCodesCols}
          handleGetAll={getProcedureCodeAction}
          loading={loading}
          handleBySearch={getProcedureCodesBySearchTermAction}
          searchLabel="Search for CPT/HCPCS codes by code or description"
          paginationDetail={getProcedureCodes}
          handleCell={handleProcedureDetail}
        />
      </CustomModal>
      {/* tos modal */}
      <CustomModal
        open={openTosModal}
        handleClose={() => setOpenTosModal(false)}
        heading="Search Tos Codes"
      >
        <CustomPreview
          isModal={true}
          rows={
            getAllTos && getAllTos?.result?.data?.length > 0
              ? getAllTos?.result?.data?.map((el) => ({
                  id: el?.tosCodeId,
                  ...el,
                }))
              : []
          }
          columns={tosCodesCols}
          paginationDetail={getAllTos}
          // searchLabel="Search by code"
          loading={tosLoading}
          handleGetAll={getAllTosCodesActios}
          handleCell={handleTosCode}
        />
      </CustomModal>
      {/* pos modal */}
      <CustomModal
        open={openPosModal}
        handleClose={() => setOpenPosModal(false)}
        heading="Search Pos Codes"
      >
        <CustomPreview
          isModal={true}
          rows={
            getAllPos && getAllPos?.result?.data?.length > 0
              ? getAllPos?.result?.data?.map((el) => ({
                  id: el?.posCodeId,
                  ...el,
                }))
              : []
          }
          columns={posCodesCols}
          handleGetAll={getAllPosCodesActios}
          handleCell={handlePoscode}
          // searchLabel="Search by Pos Code"
        />
      </CustomModal>
      {/* modifier modal */}
      {console.log("modifierData", modifierData)}
      {/* <CustomModal
        open={openModifierModal}
        handleClose={() => setOpenModifierModal(false)}
        heading={"Modifier"}
      >
        <CustomPreview
          rows={
            modifierData && modifierData.length > 0
              ? modifierData.map((el) => ({
                id: el.modifierId,
                modifierCode: el.modifierCode,
                modifierDescription: el.modifierDescription,
              }))
              : []
          }
          isModal={true}
          columns={modifierCol}
          handleCell={(params) => {
            handleModifier(params, modIdentifier);
          }}
          handleGetAll={getModifiers}
          loading={modiflerLoading}
          searchLabel="Search By Codes"
        />
    
      </CustomModal> */}
      <CustomModal
        open={openModifierModal}
        handleClose={() => setOpenModifierModal(false)}
        heading={"Modifier"}
      >
        <CustomPreview
          rows={
            modifierData && modifierData?.data?.length > 0
              ? modifierData?.data?.map((el) => ({
                  id: el.modifierId,
                  modifierCode: el.modifierCode,
                  modifierDescription: el.modifierDescription,
                }))
              : []
          }
          columns={modifierCol}
          handleGetAll={getModifiers}
          paginationDetail={modifierData}
          handleBySearch={getModifierBySearchTermAction}
          isModal={true}
          handleCell={(params) => {
            handleModifier(params, modIdentifier);
          }}
        />
      </CustomModal>
      <CustomModal
        open={openRevCodeMod}
        handleClose={() => setOpenRevCodeMod(false)}
      >
        <RevCode handleClose={() => setOpenRevCodeMod(false)} />
      </CustomModal>
      {/* Dx Ponter */}
      <CustomModal
        open={openDxPointerModal}
        handleClose={() => setOpenDxPointerModal(false)}
        heading={"DX Pointer Selection"}
      >
        <DxPointer
          handleClose={() => setOpenDxPointerModal(false)}
          formik={formik}
          procedureValues={procedureFormik.values}
          setProcedureValues={procedureFormik}
        />
      </CustomModal>
    </>
  );
};

export default ProcedureClaim;
