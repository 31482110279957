import { Grid, Stack,useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomField from "../../../components/CustomField";
import { useFormik } from "formik";
import { chargePanelProcedureValues } from "../../../utils/formikInitValues";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import CustomPreview from "../../../layout/CustomPreview";
import { useSelector } from "react-redux";
import {
  posCodesCols,
  procedureCodesCols,
  tosCodesCols,
} from "../../../utils/gridCols";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "../../../features/actions/procedureCodeAction";
import { getAllPosCodesActios } from "../../../features/actions/posCodeAction";
import { getAllTosCodesActios } from "../../../features/actions/tosAction";
import Modifiers from "../modifiers/Modifiers";
import CustomButton from "../../../components/CustomButton";

const AddPanelProcedure = ({ formik, handleClose }) => {
  const theme=useTheme()
  const [openProcedureMod, setOpenProcedureMod] = useState(false);
  const [openPosMod, setOpenPosMod] = useState(false);
  const [openTosMod, setOpenTosMod] = useState(false);
  const [openModifierMod, setOpenModifierMod] = useState(false);
  const [chargePanelOpt, setChargePanelOpt] = useState([]);
  const [modIdentifier, setModIdentifier] = useState("");
  const [procedureCodeSelected, setProcedureCodeSelected] = useState(false);
  //   useSelector here
  const { getProcedureCodes, loading } = useSelector(
    (state) => state.procedureCodes
  );
  const { getAllPos } = useSelector((state) => state.posCodes);
  const posCodeLoading = useSelector((state) => state.posCodes.loading);
  const { getAllTos } = useSelector((state) => state.tosCodes);
  const tosCodeLoading = useSelector((state) => state.tosCodes.loading);
  const procedureRows =
  getProcedureCodes && getProcedureCodes?.result?.procedureCodes?.length > 0
    ? getProcedureCodes?.result?.procedureCodes?.map((el) => ({
      id: el.procedureCodeId,
        units: el.units === null ? 1 : el.units,
        ...el,
      }))
    : [];
  const panelFormik = useFormik({
    initialValues: chargePanelProcedureValues,
    onSubmit: (values) => {
      console.log(values, "panelProcedureValues");
      const existingPanels = [...formik.values.chargePanelCodeDtos];
      const findPanel = existingPanels?.find(
        (p) => p?.procedureCodeId === values?.procedureCodeId
      );
      if (!findPanel) {
        formik.values.chargePanelCodeDtos.push(values);
        handleClose();
      } else {
        alert("Already exist in the table");
      }
    },
  });
  console.log(formik.values.chargePanelCodeDtos, "chargePanelCodeDtos");
  //   fetch cts
  const dataFetchUrls = {
    chargerPanelOption: `${path}/ct-chargePanelOption`,
  };

  useEffect(() => {
    const unitprice=panelFormik.values.unitPrice;
    const unit=panelFormik.values.units;
    const total = unitprice * unit;
    panelFormik.setFieldValue("total", total);
  }, [panelFormik.values.unitPrice, panelFormik.values.units]);

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.chargerPanelOption, setChargePanelOpt);
   
  }, []);

  //   handle add procedure
  const handleAddProcedureDetail = (param) => {
    console.log(param, "handleAddProced");
    panelFormik.setValues((preVals) => ({
      ...preVals,
      procedureCodeId: param?.procedureCodeId,
      procedureCode: param?.procedureCode,
      posCodeId: param?.posCodeId,
      posCode: param?.posCode,
      tosCodeId: param?.tosCodeId,
      tosCode: param?.tosCode,
      units: param?.units,
      unitPrice: param?.price,
      total: param?.price * param?.units,
    }));
    setOpenProcedureMod(false);
    setProcedureCodeSelected(true);
  };
  // handle open modifier
  const handleOpenModifierModal = (identifier) => {
    setOpenModifierMod(true);
    setModIdentifier(identifier);
  };

  console.log(panelFormik.values, "allPanelValues");
  return (
    <>
      <Grid
        container
        spacing={2}
        component="form"
        padding="18px"
        onSubmit={panelFormik.handleSubmit}
      >
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="Code"
            fieldVal={panelFormik.values.procedureCode}
            name="procedureCode"
            handleModalOpen={() => setOpenProcedureMod(true)}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isRequired={true}
            formik={formik}
            resetField={{
              procedureCode:""
            }}
            
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="POS"
            fieldVal={panelFormik.values.posCode}
            name="posCode"
            handleModalOpen={() => setOpenPosMod(true)}
            disable={!procedureCodeSelected}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="TOS"
            fieldVal={panelFormik.values.tosCode}
            name="tosCode"
            handleModalOpen={() => setOpenTosMod(true)}
            disable={!procedureCodeSelected}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue} 
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelectBox2
            label="Modifier Options"
            dropdownOptions={chargePanelOpt?.map((el) => ({
              id: el.chargePanelOptionId,
              value: el.chargePanelOption,
            }))}
            value={panelFormik.values.modifierOptionId}
            name="modifierOptionId"
            formik={panelFormik}
            handleBlur={panelFormik.handleBlur}
            handleChange={panelFormik.handleChange}
            showDefaultOpt={true}
            disable={!procedureCodeSelected}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="Modifier 1"
            fieldVal={panelFormik.values.mod_Code1}
            name="mod_Code1"
            handleModalOpen={() => handleOpenModifierModal(1)}
            disable={panelFormik.values.modifierOptionId === 1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="Modifier 2"
            fieldVal={panelFormik.values.mod_Code2}
            name="mod_Code2"
            handleModalOpen={() => handleOpenModifierModal(2)}
            disable={panelFormik.values.modifierOptionId === 1}
      
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="Modifier 3"
            fieldVal={panelFormik.values.mod_Code3}
            name="mod_Code3"
            handleModalOpen={() => handleOpenModifierModal(3)}
            disable={panelFormik.values.modifierOptionId === 1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSearchField
            label="Modifier 4"
            fieldVal={panelFormik.values.mod_Code4}
            name="mod_Code4"
            handleModalOpen={() => handleOpenModifierModal(4)}
            disable={panelFormik.values.modifierOptionId === 1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelectBox2
            label="Price options"
            dropdownOptions={chargePanelOpt?.map((el) => ({
              id: el.chargePanelOptionId,
              value: el.chargePanelOption,
            }))}
            value={panelFormik.values.priceOptionId}
            name="priceOptionId"
            formik={panelFormik}
            handleBlur={panelFormik.handleBlur}
            handleChange={panelFormik.handleChange}
            showDefaultOpt={true}
            disable={!procedureCodeSelected}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField
            type="number"
            isDecimal={true}
            label="Price"
            // disable={!procedureCodeSelected}
            value={panelFormik.values.unitPrice}
            name="unitPrice"
            handleChange={panelFormik.handleChange}
            handleBlur={panelFormik.handleBlur}
            disable={panelFormik.values.priceOptionId === 1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField
            type="number"
            isDecimal={true}
            label="Units"
            disable={!procedureCodeSelected}
            value={panelFormik.values.units}
            name="units"
            handleChange={panelFormik.handleChange}
            handleBlur={panelFormik.handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField
            type="number"
            isDecimal={true}
            label="Total"
            disable={!procedureCodeSelected}
            value={panelFormik.values.total}
            name="total"
            handleChange={panelFormik.handleChange}
            handleBlur={panelFormik.handleBlur}
          />
        </Grid>

        <Stack sx={{position: "sticky", bottom: 0, 
                 padding: "4px", zIndex: 1 }} direction="row" alignItems="center" gap={2} padding="12px 8px">
          <CustomButton isAdd={true} isBlue={true} type="submit">
            Add
          </CustomButton>
        </Stack>
      </Grid>

      {/* modals here */}
      <CustomModal
        open={openProcedureMod}
        handleClose={() => setOpenProcedureMod(false)}
        heading="Procedure Codes"
      >
        <CustomPreview
          columns={procedureCodesCols}
          rows={procedureRows}
         
          loading={loading}
          handleGetAll={getProcedureCodeAction}
          handleBySearch={getProcedureCodesBySearchTermAction}
          searchLabel="Search for CPT/HCPCS codes by code or description"
          isModal={true}
          handleCell={handleAddProcedureDetail}
        />
      </CustomModal>
      {/* pos */}
      <CustomModal
        open={openPosMod}
        heading="POS Codes"
        handleClose={() => setOpenPosMod(false)}
      >
        <CustomPreview
          isModal={true}
          searchLabel="Search by code, descroption"
          rows={
            getAllPos && getAllPos.length > 0
              ? getAllPos.map((el) => ({
                  id: el?.posCodeId,
                  ...el,
                }))
              : []
          }
          columns={posCodesCols}
          handleGetAll={getAllPosCodesActios}
          handleCell={(param) => {
            panelFormik.setFieldValue("posCode", param?.posCode);
            panelFormik.setFieldValue("posCodeId", param?.id);
            setOpenPosMod(false);
          }}
          loading={posCodeLoading}
        />
      </CustomModal>
      {/* tos modal */}
      <CustomModal
        open={openTosMod}
        heading="TOS Codes"
        handleClose={() => setOpenTosMod(false)}
      >
        <CustomPreview
          isModal={true}
          searchLabel="Search by code, descroption"
          rows={
            getAllTos && getAllTos.length > 0
              ? getAllTos.map((el) => ({
                  id: el?.tosCodeId,
                  ...el,
                }))
              : []
          }
          columns={tosCodesCols}
          handleGetAll={getAllTosCodesActios}
          handleCell={(param) => {
            panelFormik.setFieldValue("tosCode", param?.tosCode);
            panelFormik.setFieldValue("tosCodeId", param?.id);
            setOpenTosMod(false);
          }}
          loading={tosCodeLoading}
        />
      </CustomModal>
      {/* modifiers modal */}
      <CustomModal
        open={openModifierMod}
        handleClose={() => setOpenModifierMod(false)}
      >
        <Modifiers
          setProcedureValues={panelFormik}
          identifier={modIdentifier}
          handleClose={() => setOpenModifierMod(false)}
        />
      </CustomModal>
    </>
  );
};

export default AddPanelProcedure;


