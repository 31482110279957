// import React from "react";
// import MainHeading from "../../../components/MainHeading";
// import { Box, Stack, Typography } from "@mui/material";
// import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { newPracticeAction } from "../../../features/actions/practiceAction";
// import CustomButton from "../../../components/CustomButton";
// import PracticeInfo from "./PracticeInfo";
// import { practiceInitVals } from "../../../utils/formikInitValues";
// import { practiceSchema } from "../../../schemas/patientSchema";
// import CustomDialog from "../../../components/CustomDialog";
// import { useState } from "react";
// import CustomModal from "../../../components/CustomModal";
// import OtherOfiice from "./OtherOfiice";

// const NewPractice = () => {
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const [openAddOfficeMod, setOpenAddOfficeMod] = useState(false)
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const formik = useFormik({
//     initialValues: practiceInitVals,
//     validationSchema: practiceSchema,
//     onSubmit: (values, action) => {

//       try {
//         dispatch(newPracticeAction(values));

//       } catch (error) {
//         console.error("Error creating patient:", error);
//       }
//       action.resetForm();
//       navigate("/practice");
//       console.log("values practice",values)
//     },

//   });

//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/practice");
//   };
//   return (
//     <>
//       <Box margin={"20px"}>
//         <Stack
//          direction={"row"}
//          alignItems={"center"}
//          gap={"10px"}
//          marginBottom={"20px"}
//         >
//           <CustomButton
//             isBlue={true}
//             type="submit"
//             formId="practice-form"
//             isSave={true}
//           >
//             Save
//           </CustomButton>

//           <CustomButton isCancel={true} handleClick={handleCancel}>
//             Cancel
//           </CustomButton>
//           <CustomButton isAdd={true} handleClick={() => setOpenAddOfficeMod(true)}>
//             Add Other Office
//           </CustomButton>
//         </Stack>

//         <form id="practice-form" onSubmit={formik.handleSubmit}>
//           <PracticeInfo formik={formik} />
//         </form>
//       </Box>
//       <CustomDialog
//         open={openCancelDialog}
//         isWarning={true}
//         handleClose={() => setOpenCancelDialog(false)}
//         handleConfirm={handleConfirmCancel}
//         handleCancel={() => setOpenCancelDialog(false)}
//         confirmButtonTitle="OK"
//         cancelButtonTitle="Cancel"
//       >
//         <Typography component="h3">Are you sure you want to cancel?</Typography>
//       </CustomDialog>
//       {/* Other offices */}
//       <CustomModal height={"auto"} heading={"Add Office"} open={openAddOfficeMod} handleClose={() => setOpenAddOfficeMod(false)}>
//           <OtherOfiice formik={formik} handleClose={() => setOpenAddOfficeMod(false)} />
//       </CustomModal>
//     </>
//   );
// };

// export default NewPractice;

import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPracticeListAction, newPracticeAction } from "../../../features/actions/practiceAction";
import CustomButton from "../../../components/CustomButton";
import PracticeInfo from "./PracticeInfo";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import OtherOfiice from "./OtherOfiice";

const NewPractice = () => {
  let flag = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: practiceInitVals,
    validationSchema: practiceSchema,
    onSubmit: (values, action) => {
      try {
        if(!flag){
          flag = true;
          dispatch(newPracticeAction(values));
          dispatch(getPracticeListAction())
          navigate("/practice");
        }
      } catch (error) {
        console.error("Error creating patient:", error);
      }
      action.resetForm();
    },
  });

  return (
    <>
      <PracticeInfo formik={formik} />
    </>
  );
};

export default NewPractice;
