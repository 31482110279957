import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryCodeAction,
  newInventoryCodeAction,
  updateInventoryCodeAction,
  getInventoryCodeIdAction
} from "../actions/inventoryCode";
import { toast } from "react-toastify";

const initialState = {
  getInventoryCodeData: [],
  createInventoryCodeData: {},
  inventoryCodeByIdData: {},
  error: null,
  loading: false,
};
const inventoryCodeSlice = createSlice({
  name: "inventoryCode",
  initialState: initialState,

  extraReducers: {
    [getInventoryCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [getInventoryCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getInventoryCodeData = action.payload;
    },
    [getInventoryCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "inventoryCodeErr");
    },
    [getInventoryCodeIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getInventoryCodeIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventoryCodeByIdData = action.payload;
      console.log(action.paylaod, "inventoryCode slice get payload");
    },
    [getInventoryCodeIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [newInventoryCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [newInventoryCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createInventoryCodeData = action.payload;
      toast.success("InventoryCode has been created successfully!");
    },
    [newInventoryCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "inventoryCodeErr");
      toast.error("InventoryCode Creation Failed.");
    },
    [updateInventoryCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [updateInventoryCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      // state.createInventoryCodeData = action.payload;
      // const updateInventoryCodeIndex =
      //   state.getInventoryCodeData.result?.findIndex(
      //     (item) => item.inventoryCodeId === action.payload.inventoryCodeId
      //   );
      // if (updateInventoryCodeIndex !== -1) {
      //   state.getInventoryCodeData.result[updateInventoryCodeIndex] = {
      //     ...state.getPractices.result[updateInventoryCodeIndex],
      //     ...action.payload,
      //   };
      // }
      toast.success("Inventory Code Updated Successfully!");
    },
    [updateInventoryCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "inventoryCodeErr");
      toast.error("InventoryCode Updation Failed.");
    },
  },
});

export default inventoryCodeSlice.reducer;
