import { Box, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomDatePicker from "./../../../components/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

const FeeScheduleForm = ({ feeScheduleFormik }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-content",
      headerClassName: "header-bg",
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-content",
      headerClassName: "header-bg",
      editable: true,
      valueFormatter: (params) => {
        const price = Number(params.value);
        return price.toFixed(2);
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-content",
      headerClassName: "header-bg",
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
      flex: 3,
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-content",
      headerClassName: "header-bg",
    },
  ];

  const handleEditCell = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [field]: Number(value),
        };
      }
      return row;
    });
    setRows(updatedRows);
    feeScheduleFormik.setFieldValue("feeSchedulePriceDto", updatedRows);
  };

  useEffect(() => {
    if (feeScheduleFormik.values.feeSchedulePriceDto.length > 0) {
      setRows(
        feeScheduleFormik.values.feeSchedulePriceDto.map((el) => ({
          id: el?.feeSchedulePriceId,
          ...el,
        }))
      );
    }
  }, [feeScheduleFormik.values.feeSchedulePriceDto]);
  return (
    <>
      <Grid container spacing={2} sx={{ width: "50%" }}>
        <Grid item md={12}>
          <CustomField
            label={"Name"}
            value={feeScheduleFormik?.values?.feeSchedulesName}
            name="feeSchedulesName"
            handleChange={feeScheduleFormik?.handleChange}
            handleBlur={feeScheduleFormik?.handleBlur}
            isRequired={true}
            error={feeScheduleFormik.errors}
            touched={feeScheduleFormik.touched}
          />
        </Grid>

        <Grid item md={6}>
          <CustomDatePicker
            dateLabel={"Effective From"}
            handleDateBlur={feeScheduleFormik?.handleBlur}
            handleDateChange={feeScheduleFormik?.handleChange}
            name={"effectiveFrom"}
            isFromTo={true}
            formik={feeScheduleFormik}
            dateValue={feeScheduleFormik?.values?.effectiveFrom}
          />
        </Grid>
        <Grid item md={6}>
          {" "}
          <CustomDatePicker
            dateLabel={"Effective To"}
            handleDateBlur={feeScheduleFormik?.handleBlur}
            handleDateChange={feeScheduleFormik?.handleChange}
            name={"effectiveTo"}
            isFromTo={true}
            formik={feeScheduleFormik}
            dateValue={feeScheduleFormik?.values?.effectiveTo}
          />
        </Grid>
        <Grid item md={12}>
          <CustomField
            label={"Description"}
            value={feeScheduleFormik?.values?.description}
            name="description"
            handleChange={feeScheduleFormik?.handleChange}
            handleBlur={feeScheduleFormik?.handleBlur}
          />
        </Grid>
      </Grid>

      <Box marginTop={"20px"}>
        <DataGrid
          rows={rows}
          showCellRightBorder
          disableSelectionOnClick
          rowsPerPageOptions={[]}
          columns={columns}
          autoHeight
          rowHeight={35}
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                display: "none",
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
            "& .MuiTablePagination-displayedRows": { display: "none" },
            "& .MuiInputBase-input": {
              fontSize: "1.3rem", // Adjust the font size here
            },
          }}
          onCellEditCommit={(params) =>
            handleEditCell(params.id, params.field, params.value)
          }
        />
      </Box>
    </>
  );
};
export default FeeScheduleForm;