import { useFormik } from "formik";
import React from "react";
import { adjustmentInitVals } from "../../../utils/formikInitValues";
import { useDispatch, useSelector } from "react-redux";
import AdjustmentInfo from "./AdjustmentInfo";
import { useNavigate } from "react-router";
import { newAdjustmentAction } from "../../../features/actions/adjustmentAction";
import { adjustmentSchema } from "../../../schemas/patientSchema";

const NewAdjustmentCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: adjustmentInitVals,
    validationSchema : adjustmentSchema,
    onSubmit: (values, action) => {
      console.log(values, "values");
      try {
        dispatch(newAdjustmentAction(values));
      } catch (err) {
        console.log(err, "create adjustment error");
        throw err;
      }
      action.resetForm();
      navigate("/adjustment");
    },
  });

 
  return (
    <>
     <AdjustmentInfo formik={formik} />
 </>

  );
};

export default NewAdjustmentCode;
