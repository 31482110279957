import React from "react";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import { FormControl, Grid } from "@mui/material";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { useState, useEffect } from "react";
import { getData, postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import MasterTable from "./MasterTable";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import CustomMultiSelect from "../../../components/CustomMultiSelect/CustomMultiSelect";
import { useTheme } from "@mui/material";

const SearchRevMaster = () => {
  const theme = useTheme();
  const [openMasterMod, setOpenMasterMod] = useState(false);
  const [revenueCodeCategoryOpt, setRevenueCodeCategoryOpt] = useState([]);
  const [ctMasterList, setCtMasterList] = useState([]);
  const [masterListData, setMasterListData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  console.log(selectedCategory, "selectedCategory");
  const transformedOptions = revenueCodeCategoryOpt.map((item) => ({
    label: item.revenueCodeCategory,
    value: item.revenueCodeCategoryId,
  }));
  // formik here
  const formik = useFormik({
    initialValues: {
      searchValue: "",
      orderBy: 1,
    },
    onSubmit: async (values) => {
      // console.log(values, "checkSearchObject");
      const selectedCategoryIds = selectedCategory.map(
        (category) => category.value
      );

      try {
        const response = await postData(
          `${path}/masterRevenueCode/SearchMasterRevenueCodeByCategory`,
          {
            ...values,
            searchCategory:
              selectedCategoryIds.length > 0 ? selectedCategoryIds : [0],
          }
        );
        if (response) {
          setMasterListData(response.result);
        }
        setOpenMasterMod(true);
      } catch (err) {
        console.log(err, ": error in fetching master list data");
        throw new Error(err);
      }
    },
  });
  const dataFetchUrls = {
    revenueCodeCategory: `${path}/ct-revenueCodeCategory`,
    ctMasterList: `${path}/ct-masterListOrderBy`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(
      dataFetchUrls.revenueCodeCategory,
      setRevenueCodeCategoryOpt
    );
    fetchDataOptions(dataFetchUrls.ctMasterList, setCtMasterList);
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems="end" padding="18px">
        <Grid item md={9}>
          <CustomField
            type="text"
            label="Enter Code to Search For"
            value={formik.values.searchValue}
            name="searchValue"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth>
            <CustomButton
              type="submit"
              isBlue={true}
              isSearch={true}
              handleClick={formik.handleSubmit}
            >
              Search
            </CustomButton>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            {/* <MultiSelect
              options={transformedOptions}
              value={selectedCategory}
              onChange={setSelectedCategory}
              style={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "red",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "blue",
                }),
              }}
            /> */}
              <CustomMultiSelect
            className="custom-multi-select"
            options={transformedOptions}
            value={selectedCategory}
          handleChange={setSelectedCategory}
           handleSelectAll={true}
         />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <CustomSelectBox2
            value={formik.values.orderBy}
            showDefaultOpt={true}
            name="orderBy"
            dropdownOptions={ctMasterList?.map((item) => ({
              value: item.masterListOrderByName,
              id: item.masterListOrderById,
            }))}
            label="Order Results By"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          />
        </Grid>
      </Grid>

      {/* modals */}
      <CustomModal
        open={openMasterMod}
        handleClose={() => setOpenMasterMod(false)}
        showAddButton={true}
        heading={"Revenue Master List"}
      >
        <MasterTable masterListData={masterListData} />
      </CustomModal>
    </>
  );
};

export default SearchRevMaster;
