import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import manageaccount from "../../../src/assets/manage account.svg";
import feeschedule from "../../../src/assets/Free schedule-01.svg";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";

import Collapse from "@mui/material/Collapse";
// import { ToggleButton } from "react-bootstrap";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import "../../components/Custom-styling/Sidebar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";
import FormControl from "@mui/material/FormControl";
// import { useMediaQuery } from "@mui/material";
import { IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import PersonIcon from "@mui/icons-material/Person";
// import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import createpatient from "../../../src/assets/create patient.svg";
import managepatient from "../../../src/assets/manage patient.svg";
import claim from "../../../src/assets/claim1.svg";
import claimtracker from "../../../src/assets/claim tracker.svg";
import accountadministration from "../../../src/assets/account administration.svg";
import arcontrol from "../../../src/assets/AR control.svg";
import payment from "../../../src/assets/payment1.svg";
import viewpayment from "../../../src/assets/view payment.svg";
import practice from "../../../src/assets/practice.svg";
import provider from "../../../src/assets/providerrr.svg";
import icdprocedure from "../../../src/assets/ICD procedure-01.svg";
import statuscontrol from "../../../src/assets/status control.svg";
import followupmanagement from "../../../src/assets/follow up management.svg";
import paymentplans from "../../../src/assets/payment plans.svg";
import statementbatchprint from "../../../src/assets/statement batch print.svg";
import paymenttracker from "../../../src/assets/payment tracker.svg";
import labelbatchprint from "../../../src/assets/label batch print.svg";
import referringprovider from "../../../src/assets/referring provider.svg";
import appointment from "../../../src/assets/appointment.svg";
// import faq from "../../../src/assets/FAQ.svg";
// import form from "../../../src/assets/profile form.svg";
import setup from "../../../src/assets/setup.svg";
import paymentt from "../../../src/assets/paymentt.svg";
import facility from "../../../src/assets/facility.svg";
import diagnosis from "../../../src/assets/diagnosis.svg";
import logout from "../../../src/assets/log out.svg";
import patientt from "../../../src/assets/patientt.svg";
import claimt from "../../../src/assets/claimt.svg";
import registeruser from "../../../src/assets/registeruser.svg";
import pagest from "../../../src/assets/pagest.svg";
import dashboardt from "../../../src/assets/dashboardt.svg";
import remittence from "../../../src/assets/remittance.svg";
import revenue from "../../../src/assets/revenue.svg";
import adjustment from "../../../src/assets/adjustment.svg";
import icdProcedure from "../../../src/assets/ICD procedure-01.svg";
import chargepanel from "../../../src/assets/charge panel-01.svg";
import multiplemanagepatients from "../../../src/assets/multiple manage patients.svg";
import procedure from "../../../src/assets/procedure-01.svg";
import inventory from "../../../src/assets/inventory-01.svg";
import era from "../../../src/assets/ERA.svg";

import { useTheme } from "@mui/material";
import CustomSearchField from "../../components/CustomSearchField";
import { Telegram } from "@mui/icons-material";

const drawerWidth = 240;
const userTypeIdLocal = () => {
  return JSON.parse(localStorage.getItem("userDetails"));
};
const Sidebar2 = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [hide, setHide] = useState(userTypeIdLocal());
  console.log(hide);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleToggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const handleLogout = () => {
    // let cookies = document.cookie.split(";");
    // for (let i = 0; i < cookies.length; i++) {
    //   const cookie = cookies[i].trim();
    //   if (cookie.startsWith("token=")) {
    //     document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    //   }
    // }
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userDetails");
    // localStorage.removeItem("practiceSequenceNo");
    localStorage.removeItem("practiceId");
    navigate("/");
  };

  const SectionList = ({ src, clink, mtitle, handleClick, isOpen }) => (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <img style={{ color: "white", width: "28px" }} src={src} />
          </ListItemIcon>
          <ListItemText
            primary={
              <span
                style={{
                  fontSize: "14px",
                  color: theme.palette.sidebarText.primary,
                }}
              >
                {mtitle}
              </span>
            }
          />
          <ListItemIcon>
            {isOpen ? (
              <ArrowDropUpTwoToneIcon
                style={{ fontSize: "26px", color: "white" }}
              />
            ) : (
              <ArrowDropDownIcon style={{ fontSize: "26px", color: "white" }} />
            )}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <hr style={{ color: "white" }} />
    </>
  );

  return (
    <>
      <Box
        sx={{
          marginRight: {
            xs: "23px",
            md: "15px",
          },
          // marginTop: "60px", // Adjust this value to move the sidebar down
          // position: "sticky",
        }}
      >
        <CssBaseline />
        <AppBar position="fixed"></AppBar>
        <Drawer
          position="fixed"
          sx={{
            width: isSidebarOpen ? drawerWidth : 0,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isSidebarOpen ? drawerWidth : 70,
              overflowX: isSidebarOpen ? "hidden" : "hidden",
              boxSizing: "border-box",
              boxShadow: "7px 0px 19px 0px rgba(0, 0, 0, 0.15)",
              marginTop: "60px",
              overflowY: "auto",
              height: "calc(100% - 60px)",
              // top: "60px",
              // after theme
              // background: "#0B59D7",
              // color: "white",
              background: theme.palette.sidebarBackground.primary,
              transition: "width 0.4s ease-in-out",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#114172",
              boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            {isSidebarOpen ? (
              <>
                {/* <Typography
                  sx={{ color: "white" }}
                  variant="h2"
                  fontWeight={"600"}
                  padding={"5px"}
                >
                  Admin
                </Typography> */}
                <IconButton
                  onClick={handleToggleSidebar}
                  classes={{ root: "custom-icon-button" }}
                >
                  <MenuOutlinedIcon
                    sx={{
                      color: "#F2F6FC",
                      fontSize: "2.4rem",
                      marginLeft: "180px",
                    }}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={handleToggleSidebar}
                  classes={{ root: "custom-icon-button" }}
                >
                  <MenuOutlinedIcon
                    sx={{
                      color: "#F2F6FC",
                      fontSize: "2.4rem",
                      marginLeft: "13px",
                    }}
                  />
                </IconButton>
              </>
            )}
          </Box>
          <Toolbar />
          <Divider />
          <List sx={{ marginTop: "-60px" }}>
            {/* Dashboard */}
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/dashboard"
                style={{
                  backgroundColor:
                    activeDropdown === "Dashboard"
                      ? theme.palette.activeDropDown.primary
                      : "transparent",
                  border:
                    activeDropdown === "Dashboard" ? "1px solid white" : "none",
                }}
                onClick={() => {
                  setActiveDropdown("Dashboard");
                  setOpenSection(null);
                }}
              >
                <ListItemIcon>
                  <img
                    src={dashboardt}
                    style={{ color: "white", width: "24px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Link className="sidebarList" to="/dashboard">
                      Dashboard
                    </Link>
                  }
                />
              </ListItemButton>
            </ListItem>

            <hr style={{ color: "white" }} />
            {/* Patient */}
            <SectionList
              handleClick={() => handleToggleSection("patient")}
              src={patientt}
              mtitle="Patient"
              isOpen={openSection === "patient"}
            ></SectionList>

            {/* collapse section */}

            {isSidebarOpen ? (
              <Collapse
                in={openSection === "patient"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "200px",
                    height: "auto",
                    left: "17px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/createpatient"
                      style={{
                        backgroundColor:
                          activeDropdown === "Create Patient"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Create Patient"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Create Patient")}
                    >
                      <ListItemIcon
                        title="Create Patient"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={createpatient}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Create Patient
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem> */}

                  {/* <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients"
                      style={{
                        backgroundColor:
                          activeDropdown === "Manage Patient"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Manage Patient"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Manage Patient")}
                    >
                      <ListItemIcon title="Manage Patient">
                        <img
                          src={managepatient}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "14px" }}>
                            Manage Patient
                          </span>
                        }
                      />
                    </ListItemButton>
                  </ListItem> */}

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients"
                      style={{
                        backgroundColor:
                          activeDropdown === "Patient"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Patient"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Patient")}
                    >
                      <ListItemIcon
                        title="Patients"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={multiplemanagepatients}
                          alt="Patients"
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Patients</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  {/* <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients/manage-account"
                      style={{
                        backgroundColor:
                          activeDropdown === "Manage Account"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Manage Account"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Manage Account")}
                    >
                      <ListItemIcon
                        title="Manage Account"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={manageaccount}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Manage Account
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients/payment-plans"
                      style={{
                        backgroundColor:
                          activeDropdown === "Payment Plans"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Payment Plans"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Payment Plans")}
                    >
                      <ListItemIcon
                        title="Payment Plans"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={paymentplans}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Payment Plans
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/ar-control"
                      style={{
                        backgroundColor:
                          activeDropdown === "AR Control"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "AR Control"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("AR Control")}
                    >
                      <ListItemIcon
                        title="AR Control"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={arcontrol}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>AR Control</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients/statments/print"
                      style={{
                        backgroundColor:
                          activeDropdown === "Statement Batch Print"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Statement Batch Print"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Statement Batch Print")}
                    >
                      <ListItemIcon
                        title="Statement Batch Print"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={statementbatchprint}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Statement Batch Print
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/label-batch-print"
                      style={{
                        backgroundColor:
                          activeDropdown === "Label Btach Print"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Label Btach Print"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Label Btach Print")}
                    >
                      <ListItemIcon
                        title="Label Btach Print"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={labelbatchprint}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Label Batch Print
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            ) : (
              <Collapse
                in={openSection === "patient"}
                timeout="auto"
                width="50px"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "50px",
                    height: "auto",
                    left: "5px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  {/* <ListItemButton component={Link} to="/createpatient">
                    <ListItemIcon title="Create Patient">
                      <img
                        src={createpatient}
                        style={{ color: "white", width: "17px" }}
                      />
                    </ListItemIcon>
                  </ListItemButton> */}

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/patients">
                      <ListItemIcon title="Manage Patients">
                        <img
                          src={multiplemanagepatients}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients/manage-account"
                    >
                      <ListItemIcon title="Manage Account">
                        <img
                          src={manageaccount}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payment">
                      <ListItemIcon title="Payment Plans">
                        <img
                          src={paymentplans}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/ar-control">
                      <ListItemIcon title="AR Control">
                        <img
                          src={arcontrol}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/patients/statments/print"
                    >
                      <ListItemIcon title="Statement Batch Print">
                        <img
                          src={statementbatchprint}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/label-batch-print">
                      <ListItemIcon title="Label Batch Print">
                        <img
                          src={labelbatchprint}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            )}
            {/* claim */}
            <SectionList
              handleClick={() => handleToggleSection("claim")}
              src={claimt}
              mtitle="Claim"
              isOpen={openSection === "claim"}
            ></SectionList>
            {/* collapse section */}
            {isSidebarOpen ? (
              <Collapse
                in={openSection === "claim"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "200px",
                    height: "auto",
                    left: "17px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/claims"
                      style={{
                        backgroundColor:
                          activeDropdown === "Claim"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Claim"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Claim")}
                    >
                      <ListItemIcon title="Claim" style={{ minWidth: "auto" }}>
                        <img
                          src={claim}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}> Claim</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/claim-submission"
                      style={{
                        backgroundColor:
                          activeDropdown === "ClaimSubmission"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "ClaimSubmission"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("ClaimSubmission")}
                    >
                      <ListItemIcon title="Claim Submission" style={{ minWidth: "auto" }}>
                        {/* <img
                          src={claim}
                          style={{ color: "white", width: "17px" }}
                        /> */}
                        <Telegram sx={{fontSize:"22px", color:"#fff"}}/>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}> Claim Submission</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
{/* 
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/claim-tracker"
                      style={{
                        backgroundColor:
                          activeDropdown === "Claim-Tracker"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Claim-Tracker"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Claim-Tracker")}
                    >
                      <ListItemIcon
                        title="Claim-Tracker"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={claimtracker}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            Claim Tracker
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/status-control"
                      style={{
                        backgroundColor:
                          activeDropdown === "Status-Control"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Status-Control"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Status-Control")}
                    >
                      <ListItemIcon
                        title="Status-Control"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={statuscontrol}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            Status Control
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/follow-up-management"
                      style={{
                        backgroundColor:
                          activeDropdown === "Follow-Up-Management"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Follow-Up-Management"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Follow-Up-Management")}
                    >
                      <ListItemIcon
                        title="Follow-Up-Management"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={followupmanagement}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            Follow Up Management
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/claim-batch-print"
                      style={{
                        backgroundColor:
                          activeDropdown === "Claim-Batch-Print"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Claim-Batch-Print"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Claim-Batch-Print")}
                    >
                      <ListItemIcon
                        title="Claim-Batch-Print"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={claimtracker}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            {" "}
                            Claim Batch Print
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            ) : (
              <Collapse
                in={openSection === "claim"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "50px",
                    height: "auto",
                    left: "5px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/claims">
                      <ListItemIcon title="Claim">
                        <img
                          src={claim}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  {/* <ListItem disablePadding>
                    <ListItemButton component={Link} to="/claim-tracker">
                      <ListItemIcon title="Claim Tracker">
                        <img
                          src={claimtracker}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/status-control">
                      <ListItemIcon title="Status Control">
                        <img
                          src={statuscontrol}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/follow-up-management">
                      <ListItemIcon title="Follow Up Management">
                        <img
                          src={followupmanagement}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem> */}


                  {/* <ListItem disablePadding>
                    <ListItemButton component={Link} to="/claim-tracker">
                      <ListItemIcon title="">
                        <img
                          src={followupmanagement}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            )}

            {/* Payment */}
            <SectionList
              handleClick={() => handleToggleSection("payment")}
              src={paymentt}
              mtitle="Payment"
              isOpen={openSection === "payment"}
            ></SectionList>
            {/* collapse section */}

            {isSidebarOpen ? (
              <Collapse
                in={openSection === "payment"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "200px",
                    height: "auto",
                    left: "17px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/payment"
                      style={{
                        backgroundColor:
                          activeDropdown === "Payment"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Payment"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Payment")}
                    >
                      <ListItemIcon
                        title="Post Payment"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={payment}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Post Payment</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  {/* <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/payment/view"
                      style={{
                        backgroundColor:
                          activeDropdown === "Payment View"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Payment View"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Payment View")}
                    >
                      <ListItemIcon
                        title="View Payment"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={viewpayment}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>View Payment</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/payment/era/search"
                      style={{
                        backgroundColor:
                          activeDropdown === "ERA"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "ERA" ? "1px solid white" : "none",
                      }}
                      onClick={() => setActiveDropdown("ERA")}
                    >
                      <ListItemIcon title="Era" style={{ minWidth: "auto" }}>
                        <img
                          src={era}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={<span style={{ fontSize: "12px" }}>ERA</span>}
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/payment/track"
                      style={{
                        backgroundColor:
                          activeDropdown === "payment-tracker"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "payment-tracker"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("payment-tracker")}
                    >
                      <ListItemIcon
                        title="Payment Tracker"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={paymenttracker}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Payment Tracker
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            ) : (
              <Collapse
                in={openSection === "payment"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "50px",
                    height: "auto",
                    left: "5px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payment">
                      <ListItemIcon title="Post Payment">
                        <img
                          src={payment}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  {/* <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payment/view">
                      <ListItemIcon title="View Payment">
                        <img
                          src={viewpayment}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payment/era/search">
                      <ListItemIcon title="ERA">
                        <img
                          src={era}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payment/track">
                      <ListItemIcon title="Payment Tracker">
                        <img
                          src={paymenttracker}
                          style={{ color: "white", width: "17px" }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            )}

            {/* Custom Setup */}
            <SectionList
              handleClick={() => handleToggleSection("custom-setup")}
              src={setup}
              mtitle="Custom Setup"
              isOpen={openSection === "custom-setup"}
            ></SectionList>
            {/* collapse section */}
            {isSidebarOpen ? (
              <Collapse
                in={openSection === "custom-setup"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "200px",
                    height: "auto",
                    left: "17px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/practice"
                      style={{
                        backgroundColor:
                          activeDropdown === "Practice"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Practice"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Practice")}
                    >
                      <ListItemIcon
                        title="Practice"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={practice}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Practice</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/provider"
                      style={{
                        backgroundColor:
                          activeDropdown === "Provider"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Provider"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Provider")}
                    >
                      <ListItemIcon
                        title="Provider"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={provider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Provider</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/referringProvider"
                      style={{
                        backgroundColor:
                          activeDropdown === "Referring Provider"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Referring Provider"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Referring Provider")}
                    >
                      <ListItemIcon
                        title="Referring Provider"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={referringprovider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Referring Provider
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/facility"
                      style={{
                        backgroundColor:
                          activeDropdown === "Facility"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Facility"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Facility")}
                    >
                      <ListItemIcon
                        title="Facility"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={facility}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Facility</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/payer"
                      style={{
                        backgroundColor:
                          activeDropdown === "Payer"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Payer"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Payer")}
                    >
                      <ListItemIcon title="Payer" style={{ minWidth: "auto" }}>
                        <img
                          src={provider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Payer</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  {/*procedure code  */}
                  <Typography variant="h5" sx={{ margin: "10px 15px 0" }}>
                    Codes
                  </Typography>
                  <hr style={{ color: "white" }} />
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/procedure-codes"
                      style={{
                        backgroundColor:
                          activeDropdown === "Procedure"
                            ? "#2A72AD"
                            : "transparent",
                        border:
                          activeDropdown === "Procedure"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Procedure")}
                    >
                      <ListItemIcon
                        title="Procedure"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={procedure}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Procedure</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    {/* <ListItemButton component={Link} to="/referringProvider">
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Referring Provider
                          </span>
                        }
                      />
                    </ListItemButton> */}
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/diagnosis"
                      style={{
                        backgroundColor:
                          activeDropdown === "Diagnosis"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Diagnosis"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Diagnosis")}
                    >
                      <ListItemIcon
                        title="Diagnosis"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={diagnosis}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Diagnosis</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* remittance */}
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/remittance"
                      style={{
                        backgroundColor:
                          activeDropdown === "Remittance"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Remittance"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Remittance")}
                    >
                      <ListItemIcon
                        title="Remittence"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={remittence}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Remittance</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* adjustment */}
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/adjustment"
                      style={{
                        backgroundColor:
                          activeDropdown === "Adjustment"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Adjustment"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Adjustment")}
                    >
                      <ListItemIcon
                        title="Adjustment"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={adjustment}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Adjustment</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/revenues"
                      style={{
                        backgroundColor:
                          activeDropdown === "Revenue"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Revenue"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Revenue")}
                    >
                      <ListItemIcon
                        title="Revenue"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={revenue}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Revenue</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/inventorycode"
                      style={{
                        backgroundColor:
                          activeDropdown === "InventoryCode"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "InventoryCode"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("InventoryCode")}
                    >
                      <ListItemIcon
                        title="Inventory Code"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={inventory}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>
                            Inventory Code
                          </span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/charge-panel"
                      style={{
                        backgroundColor:
                          activeDropdown === "Charge Panel"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Charge Panel"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Charge Panel")}
                    >
                      <ListItemIcon
                        title="Inventory Code"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={chargepanel}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Charge Panel</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton> 
                  </ListItem>*/}
                   <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/fee-schedule"
                      style={{
                        backgroundColor:
                          activeDropdown === "Fee Schedule"
                            ? theme.palette.activeDropDown.primary
                            : "transparent",
                        border:
                          activeDropdown === "Fee Schedule"
                            ? "1px solid white"
                            : "none",
                      }}
                      onClick={() => setActiveDropdown("Fee Schedule")}
                    >
                      <ListItemIcon
                        title="Inventory Code"
                        style={{ minWidth: "auto" }}
                      >
                        <img
                          src={chargepanel}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "12px" }}>Fee Schedule</span>
                        }
                        style={{ marginLeft: "20px" }}
                      />
                    </ListItemButton>
                  </ListItem> 

                  <hr style={{ color: "white" }} />
                </List>
              </Collapse>
            ) : (
              <Collapse
                in={openSection === "custom-setup"}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  style={{
                    background: theme.palette.sidebarDropDowns.primary,
                    color: theme.palette.sidebarDDownText.primary,
                    width: "50px",
                    height: "auto",
                    left: "5px",
                    borderRadius: "0  0 10px 10px",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/practice">
                      <ListItemIcon title="Practice">
                        <img
                          src={practice}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/provider">
                      <ListItemIcon title="Provider">
                        <img
                          src={provider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/referringProvider">
                      <ListItemIcon title="Referring Provider">
                        <img
                          src={referringprovider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/facility">
                      <ListItemIcon title="Facility">
                        <img
                          src={facility}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/payer">
                      <ListItemIcon title="Payer">
                        <img
                          src={provider}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <Typography variant="h5" sx={{ margin: "10px 3px 0" }}>
                    Codes
                  </Typography>
                  <hr style={{ color: "white" }} />

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/procedure-codes">
                      <ListItemIcon title="Procedure">
                        <img
                          src={procedure}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/diagnosis">
                      <ListItemIcon title="Diagnosis">
                        <img
                          src={diagnosis}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/remittance">
                      <ListItemIcon title="Remittence">
                        <img
                          src={remittence}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/adjustment">
                      <ListItemIcon title="Adjustment">
                        <img
                          src={adjustment}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/revenues">
                      <ListItemIcon title="Revenue">
                        <img
                          src={revenue}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/inventorycode">
                      <ListItemIcon title="Inventory Code">
                        <img
                          src={chargepanel}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton component={Link} to="/charge-panel">
                      <ListItemIcon title="Charge Panel">
                        <img
                          src={chargepanel}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem> */}

                  <ListItem disablePadding>
                    <ListItemButton component={Link} to="/fee-schedule">
                      <ListItemIcon title="Charge Panel">
                        <img
                          src={chargepanel}
                          style={{ color: "white", width: "17px" }}
                          alt=""
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            )}

            {/* administration */}
            {[1, 3].includes(hide?.userTypeId) && (
              <SectionList
                handleClick={() => handleToggleSection("administration")}
                src={accountadministration}
                mtitle="Administration"
                style={{ background: "red" }}
                isOpen={openSection === "administration"}
              ></SectionList>
            )}
            {/* collapse section */}
            {[1, 3].includes(hide?.userTypeId) && (
              <>
                {isSidebarOpen ? (
                  <Collapse
                    in={openSection === "administration"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      style={{
                        background: theme.palette.sidebarDropDowns.primary,
                        color: theme.palette.sidebarDDownText.primary,
                        width: "200px",
                        height: "auto",
                        left: "15px",
                        borderRadius: "0  0 10px 10px",
                      }}
                    >
                      {hide?.userTypeId === 1 && (
                        <ListItem disablePadding>
                          <ListItemButton
                            component={Link}
                            to="/company"
                            style={{
                              backgroundColor:
                                activeDropdown === "Company"
                                  ? theme.palette.activeDropDown.primary
                                  : "transparent",
                              border:
                                activeDropdown === "Company"
                                  ? "1px solid white"
                                  : "none",
                            }}
                            onClick={() => setActiveDropdown("Company")}
                          >
                            <ListItemIcon
                              title="Company"
                              style={{ minWidth: "auto" }}
                            >
                              <img
                                src={accountadministration}
                                style={{ color: "white", width: "17px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <span style={{ fontSize: "12px" }}>
                                  Company
                                </span>
                              }
                              style={{ marginLeft: "20px" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                      {/* user */}
                      <ListItem disablePadding>
                        <ListItemButton
                          component={Link}
                          to="/users"
                          style={{
                            backgroundColor:
                              activeDropdown === "Register User"
                                ? theme.palette.activeDropDown.primary
                                : "transparent",
                            border:
                              activeDropdown === "Register User"
                                ? "1px solid white"
                                : "none",
                          }}
                          onClick={() => setActiveDropdown("Register User")}
                        >
                          <ListItemIcon
                            title="Register User"
                            style={{ minWidth: "auto" }}
                          >
                            <img
                              src={registeruser}
                              style={{ color: "white", width: "17px" }}
                              alt=""
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <span style={{ fontSize: "12px" }}>
                                Register User
                              </span>
                            }
                            style={{ marginLeft: "20px" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse>
                ) : (
                  <Collapse
                    in={openSection === "administration"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      style={{
                        background: theme.palette.sidebarDropDowns.primary,
                        color: theme.palette.sidebarDDownText.primary,
                        width: "50px",
                        height: "auto",
                        left: "5px",
                        borderRadius: "0  0 10px 10px",
                      }}
                    >
                      {hide?.userTypeId === 1 && (
                        <ListItem disablePadding>
                          <ListItemButton component={Link} to="/company">
                            <ListItemIcon title="Company">
                              <img
                                src={accountadministration}
                                style={{ color: "white", width: "17px" }}
                              />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      )}
                      <ListItem disablePadding>
                        <ListItemButton component={Link} to="/users">
                          <ListItemIcon title="Register User">
                            <img
                              src={registeruser}
                              style={{ color: "white", width: "17px" }}
                              alt=""
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse>
                )}
              </>
            )}
            {/* logout */}
            {isSidebarOpen ? (
              <ListItem onClick={handleLogout} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <img
                      src={logout}
                      style={{ color: "white", width: "17px" }}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<p className="sidebarList">Log Out</p>}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem onClick={handleLogout} disablePadding>
                <ListItemButton title="Logout">
                  <ListItemIcon>
                    <img
                      src={logout}
                      style={{ color: "white", width: "17px" }}
                      alt=""
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider />
        </Drawer>
        <Toolbar />
      </Box>
    </>
  );
};
export default Sidebar2;
