import React from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import {
  register,
  userManagementIntVal,
} from "../../../utils/formikInitValues";
import { newUserAction } from "../../../features/actions/userAction";
import { useDispatch } from "react-redux";
import { registerSchema } from "../../../schemas/userSchema";
import CustomDialog from "../../../components/CustomDialog";
const CreateUser = () => {
  const navigate = useNavigate();
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/users");
  //   }
  // };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: userManagementIntVal,
    validationSchema:registerSchema,
    onSubmit: (values) => {
      try {
        dispatch(newUserAction(values));
        navigate("/users");
      }catch (err) {
        console.log("Error:", err);
        return;
      }
    },
  });

  return (
    <>
      <Box margin="20px">
        <form onSubmit={formik.handleSubmit}>
          <UserForm formik={formik} />
        </form>
      </Box>
    </>
  );
};

export default CreateUser;
