// handle cancelf
export const handleCancel = (formik, path, navigate) => {
  const conform = window.confirm("Are you sure you want to cancel?");
  if (conform) {
    formik.resetForm();
    navigate(path);
  }
};

export const handleIsActive = (formik) => {
  console.log("active", !formik.values.isActive);

  formik.setFieldValue("isActive", !formik.values.isActive);
};

export const getTokenFromCookie = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("token=")) {
      return cookie.substring("token=".length);
    }
  }
};
