import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider,Stack} from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import image1 from "../../../assets/doc.png";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import {
  getUserByIdAction,
  updateUserAction,
} from "../../../features/actions/userAction";
import ChangePassword from "./ChangePassword";
import { updateUserSechema, userSechema } from "../../../schemas/userSchema";

import CustomDialog from "../../../components/CustomDialog";
import { userManagementIntVal } from "../../../utils/formikInitValues";
import Loading from "../../../components/Loading";
import CustomField from "../../../components/CustomField";
import CustomModal from "../../../components/CustomModal";
import { Visibility } from "@mui/icons-material";



const UpdateUser = () => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openChangePassMod,setOpenChangePassMod] =useState(false);
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { singleUserData, loading } = useSelector((state) => state.users);
  console.log(singleUserData, "singleUser");
  const formik = useFormik({
    initialValues: singleUserData,
    validationSchema: userSechema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      console.log(values, "values user update");
      dispatch(updateUserAction(values));
      navigate("/users");
    },
  });

  useEffect(() => {
    try {
      dispatch(getUserByIdAction(userId));
    } catch (e) {
      console.log(e, ": Error in fething single user api");
    }
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };
  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/users");
  };



  return (
    <>
      <Box margin="30px">
      <form onSubmit={formik.handleSubmit}>
    <div style={{textAlign:'right',marginBottom:'10px'}}>
    <CustomButton type="submit" isBlue={true} width={'10%'}>Update</CustomButton>
    <CustomButton isCancel={true} margin={'10px'} handleClick={handleCancel}>
          Cancel
        </CustomButton>
    </div>
        <Box
          sx={{
            border: "1px solid #C7C7C7",
            borderRadius: "10px",
            background: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              padding: "30px",
            }}
          >
            <Grid
              container
              sx={{
                width: { sm: "100%", xs: "100%", md: "200px" },
                marginBottom: "20px",
                display:'flex',
                justifyContent:'center'
                
              }}
            >
              <Grid
                item
                md={12}
                sx={{ borderRadius: "50%", margin: "10px", padding: "10px"}}
              >
                <img src={image1} alt="image"/>
              </Grid>
            </Grid>


            <Grid container spacing={2}>
              <Grid item md={4} sm={6}>
                <CustomField
                  type="text"
                  label="First Name"
                  value={formik.values.firstName}
                  name='firstName'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                     error={formik.errors}
                touched={formik.touched}
               isRequired={true}
                />
              </Grid>
              <Grid item md={4} sm={6}>
                <CustomField
                  type="text"
                  label="Last Name"
                  value={formik.values.lastName}
                  name='lastName'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={formik.errors}
                  touched={formik.touched}
                  isRequired={true}
                />
              </Grid>
              <Grid item md={4} sm={6}>
                <CustomField
                  type="text"
                  label="Username"
                  value={formik.values.username}
                  name='username'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={formik.errors}
                  touched={formik.touched}
                  isRequired={true}
                />
              </Grid>
            
              <Grid item md={3} sm={6}>
                <CustomField
                  type="text"
                  label="Title"
                  value={formik.values.title}
                  name='title'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <CustomField
                  type="email"
                  label="Email"
                  value={formik.values.email}
                  name='email'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  disable={true}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <CustomField
                  type="text"
                  label="Phone1"
                  value={formik.values.phone1}
                  name='phone1'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={formik.errors}
                  touched={formik.touched}
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <CustomField
                  type="text"
                  label="Phone2"
                  value={formik.values.phone2}
                  name='phone2'
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <hr style={{ border: "1px solid grey" }} />
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row" }, padding: "30px" }}>
            <Grid
              container
              sx={{ width: { sm: "100%", xs: "100%", md: "200px" } }}
            >
              <Grid
                item
                md={12}
                sx={{ borderRadius: "50%", margin: "10px", padding: "10px" }}
              >
                {/* <img src={image1} alt='image'/> */}
              </Grid>
            </Grid>
            <Grid container sx={{marginLeft:{md:'30px',xs:'0'}}}>
              <Grid item md={4} xs={12}>
                <CustomButton isBlue={true} 
                handleClick={() => setOpenChangePassMod(true)}
                >Change Password</CustomButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </form>
        
      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
          <CustomModal
        open={openChangePassMod}
        handleClose={() => setOpenChangePassMod(false)}
        heading="Change Password"       
      >
      <ChangePassword />
      </CustomModal>
      </Box>
    </>
  );
};

export default UpdateUser;
