import { Edit } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import { setPaymentDataForApi } from "../../features/slice/PaymentSlice";
import React, { useEffect, useState } from "react";
import CustomModal from "../../components/CustomModal";
import EditPayDetail from "./EditPayDetail";
// import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import CustomSelectBox2 from "../../components/CustomSelectBox2";

const PostPayDetail = ({ setShowDetail, formik }) => {
  const theme = useTheme(); // Access the current theme
  const [openEditModal, setOpenEditModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [currentClaimChargesStatus, setCurrentClaimChargesStatus] =
    useState(null);
  const [claimStatus, setClaimStatus] = useState([]);
  const sum = (claimId, chargesId, key) => {
    return (
      formik.values?.paymentClaimDto
        .find((v) => v?.claimId === claimId)
        ?.paymentDetailDto.find((val) => val?.id === chargesId)?.[key] || 0
    );
  };

  const sumFromClaimChargesDto = (claimId, chargesId, key) => {
    return (
      formik.values?.paymentClaimDto
        .find((v) => v?.claimId === claimId)
        ?.claimChargesDto.find((val) => val?.claimChargesId === chargesId)?.[key] || 0
    );
  };

  const formattedData = formik.values?.currentClaim?.map((item) => {
    const chargesId = item?.claimChargesId || item?.id;
    return {
      id: chargesId,
      claimId: item?.claimId,
      procedureCode: item?.procedureCode,
      procedureCodeId: item?.procedureCodeId,
      claimNumber: item?.claimNumber,
      claimChargesId: item?.claimChargesId,
      amount: item?.amount,
      fromDate: item?.dos,
      startBalance: item?.startBalance,
      allowed: sum(item?.claimId, chargesId, "allowed"),
      paid: sum(item?.claimId, chargesId, "paid"),
      adjusted: sum(item?.claimId, chargesId, "adjusted"),
      unpaid: sum(item?.claimId, chargesId, "unpaid"),
      deductible: sum(item?.claimId, chargesId, "deductible"),
      endBalance: sumFromClaimChargesDto(item?.claimId, chargesId, "endBalance"),
      otherCredits: sum(item?.claimId, chargesId, "otherCredits"),
      claimStatusId: item.claimStatusId,
      claimStatus: "",
    };
  });
  // rows
  const [rowData, setRowData] = useState(formattedData);
  const patientClaimCharges = [
    {
      field: "action",
      headerName: "Edit",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => {
            if (params.row.startBalance > 0) {
              handleEditClick(params.row);
            } else {
              alert("This Claim Charges Balance is 0");
            }
          }}
        >
          <Edit />
        </Button>
      ),
    },
    {
      field: "fromDate",
      headerName: "DOS",
      flex: 3,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "procedureCode",
      headerName: "Procedure Code",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "startBalance",
      headerName: "Start Balance",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "allowed",
      headerName: "Allowed",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "paid",
      headerName: "Paid",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "adjusted",
      headerName: "Adjusted",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "unpaid",
      headerName: "Unpaid",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "deductible",
      headerName: "Deductible",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "otherCredits",
      headerName: "Other Credits",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "claimStatusId",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <>
          <Typography>
            {
              claimStatus?.find(
                (v) => v?.claimStatusId === Number(params?.row?.claimStatusId)
              )?.claimStatusType
            }
          </Typography>
        </>
      ),
    },
    {
      field: "endBalance",
      headerName: "End Balance",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  const patientColumns = [
    {
      field: "action",
      headerName: "Edit",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => {
            setCurrentClaimChargesStatus(params.row.claimStatusId);
            handleEditClick(params.row);
          }}
        >
          <Edit />
        </Button>
      ),
    },
    {
      field: "fromDate",
      headerName: "DOS",
      flex: 3,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "procedureCode",
      headerName: "Procedure Code",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "startBalance",
      headerName: "Start Balance",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "allowed",
      headerName: "Apply Payment",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "adjusted",
      headerName: "Adjusted",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "otherCredits",
      headerName: "Apply Credits",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "claimStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <>
          <Typography>
            {
              claimStatus?.find(
                (v) => v?.claimStatusId === Number(params?.row?.claimStatusId)
              )?.claimStatusType
            }
          </Typography>
        </>
      ),
    },
    {
      field: "endBalance",
      headerName: "End Balance",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  const handleEditClick = (row) => {
    console.log(row, "check selectedrow edit");
    setEditedData(row);
    setOpenEditModal(true);
  };

  const handleSaveEdit = (updatedData) => {
    setCurrentClaimChargesStatus(null);
    console.log(updatedData, "all updatedData");
    let updatedDetailInfo = rowData.map((item) =>
      item.id === updatedData.id ? updatedData : item
    );
    console.log(updatedDetailInfo, "updatedInfo17");
    let paymentDetailDto = formik.values.paymentClaimDto;
    let findClaimChargesListIndex = paymentDetailDto.findIndex(
      (v) => v.claimId === updatedDetailInfo[0]?.claimId
    );
    if (findClaimChargesListIndex !== -1) {
      paymentDetailDto[findClaimChargesListIndex].paymentDetailDto =
        updatedDetailInfo;
      formik.setFieldValue("paymentClaimDto", paymentDetailDto);
      setRowData([...updatedDetailInfo]);
      setOpenEditModal(false);
    }
  };

  let actions = [
    {
      id: 1,
      value: "Processed",
    },
    {
      id: 2,
      value: "Processed & Forwarded",
    },
    {
      id: 1,
      value: "Denied",
    },
    {
      id: 1,
      value: "Refund / Reversal",
    },
  ];

  let tcn = [
    {
      id: 1,
      value: "No TCN (remittance information cannot be entered)",
    },
  ];
  // handle done
  const handleDone = () => {
    console.log("handelDone", formik.values);
    setShowDetail(false);
    formik.setFieldValue("currentClaim", null);
  };
  const fetchClaimStatus = async () => {
    try {
      let response = await getData(`${path}/ct-claimStatus`);
      console.log("response2323", response);
      setClaimStatus(response?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = (data) => {
    let typeOfStatus = {};
    let paidId = "6";
    let sum = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i]?.claimStatusId) {
        let status = data[i].claimStatusId.toString();
        typeOfStatus[status]
          ? typeOfStatus[status]++
          : (typeOfStatus[status] = 1);
      }
    }

    if (typeOfStatus[paidId] === data.length) {
      return paidId;
    }
    delete typeOfStatus[paidId];
    for (let key in typeOfStatus) {
      sum += typeOfStatus[key];
    }
    console.log(typeOfStatus);
    console.log(sum % Object.keys(typeOfStatus).length);
    if (sum % Object.keys(typeOfStatus).length === 0) {
      console.log("e");
      return Object.keys(typeOfStatus)[0];
    } else {
      let id;
      let max = 0;
      for (let i in typeOfStatus) {
        if (typeOfStatus[i] > max && i !== paidId) {
          max = typeOfStatus[i];
          id = i;
        }
      }
      return id;
    }
  };

  useEffect(() => {
    fetchClaimStatus();
    //check first if the paymentClaimDto key paymentDetail is not null then we will assign updated value
    let claimId = formattedData[0].claimId;

    //find Index in paymentDataForApi of specific claim payment
    let paymentDetailDtoIndex = formik.values?.paymentClaimDto.findIndex(
      (val) => val.claimId === claimId
    );
    let res = getStatus(
      formik.values?.paymentClaimDto[paymentDetailDtoIndex]?.claimChargesDto
    );
    setStatus(res);
    if (paymentDetailDtoIndex !== -1) {
      let paymentDetailDtoLength =
        formik.values?.paymentClaimDto[paymentDetailDtoIndex].paymentDetailDto
          .length;
      /// if length is zero so we asgin formatted data as a row data
      if (paymentDetailDtoLength === 0) {
        setRowData(formattedData);
      }
      /// if both length equal so we set paymentdataForApi
      else if (paymentDetailDtoLength === formattedData.length) {
        setRowData(
          formik.values.paymentClaimDto[paymentDetailDtoIndex].paymentDetailDto
        );
      } else if (formattedData.length > paymentDetailDtoLength) {
        let formattedData = [...rowData];
        let valueNotEdit = [];
        let formattedDataPaymentDetail =
          formik.values.paymentClaimDto[paymentDetailDtoIndex].paymentDetailDto;
        for (let i = 0; i < formattedData.length; i++) {
          const element = formattedData[i];
          let found = formattedDataPaymentDetail.find(
            (val) => val.id === element.id
          );
          if (!found) {
            valueNotEdit.push(element);
          }
        }
        let finalUpdatedValue = formattedDataPaymentDetail.concat(valueNotEdit);
        console.log("object mmm", finalUpdatedValue);
        setRowData(finalUpdatedValue);
      }
    }
  }, []);
  return (
    <>
      <CustomModal
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
      >
        <EditPayDetail
          currentClaimChargesStatus={currentClaimChargesStatus}
          formik={formik}
          data={editedData}
          claimStatus={claimStatus}
          onSave={handleSaveEdit}
          handleClose={() => setOpenEditModal(false)}
        />
      </CustomModal>
      <div style={{ width: "100%" }}>
        <Box>
          <CustomButton margin={"10px"} isBlue={true} handleClick={handleDone}>
            Done
          </CustomButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            margin: "10px 0px",
          }}
        >
          {/* <CustomSelectBox2
            value={""}
            name=""
            dropdownOptions={actions?.map((opt) => ({
              value: opt.value,
              id: opt.id,
            }))}
            label="Action"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          />
          <CustomSelectBox2
            value={""}
            name=""
            dropdownOptions={tcn?.map((opt) => ({
              value: opt.value,
              id: opt.id,
            }))}
            label="TCN"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          /> */}
          <CustomSelectBox2
            value={status}
            name="status"
            dropdownOptions={claimStatus?.map((opt) => ({
              value: opt.claimStatusType,
              id: opt.claimStatusId,
            }))}
            label="Status"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          />
        </Box>
        <DataGrid
          rows={rowData}
          columns={
            formik.values.paymentByTypeId !== 1
              ? patientClaimCharges
              : patientColumns
          }
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,

              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "12.5px",
            },
          }}
          autoHeight
          disableSelectionOnClick
        />
      </div>
    </>
  );
};

export default PostPayDetail;
