import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getProcedureCodeAction,
  newProcedureCodeAction,
  updateProcedureCodeAction,
  getProcedureCodesByIdAction
} from "../actions/procedureCodeAction";

const initialState = {
  createProcedureCode: {},
  updateProcedureCode: {},
  getProcedureCodes: [],
  procedureByIdData : {},
  loading: false,
  error: null,
};

const procedureCodeSlice = createSlice({
  name: "procedureCodes",
  initialState: initialState,
  extraReducers: {
    [newProcedureCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [newProcedureCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createProcedureCode = action.payload;
      toast.success("Procedure Code has been created successfully!");
    },
    [newProcedureCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Procedure Code creation failed!");
    },
    [getProcedureCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [getProcedureCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getProcedureCodes = action.payload;
      console.log(action.paylaod, "procedure codes slice get payload");
    },
    [getProcedureCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProcedureCodesByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getProcedureCodesByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.procedureByIdData = action.payload;
      console.log(action.paylaod, "procedure slice get payload");
    },
    [getProcedureCodesByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateProcedureCodeAction.pending]: (state) => {
      state.loading = true;
    },
    [updateProcedureCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createProcedureCode = action.payload;
      toast.success("Procedure Code has been updated successfully!");
    },
    [updateProcedureCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Procedure Code updation failed!");
    },
  },
});

export default procedureCodeSlice.reducer;
