import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import PostPayGrid from "./PostPayGrid";
import PostPayDetail from "./PostPayDetail";
import path from "../../config/apiUrl";
import axios from "axios";
import CustomModal from "../../components/CustomModal";
import MultipleClaims from "./MultipleClaims";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import logo from "../../../src/assets/Applied.png";
// import MainHeading from "../../components/MainHeading";
import CustomDialog from "../../components/CustomDialog";
import "./Payment.css";

const PostPayment = ({ setApplyEob, setShowPostPay, formik }) => {
  const theme = useTheme(); // Access the current theme
  // const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  // const [detailInfo, setDetailInfo] = useState([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [multipleClaimData, setMultipleClaimData] = useState([]);
  const [openMultiClaimMod, setOpenMultiClaimMod] = useState(false);
  const [appliedValue, setAppliedValue] = useState(0);

  // const handleCancel = () => {
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     setShowPostPay(false);
  //     setApplyEob(false);
  //   }
  // };

  const handleGetMultiClaims = async () => {
    const seqNo = formik.values.payerSequenceNo;
    try {
      let url;
      if (formik.values.isClaim) {
        url = `${path}/paymentClaims`;
        const response = await axios.get(url);
        console.log(response, "getMultiClaimResponse");
        const multiClaimsData = response?.data?.result;
        setMultipleClaimData(multiClaimsData);
        console.log(multiClaimsData, "get multi claims data");
        setOpenMultiClaimMod(true);
      } else {
        url = `${path}/payerClaimDetail/SequenceNo?sequenceNo=${seqNo}`;
        const response = await axios.get(url);
        console.log(response, "getMultiClaimResponse");
        const multiClaimsData = response?.data.result;
        setMultipleClaimData(multiClaimsData);
        console.log(multiClaimsData, "get multi claims data");
        setOpenMultiClaimMod(true);
      }
    } catch (error) {
      setMultipleClaimData([]);
      throw error;
    }
  };

  useEffect(() => {
    const sumAppliedValue = () => {
      let total = 0;
      if (formik.values?.paymentClaimDto) {
        for (let i = 0; i < formik.values?.paymentClaimDto?.length; i++) {
          const element = formik.values?.paymentClaimDto[i];
          let totalofSingleClaim = element?.paymentDetailDto?.reduce(
            (sum, current) => sum + current?.allowed,
            0
          );
          console.log("totalofSingleClaim", totalofSingleClaim, element);
          total += totalofSingleClaim;
        }
      }
      setAppliedValue(total);
    };
    sumAppliedValue();
  }, [formik.values]);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
        setShowPostPay(false);
        setApplyEob(false);
  };
  return (
    <>
      <div style={{ padding: "10px", width: "98%", marginLeft: "-10px" }}>
        {/* <MainHeading mainheading={"New Payment"} /> */}
      </div>

      <Box margin="20px">
        {/* custom modal for multiple claims */}
        <CustomModal
          open={openMultiClaimMod}
          handleClose={() => {
            setOpenMultiClaimMod(false);
          }}
        >
          <MultipleClaims
            formik={formik}
            multipleClaimData={multipleClaimData}
            handleClose={() => setOpenMultiClaimMod(false)}
          />
        </CustomModal>
        {showDetail ? (
          <Box sx={{ width: "100%" }}>
            <PostPayDetail formik={formik} setShowDetail={setShowDetail} />
          </Box>
        ) : (
          <div>
            <Stack flexDirection={"row"} alignItems="center">
              <CustomButton
                disabled={
                  !(
                    formik.values?.paymentClaimDto?.length > 0 &&
                    formik.values?.paymentClaimDto.find(
                      (v) => v?.paymentDetailDto?.length > 0
                    )
                  )
                }
                variant="contained"
                fontSize="1.1rem"
                width="200px"
                isBlue={true}
                isSave={true}
                handleClick={formik.handleSubmit}
              >
                Save Payment
              </CustomButton>
              {/* <CustomButton
                fontSize="1.1rem"
                margin="0 5px 0"
                isBlue={false}
                width="200px"
                variant="contained"
                color="error"
                isCancel={true}
                handleClick={handleCancel}
              >
                Cancel
              </CustomButton> */}
                <CustomButton  fontSize="1.1rem"
                margin="0 5px 0"
                width="200px"
                variant="contained"
                color="error"
                isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>

              {formik.values.paymentByTypeId === 2 && (
                <Stack flexDirection="row" alignItems="center">
                  <CustomButton
                    // margin="15px 0 "
                    variant="contained"
                    width="200px"
                    fontSize="1.1rem"
                    margin="0 3px 0"
                    isBlue={false}
                    isAdd={true}
                    handleClick={handleGetMultiClaims}
                  >
                    Add Claims
                  </CustomButton>
                </Stack>
              )}
            </Stack>

            <Box
              className="mainBox"
              style={{
                backgroundColor: theme.palette.dataGridHeading.primary,
              }}
            >
              <Box
                display="grid"
                gap="20px"
                sx={{
                  marginTop: "30px",
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 450px))",
                  },
                }}
              >
                {/* Typography item */}
                <Box sx={{ width: { md: "50%", sm: "40%" } }}>
                  {" "}
                  <Typography
                    className="mainBoxText"
                    variant="h4"
                    component="p"
                  >
                    Payment -{" "}
                    {formik.values.paymentMethodId === "3"
                      ? formik.values.creditCardTypeId
                      : formik.values.paymentMethodId === "1"
                      ? "Check"
                      : "EFT"}{" "}
                    From <br />
                    <span
                      style={{
                        // color: "#747474",
                        color: theme.palette.accordiansHeadingText.primary,
                      }}
                    >
                      {formik.values.paymentBy}
                    </span>
                  </Typography>
                  <Stack margin="20px 0 10px">
                    {formik.values.paymentMethodId === "1" && (
                      <Typography variant="h5" component="span">
                        <strong>Check:</strong> {formik.values.checkNumber}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                <Box
                  sx={{
                    width: { md: "50%", sm: "60%" },
                    display: "flex",
                    // background:'blue',
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                  }}
                >
                  {/* Amount */}
                  <Box
                    className="InnnerBox"
                    sx={{
                      width: { md: "170px" },
                      height: { md: "80px" },
                      padding: "5px",
                      background: theme.palette.customFieldsBackground.primary,
                    }}
                  >
                    <Box className="amountInnnerBox1">
                      <img src={logo} alt="" />
                    </Box>
                    <Box
                      sx={{
                        margin: "20px 13px 0",
                      }}
                    >
                      <Typography variant="h5" component="span">
                        <strong
                          style={{
                            color: theme.palette.textMainHeading.primary,
                          }}
                        >
                          Amount:
                        </strong>{" "}
                        <br />
                        <div
                          style={{
                            color: theme.palette.textMainHeading.primary,
                          }}
                          className="text-center"
                        >
                          {" "}
                          $ {formik.values.paymentAmount}
                        </div>
                        <br />
                      </Typography>
                    </Box>
                  </Box>

                  {/* Applied */}
                  <Box
                    className="InnnerBox"
                    sx={{
                      width: { md: "170px" },
                      height: { md: "80px" },
                      padding: "5px",
                      background: theme.palette.customFieldsBackground.primary,
                    }}
                  >
                    <Box className="appliedInnerBox1">
                      <img src={logo} alt="" />
                    </Box>
                    <Box
                      sx={{
                        margin: "20px 13px 0",
                      }}
                    >
                      <Typography variant="h5" component="span">
                        <strong
                          style={{
                            margin: "0A 10px 0",
                            color: theme.palette.textMainHeading.primary,
                          }}
                        >
                          Applied:
                        </strong>{" "}
                        <br />
                        <div
                          style={{
                            color: theme.palette.textMainHeading.primary,
                          }}
                          className="text-center"
                        >
                          {" "}
                          $ {appliedValue}
                        </div>
                        <br />
                      </Typography>
                    </Box>
                  </Box>
                  {/* Unapplied */}

                  <Box
                    className="InnnerBox"
                    sx={{
                      width: { md: "170px" },
                      height: { md: "80px" },
                      padding: "5px",
                      background: theme.palette.customFieldsBackground.primary,
                    }}
                  >
                    <Box className="unappliedInnerBox1">
                      <img src={logo} alt="" />
                    </Box>

                    <Box
                      sx={{
                        margin: "20px 5px 0",
                      }}
                    >
                      <Typography variant="h5" component="span">
                        <strong
                          style={{
                            color: theme.palette.textMainHeading.primary,
                          }}
                        >
                          Unapplied:
                        </strong>{" "}
                        <br />
                        <div
                          style={{
                            color: theme.palette.textMainHeading.primary,
                          }}
                          className="text-center"
                        >
                          $ {formik.values.paymentAmount - appliedValue}
                        </div>
                        <br />
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Actual */}
                {/* <Typography variant="h4" component="p">
              Payment -{" "}
              {formik.values.paymentMethodId === "3"
                ? formik.values.creditCardTypeId
                : formik.values.paymentMethodId === "1"
                ? "Check"
                : "EFT"}{" "}
              from <span>{formik.values.paymentBy}</span>
            </Typography>

            <Stack margin="20px 0 10px">
              {formik.values.paymentMethodId === "1" && (
                <Typography variant="h5" component="span">
                  <strong>Check:</strong> {formik.values.checkNumber}
                </Typography>
              )}
              <Typography variant="h5" component="span">
                <strong>Amount:</strong> $ {formik.values.paymentAmount}
              </Typography>
              <Typography variant="h5" component="span">
                <strong>Applied:</strong> $ {appliedValue}
              </Typography>
              <Typography variant="h5" component="span">
                <strong>Unapplied:</strong> ${" "}
                {formik.values.paymentAmount - appliedValue}
              </Typography>
            </Stack> */}

                {/* get payer  */}

                {/* {formik.values.paymentByTypeId === 2 && (
              <Stack flexDirection="row" alignItems="center">
                <Typography marginRight="15px">Add Payment For:</Typography>
                <CustomButton
                  margin="15px 0 "
                  variant="contained"
                  width="150px"
                  handleClick={handleGetMultiClaims}
                >
                  Add Claims
                </CustomButton>
              </Stack>
            )} */}
              </Box>
            </Box>

            <PostPayGrid setShowDetail={setShowDetail} formik={formik} />
            {/* <div>Work</div> */}
          </div>
        )}
      </Box>
      <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
     isCancel={true}
    />
   
    </>
  );
};

export default PostPayment;
