import React, { useState } from "react";
// import { useEffect } from "react";
import {
  useDispatch,
  //  useDispatch,
  useSelector,
} from "react-redux";
import {
  getPatientAction,
  getPatientBySearchTermAction,
} from "../../features/actions/createPatientAction";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomPreview from "../../layout/CustomPreview";
import { patientCols } from "../../utils/gridCols";
import { switchPracticeAction } from "../../features/actions/userAction";
import CustomDialog from "../../components/CustomDialog";
const ManagePatient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAllPatients, loading } = useSelector((state) => state.patient);
  const [openSwitchWarning, setOpenSwitchWarning] = useState(false);
  const [practiceForSwitch, setPracticeForSwitch] = useState({
    seqno: null,
    name: "",
  });

  // rows
  const rows =
    getAllPatients && getAllPatients?.result?.patients?.length > 0
      ? getAllPatients.result?.patients?.map((el) => ({
          id: el.accountNo,
          insuredName: el?.getInsuredParty?.insuredName,
          ...el,
        }))
      : [];

  const handleCell = (params) => {
    const practiceSeq = localStorage.getItem("practiceSequenceNo");
    if (Number(practiceSeq) === params?.practiceSequenceNo) {
      setOpenSwitchWarning(false);
      setPracticeForSwitch({
        seqno: null,
        name: "",
      });
      navigate(`/patient/update/${params.accountNo}`);
    } else {
      setOpenSwitchWarning(true);
      setPracticeForSwitch({
        ...practiceForSwitch,
        seqno: params.practiceSequenceNo,
        name: params?.practiceName,
      });
    }
  };

  const handleSwitchPractice = async (getSeq) => {
    try {
      localStorage.setItem("practiceSequenceNo", getSeq);
      await dispatch(switchPracticeAction(getSeq)).then(({ payload }) =>
        localStorage.setItem("token", payload.result.token)
      );
      window.location.reload();
    } catch (err) {
      console.log("Error switching practice", err);
    }
    return;
  };

  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={patientCols}
          paginationDetail={getAllPatients}
          searchLabel="Search by Account #, Name, Account Type, Phone No and Date Of Birth"
          moduleHeading="All Patients"
          loading={loading}
          handleCell={(params) => handleCell(params)}
          handleGetAll={getPatientAction}
          handleBySearch={getPatientBySearchTermAction}
          isExactMatch={true}
          isByPatient={true}
          showAll={true}
          navPath={"/patients/new"}
          showNew={true}
        />

        {/* custo modal */}
        <CustomDialog
          open={openSwitchWarning}
          confirmButtonTitle={"Ok"}
          isWarning={true}
          handleConfirm={() => setOpenSwitchWarning(false)}
        >
          <Typography component={"h2"}>
            You need to switch the practice{" "}
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => handleSwitchPractice(practiceForSwitch?.seqno)}
            >
              {practiceForSwitch?.name} ({practiceForSwitch?.seqno})
            </Link>{" "}
            to edit the patient
          </Typography>
        </CustomDialog>
      </Box>
    </>
  );
};

export default ManagePatient;
