import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { patientCols } from "../../utils/gridCols";
import { useNavigate } from "react-router-dom";

const DuplicatePat = ({ handleClose, duplicateData }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const rows =
    duplicateData?.result?.patients?.length > 0
      ? duplicateData?.result?.patients?.map((el) => ({
          id: el?.patientId,
          ...el,
        }))
      : [];

  return (
    <Box margin="15px">
      <DataGrid
        rows={rows}
        columns={patientCols}
        autoHeight
        rowHeight={35}
        disableSelectionOnClick
        onCellClick={(params) => {
            handleClose()
            navigate(`/patient/update/${params.row.accountNo}`)}}
        sx={{
          "& .header-bg": {
            background: theme.palette.dataGridHeading.primary,
            color: theme.palette.textMainHeading.primary,
            fontSize: "16px",
          },
          "& .cell-content": {
            fontSize: "1.2rem",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "1.3rem",
          },
          "& .MuiTablePagination-actions": {
            "& svg": {
              fontSize: "1.8rem",
              color: theme.palette.textMainHeading.primary,
              marginBottom: "8px",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: theme.palette.textMainHeading.primary,
            },
          },
        }}
      />
    </Box>
  );
};

export default DuplicatePat;
