// import { Box, Typography, Stack, Grid } from "@mui/material";
// import React, { useEffect } from "react";
// import { useFormik } from "formik";
// import { useDispatch, useSelector } from "react-redux";
// import CheckBox from "../../../components/CheckBox";
// import {
//   getProviderByIdAction,
//   updateProviderAction,
// } from "../../../features/actions/providerAction";
// import MainHeading from "../../../components/MainHeading";
// import ProviderInfo from "./ProviderInfo";
// import CustomButton from "../../../components/CustomButton";
// import { useNavigate, useParams } from "react-router-dom";
// import { providerSchema } from "../../../schemas/patientSchema";
// import Loading from "../../../components/Loading";
// import CustomDialog from "../../../components/CustomDialog";
// import { providerInitValues } from "../../../../src/utils/formikInitValues";
// import { useState } from "react";
// const UpdateProvider = () => {
//   const dispatch = useDispatch();
//   const { providerSequenceNo } = useParams();
//   console.log('Provider Sequence No:', providerSequenceNo);
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const { providerById, singleProviderLoading } = useSelector(
//     (state) => state.provider
//   );
//   console.log(providerById, "checkProviders");

//   // const handleCancel = () => {
//   //   setOpenCancelDialog(true);
//   // };

//   // const handleConfirmCancel = () => {
//   //   navigate("/provider");
//   // };
//   const navigate = useNavigate();

//   const providerToEdit = providerById?.result;
//   console.log(providerToEdit, "provider to edit");
//   const formik = useFormik({
//     initialValues: providerById?.result || providerInitValues,
//     // initialValues:providerInitValues,
//     validationSchema: providerSchema,
//     enableReinitialize: true,
//     onSubmit: (values, action) => {
//       try {
//         dispatch(
//           updateProviderAction({
//             providerSequenceNo: providerToEdit?.providerSequenceNo,
//             ...values,
//           })
//         );
//         action.resetForm();
//         navigate("/provider");
//       } catch (error) {
//         throw new Error(error);
//       }
//     },
//   });
//   console.log(formik.values, "formikProvider")
//   useEffect(() => {
//     dispatch(getProviderByIdAction(providerSequenceNo));
//   }, []);

//   return (
//     <>
//       <Grid container padding={"10px"}>
//         <Grid item md={12} container spacing={1}>
//         {/* <Grid item>
//         <CustomButton
//             formId="provider-form"
//             isEdit={true}
//             isBlue={true}
//             type="submit"
//           >
//             Update
//           </CustomButton>
//    </Grid>
//    <Grid item>
//     <CustomButton isCancel={true} handleClick={handleCancel}>
//          Cancel
//        </CustomButton>
//    </Grid> */}
//       <Grid item>
//           <CheckBox  
//           CheckedValue={!formik.values.isActive} 
//           handleChange={() => formik.setFieldValue("isActive", !formik.values.isActive)} label="Make this provider inactive"/>
//         </Grid>
//         <Grid item md={12}>
//           <form id="provider-form" onSubmit={formik.handleSubmit}>
//             {singleProviderLoading ? (
//               <Loading />
//             ) : (
//               <ProviderInfo formik={formik} />
//             )}
//           </form>
//         </Grid>
//       </Grid>

//       {/* <CustomDialog
//         open={openCancelDialog}
//         isWarning={true}
//         handleClose={() => setOpenCancelDialog(false)}
//         handleConfirm={handleConfirmCancel}
//         handleCancel={() => setOpenCancelDialog(false)}
//         confirmButtonTitle="OK"
//         cancelButtonTitle="Cancel"
//       >
//         <Typography component="h3">Are you sure you want to cancel?</Typography>
//       </CustomDialog> */}
//       </Grid>
//     </>
//   );
// };

// export default UpdateProvider;





import { Box, Typography, Stack, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../components/CheckBox";
import {
  getProviderByIdAction,
  updateProviderAction,
} from "../../../features/actions/providerAction";
import MainHeading from "../../../components/MainHeading";
import ProviderInfo from "./ProviderInfo";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { providerSchema } from "../../../schemas/patientSchema";
import Loading from "../../../components/Loading";
import CustomDialog from "../../../components/CustomDialog";
import { providerInitValues } from "../../../../src/utils/formikInitValues";
import { useState } from "react";
const UpdateProvider = () => {
  const dispatch = useDispatch();
  const { providerSequenceNo } = useParams();
  console.log('Provider Sequence No:', providerSequenceNo);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { providerById, singleProviderLoading } = useSelector(
    (state) => state.provider
  );
  console.log(providerById, "checkProviders");
  const navigate = useNavigate();

  if(providerSequenceNo){
  
  }

  const providerToEdit = providerById?.result;
  console.log(providerToEdit, "provider to edit");
  const formik = useFormik({
    initialValues: providerById?.result || providerInitValues,
    validationSchema: providerSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      try {
        dispatch(
          updateProviderAction({
            providerSequenceNo: providerToEdit?.providerSequenceNo,
            ...values,
          })
        );
        action.resetForm();
        navigate("/provider");
      } catch (error) {
        throw new Error(error);
      }
    },
  });
  console.log(formik.values, "formikProvider")
  useEffect(() => {
    dispatch(getProviderByIdAction(providerSequenceNo));
  }, []);

  return (
    <>
     
        <ProviderInfo formik={formik}/>
    
    </>
  );
};

export default UpdateProvider;
