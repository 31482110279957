import React, { useEffect } from "react";
import { payerSchema } from "../../../schemas/patientSchema";
import { payerIntialVal } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import PayerForm from "./PayerForm";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import { toast } from "react-toastify";
  
import {
  createNewPayerAction,
  updatePayerAction,
} from "../../../features/actions/payerAction";
import { getPayerByIdAction } from "../../../features/actions/payerAction";
const UpdatePayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {seqNumber} = useParams();

  const { payerByIdData, loading } = useSelector((state) => state.payer);
  console.log("payerbyiddata",payerByIdData)
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const findPayer = payerByIdData?.result;
  console.log("findPayer",findPayer)
  const formik = useFormik({
    initialValues: findPayer || payerIntialVal,
    validationSchema: payerSchema,
    enableReinitialize: true,

    onSubmit: (values,action) => {
      console.log("Submitting form with values:", values); 
      dispatch(
        updatePayerAction({
          sequenceNo: findPayer?.sequenceNo,
          ...values,
        })
      ).then((response) => {
        console.log("Update response:", response);
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/payer");
        } else {
         
          console.error("Update Procedure:", response.error.message);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getPayerByIdAction(seqNumber));
  }, [dispatch]);

  console.log("Insurance Group ID:", formik.values.insuranceGroupId);

  return (
    <>
          <PayerForm formik={formik}/>
    </>
  );
};

export default UpdatePayer;









