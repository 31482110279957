import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeeScheduleForm from "./FeeScheduleForm";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal";
import Configuration from "./configuration/Configuration";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  createFeeScheduleAction,
  getBlankScheduleAction,
} from "../../../features/actions/feeSchedulesAction";

const CreateFeeSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { fetchedSchedulesData } = useSelector((state) => state.feeSchedule);
  const feeScheduleFormik = useFormik({
    initialValues: {
      feeSchedulesName: "",
      effectiveFrom: null,
      effectiveTo: null,
      isActive: true,
      description: "",
      feeScheduleConfigurationDto: [],
      feeSchedulePriceDto: fetchedSchedulesData || [],
    },
    validationSchema: yup.object().shape({
      feeSchedulesName: yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(createFeeScheduleAction(values)).then((response) => {
          if (response?.type === "createFeeSchedule/fulfilled") {
            navigate("/fee-schedule");
          }
        });
      } catch (error) {
        console.log(error, "error in submitFeeSchedule");
        throw error;
      }
    },
  });

  const handleConfirmCancel = () => {
    feeScheduleFormik.resetForm();
    navigate("/fee-schedule");
  };

  useEffect(() => {
    try {
      if (feeScheduleFormik.values.feeSchedulePriceDto.length === 0) {
        dispatch(getBlankScheduleAction()).then(({ payload }) =>
          feeScheduleFormik?.setFieldValue(
            "feeSchedulePriceDto",
            payload?.result
          )
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  }, []);
  return (
    <>
      <Box
        margin={"20px"}
        component={"form"}
        onSubmit={feeScheduleFormik.handleSubmit}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"20px"}
          margin={"20px 0"}
        >
          <CustomButton type={"submit"} isAdd={true} isBlue={true}>
            Save
          </CustomButton>
          <CustomButton
            isCancel={true}
            handleClick={() => setOpenCancelDialog(true)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            isSetting={true}
            handleClick={() => setOpenConfigModal(true)}
          >
            Configuration
          </CustomButton>
        </Stack>
        <FeeScheduleForm feeScheduleFormik={feeScheduleFormik} />
      </Box>
      <CustomModal
        heading={"Configuration"}
        height={"auto"}
        open={openConfigModal}
        handleClose={() => setOpenConfigModal(false)}
      >
        <Configuration
          feeScheduleFormik={feeScheduleFormik}
          isOpen={openConfigModal}
        />
      </CustomModal>
      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
    </>
  );
};
export default CreateFeeSchedule;
