import { FormControl, Grid,useTheme,Box,Typography,Paper } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import React, { useEffect, useState} from "react";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import TextArea from "../../../components/TextArea";
import CustomDialog from "../../../components/CustomDialog";
import CheckBox from "../../../components/CheckBox";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleIsActive } from "../../../utils/customFunctions";
const AdjustmentInfo = ({ formik }) => {
  const { loading } = useSelector((state) => state.adjustments);
  const navigate=useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [adjustmentTypeOpt, setAdjustmentTypeOpt] = useState([]);
  const theme=useTheme()

  const getAdjustmentTypes = async () => {
    try {
      const response = await getData(`${path}/ct-adjustmentCodeType`);
      console.log(response, "check response");
      if (response) {
        setAdjustmentTypeOpt(response.result);
      }
    } catch (err) {
      console.log(err, "fetching adjustment codes err:");
      throw err;
    }
  };
  useEffect(() => {
    getAdjustmentTypes();
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/adjustment");
  };
  
  return (
   
    <form id="adjustmentInfo" onSubmit={formik.handleSubmit}>
    <Box sx={{display:'flex',justifyContent:'start',padding:'15px',gap:'10px'}}>
    <CustomButton
       type="submit"
       formId="adjustmentInfo"
       isBlue={true}
       isSave={true}
     >
       {loading ? "Saving..." : "Save"}
     </CustomButton>
       
     <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
         {formik.values.adjustmentCodeId && (
    <Box sx={{ marginLeft:'auto'}}> 
      <CheckBox
        CheckedValue={!formik.values.isActive}
        handleChange={() =>
          // formik.setFieldValue("isActive",!formik.values.isActive)
          handleIsActive(formik)
        }
        label="Make this Adjustment inactive"
      />
    </Box>
  )}
        
    </Box>
    <Paper elevation={3} style={{ padding: 20, margin: 15 }}>
    <Grid container spacing={2}>
      <Grid item md={6}>
        <CustomField
          label="Code"
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          type="text"
          value={formik.values.adjustmentCode}
          name="adjustmentCode"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      </Grid>
      <Grid item md={6}>
        <CustomSelectBox2
          label="Adjust Type"
          value={formik.values.adjustmentCodeTypeId}
          name="adjustmentCodeTypeId"
          formik={formik}
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          dropdownOptions={adjustmentTypeOpt.map((opt) => ({
            id: opt.adjustmentCodeTypeId,
            value: opt.adjustmentCodeType,
          }))}
        />
      </Grid>

      <Grid item md={12}>
        {/* <TextArea
          placeholder="Description"
          style={{ fontSize: "1.3rem" }}
          minRows={5}
          value={formik.values.description}
          name="description"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        /> */}
        <CustomField
      label="Description"
        value={formik.values.description}
        name="description"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        />
        
        {formik.errors["description"] && formik.touched["description"] && (
          <div
            style={{
              color: "red",
              // border: "1px solid red",
              border:`2px solid ${theme.palette.errorFieldsBorder.primary}`,
              fontSize: "1.3rem",
              marginTop: "8px",
              display: "block",
            }}
          >
            {formik.errors["description"]}
          </div>
        )}
      </Grid>
    </Grid>
    <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
 isCancel={true}
/>


</Paper>
    </form>
  );
};

export default AdjustmentInfo;
