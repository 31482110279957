import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdjustmentRemittanceAction } from "../../../features/actions/remittanceAction";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../components/Loading";
import { Box,Grid, useTheme  } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";




const AdjTable = ({ handleClose }) => {
  const dispatch = useDispatch();
  const theme=useTheme();
  const { adjustmentData, loading } = useSelector((state) => state.remittance);
  const [selectedAdjustment, setSelectedAdjustment] = useState(null);
  console.log(selectedAdjustment, "selectedRemark");
  const rows =
    adjustmentData && adjustmentData.length > 0
      ? adjustmentData.map((item) => ({
          id: item.adjustmentCodeId ,
          ...item,
        }))
      : [];
  const columns = [
    {
      field: "adjustmentCode",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "longDescription",
      minWidth: 100,
      flex: 2,
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];
  useEffect(() => {
    try {
      dispatch(getAdjustmentRemittanceAction());
    } catch (e) {
      console.log(e, ": Error fetching remark remittance");
      throw e;
    }
  }, []);

  // handle add adjustments
  const handleAddAdjustment = async () => {
    const selectedAllAdj = selectedAdjustment?.map((adj) =>
      rows?.find((el) => el.id === adj)
    );
    const adjustmentUpdateStructure = selectedAllAdj.map((adj) => ({
      remittanceCodeName: adj?.adjustmentCode,
      remittanceTypeId: adj?.remittanceTypeId,
      remittanceType: adj?.remittanceType,
      remittanceInfoLevelId: adj?.remittanceInfoLevelId,
      remittanceInfoLevel: adj?.remittanceInfoLevel,
      reportDescription: adj?.reportDescription,
      longDescription: adj?.longDescription,
      isMemoline: adj?.isMemoline,
      isMemolineAmount: adj?.isMemolineAmount,
      remittanceCodeDetailDtos: adj?.remittanceCodeDetailDtos,
    }));

    try {
      const response = await postData(
        `${path}/remittanceCode`,
        adjustmentUpdateStructure
      );
      if (response) {
        toast.success("Adjustment code added successfully");
        handleClose();
        return response;
      }
    } catch (err) {
      console.log(err, ": Error inposting remittance adjustment");
      handleClose();
      toast.error(err.response.data.message);
    }
  };
  return (
    <Grid md={6} sx={{margin:'20px'}}>
      {loading ? (
        <Loading />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{
            "& .header-bg": {
              background: theme.palette.modalTables.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
            "& .MuiTablePagination-root .MuiTablePagination-selectLabel, & .MuiTablePagination-root .MuiTablePagination-select": {
              display: "none",
            },
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          onSelectionModelChange={(newAdjustment) =>
            setSelectedAdjustment(newAdjustment)
          }
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
                {adjustmentData.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      )}

      <div
        style={{
          position: "sticky",
          bottom: "0",
          borderTop: ".5px solid grey",
          width: "100%",
          background: "#fff",
          padding: "10px",
        }}
      >
        <CustomButton
          isBlue={true}
          isAdd={true}
          handleClick={handleAddAdjustment}
        >
          Add
        </CustomButton>
      </div>
    </Grid>
  );
};

export default AdjTable;
