import { Box, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { scheduleConfiguration } from "../../../../data/feeScheduleData";
import CheckBox from "../../../../components/CheckBox";
import path from "../../../../config/apiUrl";
import { getData } from "../../../../config/axiosFunctions";
import CustomMultiSelect from "../../../../components/CustomMultiSelect/CustomMultiSelect";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { getFeeScheduleConfigAction } from "../../../../features/actions/feeSchedulesAction";

const Configuration = ({ feeScheduleFormik, isOpen }) => {
  const dispatch = useDispatch();
  const [radioSelection, setRadioSelction] = useState({});
  const [dropdownData, setDropDownData] = useState({});
  const [loading, setLoading] = useState(false);
  //  redux selector
  const { configurationOptionsData } = useSelector(
    (state) => state.feeSchedule
  );
  // key mapping object here
  const keyMappings = {
    1: { valueKey: "payerId", labelKey: "payerName" },     // Payer
    2: { valueKey: "payerTypeId", labelKey: "payerType" }, // Payer Type
    3: { valueKey: "accountTypeId", labelKey: "accountType" }, // Account Type
    4: { valueKey: "providerId", labelKey: "providerName" }, // Provider
    5: { valueKey: "practiceId", labelKey: "practiceName" }, // Practice
    6: { valueKey: "facilityId", labelKey: "facilityName" }, // Facility
    8: { valueKey: "posCodeId", labelKey: "posCode" }, // POS
    9: { valueKey: "tosCodeId", labelKey: "tosCode" }, // tos
    10: { valueKey: "modifierId", labelKey: "modifierCode" }, // modifier
  };
  // config here
  const handleConfigChange = async (config) => {
    let allConfig = [...feeScheduleFormik?.values?.feeScheduleConfigurationDto];
    let found = allConfig?.findIndex(
      (c) => c?.feeConfigurationId === config.feeConfigurationId
    );
    if (found === -1) {
      const firstRadioBtn = config?.searchByDtos?.[0]
      console.log(firstRadioBtn, "checkFirstRadioBtn")
      allConfig.push({
        // feeScheduleConfigurationId: 0,
        feeConfigurationId: config.feeConfigurationId,
        configurationType: config.feeConfigurationType,
        searchById: firstRadioBtn?.searchById,
        searchByType: firstRadioBtn?.searchByType,
        feeScheduleId: 0,
        feeScheduleSequenceNo: 0,
        searchByValueListDto: [],
      });
      feeScheduleFormik.setFieldValue("feeScheduleConfigurationDto", allConfig);
      if(firstRadioBtn) {
        const data = await fetchDropdownData(firstRadioBtn.searchById);
        if (data.result.length > 0) {
          const { valueKey, labelKey } = keyMappings[firstRadioBtn.searchById];
          const formattedData = formatDropdownData(data.result, valueKey, labelKey);
          setDropDownData((prevState) => ({
            ...prevState,
            [config.feeConfigurationId]: formattedData,
          }));
        }
      }
    } else {
      allConfig.splice(found, 1);
      feeScheduleFormik.setFieldValue("feeScheduleConfigurationDto", allConfig);
    }
  };
  // set the format for the multiselect
  const formatDropdownData = (data, valueKey, labelKey) => {
    return data.map((item) => ({
      value: item[valueKey],
      label: item[labelKey],
    }));
  };
  // fetch data for the dropdown
  const fetchDropdownData = async (typeId) => {
    const endpoints = {
      1: `${path}/payer/getPayersList`,
      2: `${path}/ct-payerType`,
      3: `${path}/ct-accountType`,
      4: `${path}/provider/getProviderList`,
      5: `${path}/practice/getPracticeList`,
      6: `${path}/facility/getFacilityList`,
      8: `${path}/PosCode/GetAllPosCodesList`,
      9: `${path}/ct-tosCode/GetAllTosCodesList`,
      10: `${path}/ct-modifierCode/GetAllModifiersList`,
    };
    setLoading(true);
    try {
      const response = await getData(endpoints[typeId]);
      setLoading(false);
      return response;
    } catch (err) {
      console.error(err, ": Error in fetching dropdown data");
      setLoading(false);
      throw err;
    }
  };
  // handle radio
  const handleRadioChange = async (configId, typeId, searchByType) => {
    setRadioSelction((prevState) => ({ ...prevState, [configId]: typeId }));
    let allConfig = [...feeScheduleFormik?.values?.feeScheduleConfigurationDto];
    let found = allConfig.findIndex((c) => c?.feeConfigurationId === configId);
    if (found !== -1) {
      let configCopy = JSON.parse(JSON.stringify(allConfig[found]));
      configCopy.searchById = typeId;
      configCopy.searchByType = searchByType;
      configCopy.searchByValueListDto = [];
      allConfig[found] = configCopy;
      feeScheduleFormik.setFieldValue("feeScheduleConfigurationDto", allConfig);
    }
  
    // Fetch new dropdown data based on the newly selected radio button
    const data = await fetchDropdownData(typeId);
    if (data.result.length > 0) {
      const { valueKey, labelKey } = keyMappings[typeId];
      const formattedData = formatDropdownData(data.result, valueKey, labelKey);
      setDropDownData((prevState) => ({
        ...prevState,
        [configId]: formattedData,
      }));
    }
  };
  

  useEffect(() => {
    dispatch(getFeeScheduleConfigAction());
  }, []);

  // handle dropdown here
  const handleDropdownChange = (selectedOptions, configId) => {
    let allConfig = [...feeScheduleFormik?.values?.feeScheduleConfigurationDto];
    let found = allConfig.findIndex((c) => c?.feeConfigurationId === configId);
  
    if (found !== -1) {
      // Deep copy the item to update without mutating the state
      let configCopy = JSON.parse(JSON.stringify(allConfig[found]));
  
      configCopy.searchByValueListDto = selectedOptions.map((opt) => ({
        searchByValueId: 0,
        searchById: 0,
        searchByType: "",
        searchByValueOptionId: opt.value,
        searchByValueOptionType: opt.label,
        feeScheduleId: 0,
        feeScheduleSequenceNo: null,
      }));
  
      allConfig[found] = configCopy;
      feeScheduleFormik.setFieldValue("feeScheduleConfigurationDto", allConfig);
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      feeScheduleFormik.values?.feeScheduleConfigurationDto.forEach(async (config) => {
          if (config?.searchById) {
            const data = await fetchDropdownData(config.searchById);
            const { valueKey, labelKey } = keyMappings[config.searchById];
          const formattedData = formatDropdownData(data.result, valueKey, labelKey);
            setDropDownData((prevState) => ({
              ...prevState,
              [config.feeConfigurationId]: formattedData,
            }));
          }
      });
    }
  }, [isOpen]);
  return (
    <Stack gap={"10px"} margin={"15px"}>
      {configurationOptionsData?.map((config, i) => (
        <Stack key={config?.feeConfigurationId}>
          <CheckBox
            label={config?.feeConfigurationType}
            CheckedValue={feeScheduleFormik?.values?.feeScheduleConfigurationDto?.some(
              (el) => el?.feeConfigurationId === config?.feeConfigurationId
            )}
            handleChange={() => handleConfigChange(config)}
          />
          {feeScheduleFormik?.values?.feeScheduleConfigurationDto?.some(
            (item, i) => item?.feeConfigurationId === config?.feeConfigurationId
          ) && (
            <Stack key={i}>
              <RadioGroup
                value={   feeScheduleFormik?.values?.feeScheduleConfigurationDto.find(
                  (item) => item?.feeConfigurationId === config?.feeConfigurationId
                )?.searchById || 0}
                onChange={(e) =>
                  handleRadioChange(
                    config.feeConfigurationId,
                    Number(e.target.value),
                    config.searchByDtos.find(
                      (type) => type.searchById === Number(e.target.value)
                    )?.searchByType || ""
                  )
                }
              >
                {config?.searchByDtos?.map((type) => (
                  <FormControlLabel
                    key={type?.searchById}
                    label={type?.searchByType}
                    value={type?.searchById}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
              {dropdownData[config?.feeConfigurationId] && (
                <MultiSelect
                  labelledBy="Select"
                  isLoading={loading}
                  options={dropdownData[config?.feeConfigurationId]}
                value={feeScheduleFormik?.values?.feeScheduleConfigurationDto.find(
                  (item) => item?.feeConfigurationId === config?.feeConfigurationId
                )?.searchByValueListDto.map((opt) => ({
                        value: opt.searchByValueOptionId,
                        label: opt.searchByValueOptionType,
                })) || []}
                  onChange={(selectedOptions) =>
                      handleDropdownChange(selectedOptions, config.feeConfigurationId)}
                />
              )}
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
export default Configuration;
