import React, { useState, useEffect } from 'react';
import { Grid, Typography,Box,Stack,useTheme, ToggleButton,  } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AdminImage from '../../assets/ellipse21.png';
import UserImage1 from '../../assets/userr1.svg';
import UserImage2 from '../../assets/user2.png';
import UserImage3 from '../../assets/user3.png';
import UserImage4 from '../../assets/user4.png';
import BarChart from './BarChart';
import '../dashboard/dashboard.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlueDot from '../../assets/bluedot.png'
import WaveImage from '../../assets/waveImg.png';
import LightBlueDot from '../../assets/lightblue.svg';
import ShadedEllipse from '../../assets/shadedellipse.svg';
import LineChart from './LineChart';
import ProfileCard from './dashboard-cards/ProfileCard';
import AdminAnnouncement from './dashboard-cards/AdminAnnouncement';
import PatientRecords from './dashboard-cards/PatientRecords';
import TotalClaim from './dashboard-cards/TotalClaim';
import Ratio from './dashboard-cards/Ratio';
import NewPatient from './dashboard-cards/NewPatient';
import OldPatient from './dashboard-cards/OldPatient';
import DoctorsAppointment from './dashboard-cards/DoctorsAppointment';
import PendingPayments from './dashboard-cards/PendingPayments';
import Toggle from '../../components/Toggle';
import CustomField from '../../components/CustomField';
import CheckBox from '../../components/CheckBox';
import { getData } from '../../config/axiosFunctions';
import path from '../../config/apiUrl';

const Dashboard = () => {
  const theme = useTheme();
  // logic of fetching 
  const [apiData, setApiData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getData(`${path}/dashboard`)
      if(response) {
        setApiData(response.result)
      }
      // const formattedData = data.result[0].todaysPatientsDto.map(item => ({
      //   accountNo: item.accountNo,
      //   patientName: item.patientName,
      //   patientCreatedOn: new Date(item.patientCreatedOn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      // }));
      // setApiData(formattedData)
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
 
 <Box height={30}>
  <Box sx={{display:"flex"}}>
<Box component="main" sx={{ flexGrow: 1, p: 3, margin: '10px' }}>
  <Grid container spacing={2}>
    <Grid item lg={5} xs={12}>
        <ProfileCard/>
    </Grid>

    <Grid item lg={7} xs={12}>
      <AdminAnnouncement 
   
      />
    </Grid>
  </Grid>

{/* 2nd row */}
  <Grid container spacing={2} marginTop={'20px'}>
    <Grid  item lg={4} xs={12}>
        <PatientRecords data={apiData}/>
    </Grid>

    <Grid item lg={3} xs={12}>
      <Stack spacing={2} direction='column' >
        <TotalClaim/>
          <Ratio/>

      
      <Stack spacing={2} direction='row' >
       <NewPatient data={apiData}/>
       <OldPatient data={apiData}/>
     </Stack>
      </Stack>
    </Grid>

    <Grid item lg={5} xs={12}>
      <Stack spacing={2} direction='column'>
      <PendingPayments data={apiData}/>
      </Stack>
    </Grid>
    </Grid>

{/* 3rd row */}
    <Grid container spacing={2} marginTop={'20px'}>
    <Grid item lg={6} xs={12}>
    <BarChart data={apiData}/> 
    </Grid>
  
    <Grid item lg={6} xs={12}>
   <LineChart data={apiData}/>
    </Grid>
    </Grid>
</Box>
</Box>
 </Box>



    </>
  )
}

export default Dashboard;
