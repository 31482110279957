import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers , newUserAction , updateUserAction, updateUserPasswordAction } from "../actions/userAction";
import { toast } from "react-toastify";
import { getAllPosCodesActios } from "../actions/posCodeAction";

const initialState = {
    getAllPos: [],
    createPos: {},
    error: null,
    loading: false,
  };

  const posCodeSlice = createSlice({
    name: "posCodes",
    initialState: initialState,
  
    extraReducers: {
      [getAllPosCodesActios.pending]: (state) => {
        state.loading = true;
      },
      [getAllPosCodesActios.fulfilled]: (state, action) => {
        state.loading = false;
        state.getAllPos = action.payload;
      },
      [getAllPosCodesActios.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log(state.error, "user error");
      },
     
    }})

    export default posCodeSlice.reducer