import React from "react";
import CustomButton from "../../../components/CustomButton";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  FormControl,
  Radio,
  FormControlLabel,
  Stack,
  RadioGroup,
} from "@mui/material";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { MultiSelect } from "react-multi-select-component";
import CustomSearchField from "../../../components/CustomSearchField";
import { claimCols } from "../../../utils/gridCols";
import { getClaimAction } from "../../../features/actions/claimAction";
import { getClaimBySearchTermAction } from "../../../features/actions/claimAction";
import CustomField from "../../../components/CustomField";
import CustomModal from "../../../components/CustomModal";
import MultiPayer from "./MultiPayer";
import { patientCols } from "../../../utils/gridCols";
import CustomPreview from "../../../layout/CustomPreview";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatientBySearchTermAction } from "../../../features/actions/createPatientAction";
import { getPatientAction } from "../../../features/actions/createPatientAction";
import { getFacilityAction } from "../../../features/actions/facilityAction";
import { getFacilityBySearchTermAction } from "../../../features/actions/facilityAction";
import { facilityCols } from "../../../utils/gridCols";
import { getPracticeAction } from "../../../features/actions/practiceAction";
import { getPracticeBySearchTermAction } from "../../../features/actions/practiceAction";
import { getPayerAction } from "../../../features/actions/payerAction";
import { getPayerBySearchTermAction } from "../../../features/actions/payerAction";
import { practiceCols } from "../../../utils/gridCols";
import { providerCols } from "../../../utils/gridCols";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { getProviderAction } from "../../../features/actions/providerAction";
import { payerCol } from "../../../utils/gridCols";
import { getProviderBySearchTermAction } from "../../../features/actions/providerAction";
import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview";
import { useConfirm } from "material-ui-confirm";
import CustomMultiSelect from "../../../components/CustomMultiSelect/CustomMultiSelect";
import { useState } from "react";
const FollowUpManagementInfo = ({ formik, claimStatusOptions }) => {
  const claimBalanceId = formik.values.claimBalanceId;
  let gridSize = 12;
  let showFirstAdditionalField = false;
  let showSecondAdditionalField = false;

  if (claimBalanceId === 5) {
    gridSize = 6;
    showFirstAdditionalField = true;
    showSecondAdditionalField = true;
  } else if (claimBalanceId !== 1) {
    gridSize = 6;
    showFirstAdditionalField = true;
  }
  const theme = useTheme();
  let confirm = useConfirm();
  const [openUserList, setOpenUserList] = useState(false);
  const [openClaimList, setOpenClaimList] = useState(false);
  const [openPatientList, setOpenPatientList] = useState(false);
  const [openPracticeList, setOpenPracticeList] = useState(false);
  const [openProviderList, setOpenProviderList] = useState(false);
  const [openFacilityList, setOpenFacilityList] = useState(false);
  const [openPayerList, setOpenPayerList] = useState(false);
  const [dateOfServiceOpt, setDateOfServiceOpt] = useState([]);
  const [payerSelectedRow, setPayerSelectedRow] = useState([]);
  const [facilitySelectedRow, setFacilitySelectedRow] = useState([]);
  const [providerSelectedRow, setProviderSelectedRow] = useState([]);
  const [practiceSelectedRow, setPracticeSelectedRow] = useState([]);
  console.log("payerSelectedRow", payerSelectedRow);
  const [claimStatusOpt, setClaimStatusOpt] = useState([]);
  const [claimBalanceOpt, setClaimBalanceOpt] = useState([]);
  const [claimTypeOpt, setClaimTypeOpt] = useState([]);
  const [payerTypeOpt, setPayerTypeOpt] = useState([]);
  const [filterTypeOpt, setFilterTypeOpt] = useState([]);
  const [filterBilledOpt, setFilterBilledOpt] = useState([]);
  // use selectors
  const { getAllPayer } = useSelector((state) => state.payer);
  const { getPractices } = useSelector((state) => state.practices);
  const { getFacilityData } = useSelector((state) => state.facility);
  const { getAllPatients } = useSelector((state) => state.patient);
  const { getProviders } = useSelector((state) => state.provider);
  const { getClaims } = useSelector((state) => state.claim);
  const payerLoading = useSelector((state) => state.payer.loading);
  const practiceLoading = useSelector((state) => state.practices.loading);
  const facilityLoading = useSelector((state) => state.facility.loading);
  const patientLoading = useSelector((state) => state.patient.loading);
  const providerLoading = useSelector((state) => state.provider.loading);
  const claimLoading = useSelector((state) => state.claim.loading);
  const payerRows =
  getAllPayer && getAllPayer?.result?.payers?.length > 0
  ? getAllPayer?.result?.payers?.map((el) => ({
      id: el?.payerId,
      ...el,
    }))
  : []
  const practiceRows =
    getPractices && getPractices?.result?.practice?.length > 0
      ? getPractices?.result?.practice?.map((el) => ({
          id: el.practiceId,
          ...el,
        }))
      : [];
  // rows
  const facilityRows =
    getFacilityData?.result && getFacilityData.result?.facility?.length > 0
      ? getFacilityData.result?.facility?.map((el) => ({
          id: el.facilityId,
          facilityName: el.facilityName,
          phoneNumber: el.phoneNumber,
          sequenceNo: el.sequenceNo,
          taxonomySpecialty: el.taxonomySpecialty,
        }))
      : [];
  // rows
  const rows =
    getAllPatients && getAllPatients?.result?.patients?.length > 0
      ? getAllPatients.result?.patients?.map((el) => ({
          id: el.accountNo,
          insuName: el?.getInsuParty?.insuName,
          ...el,
        }))
      : [];

  console.log(getProviders, "checking provider");
  const providerRows =
    getProviders && getProviders.result?.providers?.length > 0
      ? getProviders?.result?.providers?.map((el) => ({
          id: el.providerId,
          ...el,
        }))
      : [];

  // rows
  const claimRows =
    getClaims && getClaims.result?.claims.length > 0
      ? getClaims.result?.claims.map((el) => ({
          id: el.claimNumber,
          fromDate: el?.getClaimChargesDto?.fromDate,
          ...el,
        }))
      : [];

  const dataFetchUrls = {
    dateOfService: `${path}/ct-dOS`,
    // claimStatus: `${path}/ct-claimStatus`,
    claimBalance: `${path}/ct-arFilters`,
    claimType: `${path}/ct-claimTypes`,
    payerType: `${path}/ct-payerType`,
    filterType: `${path}/ct-filterType`,
    filterBilled: `${path}/FilterBilled`,

    // createUser: `${path}/authenticate/users`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.dateOfService, setDateOfServiceOpt);
    fetchDataOptions(dataFetchUrls.claimBalance, setClaimBalanceOpt);
    fetchDataOptions(dataFetchUrls.claimType, setClaimTypeOpt);
    fetchDataOptions(dataFetchUrls.payerType, setPayerTypeOpt);
    fetchDataOptions(dataFetchUrls.filterType, setFilterTypeOpt);
    fetchDataOptions(dataFetchUrls.filterBilled, setFilterBilledOpt);
  }, []);

  const handleClearPractice = () => {
    formik.setFieldValue("practiceId", []);
  };
  return (
    <>
      <Box
        sx={{
          border: "1px solid #6e6b6b",
          borderRadius: "5px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            background: theme.palette.revenueStatement.primary,
            height: "33px",
          }}
        >
          <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
            Follow Up Options
          </Typography>
        </Box>

        <Grid container spacing={2} padding={2}>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Claim Status Id
              </label>
              {/* <MultiSelect
                options={claimStatusOptions?.map((el) => ({
                  label: el?.claimStatusType,
                  value: el?.claimStatusId,
                }))}
                value={formik.values.claimStatusId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("claimStatusId", selectedOption);
                }}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              /> */}
               <CustomMultiSelect
            className="custom-multi-select"
            options={claimStatusOptions?.map((el) => ({
              label: el?.claimStatusType,
              value: el?.claimStatusId,
            }))}
            value={formik.values.claimStatusId}
            handleChange={(selectedOption) => {
              formik.setFieldValue("claimStatusId", selectedOption);
            }}
           handleSelectAll={true}
         />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomSelectBox2
              value={formik.values.followUpId}
              name="followUpId"
              dropdownOptions={dateOfServiceOpt?.map((opt) => ({
                value: opt.dos,
                id: opt.dosId,
              }))}
              label="Follow Up Date"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker
              dateLabel="From"
              formik={formik}
              name="followUpFromDate"
              dateValue={formik.values.followUpFromDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker
              dateLabel="To"
              formik={formik}
              name="followUpToDate"
              dateValue={formik.values.followUpToDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomSelectBox2
              value={formik.values.dateOfServiceId}
              name="dateOfServiceId"
              dropdownOptions={dateOfServiceOpt?.map((opt) => ({
                value: opt.dos,
                id: opt.dosId,
              }))}
              label="Date Of Service"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker
              dateLabel="From"
              formik={formik}
              name="dateOfServiceFromDate"
              dateValue={formik.values.dateOfServiceFromDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker
              dateLabel="To"
              name="dateOfServiceToDate"
              dateValue={formik.values.dateOfServiceToDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
              formik={formik}
            />
          </Grid>

          {/* Radio buttons */}
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3">Filter Billed Insurance by:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <RadioGroup row name="row-radio-buttons-group">
              {filterBilledOpt.map((option) => (
                <FormControlLabel
                  checked={formik.values.noOfDays === option?.filterBilledId}
                  control={
                    <Radio
                      onChange={() =>
                        formik.setFieldValue("noOfDays", option?.filterBilledId)
                      }
                    />
                  }
                  label={option.filterBilledType}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <RadioGroup row name="row-radio-buttons-group">
              {filterTypeOpt.map((option) => (
                <FormControlLabel
                  checked={
                    formik.values.filterByDaysOrDateId === option?.filterTypeId
                  }
                  control={
                    <Radio
                      onChange={() =>
                        formik.setFieldValue(
                          "filterByDaysOrDateId",
                          option?.filterTypeId
                        )
                      }
                    />
                  }
                  label={option.filterType}
                />
              ))}
            </RadioGroup>
          </Grid>
          {formik.values.filterByDaysOrDateId === 2 && (
            <>
              <Grid item xs={12} sm={12} md={4}>
                <CustomSelectBox2
                  value={formik.values.billedInsDateId}
                  name="billedInsDateId"
                  dropdownOptions={dateOfServiceOpt?.map((opt) => ({
                    value: opt.dos,
                    id: opt.dosId,
                  }))}
                  label="Last Billed Ins Date"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  showDefaultOpt={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <CustomDatePicker
                  dateLabel="From"
                  formik={formik}
                  name="billedInsFromDate"
                  dateValue={formik.values.billedInsFromDate}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <CustomDatePicker
                  dateLabel="To"
                  formik={formik}
                  name="billedInsToDate"
                  dateValue={formik.values.billedInsToDate}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </Grid>
            </>
          )}

          {formik.values.filterByDaysOrDateId === 1 && (
            <Grid item xs={12} sm={12} md={12}>
              <CustomSelectBox2
                value={formik.values.billedInsId}
                name="billedInsId"
                dropdownOptions={claimBalanceOpt?.map((opt) => ({
                  value: opt.arFilterName,
                  id: opt.arFilterId,
                }))}
                label="Days Since Last Billed Ins"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                showDefaultOpt={true}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={gridSize}>
            <CustomSelectBox2
              value={formik.values.claimBalanceId}
              name="claimBalanceId"
              dropdownOptions={claimBalanceOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Claim Balance"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </Grid>

          {showFirstAdditionalField && (
            <Grid item xs={12} sm={6} md={3} className="mt-4">
              <CustomField
                type="number"
                value={formik.values.claimBalanceValue1}
                name="claimBalanceValue1"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
          )}

          {showSecondAdditionalField && (
            <Grid item xs={12} sm={6} md={3} className="mt-4">
              <CustomField
                type="number"
                value={formik.values.claimBalanceValue2}
                name="claimBalanceValue2"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <CustomSelectBox2
              value={formik.values.claimTypeId}
              name="claimTypeId"
              dropdownOptions={claimTypeOpt?.map((opt) => ({
                value: opt.claimType,
                id: opt.claimTypeId,
              }))}
              label="Claim Type"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <CustomSearchField
              handleModalOpen={() => setOpenPayerList(true)}
              label="Current Payer"
              type="text"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              // fieldVal={formik.values.payerId }
              fieldVal={
                formik.values.payerId &&
                `(${formik.values?.payerName})`
              }
              name="payerId"
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={4}>
            <CustomSearchField
              type="text"
              label="Current Payer"
              fieldVal={formik.values.payerId?.map((el) => el?.label)}
              handleModalOpen={() => setOpenPayerList(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequi={true}
              name="payerId"
              resetField={{
                payerName:[]
              }}
            />
            <CustomModal
              open={openPayerList}
              handleClose={() => setOpenPayerList(false)}
              heading={"All Payers"}
            >
              <MultiSelectCustomPreview
                setState={setPayerSelectedRow}
                rows={payerRows}
                isModal={true}
                columns={payerCol}
                paginationDetail={getPayerAction}
                searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
                loading={payerLoading}
                handleGetAll={getPayerAction}
                handleBySearch={getPayerBySearchTermAction}
                state={payerSelectedRow}
                handleCell={(selectedRow) => {
                  let setRowData = selectedRow?.map((el) => ({  
                    value: el?.payerSequenceNo,
                    label: el?.payerName,
                  }));
                  formik.setValues((preVals) => ({
                    ...preVals,
                    payerId: [...setRowData],
                  }));
                  setOpenPayerList(false);
                 
                }}
              />
            </CustomModal>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomSearchField
              handleModalOpen={() => setOpenClaimList(true)}
              label="Claim #"
              fieldVal={formik.values.claimNumber}
              name="claimNumber"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequi={true}
              formik={formik}
              resetField={{
                claimNumber: 0,
              }}
     
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomSearchField
              handleModalOpen={() => setOpenPatientList(true)}
              label="Patient"
              type="text"
              fieldVal={formik.values.patientName}
              name="patientName"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
               resetField={{
                patientName: "",
                patientAccountNumber:null
              }}
          
            />

          </Grid>
          <Grid item md={12} sm={12} xs={12}>   
            <FormControl fullWidth>
              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Payer Type Id
              </label>
              {/* <MultiSelect
                options={payerTypeOpt?.map((el) => ({
                  label: el?.payerType,
                  value: el?.payerTypeId,
                }))}
                value={formik.values.payerTypeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("payerTypeId", selectedOption);
                }}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              /> */}
              <CustomMultiSelect
            className="custom-multi-select"
            options={payerTypeOpt?.map((el) => ({
              label: el?.payerType,
              value: el?.payerTypeId,
            }))}
            value={formik.values.payerTypeId}
            handleChange={(selectedOption) => {
              formik.setFieldValue("payerTypeId", selectedOption);
            }}
           handleSelectAll={true}
         />
            </FormControl>
   
          </Grid>

          {/* change */}
          <Grid item md={4} sm={12} xs={12}>
           
 <CustomSearchField
        label="Practice"
        fieldVal={formik.values.practiceId?.map((el) => el?.label)}
        handleModalOpen={() => setOpenPracticeList(true)}
        name="practiceId"
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        isRequi={true}
        formik={formik}
        resetField={{
          practiceId:[]
        }}
      />

              <CustomModal
              open={openPracticeList}
              handleClose={() => setOpenPracticeList(false)}
              heading={"Practice"}
            >
              <MultiSelectCustomPreview
                setState={setPracticeSelectedRow}
                rows={practiceRows}
                isModal={true}
                columns={practiceCols}
                paginationDetail={getPracticeAction}
                searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
                loading={practiceLoading}
                handleGetAll={getPracticeAction}
                handleBySearch={getPracticeBySearchTermAction}
                state={practiceSelectedRow}
                handleCell={(selectedRow) => {
                  let setRowData = selectedRow?.map((el) => ({  
                    value: el?.sequenceNo,
                    label: el?.practiceName,
                  }));
                  formik.setValues((preVals) => ({
                    ...preVals,
                    practiceId: [...setRowData],
                  }));
                  setOpenPracticeList(false);
                }}
              />
            </CustomModal>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
          
              <CustomSearchField
              label="Provider"
              fieldVal={formik.values.providerId?.map((el) => el?.label)}
              handleModalOpen={() => setOpenProviderList(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="providerId"
              isRequi={true}
              fromik={formik}
              resetField={{
                providerFirstName:[]
              }}
            />
              <CustomModal
              open={openProviderList}
              handleClose={() => setOpenProviderList(false)}
              heading={"Provider"}
            >
              <MultiSelectCustomPreview
                setState={setProviderSelectedRow}
                rows={providerRows}
                isModal={true}
                columns={providerCols}
                paginationDetail={getProviderAction}
                searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
                loading={providerLoading}
                handleGetAll={getProviderAction}
                handleBySearch={getProviderBySearchTermAction}
                state={providerSelectedRow}
                handleCell={(selectedRow) => {
                  let setRowData = selectedRow?.map((el) => ({  
                    value: el?.providerSequenceNo,
                    label: el?.providerFirstName,
                  }));
                  formik.setValues((preVals) => ({
                    ...preVals,
                    providerId: [...setRowData],
                  }));
                setOpenProviderList(false);
                 
                }}
              />
            </CustomModal>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
                <CustomSearchField
              label="Facility"
              fieldVal={formik.values.facilityId?.map((el) => el?.label)}
              handleModalOpen={() => setOpenFacilityList(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="facilityId"
              resetField={{
                facilityId:[]
              }}
              formik={formik}
              isRequi={true}
          
            />
              <CustomModal
              open={openFacilityList}
              handleClose={() => setOpenFacilityList(false)}
              heading={"Facility"}
            >
              <MultiSelectCustomPreview
                setState={setFacilitySelectedRow}
                rows={facilityRows}
                isModal={true}
                columns={facilityCols}
                paginationDetail={getFacilityAction}
                searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
                loading={facilityLoading}
                handleGetAll={getFacilityAction}
                handleBySearch={getFacilityBySearchTermAction}
                state={facilitySelectedRow}
                handleCell={(selectedRow) => {
                  let setRowData = selectedRow?.map((el) => ({  
                    // value: el?.providerSequenceNo,
                    // label: el?.providerFirstName,
                    value: el?.sequenceNo,
                    label: el?.facilityName,
                  }));
                  formik.setValues((preVals) => ({
                    ...preVals,
                   facilityId: [...setRowData],
                  }));
                  setOpenFacilityList(false);
                 
                }}
              />
            </CustomModal>
          </Grid>
        </Grid>
      </Box>

      {/* Task Options */}

      {/* <Box
        sx={{
          border: "1px solid #6e6b6b",
          borderRadius: "5px",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            background: theme.palette.revenueStatement.primary,
            height: "33px",
          }}
        >
          <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
            Task Options
          </Typography>
        </Box>

        <Grid container spacing={2} padding={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CustomSelectBox2
              dropdownOptions={[]}
              label="Task Assigned"
              // handleChange={formik.handleChange}
              // handleBlur={formik.handleBlur}
              // formik={formik}
              // showDefaultOpt={true}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl fullWidth>
              <MultiSelect
                options={[]}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomSelectBox2
              dropdownOptions={[]}
              label="Due Date"
              // handleChange={formik.handleChange}
              // handleBlur={formik.handleBlur}
              // formik={formik}
              // showDefaultOpt={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker dateLabel="From" />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <CustomDatePicker dateLabel="To" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomField label="Assigned To" type="text" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CustomButton
              isUser={true}
              isBlue={true}
              width="70%"
              margin={"20px 30px"}
              handleClick={() => setOpenUserList(true)}
            >
              Select Users
            </CustomButton>
          </Grid>
        </Grid>
      </Box> */}

      {/* Custom Modals */}
      <CustomModal
        open={openUserList}
        handleClose={() => setOpenUserList(false)}
        heading={"Select Assign To User"}
      ></CustomModal>

      <CustomModal
        open={openClaimList}
        handleClose={() => setOpenClaimList(false)}
        heading={"Claim Search"}
      >
        <CustomPreview
          rows={claimRows}
          isModal={true}
          columns={claimCols}
          paginationDetail={getClaims}
          searchLabel="Search by Claim Id,Patient Name,Dos"
          loading={claimLoading}
          handleCell={(params) => {
            formik.setFieldValue("claimNumber", params?.claimNumber);
            setOpenClaimList(false);
          }}
          handleGetAll={getClaimAction}
          handleBySearch={getClaimBySearchTermAction}
        />
      </CustomModal>
      {/* <CustomModal
        open={openPracticeList}
        handleClose={() => setOpenPracticeList(false)}
        height={"20%"}
        heading={"All Practices"}
      >
        <CustomPreview
          isModal={true}
          rows={practiceRows}
          columns={practiceCols}
          paginationDetail={getPractices}
          searchLabel="Search for practices by name, phone #, sequence #"
          loading={practiceLoading}
          handleCell={(params) => {
            formik.setFieldValue("practiceId", [params?.practiceId]);
            formik.setFieldValue("practiceName", [params?.practiceName]);
            setOpenPracticeList(false);
          }}
          handleGetAll={getPracticeAction}
          handleBySearch={getPracticeBySearchTermAction}
        />
      </CustomModal> */}
      {/* <CustomModal
        open={openProviderList}
        handleClose={() => setOpenProviderList(false)}
        height={"20%"}
        heading={"All Providers"}
      >
        <CustomPreview
          rows={providerRows}
          isModal={true}
          columns={providerCols}
          loading={providerLoading}
          paginationDetail={getProviders}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search by Provider Name and Sequence No"
          handleCell={(params) => {
            formik.setFieldValue("providerId", [params?.providerId]);
            formik.setFieldValue(
              "providerName",
              `${params?.providerFirstName} ${params?.providerLastName || ""}`
            );
            setOpenProviderList(false);
          }}
        />
      </CustomModal> */}
      {/* <CustomModal
        open={openFacilityList}
        handleClose={() => setOpenFacilityList(false)}
        height={"20%"}
        heading={"Facility"}
      >
        <CustomPreview
          isModal={true}
          rows={facilityRows}
          columns={facilityCols}
          loading={facilityLoading}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          handleCell={(params) => {
            console.log(params, "facilityParams");
            formik.setFieldValue("facilityId", [params?.id]);
            formik.setFieldValue("facilityName", params?.facilityName);
            setOpenFacilityList(false);
          }}
          searchLabel="Search by First Name, Phone ,or Sequence Number"
        />
      </CustomModal> */}
      <CustomModal
        open={openPatientList}
        handleClose={() => setOpenPatientList(false)}
        heading={"Patient Search"}
      >
        <CustomPreview
          rows={rows}
          isModal={true}
          columns={patientCols}
          paginationDetail={getAllPatients}
          searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
          loading={patientLoading}
          handleCell={(params) => {
            formik.setFieldValue("patientAccountNumber", params?.accountNo);
            formik.setFieldValue("patientName", params?.patientName);
            setOpenPatientList(false);
          }}
          handleGetAll={getPatientAction}
          handleBySearch={getPatientBySearchTermAction}
        />
      </CustomModal>
      {/* <CustomModal
        open={openPayerList}
        handleClose={() => setOpenPayerList(false)}
        height={"20%"}
        heading={"All Payers"}
      >
        <CustomPreview
          rows={payerRows}
          isModal={true}
          columns={payerCol}
          paginationDetail={getPayerAction}
          searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
          loading={payerLoading}
          handleCell={(params) => {
            formik.setFieldValue("payerId", params?.payerId);
            formik.setFieldValue("payerName", params?.payerName);
            setOpenPayerList(false);
          }}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
        />
      </CustomModal> */}
    </>
  );
};

export default FollowUpManagementInfo;
