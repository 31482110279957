import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";

export const getDuplicatePatientsAction = createAsyncThunk(
    "getDuplicatePatients",
    async (values) => {
      try {
        const response = await getData(
          `${path}/patients/DuplicatePatient?last=${values?.lastName}&first=${values?.firstName}&birthdate=${values?.dateOfBirth}&Gender=${values?.genderName}`
        );
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );