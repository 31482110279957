import React, { useState } from 'react';
import { Box, Stack, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomPreview from '../../../layout/CustomPreview'; 
import CustomButton from '../../../components/CustomButton'; 
import CustomModal from '../../../components/CustomModal';
import { getReferringProviderBySearchTermAction } from '../../../features/actions/referringProviderAction';
import { getReferringProviderAction } from '../../../features/actions/referringProviderAction';
import { referringProviderCol } from '../../../utils/gridCols'; 
import NPIRegistry from './NPIRegistry';

const ReferringProvider = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNpiRegistry,setOpenNpiRegistry]=useState(false)
  const navigate = useNavigate();
  const { getReferringProviderData, loading } = useSelector((state) => state.referringProvider);

  const rows =
  getReferringProviderData?.result && getReferringProviderData.result?.referringProviders?.length > 0
    ? getReferringProviderData.result?.referringProviders?.map((el) => ({
      id: el.referringProviderId,
        referringProviderFirstName: el.referringProviderFirstName,
        referringProviderLastName: el.referringProviderLastName,
        referringProviderTaxonomySpecialtyCode: el.referringProviderTaxonomySpecialtyCode,
        referringProviderSequenceNo: el.referringProviderSequenceNo,
      }))
    : [];
  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (route) => {

    console.log(route);
    navigate(route); 
    handleClosePopup(); 
  };

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={referringProviderCol}
        loading={loading}
        handleGetAll={getReferringProviderAction}
        handleCell={(params) => navigate(`/referringProvider/update/${params?.referringProviderSequenceNo}`)}
        paginationDetail={getReferringProviderData}
        handleBySearch={getReferringProviderBySearchTermAction}
        moduleHeading={'Referring Provider'}
        searchLabel="Search by First Name Sequence Number"
        showAll={true}
      >
      <Stack direction="row" alignItems="center">
        <CustomButton
          isAdd={true}
          isBlue={true}
          handleClick={handleClickButton}
        >
          New Referring
        </CustomButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClosePopup}
        >
          <MenuItem onClick={() => handleSelectOption('/referringProvider/new')}>Add Manually</MenuItem>
          <MenuItem onClick={() => setOpenNpiRegistry(true)}>Add Via NPI Registry</MenuItem>
        </Menu>
      </Stack>
      </CustomPreview>
      <CustomModal
        open={openNpiRegistry}
        handleClose={() => setOpenNpiRegistry(false)}
        heading="NPI Registry"
        // handleClick={noteFormik?.handleSubmit}
        // showButton={true}
        // btnLabel={"Done"}
      >
      <NPIRegistry/>
      </CustomModal>
    </Box>
  );
};

export default ReferringProvider;

