import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { adjustPrices } from "../../../../data/feeScheduleData";
import CustomField from "../../../../components/CustomField";

const FeeAdjustPrice = ({ scheduleTypeFormik }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: "1px solid #6e6b6b",
        borderRadius: "5px",
        // margin: "15px",
      }}
    >
      <Box
        sx={{
          background: theme.palette.revenueStatement.primary,
          height: "33px",
        }}
      >
        <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
          Adjust Prices
        </Typography>
      </Box>

      <FormControl fullWidth sx={{ padding: "10px" }}>
        <Stack flexDirection="column" alignItems="left" marginLeft="20px">
          {adjustPrices.map((value) => (
            <Stack direction="row" alignItems={"center"} gap="10px">
              <FormControlLabel
                key={value.id}
                label={value.ctOption}
                control={
                  <Radio
                    value={value.id}
                    checked={
                      scheduleTypeFormik.values.adjustPriceId === value.id
                    }
                    onChange={() =>{
                      scheduleTypeFormik.setFieldValue("adjustedPriceValue", 0)
                      scheduleTypeFormik.setFieldValue(
                        "adjustPriceId",
                        value.id
                      )}
                    }
                    name="radio-buttons"
                  />
                }
              />
              {scheduleTypeFormik.values.adjustPriceId !== 1 &&
                scheduleTypeFormik.values.adjustPriceId === value.id && (
                  <CustomField
                    value={scheduleTypeFormik?.values?.adjustedPriceValue}
                    type={"number"}
                    handleChange={scheduleTypeFormik?.handleChange}
                    handleBlur={scheduleTypeFormik?.handleBlur}
                    name={"adjustedPriceValue"}
                    isDecimal={true}
                    
                  />
                )}
            </Stack>
          ))}
        </Stack>
      </FormControl>
    </Box>
  );
};

export default FeeAdjustPrice;
