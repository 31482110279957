// import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import {
//   getAdjustmentRemittanceAction,
//   getRemarkRemittanceAction,
//   getRemittanceAction,
//   newRemittanceAction,
//   updateRemittanceAction,
//   getSingleRemittanceAction
// } from "../actions/remittanceAction";

// const initialState = {
//   createRemittanceData: {},
//   getRemittance: [],
//   getSingleRemittance: {},
//   remarkData: [],
//   adjustmentData: [],
//   
//   loading: false,
//   error: null,
// };
// const remittanceSlice = createSlice({
//   name: "remittance",
//   initialState: initialState,
//   
//  
//   
//   
//
//   extraReducers: {
//     [getRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [getRemittanceAction.fulfilled]: (state, action) => {
//       console.log(action.payload);
//       state.loading = false;
//       state.getRemittance = action.payload;
//     },
//     [getRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload.message;
//     },
//     [newRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [newRemittanceAction.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.createRemittanceData = action.payload;
//       toast.success("Remittance has been created Successfully!");
//     },
//     [newRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       // state.error = action.payload.message;
//       state.error = action.payload;
//       toast.error("Remittance creation failed");
//       console.log(toast.error,"message")

//     },
  
//     [updateRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [updateRemittanceAction.fulfilled]: (state, action) => {
//       state.loading = false;
//       toast.success("Remittance Updated Successfully!");
//     }, 
//     [updateRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload.message;
//       toast.error("Remittance updation failed");
//     },
//     [getRemarkRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [getRemarkRemittanceAction.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.remarkData = action.payload.result;
//     },
//     [getRemarkRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload.message;
//     },
//     [getAdjustmentRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [getAdjustmentRemittanceAction.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.adjustmentData = action.payload.result;
//     },
//     [getAdjustmentRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload.message;
//     },
//     [getSingleRemittanceAction.pending]: (state) => {
//       state.loading = true;
//     },
//     [getSingleRemittanceAction.fulfilled]: (state, action) => {
//       console.log(action.payload);
//       state.loading = false;
//       // state.getSingleRemittance = action.payload;
//       state.getSingleRemittance = action?.payload
//     },
//     [getSingleRemittanceAction.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//   },
// });

// export default remittanceSlice.reducer;






import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAdjustmentRemittanceAction,
  getRemarkRemittanceAction,
  getRemittanceAction,
  newRemittanceAction,
  updateRemittanceAction,
  getSingleRemittanceAction,
  fetchRemittanceByTypeAction
} from "../actions/remittanceAction";

const initialState = {
  createRemittanceData: {},
  getRemittance: [],
  getSingleRemittance: {},
  remarkData: [],
  adjustmentData: [],
  btnNo:2,
  loading: false,
  error: null,
};
const remittanceSlice = createSlice({
  name: "remittance",
  initialState: initialState,
  // reducers: {
  //   handleChange: (state, action) {
  //     state.btnNo = action.payload
  //   }
  // },
  extraReducers: {
    [fetchRemittanceByTypeAction.pending]: (state) => {
      state.loading = true;
    },
    [fetchRemittanceByTypeAction.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.getRemittance = action.payload;
      console.log(state.getRemittance , "remkitance get all");
    },
    [fetchRemittanceByTypeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [newRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [newRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createRemittanceData = action.payload;
      toast.success("Remittance has been created Successfully!");
    },
    [newRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      // state.error = action.payload.message;
      state.error = action.payload;
      toast.error("Remittance creation failed");
      console.log(toast.error,"message")

    },
  
    [updateRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [updateRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Remittance Updated Successfully!");
    }, 
    [updateRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      toast.error("Remittance updation failed");
    },
    [getRemarkRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getRemarkRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.remarkData = action.payload.result;
    },
    [getRemarkRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAdjustmentRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getAdjustmentRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.adjustmentData = action.payload.result;
    },
    [getAdjustmentRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSingleRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getSingleRemittanceAction.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.loading = false;
      // state.getSingleRemittance = action.payload;
      state.getSingleRemittance = action?.payload
    },
    [getSingleRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default remittanceSlice.reducer;

