import React, { useState } from "react";
import CompanyInfo from "./CompanyInfo";
import { useFormik } from "formik";
import { companyInitVals } from "../../../utils/formikInitValues";
import { companySchema } from "../../../schemas/userSchema";
import { useNavigate } from "react-router-dom";
import { newCompanyAction } from "../../../features/actions/companiesAction";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
const NewCompany = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
    const formik=useFormik({
     initialValues:companyInitVals,
     validationSchema:companySchema,
     onSubmit:(values,action)=>{
      console.log(values)
      dispatch(newCompanyAction(values));
      action.resetForm();
      navigate("/company");
     },
    })
  return (

     <CompanyInfo formik={formik}/>

  )
}

export default NewCompany
