import { Box, 
  // TextField, Typography 
} from "@mui/material";
import React 
// { useEffect } 
from "react";
import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomField from "../../components/CustomField";
// import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
import CustomSelectBox from "../../components/CustomSelectBox";

const EditPayDetail = ({
  data,
  onSave,
  handleClose,
  formik,
  claimStatus,
  currentClaimChargesStatus,
}) => {
  const [editedData, setEditedData] = useState({ ...data });
  const handleSave = () => {
    if (formik.values.paymentByTypeId === 1) {
      let allowed = editedData.allowed;
      // let paid = editedData.paid;
      const updatedData = {
        ...editedData,
        allowed: allowed,
        paid: allowed,
        adjusted: 0,
        unpaid: 0,
        endBalance:
          allowed <= editedData.startBalance
            ? editedData.startBalance - allowed
            : 0,
        otherCredits: 0,
        claimStatusId:
          editedData.claimStatusId === currentClaimChargesStatus
            ? (allowed + editedData.adjusted) >= editedData.startBalance

              ? 6
              : 1
            : currentClaimChargesStatus,
      };
      onSave(updatedData);
    } else {
      let allowed = editedData.allowed;
      let paid = editedData.paid > 0 ? editedData.paid : editedData.allowed;
      let adjusted = editedData.adjusted;
      // let deductible = editedData.deductible;
      let unpaid = editedData.unpaid;
      const updatedData = {
        ...editedData,
        allowed: allowed,
        paid: paid,
        unpaid: unpaid > 0 ? unpaid : allowed - paid,
        endBalance: editedData.startBalance - adjusted - paid,
        adjusted: adjusted >= 0 ? adjusted : editedData.amount - allowed,
        otherCredits: paid > allowed ? allowed - paid : 0,
        claimStatusId:
          editedData.claimStatusId === currentClaimChargesStatus
            ? (allowed + adjusted) >= editedData.startBalance
              ? 6
              : 1
            : currentClaimChargesStatus || (allowed + adjusted) >= editedData.startBalance
              ? 6
              : 1,
      };
      onSave(updatedData);
    }
    handleClose();
  };

  return (
    <>
      <Box
        display="grid"
        padding="20px"
        gap="30px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          type="number"
          size="small"
          label="Amount"
          disable={true}
          touched={formik.touched}
          error={formik.errors}
          value={editedData.amount}
          name="amount"
          handleChange={(e) =>
            setEditedData({ ...editedData, amount: Number(e.target.value) })
          }
        />
        <CustomField
          type="number"
          size="small"
          label="Start Balance"
          disable={true}
          touched={formik.touched}
          error={formik.errors}
          value={editedData.startBalance}
          name="startBalance"
          handleChange={(e) =>
            setEditedData({
              ...editedData,
              startBalance: Number(e.target.value),
            })
          }
        />
        <CustomField
          type="number"
          size="small"
          touched={formik.touched}
          error={formik.errors}
          label={
            formik.values.paymentByTypeId !== 1 ? "Allowed" : "Apply Payment"
          }
          value={editedData.allowed}
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="allowed"
          handleChange={(e) => {
            setEditedData({
              ...editedData,
              allowed: Number(e.target.value),
              adjusted: Math.abs(
                parseFloat(editedData.startBalance - Number(e.target.value))
              ),
            });
          }}
        />
        <CustomSelectBox
          name="claimStatusId"
          value={editedData.claimStatusId}
          dropdownOptions={claimStatus?.map((opt) => ({
            value: opt.claimStatusType,
            id: opt.claimStatusId,
          }))}
          label="Claim Status"
          handleChange={(e) => {
            setEditedData({
              ...editedData,
              claimStatusId: Number(e.target.value),
              claimStatus: claimStatus?.find(
                (v) => v?.claimStatusId === Number(e.target.value)
              )?.claimStatusType,
            });
          }}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Paid"
            value={editedData.paid}
            touched={formik.touched}
            error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="paid"
            handleChange={(e) => {
              let amount = Number.isNaN(e.target.value)
                ? 0
                : Number(e.target.value);
              setEditedData({
                ...editedData,
                paid: amount,
              });
            }}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Adjusted"
            value={editedData.adjusted}
            touched={formik.touched}
            error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="adjusted"
            handleChange={(e) => {
              console.log("Number.isNaN(e.target.value)", e.target.value);
              let amount = Number.isNaN(e.target.value)
                ? 0
                : Number(e.target.value);
              setEditedData({ ...editedData, adjusted: amount });
            }}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Unpaid"
            touched={formik.touched}
            error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="unpaid"
            value={editedData.unpaid}
            // size="small"
            // fullWidth
            // InputLabelProps={{ shrink: true }}
            // variant="outlined"
            handleChange={(e) => {
              if (e.target.value) {
                let amount = Number.isNaN(e.target.value) ? 0 : e.target.value;
                setEditedData({ ...editedData, unpaid: amount });
              }
            }}
          />
        )}
        {formik.values.paymentByTypeId !== 1 && (
          <CustomField
            type="number"
            label="Deductible"
            touched={formik.touched}
            error={formik.errors}
            disable={formik.values.paymentByTypeId === 1}
            name="deductible"
            value={editedData.deductible}
            // fullWidth
            // InputLabelProps={{ shrink: true }}
            // variant="outlined"
            // size="small"
            handleChange={(e) => {
              if (e.target.value) {
                let amount = Number.isNaN(e.target.value) ? 0 : e.target.value;
                setEditedData({
                  ...editedData,
                  deductible: amount,
                });
              }
            }}
          />
        )}
      </Box>
      <div>
        <Box
          display="grid"
          padding="20px"
          gap="30px"
          sx={{
            gridTemplateColumns: {
              xs: "repeat(4, minmax(0, 1fr))",
              sm: "repeat(4, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
            },
          }}
        >
          <CustomButton isBlue={true} handleClick={handleSave} isSave={true}>
            Save
          </CustomButton>
          <CustomButton isBlue={true} isCancel={true} handleClick={handleClose}>
            Cancel
          </CustomButton>
        </Box>
      </div>
    </>
  );
};

export default EditPayDetail;
