// import {
//   Box,
//   Button,
//   FormControlLabel,
//   Paper,
//   Radio,
//   RadioGroup,
//   Stack,
//   Tab,
//   Tabs,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import { pdf } from "@react-pdf/renderer";
// import React, { useCallback, useEffect } from "react";
// import { Dropdown } from "react-bootstrap";
// import PrintIcon from "@mui/icons-material/Print";
// import { useState } from "react";
// import ClaimInfo from "./ClaimInfo";
// import ClaimCharges from "./ClaimCharges";
// import { useFormik } from "formik";
// import { claimInitVal } from "../../../utils/formikInitValues";
// import { useDispatch } from "react-redux";
// import { newClaimAction } from "../../../features/actions/claimAction";
// import fingerTouchLogo from "../../../assets/finger_touch.png";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import AdditionInfo from "./AdditionInfo";
// import "./claim-styling/claim.css";
// import AmbulanceInfo from "./AmbulanceInfo";
// import { FormLabel } from "react-bootstrap";
// import InsAdditionalinfo from "./InsAdditionalinfo";
// import InformationCodes from "./InformationCodes";
// import CustomButton from "../../../components/CustomButton";
// import { getData, postData } from "../../../config/axiosFunctions";
// import { useSpring, animated } from "react-spring";
// import path from "../../../config/apiUrl";
// import axios from "axios";
// import CmsForm15 from "../../../components/pdfs/CmsForm15";
// import { handleCancel } from "../../../utils/customFunctions";
// import CmsUB04 from "../../../components/pdfs/CmsUB04";
// import { claimSchema } from "../../../schemas/patientSchema";
// import ClaimSummary from "../../../components/pdfs/ClaimSummary";
// import CustomDialog from "../../../components/CustomDialog";

// const NewClaim = () => {

//   const navigate = useNavigate();
//   const [tabValue, setTabValue] = useState(0);
//   const [pdfData, setPdfData] = useState([]);
//   const [openClaimSummary, setOpenClaimSummary] = useState(false);
//   const location = useLocation();
//   console.log(location.state,"location id")
//   const theme = useTheme(); // Access the current theme
//   const mode = theme.palette.mode; // Extract the mode from the theme
//   const colors = theme.palette; // Extract the colors from the theme
//   const [claimIds, setClaimIds] = useState({
//     patientAccountNo: null,
//     patientId: null,
//     practiceId: null,
//     billingProviderId: null,
//     insuredPartyId: null,
//     payerInfoId: null,
//     renderingProviderId: null,
//     supervisingProviderId: null,
//     providerId: null,
//   });
//   const [facilityId, setFacilityId] = useState(null);
//   const [claimChargesDto, setClaimChargesDto] = useState([]);
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);

//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/claims");
//   };
//   const dispatch = useDispatch();
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const formik = useFormik({
//     initialValues: claimInitVal,
//     validationSchema: claimSchema,
//     onSubmit: (values, action) => {
//       if (
//         values.claimTypeId === 1 &&
//         values.icD_DiagnosisDetailDto.length <= 0 &&
//         claimChargesDto.length <= 0
//       ) {
//         toast.error("At least one Diagnosis Code and Procedure is required");
//         return;
//       }
//       console.log("object");
//       const postValues = {
//         ...values,
//         claimChargesUpdatedDto: claimChargesDto,

//       };
//       console.log("postval",postValues)

//       try {
//         dispatch(newClaimAction(postValues));
//         navigate("/claims");
//       } catch (error) {
//         throw error;
//       }
//       action.resetForm();
//     },
//   });

//   const fetchPdfData = async (findClaim) => {
//     try {
//       if (findClaim?.claimTypeId === 1) {
//         const cmsPdfData = await axios.get(
//           `${path}/cmsForm?claimNo=${findClaim.claimNumber}`
//         );
//         console.log(cmsPdfData, "checkCmsPdfData");
//         if (cmsPdfData.status === 200) {
//           console.log(cmsPdfData, "response99");
//           const data = cmsPdfData.data;
//           setPdfData(data.result);
//         } else {
//           setPdfData([]);
//         }
//       } else if (findClaim?.claimTypeId === 2) {
//         const ub04Pdf = await getData(
//           `${path}/ub04Form/${findClaim?.claimNumber}`
//         );
//         console.log(ub04Pdf, "ub04Data");
//         setPdfData(ub04Pdf.result);
//       }
//     } catch (err) {
//       console.log(err, "pdfError");
//     }
//   };

//   const handleSaveAndPreviewClick = async () => {
//     try {
//       const updateValues = {
//         ...formik.values,
//         claimChargesUpdatedDto: formik.values.claimChargesDto,
//       };
//       await postData(`${path}/claim/AddUpdatedClaim`, updateValues).then(
//         (e) => {
//           console.log("updateValues", e?.result[e?.result.length - 1]);
//           fetchPdfData(e?.result[e?.result.length - 1]).then((data) => {
//             navigate("/claims");
//             handlePreviewClick(e?.result[e?.result.length - 1]);
//           });
//         }
//       );
//     } catch (error) {
//       toast.error("Error while save and preview");
//     }
//   };

//   const claimSummaryProps = useSpring({
//     width: openClaimSummary ? 450 : 0,
//     right: openClaimSummary ? 0 : -70, // Start off-screen
//     from: { right: -30 },
//     config: { duration: 600 }, // Adjust duration as needed
//   });

//   const handlePreviewClick = async (findClaim) => {
//     const pdfBlob = await pdf(
//       findClaim?.claimTypeId === 1 ? (
//         <CmsForm15 data={pdfData} />
//       ) : (
//         <CmsUB04 pdfData={pdfData} />
//       )
//     ).toBlob();
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     window.open(pdfUrl, "_blank");
//   };

//   // handleClaimSummary
//   const handleClaimSummary = useCallback(() => {
//     setOpenClaimSummary(true);
//   }, []);
// console.log(formik.values.claimTypeId);
//   console.log("Form values after change:", formik.values);
//   return (
//     <>
//       <Box margin="20px" paddingBottom={"25px"}>
//         {/* <MainHeading mainheading={"Create Claim"} /> */}
//         {/* select claim type */}
//         <Stack>
//           <FormLabel
//             style={{ fontSize: "1.4rem", fontWeight: "500" }}
//             id="claimType"
//           >
//             Select Claim Type
//           </FormLabel>
//           {/* <RadioGroup
//             row
//             aria-labelledby="claimType"
//             name="row-radio-buttons-group"
//           >
//             <FormControlLabel
//               value={1}
//               control={
//                 <Radio
//                   checked={formik.values.claimTypeId === 1}
//                   onChange={() => {
//                     formik.setFieldValue("claimSummaryDto.formType", "CMS 1500 02-12")
//                     formik.setFieldValue("claimSummaryDto.formTypeId", 1)
//                     formik.setFieldValue("claimTypeId", 1)
//                     formik.setFieldValue("claimFrequencyId", 1)
//                     formik.setFieldValue("typeOfBill", null)
//                     // formik.setFieldValue("facilityTypeId", null)
//                     // formik.setFieldValue("careTypeId", null)
//                     // formik.setFieldValue("frequencyTypeId", null)

//                     // facilityTypeId: null,
//                     // careTypeId: null,
//                     // frequencyTypeId: null,
//                     // typeOfBill: "",

//                     // console.log("consolecheck",formik.setFieldValue("typeOfBill",null))
//                     // formik.setFieldValue("typeOfBill",null)
//                     // console.log("facility null ho ri ya ni", formik.values);
//                   }}
//                   style={{
//                     width: "30px",
//                     color: theme.palette.radioButtons.primary,
//                   }}
//                 />
//               }
//               label={
//                 <Typography variant="h5" fontWeight="600">
//                   Professional
//                 </Typography>
//               }
//             />
//             <FormControlLabel
//               value={2}
//               control={
//                 <Radio
//                   checked={formik.values.claimTypeId === 2}
//                   onChange={() => {
//                     formik.setFieldValue("claimSummaryDto.formType", "CMS-1450 UB-04")
//                     formik.setFieldValue("claimSummaryDto.formTypeId", 2)
//                     formik.setFieldValue("claimTypeId", 2)
//                     formik.setFieldValue("claimFrequencyId", null)
//                     formik.setFieldValue("frequencyTypeId", 2)
//                   }}

//                   style={{
//                     width: "30px",
//                     color: theme.palette.radioButtons.primary,
//                   }}
//                 />
//               }
//               label={
//                 <Typography variant="h5" fontWeight="600">
//                   Institutional
//                 </Typography>
//               }
//             />
//           </RadioGroup> */}
//           {/* <RadioGroup
//       row
//       aria-labelledby="claimType"
//       name="row-radio-buttons-group"
//     >
//       <FormControlLabel
//         value={1}
//         control={
//           <Radio
//             checked={formik.values.claimTypeId === 1}
//             onChange={() => {
//               formik.setFieldValue("claimSummaryDto.formType", "CMS 1500 02-12");
//               formik.setFieldValue("claimSummaryDto.formTypeId", 1);
//               formik.setFieldValue("claimTypeId", 1);
//               formik.setFieldValue("claimFrequencyId", 1);
//               formik.setFieldValue("careTypeId", null);
//               formik.setFieldValue("typeOfBill", null);
//               formik.setFieldValue("facilityTypeId", null);
//               formik.setFieldValue("careTypeId", null);
//               formik.setFieldValue("frequencyTypeId", null);
//             }}
//             style={{
//               width: "30px",
//               color: theme.palette.radioButtons.primary,
//             }}
//           />
//         }
//         label={
//           <Typography variant="h5" fontWeight="600">
//             Professional
//           </Typography>
//         }
//       />
//       <FormControlLabel
//         value={2}
//         control={
//           <Radio
//             checked={formik.values.claimTypeId === 2}
//             onChange={() => {
//               formik.setFieldValue("claimSummaryDto.formType", "CMS-1450 UB-04");
//               formik.setFieldValue("claimSummaryDto.formTypeId", 2);
//               formik.setFieldValue("claimTypeId", 2);
//               formik.setFieldValue("claimFrequencyId", null);
//               formik.setFieldValue("typeOfBill", null);
//               formik.setFieldValue("facilityTypeId", 1);
//               formik.setFieldValue("careTypeId", 1);
//               formik.setFieldValue("frequencyTypeId", 2);
//             }}
//             style={{
//               width: "30px",
//               color: theme.palette.radioButtons.primary,
//             }}
//           />
//         }
//         label={
//           <Typography variant="h5" fontWeight="600">
//             Institutional
//           </Typography>
//         }
//       />
//     </RadioGroup> */}
//         </Stack>

//         <Stack
//             direction="row"
//             alignContent="center"

//             sx={{
//               order: { xs: 1, sm: 1, md: 3 },
//               alignItems: "center",
//             }}
//           >
//             <Dropdown
//               className="print"
//             >
//               <Dropdown.Toggle
//                 className="printDropDown"
//                 id="dropdown-basic"
//                 style={{ padding: "10px" }}
//               >
//                 <PrintIcon />
//                 Print
//               </Dropdown.Toggle>

//               <Dropdown.Menu>
//                 <Dropdown.Item onClick={handleSaveAndPreviewClick}>
//                   Show Preview & Save
//                 </Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>
//             <CustomButton
//               type="submit"
//               formId="claimForm"
//               isBlue={true}
//               padding={"7px"}
//               margin={"12px"}
//               isSave={true}
//               sx={{
//                 marginRight: "30px",
//               }}
//             >
//               Save
//             </CustomButton>

//             <CustomButton isCancel={true} handleClick={handleCancel}>
//               Cancel
//             </CustomButton>
//           </Stack>
//         <Stack
//           flexDirection={{ xs: "column", sm: "column", md: "row" }}
//           justifyContent="space-between"
//         >
//           <Tabs
//             value={tabValue}
//             onChange={handleTabChange}
//             sx={{
//               order: { xs: 2, sm: 2, md: 1 },
//               marginBottom: "10px",
//               borderRadius: "10px 10px 0 0",
//             }}
//           >
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                   formik.errors["patientFirstName"] ||
//                     formik.errors["billingProviderFirstName"] ||
//                     formik.errors["renderingProviderFirstName"]
//                     ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                     : "",
//               }}
//               className="tabsdesigning"
//               label="Claim"
//               value={0}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                   formik.values.claimTypeId === 1
//                     ? formik.values.claimChargesDto?.length === 0 &&
//                       formik.values.icD_DiagnosisDetailDto.length === 0
//                       ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                       : ""
//                     : formik.values.claimChargesDto.length === 0
//                       ?  `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                       : "",
//               }}
//               className="tabsdesigning"
//               label="Charges"
//               value={1}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                 formik.values.claimTypeId === 2 ?
//                   formik.touched["statementCoversFromDate"] && formik.errors["statementCoversFromDate"] &&
//                     formik.touched["statementCoversToDate"] && formik.errors["statementCoversToDate"] &&
//                     formik.touched["admissionTypeId"] && formik.errors["admissionTypeId"] &&
//                     formik.touched["patientStatusId"] && formik.errors["patientStatusId"]
//                  &&    `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                     : "",
//               }}
//               className="tabsdesigning"
//               label="Additional Info"
//               value={2}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                 formik.values.claimTypeId === 2 ?
//                 formik.touched["principalDiagnosisId"] && formik.errors["principalDiagnosisId"]
//                   &&  `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                   : "",
//               }}
//               className="tabsdesigning"
//               label={
//                 formik.values.claimTypeId === 1
//                   ? "Ambulance Info"
//                   : "Information Codes"
//               }
//               value={3}
//             />
//           </Tabs>
//         </Stack>

//         <form id="claimForm" onSubmit={formik.handleSubmit}>
//           <Box
//             sx={{
//               padding: "15px",
//             }}
//           >
//             {tabValue === 0 && (
//               <ClaimInfo
//                 formik={formik}
//                 setClaimIds={setClaimIds}
//                 setFacilityId={setFacilityId}
//                 location={location}
//               />
//             )}
//             {tabValue === 1 && (
//               <ClaimCharges
//                 formik={formik}
//                 setClaimChargesDto={setClaimChargesDto}
//                 claimChargesDto={claimChargesDto}
//               />
//             )}
//             {tabValue === 2 &&
//               (formik.values.claimTypeId === 1 ? (
//                 <AdditionInfo
//                   formik={formik}
//                 // setClaimChargesDto={setClaimChargesDto}
//                 // claimChargesDto={claimChargesDto}
//                 />
//               ) : (
//                 <InsAdditionalinfo formik={formik}/>
//               ))}
//             {tabValue === 3 &&
//               (formik.values.claimTypeId === 1 ? (
//                 <AmbulanceInfo
//                   formik={formik}
//                 // setClaimChargesDto={setClaimChargesDto}
//                 // claimChargesDto={claimChargesDto}
//                 />
//               ) : (
//                 <InformationCodes formik={formik} />
//               ))}
//           </Box>
//         </form>
//       </Box>
//       {openClaimSummary ? (
//         <animated.div
//           style={{
//             width: "30%",
//             position: "fixed",
//             top: "14%",
//             overflow: "hidden",
//             zIndex: 1,
//             ...claimSummaryProps, // Apply animated styles
//           }}
//         >
//           <ClaimSummary
//             formik={formik}

//             // claimNo={Number(claimNumber)}
//             handleClose={() => setOpenClaimSummary(false)}
//           />
//         </animated.div>
//       ) : (
//         <Button
//           style={{ background: theme.palette.accSummary.primary }}
//           className="accountSummaryBtn"
//           onClick={handleClaimSummary}
//         >
//           <span>
//             <img
//               src={fingerTouchLogo}
//               alt="finger-touch-logo"
//               style={{ marginRight: "5px" }}
//             />
//           </span>
//           <span>Claim Summary</span>
//         </Button>
//       )}

//       {/* custom dialog */}
// <CustomDialog
//         open={openCancelDialog}
//         isWarning={true}
//         handleClose={() => setOpenCancelDialog(false)}
//         handleConfirm={handleConfirmCancel}
//         handleCancel={() => setOpenCancelDialog(false)}
//         confirmButtonTitle="OK"
//         cancelButtonTitle="Cancel"
//         isCancel={true}
//       />

//     </>
//   );
// };

// export default NewClaim;

// import {
//   Box,
//   Button,
//   FormControlLabel,
//   Paper,
//   Radio,
//   RadioGroup,
//   Stack,
//   Tab,
//   Tabs,
//   Typography,
//   useTheme,
// } from "@mui/material";
// import { pdf } from "@react-pdf/renderer";
// import React, { useCallback, useEffect } from "react";
// import { Dropdown } from "react-bootstrap";
// import PrintIcon from "@mui/icons-material/Print";
// import { useState } from "react";
// import ClaimInfo from "./ClaimInfo";
// import ClaimCharges from "./ClaimCharges";
// import { useFormik } from "formik";
// import { claimInitVal } from "../../../utils/formikInitValues";
// import { useDispatch } from "react-redux";
// import { newClaimAction } from "../../../features/actions/claimAction";
// import fingerTouchLogo from "../../../assets/finger_touch.png";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import AdditionInfo from "./AdditionInfo";
// import "./claim-styling/claim.css";
// import AmbulanceInfo from "./AmbulanceInfo";
// import { FormLabel } from "react-bootstrap";
// import InsAdditionalinfo from "./InsAdditionalinfo";
// import InformationCodes from "./InformationCodes";
// import CustomButton from "../../../components/CustomButton";
// import { getData, postData } from "../../../config/axiosFunctions";
// import { useSpring, animated } from "react-spring";
// import path from "../../../config/apiUrl";
// import axios from "axios";
// import CmsForm15 from "../../../components/pdfs/CmsForm15";
// import { handleCancel } from "../../../utils/customFunctions";
// import CmsUB04 from "../../../components/pdfs/CmsUB04";
// import { claimSchema } from "../../../schemas/patientSchema";
// import ClaimSummary from "../../../components/pdfs/ClaimSummary";
// import CustomDialog from "../../../components/CustomDialog";
// import { useSelector } from "react-redux";

// const NewClaim = () => {

//   const navigate = useNavigate();
//   const [tabValue, setTabValue] = useState(0);
//   const [pdfData, setPdfData] = useState([]);
//   const [openClaimSummary, setOpenClaimSummary] = useState(false);
//   const location = useLocation();
//   console.log(location,"location id")
//   const profId = location.state.state?.profId;
//   console.log(profId,"profId")
//   const instId = location.state.state?.instId;
//   console.log(instId,"instId")
//   const theme = useTheme();
//   const [claimIds, setClaimIds] = useState({
//     patientAccountNo: null,
//     patientId: null,
//     practiceId: null,
//     billingProviderId: null,
//     insuredPartyId: null,
//     payerInfoId: null,
//     renderingProviderId: null,
//     supervisingProviderId: null,
//     providerId: null,
//   });
//   const [facilityId, setFacilityId] = useState(null);
//   const [claimChargesDto, setClaimChargesDto] = useState([]);
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const state = useSelector((state) => state);
// console.log(state, "Full Redux State");

// // useEffect(() => {
// //   console.log("Claim type changed:", claimType);
// // }, [claimType]);

//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/claims");
//   };
//   const dispatch = useDispatch();
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const formik = useFormik({
//     initialValues: claimInitVal,
//     validationSchema: claimSchema,
//     onSubmit: (values, action) => {
//       if (
//         values.claimTypeId === 1 &&
//         values.icD_DiagnosisDetailDto.length <= 0 &&
//         claimChargesDto.length <= 0
//       ) {
//         toast.error("At least one Diagnosis Code and Procedure is required");
//         return;
//       }
//       console.log("object");
//       const postValues = {
//         ...values,
//         claimChargesUpdatedDto: claimChargesDto,

//       };
//       console.log("postval",postValues)

//       try {
//         dispatch(newClaimAction(postValues));
//         navigate("/claims");
//       } catch (error) {
//         throw error;
//       }
//       action.resetForm();
//     },
//   });

//   const fetchPdfData = async (findClaim) => {
//     try {
//       if (findClaim?.claimTypeId === 1) {
//         const cmsPdfData = await axios.get(
//           `${path}/cmsForm?claimNo=${findClaim.claimNumber}`
//         );
//         console.log(cmsPdfData, "checkCmsPdfData");
//         if (cmsPdfData.status === 200) {
//           console.log(cmsPdfData, "response99");
//           const data = cmsPdfData.data;
//           setPdfData(data.result);
//         } else {
//           setPdfData([]);
//         }
//       } else if (findClaim?.claimTypeId === 2) {
//         const ub04Pdf = await getData(
//           `${path}/ub04Form/${findClaim?.claimNumber}`
//         );
//         console.log(ub04Pdf, "ub04Data");
//         setPdfData(ub04Pdf.result);
//       }
//     } catch (err) {
//       console.log(err, "pdfError");
//     }
//   };

//   const handleSaveAndPreviewClick = async () => {
//     try {
//       const updateValues = {
//         ...formik.values,
//         claimChargesUpdatedDto: formik.values.claimChargesDto,
//       };
//       await postData(`${path}/claim/AddUpdatedClaim`, updateValues).then(
//         (e) => {
//           console.log("updateValues", e?.result[e?.result.length - 1]);
//           fetchPdfData(e?.result[e?.result.length - 1]).then((data) => {
//             navigate("/claims");
//             handlePreviewClick(e?.result[e?.result.length - 1]);
//           });
//         }
//       );
//     } catch (error) {
//       toast.error("Error while save and preview");
//     }
//   };

//   const claimSummaryProps = useSpring({
//     width: openClaimSummary ? 450 : 0,
//     right: openClaimSummary ? 0 : -70, // Start off-screen
//     from: { right: -30 },
//     config: { duration: 600 }, // Adjust duration as needed
//   });

//   const handlePreviewClick = async (findClaim) => {
//     const pdfBlob = await pdf(
//       findClaim?.claimTypeId === 1 ? (
//         <CmsForm15 data={pdfData} />
//       ) : (
//         <CmsUB04 pdfData={pdfData} />
//       )
//     ).toBlob();
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     window.open(pdfUrl, "_blank");
//   };

//   // handleClaimSummary
//   const handleClaimSummary = useCallback(() => {
//     setOpenClaimSummary(true);
//   }, []);
// console.log(formik.values.claimTypeId);
//   console.log("Form values after change:", formik.values);
//   return (
//   <>
//       <Box margin="20px" paddingBottom={"25px"}>
//         {/* <MainHeading mainheading={"Create Claim"} /> */}
//         {/* select claim type */}
//         <Stack>
//           <FormLabel
//             style={{ fontSize: "1.4rem", fontWeight: "500" }}
//             id="claimType"
//           >
//             Select Claim Type
//           </FormLabel>
//         </Stack>

//         <Stack
//             direction="row"
//             alignContent="center"

//             sx={{
//               order: { xs: 1, sm: 1, md: 3 },
//               alignItems: "center",
//             }}
//           >
//             <Dropdown
//               className="print"
//             >
//               <Dropdown.Toggle
//                 className="printDropDown"
//                 id="dropdown-basic"
//                 style={{ padding: "10px" }}
//               >
//                 <PrintIcon />
//                 Print
//               </Dropdown.Toggle>

//               <Dropdown.Menu>
//                 <Dropdown.Item onClick={handleSaveAndPreviewClick}>
//                   Show Preview & Save
//                 </Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>
//             <CustomButton
//               type="submit"
//               formId="claimForm"
//               isBlue={true}
//               padding={"7px"}
//               margin={"12px"}
//               isSave={true}
//               sx={{
//                 marginRight: "30px",
//               }}
//             >
//               Save
//             </CustomButton>

//             <CustomButton isCancel={true} handleClick={handleCancel}>
//               Cancel
//             </CustomButton>
//           </Stack>
//         <Stack
//           flexDirection={{ xs: "column", sm: "column", md: "row" }}
//           justifyContent="space-between"
//         >
//           <Tabs
//             value={tabValue}
//             onChange={handleTabChange}
//             sx={{
//               order: { xs: 2, sm: 2, md: 1 },
//               marginBottom: "10px",
//               borderRadius: "10px 10px 0 0",
//             }}
//           >
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                   formik.errors["patientFirstName"] ||
//                     formik.errors["billingProviderFirstName"] ||
//                     formik.errors["renderingProviderFirstName"]
//                     ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                     : "",
//               }}
//               className="tabsdesigning"
//               label="Claim"
//               value={0}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                   location.state.id=== 1
//                     ? formik.values.claimChargesDto?.length === 0 &&
//                       formik.values.icD_DiagnosisDetailDto.length === 0
//                       ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                       : ""
//                     : formik.values.claimChargesDto.length === 0
//                       ?  `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                       : "",
//               }}
//               className="tabsdesigning"
//               label="Charges"
//               value={1}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                 formik.values.claimTypeId === 2 ?
//                   formik.touched["statementCoversFromDate"] && formik.errors["statementCoversFromDate"] &&
//                     formik.touched["statementCoversToDate"] && formik.errors["statementCoversToDate"] &&
//                     formik.touched["admissionTypeId"] && formik.errors["admissionTypeId"] &&
//                     formik.touched["patientStatusId"] && formik.errors["patientStatusId"]
//                  &&    `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                     : "",
//               }}
//               className="tabsdesigning"
//               label="Additional Info"
//               value={2}
//             />
//             <Tab
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "bold",
//                 borderRadius: "10px 10px 0 0",
//                 borderBottom:
//                 formik.values.claimTypeId === 2 ?
//                 formik.touched["principalDiagnosisId"] && formik.errors["principalDiagnosisId"]
//                   &&  `2px solid ${theme.palette.errorFieldsBorder.primary}`
//                   : "",
//               }}
//               className="tabsdesigning"
//               label={
//                 formik.values.claimTypeId === profId
//                   ? "Ambulance Info"
//                   : "Information Codes"
//               }
//               value={3}
//             />
//           </Tabs>
//         </Stack>

//         <form id="claimForm" onSubmit={formik.handleSubmit}>
//           <Box
//             sx={{
//               padding: "15px",
//             }}
//           >
//             {tabValue === 0 && (
//               <ClaimInfo
//                 formik={formik}
//                 setClaimIds={setClaimIds}
//                 setFacilityId={setFacilityId}
//                 location={location}
//               />
//             )}
//             {tabValue === 1 && (
//               <ClaimCharges
//                 formik={formik}
//                 setClaimChargesDto={setClaimChargesDto}
//                 claimChargesDto={claimChargesDto}
//               />
//             )}
//             {tabValue === 2 &&
//               (formik.values.claimTypeId === profId ? (
//                 <AdditionInfo
//                   formik={formik}
//                 // setClaimChargesDto={setClaimChargesDto}
//                 // claimChargesDto={claimChargesDto}
//                 />
//               ) : (
//                 <InsAdditionalinfo formik={formik}/>
//               ))}
//             {tabValue === 3 &&
//               (formik.values.claimTypeId === profId ? (
//                 <AmbulanceInfo
//                   formik={formik}
//                 // setClaimChargesDto={setClaimChargesDto}
//                 // claimChargesDto={claimChargesDto}
//                 />
//               ) : (
//                 <InformationCodes formik={formik} />
//               ))}
//           </Box>
//         </form>
//       </Box>
//       {openClaimSummary ? (
//         <animated.div
//           style={{
//             width: "30%",
//             position: "fixed",
//             top: "14%",
//             overflow: "hidden",
//             zIndex: 1,
//             ...claimSummaryProps, // Apply animated styles
//           }}
//         >
//           <ClaimSummary
//             formik={formik}

//             // claimNo={Number(claimNumber)}
//             handleClose={() => setOpenClaimSummary(false)}
//           />
//         </animated.div>
//       ) : (
//         <Button
//           style={{ background: theme.palette.accSummary.primary }}
//           className="accountSummaryBtn"
//           onClick={handleClaimSummary}
//         >
//           <span>
//             <img
//               src={fingerTouchLogo}
//               alt="finger-touch-logo"
//               style={{ marginRight: "5px" }}
//             />
//           </span>
//           <span>Claim Summary</span>
//         </Button>
//       )}

//       {/* custom dialog */}
// <CustomDialog
//         open={openCancelDialog}
//         isWarning={true}
//         handleClose={() => setOpenCancelDialog(false)}
//         handleConfirm={handleConfirmCancel}
//         handleCancel={() => setOpenCancelDialog(false)}
//         confirmButtonTitle="OK"
//         cancelButtonTitle="Cancel"
//         isCancel={true}
//       />

//     </>
//   );
// };

// export default NewClaim;

import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import React, { useCallback, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PrintIcon from "@mui/icons-material/Print";
import { useState } from "react";
import ClaimInfo from "./ClaimInfo";
import ClaimCharges from "./ClaimCharges";
import { useFormik } from "formik";
import {
  claimInitVal,
  professionalClaimValues,
} from "../../../utils/formikInitValues";
import { useDispatch } from "react-redux";
import { newClaimAction } from "../../../features/actions/claimAction";
import fingerTouchLogo from "../../../assets/finger_touch.png";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdditionInfo from "./AdditionInfo";
import "./claim-styling/claim.css";
import AmbulanceInfo from "./AmbulanceInfo";
import { FormLabel } from "react-bootstrap";
import InsAdditionalinfo from "./InsAdditionalinfo";
import InformationCodes from "./InformationCodes";
import CustomButton from "../../../components/CustomButton";
import { getData, postData } from "../../../config/axiosFunctions";
import { useSpring, animated } from "react-spring";
import path from "../../../config/apiUrl";
import axios from "axios";
import CmsForm15 from "../../../components/pdfs/CmsForm15";
import { handleCancel } from "../../../utils/customFunctions";
import CmsUB04 from "../../../components/pdfs/CmsUB04";
import { claimSchema } from "../../../schemas/patientSchema";
import ClaimSummary from "../../../components/pdfs/ClaimSummary";
import CustomDialog from "../../../components/CustomDialog";
import { useSelector } from "react-redux";

const NewClaim = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [pdfData, setPdfData] = useState([]);
  const [openClaimSummary, setOpenClaimSummary] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  // const [claimIds, setClaimIds] = useState({
  //   patientAccountNo: null,
  //   patientId: null,
  //   practiceId: null,
  //   billingProviderId: null,
  //   insuredPartyId: null,
  //   payerInfoId: null,
  //   renderingProviderId: null,
  //   supervisingProviderId: null,
  //   providerId: null,
  // });
  const [claimChargesDto, setClaimChargesDto] = useState([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const claimType = useSelector((state) => state.claim.claimType);
  console.log(claimType, "claimType");

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/claims");
  };
  const dispatch = useDispatch();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const formik = useFormik({
    // initialValues: claimInitVal,
    initialValues: professionalClaimValues,
    validationSchema: claimSchema,
    validateOnMount: true,
    onSubmit: (values, action) => {
      const emptyClaimCharged = values.claimChargesDto.length === 0;
      if (emptyClaimCharged) {
        toast.error("At least one Procedure Code is required");
      } else {
        console.log(values, "submittedClaim");
        try {
          dispatch(newClaimAction(values)).then((response) => {
            if (response.type === "createClaim/fulfilled") {
              navigate("/claims");
            }
          });
          action.resetForm();
        } catch (error) {
          throw error;
        }
      }
    },
  });

  // const fetchPdfData = async (findClaim) => {
  //   try {
  //     if (findClaim?.claimTypeId === 1) {
  //       const cmsPdfData = await axios.get(
  //         `${path}/cmsForm?claimNo=${findClaim.claimNumber}`
  //       );
  //       console.log(cmsPdfData, "checkCmsPdfData");
  //       if (cmsPdfData.status === 200) {
  //         console.log(cmsPdfData, "response99");
  //         const data = cmsPdfData.data;
  //         setPdfData(data.result);
  //       } else {
  //         setPdfData([]);
  //       }
  //     } else if (findClaim?.claimTypeId === 2) {
  //       const ub04Pdf = await getData(
  //         `${path}/ub04Form/${findClaim?.claimNumber}`
  //       );
  //       console.log(ub04Pdf, "ub04Data");
  //       setPdfData(ub04Pdf.result);
  //     }
  //   } catch (err) {
  //     console.log(err, "pdfError");
  //   }
  // };

  // const handleSaveAndPreviewClick = async () => {
  //   try {
  //     const updateValues = {
  //       ...formik.values,
  //       claimChargesUpdatedDto: formik.values.claimChargesDto,
  //     };
  //     await postData(`${path}/claim/AddUpdatedClaim`, updateValues).then(
  //       (e) => {
  //         console.log("updateValues", e?.result[e?.result.length - 1]);
  //         fetchPdfData(e?.result[e?.result.length - 1]).then((data) => {
  //           navigate("/claims");
  //           handlePreviewClick(e?.result[e?.result.length - 1]);
  //         });
  //       }
  //     );
  //   } catch (error) {
  //     toast.error("Error while save and preview");
  //   }
  // };

  const claimSummaryProps = useSpring({
    width: openClaimSummary ? 450 : 0,
    right: openClaimSummary ? 0 : -70, // Start off-screen
    from: { right: -30 },
    config: { duration: 600 }, // Adjust duration as needed
  });

  // const handlePreviewClick = async (findClaim) => {
  //   const pdfBlob = await pdf(
  //     findClaim?.claimTypeId === 1 ? (
  //       <CmsForm15 data={pdfData} />
  //     ) : (
  //       <CmsUB04 pdfData={pdfData} />
  //     )
  //   ).toBlob();
  //   const pdfUrl = URL.createObjectURL(pdfBlob);
  //   window.open(pdfUrl, "_blank");
  // };

  // handleClaimSummary
  const handleClaimSummary = useCallback(() => {
    setOpenClaimSummary(true);
  }, []);

  console.log("Form values after change:", formik.values);
  return (
    <>
      <Box margin="20px" paddingBottom={"25px"}>
        <Stack
          direction="row"
          alignContent="center"
          marginBottom={"12px"}
          columnGap={"10px"}
          sx={{
            order: { xs: 1, sm: 1, md: 3 },
            alignItems: "center",
          }}
        >
          {/* <Dropdown className="print">
            <Dropdown.Toggle
              className="printDropDown"
              id="dropdown-basic"
              style={{ padding: "10px" }}
            >
              <PrintIcon />
              Print
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleSaveAndPreviewClick}>
                Show Preview & Save
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <CustomButton
            type="submit"
            formId="claimForm"
            isBlue={true}
            padding={"7px"}
            isSave={true}
            sx={{
              marginRight: "30px",
            }}
          >
            Save
          </CustomButton>

          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
        </Stack>
        <Stack
          flexDirection={{ xs: "column", sm: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              order: { xs: 2, sm: 2, md: 1 },
              marginBottom: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
                borderBottom:
                  formik.errors["patientFirstName"] ||
                  formik.errors["billingProviderFirstName"] ||
                  formik.errors["renderingProviderFirstName"]
                    ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                    : "",
              }}
              className="tabsdesigning"
              label="Claim"
              value={0}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
                borderBottom:
                  claimType === 1
                    ? formik.values.claimChargesDto?.length === 0 &&
                      formik.values.icD_DiagnosisDetailDto.length === 0
                      ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                      : ""
                    : formik.values.claimChargesDto.length === 0
                    ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
                    : "",
              }}
              className="tabsdesigning"
              label="Charges"
              value={1}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
                borderBottom:
                  formik.values.claimTypeId === 2
                    ? formik.touched["statementCoversFromDate"] &&
                      formik.errors["statementCoversFromDate"] &&
                      formik.touched["statementCoversToDate"] &&
                      formik.errors["statementCoversToDate"] &&
                      formik.touched["admissionTypeId"] &&
                      formik.errors["admissionTypeId"] &&
                      formik.touched["patientStatusId"] &&
                      formik.errors["patientStatusId"] &&
                      `2px solid ${theme.palette.errorFieldsBorder.primary}`
                    : "",
              }}
              className="tabsdesigning"
              label="Additional Info"
              value={2}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
                borderBottom:
                  formik.values.claimTypeId === 2
                    ? formik.touched["principalDiagnosisId"] &&
                      formik.errors["principalDiagnosisId"] &&
                      `2px solid ${theme.palette.errorFieldsBorder.primary}`
                    : "",
              }}
              className="tabsdesigning"
              label={
                "Ambulance Info"
                // formik.values.claimTypeId === claimType
                //   ? "Ambulance Info"
                //   : "Information Codes"
              }
              value={3}
            />
          </Tabs>
        </Stack>

        <form id="claimForm" onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              padding: "15px",
            }}
          >
            {tabValue === 0 && (
              <ClaimInfo formik={formik} location={location} />
            )}
            {tabValue === 1 && (
              <ClaimCharges
                formik={formik}
                setClaimChargesDto={setClaimChargesDto}
                claimChargesDto={claimChargesDto}
              />
            )}
            {
              tabValue === 2 && (
                // (formik.values.claimTypeId === claimType ? (
                //   <AdditionInfo
                //     formik={formik}
                //     // setClaimChargesDto={setClaimChargesDto}
                //     // claimChargesDto={claimChargesDto}
                //   />
                // ) : (
                // ))
                <AdditionInfo formik={formik} />
              )
              // <InsAdditionalinfo formik={formik} />
            }
            {tabValue === 3 && (
              // (formik.values.claimTypeId === claimType ? (
              //   <AmbulanceInfo
              //     formik={formik}
              //     // setClaimChargesDto={setClaimChargesDto}
              //     // claimChargesDto={claimChargesDto}
              //   />
              // ) : (
              //   <InformationCodes formik={formik} />
              // ))
              <AmbulanceInfo
                formik={formik}
                // setClaimChargesDto={setClaimChargesDto}
                // claimChargesDto={claimChargesDto}
              />
            )}
          </Box>
        </form>
      </Box>
      {/* {openClaimSummary ? (
        <animated.div
          style={{
            width: "30%",
            position: "fixed",
            top: "14%",
            overflow: "hidden",
            zIndex: 1,
            ...claimSummaryProps, 
          }}
        >
          <ClaimSummary
            formik={formik}

            // claimNo={Number(claimNumber)}
            handleClose={() => setOpenClaimSummary(false)}
          />
        </animated.div>
      ) : (
        <Button
          style={{ background: theme.palette.accSummary.primary }}
          className="accountSummaryBtn"
          onClick={handleClaimSummary}
        >
          <span>
            <img
              src={fingerTouchLogo}
              alt="finger-touch-logo"
              style={{ marginRight: "5px" }}
            />
          </span>
          <span>Claim Summary</span>
        </Button>
      )} */}

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
        isCancel={true}
      />
    </>
  );
};

export default NewClaim;
