

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDiagnosisByIdAction,
  updateDiagnosisAction,
} from "../../../features/actions/diagnosisAction";
import { diagnosisInitVal } from "../../../utils/formikInitValues";
import { diagnosisSchema } from "../../../schemas/patientSchema";
import DiagnosisForm from "./DiagnosisForm";

const UpdateDiagnosis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { diagnosisByIdData, loading } = useSelector(
    (state) => state.diagnosis
  );

  const findDiagnosis = diagnosisByIdData?.result;
  console.log("findDiagnosis", findDiagnosis);

  const formik = useFormik({
    initialValues: findDiagnosis || diagnosisInitVal,
    validationSchema: diagnosisSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      console.log("Submitting form with values:", values);
      dispatch(
        updateDiagnosisAction({
          diagnosisCodeId: findDiagnosis?.diagnosisCodeId,
          ...values,
        })
      );
      navigate("/diagnosis");
    },
  });



  useEffect(() => {
    dispatch(getDiagnosisByIdAction(id));
  }, [dispatch, id]);

  return (
    <>
 <DiagnosisForm formik={formik} />
    </>
  );
};

export default UpdateDiagnosis;
