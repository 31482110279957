import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";

export const getModifiers = createAsyncThunk("getModifiers", async (page) => {
  try {
    const newPage = page?.page+1
    const response = await getData(`${path}/ct-modifierCode?page=${newPage}&pageSize=2`);
    if (response) {
        console.log(response, "getModifiers response");
      return response?.result || [];
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error);
  }
});

export const getModifierBySearchTermAction = createAsyncThunk("getModifier", async ({searchTerm, page}) => {
  console.log(searchTerm, "searchTerm")
  try {
    const response = await getData(
      `${path}/ct-modifierCode/searchModifierCode?searchValue=${searchTerm}&page=${page}&pageSize=5`

    );
    console.log(response, "action Modifier by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});
