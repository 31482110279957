import React, { useEffect } from "react";
import { Box, Grid,useTheme,Typography } from "@mui/material";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import {
  updateUserAction,
  updateUserPasswordAction,
} from "../../../features/actions/userAction";
import CustomField from "../../../components/CustomField";
import { changePasswordSchema } from "../../../schemas/userSchema";
const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false); 
  const [showNewPassword, setShowNewPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const navigate = useNavigate();
  const params = useParams();
  console.log(params, "paramsuser");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: () => {
      if (formik.values.newPassword === formik.values.confirmPassword) {
        dispatch(
          updateUserPasswordAction({ ...formik.values, userId: params?.userId })
        );
        navigate("/users");
      } else {
        alert("Password are not same");
      }
    },
  });
  const handleVisibilityToggle = (fieldName) => {
    if (fieldName === "oldPassword") {
      setShowOldPassword(!showOldPassword);
    }else if (fieldName === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (fieldName === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const theme=useTheme();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} padding={"20px"}>
        <Grid item xs={12}>
          {/* <CustomField
            label={"Old Password"}
            type={"text"}
            isRequired={true}
            touched={formik.touched}
            error={formik.errors}
            value={formik.values.oldPassword}
            name="oldPassword"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          /> */}
             <Typography
                sx={{  
                  fontSize: '1.4rem',
                  fontWeight:'bold',
                  fontFamily: 'Inter, sans-serif',
                  color: theme.palette.Labeltext.primary
                }}
               
              >
               Old Password
              </Typography>
          <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  className="passwordField"
                  id="outlined"
                  type={showOldPassword ? "text" : "password"}
                  name="oldPassword"
                  style={{
                    border: formik.errors.oldPassword && formik.touched.oldPassword ? '2px solid #e84a3f' : '1px solid #6A6A6A',
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  // onClick={handleClick}
                  onClick={() => handleVisibilityToggle("oldPassword")} 
                >
                  {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
          
        </Grid>
        <Grid item xs={12}>
          {/* <CustomField
            label={"New Password"}
            type={"text"}
            value={formik.values.newPassword}
            isRequired={true}
            touched={formik.touched}
            error={formik.errors}
            name="newPassword"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          /> */}
       <Typography
                sx={{  
                  fontSize: '1.4rem',
                  fontWeight:'bold',
                  fontFamily: 'Inter, sans-serif',
                  color: theme.palette.Labeltext.primary
                }}
               
              >
               New Password
              </Typography>
          <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  className="passwordField"
                  id="outlined"
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  style={{
                    border: formik.errors.newPassword && formik.touched.newPassword ? '2px solid #e84a3f' : '1px solid #6A6A6A',
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  // onClick={handleClick}
                  onClick={() => handleVisibilityToggle("newPassword")} 
                >
                  {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
        </Grid>
        <Grid item xs={12}>
          {/* <CustomField
            label={"Confirm Password"}
            type={"text"}
            value={formik.values.confirmPassword}
            isRequired={true}
            touched={formik.touched}
            error={formik.errors}
            name="confirmPassword"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          /> */}
             <Typography
                sx={{  
                  fontSize: '1.4rem',
                  fontWeight:'bold',
                  fontFamily: 'Inter, sans-serif',
                  color: theme.palette.Labeltext.primary
                }}
               
              >
               Confirm Password
              </Typography>
          <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className="passwordField"
                  id="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  style={{
                    border: formik.errors.confirmPassword && formik.touched.confirmPassword ? '2px solid #e84a3f' : '1px solid #6A6A6A',
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  // onClick={handleClick}
                  onClick={() => handleVisibilityToggle("confirmPassword")} 
                >
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
        </Grid>
        <Grid item xs={12}>
          <CustomButton isBlue={true} type="submit" isEdit={true}>
            Update Password
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
