import { createSlice } from "@reduxjs/toolkit";
import { createNewChargePanelAction, getChargePanelAction, updateChargePanelAction } from "../actions/chargePanelAction";
import { getChargePanelByIdAction } from "../actions/chargePanelAction";
import { toast } from "react-toastify";

const initialState = {
  getChargePanelData: [],
  createChargePanel:{},
  chargePanelById:{},
  error: null,
  loading: false,
};

const chargePanelSlice = createSlice({
  name: "chargePanel",
  initialState: initialState,

  extraReducers: {
    [getChargePanelAction.pending]: (state) => {
      state.loading = true;
    },
    [getChargePanelAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getChargePanelData = action.payload;
    },
    [getChargePanelAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getChargePanelByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getChargePanelByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.chargePanelById = action.payload;
      console.log(state.chargePanelById,"chargepanelbyId")
      console.log(action.paylaod, "charge panel slice get payload");
    },
    [getChargePanelByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createNewChargePanelAction.pending]: (state) => {
      state.loading = true;
    },
    [createNewChargePanelAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createChargePanel = action.payload;
      toast.success("Charge Panel Created Successfully!")
    },
    [createNewChargePanelAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Charge Panel Creation Failed")

    },
    [updateChargePanelAction.pending]: (state) => {
      state.loading = true;
    },
    [updateChargePanelAction.fulfilled]: (state, action) => {
      state.loading = false;
      // const findPanelIndex = state.getChargePanelData.result?.findIndex(
      //   (val) => val.chargerPanelId === action.payload.chargerPanelId
      // );
      // if (findPanelIndex !== -1) {
      //   state.getChargePanelData.result[findPanelIndex] = {
      //     ...state.getChargePanelData.result[findPanelIndex],
      //     ...action.payload,
      //   };
      // }
      // toast.success("Remittance Updated Successfully!");
    },
    [updateChargePanelAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      // toast.error("Remittance updation failed :(");
    },
  },
});

export default chargePanelSlice.reducer;
