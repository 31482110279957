import { createSlice } from "@reduxjs/toolkit";
import {
  getFacilityAction,
  newFacilityAction,
  getFacilityByIdAction,
  updateFacilityAction,
} from "../actions/facilityAction";
import { toast } from "react-toastify";

const initialState = {
  getFacilityData: [],
  createFacilityData: {},
  facilityByIdData : {},
  error: null,
  loading: false,
};
const facilitySlice = createSlice({
  name: "facility",
  initialState: initialState,

  extraReducers: {
    [getFacilityAction.pending]: (state) => {
      state.loading = true;
    },
    [getFacilityAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFacilityData = action.payload;
      console.log(state.getFacilityData, "facility slice");
    },
    [getFacilityAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "facilityErr");
    },
    [newFacilityAction.pending]: (state) => {
      state.loading = true;
    },
    [newFacilityAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createFacilityData = action.payload;
      toast.success("Facility has been created successfully!");
    },
    [newFacilityAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "facilityErr");
      toast.error("Facility Creation Failed.");
    },
    [getFacilityByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getFacilityByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.facilityByIdData = action.payload;
      console.log(action.paylaod, "facility slice get payload");
    },
    [getFacilityByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateFacilityAction.pending]: (state) => {
      state.loading = true;
    },
    [updateFacilityAction.fulfilled]: (state, action) => {
      state.loading = false;
      // const updateFacilityIndex = state.getFacilityData.result?.findIndex(
      //   (item) => item.facilityId === action.payload.facilityId
      // );
      // if (updateFacilityIndex !== -1) {
      //   state.getFacilityData.result[updateFacilityIndex] = {
      //     ...state.getPractices.result[updateFacilityIndex],
      //     ...action.payload,
      //   };
      // }
      toast.success("Facility Updated Successfully!");
    },
    [updateFacilityAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "facilityErr");
      toast.error("Facility Updation Failed.");
    },

  },
});

export default facilitySlice.reducer;
