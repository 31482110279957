import { createSlice } from "@reduxjs/toolkit";
import { getGuarantorAction } from "../actions/guarantorAction";

const initialState = {
  getGuarantorData: [],
  error: null,
  loading: {
    getGuarantorLoading: false,
  },
};
const guarantorSlice = createSlice({
  name: "guarantor",
  initialState: initialState,

  extraReducers: {
    [getGuarantorAction.pending]: (state) => {
      state.loading.getGuarantorLoading = true;
    },
    [getGuarantorAction.fulfilled]: (state, action) => {
      state.loading.getGuarantorLoading = false;
      state.getGuarantorData = action.payload;
    },
    [getGuarantorAction.rejected]: (state, action) => {
      state.loading.getGuarantorLoading = false;
      state.error = action.payload;
    },
  },
});
export default guarantorSlice.reducer

