import React from 'react'
import CompanyInfo from './CompanyInfo'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { companyInitVals } from '../../../utils/formikInitValues'
import { getCompanyByIdAction,updateCompanyAction} from '../../../features/actions/companiesAction'
import { companySchema } from '../../../schemas/userSchema'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const UpdateCompany = () => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {companyCode} = useParams();
  const {companyByIdData, loading } = useSelector((state) => state.companies);


  const formik=useFormik({
    initialValues:companyByIdData?.result || companyInitVals,
    validationSchema: companySchema,
    enableReinitialize: true,
    onSubmit: (values,action) => {
      console.log("Submitting form with values:", values); 
      dispatch(
        updateCompanyAction({
          companyCode: companyByIdData?.companyCode,
          ...values,
        })
      ).then((response) => {
        console.log("Update response:", response);
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/company");
        } else {
          console.error("Update failed:", response.error.message);
        }
      });
    },
  })

  useEffect(() => {
    dispatch(getCompanyByIdAction(companyCode));
  }, []);
  return (
    <>
      <CompanyInfo formik={formik}/>
    </>
  )
}

export default UpdateCompany

