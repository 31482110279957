import React from "react";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  useTheme,
  FormControl,
  Paper,
} from "@mui/material";
import { useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomDialog from "../../../components/CustomDialog";
import TextArea from "../../../components/TextArea";
import CheckBox from "../../../components/CheckBox";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const RevenueInfo = ({ formik }) => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  console.log(formik.values, "formikValues");
  const theme = useTheme();
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/revenues");
  };
  return (
    <>
      <form id="revenueForm" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            padding: "15px",
            gap: "10px",
          }}
        >
          <CustomButton
            formId="revenueForm"
            type="submit"
            isBlue={true}
            isSave={true}
          >
            Save
          </CustomButton>

          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
          {formik.values.revenueCodeId && (
            <Box sx={{ marginLeft: "auto" }}>
              <CheckBox
                CheckedValue={!formik.values.isActive}
                handleChange={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
                label="Make this Revenue inactive"
              />
            </Box>
          )}
        </Box>
        <Paper elevation={3} style={{ padding: 20, margin: 15 }}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <CustomField
                type="text"
                label="Code"
                value={formik.values.revenueCode}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                name="revenueCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item md={6}>
              <CustomField
                type="number"
                name="revenueCodePrice"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                label="Price"
                value={formik.values.revenueCodePrice}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item md={12}>
              <CheckBox
                label="Exclude this code from duplicate service checks"
                CheckedValue={formik.values.isDuplicateService === true}
                name="isDuplicateService"
                handleChange={() =>
                  formik.setFieldValue(
                    "isDuplicateService",
                    !formik.values.isDuplicateService
                  )
                }
              />
            </Grid>


            <Grid item md={6}>
              <CustomField
                type="text"
                name="revenueCodeDescription"
                label="Description"
                value={formik.values.revenueCodeDescription}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {formik.errors["revenueCodeDescription"] &&
                formik.touched["revenueCodeDescription"] && (
                  <div
                    style={{
                      color: theme.palette.errorFieldsBorder.primary,
                      border: `2px solid ${theme.palette.errorFieldsBorder.primary}`,
                      fontSize: "1.3rem",
                      marginTop: "8px",
                      display: "block",
                    }}
                  >
                    {formik.errors["revenueCodeDescription"]}
                  </div>
                )}
            </Grid>

            <Grid item md={6}>
              <CustomField
                type="text"
                name="statementDescription"
                label="Statement Description"
                value={formik.values.statementDescription}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
        </Paper>

        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
   
      </form>
    </>
  );
};

export default RevenueInfo;
