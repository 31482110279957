import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";

const CustomDataGrid = ({
  rows,
  page,
  handlePageChange,
  pageSize,
  columns,
  handleCellClick,
  paginationDetail,
}) => {
  const theme = useTheme();
  const reducedCellHeight = 35;
console.log(page);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{
        "& .header-bg": {
          background: theme.palette.dataGridHeading.primary,
          color: theme.palette.textMainHeading.primary,
          fontSize: "14px",
        },
        "& .cell-content": {
          fontSize: "1.3rem",
        },
        "& .MuiTablePagination-displayedRows": {
          fontSize: "1.3rem",
        },
        "& .MuiTablePagination-actions": {
          "& svg": {
            fontSize: "1.8rem",
            color: theme.palette.textMainHeading.primary,
            marginBottom: "8px",
          },
        },
        "& .MuiTablePagination-root .MuiTablePagination-selectLabel, & .MuiTablePagination-root .MuiTablePagination-select": {
          display: "none",
        },
      }}
      pagination
      pageSize={paginationDetail?.result?.pageSize}
      paginationMode="server"
      page={page}
      rowCount={paginationDetail?.result?.totalCount}
      onPageChange={handlePageChange}
      showCellRightBorder={true}
      autoHeight
      rowHeight={reducedCellHeight}
      disableSelectionOnClick
      disableExtendRowFullWidth
      onCellClick={(params) => {
        handleCellClick(params.row);
      }}
      components={{
        NoRowsOverlay: () => (
          <h3
            style={{
              width: "100%",
              textAlign: "center",
              padding: "16px",
            }}
          >
            {rows?.length === 0 && "No Data Is Added"}
          </h3>
        ),
      }}
    />
  );
};

export default CustomDataGrid;



