import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPreview from "../../../layout/CustomPreview";
import CustomModal from "../../../components/CustomModal";
import SearchICDCodeMaster from "./SearchICDCodeMaster";
import { icdProcedureCol } from "../../../utils/gridCols";

import {
  getICDProcedureCodeAction,
  getICDProcedureCodesBySearchTermAction,
} from "../../../features/actions/icdProcedureAction";
const ICDProcedure = () => {
  const [openICDProcedureModal, setOpenICDProcedureModal] = useState(false);
  const { getIcdProcedureData, loading } = useSelector(
    (state) => state.icdProcedure
  );
  const navigate = useNavigate();
  // const icdProcedureRows =  getIcdProcedureData?.icdProcedure?.length > 0
  //     ? getIcdProcedureData.icdProcedure?.map((row) => ({
  //         id: row.icdProcedureCodesId,
  //         ...row,
  //       }))
  //     : [];
  const icdProcedureRows =
  getIcdProcedureData?.result && getIcdProcedureData.result?.icdProcedure?.length > 0
    ? getIcdProcedureData.result?.icdProcedure?.map((el) => ({
        id: el.icdProcedureCodesId,
        ...el,
      }))
    : [];


  return (
    <>
      <Box>
        <CustomPreview
          rows={icdProcedureRows}
          columns={icdProcedureCol}
          moduleHeading={"ICD Procedure Codes"}
          searchLabel="Search by ICD Code and Description"
          handleGetAll={getICDProcedureCodeAction}
          handleBySearch={getICDProcedureCodesBySearchTermAction}
          paginationDetail={getIcdProcedureData}
          loading={loading}
          handleCell={(params) =>
            navigate(`/icd-procedures/update/${params?.id}`)
          }
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CustomButton
              isBlue={true}
              isAdd={true}
              handleClick={() => navigate("/icd-procedures/new")}
            >
              ICD Procedure Codes
            </CustomButton>
            <CustomButton
              isAdd={true}
              handleClick={() => setOpenICDProcedureModal(true)}
            >
              Add From Master List
            </CustomButton>
          </Stack>
        </CustomPreview>

        {/* Custom Modal */}
        <CustomModal
          open={openICDProcedureModal}
          handleClose={() => setOpenICDProcedureModal(false)}
        >
          <SearchICDCodeMaster
            handleClose={() => setOpenICDProcedureModal(false)}
          />
        </CustomModal>
      </Box>
    </>
  );
};

export default ICDProcedure;
