// import { Box } from "@mui/material";
// import CustomButton from "../components/CustomButton";
// import { useNavigate } from "react-router-dom";
import { Done } from "@mui/icons-material";
// const navigate = useNavigate();
export const practiceCols = [
  {
    field: "practiceName",
    headerName: "Practice Name",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
    minWidth: 160,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    // field: "sequenceNo",
    // flex: 1,
    field: "practiceId",
    flex: 1,
    minWidth: 160,
    headerName: "Practice Id",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "practiceNpi",
    flex: 1,
    minWidth: 160,
    headerName: "NPI",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// patients
export const patientCols = [
  {
    // field: "accountNo",
    // headerName: "Account #",
    field: "patientId",
    headerName: "Patient Id",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    cellClassName: "cell-content",
    headerClassName: "header-bg",
  },
  {
    // field: "practiceSequenceNo",
    // headerName: "Practice Sequence",
    field: "practiceId",
    headerName: "Practice Id",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    cellClassName: "cell-content",
    headerClassName: "header-bg",
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 230,
    flex: 1,
    headerAlign: "left",
    align: "left",
    cellClassName: "cell-content",
    headerClassName: "header-bg",
    valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
  },

  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    minWidth: 100,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredName",
    headerName: "Insured",
    minWidth: 230,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "totalAmountUnpaid",
    headerName: "Balance Due Patient",
    minWidth: 160,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) => (params.value === null ? "0" : params.value),
  },
  {
    field: "accountType",
    headerName: "Account Type",
    minWidth: 180,
    flex: 1,
    align: "left",
    headerAlign: "left",
    cursor: "pointer",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// claim
export const claimCols = [
  {
    // field: "claimNumber",
    // headerName: "Claim ID",
    field: "claimId",
    headerName: "Claim ID",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patientName",
    headerName: "Patient",
    minWidth: 180,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.patientFirstName} ${params.row.patientLastName}`,
  },
  {
    field: "fromDate",
    headerName: "DOS",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "referenceNo",
    headerName: "Reference No",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },

  {
    field: "claimType",
    headerName: "Claim Type",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

//claimBatchPrintColumn

export const claimBatchPrintColumn = [
  {
    // field: "claimNumber",
    // headerName: "Claim No",
    field: "claimId",
    headerName: "Claim ID",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patientAccountNumber",
    headerName: "Patient Account No",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patientName",
    headerName: "Patient Name",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerName",
    headerName: "Payer Name",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerSeqNumber",
    headerName: "Payer Seq No",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const patientColsForPayment = [
  {
    // field: "accountNo",
    // headerName: "Account #",
    field: "patientId",
    headerName: "Patient Id",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    cellClassName: "cell-content",
    headerClassName: "header-bg",
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 180,
    flex: 1,
    headerAlign: "left",
    align: "left",
    cellClassName: "cell-content",
    headerClassName: "header-bg",
    valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
  },

  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    minWidth: 100,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredName",
    headerName: "Insured",
    minWidth: 180,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "totalAmountUnpaid",
    headerName: "Balance Due Patient",
    minWidth: 160,
    flex: 1,
    align: "left",
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "accountType",
    headerName: "Account Type",
    minWidth: 180,
    flex: 1,
    align: "left",
    headerAlign: "left",
    cursor: "pointer",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// provider
export const providerCols = [
  {
    field: "name",
    headerName: "Provider Name",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) => {
      const { providerFirstName, providerLastName } = params.row;
      return `${providerFirstName} ${providerLastName ? providerLastName : ""}`;
    },
  },
  {
    field: "providerNPINo",
    headerName: "NPI",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    // field: "providerSequenceNo",
    field: "providerId",
    minWidth: 100,
    flex: 1,
    headerName: "provider Id",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "isActive",
    minWidth: 100,
    flex: 1,
    headerName: "Inactive",
    headerAlign: "left",
    align: "left",
    valueGetter: (params) => (!params.value ? "Active" : "Inactive"),
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// facility
export const facilityCols = [
  {
    field: "facilityName",
    headerName: "Facility Name",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "facilityId",
    headerName: "Facility Id",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },

  {
    field: "taxonomySpecialty",
    headerName: "Taxonomy",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "phoneNumber",
    headerName: "Ph #",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// remittance
export const remittanceCols = [
  {
    field: "remittanceCodeName",
    headerName: "Code",
    minWidth: 10,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "remittanceType",
    minWidth: 10,
    flex: 1,
    headerName: "Type",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "remittanceInfoLevel",
    minWidth: 100,
    flex: 3,
    headerName: "Informational Level",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "reportDescription",
    minWidth: 100,
    flex: 2,
    headerName: "Description",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// procedure codes
export const procedureCodesCols = [
  {
    field: "procedureCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "procedureDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "price",
    headerName: "Price",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// diagnosis
export const diagnosisCodesCols = [
  {
    field: "diagnosisCode",
    headerName: "Diagnosis Code",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "codeType",
    headerName: "Code Type",
    flex: 4,
    minWidth: 180,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "diagnosisDescription",
    headerName: "Description",
    flex: 4,
    minWidth: 180,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  // {
  //   field: "isActive",
  //   headerName: "Inactive",
  //   flex: 2,
  //   minWidth: 100,
  //   headerAlign: "left",
  //   align: "left",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  // },
];

// payment plans
export const paymentPlanCols = [
  {
    field: "dos",
    headerName: "DOS",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "claimNo",
    headerName: "Claim #",
    flex: 4,
    minWidth: 180,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "cpt",
    headerName: "CPT",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "balance",
    headerName: "Balance",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },

  {
    field: "practice",
    headerName: "Practice",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// adjustment codes cols
export const adjustmentCodeCols = [
  {
    field: "adjustmentCode",
    headerName: "Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "adjustmentCodeType",
    headerName: "Code Type",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// revenues cols
export const revenueCodesCols = [
  {
    field: "revenueCode",
    headerName: "Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "revenueCodeDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "revenueCodePrice",
    minWidth: 100,
    flex: 1,
    headerName: "Price",
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// inventory codes
export const inventoryCodeCols = [
  {
    field: "code",
    headerName: "Code",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "codeDescription",
    headerName: "Code Description",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "billingDescription",
    headerName: "Billing Description",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// all users columns
export const userCols = [
  {
    field: "username",
    headerName: "Username",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "title",
    headerName: "Title",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "userType",
    headerName: "Type",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  // {
  //   field: "",
  //   headerName: "Actions",
  //   flex: 2,
  //   minWidth: 350,
  //   headerAlign: "left",
  //   align: "left",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  //   renderCell: (params) => (
  //     <Box
  //       sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}
  //     >
  //       <CustomButton
  //         handleClick={() => {
  //           if (params?.id) {
  //             navigate(`/updateuser/${params?.id}`);
  //           }
  //         }}
  //       >
  //         Edit Profile
  //       </CustomButton>
  //       <CustomButton
  //         handleClick={() => {
  //           if (params?.id) {
  //             navigate(`/changepassword/${params?.id}`);
  //           }
  //         }}
  //         isBlue={true}
  //       >
  //         Change Password
  //       </CustomButton>
  //     </Box>
  //   ),
  // },
];
// patient claim details
export const patientClaimDetailCols = [
  {
    // field: "patientAccountNo",
    // headerName: "Account #",
    field: "patientId",
    headerName: "Patient Id",
    flex: 1,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.patientFirstName} ${params.row.patientLastName}`,
  },

  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    flex: 1,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "accountType",
    headerName: "Account Type",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    flex: 1,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// payer columns
export const payerCol = [
  {
    field: "insuranceDescriptionId",
    headerName: "ID",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuranceDescriptionName",
    headerName: "Insurance Name",
    flex: 2,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "planName",
    headerName: "Plan Name",
    flex: 2,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "claimOfficeAddress",
    headerName: "Payer Address",
    flex: 3,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuranceTypeName",
    headerName: "Insurance Type",
    flex: 2,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// payer insurance cols
export const insurancePayer = [
  {
    field: "insuranceDescriptionName",
    headerName: "Payer Name",
    flex: 2,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "planName",
    headerName: "Plan Name",
    flex: 2,
    minWidth: 250,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "referenceNo",
    headerName: "Reference #",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuranceAddressDtos[0].address",
    headerName: "Payer Address",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const primaryInsPayerCol = [
  {
    field: "payerName",
    headerName: "Payer Name",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "planName",
    headerName: "Plan Name",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerReferenceNo",
    headerName: "Reference #",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerAddress",
    headerName: "Payer Address",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// payer info
export const payerInfoCols = [
  {
    field: "payerInfoSequenceNumber",
    headerName: "Sequence #",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerId",
    headerName: "Payer ID",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payerInfoPolicyType",
    headerName: "Policy Type",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// referring provider columns
export const refProvider = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 250,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.renderingProviderFirstName} ${params.row.renderingProviderLastName}`,
  },
  {
    field: "renderingProviderNPINo",
    headerName: "NPI",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "renderingProviderSequenceNo",
    headerName: "Seq #",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const diagnosisCol = [
  {
    field: "codeType",
    headerName: "Code Type",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "diagnosisDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "diagnosisCode",
    headerName: "Description",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// referring provider
export const referringProviderCol = [
  {
    field: "referringProviderFirstName",
    headerName: "First Name",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "referringProviderLastName",
    headerName: "Last Name",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "referringProviderTaxonomySpecialtyCode",
    headerName: "Taxonomy Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "referringProviderId",
    headerName: "Referring Provider Id",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  // {
  //   field: "referringProviderSequenceNo",
  //   headerName: "Sequence No",
  //   minWidth: 100,
  //   flex: 1,
  //   headerAlign: "left",
  //   align: "left",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  // },
];

// hcpcs master list cols
export const hcpcsMasterCols = [
  {
    field: "isAdded",
    headerName: "Added",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    renderCell: (params) => <span>{params.value ? <Done /> : ""}</span>,
  },
  {
    field: "hcpcsCode",
    headerName: "Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "procedureDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// cpt master list code
export const cptMasterCols = [
  {
    field: "isAdded",
    headerName: "Added",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    renderCell: (params) => <span>{params.value ? <Done /> : ""}</span>,
  },
  {
    field: "cptCode",
    headerName: "Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "procedureDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const tosCodesCols = [
  {
    field: "tosCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "tosDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
export const posCodesCols = [
  {
    field: "posCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "posDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
export const renderingProvider = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 250,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.renderingProviderFirstName} ${params.row.renderingProviderLastName}`,
  },
  {
    field: "renderingProviderNPINo",
    headerName: "NPI",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "renderingProviderSequenceNo",
    headerName: "Seq #",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// billing provider
export const billingProviderCols = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 250,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.billingProviderFirstName} ${params.row.billingProviderLastName}`,
  },
  {
    field: "billingProviderNPINo",
    headerName: "NPI",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "billingProviderSequenceNo",
    headerName: "Seq #",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const procedureModal = [
  {
    field: "procedureCode",
    headerName: "Procedure Code",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
  },
  {
    field: "procedureDescription",
    headerName: "Procedure Description",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
  },
  {
    field: "price",
    headerName: "Amount",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
  },
  {
    field: "inactive",
    headerName: "Inactive",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
  },
];

export const icdProcedureCol = [
  {
    field: "icdCode",
    headerName: "ICD Code",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "left",
  },
  {
    field: "icdCodeType",
    headerName: "ICD Code Type",
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "left",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 160,
    flex: 3,
    headerAlign: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    filterable: true,
    align: "left",
  },
];

// charge panel cols
export const chargePanelCols = [
  {
    field: "chargePanelCode",
    headerName: "Code",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "chargePanelTitle",
    headerName: "Title",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "chargePanelDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// charge panel procedures
// export const chargePanelProcedureCols = [
//   {
//     field: "procedureCode",
//     headerName: "Code",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "posCode",
//     headerName: "POS",
//     minWidth: 100,
//     flex: 1,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "tosCode",
//     headerName: "TOS",
//     minWidth: 100,
//     flex: 1,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "modifierOption",
//     headerName: "Modifier Options",
//     minWidth: 100,
//     flex: 3,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "mod_Code1",
//     headerName: "Modifier 1",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "mod_Code2",
//     headerName: "Modifier 2",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "mod_Code3",
//     headerName: "Modifier 3",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "mod_Code4",
//     headerName: "Modifier 4",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "priceOption",
//     headerName: "Price Option",
//     minWidth: 100,
//     flex: 3,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "unitPrice",
//     headerName: "Price",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "units",
//     headerName: "Units",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "total",
//     headerName: "Total",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",
//   },
//   {
//     field: "",
//     headerName: "Action",
//     minWidth: 100,
//     flex: 2,
//     headerAlign: "left",
//     align: "left",
//     headerClassName: "header-bg",
//     cellClassName: "cell-content",

//   },
// ];
// searched Edi data cols
export const searchedEraCols = [
  {
    field: "applied",
    headerName: "Applied",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    renderCell: (params) => <span>{params.value ? <Done /> : ""}</span>,
  },
  {
    field: "payer",
    headerName: "Payer",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "rptDate",
    headerName: "Rpt Date",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "submitter",
    headerName: "Submitter #",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "fileName",
    headerName: "File Name",
    minWidth: 250,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "checks",
    headerName: "Checks",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payments",
    headerName: "Payments",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "checkDate",
    headerName: "Check Date",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// era detail cols
export const eraDetailCols = [
  {
    field: "checkNumber",
    headerName: "Check #",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patient",
    headerName: "Patient",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "account",
    headerName: "Account",
    minWidth: 150,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "claim",
    headerName: "Claim",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "claimStatus",
    headerName: "Claim Status",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "tcn",
    headerName: "TCN",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "dos",
    headerName: "DOS",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "billed",
    headerName: "Billed",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "allowed",
    headerName: "Allowed",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "paid",
    headerName: "Paid",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "unapplied",
    headerName: "Unapplied",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "adjusted",
    headerName: "Adjusted",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "unpaid",
    headerName: "Unpaid",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "balance",
    headerName: "Balance",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "payer",
    headerName: "Payer",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const newPaymentPlanCols = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "date",
    headerName: "Due Date",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },

  {
    field: "installment",
    headerName: "Amount",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 200,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  // {
  //   // field: "account",
  //   headerName: "Delete",
  //   minWidth: 150,
  //   flex: 2,
  //   headerAlign: "left",
  //   align: "left",
  //   headerClassName: "header-bg",
  //   cellClassName: "cell-content",
  // },
];

export const patientMultipleClaimCols = [
  {
    // field: "claimNumber",
    // headerName: "Claim #",
    field: "claimId",
    headerName: "Claim ID",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "name",
    headerName: "Patient",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.patientFirstName} ${params.row.patientLastName}`,
  },
  {
    field: "practiceAddress",
    headerName: "Address",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

// batch searched data
export const batchStateSearchedCols = [
  {
    field: "accountNum",
    headerName: "Patient #",
    minWidth: 150,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patientName",
    headerName: "Patient",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "statementAmount",
    headerName: "Stmt Amount",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "statementType",
    headerName: "Stmt Type",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "timeSent",
    headerName: "Time Sent",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "mailTo",
    headerName: "Mail To",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "lastStatementDate",
    headerName: "Last Stmt Date",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    // field: "claimNumber",
    // headerName: "Claim #",
    field: "claimId",
    headerName: "Claim ID",
    minWidth: 100,
    flex: 1,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
export const modifierCol = [
  {
    field: "modifierCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
  },
  {
    field: "modifierDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
  },
];

export const claimChargesHistoryCol = [
  {
    headerName: "Charges Id",
  },
  {
    headerName: "Procedure Code",
  },
  {
    headerName: "Date",
  },
  {
    headerName: "User",
  },
  {
    headerName: "Bill To",
  },
  {
    headerName: "Amount",
  },
];
// followup Management Cols
export const followUpManagementCols = [
  {
    // field: "claimnumber",
    // headerName: "Claim Number",
    field: "claimId",
    headerName: "Claim ID",
    minWidth: 200,
    flex: 2,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "patientName",
    headerName: "Patient Name",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "dos",
    headerName: "Dos",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "currentPayer",
    headerName: "Current Payer",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "lastBilledDate",
    headerName: "Last Billed date",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "balance",
    headerName: "Balance",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "claimFollowupDate",
    headerName: "Claim Follow Up Date",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "lastNote",
    headerName: "Last Note",
    minWidth: 200,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const feeSchedulesCols = [
  {
    field: "feeSchedulesName",
    headerName: "Name",
    flex: 2,
    minWidth: 200,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "effectiveFrom",
    headerName: "Effective From",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "effectiveTo",
    headerName: "Effective To",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "sequenceNo",
    headerName: "Unique Id",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "isActive",
    headerName: "Inactive",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    renderCell: (params) => {
      return params.row.isActive ? (
        ""
      ) : (
        <Done fontSize="large" color="success" />
      );
    },
  },
];

// insuredCols
export const insuredCol = [
  {
    field: "insuredFirstName",
    headerName: "First Name",
    flex: 1,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredLastName",
    headerName: "Last Name",
    flex: 1,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredZipCode",
    headerName: "Insured Zip Code",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredAddress",
    headerName: "Insured Address",
    flex: 2,
    minWidth: 150,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "insuredStateName",
    headerName: "State",
    flex: 2,
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const companyCols = [
  {
    // field: "companyCode",
    // headerName: "Code",
    field: "companyId",
    headerName: "Code Id",
    minWidth: 100,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "companyName",
    headerName: "Company",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "website",
    headerName: "Website",
    flex: 1,
    minWidth: 160,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
// guarantor
export const guarantorCols = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    valueGetter: (params) =>
      `${params.row.guarantorFirstName} ${params.row.guarantorLastName}`,
  },
  {
    field: "guarantorDOB",
    headerName: "DOB",
    minWidth: 150,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "guarantorAddress",
    headerName: "Address",
    minWidth: 300,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "guarantorStateName",
    headerName: "State",
    minWidth: 150,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
//  employer columns
export const emplyerCols = [
  {
    field: "employerName",
    headerName: "Name",
    minWidth: 200,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "employerAddress",
    headerName: "Address",
    minWidth: 250,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "employerStateName",
    headerName: "State",
    minWidth: 150,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "employerCityName",
    headerName: "City",
    minWidth: 150,
    headerAlign: "left",
    filterable: true,
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const posCols = [
  {
    field: "posCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 2,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "posDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];

export const tosCodeCols = [
  {
    field: "tosCode",
    headerName: "Codes",
    minWidth: 100,
    flex: 2,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "tosDescription",
    headerName: "Description",
    minWidth: 100,
    flex: 3,
    headerAlign: "left",
    align: "left",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
