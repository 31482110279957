import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import CustomSearchField from "../../../components/CustomSearchField";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import CustomButton from "../../../components/CustomButton";
import TextArea from "../../../components/TextArea";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { useSelector } from "react-redux";
import CustomDatePicker from "./../../../components/CustomDatePicker";
import { handleIsActive } from "../../../utils/customFunctions";
const RemittanceInfo = ({ formik }) => {
  const navigate = useNavigate();
  const [remittanceTypeOpt, setRemittanceTypeOpt] = useState([]);
  const [categoryCodeOpts, setCategoryCodeOpts] = useState([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { loading } = useSelector((state) => state.remittance);
  const [informationLevelOpt, setInformationLevelOpt] = useState([]);
  const [detailCheckOpt, setDetailCheckOpt] = useState([]);
  // Define data fetching URLs
  const dataFetchUrls = {
    remittanceType: `${path}/ct-remittanceType`,
    informationLevel: `${path}/ct-remittanceInfoLevel`,
    detailChecks: `${path}/ct-remittanceCodeDetail`,
    categoryCode: `${path}/ct-categoryCode`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.remittanceType, setRemittanceTypeOpt);
    fetchDataOptions(dataFetchUrls.informationLevel, setInformationLevelOpt);
    fetchDataOptions(dataFetchUrls.detailChecks, setDetailCheckOpt);
    fetchDataOptions(dataFetchUrls.categoryCode, setCategoryCodeOpts);
  }, []);
  // handle checkbox
  const handleCheckboxChange = (checkVal) => {
    const previosDetCodes = [...formik.values?.remittanceCodeDetailDtos];
    const findVal = previosDetCodes?.findIndex(
      (val) => val.remittanceCodeDetailId === checkVal.remittanceCodeDetailId
    );
    if (findVal !== -1) {
      previosDetCodes.splice(findVal, 1);
    } else {
      previosDetCodes.push(checkVal);
    }

    formik.setFieldValue("remittanceCodeDetailDtos", previosDetCodes);
  };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/remittance");
  };
  return (
    <>
      <form id="remittanceForm" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
            padding: "10px",
            alignItems: "flex-end",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
            <CustomButton
              type="submit"
              formId="remittanceForm"
              isBlue={true}
              isSave={true}
            >
              {loading ? "Saving..." : "Save"}
            </CustomButton>
            <CustomButton isCancel={true} handleClick={handleCancel}>
              Cancel
            </CustomButton>
          </Stack>

          {formik.values.remittanceCodeId && (
            <Stack>
              <CheckBox
                CheckedValue={!formik.values.isActive}
                handleChange={
                  () => handleIsActive(formik)
                  // formik.setFieldValue("isActive", !formik.values.isActive)
                }
                label="Make this remittance inactive"
              />
            </Stack>
          )}
        </Box>
        <Paper elevation={3} style={{ padding: 20, margin: 15 }}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <CustomField
                type="text"
                label="Code"
                value={formik.values.remittanceCodeName}
                name="remittanceCodeName"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
              />
            </Grid>
            <Grid item md={4}>
              <CustomSelectBox2
                label="Type"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                value={formik.values.remittanceTypeId}
                name="remittanceTypeId"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                dropdownOptions={remittanceTypeOpt.map((opt) => ({
                  value: opt.remittanceType,
                  id: opt.remittanceTypeId,
                }))}
              />
            </Grid>
            <Grid item md={4}>
              <CustomSelectBox2
                value={formik.values.remittanceInfoLevelId}
                name="remittanceInfoLevelId"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
                dropdownOptions={informationLevelOpt.map((opt) => ({
                  value: opt.remittanceInfoLevel,
                  id: opt.remittenceInfoLevelId,
                }))}
                label="Information Level"
              />
            </Grid>
            {formik.values.remittanceTypeId === 2 && (
              <>
                <Grid item md={4}>
                  <CustomSelectBox2
                    value={formik.values.categoryCodeId}
                    name="categoryCodeId"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    formik={formik}
                    dropdownOptions={categoryCodeOpts?.map((opt) => ({
                      value: opt.categoryCodeName,
                      id: opt.categoryCodeId,
                    }))}
                    label="Code Category"
                  />
                </Grid>
                <Grid item md={4}>
                  <CustomDatePicker
                    dateValue={formik.values.effectiveDateFrom}
                    dateLabel={"Effective From"}
                    formik={formik}
                    name={"effectiveDateFrom"}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                    isFromTo={true}
                  />
                </Grid>
                <Grid item md={4}>
                  <CustomDatePicker
                    dateValue={formik.values.effectiveDateTo}
                    dateLabel={"Effective To"}
                    formik={formik}
                    name={"effectiveDateTo"}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                    isFromTo={true}
                  />
                </Grid>
              </>
            )}
            <Grid item md={12}>
              <Stack direction="row" gap={"15px"}>
                {detailCheckOpt.map((opt) => (
                  <CheckBox
                    label={opt.remittanceCodeDetail}
                    key={opt.remittanceCodeDetailId}
                    name="remittanceCodeDetailId"
                    CheckedValue={formik.values?.remittanceCodeDetailDtos.some(
                      (v) =>
                        v?.remittanceCodeDetailId ===
                        opt?.remittanceCodeDetailId
                    )}
                    handleChange={() => handleCheckboxChange(opt)}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item md={6}>
              <CustomField
                label="Report Description"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                type="text"
                value={formik.values.reportDescription}
                name="reportDescription"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <CustomField
                label="Long Description"
                type="text"
                value={formik.values.longDescription}
                name="longDescription"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </Grid>
            {/* <Grid item md={12}>
              <Typography component="h3">Memoline</Typography>
            </Grid>

            <Grid item md={12}>
              <Stack direction={"row"} gap={"15px"}>
                <CheckBox
                  CheckedValue={formik.values.isMemoline === true}
                  label="Use Memoline on Activity and Statements"
                  name="isMemoline"
                  handleChange={() =>
                    formik.setFieldValue(
                      "isMemoline",
                      !formik.values.isMemoline
                    )
                  }
                />
                <CheckBox
                  label="Include the amount in the memoline"
                  CheckedValue={formik.values.isMemolineAmount === true}
                  name="isMemolineAmount"
                  handleChange={() =>
                    formik.setFieldValue(
                      "isMemolineAmount",
                      !formik.values.isMemolineAmount
                    )
                  }
                />
              </Stack>
            </Grid>
            <Grid item md={12}>
              <CustomSearchField />
            </Grid> */}
          </Grid>
        </Paper>
        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
      </form>
    </>
  );
};

export default RemittanceInfo;
