import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import SearchNpi from "../practices/npi/SearchNpi";
import Practice from "../practices/Practice";
import ProviderModal from "../../../components/ProviderModal";
import { Paper } from "@mui/material";
import { handleIsActive } from "../../../utils/customFunctions";
import {
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  Stack,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomAccordion from "../../../components/CustomAccordion";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { getData } from "../../../config/axiosFunctions";
import CustomDatePicker from "../../../components/CustomDatePicker";
import path from "../../../config/apiUrl";
import CustomPreview from "../../../layout/CustomPreview";
import Loading from "./../../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { practiceCols, providerCols } from "../../../utils/gridCols";
import {
  getPracticeAction,
  getPracticeBySearchTermAction,
} from "../../../features/actions/practiceAction";
import {
  getProviderAction,
  getProviderBySearchTermAction,
  getProviderSeqAction,
} from "../../../features/actions/providerAction";
import CustomDialog from "../../../components/CustomDialog";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CheckBox from "../../../components/CheckBox";
import { Done } from "@mui/icons-material";
import CustomDataGrid from "../../../components/CustomDataGrid";

const ProviderInfo = ({ formik }) => {
  console.log(formik?.errors);

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [taxonomyListModal, setTaxonomyListModal] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [practiceModal, setPracticeModal] = useState(false);
  const [procedureModal, setProcedureModal] = useState(false);
  const [billingProviderModal, setBillingProviderModal] = useState(false);
  const [elibilityProviderModal, setEligibilityProviderModal] = useState(false);
  const [billAsOpt, setBillAsOpt] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [billClaimsOpt, setBillClaimsOpt] = useState([]);
  const [claimModeTypes, setClaimModeTypes] = useState([]);
  const [providerTypes, setProviderType] = useState([]);
  const [idNumTypes, setIdNumTypes] = useState([]);
  const [npiResultData, setNpiResultData] = useState([]);
  const [openNpiPaper, setOpenNpiPaper] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // use selectors
  const { getPractices } = useSelector((state) => state.practices);
  const providerLoading = useSelector((state) => state.provider.loading);
  const { getProviders, providerBySeqData } = useSelector(
    (state) => state.provider
  );
  console.log(providerBySeqData, "checkProviderBySeqData");
  const practicesLoading = useSelector((state) => state.practices.loading);

  const handleSelectTaxonomy = (taxonomyCode) => {
    console.log(taxonomyCode, "checking taxonomy Code");
    formik.setFieldValue("providerTaxonomySpecialtyCode", taxonomyCode);
  };

  const handleSelectNpi = (npi) => {
    formik.setFieldValue("providerNPINo", npi?.npiNo);
    formik.setFieldValue("providerNPIId", npi?.id);
  };

  const handleGetPracticeData = (values) => {
    formik.setFieldValue("practiceId", values.id);
    formik.setFieldValue("practiceSequenceNo", values.sequenceNo);
    formik.setFieldValue("practiceName", values.practiceName);
    formik.setFieldValue("practiceAddress", values.practiceAddress);
    setPracticeModal(false);
  };
  // getting billing provider
  const handleGetBillingProvider = (val) => {
    formik.setValues((preVals) => ({
      ...preVals,
      billingProviderId: val.id,
      billingProviderFirstName: val.providerFirstName,
      billingProviderLastName: val.providerLastName ? val.providerLastName : "",
      billingProviderSeqNo: val.providerSequenceNo,
      providerOrganization: val.providerOrganization,
    }));
    setBillingProviderModal(false);
  };
  // getting eligibility provider
  const handleEligibilityProvider = (val) => {
    console.log(val, "provider values");
    formik.setValues((preVals) => ({
      ...preVals,
      eligibilityProviderId: val.id,
      eligibilityProviderFirstName: val.providerFirstName,
      eligibilityProviderLastName: val.providerLastName
        ? val.providerLastName
        : "",
      eligibilityProviderSeqNo: val.providerSequenceNo,
      providerOrganization: val.providerOrganization,
    }));

    setEligibilityProviderModal(false);
  };
  // revcode function
  const handleRevCode = (val) => {
    console.log(val, "checkvaluesRev");
    formik.setFieldValue("revCodeId", val.id);
    formik.setFieldValue("revCode", val.procedureCode);
    setProcedureModal(false);
  };
  // dropdown
  const dataFetchUrls = {
    billAs: `${path}/ct-billAs`,
    billClaims: `${path}/ct-claimBilling`,
    claimModeTypes: `${path}/ct-claimMode`,
    idNumType: `${path}/ct-id_No`,
    providerType: `${path}/ct-provideType`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      console.log(response, "checking response options");
      setter(response.result);
    } catch (error) {
      console.log("Fething DD Options: ", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.billAs, setBillAsOpt);
    fetchDataOptions(dataFetchUrls.billClaims, setBillClaimsOpt);
    fetchDataOptions(dataFetchUrls.claimModeTypes, setClaimModeTypes);
    fetchDataOptions(dataFetchUrls.idNumType, setIdNumTypes);
    fetchDataOptions(dataFetchUrls.providerType, setProviderType);
  }, []);

  const handleCheckboxChange = (claimBillingId) => {
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [claimBillingId]: !prevItems[claimBillingId],
    }));
    formik.setFieldValue("claimBillingId", claimBillingId);
  };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/provider");
  };
  {
    formik.values.sequenceNo && (
      <Box sx={{ marginLeft: "auto" }}>
        <CheckBox
          CheckedValue={!formik.values.isActive}
          handleChange={() =>
            // formik.setFieldValue("isActive", !formik.values.isActive)
            handleIsActive(formik)
          }
          label="Make this Practice inactive"
        />
      </Box>
    );
  }

  const handleNpiChange = async (e) => {
    const npiValue = e.target.value;
    formik.setFieldValue("providerNPINo", npiValue);
    if (formik.values.providerNPINo.length >= 4) {
      try {
        const response = await getData(
          `${path}/npi/searchNPI?npiNo=${npiValue}`
        );
        if (response.result.length > 0) {
          setNpiResultData(response.result);
          setOpenNpiPaper(true);
        }
      } catch (e) {
        console.log(e, ": Error in feting NPI");
        throw e;
      }
    } else {
      setOpenNpiPaper(false);
    }
  };

  console.log(formik.values, "providerFormikValues");

  useEffect(() => {
    try {
      if (formik.values.practiceId) {
        dispatch(getProviderSeqAction(formik.values.practiceSequenceNo));
      }
    } catch (e) {
      console.log(e, "error in useEffect");
    }
  }, [formik.values.practiceId]);
  return (
    <>
      <form id="provider-form" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
            padding: "10px",
          }}
        >
          <Stack direction={"row"} gap={"15px"}>
            <CustomButton
              formId="provider-form"
              isBlue={true}
              isSave={!formik.values.providerId && true}
              isEdit={formik.values.providerId && true}
              type="submit"
            >
              {formik.values.providerId ? "Update" : "Save"}
            </CustomButton>

            <CustomButton isCancel={true} handleClick={handleCancel}>
              Cancel
            </CustomButton>
          </Stack>

          <Stack>
            {formik.values.providerId && (
              <CheckBox
                CheckedValue={!formik.values.isActive}
                handleChange={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
                label="Make this provider inactive"
              />
            )}
          </Stack>
        </Box>

        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />

        <Paper elevation={3} style={{ padding: 20, margin: 7 }}>
          <Grid container spacing={2}>
            {formik.values?.providerTypeId === 1 ? (
              <>
                <Grid item md={3} xs={12}>
                  <CustomField
                    type="text"
                    label="First Name"
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    error={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    value={formik.values?.providerFirstName}
                    name="providerFirstName"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomField
                    type="text"
                    label="Last Name"
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    error={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    value={
                      formik.values?.providerLastName
                        ? formik.values?.providerLastName
                        : ""
                    }
                    name="providerLastName"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomField
                    type="text"
                    label="MI"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    value={formik.values?.providerMI}
                    name="providerMI"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomField
                    type="text"
                    label="Credentials"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    value={formik.values?.providerCredential}
                    name="providerCredential"
                  />
                </Grid>
              </>
            ) : (
              <Grid item md={12} xs={12}>
                <CustomField
                  type="text"
                  label="Origanization Name"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  value={formik.values?.providerFirstName}
                  name="providerFirstName"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack flexDirection={"row"} alignItems={"center"}>
                {providerTypes.map((val, ind) => {
                  return (
                    <FormControlLabel
                      label={val?.providerType}
                      control={
                        <Radio
                          value={val?.providerType}
                          variant="soft"
                          name="radio-buttons"
                          checked={
                            formik.values?.providerTypeId === val.providerTypeId
                          }
                          onChange={() => {
                            formik.setFieldValue("providerFirstName", "");
                            formik.setFieldValue("providerLastName", "");
                            formik.setFieldValue("providerMI", "");
                            formik.setFieldValue("providerCredential", "");
                            formik.setFieldValue(
                              "providerTypeId",
                              val.providerTypeId
                            );
                            formik.setFieldValue(
                              "ProviderType",
                              val.ProviderType
                            );
                          }}
                        />
                      }
                    />
                  );
                })}
              </Stack>
            </Grid>
            {/* modals npi - taxonomy */}
            <Grid item md={3} xs={12}>
              <CustomField
                disable={true}
                type="text"
                label={"Sequence #"}
                value={formik.values?.providerSequenceNo || null}
                name="providerSequenceNo"
                // handleChange={formik.handleChange}
                // handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormGroup
                sx={{
                  position: "relative",
                }}
              >
                <CustomSearchField
                  isRequired={true}
                  fieldVal={formik.values?.providerNPINo}
                  name="providerNPINo"
                  handleChange={handleNpiChange}
                  handleBlur={formik.handleBlur}
                  handleModalOpen={() => setSearchNpiModal(true)}
                  label="NPI"
                  formik={formik}
                  resetField={{
                    providerNPINo: null,
                  }}
                  isTypeAble={true}
                />
                {openNpiPaper && (
                  <Paper
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 111,
                      boxShadow: 3,
                      width: "100%",
                    }}
                  >
                    {npiResultData?.map((item) => (
                      <Stack margin="5px" width="100%" key={item?.npiId}>
                        <CustomButton
                          isBlue={false}
                          handleClick={() => {
                            formik.setFieldValue("providerNPINo", item?.npiNo);
                            setOpenNpiPaper(false);
                          }}
                        >
                          <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                          {item?.npiNo}
                        </CustomButton>
                      </Stack>
                    ))}
                  </Paper>
                )}
              </FormGroup>
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSearchField
                name="providerTaxonomySpecialtyCode"
                label="Taxonomy"
                isRequired={true}
                fieldVal={formik.values?.providerTaxonomySpecialtyCode}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                handleModalOpen={() => setTaxonomyListModal(true)}
                formik={formik}
                resetField={{
                  providerTaxonomySpecialtyCode: null,
                }}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <CustomField
                type="text"
                label="License"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values?.license}
                name="license"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomDatePicker
                dateLabel="Expiry Date"
                name="expiryDate"
                dateValue={formik.values?.expiryDate}
                handleDateChange={(date) => {
                  formik.handleChange("expiryDate")(date);
                }}
                // handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
                formik={formik}
                isFromTo={true}
              />
            </Grid>

            {/* sequence refrence code */}

            <Grid item md={4} xs={12}>
              <CustomField
                type="number"
                label="Refrence#"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values?.providerReferenceNo}
                name="providerReferenceNo"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomField
                type="text"
                label="Code"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values?.providerCode}
                name="providerCode"
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid item md={4} margin={1}>
          <CustomAccordion
            heading={"Billing Information"}
            maxHeight="300px "
            hasErrors={formik?.errors?.practiceName}
          >
            <AccordionDetails>
              {/* practice for the provider */}
              <Box
                display="grid"
                gap="30px"
                margin={"20px 0"}
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(3, minmax(0, 1fr))",
                  },
                }}
              >
                <CustomSearchField
                  isRequired={true}
                  fieldVal={
                    (formik.values?.practiceName.length > 0 &&
                      `${formik.values?.practiceName} (${formik.values?.practiceSequenceNo})`) ||
                    ""
                  }
                  name="practiceName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  label="Practice for this provider"
                  handleModalOpen={() => setPracticeModal(true)}
                  formik={formik}
                  resetField={{
                    practiceName: "",
                    practiceSequenceNo: null,
                  }}
                />

                <CustomSearchField
                  type="text"
                  fieldVal={
                    (formik.values?.billingProviderFirstName?.length > 0 &&
                      `${formik.values?.billingProviderFirstName} ${
                        formik.values?.billingProviderLastName
                          ? formik.values?.billingProviderLastName
                          : ""
                      } (${formik.values?.billingProviderSeqNo})`) ||
                    ""
                  }
                  name="billingProvider"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  label="Bills Claim Under"
                  handleModalOpen={() => setBillingProviderModal(true)}
                  formik={formik}
                  resetField={{
                    billingProviderFirstName: "",
                  }}
                />
                <CustomSearchField
                  type="text"
                  fieldVal={
                    (formik.values?.eligibilityProviderFirstName?.length > 0 &&
                      `${formik.values?.eligibilityProviderFirstName} ${
                        formik.values?.eligibilityProviderLastName
                          ? formik.values?.eligibilityProviderLastName
                          : ""
                      } (${formik.values?.eligibilityProviderSeqNo})`) ||
                    ""
                  }
                  name="eligibilityProvider"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  label="Check Eligibility Under"
                  handleModalOpen={() => setEligibilityProviderModal(true)}
                  formik={formik}
                  resetField={{
                    eligibilityProviderFirstName: "",
                  }}
                />
              </Box>

              <Box
                display="grid"
                marginBottom="20px"
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                  },
                }}
                gap="20px"
              >
                <CustomSelectBox2
                  label="Use which ID number?"
                  value={formik.values?.id_NoId}
                  name="id_NoId"
                  formik={formik}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={idNumTypes?.map((option) => ({
                    value: option.id_NoName,
                    id: option.id_NoId,
                  }))}
                />

                <CustomField
                  type="text"
                  value={formik.values?.id_NoValue}
                  name="id_NoValue"
                  handleChange={formik.handleChange}
                  isRequired={true}
                  error={formik.errors}
                  touched={formik.touched}
                  handleBlur={formik.handleBlur}
                  label={
                    formik.values?.id_NoId === 1
                      ? "Social Security # (SSN)"
                      : "Employer Identification Number # (EIN)"
                  }
                />
              </Box>

              <Stack gap="20px">
                {/* bill as */}
                <CustomSelectBox2
                  formik={formik}
                  value={formik.values?.billAsId}
                  name="billAsId"
                  dropdownOptions={billAsOpt?.map((opt) => ({
                    value: opt.billAsName,
                    id: opt.billAsId,
                  }))}
                  label="Bill As"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
                {/* Bill Claims Checkboxes */}
                <Stack marginTop="10px">
                  {billClaimsOpt?.map((option) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormGroup>
                        <FormControlLabel
                          className="checkbox-label"
                          control={
                            <Checkbox
                              checked={
                                formik.values?.claimBillingId ===
                                option.claimBillingId
                              }
                              onChange={() =>
                                handleCheckboxChange(option.claimBillingId)
                              }
                            />
                          }
                          label={option?.claimBillingType}
                        />
                      </FormGroup>

                      {formik.values?.claimBillingId ===
                      option.claimBillingId ? (
                        <CustomSelectBox2
                          formik={formik}
                          value={formik.values?.claimModeId}
                          name="claimModeId"
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          dropdownOptions={claimModeTypes?.map((opt) => ({
                            value: opt.claimModeType,
                            id: opt.claimModeId,
                          }))}
                          label=""
                        />
                      ) : null}
                    </Stack>
                  ))}
                  {formik.errors["claimBillingId"] &&
                    formik.touched["claimBillingId"] && (
                      <div
                        style={{
                          color: "red",
                          border: "1px solid red",
                          fontSize: "1.3rem",
                          marginTop: "8px",
                          display: "block",
                        }}
                      >
                        {formik.errors["claimBillingId"]}
                      </div>
                    )}
                </Stack>
                {/* contact information */}

                <div>
                  <Typography
                    variant="h2"
                    component="h3"
                    sx={{
                      background: theme.palette.accordInnerHeading.primary,
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      columnGap: "20px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(2, minmax(0, 1fr))",
                        md: "repeat(4, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <CustomField
                      type="text"
                      value={formik.values?.homePhone}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="homePhone"
                      label="Home Phone"
                      mask="(999) 999-9999"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.cellPhone}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="cellPhone"
                      label="Cell Phone"
                      mask="(999) 999-9999"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.faxNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="faxNo"
                      label="Fax #"
                      mask="(999) 999-9999"
                    />
                    <CustomField
                      type="email"
                      value={formik.values?.email}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="email"
                      label="Email"
                    />
                  </Box>
                </div>
                {/* ID Numbers */}
                <div>
                  <Typography
                    variant="h2"
                    component="h3"
                    sx={{
                      background: theme.palette.accordInnerHeading.primary,
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    ID Numbers
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "20px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(2, minmax(0, 1fr))",
                        md: "repeat(3, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <CustomField
                      type="text"
                      value={formik.values?.specialtyLicenseNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="specialtyLicenseNo"
                      label="Speciality License #"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.stateLicenseNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="stateLicenseNo"
                      label="State License #"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.anesthesiaLicenseNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="anesthesiaLicenseNo"
                      label="Anesthesia License #"
                    />
                    <CustomField
                      type="text"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      label="UPIN #"
                      value={formik.values?.upinNo}
                      name="upinNo"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.blueCrossNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="blueCrossNo"
                      label="Blue Cross #"
                    />
                    <CustomField
                      type="text"
                      value={formik.values?.tricareNo}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      name="tricareNo"
                      label="Tricare/Champus #"
                    />
                  </Box>
                </div>
                {/* claim defaults */}
                <Box>
                  <Typography
                    variant="h2"
                    component="h3"
                    sx={{
                      background: theme.palette.accordInnerHeading.primary,
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    Claim Defaults
                  </Typography>
                  <CustomSearchField
                    fieldVal={formik.values?.revCode}
                    type="text"
                    name="revCode"
                    label="Rev Code"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    handleModalOpen={() => setProcedureModal(true)}
                  />
                </Box>
              </Stack>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>

        <CustomModal
          open={taxonomyListModal}
          handleClose={() => setTaxonomyListModal(false)}
        >
          <TaxonomyCategories
            //   setTaxonomyName={setTaxonomyName}
            handleSelectTaxonomy={handleSelectTaxonomy}
            handleClose={() => setTaxonomyListModal(false)}
          />
        </CustomModal>
        {/* npi modal */}
        <CustomModal
          open={searchNpiModal}
          handleClose={() => setSearchNpiModal(false)}
          heading={"NPI Search"}
          width={"60%"}
        >
          <SearchNpi
            //   setFieldValue={setFieldValue}
            handleSelectNpi={handleSelectNpi}
            handleClose={() => setSearchNpiModal(false)}
            setSearchNpiModal={setSearchNpiModal}
          />
        </CustomModal>

        <CustomModal
          open={practiceModal}
          handleClose={() => setPracticeModal(false)}
          heading="All Practice"
        >
          <CustomPreview
            rows={
              getPractices?.result && getPractices?.result?.practices.length > 0
                ? getPractices.result?.practices.map((el) => ({
                    id: el?.practiceId,
                    ...el,
                  }))
                : []
            }
            columns={practiceCols}
            loading={practicesLoading}
            handleGetAll={getPracticeAction}
            handleBySearch={getPracticeBySearchTermAction}
            searchLabel="Search for practices by name, phone #, sequence #"
            handleCell={(params) => handleGetPracticeData(params)}
            isModal={true}
          />
        </CustomModal>

        <CustomModal
          open={billingProviderModal}
          handleClose={() => setBillingProviderModal(false)}
          heading="All Provider"
        >
          {/* <CustomPreview
            rows={
              getProviders?.result && getProviders?.result?.providers.length > 0
                ? getProviders.result?.providers.map((el) => ({
                    id: el.providerId,
                    ...el,
                  }))
                : []
            }
            paginationDetail={getProviders}
            columns={providerCols}
            loading={providerLoading}
            handleGetAll={getProviderAction}
            handleBySearch={getProviderBySearchTermAction}
            searchLabel="Search for provider by name, sequence #"
            handleCell={handleGetBillingProvider}
            isModal={true}
          /> */}
          <CustomDataGrid
            columns={providerCols}
            // paginationDetail={paginationDetail}
            rows={[]}
            // pageSize={previewFormik.values.pageSize}
            // handleCellClick={handleCell}
            // page={previewFormik.values.page}
            // handlePageChange={handlePageChange}
          />
        </CustomModal>
        <CustomModal
          open={elibilityProviderModal}
          handleClose={() => setEligibilityProviderModal(false)}
          heading="All Provider"
        >
          <CustomPreview
            rows={
              getProviders?.result && getProviders?.result?.providers.length > 0
                ? getProviders.result?.providers.map((el) => ({
                    id: el.providerId,
                    ...el,
                  }))
                : []
            }
            paginationDetail={getProviders}
            columns={providerCols}
            loading={providerLoading}
            handleGetAll={getProviderAction}
            handleBySearch={getProviderBySearchTermAction}
            searchLabel="Search for provider by name, sequence #"
            handleCell={handleEligibilityProvider}
            isModal={true}
          />
        </CustomModal>

        <CustomModal
          open={procedureModal}
          handleClose={() => setProcedureModal(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <h3>No Item added yet</h3>
          </div>
        </CustomModal>
        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
      </form>
    </>
  );
};

export default ProviderInfo;
