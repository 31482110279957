import React from 'react'
import { useEffect,useState } from 'react';
import { Stack,Typography,useTheme,Box} from '@mui/material';
// import MainHeading from '../../../components/MainHeading';
import CustomButton from '../../../components/CustomButton';
import CustomDialog from '../../../components/CustomDialog';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getPatientByAccountNoAction } from '../../../features/actions/createPatientAction';
// import { handleCancel } from '../../../utils/customFunctions';
import '../payment-plans/PaymentPlans.css';



const ManagePayPlans = () => {

    // const handleCancel = () => {
    //   console.log("object");
    //   const conform = window.confirm("Are you sure you want to cancel?");
    //   if (conform) {
    //     // formik.resetForm();
    //     navigate("/patients/payment-plans");
    //   }
    // };
  
  const {accountNo} = useParams()
  const dispatch = useDispatch()
  const {singlePatientData} = useSelector(state => state.patient)
  console.log(singlePatientData, "singlePatientData")
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const patientData = singlePatientData?.result
  console.log(patientData, "patientData")
    const theme= useTheme();
    const navigate=useNavigate();
    console.log(accountNo, "accountNoRev")

    useEffect(() => {
      dispatch(getPatientByAccountNoAction(accountNo))
    }, [])

    const handleCancel = () => {
      setOpenCancelDialog(true);
    };
  
    const handleConfirmCancel = () => {
      navigate("/patients/payment-plans");
    };
  return (
<>
    <Box margin="20px">
    {/* <MainHeading mainheading="Manage Payment Plans" /> */}

    <Stack direction="row" alignItems="center" gap="10px" marginBottom={'20px'}>
    <Typography variant='h3' fontWeight={600}>{patientData?.firstName} {patientData?.lastName}</Typography>
      <Typography variant='h3' fontWeight={600}>{accountNo}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" gap="10px">
      {/* <CustomButton
        
        handleClick={() => navigate(`/patients/payment-plans/${accountNo}/plan/new`)}
        isBlue={true}
        isAdd={true}
      >
        New Payment Plans
      </CustomButton>
      <CustomButton isBlue={false} isCancel={true} 
        handleClick={() => {
          handleCancel(
            // formik,
             "/patients/payment-plans", navigate);
        }}
      >
        Cancel
      </CustomButton> */}
     
     <CustomButton
        
        handleClick={() => navigate(`/patients/payment-plans/${accountNo}/plan/new`)}
        isBlue={true}
        isAdd={true}
      >
        New Payment Plans
      </CustomButton>
         <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
    </Stack>
    <Box
        style={{
        //   borderRadius: "20px",
          background: theme.palette.innerBox.primary,
        }}
        // display="flex"
        // flexDirection="column"
        marginTop={'30px'}
      >
        <Box
          display="flex"
          sx={{
            width: { xs: "95%", sm: "75%", md: "100%" },
            background:theme.palette.accordiansBodyBackground.primary
            // marginLeft: "15px",
            // marginTop: "10px",
            // marginBottom: "20px",
            // background:'grey'
          }}
          flexDirection="column"
          gap={"20px"}
        >
    
    <Box sx={{width:'100%',height:'50px',background:theme.palette.accordiansBackground.primary}}>
    <Typography
      component="h2"
      sx={{ color: theme.palette.textMainHeading.primary,margin:'10px'}}
    >
    Managing Payment Plans for
    </Typography>
    </Box>
  
    <Typography component="h5">
      <strong></strong>
      {/* <span>{formik.values.defaultPractice}</span> */}
    </Typography>
  {/* </Stack> */}
  

  <Box
        display="grid"
        margin={"10px"}
        gap="10px"
        alignItems="flex-end"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(3, minmax(0, 1fr))",
          },
        }}
      >
   <Typography variant='h3'>Current Payment Plan Balance:</Typography>
   <Typography variant='h3'>Charge Balance Due Patient:</Typography>
   <Typography variant='h3'>Charge Balance Due Insurance:</Typography>
      </Box>
        </Box>
      </Box>
  </Box>
 <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
 isCancel={true}
/>

</>

  )
}

export default ManagePayPlans;
