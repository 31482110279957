import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";

export const getGuarantorAction = createAsyncThunk(
  "getGuarantorAction",
  async (page) => {
    console.log(page);
    const newPage = page?.page + 1
    try {
      const response = await getData(
        `${path}/guarantor?page=${newPage}&pageSize=20`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
//   search guarantor
export const getGuarantorBySearchTermAction = createAsyncThunk(
  "getGuarantorAction",
  async ({ searchTerm, page }) => {
    try {
      const response = await getData(
        `${path}/guarantor/search?searchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
