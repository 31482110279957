import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getClaimAction,
  getPatientClaimsDetailAction,
  getClaimAcivityActionSingle,
  getClaimAcivityActionMultiple,
  newClaimAction,
  updateClaimAction,
  getClaimByNumberAction,
  getPatientMultipleClaims,
  getClaimChargesHistoryAction,
} from "../actions/claimAction";

const initialState = {
  createClaimData: {},
  getClaims: [],
  
  // multipleClaims:[],
  singleClaimData: {},
  getSingleClaimActiviy: [],
  claimChargesHistory: [],
  primaryPayers: [],
  secondaryPayer: [],
  tertiaryPayer: [],
  patientClaimsData: [],
  getClaimActiviy: [],
  claimInsurances:[],
  loading: false,
  claimType: null,
  getPatientClaimDetailLoad: false,
  error: null,
};
const claimSlice = createSlice({
  name: "claim",
  initialState: initialState,
  reducers: {
    setPrimaryPayer: (state, action) => {
      state.primaryPayers = action.payload
    },
    setSecondaryPayer: (state, action) => {
      state.secondaryPayer = action.payload
    },
    setTertiaryPayer: (state, action) => {
      state.tertiaryPayer = action.payload
    },
    setClaimType: (state, action) => {
      console.log(action,"action claim type")
      state.claimType = action.payload
    },
    setInsurances: (state, action) => {
      state.claimInsurances = action.payload
    }
    
  },
  extraReducers: {
    [newClaimAction.pending]: (state) => {
      state.loading = true;
    },
    [newClaimAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createClaimData = action.payload;
      toast.success("Claim has been created Successfully!");
    },
    [newClaimAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Claim creation failed");
    },
    [getClaimAction.pending]: (state) => {
      state.loading = true;
    },
    [getClaimAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClaims = action.payload;
      console.log(action.payload, "claim slice get payload");
    },
    [getClaimAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getClaimByNumberAction.pending]: (state) => {
      state.loading = true;
    },
    [getClaimByNumberAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleClaimData = action.payload.result;
      console.log(action.payload, "claim slice get payload");
    },
    [getClaimByNumberAction.rejected]: (state, action) => {
      console.log("error list3232", action.payload)
      state.loading = false;
      state.error = action.payload;
    },
    // patient claim details
    [getPatientClaimsDetailAction.pending]: (state) => {
      state.getPatientClaimDetailLoad = true;
    },
    [getPatientClaimsDetailAction.fulfilled]: (state, action) => {
      state.getPatientClaimDetailLoad = false;
      state.patientClaimsData = action.payload;
      console.log(action.payload, "patient claim details slice get payload");
    },
    [getPatientClaimsDetailAction.rejected]: (state, action) => {
      state.getPatientClaimDetailLoad = false;
      state.error = action.payload;
    },
    [updateClaimAction.pending]: (state) => {
      state.loading = true;
    },
    [updateClaimAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Claim Updated Successfully!");
    },
    [updateClaimAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Claim Updation Failed");
    },
    [getClaimAcivityActionSingle.pending]: (state) => {
      state.loading = true;
    },
    [getClaimAcivityActionSingle.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClaimActiviy = action.payload;
    },
    [getClaimAcivityActionSingle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getClaimChargesHistoryAction.pending]: (state) => {
      state.loading = true;
    },
    [getClaimChargesHistoryAction.fulfilled]: (state, action) => {
      state.loading = false;

      state.claimChargesHistory = action.payload;
    },
    [getClaimChargesHistoryAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getClaimAcivityActionMultiple.pending]: (state) => {
      state.loading = true;
    },
    [getClaimAcivityActionMultiple.fulfilled]: (state, action) => {
      state.loading = false;
      state.getClaimActiviy = action.payload;
    },
    [getClaimAcivityActionMultiple.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setPrimaryPayer, setSecondaryPayer, setTertiaryPayer, setClaimType , setInsurances} = claimSlice.actions;
export default claimSlice.reducer;
