import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Patient from "./scenes/patient";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ManagePatient from "./scenes/patient/ManagePatient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPractice from "./scenes/custom-setup/practices/NewPractice";
import Practice from "./scenes/custom-setup/practices/Practice";
import UpdatePractice from "./scenes/custom-setup/practices/UpdatePractice";
import NewProvider from "./scenes/custom-setup/provider/NewProvider";
import Provider from "./scenes/custom-setup/provider/Provider";
import UpdateProvider from "./scenes/custom-setup/provider/UpdateProvider";
import NewClaim from "./scenes/claim-dir/claim/NewClaim";
import Claim from "./scenes/claim-dir/claim/Claim";
import UpdateClaim from "./scenes/claim-dir/claim/UpdateClaim";
import NewPayment from "./scenes/payment-module/NewPayment";
import PostPayDetail from "./scenes/payment/PostPayDetail";
import CreatePatient from "./scenes/patient/CreatePatient";
import NewLogin from "./auth/NewLogin";
import PreviewPdf from "./components/pdfs/PreviewPdf";
import ViewPayment from "./scenes/payment-module/ViewPayment";
import EditPayment from "./scenes/payment-module/EditPayment";
import Facility from "./scenes/custom-setup/facility/Facility";
import NewFacility from "./scenes/custom-setup/facility/NewFacility";
import UpdateFacility from "./scenes/custom-setup/facility/UpdateFacility";
import ReferringProvider from "./scenes/custom-setup/referring-provider/ReferringProvider";
import NewReferringProvider from "./scenes/custom-setup/referring-provider/NewReferringProvider";
import UpdateRefferingProvider from "./scenes/custom-setup/referring-provider/UpdateRefferingProvider";
import Sidebar2 from "./scenes/global/Sidebar2";
import ARControl from "./scenes/patient/ar_control/ARControl";
import ProtectedRoute from "./utils/ProtectedRoute";
import Diagnosis from "./scenes/custom-setup/DiagnosisModule/Diagnosis";
import CreateDiagnosis from "./scenes/custom-setup/DiagnosisModule/CreateDiagnosis";
import UpdateDiagnosis from "./scenes/custom-setup/DiagnosisModule/UpdateDiagnosis";
import CreateUser from "./scenes/custom-setup/registerUser/CreateUser";
import UpdateUser from "./scenes/custom-setup/registerUser/UpdateUser";
import AllUser from "./scenes/custom-setup/registerUser/AllUser";
import ChangePassword from "./scenes/custom-setup/registerUser/ChangePassword";
import NotFound from "./scenes/404NotFound/NotFound";
import NewRemittance from "./scenes/custom-setup/remittance/NewRemittance";
import UpdateRemittance from "./scenes/custom-setup/remittance/UpdateRemittance";
import Remittance from "./scenes/custom-setup/remittance/Remittance";
// import RevenueInfo from "./scenes/custom-setup/revenue/RevenueInfo";
import Adjustments from "./scenes/custom-setup/adjustment-codes/Adjustments";
import NewAdjustmentCode from "./scenes/custom-setup/adjustment-codes/NewAdjustmentCode";
import Revenue from "./scenes/custom-setup/revenue/Revenue";
import NewRevenue from "./scenes/custom-setup/revenue/NewRevenue";
import UpdateRevenue from "./scenes/custom-setup/revenue/UpdateRevenue";
import UpdateAdjustment from "./scenes/custom-setup/adjustment-codes/UpdateAdjustment";
import NewProcedure from "./scenes/custom-setup/procedure-codes/NewProcedure";
import ProcedureCodes from "./scenes/custom-setup/procedure-codes/ProcedureCodes";
import NewInventoryCode from "./scenes/custom-setup/Inventory-Code/NewInventoryCode";
import InventoryCode from "./scenes/custom-setup/Inventory-Code/InventoryCode";
import UpdateInventoryCode from "./scenes/custom-setup/Inventory-Code/UpdateInventoryCode";
import ClaimActivity from "./scenes/claim-dir/claim-activity/ClaimActivity";
import Payer from "./scenes/custom-setup/payer/Payer";
import CreatePayer from "./scenes/custom-setup/payer/CreatePayer";
import UpdatePayer from "./scenes/custom-setup/payer/UpdatePayer";
import Era from "./scenes/custom-setup/era/Era";
import UpdateProcedure from "./scenes/custom-setup/procedure-codes/UpdateProcedure";
import NewChargePanel from "./scenes/custom-setup/charge-panel/NewChargePanel";
import ChargePanel from "./scenes/custom-setup/charge-panel/ChargePanel";
import ClaimTracker from "./scenes/claim-dir/claim-tracker/ClaimTracker";
import ICDProcedure from "./scenes/custom-setup/icd-procedures/ICDProcedure";
import NewICDProcedureCodes from "./scenes/custom-setup/icd-procedures/NewICDProcedureCodes";
import UpdateChargePanel from "./scenes/custom-setup/charge-panel/UpdateChargePanel";
import EraDetail from "./scenes/custom-setup/era/EraDetail";
import UpdatePatient from "./scenes/patient/UpdatePatient";
import ManageAccount from "./scenes/patient/manage-account/ManageAccount";
import PaymentPlans from "./scenes/patient/payment-plans/PaymentPlans";
import ManageAccountDetails from "./scenes/patient/manage-account/ManageAccountDetails";
import ManagePayPlans from "./scenes/patient/payment-plans/ManagePayPlans";
import NewPaymentPlans from "./scenes/patient/payment-plans/NewPaymentPlans";
import PaymentTracker from "./scenes/payment-module/payment-tracker/PaymentTracker";
import { BatchStatmentPrint } from "./scenes/patient/BatchStatmentPrint/BatchStatmentPrint";
import LabelBatchPrint from "./scenes/patient/label-batch-print/LabelBatchPrint";
import UpdateICDProcedure from "./scenes/custom-setup/icd-procedures/UpdateICDProcedure";
import StatusControl from "./scenes/claim-dir/status-control/StatusControl";
import FollowUpManagement from "./scenes/claim-dir/followup-management/FollowUpManagement";
import ClaimBatchPrint from "./scenes/claim-dir/claim/claim-batch-print/ClaimBatchPrint";
import FeeSchedules from "./scenes/custom-setup/fee-schedules/FeeSchedules";
import Company from "./scenes/administration/company/Company";
import CreateFeeSchedule from "./scenes/custom-setup/fee-schedules/CreateFeeSchedule";
import UpdateFeeSchedule from "./scenes/custom-setup/fee-schedules/UpdateFeeSchedule";
import NewCompany from "./scenes/administration/company/NewCompany";
import UpdateCompany from "./scenes/administration/company/UpdateCompany";
import ClaimSubmission from "./scenes/claim-dir/submission/ClaimSubmission";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  // const [isNavbar, setIsNavbar] = useState(true);
  const location = useLocation();

  const isLoginPage = location.pathname === "/";

  const handleThemeToggle = () => {
    console.log("Before Toggle:", colorMode);
    colorMode.toggleColorMode();
    console.log("After Toggle:", colorMode);
  };

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {!isLoginPage && (
              <Topbar
                setIsSidebar={setIsSidebar}
                handleThemeToggle={handleThemeToggle}
                colorMode={colorMode}
              />
            )}
            {!isLoginPage && <Sidebar2 isSidebar={isSidebar} />}
            <Box
              className="content"
              sx={{
                marginTop: isLoginPage ? "0px !important" : "80px !important",
              }}
            >
              <Routes>
                <Route path="/" element={<NewLogin />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/*" element={<NotFound />} />
                  <Route path="/patient" element={<Patient />} />
                  <Route path="/patients/new" element={<CreatePatient />} />

                  <Route
                    // path="/patient/update/:accountNo"
                    path="/patient/update/:patientId"
                    element={<UpdatePatient />}
                  />
                  <Route path="/patients" element={<ManagePatient />} />
                  <Route
                    path="/patients/manage-account"
                    element={<ManageAccount />}
                  />
                  <Route
                    // path="/patients/manage-account/:accountNo"
                    path="/patients/manage-account/:patientId"
                    element={<ManageAccountDetails />}
                  />
                  <Route
                    path="/patients/payment-plans"
                    element={<PaymentPlans />}
                  />
                  <Route
                    path="/patients/statments/print"
                    element={<BatchStatmentPrint />}
                  />
                  <Route
                    // path="/patients/payment-plans/:accountNo"
                    path="/patients/payment-plans/:patientId"
                    element={<ManagePayPlans />}
                  />
                  <Route
                    // path="/patients/payment-plans/:accountNo/plan/new"
                    path="/patients/payment-plans/:patientId/plan/new"
                    element={<NewPaymentPlans />}
                  />

                  {/* Label-batch-print */}
                  <Route
                    path="/label-batch-print"
                    element={<LabelBatchPrint />}
                  />

                  {/* practices */}
                  <Route path="/practice/new" element={<NewPractice />} />
                  <Route path="/practice" element={<Practice />} />
                  <Route
                    path="/practice/update/:practiceId"
                    element={<UpdatePractice/>}
                  />
                  {/* provider */}
                  <Route path="/provider/new" element={<NewProvider/>} />
                  <Route path="/provider" element={<Provider />} />
                  <Route
                    // path="/provider/update/:providerSequenceNo"
                    path="/provider/update/:providerId"
                    element={<UpdateProvider/>}
                  />
                  {/*claim  */}
                  <Route path="/claims/new" element={<NewClaim />} />
                  <Route path="/claims" element={<Claim />} />
                  <Route
                    // path="/claims/update/:claimNumber"
                    path="/claims/update/:claimId"
                    element={<UpdateClaim />}
                  />

                  <Route path="/claim-tracker" element={<ClaimTracker />} />

                  <Route path="/status-control" element={<StatusControl />} />

                  <Route
                    path="/follow-up-management"
                    element={<FollowUpManagement />}
                  />

                  <Route
                    path="/claim-batch-print"
                    element={<ClaimBatchPrint />}
                  />
                  <Route
                    path="/claim-submission"
                    element={<ClaimSubmission />}
                  />

                  <Route path="/payment" element={<NewPayment />} />
                  <Route path="/payment/view" element={<ViewPayment />} />
                  <Route path="payment/era/search" element={<Era />} />
                  <Route
                    path="payment/era/search/fileName"
                    element={<EraDetail />}
                  />

                  <Route path="/editPayment/:id" element={<EditPayment />} />
                  <Route
                    path="/payment/post/postpay-detail"
                    element={<PostPayDetail />}
                  />
                  <Route path="/payment/track" element={<PaymentTracker />} />

                  <Route path="/pdf-view" element={<PreviewPdf />} />
                  {/* facilty */}
                  <Route path="/facility" element={<Facility />} />
                  <Route path="/diagnosis" element={<Diagnosis />} />
                  <Route path="/diagnosis/new" element={<CreateDiagnosis />} />
                  <Route path="/facility/new" element={<NewFacility />} />
                  <Route
                    // path="/facility/update/:sequenceNo"
                    path="/facility/update/:facilityId"
                    element={<UpdateFacility />}
                  />
                  <Route
                    path="/diagnosis/update/:id"
                    element={<UpdateDiagnosis />}
                  />
                  <Route
                    path="/inventorycode/new"
                    element={<NewInventoryCode />}
                  />
                  <Route path="/inventorycode" element={<InventoryCode />} />
                  <Route
                    path="/inventorycode/update/:id"
                    element={<UpdateInventoryCode />}
                  />
                  {/* Referring Provider */}
                  <Route
                    path="/referringProvider"
                    element={<ReferringProvider />}
                  />
                  <Route
                    path="/referringProvider/new"
                    element={<NewReferringProvider />}
                  />
                  {/* <Route
                  path="/referringProvider/update/:seqNumber"
                  element={<UpdateRefferingProvider />}
                /> */}
                  <Route
                    // path="/referringProvider/update/:referringProviderSequenceNo"
                    path="/referringProvider/update/:referringProviderId"
                    element={<UpdateRefferingProvider />}
                  />

                  {/* AR Control */}
                  <Route path="/ar-control" element={<ARControl />} />

                  {/* Register User */}
                  <Route path="/users" element={<AllUser/>} />
                  <Route path="/user/new" element={<CreateUser />} />
                  <Route path="/user/update/:userId" element={<UpdateUser />} />
                  <Route
                    path="/changepassword/:id"
                    element={<ChangePassword />}
                  />

                  {/* remittance */}
                  <Route path="/remittance" element={<Remittance />} />
                  <Route path="/remittance/new" element={<NewRemittance />} />
                  <Route
                    path="/remittance/update/:id"
                    element={<UpdateRemittance />}
                  />

                  {/* adjustment codes */}
                  <Route path="/adjustment" element={<Adjustments />} />
                  <Route
                    path="/adjustment/new"
                    element={<NewAdjustmentCode />}
                  />
                  <Route
                    path="/adjustment/update/:id"
                    element={<UpdateAdjustment />}
                  />

                  {/* revenue */}
                  <Route path="/revenues" element={<Revenue />} />
                  <Route path="/revenues/new" element={<NewRevenue />} />
                  <Route
                    path="/revenues/update/:id"
                    element={<UpdateRevenue />}
                  />

                  {/* procedure codes */}
                  <Route
                    path="/procedure-codes/new"
                    element={<NewProcedure />}
                  />
                  <Route
                    path="/procedure-codes/update/:id"
                    element={<UpdateProcedure />}
                  />
                  <Route path="/procedure-codes" element={<ProcedureCodes />} />

                  {/* ICD Procedures */}
                  <Route path="/icd-procedures" element={<ICDProcedure />} />
                  <Route
                    path="/icd-procedures/new"
                    element={<NewICDProcedureCodes />}
                  />
                  <Route
                    path="/icd-procedures/update/:id"
                    element={<UpdateICDProcedure />}
                  />

                  {/* activity */}
                  <Route
                    path="/patients/acitvity/:patientId/:type"
                    element={<ClaimActivity />}
                  />
                  <Route
                    // path="/patients/acitvity/:patientId/:type/:claimNumber"
                    path="/patients/acitvity/:patientId/:type/:claimId"
                    element={<ClaimActivity />}
                  />
                  {/* payer */}
                  <Route path="/payer" element={<Payer />} />
                  <Route path="/payer/create" element={<CreatePayer />} />
                  {/* <Route path="/payer/update/:id" element={<UpdatePayer />} /> */}
                  <Route
                    // path="/payer/update/:seqNumber"
                    path="/payer/update/:insuranceDescriptionId"
                    element={<UpdatePayer />}
                  />
                  {/* charge panel */}
                  <Route path="/charge-panel" element={<ChargePanel />} />
                  <Route
                    path="/charge-panel/new"
                    element={<NewChargePanel />}
                  />
                  <Route
                    path="/charge-panel/update/:id"
                    element={<UpdateChargePanel />}
                  />
                  {/* <Route path="/charge-panel/update:id" element={<ChargePanel />} /> */}
                  <Route path="/fee-schedule" element={<FeeSchedules />} />
                  <Route
                    path="/fee-schedule/new"
                    element={<CreateFeeSchedule />}
                  />
                  <Route
                    path="/fee-schedule/update/:feeSchedulesId"
                    element={<UpdateFeeSchedule />}
                  />
                  <Route path="/company" element={<Company />} />
                  <Route path="/company/new" element={<NewCompany />} />
                  <Route
                    // path="/company/update/:companyCode"
                    path="/company/update/:companyId"
                    element={<UpdateCompany />}
                  />

                  {/* protected route closed here */}
                </Route>
              </Routes>
            </Box>
          </div>
          <ToastContainer />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
