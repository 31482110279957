import { createSlice } from "@reduxjs/toolkit";
import { getReferringProviderAction,newReferringProviderAction,getReferringProviderByIdAction,updateRefferingProvider} from "../actions/referringProviderAction";
import { toast } from "react-toastify";

const initialState = {
  getReferringProviderData: [],
  createReferringProviderData: {},
  refProviderByIdData:{},
  error: null,
  loading: false,
};
const referringProviderSlice = createSlice({
  name: "referringProvider",
  initialState: initialState,

  extraReducers: {
    [getReferringProviderAction.pending]: (state) => {
      state.loading = true;
    },
    [getReferringProviderAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getReferringProviderData = action.payload;
      console.log(state.getReferringProviderData, "referringProvider");

    },
    [getReferringProviderAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "referringProviderErr");
    },
    [newReferringProviderAction.pending]: (state) => {
      state.loading = true;
    },
    [newReferringProviderAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createReferringProviderData = action.payload;
      toast.success("Referring Provider has been created successfully!");
    },
    [newReferringProviderAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "ReferringProvider Err");
      toast.error("Referring Provider Creation Failed.");
    },
    [getReferringProviderByIdAction.pending]: (state) => {
      state.loading = true;
    },
    [getReferringProviderByIdAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.refProviderByIdData = action.payload;
      console.log(action.paylaod, "ref Provider slice get payload");
    },
    [getReferringProviderByIdAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateRefferingProvider.pending]: (state) => {
      state.loading = true;
    },
    [updateRefferingProvider.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Reffering Provider Updated Successfully!");
    },
    [updateRefferingProvider.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "RefferingProviderErr");
      toast.error("Reffering Provider Updation Failed.");
    },

  },
});

export default referringProviderSlice.reducer;
