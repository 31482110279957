// import React, { useEffect, useState } from "react";
// import { Box, FormControl, useTheme } from "@mui/material";
// import CustomField from "../../../components/CustomField";
// import CustomDatePicker from "../../../components/CustomDatePicker";
// import TextArea from "../../../components/TextArea";
// import path from "../../../config/apiUrl";
// import { getData } from "../../../config/axiosFunctions";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
// const DiagnosisForm = ({ formik }) => {
//   console.log(formik.values);

//   const theme = useTheme();
//   // const mode = theme.palette.mode;
//   // const colors = theme.palette;
//   const [codeType, setCodeType] = useState([]);
//   console.log(codeType);

//   const dataFetchUrls = {
//     codeType: `${path}/CTICDcodetype`,
//   };
//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.codeType, setCodeType);
//   }, []);
//   return (
//     <>
//       <Box sx={{ display: "flex", gap: "10px", width: " 100%" }}>
//         <CustomField
//           type="text"
//           label="Code"
//           value={formik.values.diagnosisCode}
//           isRequired={true}
//           error={formik.errors}
//           touched={formik.touched}
//           name="diagnosisCode"
//           handleChange={formik.handleChange}
//           handleBlur={formik.handleBlur}
//         />
//         {/* <CustomField
//           type="text"
//           label="Code Type"
//           value={formik.values.codeType}
//           name="codeType"
//           disable={true}
//           handleChange={formik.handleChange}
//           handleBlur={formik.handleBlur}
//         /> */}
//         <CustomSelectBox2
//           formik={formik}
//           value={formik.values.codeTypeId}
//           name="codeTypeId"
//           dropdownOptions={codeType?.map((opt) => ({
//             value: opt.cticDcode,
//             id: opt.cticDcodetypeId,
//           }))}
//           label="Code Type"
//           handleChange={formik.handleChange}
//           handleBlur={formik.handleBlur}
//           showDefaultOpt={true}
//         />
//       </Box>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           width: " 60%",
//           margin: "8px 0px",
//         }}
//       >
//         <TextArea
//           type="text"
//           value={formik.values.diagnosisDescription}
//           handleChange={formik.handleChange}
//           handleBlur={formik.handleBlur}
//           name={"diagnosisDescription"}
//           minRows={3}
//           placeholder="Description"
//         />
//         {formik.errors["diagnosisDescription"] &&
//           formik.touched["diagnosisDescription"] && (
//             <div
//               style={{
//                 color: theme.palette.errorFieldsBorder.primary,
//                 border: `2px solid ${theme.palette.errorFieldsBorder.primary}`,
//                 fontSize: "1.3rem",
//                 marginTop: "8px",
//                 display: "block",
//               }}
//             >
//               {formik.errors["diagnosisDescription"]}
//             </div>
//           )}
//       </Box>
//       <Box sx={{ display: "flex", gap: "10px", width: " 60%" }}>
//         <FormControl fullWidth>
//           <CustomDatePicker
//             formik={formik}
//             dateLabel="Effective Date"
//             name="effectiveDate"
//             dateValue={formik.values.effectiveDate}
//             handleDateChange={formik.handleChange}
//             handleDateBlur={formik.handleBlur}
//           />
//         </FormControl>
//         <FormControl fullWidth>
//           <CustomDatePicker
//             formik={formik}
//             dateLabel="Termination Date"
//             name="terminationDate"
//             dateValue={formik.values.terminationDate}
//             handleDateChange={formik.handleChange}
//             handleDateBlur={formik.handleBlur}
//           />
//         </FormControl>
//       </Box>
//     </>
//   );
// };

// export default DiagnosisForm;

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  useTheme,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomDialog from "../../../components/CustomDialog";
import TextArea from "../../../components/TextArea";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import {handleIsActive} from "../../../utils/customFunctions"
const DiagnosisForm = ({ formik }) => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const theme = useTheme();
  const [codeType, setCodeType] = useState([]);
  console.log(codeType);

  const dataFetchUrls = {
    codeType: `${path}/CTICDcodetype`,
  };
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.codeType, setCodeType);
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/diagnosis");
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: "10px",
            padding: "10px",
          }}
        >
          <CustomButton type="submit" isBlue={true} isSave={true}>
            Save
          </CustomButton>

          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
          {formik.values.diagnosisCodeId && (
    <Box sx={{ marginLeft:'auto'}}> 
      <CheckBox
        CheckedValue={!formik.values.isActive}
        handleChange={() =>
          // formik.setFieldValue("isActive",!formik.values.isActive)
        handleIsActive(formik)
        }
        label="Make this Diagnosis inactive"
      />
    </Box>
  )}
        </Box>
        <Paper elevation={3} style={{ padding: 20, margin: 15 }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <CustomField
                type="text"
                label="Code"
                value={formik.values.diagnosisCode}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                name="diagnosisCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={6}>
              <CustomSelectBox2
                formik={formik}
                value={formik.values.codeTypeId}
                name="codeTypeId"
                dropdownOptions={codeType?.map((opt) => ({
                  value: opt.cticDcode,
                  id: opt.cticDcodetypeId,
                }))}
                label="Code Type"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />
            </Grid>
            <Grid item md={12}>
              <CustomField
                type="text"
                label="Description"
                value={formik.values.diagnosisDescription}
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                name="diagnosisDescription"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDatePicker
                formik={formik}
                dateLabel="Effective Date"
                name="effectiveDate"
                dateValue={formik.values.effectiveDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
                isFromTo={true}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDatePicker
                dateLabel={"Termination Date"}
                handleDateBlur={formik?.handleBlur}
                handleDateChange={formik?.handleChange}
                name={"terminationDate"}
                isFromTo={true}
                formik={formik}
                dateValue={formik?.values?.terminationDate}
              />
            </Grid>
          </Grid>
        </Paper>
        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
      </form>
    </>
  );
};

export default DiagnosisForm;
