import { Box, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProviderAction } from "../../../features/actions/providerAction";
import CustomButton from "../../../components/CustomButton";
import { getProviderBySearchTermAction } from "../../../features/actions/providerAction";
import CustomPreview from "../../../layout/CustomPreview";
import { providerCols } from "../../../utils/gridCols";
import CustomDialog from "./../../../components/CustomDialog";
import { switchPracticeAction } from "../../../features/actions/userAction";

const Provider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [openSwitchWarning, setOpenSwitchWarning] = useState(false);
  const [practiceForSwitch, setPracticeForSwitch] = useState({
    seqno: null,
    name:""
  });
  const { getProviders, loading } = useSelector((state) => state.provider);
  console.log(getProviders, "checking provider");
  const rows =
    getProviders && getProviders.result?.providers?.length > 0
      ? getProviders?.result?.providers?.map((el) => ({
          id: el.providerId,
          ...el,
        }))
      : [];

  const handleCellProvider = (params) => {
    const practiceSeq = localStorage.getItem("practiceSequenceNo");
    if (Number(practiceSeq) === params?.practiceSequenceNo) {
      setOpenSwitchWarning(false);
      setPracticeForSwitch({
        seqno:null,
        name:""
      })
      navigate(`/provider/update/${params.providerSequenceNo}`);
    } else {
      setOpenSwitchWarning(true);
      setPracticeForSwitch({
        ...practiceForSwitch,
        seqno:params.practiceSequenceNo,
        name:params?.practiceName
      })
    }
  };

  const handleSwitchPractice = async (getSeq) => {
    try {
      localStorage.setItem("practiceSequenceNo", getSeq)
      await dispatch(switchPracticeAction(getSeq)).then(({ payload }) =>
        localStorage.setItem("token", payload.result.token)
      );
      window.location.reload();
    } catch (err) {
      console.log("Error switching practice", err);
    }
    return;
  };
  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={providerCols}
        loading={loading}
        paginationDetail={getProviders}
        handleGetAll={getProviderAction}
        handleBySearch={getProviderBySearchTermAction}
        searchLabel="Search by Provider Name and Sequence No"
        moduleHeading="All Providers"
        showAll={true}
        handleCell={(params) => handleCellProvider(params)}
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/provider/new")}
          >
            Add Provider
          </CustomButton>
        </Stack>
      </CustomPreview>

      {/* custo modal */}
      <CustomDialog
        open={openSwitchWarning}
        confirmButtonTitle={"Ok"}
        isWarning={true}
        handleConfirm={() => setOpenSwitchWarning(false)}
      >
        <Typography component={"h2"}>
          You need to switch the practice <Link sx={{cursor:"pointer"}} onClick={() => handleSwitchPractice(practiceForSwitch?.seqno)}>{practiceForSwitch?.name} ({practiceForSwitch?.seqno})</Link>  to edit the provider
        </Typography>
      </CustomDialog>
    </Box>
  );
};

export default Provider;
