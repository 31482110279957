import { Grid,Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { newProviderAction } from "../../../features/actions/providerAction";
import MainHeading from "../../../components/MainHeading";
import ProviderInfo from "./ProviderInfo";
import { providerInitValues } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import {useNavigate} from "react-router-dom"
import { providerSchema } from "../../../schemas/patientSchema";
import { handleCancel } from "../../../utils/customFunctions";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const NewProvider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: providerInitValues,
    validationSchema : providerSchema,
    onSubmit: (values, action) => {
      console.log(values,"values provider")
      try {
        dispatch(newProviderAction(values));
      } catch (error) {
        throw new Error(error);
      }
      action.resetForm();
      navigate("/provider")
    },
  });


  return (
    <>
          <ProviderInfo formik={formik} />
    </>
  );
};

export default NewProvider;
