import { Box,Typography } from "@mui/material";
import { useState } from "react";
import React, { useEffect } from "react";
import MainHeading from "../../../components/MainHeading";
import RemittanceInfo from "./RemittanceInfo";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleRemittanceAction,
  updateRemittanceAction,
} from "../../../features/actions/remittanceAction";
import { useFormik } from "formik";
import { remittanceValues } from "../../../utils/formikInitValues";
import { remittanceSchema } from "../../../schemas/patientSchema";
import { toast } from "react-toastify";
import CustomDialog from "../../../components/CustomDialog";

const UpdateRemittance = () => {
  let flage = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getSingleRemittance, loading } = useSelector((state) => state.remittance);
  console.log(getSingleRemittance, "checkSingleRemittance")
  const formik = useFormik({
    initialValues: getSingleRemittance.result || remittanceValues,
    enableReinitialize: true,
    validationSchema: remittanceSchema,
    onSubmit: (values) => {
    try {
        dispatch(
          updateRemittanceAction({
            remittanceCodeId: getSingleRemittance?.result?.remittanceCodeId,
            ...values,
          })
        );
      } catch (err) {
        console.error("Error updating remittance:", err);
        throw err;
      }
      navigate("/remittance");
    },
  });
  useEffect(() => {
    if(!flage){
flage = true

        dispatch(getSingleRemittanceAction(id));
    }
  }, [id,dispatch]);

  return (
    <>
          <RemittanceInfo formik={formik} />
    </>
  );
};

export default UpdateRemittance;
