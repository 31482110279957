// import {
//   Box,
//   Grid,
//   Stack,
//   Typography,
//   useTheme,
//   Link,
//   FormGroup,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import MultiButton from "../../components/MultiButton";
// import CustomButton from "../../components/CustomButton";
// import CustomModal from "../../components/CustomModal";
// import InsuredModalForm from "./InsuredModalForm";
// import InsuredPartyDetails from "./InsuredPartyDetails";
// const MainInsurance = ({ formik }) => {
//   const [openInsModal, setOpenInsModal] = useState(false);
//   const [priorityId, setPriorityId] = useState(1);
//   const [editMode, setEditMode] = useState(false);
//   const [editIndex, setEditIndex] = useState(0);
//   const [openInsuredPartyDetail, setOpenInsuredPartyDetail] = useState(false);
//   const [insuredId, setInsuredId] = useState(null);
//   // insranse function
//   const handleAddInsurance = (values) => {
//     if (!editMode) {
//       // Ensure insurancePoliciesDtos exists and is an array
//       formik.setValues((prevValues) => ({
//         ...prevValues,
//         insurancePoliciesDtos: [
//           ...(prevValues.insurancePoliciesDtos || []), // Default to empty array if undefined
//           values,
//         ],
//       }));
//     } else {
//       formik.setValues((prevValues) => {
//         const updatedPolicies = [...prevValues.insurancePoliciesDtos];
//         updatedPolicies[editIndex] = values; // Update the specific item
//         return {
//           ...prevValues,
//           insurancePoliciesDtos: updatedPolicies,
//         };
//       });
//     }
//     // Close the insurance modal
//     setOpenInsModal(false);
//   };

//   // handle Edit
//   const handleEdit = (index) => {
//     setEditIndex(index);
//     setEditMode(true);
//     setOpenInsModal(true);
//   };

//   const theme = useTheme();
//   const multiBtn = [
//     { id: 1, title: "Primary" },
//     { id: 2, title: "Secondary" },
//     { id: 3, title: "Tertiary" },
//   ];

//   // vire insured detail function
//   const handleViewInsuredDetails = (insId) => {
//     setInsuredId(insId);
//     setOpenInsuredPartyDetail(true);
//   };
//   // handleInactive
//   const handleInactive = (ind) => {
//     const updatedPolicy = formik.values.insurancePoliciesDtos?.map(
//       (policy, i) => {
//         if (i === ind) {
//           return { ...policy, isActive: !policy.isActive };
//         }
//         return policy;
//       }
//     );

//     // Update the formik values with the updated insurancePoliciesDtos
//     formik.setValues((prevValues) => ({
//       ...prevValues,
//       insurancePoliciesDtos: updatedPolicy,
//     }));
//   };

//   console.log(formik.values.insurancePoliciesDtos, "insurancePoliciesDtos")
//   return (
//     <>
//       <Grid container spacing={2}>
//         <Grid item md={12}>
//           <Box
//             sx={{
//               background: "white",
//               width: "100wv",
//               height: "100vh",
//               position: "relative",
//               overflow:'auto'
//             }}
//           >
//             <Grid
//               container
//               sx={{ background: "#FAFAFA", height: "8%", display: "flex" }}
//             >
//               <Grid item md={10}>
//                 <Typography
//                   variant="h4"
//                   fontWeight={600}
//                   sx={{ position: "absolute", left: "30px", top: "15px" }}
//                 >
//                   Insurance Info
//                 </Typography>
//               </Grid>
//               <Grid item md={2}>
//                 <MultiButton
//                   margin={"8px 0 0 30px"}
//                   title={"Add New"}
//                   multiOpts={multiBtn?.map((opt) => ({
//                     id: opt?.id,
//                     value: opt?.title,
//                   }))}
//                   onSelect={(value) => {
//                     setPriorityId((preVal) => (preVal = value.id));
//                     setOpenInsModal(true);
//                   }}
//                 />
//               </Grid>
//             </Grid>
//             {formik.values.insurancePoliciesDtos.length >= 1 &&
//               formik.values.insurancePoliciesDtos.map((value, index) => {
//                 return (
//                   <Box
//                     key={index}
//                     sx={{
                      
//                       border: "2px solid #CCCCCC",
//                       borderRadius: "9px",
//                       margin: "17px 0",
//                       margin: "20px 10px ",
//                     }}
//                   >
//                     <Grid
//                       container
//                       sx={{
//                         background: theme.palette.insuranceTable.primary,
//                         height: "60px",
//                         borderBottom: "2px solid #CCCCCC",
                     
//                       }}
//                     >
//                       <Grid item md={6}>
//                         <Typography
//                           variant="h3"
//                           padding={"10px"}
//                           width={"50%"}
//                           sx={{ color: "#3A689A", fontWeight: "800" }}
//                         >
//                           {value?.insuranceDescriptionName} (
//                           {value?.insuranceDescriptionSeqNo}){" "}
//                           {value?.payerInfoPriorityId === 1
//                             ? " | P"
//                             : value?.payerInfoPriorityId === 2
//                             ? " | S"
//                             : " | T"}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6}>
//                         <Stack
//                           direction="row"
//                           justifyContent={"end"}
//                           padding={"10px"}
//                           columnGap="10px"
//                         >
//                          {
//                           !value?.isDefault && (
//                             <FormGroup>
//                             <CustomButton
//                               isBlue={false}
//                               // handleClick={() => {
//                               //   handleEdit(index);
//                               // }}
//                             >
//                               Set Default
//                             </CustomButton>
//                           </FormGroup>
//                           )
//                          }
//                           <FormGroup>
//                             <CustomButton
//                               isBlue={true}
//                               handleClick={() => {
//                                 handleEdit(index);
//                               }}
//                             >
//                               Edit
//                             </CustomButton>
//                           </FormGroup>


//                           <FormGroup>
//                             <CustomButton
//                               isBlue={false}
//                               variant="text"
//                               handleClick={() => handleInactive(index)}
//                             >
//                               {value?.isActive ? "Inactive" : "Reactive"}
//                             </CustomButton>
//                           </FormGroup>


//                         </Stack>
//                       </Grid>
//                     </Grid>
//                     <Grid container spacing={4} padding={2}>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Priority:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {value?.payerInfoPriorityName}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Insured:
//                         </Typography>
//                         {value?.insuredPartyId ? (
//                           <Link
//                             href="#"
//                             sx={{
//                               fontSize: "1.3rem",
//                             }}
//                             onClick={() => handleViewInsuredDetails(value?.insuredPartyId)}
//                           >
//                             {`${value.insuredFirstName} ${value.insuredLastName}`}
//                           </Link>
//                         ) : (
//                           <Typography
//                             variant="h6"
//                             sx={{ color: "#B8B8B8" }}
//                           >{`${value.insuredFirstName} ${value.insuredLastName}`}</Typography>
//                         )}
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Member ID:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {value?.payerInfoMemberId}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Group ID:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {value?.payerInfoGroupId}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Copay:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {`${value?.payerInfoCopayAmount}`}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Co-Insurance:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {`${value?.payerInfoCoInsurancePercent}`}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Deductible:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {`${value?.payerInfoDeductibleAmount}`}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={3}>
//                         <Typography component="h4" fontWeight={600}>
//                           Out Of Pocket Max:
//                         </Typography>
//                         <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
//                           {`${value?.payerInfoOutOfPocketMax}`}
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 );
//               })}
//           </Box>
//         </Grid>
//       </Grid>
//       <CustomModal
//         open={openInsModal}
//         handleClose={() => {
//           setOpenInsModal(false);
//           setEditMode(false);
//         }}
//         heading="Add Policy"
//         height={"70%"}
//       >
//         <InsuredModalForm
//           editMode={editMode}
//           formik={formik}
//           handleAddInsurance={handleAddInsurance}
//           openInsModal={setOpenInsModal}
//           editIndex={editIndex}
//           priorityId={priorityId}
//         />
//       </CustomModal>
//       {/* insured party modal */}
//       <CustomModal
//         open={openInsuredPartyDetail}
//         handleClose={() => setOpenInsuredPartyDetail(false)}
//         heading="Insured Party Details"
//         height="auto"
//       >
//         <InsuredPartyDetails insuredPartyId={insuredId} />
//       </CustomModal>
//     </>
//   );
// };

// export default MainInsurance;



import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
  Link,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MultiButton from "../../components/MultiButton";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import InsuredModalForm from "./InsuredModalForm";
import InsuredPartyDetails from "./InsuredPartyDetails";
const MainInsurance = ({ formik }) => {
  const [openInsModal, setOpenInsModal] = useState(false);
  const [priorityId, setPriorityId] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [openInsuredPartyDetail, setOpenInsuredPartyDetail] = useState(false);
  const [insuredId, setInsuredId] = useState(null);
  // insranse function
  const handleAddInsurance = (values) => {
    if (!editMode) {
      // Ensure insurancePoliciesDtos exists and is an array
      formik.setValues((prevValues) => ({
        ...prevValues,
        insurancePoliciesDtos: [
          ...(prevValues.insurancePoliciesDtos || []), // Default to empty array if undefined
          values,
        ],
      }));
    } else {
      formik.setValues((prevValues) => {
        const updatedPolicies = [...prevValues.insurancePoliciesDtos];
        updatedPolicies[editIndex] = values; // Update the specific item
        return {
          ...prevValues,
          insurancePoliciesDtos: updatedPolicies,
        };
      });
    }
    // Close the insurance modal
    setOpenInsModal(false);
  };

  // handle Edit
  const handleEdit = (index) => {
    setEditIndex(index);
    setEditMode(true);
    setOpenInsModal(true);
  };

  const theme = useTheme();
  const multiBtn = [
    { id: 1, title: "Primary" },
    { id: 2, title: "Secondary" },
    { id: 3, title: "Tertiary" },
  ];

  // vire insured detail function
  const handleViewInsuredDetails = (insId) => {
    setInsuredId(insId);
    setOpenInsuredPartyDetail(true);
  };
  // handleInactive
  const handleInactive = (ind) => {
    const updatedPolicy = formik.values.insurancePoliciesDtos?.map(
      (policy, i) => {
        if (i === ind) {
          return { ...policy, isActive: !policy.isActive };
        }
        return policy;
      }
    );

    // Update the formik values with the updated insurancePoliciesDtos
    formik.setValues((prevValues) => ({
      ...prevValues,
      insurancePoliciesDtos: updatedPolicy,
    }));
  };

  console.log(formik.values.insurancePoliciesDtos, "insurancePoliciesDtos")

 
  // const handleDefault = (val, val2, index) => {
  //   if (val == 1 && val2 == false) {
  //     console.log(formik.values.insurancePoliciesDtos,"policydto")
  //     const updated = [...formik.values.insurancePoliciesDtos];
  //     updated[index].isDefault = true;
  
  //     formik.setFieldValue('insurancePoliciesDtos', updated);
  //   }
  // };

  // const handleDefault = (priorityId, isDefault, index) => {
  //   console.log(index,"index1")
  //   if (!isDefault) {
  //     const updated = [...formik.values.insurancePoliciesDtos];
  //     console.log(updated,"updated")
  //     updated[index].isDefault = true;

  //     updated.forEach((policy, i) => {
  //       console.log(policy,"policy")
  //       console.log(i,"index")
  //       if (i !== index) {
  //         policy.isDefault = false;
  //       }
  //     });
  //     formik.setFieldValue("insurancePoliciesDtos", updated);
  //   }
  // };

  const handleDefault = (priorityId, isDefault, index) => {
    console.log(isDefault,"defaultValue")
    if (!isDefault) {
      const updated = [...formik.values.insurancePoliciesDtos];
      console.log(updated,"updatedarray")
  
      // Find the relevant policy 
      const policy = updated.find((policy) => policy.payerInfoPriorityId === priorityId && policy.isDefault === false);
      console.log(policy,"policy123")
      if (policy) {
        updated.forEach((policy, i) => {
          console.log(policy,"policyyy")
        console.log(i,"index")
          if (policy.payerInfoPriorityId === priorityId) {
            if (i === index) {
              policy.isDefault = true; 
            } else {
              policy.isDefault = false; 
            }
          }
        });
        formik.setFieldValue("insurancePoliciesDtos", updated);
      }
    }
  };
  
  
  

  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box
            sx={{
              background: "white",
              width: "100wv",
              height: "100vh",
              position: "relative",
              overflow:'auto'
            }}
          >
            <Grid
              container
              sx={{ background: "#FAFAFA", height: "8%", display: "flex" }}
            >
              <Grid item md={10}>
                <Typography
                  variant="h4"
                  fontWeight={600}
                  sx={{ position: "absolute", left: "30px", top: "15px" }}
                >
                  Insurance Info
                </Typography>
              </Grid>
              <Grid item md={2}>
                <MultiButton
                  margin={"8px 0 0 30px"}
                  title={"Add New"}
                  multiOpts={multiBtn?.map((opt) => ({
                    id: opt?.id,
                    value: opt?.title,
                  }))}
                  onSelect={(value) => {
                    setPriorityId((preVal) => (preVal = value.id));
                    setOpenInsModal(true);
                  }}
                />
              </Grid>
            </Grid>
            {formik.values.insurancePoliciesDtos.length >= 1 &&
              formik.values.insurancePoliciesDtos.map((value, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      
                      border: "2px solid #CCCCCC",
                      borderRadius: "9px",
                      margin: "17px 0",
                      margin: "20px 10px ",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        background: theme.palette.insuranceTable.primary,
                        height: "60px",
                        borderBottom: "2px solid #CCCCCC",
                     
                      }}
                    >
                      <Grid item md={6}>
                        <Typography
                          variant="h3"
                          padding={"10px"}
                          width={"50%"}
                          sx={{ color: "#3A689A", fontWeight: "800" }}
                        >
                          {value?.insuranceDescriptionName} (
                          {value?.insuranceDescriptionSeqNo}){" "}
                          {value?.payerInfoPriorityId === 1
                            ? " | P"
                            : value?.payerInfoPriorityId === 2
                            ? " | S"
                            : " | T"}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Stack
                          direction="row"
                          justifyContent={"end"}
                          padding={"10px"}
                          columnGap="10px"
                        >
                         {
                          !value?.isDefault && (
                            <FormGroup>
                            <CustomButton
                              isBlue={false}
                              handleClick={() => {
                                handleDefault(value?.payerInfoPriorityId,value?.isDefault,index);
                              }}
                            >
                              Set Default
                            </CustomButton>
                          </FormGroup>
                          )
                         }

                          <FormGroup>
                            <CustomButton
                              isBlue={true}
                              handleClick={() => {
                                handleEdit(index);
                              }}
                            >
                              Edit
                            </CustomButton>
                          </FormGroup>


                          <FormGroup>
                            <CustomButton
                              isBlue={false}
                              variant="text"
                              handleClick={() => handleInactive(index)}
                            >
                              {value?.isActive ? "Inactive" : "Reactive"}
                            </CustomButton>
                          </FormGroup>


                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} padding={2}>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Priority:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {value?.payerInfoPriorityName}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Insured:
                        </Typography>
                        {value?.insuredPartyId ? (
                          <Link
                            href="#"
                            sx={{
                              fontSize: "1.3rem",
                            }}
                            onClick={() => handleViewInsuredDetails(value?.insuredPartyId)}
                          >
                            {`${value.insuredFirstName} ${value.insuredLastName}`}
                          </Link>
                        ) : (
                          <Typography
                            variant="h6"
                            sx={{ color: "#B8B8B8" }}
                          >{`${value.insuredFirstName} ${value.insuredLastName}`}</Typography>
                        )}
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Member ID:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {value?.payerInfoMemberId}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Group ID:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {value?.payerInfoGroupId}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Copay:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {`${value?.payerInfoCopayAmount}`}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Co-Insurance:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {`${value?.payerInfoCoInsurancePercent}`}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Deductible:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {`${value?.payerInfoDeductibleAmount}`}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="h4" fontWeight={600}>
                          Out Of Pocket Max:
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#B8B8B8" }}>
                          {`${value?.payerInfoOutOfPocketMax}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        open={openInsModal}
        handleClose={() => {
          setOpenInsModal(false);
          setEditMode(false);
        }}
        heading="Add Policy"
        height={"70%"}
      >
        <InsuredModalForm
          editMode={editMode}
          formik={formik}
          handleAddInsurance={handleAddInsurance}
          openInsModal={setOpenInsModal}
          editIndex={editIndex}
          priorityId={priorityId}
        />
      </CustomModal>
      {/* insured party modal */}
      <CustomModal
        open={openInsuredPartyDetail}
        handleClose={() => setOpenInsuredPartyDetail(false)}
        heading="Insured Party Details"
        height="auto"
      >
        <InsuredPartyDetails insuredPartyId={insuredId} />
      </CustomModal>
    </>
  );
};

export default MainInsurance;
