import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomExpandIcon from "./CustomExpandIcon";
import CustomButton from "./CustomButton";
// import { Search } from "@mui/icons-material";

const CustomAccordion = ({
  heading,
  children,
  defaultExpanded,
  maxHeight,
  hasErrors,
  isAdditional,
  button,
  handleSearch,
  handleRemoveData,
  isRemove,
  margin,
  padding
}) => {
  const theme = useTheme();
console.log(hasErrors, "hasError");

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent accordion from closing
    handleSearch();
  };
  const handleRemoveClick = (event) => {
    event.stopPropagation(); // Prevent accordion from closing
    handleRemoveData();
  };

  return (
    <Accordion defaultExpanded={defaultExpanded} >
      <AccordionSummary
        // sx={{ backgroundColor: "#FAFAFA" }}
        sx={{
          background: theme.palette.accordiansBackground.primary,
          border: hasErrors ? theme.palette.errorFieldsBorder.primary : "none",
        }}
        expandIcon={
          <CustomExpandIcon
            sx={{ background: theme.palette.accordiansHeadingText.primary }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          width="100%"
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            className="accordianSummaryHeading"
            sx={{ color: hasErrors ? "red" : theme.palette.accordiansHeadingText.primary }}
            component="h3"
            fontWeight="700"
          >
            {heading}
          </Typography>

          {isAdditional && (
            <Stack direction="row" alignItems="center" gap={"10px"}>
              <CustomButton
                handleClick={handleButtonClick}
                isBlue={true}
                isSearch={true}
              />
              {isRemove && (
                <CustomButton handleClick={handleRemoveClick} isDelete={true} />
              )}
            </Stack>
          )}
        </Stack>
        {/* <button style={{fontSize:'1rem',position:'relative',left:'60%'}}>{button}</button> */}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          maxHeight: maxHeight,
          overflowY: maxHeight && "scroll",
          background: theme.palette.accordiansBodyBackground.primary,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
