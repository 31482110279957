// // import React from 'react';
// // import { Typography, Stack, Box,useTheme } from '@mui/material';
// // import Card from '@mui/material/Card';
// // import CardContent from '@mui/material/CardContent';
// // import UserImage1 from '../../../assets/userr1.svg';
// // import UserImage2 from '../../../assets/user2.png';
// // import UserImage3 from '../../../assets/user3.png';
// // import UserImage4 from '../../../assets/user4.png';
// // import MoreVertIcon from '@mui/icons-material/MoreVert';

// // const PatientRecords = () => {
// //   const theme=useTheme();
// //   const cardObj = [
// //     { id: '1', time: '1:15 pm', image: UserImage1, name: 'Dakota Smith', AccNo: '0983547737' },
// //     { id: '2', time: '1:15 pm', image: UserImage2, name: 'Dakota Smith', AccNo: '0373547534' },
// //     { id: '3', time: '1:15 pm', image: UserImage3, name: 'Dakota Smith', AccNo: '0386548931' },
// //     { id: '4', time: '1:15 pm', image: UserImage4, name: 'Dakota Smith', AccNo: '0356258936' },
// //     { id: '5', time: '1:15 pm', image: UserImage2, name: 'Dakota Smith', AccNo: '0356258932' },
// //     { id: '6', time: '1:15 pm', image: UserImage1, name: 'Dakota Smith', AccNo: '0356258937' },
// //     { id: '7', time: '1:15 pm', image: UserImage4, name: 'Dakota Smith', AccNo: '0356258936' },
// //     { id: '8', time: '1:15 pm', image: UserImage3, name: 'Dakota Smith', AccNo: '0356258934' },
// //     { id: '9', time: '1:15 pm', image: UserImage2, name: 'Dakota Smith', AccNo: '0356258935' },
    
// //   ];
// //   console.log("cardObject", cardObj);

// //   return (
// //     <>
// //       <Card className='scrollBar' sx={{  width: '100%', maxHeight: '355px', overflowY: 'auto',
// //       background:theme.palette.announcementCard.primary,}}>
// //         <CardContent>
// //           <Box marginTop={'7px'} sx={{ display: 'flex', width: '100%' }}>
// //             <Typography sx={{color:theme.palette.patientsHeading.primary,}} fontWeight={'600'} className='todayPatientHead'>Your Patients Today</Typography>
// //             <Typography sx={{ marginLeft: { lg: '8%', md: '60%', sm: '60%', xs: '60%', xl: '55%' },
// //             color:theme.palette.allPatientHeading.primary}} className='todayPatientHead2'>All Patients</Typography>
// //           </Box>
// //           <div>
// //             {cardObj.map((item) => (
// //               <Stack spacing={4} direction='row' marginTop={'10px'} key={item.id}>
// //                 <Box>
// //                   <Typography sx={{ color: 'black', fontSize: '1.5rem',color:theme.palette.globalText.primary, }}>{item.time}</Typography>
// //                 </Box>
// //                 <Box sx={{background:theme.palette.patientAcc.primary,}}className='patientAccBox'>
// //                   <Stack spacing={4} direction='row' padding={'1px'}>
// //                     <img style={{ width: '34px', padding: '3px' }} src={item.image} alt="adminImg" />
// //                     <Stack direction='column'>
// //                       <Typography fontWeight={'600'} sx={{ color:theme.palette.globalText.primary, fontSize: '1.2rem',width:'100%' }}>{item.name}</Typography>
// //                       <Typography sx={{ color:theme.palette.announcementText.primary, fontSize: '1rem' }}>{item.AccNo}</Typography>
// //                     </Stack>
// //                     <MoreVertIcon style={{ marginTop: '10px', fontSize: '2.5rem', color: '#1B70FA' }} />
// //                   </Stack>
// //                 </Box>
// //               </Stack>
// //             ))}
// //           </div>
// //         </CardContent>
// //       </Card>
// //     </>
// //   );
// // };

// // export default PatientRecords;






// api
// import React, { useState,useEffect } from 'react';
// import { Typography, Stack, Box,useTheme } from '@mui/material';
// import Card from '@mui/material/Card';
// import { Link } from 'react-router-dom';
// import CardContent from '@mui/material/CardContent';
// import UserImage1 from '../../../assets/userr1.svg';
// import UserImage2 from '../../../assets/user2.png';
// import UserImage3 from '../../../assets/user3.png';
// import UserImage4 from '../../../assets/user4.png';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// const PatientRecords = () => {

//   const [apiData, setApiData] = useState([]);
//   const theme = useTheme();
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://192.168.3.73:86/api/dashboard');
//         const data = await response.json()

//         const formattedData = data.result[0].todaysPatientsDto.map(item => ({
//           accountNo: item.accountNo,
//           patientName: item.patientName,
//           patientCreatedOn: new Date(item.patientCreatedOn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
//         }));
//         setApiData(formattedData)

//         // setApiData(data.result[0].todaysPatientsDto);
//       } catch (error) {
//         console.log("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);
  
      

//   return (
//     <>
//       <Card
//         className='scrollBar'
//         sx={{
//           width: '100%',
//           maxHeight: '355px',
//           overflowY: 'auto',
//           background: theme.palette.announcementCard.primary,
//         }}
//       >
//         <CardContent>
//           <Box marginTop={'7px'} sx={{ display: 'flex', width: '100%' }}>
//             <Typography
//               sx={{
//                 color: theme.palette.patientsHeading.primary,
//               }}
//               fontWeight={'600'}
//               className='todayPatientHead'
//             >
//               Your Patients Today
//             </Typography>
//             <Typography
//               component={Link}
//               to="/managepatient"
//               sx={{
//                 marginLeft: { lg: '8%', md: '60%', sm: '60%', xs: '60%', xl: '55%' },
//                 color: theme.palette.allPatientHeading.primary,
//                 textDecoration: 'none',
//               }}
//               className='todayPatientHead2'
//             >
//               All Patients
//             </Typography>
//           </Box>
//           <div>
//               {apiData.map((item) => (
//                 <Stack spacing={4} direction='row' marginTop={'10px'} key={item.accountNo}>
//                   <Box>
//                     <Typography
//                       sx={{
//                         color: theme.palette.globalText.primary,
//                         fontSize: '1.5rem',
//                         marginTop:'7px',
//                         fontFamily:'poppins',
//                       }}
//                     >
//                       {item.patientCreatedOn}
//                     </Typography>
//                   </Box>
//                   <Box sx={{ background: theme.palette.patientAcc.primary }} className='patientAccBox'>
//                     <Stack sx={{ width:'80%',padding:'6px'}}  spacing={4} direction='row' padding={'1px'}>
//                       <Stack direction='column'>
//                         <Typography
//                           fontWeight={'600'}
//                           sx={{
//                             color: theme.palette.globalText.primary,
//                             fontSize: '1.5rem',
//                             fontFamily:'poppins'
//                           }}
//                         >
//                           {item.patientName}
//                         </Typography>
//                         <Typography
//                           sx={{
//                             color: theme.palette.announcementText.primary,
//                             fontSize: '1.3rem',
//                             fontFamily:'poppins'
//                           }}
//                         >
//                          {item.accountNo}
//                         </Typography>
//                       </Stack>
//                       {/* <MoreVertIcon
//                         style={{
//                           marginTop: '10px',
//                           fontSize: '2.5rem',
//                           color: '#1B70FA',
//                         }}
//                       /> */}
//                     </Stack>
//                   </Box>
//                 </Stack>
//               ))
//           }
//           </div>
//         </CardContent>
//       </Card>
//     </>
//   );
// };

// export default PatientRecords;







import React, { useState,useEffect } from 'react';
import { Typography, Stack, Box,useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import UserImage1 from '../../../assets/userr1.svg';
import UserImage2 from '../../../assets/user2.png';
import UserImage3 from '../../../assets/user3.png';
import UserImage4 from '../../../assets/user4.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const PatientRecords = ({data}) => {
  const theme = useTheme();
  console.log(data, "data in patient records")
  const todaysPatients = data && data[0] ? data[0].todaysPatientsDto : [];
  return (

    <>
      <Card
        className='scrollBar'
        sx={{
          width: '100%',
          // maxHeight: '355px',
          minHeight: '100%', 
          overflowY: 'auto',
          background: theme.palette.announcementCard.primary,
        }}
      >
        <CardContent>
          <Box marginTop={'7px'} sx={{ display: 'flex', width: '100%' }}>
            <Typography
              sx={{
                color: theme.palette.patientsHeading.primary,
              }}
              fontWeight={'600'}
              className='todayPatientHead'
            >
              Your Patients Today
            </Typography>
            <Typography
              component={Link}
              to="/patients"
              sx={{
                marginLeft: { lg: '8%', md: '60%', sm: '60%', xs: '60%', xl: '55%' },
                color: theme.palette.allPatientHeading.primary,
                textDecoration: 'none',
              }}
              className='todayPatientHead2'
            >
              All Patients
            </Typography>
          </Box>
          {todaysPatients.length ? (
              todaysPatients.map((item,index) => (
                <Stack key = {index} spacing={4} direction='row' marginTop={'10px'}>
                <Box>
                        <Typography
                          sx={{
                            color: theme.palette.globalText.primary,
                            fontSize: '1.5rem',
                            marginTop:'7px',
                            fontFamily:'poppins',
                          }}
                        >
                       {item.patientCreatedOn}
                        </Typography>
                      </Box>
                      <Box sx={{ background: theme.palette.patientAcc.primary }} className='patientAccBox'>
                        <Stack sx={{ width:'80%',padding:'6px'}}  spacing={4} direction='row' padding={'1px'}>
                          <Stack direction='column'>
                            <Typography
                              fontWeight={'600'}
                              sx={{
                                color: theme.palette.globalText.primary,
                                fontSize: '1.5rem',
                                fontFamily:'poppins'
                              }}
                            >
                              {item.patientName}
                            </Typography>
                            <Typography
                              sx={{
                                color: theme.palette.announcementText.primary,
                                fontSize: '1.3rem',
                                fontFamily:'poppins'
                              }}
                            >
                        {item.accountNo}
                            </Typography>
                          </Stack>
                  
                        </Stack>
                      </Box>
                </Stack>
              ))
            ) : (
              <Typography sx={{ textAlign: 'center', marginTop: '30px',fontSize:'2rem'}}>
              No data available
            </Typography>
            )}

        </CardContent>
      </Card>
    </>
  );
};

export default PatientRecords;








