import { Cancel, Warning } from "@mui/icons-material";
import { Box,  Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const StatusError = ({ errData }) => {
  console.log(errData, "errDataHere");
  return (
    <Stack padding="15px 35px" gap={"15px"}>
      <Stack direction="row" gap="50px">
        <Typography component="h5">
          Claim ID: <Link to={`/claims/update/${errData?.claimNo}`}>{errData?.claimNo}</Link>
        </Typography>
        <Typography component="h5">Run Date: {errData?.runTime}</Typography>
      </Stack>

      <Stack gap={"15px "} sx={{ border: ".5px solid lightgrey", borderRadius:"8px", paddingBottom:"15px"}}>
        <Typography
          sx={{
            background: "#f8f9fa",
            padding: "10px",
            width: "100%",
            fontSize: "1.5rem",
            fontWeight: 600,
            letterSpacing: "1.2px",
            borderTopLeftRadius:"8px",
            borderTopRightRadius:"8px"
          }}
        >
          Results
        </Typography>
        <Stack rowGap={"15px"} sx={{padding:"10px 20px"}}>

        <Stack direction={"row"} gap="7px" alignItems={"center"}>
          <Warning sx={{ color: "#ffc107", fontSize: "22px" }} />{" "}
          <Typography sx={{ fontSize: "1.5rem" }}>
            Claim not analyzed by RCM Software Edits.
          </Typography>{" "}
        </Stack>
        <Stack direction={"row"} gap="7px" alignItems={"center"}>
          <Cancel sx={{ color: "red", fontSize: "22px" }} />{" "}
          <Typography sx={{ fontSize: "1.5rem" }}>
            Claim not analyzed by Clearinghouse Edits. An issue was found.{" "}
            <br />{" "}
            <Typography
              sx={{ color: "#343a40", fontSize: "1.4rem" }}
              component={"span"}
            >
              Errors were found that will prevent this claim from being
              successfully processed at the clearinghouse.
            </Typography>
          </Typography>{" "}
        </Stack>
        <Stack width={"80%"} marginLeft={"50px"}>
          <Typography fontSize="1.7rem" fontWeight="600">
            Error:
          </Typography>
          <Typography sx={{ fontSize: "1.6rem" }}>
            {errData?.data?.map((el) => el)}
          </Typography>
        </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StatusError;
