import React from "react";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { diagnosisInitVal } from "../../../utils/formikInitValues";
import { newDiagnosisAction } from "../../../features/actions/diagnosisAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import CustomField from "../../../components/CustomField";
// import CustomDatePicker from "../../../components/CustomDatePicker";
import DiagnosisForm from "./DiagnosisForm";
import { diagnosisSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";


const CreateDiagnosis = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: diagnosisInitVal,
    validationSchema : diagnosisSchema,
    onSubmit: (values, action) => {
      console.log(values, "checkDiagnosisVals");
      dispatch(newDiagnosisAction(values));
      action.resetForm();
      navigate("/diagnosis");
    },
  });

  return (
    <>
        <DiagnosisForm formik={formik} />
       
      
    </>
  );
};

export default CreateDiagnosis;
