import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

// export const createNewPayerAction = createAsyncThunk(
//   "createNewPayer",
//   async (data) => {
//     console.log(data, "payerData")
//     try {
//       const response = await postData(`${path}/payer`, data);
//       if (response) {
//         return response;
//       }
//     } catch (error) {

//       throw new Error(error);
//     }
//   }
// );

// export const createNewPayerAction = createAsyncThunk(
//   "createNewPayer",
//   async (data) => {
//     console.log(data, "payerData")
//     try {
//       const response = await postData(`${path}/insuranceDescription`, data);
//       if (response) {
//         return response;
//       }
//     } catch (error) {

//       throw new Error(error);
//     }
//   }
// );

export const createNewPayerAction = createAsyncThunk(
  "createNewPayer",
  async (data) => {
    try {
      
      
      const response = await postData(`${path}/insuranceDescription`, data);

      if (response) {
        return response;
      }
    } catch (error) {
      console.error("API call failed:", error);
      throw new Error(error);
    }
  }
);

export const updatePayerAction = createAsyncThunk(
  "updatePayer",
  async (data) => {
    try {
      // const response = await updateData(`${path}/payer/${data.payerId}`, data);
      const response = await updateData(`${path}/insuranceDescription/${data.sequenceNo}`, data);
      console.log("updateres", response);
      console.log(response, "action update payer response");
    } catch (error) {
      console.log(error, "Error in updating payer");
    }
  }
);

export const getPayerByIdAction = createAsyncThunk(
  "getPayerById",
  async (payerSequenceNo) => {
    try {
      const response = await getData(
        // `${path}/payer/GetPayerById?SeqNo=${payerSequenceNo}`
        `${path}/insuranceDescription/${payerSequenceNo}`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPayerAction = createAsyncThunk("getPayer", async (page) => {
  try {
    const newPage = page?.page+1
    console.log(newPage);
    
    const response = await getData(`${path}/insuranceDescription?page=${newPage}&pageSize=10`);
    console.log(response, "Fetched Data");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});

// search
export const getPayerBySearchTermAction = createAsyncThunk(
  "getPayer",
  async ({ searchTerm, page }) => {
    try {
      const response = await getData(
        `${path}/insuranceDescription/SearchInsurance?SearchValue=${searchTerm}&page=${page}&pageSize=10`
        
      );
      console.log(response, "action Payer by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
