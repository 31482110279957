import React from "react";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import ReferringProviderInfo from "./ReferringProviderInfo";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { refProviderSchema } from "../../../schemas/patientSchema";
import { refProviderInitVal } from "../../../utils/formikInitValues";
import { newReferringProviderAction } from "../../../features/actions/referringProviderAction";
import { useDispatch } from "react-redux";

const NewReferringProvider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: refProviderInitVal,
    validationSchema: refProviderSchema,
    onSubmit: (values, action) => {
      console.log(values, "checkRefProviderVals");
      dispatch(newReferringProviderAction(values));
      action.resetForm();
      navigate("/referringProvider");
    },
  });
  return (
    <>
      {/* <Grid container padding={"10px"}>
        <Grid item md={12}>
        
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid item md={12} container spacing={1}>
            <Grid item>
              <CustomButton type="submit" isBlue={true} isSave={true}>
                Save
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                isBlue={false}
                isCancel={true}
                handleClick={() => {
                  formik.resetForm();
                  navigate("/referringProvider");
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <ReferringProviderInfo formik={formik} />
          </Grid>
        </form>
      </Grid> */}

    
      
            <ReferringProviderInfo formik={formik} />
       
    </>
  );
};

export default NewReferringProvider;
