import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";

export const getEmployerAction = createAsyncThunk(
  "getEmployer",
  async (page) => {
    console.log(page);
    const newPage = page?.page + 1
    try {
      const response = await getData(
        `${path}/employer?page=${newPage}&pageSize=20`
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

//   search employer
export const getEmployerBySearchTermAction = createAsyncThunk(
  "getEmployer",
  async ({ searchTerm, page }) => {
    try {
      const response = await getData(
        `${path}/employer/search?searchValue=${searchTerm}&page=${page}&pageSize=20`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
