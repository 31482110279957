import React, { useEffect, useState } from "react";
import { Stack, Grid } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import CustomField from "../../../components/CustomField";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomPreview from "../../../layout/CustomPreview";
import CustomModal from "../../../components/CustomModal";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { useSelector } from "react-redux";
import { providerCols } from "../../../utils/gridCols";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";

const columns = [
  {
    field: "debitCodeId",
    headerName: "Code",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "debitCode",
    headerName: "Debit Code",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
  },
];
const DebitAccount = ({ formik }) => {
  const providerLoading = useSelector((state) => state.provider.loading);
  const { getProviders } = useSelector((state) => state.provider);

  const [openDebitCode, setOpenDebitCode] = useState(false);
  const [debitCodeList, setDebitCodeList] = useState([]);
  const [ProviderModal, setProviderModal] = useState(false);
  // const officeLocation = [
  //   { id: "1", location: "BRUCE L.FELDMAN,MD PO BOX 290725" },
  // ];

  const fetchDebitList = async () => {
    try {
      const { result } = await getData(`${path}/ct-debitCode`);
      let list = result.map((item) => {
        return { id: item.debitCodeId, ...item };
      });
      console.log("list", list);
      setDebitCodeList(list);
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    fetchDebitList();
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} padding="18px">
          <Grid item md={12} sm={12} xs={12}>
            <CustomSearchField
              label="Debit Code"
              fieldVal={formik.values.codeType}
              error={formik.errors}
              isRequired={true}
              //   name="posCode"
              handleModalOpen={() => setOpenDebitCode(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="codeType"
              formik={formik}
              resetField={{
                codeType: "",
              }}
            />

            <CustomField
              value={formik.values.amount}
              name="amount"
              handleChange={(e) => {
                formik.setFieldValue("amount", e.target.value);
                formik.setFieldValue("remaining", e.target.value);
              }}
              handleBlur={formik.handleBlur}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              label="Amount"
              type="number"
              isDecimal={true}
            />
            <CustomDatePicker
              formik={formik}
              name="receivedDate"
              dateValue={formik.values.receivedDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
              dateLabel="Received"
            />
            <CustomField label="Memo" type="text" disable={true} />

            <CustomSearchField
              label="Provider"
              fieldVal={formik.values.providerName}
              error={formik.errors}
              isRequired={true}
              name="providerName"
              handleModalOpen={() => setProviderModal(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              resetField={{
                providerName: "",
              }}
            />

            <CustomSearchField
              label="Office Location"
              fieldVal={formik.values.practiceAddress}
              // error={formik.errors}
              // isRequired={true}
              disable={true}
              name="practiceAddress"
              handleModalOpen={() => setProviderModal(true)}
            />
          </Grid>
          <Grid item md={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CustomButton type={"submit"} isBlue={true}>
                Done
              </CustomButton>
              <CustomButton
                isCancel={true}
                margin={1.5}
                handleClick={() => formik.resetForm()}
              >
                Cancel
              </CustomButton>
            </Stack>
          </Grid>
          <CustomModal
            open={openDebitCode}
            handleClose={() => setOpenDebitCode(false)}
            heading={"Debit Code"}
          >
            <CustomDataGrid
              columns={columns}
              rows={debitCodeList}
              handleCellClick={(e) => {
                formik.setFieldValue("debitCodeId", e?.debitCodeId);
                formik.setFieldValue("codeType", e?.debitCodeType);
                setOpenDebitCode(false);
              }}
            />
          </CustomModal>
          <CustomModal
            open={ProviderModal}
            handleClose={() => setProviderModal(false)}
          >
            <CustomPreview
              rows={
                getProviders?.result &&
                getProviders?.result?.providers.length > 0
                  ? getProviders.result?.providers.map((el) => ({
                      id: el.providerId,
                      ...el,
                    }))
                  : []
              }
              paginationDetail={getProviders}
              columns={providerCols}
              loading={providerLoading}
              handleGetAll={getProviderAction}
              isModal={true}
              handleBySearch={getProviderBySearchTermAction}
              searchLabel="Search for provider by name, sequence #"
              handleCell={(e) => {
                formik.setFieldValue("practiceId", e?.practiceId);
                formik.setFieldValue("practiceAddress", e?.practiceAddress);
                formik.setFieldValue("providerId", e?.providerId);
                formik.setFieldValue(
                  "providerName",
                  `${e?.providerFirstName} ${e?.providerLastName} (${e?.providerSequenceNo})`
                );
                setProviderModal(false);
              }}
            />
          </CustomModal>
        </Grid>
      </form>
    </>
  );
};

export default DebitAccount;
