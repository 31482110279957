import React, { useState } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import SearchedEra from "./SearchedEra";

const Era = () => {
  const [searchedEra, setSearchedEra] = useState(false);
  const receivedDate = [
    { id: "1", option: "All" },
    { id: "2", option: "Other" },
    { id: "3", option: "Today" },
    { id: "4", option: "Yesterday" },
  ];

  const eraSource = [
    { id: "1", option: "Select All" },
    { id: "2", option: "ERAs from the clearing house" },
    { id: "3", option: "Manually Uploaded ERAs" },
  ];

  const handleSearchEra = () => {
    setSearchedEra(true);
  };
  return (
    <>
      <Box padding="20px">
        {!searchedEra ? (
          <>
            {/* <MainHeading mainheading="Electronic Remittance Advice" /> */}

            <Stack alignItems="center" direction="row" gap={2} margin="15px 0">
              <CustomButton
                isBlue={true}
                isSearch={true}
                handleClick={handleSearchEra}
              >
                Search
              </CustomButton>
            </Stack>
            <Grid
              container
              spacing={2}
              sx={{
                width: { xs: "100%", md: "60%" },
              }}
            >
              <Grid item md={12}>
                <FormControl sx={{ width: "50%" }}>
                  <FormControlLabel
                    sx={{ marginTop: "12px" }}
                    control={
                      <Checkbox
                        sx={{
                          color: "black",
                        }}
                      />
                    }
                    label="Include applied checks"
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <CustomSelectBox2
                  label="ERA Received Date"
                  dropdownOptions={receivedDate.map((option) => ({
                    value: option.option,
                    id: option.id,
                  }))}
                />
              </Grid>

              <Grid item md={4}>
                <CustomDatePicker dateLabel="From" />
              </Grid>
              <Grid item md={4}>
                <CustomDatePicker dateLabel="To" />
              </Grid>

              <Grid item md={12}>
                <CustomField
                  type="text"
                  label="Containing check#,patient last,or TCN"
                />
              </Grid>
              <Grid item md={12}>
                <CustomField type="text" label="Payer Name" />
              </Grid>
              <Grid item md={6}>
                <CustomField type="number" label="Check Amount" />
              </Grid>
              <Grid item md={6}>
                <CustomDatePicker dateLabel="Check Date" />
              </Grid>
              <Grid item md={12}>
                <CustomField label="Other Ref #" />
              </Grid>
              <Grid item md={12}>
                <CustomSelectBox2
                  label="ERA Source"
                  dropdownOptions={eraSource.map((option) => ({
                    value: option.option,
                    id: option.id,
                  }))}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <SearchedEra handleClose={() => setSearchedEra(false)} />
          </>
        )}
      </Box>
      {/* <Box>
        <Grid container>
          <Grid item md={8}>
            <MainHeading mainheading="Electronic Remittance Advice" />
          </Grid>
        </Grid>

        <Grid container marginBottom={1}>
          <Grid item lg={3}>
            <CustomButton isBlue={true} isSearch={true}>
              Search
            </CustomButton>
          </Grid>
        </Grid>

        <Grid container marginBottom={1}>
          <Grid item lg={6}>
            <FormControlLabel
              sx={{ marginTop: "12px" }}
              control={
                <Checkbox
                  sx={{
                    color: "black",
                  }}
                />
              }
              label="Include applied checks"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item md={4}>
            <FormControl>
              <CustomSelectBox2
                name="eraReceivedDate"
                dropdownOptions={receivedDate?.map((opt) => ({
                  value: opt.option,
                  id: opt.id,
                }))}
                label="ERA Received Date"
              />
            </FormControl>
          </Grid>

          <Grid item md={4}>
            <FormControl>
              <CustomDatePicker label="From" dateValue={null} name="fromDate" />
            </FormControl>
          </Grid>

          <Grid item md={4}>
            <FormControl>
              <CustomDatePicker label="To" />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item lg={8}>
            <FormControl fullWidth>
              <CustomField
                type="text"
                label="Containing check#,patient last,or TCN"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item lg={8}>
            <FormControl fullWidth>
              <CustomField type="text" label="Payer Name" />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item lg={2}>
            <FormControl fullWidth>
              <CustomDatePicker
                    type="text"
                    label="Check Date"
                  />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item lg={4}>
            <FormControl fullWidth>
              <CustomField type="number" label="Check Amount" />
            </FormControl>
          </Grid>
          <Grid item lg={4}>
            <FormControl fullWidth>
              <CustomSelectBox2
                name="eraSource"
                dropdownOptions={eraSource?.map((opt) => ({
                  value: opt.option,
                  id: opt.id,
                }))}
                label="ERA Source"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={1}>
          <Grid item lg={8}>
            <FormControl fullWidth>
              <CustomField type="text" label="Other Ref#" />
            </FormControl>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default Era;
