import React, { useEffect } from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack,Typography } from "@mui/material";
import ProcedureInfo from "./ProcedureInfo";
import { procedureInitValues } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { procedureSchema } from "../../../schemas/patientSchema";
import { useDispatch } from "react-redux";
import { handleCancel } from "../../../utils/customFunctions";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import {
  getProcedureCodeAction,
 getProcedureCodesByIdAction,
  newProcedureCodeAction,
  updateProcedureCodeAction,
} from "../../../features/actions/procedureCodeAction";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const UpdateProcedure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { id } = useParams();
  const {procedureByIdData, loading } = useSelector((state) => state.procedureCodes);
  console.log("procedureById",procedureByIdData)

  const findProcedure = procedureByIdData?.result;
  console.log("findProcedure", findProcedure);

  useEffect(() => {
    dispatch(getProcedureCodesByIdAction(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: findProcedure || procedureInitValues,
    validationSchema: procedureSchema,
    enableReinitialize: true,
    onSubmit: (values,action) => {
      console.log("Submitting form with values:", values); 
      dispatch(
        updateProcedureCodeAction({
          procedureCodeId: findProcedure?.procedureCodeId,
          ...values,
        })
      ).then((response) => {
        console.log("Update response:", response);
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/procedure-codes");
        } else {
         
          console.error("Update Procedure:", response.error.message);
        }
      });
    },
  });


  return (
    <>
        <ProcedureInfo formik={formik}/>
  </>
  );
};

export default UpdateProcedure;
