import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomField from "../../../components/CustomField";
import CustomSelectBox from "../../../components/CustomSelectBox";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { Form } from "formik";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomAccordion from "../../../components/CustomAccordion";

const AdditionInfo = ({ formik }) => {
  const [delayReason, setDelayReason] = useState([]);
  const [specialProgram, setSpecialProgram] = useState([]);
  const [signaturePatient, setSignaturePatient] = useState([]);
  const [insureSignature, setInsureSignature] = useState([]);
  const [providerAcceptance, setProviderAcceptance] = useState([]);
  const [documentationMethod, setDocumentationMethod] = useState([]);
  const [documentationType, setDocumentationType] = useState([]);
  const [serviceAuthorization, setServiceAuthorization] = useState([]);

  // Define data fetching URLs
  const dataFetchUrls = {
    delayReason: `${path}/ct-delayReason`,
    specialProgram: `${path}/ct-specialProgram`,
    patientSignature: `${path}/patientSignature`,
    insureSignature: `${path}/ct-insuredSignature`,
    providerAcceptance: `${path}/ct-providerAcceptance`,
    documentationMethod: `${path}/ct-documentationMethod`,
    documentationType: `${path}/ct-documentationType`,
    serviceAuthorization: `${path}/ct-serviceAuthorization`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.delayReason, setDelayReason);
    fetchDataOptions(dataFetchUrls.specialProgram, setSpecialProgram);
    fetchDataOptions(dataFetchUrls.patientSignature, setSignaturePatient);
    fetchDataOptions(dataFetchUrls.insureSignature, setInsureSignature);
    fetchDataOptions(dataFetchUrls.providerAcceptance, setProviderAcceptance);
    fetchDataOptions(dataFetchUrls.documentationMethod, setDocumentationMethod);
    fetchDataOptions(dataFetchUrls.documentationType, setDocumentationType);
    fetchDataOptions(
      dataFetchUrls.serviceAuthorization,
      setServiceAuthorization
    );
  }, []);
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          borderRadius: "20px",
          background: theme.palette.innerBox.primary,
          padding: "20px",
        }}
      >
        <Grid item md={12}>
          <Typography
            variant="h3"
            style={{
              fontWeight: "600",
              color: theme.palette.textMainHeading.primary,
            }}
          >
            Show Additional Information about each field
          </Typography>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion defaultExpanded={true} heading={"Patient Condition"}>
            <AccordionDetails>
              <Typography variant="h4" fontWeight={"600"}>
                Is Patient Condition Related to:
              </Typography>
              {/* patient condition */}
              <Stack direction={"row"}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    width:"250px",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    marginTop: "6px",
                    
                  }}
                >
                  Employement
                </FormLabel>
                <RadioGroup
                  sx={{
                    marginLeft: "40px",
                  }}
                  row
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        checked={formik.values.additionalInfoDto.isEmployment}
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isEmployment",
                            true
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        checked={!formik.values.additionalInfoDto.isEmployment}
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isEmployment",
                            false
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Stack>

              <Stack direction={"row"}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    width:"250px",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    marginTop: "6px",
                  }}
                >
                  Auto Accident
                </FormLabel>
                <RadioGroup
                  sx={{
                    marginLeft: "40px",
                    // width: "150px",
                  }}
                  row
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        checked={formik.values.additionalInfoDto.isAutoAccident}
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isAutoAccident",
                            true
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        checked={
                          !formik.values.additionalInfoDto.isAutoAccident
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isAutoAccident",
                            false
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Stack>

              <Stack direction={"row"}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    width:"250px",
                 
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    marginTop: "6px",
                  }}
                >
                  Other Accident
                </FormLabel>

                <RadioGroup
                  sx={{
                    marginLeft: "40px",
                  }}
                  row
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        checked={
                          formik.values.additionalInfoDto.isOtherAccident
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isOtherAccident",
                            true
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        checked={
                          !formik.values.additionalInfoDto.isOtherAccident
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isOtherAccident",
                            false
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Stack>
              <Stack direction={"row"}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    width:"250px",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    marginTop: "6px",
                  }}
                >
                  Patient is homebound?
                </FormLabel>

                <RadioGroup
                  sx={{
                    marginLeft: "40px",
                  }}
                  row
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        checked={
                          formik.values.additionalInfoDto.isPatientHomebound
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isPatientHomebound",
                            true
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        Yes
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        checked={
                          !formik.values.additionalInfoDto.isPatientHomebound
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "additionalInfoDto.isPatientHomebound",
                            false
                          )
                        }
                        style={{ color: theme.palette.radioButtons.primary }}
                      />
                    }
                    label={
                      <Typography fontSize={"1.3rem"} fontWeight="600">
                        No
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Stack>
              {/*  */}
              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Accident Illness Date"
                      name="additionalInfoDto.accidentDate"
                      dateValue={formik.values.additionalInfoDto.accidentDate}
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Last Menstrual Period"
                      name="additionalInfoDto.lastMenstrualDate"
                      dateValue={
                        formik.values.additionalInfoDto.lastMenstrualDate
                      }
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Initial Treatment Date"
                      name="additionalInfoDto.initialTreatmentDate"
                      dateValue={
                        formik.values.additionalInfoDto.initialTreatmentDate
                      }
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Date Last Seen"
                      name="additionalInfoDto.lastSeenDate"
                      dateValue={formik.values.additionalInfoDto.lastSeenDate}
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Unable to Work From Date"
                      name="additionalInfoDto.unableToWorkFromDate"
                      dateValue={
                        formik.values.additionalInfoDto.unableToWorkFromDate
                      }
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Unable to Work To Date"
                      name="additionalInfoDto.unableToWorkToDate"
                      dateValue={
                        formik.values.additionalInfoDto.unableToWorkToDate
                      }
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Claim Information"}
          >
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Claim Codes"
                      value={formik.values.additionalInfoDto.claimCodes}
                      name="additionalInfoDto.claimCodes"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="number"
                      label="Other Claim ID"
                      value={formik.values.additionalInfoDto.otherClaimId}
                      name="additionalInfoDto.otherClaimId"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Additional Claim Information"
                      value={formik.values.additionalInfoDto.claimInformation}
                      name="additionalInfoDto.claimInformation"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Claim Note"
                      value={formik.values.additionalInfoDto.claimNote}
                      name="additionalInfoDto.claimNote"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Resubmit Reason Code"
                      value={formik.values.additionalInfoDto.reasonCode}
                      name="additionalInfoDto.reasonCode"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      value={formik.values.additionalInfoDto.reasonDelayCodeId}
                      name="additionalInfoDto.reasonDelayCodeId"
                      dropdownOptions={delayReason?.map((opt) => ({
                        value: opt.delayReasonCodeName,
                        id: opt.delayReasonCodeId,
                      }))}
                      label="Delay Reason Code"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                      showDefaultOpt={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      className="customDatePicker"
                      dateLabel="Hospitalized From Date"
                      dateValue={
                        formik.values.additionalInfoDto.hospitalizedFromDate
                      }
                      name="additionalInfoDto.hospitalizedFromDate"
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth>
                    <CustomDatePicker
                      formik={formik}
                      dateLabel="Hospitalized To Date"
                      dateValue={
                        formik.values.additionalInfoDto.hospitalizedToDate
                      }
                      handleDateChange={formik.handleChange}
                      handleDateBlur={formik.handleBlur}
                      name="additionalInfoDto.hospitalizedToDate"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="number"
                      label="Lab Charges"
                      value={formik.values.additionalInfoDto.labCharges}
                      name="additionalInfoDto.labCharges"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.specialProgramCodeId"
                      value={
                        formik.values.additionalInfoDto.specialProgramCodeId
                      }
                      dropdownOptions={specialProgram?.map((opt) => ({
                        value: opt.specialProgramName,
                        id: opt.specialProgramId,
                      }))}
                      label="Special Program Codes"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Assignment of Benefits"}
          >
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.patientSignatureId"
                      value={formik.values.additionalInfoDto.patientSignatureId}
                      dropdownOptions={signaturePatient?.map((opt) => ({
                        value: opt.patientSignatureName,
                        id: opt.patientSignatureId,
                      }))}
                      label="Patient's Signature on File"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                      showDefaultOpt={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      value={formik.values.additionalInfoDto.insuredSignatureId}
                      name="additionalInfoDto.insuredSignatureId"
                      dropdownOptions={insureSignature?.map((opt) => ({
                        value: opt.insuredSignatureName,
                        id: opt.insuredSignatureId,
                      }))}
                      label="Insured's Signature"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                      showDefaultOpt={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.providerAcceptanceId"
                      value={
                        formik.values.additionalInfoDto.providerAcceptanceId
                      }
                      dropdownOptions={providerAcceptance?.map((opt) => ({
                        value: opt.providerAcceptance,
                        id: opt.providerAcceptanceId,
                      }))}
                      label="Provider Accept Assignment"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                      showDefaultOpt={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Other Reference Information"}
          >
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.documentationMethodId"
                      value={
                        formik.values.additionalInfoDto.documentationMethodId
                      }
                      dropdownOptions={documentationMethod?.map((opt) => ({
                        value: opt.documentationMethodName,
                        id: opt.documentationMethodId,
                      }))}
                      label="Documentation Method"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                      showDefaultOpt={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.documentationTypeId"
                      value={
                        formik.values.additionalInfoDto.documentationTypeId
                      }
                      dropdownOptions={documentationType?.map((opt) => ({
                        value: opt.documentationTypeName,
                        id: opt.documentationTypeId,
                      }))}
                      label="Documentation Type"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="number"
                      label="Patient Height (in.)"
                      value={formik.values.patientHeight}
                      name="patientHeight"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="number"
                      label="Patient Weight (lbs.)"
                      value={formik.values.additionalInfoDto.patientWeight}
                      name="additionalInfoDto.patientWeight"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomSelectBox2
                      name="additionalInfoDto.serviceAuthorizationId"
                      value={
                        formik.values.additionalInfoDto.serviceAuthorizationId
                      }
                      dropdownOptions={serviceAuthorization?.map((opt) => ({
                        value: opt.serviceAuthorizationName,
                        id: opt.serviceAuthorizationId,
                      }))}
                      label="Service Authorization Exception"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      formik={formik}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Demonstration Project"
                      value={
                        formik.values.additionalInfoDto.demonstrationProject
                      }
                      name="additionalInfoDto.demonstrationProject"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Mammography Certification"
                      value={
                        formik.values.additionalInfoDto.mammographyCertification
                      }
                      name="additionalInfoDto.mammographyCertification"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Investigational Device Exemption"
                      value={
                        formik.values.additionalInfoDto
                          .investigationalDeviceExemption
                      }
                      name="additionalInfoDto.investigationalDeviceExemption"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <CustomField
                      type="text"
                      label="Ambulatory Patient Group"
                      value={
                        formik.values.additionalInfoDto.ambulatoryPatientGroup
                      }
                      name="additionalInfoDto.ambulatoryPatientGroup"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionInfo;
