import { createSlice } from "@reduxjs/toolkit";
import { createFeeScheduleAction, getBlankScheduleAction, getFeeScheduleAction, getFeeScheduleBySeqAction, getFeeScheduleConfigAction, postFeeScheduleTypeAction, updateFeeScheduleAction } from "../actions/feeSchedulesAction";
import { toast } from "react-toastify";

const initialState = {
  createScheduleData: {},
  getFeeScheduleData: [],
  fetchedSchedulesData: [],
  configurationOptionsData:[],
  singleScheduleData: {},
  defaultScheduleData: [],
  postScheduleTypeData:{},
  error: null,
  loading: {
    getAllSchedulesLoading: false,
    postScheduleTypeLoading:false,
    getBlankScheduleLoading:false,
    getConfigOptLoading:false,
    createScheduleLoading: false,
    singleScheduleDataLoading:false
  },
};
const feeScheduleSlice = createSlice({
  name: "feeSchedule",
  initialState: initialState,
 reducers: {
  setFetchedSchedule: (state, action) => {
    state.fetchedSchedulesData = action.payload
  }
 },
  extraReducers: {
    [getFeeScheduleAction.pending]: (state) => {
      state.loading.getAllSchedulesLoading = true;
    },
    [getFeeScheduleAction.fulfilled]: (state, action) => {
      state.loading.getAllSchedulesLoading = false;
      state.getFeeScheduleData= action.payload;
    },
    [getFeeScheduleAction.rejected]: (state, action) => {
      state.loading.getAllSchedulesLoading = false;
      state.error = action.payload;
    },
    [getFeeScheduleBySeqAction.pending]: (state) => {
      state.loading.singleScheduleDataLoading = true;
    },
    [getFeeScheduleBySeqAction.fulfilled]: (state, action) => {
      state.loading.singleScheduleDataLoading = false;
      state.singleScheduleData= action.payload.result;
    },
    [getFeeScheduleBySeqAction.rejected]: (state, action) => {
      state.loading.singleScheduleDataLoading = false;
      state.error = action.payload;
    },
    [getBlankScheduleAction.pending]: (state) => {
      state.loading.getBlankScheduleLoading = true;
    },
    [getBlankScheduleAction.fulfilled]: (state, action) => {
      state.loading.getBlankScheduleLoading = false;
      state.defaultScheduleData= action.payload.result;
    },
    [getBlankScheduleAction.rejected]: (state, action) => {
      state.loading.getBlankScheduleLoading = false;
      state.error = action.payload;
    },
    [getFeeScheduleConfigAction.pending]: (state) => {
      state.loading.getConfigOptLoading = true;
    },
    [getFeeScheduleConfigAction.fulfilled]: (state, action) => {
      state.loading.getConfigOptLoading = false;
      state.configurationOptionsData= action.payload.result;
    },
    [getFeeScheduleConfigAction.rejected]: (state, action) => {
      state.loading.getConfigOptLoading = false;
      state.error = action.payload;
    },
    [postFeeScheduleTypeAction.pending]: (state) => {
      state.loading.postScheduleTypeLoading = true;
    },
    [postFeeScheduleTypeAction.fulfilled]: (state, action) => {
      state.loading.postScheduleTypeLoading = false;
      state.postScheduleTypeData= action.payload;
    },
    [postFeeScheduleTypeAction.rejected]: (state, action) => {
      state.loading.postScheduleTypeLoading = false;
      state.error = action.payload;
    },
    [createFeeScheduleAction.pending]: (state) => {
      state.loading.createScheduleLoading = true;
    },
    [createFeeScheduleAction.fulfilled]: (state, action) => {
      state.loading.createScheduleLoading = false;
      state.createScheduleData= action.payload;
      toast.success("Fee schedule created successfully!")
    },
    [createFeeScheduleAction.rejected]: (state, action) => {
      state.loading.createScheduleLoading = false;
      state.error = action.payload;
      toast.error("Fee schedule creation failed")
    },
    [updateFeeScheduleAction.pending]: (state) => {
      state.loading.createScheduleLoading = true;
    },
    [updateFeeScheduleAction.fulfilled]: (state, action) => {
      state.loading.createScheduleLoading = false;
      toast.success("Fee schedule updated successfully!")
    },
    [updateFeeScheduleAction.rejected]: (state, action) => {
      state.loading.createScheduleLoading = false;
      state.error = action.payload;
      toast.error("Fee schedule updation failed")
    },
  },
});
export const {setFetchedSchedule} = feeScheduleSlice.actions
export default feeScheduleSlice.reducer;