import React, { useEffect, useRef } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomDataGrid from "../components/CustomDataGrid";
// import MainHeading from "../components/MainHeading";
import CustomField from "../components/CustomField";
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import CheckBox from "../components/CheckBox";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const CustomPreview = ({
  totalCount,
  children,
  paginationDetail,
  rows,
  columns,
  handleCell,
  moduleHeading,
  searchLabel,
  handleBySearch,
  handleGetAll,
  isModal = false,
  loading,
  isByPatient = false,
  isExactMatch = false,
  showAll = false,
  showNew,
  navPath,
  isType = false,
  type,
}) => {

  const dispatch = useDispatch();
  const initialRender = useRef(true); // Prevents multiple API calls on mount
  const navigate = useNavigate();
  const previewFormik = useFormik({
    initialValues: {
      showInActive: false,
      showAll: false,
      searchTerm: "",
      page: 0,
      pageSize: 1,
      isPatientSearch: true,
      exactMatch: false,
      type: 2,
    },
    onSubmit: (values) => {
      dispatch(
        values.searchTerm
          ? handleBySearch({ ...values, page: values.page + 1 })
          : handleGetAll({ page: values.page + 1, type: isType ? values.type : null, ...values })
      );
    },
  });

  const handleToggleChange = (event, newType) => {
    console.log(newType, "newType");
    previewFormik.setFieldValue("type", newType);
  };

  const handlePageChange = (newPage) => {
    previewFormik.setFieldValue("page", newPage); // Update page state
    
    setTimeout(() => { // Ensure state update before API call
      if (previewFormik.values.searchTerm) {
        dispatch(
          handleBySearch({
            ...previewFormik.values,
            page: newPage // Increment page correctly
          })
        );
      } else {
        dispatch(
          handleGetAll({
            ...previewFormik.values,
            page: newPage, // Increment page correctly
            type: isType ? previewFormik.values.type : null,
          })
        );
      }
    }, 0);
  };
  // Call API only on mount
  useEffect(() => {
    if (initialRender.current ) {
      initialRender.current = false;
      dispatch(
        handleGetAll({
          page: previewFormik.values.page + 1,
          type: isType ? previewFormik.values.type : null,
          ...previewFormik.values,
        })
      );
    }
  }, [previewFormik.values.page]);

  // Handle search changes
  // useEffect(() => {
  //   if (previewFormik.values.searchTerm) {
  //     dispatch(
  //       handleBySearch({
  //         ...previewFormik.values,
  //         page: previewFormik.values.page + 1,
  //       })
  //     );
  //   }
  // }, [previewFormik.values.searchTerm]);

  return (
    <Box margin="15px">
      <Grid container rowSpacing={1}>

        <Grid item md={12}>
          {isType && (
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <ToggleButtonGroup
                sx={{
                  "& .Mui-selected": {
                    color: "#fff !important",
                    background: "#1768C2 !important",
                  },
                }}
                value={previewFormik.values.type}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform"
              >
                <ToggleButton value={2}>Adjustment</ToggleButton>
                <ToggleButton value={1}>Remark</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          )}
        </Grid>

        <Grid item md={12}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {searchLabel && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  margin: "3px",
                }}
                component={"form"}
                onSubmit={previewFormik.handleSubmit}
              >
                <CustomField
                  type="text"
                  size="small"
                  label={searchLabel}
                  value={previewFormik.values.searchTerm}
                  name="searchTerm"
                  handleChange={previewFormik.handleChange}
                />
                <CustomButton
                  type="submit"
                  isBlue={true}
                  padding="5px 30px"
                  margin={"0 10px 0"}
                  isSearch={true}
                >
                  Search
                </CustomButton>
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {children && (
                // <Grid item md={12}>
                <Box>{children}</Box>
                // </Grid>
              )}
              {showNew && (
                <Box>
                  <CustomButton
                    type="button"
                    isBlue={true}
                    padding="5px 30px"
                    margin={"0 10px 0"}
                    isAdd={true}
                    handleClick={() => navigate(navPath)}
                  >
                    Add New
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item md={12}>
          <Stack direction="row" alignItems="center" gap="12px">
            {isByPatient && (
              <Box
                sx={{
                  borderRight: "2px solid grey",
                }}
              >
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        checked={previewFormik.values.isPatientSearch}
                        onChange={() =>
                          previewFormik.setValues((preVals) => ({
                            ...preVals,
                            isPatientSearch: true,
                          }))
                        }
                      />
                    }
                    label="Patient"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        checked={!previewFormik.values.isPatientSearch}
                        onChange={() =>
                          previewFormik.setValues((preVals) => ({
                            ...preVals,
                            isPatientSearch: false,
                          }))
                        }
                      />
                    }
                    label="Insured"
                  />
                </RadioGroup>
              </Box>
            )}
            {searchLabel && (
              <Box>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      showInActive: !previewFormik.values.showInActive,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.showInActive}
                  name="showInActive"
                  label={`Show inactive`}
                />
              </Box>
            )}
            {showAll && (
              <Box>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      showAll: !previewFormik.values.showAll,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.showAll}
                  name="showAll"
                  label={`Show All`}
                />
              </Box>
            )}

            {isExactMatch && (
              <Box item md={3}>
                <CheckBox
                  handleChange={() =>
                    previewFormik.setValues((preVals) => ({
                      ...preVals,
                      exactMatch: !previewFormik.values.exactMatch,
                    }))
                  }
                  CheckedValue={previewFormik?.values?.exactMatch}
                  label={`Show exact matches only`}
                />
              </Box>
            )}
          </Stack>
        </Grid>

        <Grid item md={12} xs={12} sm={12}>
          {loading ? (
            <Loading />
          ) : (
            <CustomDataGrid
              columns={columns}
              paginationDetail={paginationDetail}
              rows={rows}
              pageSize={previewFormik.values.pageSize}
              handleCellClick={handleCell}
              page={previewFormik.values.page}
              handlePageChange={handlePageChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomPreview;