import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Radio,
  FormControl,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import CheckBox from "../../../../components/CheckBox";
import { getData } from "../../../../config/axiosFunctions";
import path from "../../../../config/apiUrl";
import CustomSelectBox2 from "./../../../../components/CustomSelectBox2";
import {
  adjustPrices,
  medicareCarrierAndLocality,
  pricingMethod,
} from "../../../../data/feeScheduleData";
import CustomField from "../../../../components/CustomField";
import FeeAdjustPrice from "./FeeAdjustPrice";

const MedicareFeeSchedule = ({ scheduleTypeFormik }) => {
  const theme = useTheme();
  const [medYearOpts, setMedYearOpts] = useState([]);
  // fetch medicare year
  useEffect(() => {
    const fetchMedicareYear = async () => {
      try {
        const response = await getData(`${path}/CTMedicareYear`);
        if (response) {
          scheduleTypeFormik.setFieldValue("medicareYearId", response.result[0].medicareYearId)
          setMedYearOpts(response.result);
        }
      } catch (err) {
        console.log(err, "Error in fetching medicare year");
      }
    };

    fetchMedicareYear();
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl sx={{ width: "50%" }}>
            <CustomSelectBox2
              dropdownOptions={medYearOpts?.map((opt) => ({
                value: opt?.medicareYear,
                id: opt?.medicareYearId,
              }))}
              label={"Medicare Year"}
              showDefaultOpt={true}
              value={scheduleTypeFormik?.values?.medicareYearId}
              name="medicareYearId"
              formik={scheduleTypeFormik}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Box
            sx={{
              border: "1px solid #6e6b6b",
              borderRadius: "5px",
              // margin: "15px",
            }}
          >
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Medicare Carrier and Locality
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
              <Stack flexDirection="column" alignItems="left" marginLeft="20px">
                {medicareCarrierAndLocality.map((value) => (
                  <Stack key={value.id}>
                    <FormControlLabel
                      label={value.ctOption}
                      control={
                        <Radio
                          value={value.id}
                          checked={
                            scheduleTypeFormik?.values?.isZipCode ===
                            (value.id === 1)
                          }
                          onChange={() =>
                            scheduleTypeFormik.setFieldValue(
                              "isZipCode",
                              value.id === 1
                            )
                          }
                          name="radio-buttons"
                        />
                      }
                    />
                    {value.id === 1 &&
                      scheduleTypeFormik?.values?.isZipCode && (
                        <CustomField
                          label="Zipcode"
                          value={scheduleTypeFormik?.values?.zipCode}
                          name={"zipCode"}
                          handleChange={scheduleTypeFormik?.handleChange}
                          handleBlur={scheduleTypeFormik?.handleBlur}
                        />
                      )}
                    {value.id === 2 &&
                      !scheduleTypeFormik?.values?.isZipCode && (
                        <Stack
                          direction={"row"}
                          gap={"20px"}
                          alignItems={"center"}
                        >
                          <CustomField
                            label="Carrier"
                            value={scheduleTypeFormik?.values?.carrier}
                            name={"carrier"}
                            handleChange={scheduleTypeFormik?.handleChange}
                            handleBlur={scheduleTypeFormik?.handleBlur}
                          />
                          <CustomField
                            label="Locality"
                            value={scheduleTypeFormik?.values?.locality}
                            name={"locality"}
                            handleChange={scheduleTypeFormik?.handleChange}
                            handleBlur={scheduleTypeFormik?.handleBlur}
                          />
                        </Stack>
                      )}
                  </Stack>
                ))}
              </Stack>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box
            sx={{
              border: "1px solid #6e6b6b",
              borderRadius: "5px",
              // margin: "15px",
            }}
          >
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Pricing Method
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
              <Stack flexDirection="column" alignItems="left" marginLeft="20px">
                {pricingMethod.map((value) => (
                  <FormControlLabel
                    key={value.id}
                    label={value.ctOption}
                    control={
                      <Radio
                        value={value.id}
                        checked={
                          scheduleTypeFormik?.values?.isFacilityMethod ===
                          (value.id !== 1)
                        }
                        onChange={() =>
                          scheduleTypeFormik.setFieldValue(
                            "isFacilityMethod",
                            value.id !== 1
                          )
                        }
                        name="radio-buttons"
                      />
                    }
                  />
                ))}
              </Stack>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <FeeAdjustPrice scheduleTypeFormik={scheduleTypeFormik} />
        </Grid>
      </Grid>
    </>
  );
};

export default MedicareFeeSchedule;
