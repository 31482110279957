// import {
//   Box,
//   Button,
//   FormControl,
//   Grid,
//   Stack,
//   Typography,
//   Select,
//   useTheme,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import CustomSearchField from "../../components/CustomSearchField";
// import { Add } from "@mui/icons-material";
// import CustomField from "../../components/CustomField";
// import CustomDatePicker from "../../components/CustomDatePicker";
// import path from "../../config/apiUrl";
// import { getData } from "../../config/axiosFunctions";
// import CustomModal from "../../components/CustomModal";
// import OtherInsuredModalForm from "../patient/OtherInsuredModalForm";
// import CustomPreview from "../../layout/CustomPreview";
// import CustomButton from "../../components/CustomButton";
// import {
//   getPayerAction,
//   getPayerBySearchTermAction,
// } from "../../features/actions/payerAction";
// import { useSelector } from "react-redux";
// import { insurancePayer } from "../../utils/gridCols";
// import CustomDialog from "../../components/CustomDialog";
// // const PayerInfo = ({ insuranceFormik, formik }) => {
// const PayerInfo = ({ formik, insuranceFormik, priorityId }) => {
//   let flage = false;
//   const initPayerDet = insuranceFormik.values;

//   const theme = useTheme();
//   const [patRelationOpt, setPatRelationOpt] = useState([]);
//   const [priorityOpt, setPriorityOpt] = useState([]);
//   const [policyOpt, setPolicyOpt] = useState([]);
//   const [openPayerList, setOpenPayerList] = useState(false);
//   const [openModalForOther, setOpenModalForOther] = useState(false);
//   const [openExistPayer, setOpenExistPayer] = useState(false);
//   const [existingPayerName, setExistingPayerName] = useState("");
//   // use selectors
//   const { getAllPayer } = useSelector((state) => state.payer);

//   const payerLoading = useSelector((state) => state.payer.loading);
//   const dataFetchUrl = {
//     patientRelation: "/ct-relationToPatient",
//     priorityType: "/ct-priorityType",
//     polityType: "/ct-policyType",
//   };

//   const fetchData = async (endPoint, setState) => {
//     try {
//       const response = await getData(`${path}${endPoint}`);
//       setState(response.result);
//     } catch (error) {
//       console.log("error while fetch data", error);
//     }
//   };
//   useEffect(() => {
//     if (!flage) {
//       flage = true;
//       fetchData(dataFetchUrl.patientRelation, setPatRelationOpt);
//       fetchData(dataFetchUrl.priorityType, setPriorityOpt);
//       fetchData(dataFetchUrl.polityType, setPolicyOpt);
//     }
//   }, []);

//   // handle select payer
//   const handleSelectPayer = (param) => {
//     console.log(param, "checkParams");
//     const payerExist = formik?.values?.insuredPayerDto?.some((insuredPayer) =>
//       insuredPayer?.insurancePoliciesDtos?.some(
//         (policy) => policy?.payerId === param?.payerId
//       )
//     );
//     console.log(payerExist);

//     if (payerExist) {
//       setExistingPayerName(`${param?.insuranceDescriptionName} (${param?.sequenceNo})`);
//       setOpenPayerList(false);
//       setOpenExistPayer(true);
//       return;
//     } else {
//       insuranceFormik.setFieldValue(
//         "insuranceDescriptionId",
//         param?.insuranceDescriptionId
//       );
//       insuranceFormik.setFieldValue(
//         "insuranceDescriptionName",
//         param?.insuranceDescriptionName
//       );
//       insuranceFormik.setFieldValue("insuranceDescriptionSeqNo", param?.sequenceNo);
//       setOpenPayerList(false);
//     }
//   };

//   return (
//     <>
//       <Box>
//         <Grid container spacing={2}>
//           <Grid item md={12}>
//        {/* {insuranceFormik.values.insuredRelationShipToPatientId !== 8 && (
//         <CustomButton>Search Insured Party</CustomButton>
//       )} */}
//             <CustomSelectBox2
//               label="Patient Relation to Insured"
//               dropdownOptions={patRelationOpt?.map((opt) => ({
//                 id: opt?.relationShipToPatientId,
//                 value: opt?.relationShipToPatientName,
//               }))}
//               value={initPayerDet.insuredRelationShipToPatientId}
//               name="insuredRelationShipToPatientId"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               formik={insuranceFormik}
//               // disable={isPatientInsured}
//               showDefaultOpt={true}
//               setValName={"insuredRelationShipToPatientName"}
//               isSetName={true}
//               setOpenModalForOther={setOpenModalForOther}
//             />
//           </Grid>

//             {
//               insuranceFormik.values?.insuredPartyId && (

//           <Grid item md={12}>
//             <Stack direction="row" alignItems="center" gap="10px">
//               <Typography component={"span"} fontSize={"1.6rem !important"} fontWeight={"700 !important"}>Insured Name:</Typography>
//               <Typography component={"span"} fontSize={"1.6rem !important"}>{insuranceFormik.values?.insuredFirstName} {insuranceFormik.values?.insuredLastName}</Typography>
//             </Stack>
//           </Grid>
//               )
//             }
//           <Grid item md={5}>
//             <CustomSelectBox2
//               dropdownOptions={priorityOpt?.map((opt) => ({
//                 id: opt?.priorityTypeId,
//                 value: opt?.priorityType,
//               }))}
//               label="Priority"
//               showDefaultOpt={true}
//               value={initPayerDet.payerInfoPriorityId}
//               name="payerInfoPriorityId"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               formik={insuranceFormik}
//               isSetName={true}
//               setValName={"payerInfoPriorityName"}
//             />
//           </Grid>
//           <Grid item md={7}>
//             <CustomSelectBox2
//               dropdownOptions={policyOpt?.map((opt) => ({
//                 id: opt?.policyTypeId,
//                 value: opt?.policyType,
//               }))}
//               label="Policy type"
//               value={initPayerDet.payerInfoPolicyId}
//               name="payerInfoPolicyId"
//               handleBlur={insuranceFormik.handleBlur}
//               handleChange={insuranceFormik.handleChange}
//               showDefaultOpt={true}
//               formik={insuranceFormik}
//               isSetName={true}
//               setValName={"payerInfoPolicyType"}
//             />
//           </Grid>

//           <Grid item md={12}>
//             <Stack direction="row" alignItems="center">
//               <FormControl
//                 sx={{
//                   width: { xs: "100%", sm: "100%" },
//                   marginTop: "8px",
//                   fontSize: "1rem",
//                 }}
//               >
//                 <CustomSearchField
//                   isNested={true}
//                   isRequired={true}
//                   type="text"
//                   label="Payer Name"
//                   formik={insuranceFormik}
//                   // disable={fieldDisable}
//                   fieldVal={
//                     initPayerDet.insuranceDescriptionName &&
//                     `${initPayerDet.insuranceDescriptionName} (${initPayerDet.insuranceDescriptionSeqNo})`
//                   }
//                   name="insuranceDescriptionName"
//                   handleChange={insuranceFormik.handleChange}
//                   handleBlur={insuranceFormik.handleBlur}
//                   handleModalOpen={() => setOpenPayerList(true)}

//                   // error={newFormik.errors}z
//                   // touched={newFormik.touched}
//                 />
//               </FormControl>
//               <Button
//                 sx={{
//                   marginTop: "38px",
//                   marginRight: "40px",
//                   marginLeft: "10px",
//                   marginBottom: "4px",
//                   color: "#216FED",
//                 }}
//                 // disabled={fieldDisable}
//                 variant="outlined"
//                 onClick={() => setOpenPayerList(true)}
//               >
//                 <Add />
//               </Button>
//             </Stack>
//           </Grid>

//           <Grid item md={6}>
//             <CustomField
//               type="text"
//               label="Member ID"
//               value={initPayerDet.payerInfoMemberId}
//               name={"payerInfoMemberId"}
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               isRequired={true}
//               error={insuranceFormik.errors}
//               touched={insuranceFormik.touched}
//               isOutlined={false}
//               isNested={true}
//             />
//           </Grid>
//           <Grid item md={6}>
//             <CustomField
//               type="text"
//               label="Group ID"
//               value={initPayerDet.payerInfoGroupId}
//               name="payerInfoGroupId"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//             />
//           </Grid>
//           <Grid item md={3}>
//             <CustomField
//               type="number"
//               label="Copay"
//               value={initPayerDet.payerInfoCopayAmount}
//               name="payerInfoCopayAmount"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               isDecimal={true}
//             />
//           </Grid>
//           <Grid item md={3}>
//             <CustomField
//               type="number"
//               label="Co-Insurance %"
//               value={initPayerDet.payerInfoCoInsurancePercent}
//               name="payerInfoCoInsurancePercent"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//             />
//           </Grid>
//           <Grid item md={3}>
//             <CustomField
//               type="number"
//               label="Deductible"
//               value={initPayerDet.payerInfoDeductibleAmount}
//               name="payerInfoDeductibleAmount"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               isDecimal={true}
//             />
//           </Grid>
//           <Grid item md={3}>
//             <CustomField
//               type="number"
//               label="Out of Pocket Max"
//               value={initPayerDet.payerInfoOutOfPocketMax}
//               name="payerInfoOutOfPocketMax"
//               handleChange={insuranceFormik.handleChange}
//               handleBlur={insuranceFormik.handleBlur}
//               isDecimal={true}
//             />
//           </Grid>
//           <Grid item md={6}>
//             <CustomDatePicker
//               dateLabel="Effective Date"
//               dateValue={initPayerDet.payerInfoEffectiveDate}
//               name="payerInfoEffectiveDate"
//               handleDateBlur={insuranceFormik.handleBlur}
//               handleDateChange={insuranceFormik.handleChange}
//               formik={insuranceFormik}
//             />
//           </Grid>
//           <Grid item md={6}>
//             <CustomDatePicker
//               dateLabel="Termination Date"
//               dateValue={initPayerDet.payerInfoTerminationDate}
//               name="payerInfoTerminationDate"
//               handleDateBlur={insuranceFormik.handleBlur}
//               handleDateChange={insuranceFormik.handleChange}
//               formik={insuranceFormik}
//             />
//           </Grid>
//         </Grid>
//       </Box>

//       {/* modals here */}
//       <CustomModal
//         open={openPayerList}
//         handleClose={() => setOpenPayerList(false)}
//         heading="Payer List"
//       >
//         <CustomPreview
//           isModal={true}
//           handleCell={(param) => handleSelectPayer(param)}
//           handleGetAll={getPayerAction}
//           handleBySearch={getPayerBySearchTermAction}
//           rows={
//             getAllPayer && getAllPayer?.result?.insurance?.length > 0
//               ? getAllPayer?.result?.insurance?.map((el) => ({
//                   id: el?.insuranceDescriptionId,
//                   ...el,
//                 }))
//               : []
//           }
//           loading={payerLoading}
//           // columns={payerCol}
//           columns={insurancePayer}
//           searchLabel="Search by name or address #"
//           paginationDetail={getAllPayer}
//         />
//       </CustomModal>
//       {/* <CustomModal
//         open={openModalForOther}
//         handleClose={() => setOpenModalForOther(false)}
//         heading="Insured Details"
//       >
//         <OtherInsuredModalForm
//           setOpenModalForOther={setOpenModalForOther}
//           priorityId={priorityId}
//           priorityOpt={priorityOpt}
//           formik={formik}
//           insuranceFormik={insuranceFormik}
//         />
//       </CustomModal> */}
//       <CustomModal
//         open={openModalForOther}
//         handleClose={() => setOpenModalForOther(false)}
//         heading="Insured Details"
//       >
//         <Box>
//         <form
//           onSubmit={insuredPartyFormik.handleSubmit}
//           id="insuredPartyForm"
//           className="formContent"
//         >

//           <InsuredPartyForm
//             insuredPartyFormik={insuredPartyFormik}
//             formik={formik}
//           />
//         </form>
//       </Box>
//       <Box margin={"15px"}>
//         <Stack
//           direction="row"
//           justifyContent="space-between"
//           alignItems="center"
//         >
//           <CustomButton
//             type="submit"
//             isBlue={true}
//             padding="5px 15px"
//             // handleClick={handleAddInsurance}
//             formId="insuredPartyForm"
//           >
//             Add
//           </CustomButton>
//         </Stack>
//       </Box>
//       </CustomModal>
//       <CustomDialog
//         open={openExistPayer}
//         isWarning={true}
//         handleClose={() => setOpenExistPayer(false)}
//         isAction={false}
//       >
//         <Typography component="h3">
//           The selected payer ({existingPayerName}) is already set on this
//           patient.
//         </Typography>
//       </CustomDialog>
//     </>
//   );
// };

// export default PayerInfo;

import {
  Box,
  // Button,
  FormControl,
  Grid,
  Stack,
  Typography,
  // Select,
  // useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomSearchField from "../../components/CustomSearchField";
// import { Add } from "@mui/icons-material";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";
import CustomModal from "../../components/CustomModal";
// import { InsuredInfo } from "../../utils/formikInitValues";
// import { insuredPartySchema } from "../../schemas/patientSchema";
import CustomPreview from "../../layout/CustomPreview";
// import CustomButton from "../../components/CustomButton";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../features/actions/payerAction";
import { useSelector } from "react-redux";
import { insurancePayer } from "../../utils/gridCols";
import CustomDialog from "../../components/CustomDialog";
// import { useFormik } from "formik";
import InsuredPartyForm from "../../scenes/patient/InusredPartyForm";
// const PayerInfo = ({ insuranceFormik, formik }) => {
const PayerInfo = ({ formik, insuranceFormik, priorityId }) => {
  let flage = false;
  const initPayerDet = insuranceFormik.values;
  // const theme = useTheme();
  const [patRelationOpt, setPatRelationOpt] = useState([]);
  const [priorityOpt, setPriorityOpt] = useState([]);
  const [policyOpt, setPolicyOpt] = useState([]);
  const [openPayerList, setOpenPayerList] = useState(false);
  const [openModalForOther, setOpenModalForOther] = useState(false);
  const [openExistPayer, setOpenExistPayer] = useState(false);
  const [existingPayerName, setExistingPayerName] = useState("");
  // use selectors
  const { getAllPayer } = useSelector((state) => state.payer);

  const payerLoading = useSelector((state) => state.payer.loading);
  const dataFetchUrl = {
    patientRelation: "/ct-relationToPatient",
    priorityType: "/ct-priorityType",
    polityType: "/ct-policyType",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };

  useEffect(() => {
    if (!flage) {
      flage = true;
      fetchData(dataFetchUrl.patientRelation, setPatRelationOpt);
      fetchData(dataFetchUrl.priorityType, setPriorityOpt);
      fetchData(dataFetchUrl.polityType, setPolicyOpt);
    }
  }, []);

  // handle select payer
  const handleSelectPayer = (param) => {
    const payerExist = formik?.values?.insurancePoliciesDtos?.some((value) => {
      return param?.sequenceNo === value.insuranceDescriptionSeqNo;
    });

    if (payerExist) {
      setExistingPayerName(
        `${param?.insuranceDescriptionName} (${param?.sequenceNo})`
      );
      setOpenPayerList(false);
      setOpenExistPayer(true);
      return;
    } else {
      insuranceFormik.setFieldValue(
        "insuranceDescriptionId",
        param?.insuranceDescriptionId
      );
      insuranceFormik.setFieldValue(
        "insuranceDescriptionName",
        param?.insuranceDescriptionName
      );
      insuranceFormik.setFieldValue(
        "insuranceDescriptionSeqNo",
        param?.sequenceNo
      );
      setOpenPayerList(false);
    }
  };
  useEffect(() => {
    if (insuranceFormik.values.insuredRelationShipToPatientId === 8) {
      setOpenModalForOther(false);
    } else if (
      insuranceFormik.values.insuredRelationShipToPatientId !== 8 &&
      insuranceFormik.values.insuredPartyId !== null
    ) {
      setOpenModalForOther(false);
    } else {
      setOpenModalForOther(true);
    }
  }, [insuranceFormik.values.insuredRelationShipToPatientId, insuranceFormik.values.insuredPartyId]);

  useEffect(() => {
    const priorityName = priorityOpt.find((item) => {
      return item.priorityTypeId === insuranceFormik.values.payerInfoPriorityId;
    });

    const patRelationName = patRelationOpt.find((item) => {
      return (
        item.relationShipToPatientId ===
        insuranceFormik.values.insuredRelationShipToPatientId
      );
    });

    const policyName = policyOpt.find((item) => {
      return item.policyTypeId === insuranceFormik.values.payerInfoPolicyId;
    });

    insuranceFormik.setFieldValue(
      "insuredRelationShipToPatientName",
      patRelationName?.relationShipToPatientName
    );

    insuranceFormik.setFieldValue(
      "payerInfoPriorityName",
      priorityName?.priorityType
    );

    insuranceFormik.setFieldValue(
      "payerInfoPolicyType",
      policyName?.policyType
    );
  }, [priorityOpt, patRelationOpt, policyOpt]);
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {/* {insuranceFormik.values.insuredRelationShipToPatientId !== 8 && (
        <CustomButton>Search Insured Party</CustomButton>
      )} */}
            <CustomSelectBox2
              label="Patient Relation to Insured"
              dropdownOptions={patRelationOpt?.map((opt) => ({
                id: opt?.relationShipToPatientId,
                value: opt?.relationShipToPatientName,
              }))}
              value={initPayerDet.insuredRelationShipToPatientId}
              name="insuredRelationShipToPatientId"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              formik={insuranceFormik}
              // disable={isPatientInsured}
              showDefaultOpt={true}
              setValName={"insuredRelationShipToPatientName"}
              isSetName={true}
              setOpenModalForOther={setOpenModalForOther}
            />
          </Grid>

          {insuranceFormik.values?.insuredPartyId && (
            <Grid item md={12}>
              <Stack direction="row" alignItems="center" gap="10px">
                <Typography
                  component={"span"}
                  fontSize={"1.6rem !important"}
                  fontWeight={"700 !important"}
                >
                  Insured Name:
                </Typography>
                <Typography component={"span"} fontSize={"1.6rem !important"}>
                  {insuranceFormik.values?.insuredFirstName}{" "}
                  {insuranceFormik.values?.insuredLastName}
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item md={5}>
            <CustomSelectBox2
              dropdownOptions={priorityOpt?.map((opt) => ({
                id: opt?.priorityTypeId,
                value: opt?.priorityType,
              }))}
              label="Priority"
              showDefaultOpt={true}
              value={initPayerDet.payerInfoPriorityId}
              name="payerInfoPriorityId"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              formik={insuranceFormik}
              isSetName={true}
              setValName={"payerInfoPriorityName"}
            />
          </Grid>
          <Grid item md={7}>
            <CustomSelectBox2
              dropdownOptions={policyOpt?.map((opt) => ({
                id: opt?.policyTypeId,
                value: opt?.policyType,
              }))}
              label="Policy type"
              value={initPayerDet.payerInfoPolicyId}
              name="payerInfoPolicyId"
              handleBlur={insuranceFormik.handleBlur}
              handleChange={insuranceFormik.handleChange}
              showDefaultOpt={true}
              formik={insuranceFormik}
              isSetName={true}
              setValName={"payerInfoPolicyType"}
            />
          </Grid>

          <Grid item md={12}>
            <Stack direction="row" alignItems="center">
              <FormControl
                sx={{
                  width: { xs: "100%", sm: "100%" },
                  marginTop: "8px",
                  fontSize: "1rem",
                }}
              >
                <CustomSearchField
                  isNested={true}
                  isRequired={true}
                  type="text"
                  label="Payer Name"
                  formik={insuranceFormik}
                  // disable={fieldDisable}
                  fieldVal={
                    initPayerDet.insuranceDescriptionName &&
                    `${initPayerDet.insuranceDescriptionName} (${initPayerDet.insuranceDescriptionSeqNo})`
                  }
                  name="insuranceDescriptionName"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  handleModalOpen={() => setOpenPayerList(true)}
                  // error={newFormik.errors}
                  // touched={newFormik.touched}
                />
              </FormControl>
          
            </Stack>
          </Grid>

          <Grid item md={6}>
            <CustomField
              type="text"
              label="Member ID"
              value={initPayerDet.payerInfoMemberId}
              name={"payerInfoMemberId"}
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isRequired={true}
              error={insuranceFormik.errors}
              touched={insuranceFormik.touched}
              isOutlined={false}
              isNested={true}
            />
          </Grid>
          <Grid item md={6}>
            <CustomField
              type="text"
              label="Group ID"
              value={initPayerDet.payerInfoGroupId}
              name="payerInfoGroupId"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Copay"
              value={initPayerDet.payerInfoCopayAmount}
              name="payerInfoCopayAmount"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Co-Insurance %"
              value={initPayerDet.payerInfoCoInsurancePercent}
              name="payerInfoCoInsurancePercent"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Deductible"
              value={initPayerDet.payerInfoDeductibleAmount}
              name="payerInfoDeductibleAmount"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Out of Pocket Max"
              value={initPayerDet.payerInfoOutOfPocketMax}
              name="payerInfoOutOfPocketMax"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={6}>
            <CustomDatePicker
              dateLabel="Effective Date"
              dateValue={initPayerDet.payerInfoEffectiveDate}
              name="payerInfoEffectiveDate"
              handleDateBlur={insuranceFormik.handleBlur}
              handleDateChange={insuranceFormik.handleChange}
              formik={insuranceFormik}
              isFromTo={true}
            />
          </Grid>
          <Grid item md={6}>
            <CustomDatePicker
              dateLabel="Termination Date"
              dateValue={initPayerDet.payerInfoTerminationDate}
              name="payerInfoTerminationDate"
              handleDateBlur={insuranceFormik.handleBlur}
              handleDateChange={insuranceFormik.handleChange}
              formik={insuranceFormik}
              isFromTo={true}
            />
          </Grid>
        </Grid>
      </Box>

      {/* modals here */}
      <CustomModal
        open={openPayerList}
        handleClose={() => setOpenPayerList(false)}
        heading="Payer List"
      >
        <CustomPreview
          isModal={true}
          handleCell={(param) => handleSelectPayer(param)}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
          rows={
            getAllPayer && getAllPayer?.result?.insurance?.length > 0
              ? getAllPayer?.result?.insurance?.map((el) => ({
                  id: el?.insuranceDescriptionId,
                  ...el,
                }))
              : []
          }
          loading={payerLoading}
          // columns={payerCol}
          columns={insurancePayer}
          searchLabel="Search by name or address #"
          paginationDetail={getAllPayer}
        />
      </CustomModal>
      <CustomModal
        open={openModalForOther}
        handleClose={() => setOpenModalForOther(false)}
        heading="Insured Details"
      >
        <InsuredPartyForm
          priorityId={priorityId}
          priorityOpt={priorityOpt}
          setOpenModalForOther={setOpenModalForOther}
          insuranceFormik={insuranceFormik}
        />
      </CustomModal>
      <CustomDialog
        open={openExistPayer}
        isWarning={true}
        handleClose={() => setOpenExistPayer(false)}
        isAction={false}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
      >
        <Typography component="h3">
          The selected payer ({existingPayerName}) is already set on this
          patient.
        </Typography>
      </CustomDialog>
    </>
  );
};

export default PayerInfo;
