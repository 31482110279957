import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../components/Loading";
import DoneIcon from "@mui/icons-material/Done";
import CustomButton from "../../../components/CustomButton";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";

// masterDiagnosisCodesId: 2,
// diagnosisCode: 'BW20ZZZ',
// codeTypeId: 1,
// codeType: 'ICD-10',
// diagnosisDescription: 'Computerized Tomography (CT Scan) of Abdomen',
// effectiveDate: null,
// terminationDate: null,
// isAdded: false

const diagnosisColForMultiple = [
  {
    field: "diagnosisCode",
    headerName: "Diagnosis Code",
    minWidth: 160,
    headerAlign: "center",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "center",
  },
  {
    field: "isAdded",
    headerName: "isAdded",
    minWidth: 160,
    flex: 3,
    headerAlign: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    filterable: true,
    align: "center",
    renderCell: (params) => <span>{params.value ? <DoneIcon /> : ""}</span>,
  },
  {
    field: "codeType",
    headerName: "Code Type",
    minWidth: 160,
    headerAlign: "center",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "center",
  },
  {
    field: "diagnosisDescription",
    headerName: "Description",
    minWidth: 160,
    flex: 3,
    headerAlign: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    filterable: true,
    align: "center",
  },
];
export const DiagnosisCodeMasterList = ({
  loading,
  multiDiagnosis,
  filteredDiagnosis,
  search,
  setopenMultipleDiagnosisTable,
  handleClose,
}) => {
  console.log(multiDiagnosis);

  const reducedCellHeight = 35;
  const theme = useTheme();
  const [selectedIDC, setSelectedICD] = useState([]);
  console.log(selectedIDC);

  const handleAddMultiICD = async () => {
    const selectedAllICDs = selectedIDC?.map((id) =>
      multiDiagnosis?.find((el) => el.id === id)
    );
    console.log("selectedAllICDs", selectedAllICDs);
    const iCDUpdateValue = selectedAllICDs.map((v) => ({
      // icdCode: val.icdCode,
      // icdCodeTypeId: val.icDcodetypeId,
      // icdCodeType: val.icDcodetype,
      // description: val.description,
      id: v?.diagnosisCodeId,
      diagnosisCode: v?.diagnosisCode,
      diagnosisDescription: v?.diagnosisDescription,
      codeTypeId: v?.codeTypeId,
      codeType: v?.codeType,
      effectiveDate: v?.effectiveDate,
      terminationDate: v?.terminationDate,
    }));

    // response
    // [
    //     {
    //       id: 12,
    //       icdCode: 'test',
    //       icdCodeType: 'ICD-10',
    //       masterICTProcedureCodeId: 12,
    //       code: 'test',
    //       icDcodetypeId: 1,
    //       icDcodetype: 'ICD-10',
    //       description: 'test',
    //       isAdded: true
    //     }
    //   ]

    try {
      console.log(iCDUpdateValue);
      if (iCDUpdateValue.length === 0) {
        toast.error("atleast one select");
      } else {
        const response = await postData(
          `${path}/ct-diagnosisCode`,
          iCDUpdateValue
        );
        if (response) {
          console.log(response, "check posting remark response");
          toast.success("ICD code added successfully");
          setopenMultipleDiagnosisTable(false);
          handleClose();
          return response;
        }
      }
    } catch (err) {
      console.log(err, ": Error inposting remittance remark");
      setopenMultipleDiagnosisTable(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <DataGrid
          rows={search === "" ? multiDiagnosis : filteredDiagnosis}
          columns={diagnosisColForMultiple}
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
          }}
          autoHeight
          rowHeight={reducedCellHeight}
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSize={20}
          paginationMode="server"
          // rowCount={}
          onSelectionModelChange={(id) => {
            setSelectedICD(id);
          }}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {multiDiagnosis.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      )}

      <div
        style={{
          position: "sticky",
          bottom: "0",
          borderTop: ".5px solid grey",
          width: "100%",
          background: "#fff",
          padding: "10px",
        }}
      >
        <CustomButton
          isBlue={true}
          isAdd={true}
          handleClick={handleAddMultiICD}
        >
          Add
        </CustomButton>
      </div>
    </Box>
  );
};
