// import React from "react";
// import { Box,Typography } from "@mui/material";
// import { useState } from "react";
// import MainHeading from "../../../components/MainHeading";
// import CustomButton from "../../../components/CustomButton";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
// import { inventoryCode } from "../../../utils/formikInitValues";
// import InventoryCodeForm from "./InventoryCodeForm";
// import { useDispatch } from "react-redux";
// import { inventoryScehma } from "../../../schemas/patientSchema";
// import { useNavigate } from "react-router-dom";
// import { newInventoryCodeAction } from "../../../features/actions/inventoryCode";
// import CustomDialog from "../../../components/CustomDialog";
// const NewInventoryCode = () => {
//   const [openCancelDialog, setOpenCancelDialog] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const formik = useFormik({
//     initialValues: inventoryCode,
//     validationSchema: inventoryScehma,
//     onSubmit: (values, actions) => {
//       if (values.alert && !values.alertDescription) {
//         toast.info("Alert Description is Required");
//         return;
//       }
//       dispatch(newInventoryCodeAction(values));
//       navigate("/inventorycode");
//     },
//   });
//   const handleCancel = () => {
//     setOpenCancelDialog(true);
//   };

//   const handleConfirmCancel = () => {
//     formik.resetForm();
//     navigate("/inventorycode");
//   };
//   return (
//     <>  <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
//     {/* <MainHeading mainheading={"New Inventory Code"} /> */}
//     <form onSubmit={formik.handleSubmit} id="inventoryForm">
//       <Box
//         display="grid"
//         gap="30px"
//         margin={"20px 0"}
//         sx={{
//           gridTemplateColumns: {
//             xs: "repeat(1, minmax(0, 150px))",
//             sm: "repeat(1, minmax(0, 150px))",
//             md: "repeat(2, minmax(0, 150px))",
//           },
//         }}
//       >
//           <CustomButton
//           formId={"inventoryForm"}
//           type={"submit"}
//          isSave={true}
//           isBlue={true}
//           children={"Save"}
//         />
//         {/* <CustomButton
//           handleClick={() => {
//             const conform = window.confirm(
//               "Are you sure you want to cancel?"
//             );
//             if (conform) {
//               formik.resetForm();
//               navigate("/inventorycode");
//             }
//           }}
//           isCancel={true}
//           children={"Cancel"}
//         /> */}
//         <CustomButton isCancel={true} handleClick={handleCancel}>
//          Cancel
//        </CustomButton>
//       </Box>
//       <InventoryCodeForm formik={formik} />
//     </form>
//   </Box>
//    <CustomDialog
//    open={openCancelDialog}
//    isWarning={true}
//    handleClose={() => setOpenCancelDialog(false)}
//    handleConfirm={handleConfirmCancel}
//    handleCancel={() => setOpenCancelDialog(false)}
//    confirmButtonTitle="OK"
//    cancelButtonTitle="Cancel"
//   >
//    <Typography component="h3">Are you sure you want to cancel?</Typography>
//   </CustomDialog></>
  
//   );
// };

// export default NewInventoryCode;





import React from "react";
import { Box,Typography } from "@mui/material";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { inventoryCode } from "../../../utils/formikInitValues";
import InventoryCodeForm from "./InventoryCodeForm";
import { useDispatch } from "react-redux";
import { inventoryScehma } from "../../../schemas/patientSchema";
import { useNavigate } from "react-router-dom";
import { newInventoryCodeAction } from "../../../features/actions/inventoryCode";
import CustomDialog from "../../../components/CustomDialog";
const NewInventoryCode = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: inventoryCode,
    validationSchema: inventoryScehma,
    onSubmit: (values, actions) => {
      if (values.alert && !values.alertDescription) {
        toast.info("Alert Description is Required");
        return;
      }
      dispatch(newInventoryCodeAction(values));
      navigate("/inventorycode");
    },
  });

  return (
    <>  
  <InventoryCodeForm formik={formik} />
  </>
  
  );
};

export default NewInventoryCode;
