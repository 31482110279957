import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack,Typography } from "@mui/material";
import ProcedureInfo from "./ProcedureInfo";
import { procedureInitValues } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { procedureSchema } from "../../../schemas/patientSchema";
import { useDispatch } from "react-redux";
import { newProcedureCodeAction } from "../../../features/actions/procedureCodeAction";
import {handleCancel} from "../../../utils/customFunctions"
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const NewProcedure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: procedureInitValues,
    validationSchema: procedureSchema,
    onSubmit: (values, action) => {
      console.log(values,"values procedure")
      try {
        dispatch(newProcedureCodeAction([values]));
        navigate("/procedure-codes");
      } catch (err) {
        console.log(err, ": Error in creating new procedure code");
        throw err;
      }
    },
  });

  return (
    <>

        <ProcedureInfo formik={formik} />
   </>
  );
};

export default NewProcedure;
