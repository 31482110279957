import axios from "axios";
import { getTokenFromCookie } from "../utils/customFunctions";
export const postData = async (url, data) => {
  const token = localStorage.getItem("token");
  // const token = getTokenFromCookie();
  console.log(token);
  try {
    const response = await axios.post(
      url,
      data,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    if (response.status === 200) {
      console.log(response);

      return await response.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getData = async (url) => {
  const token = localStorage.getItem("token");
  // const token = getTokenFromCookie();
  console.log(token, "get");

  try {
    const response = await axios.get(url,{
      headers: { Authorization: `Bearer ${token}` },
    }) ;
    console.log(response.data, "getDataCustomFunc");
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Request failed with status: " + response.status);
    }
  } catch (error) {
    console.error(`Error in getData for URL ${url}:`, error);
    throw error;
  }
};

export const updateData = async (url, data) => {
  const token = localStorage.getItem("token");
  // const token = getTokenFromCookie();
  console.log(token);
  try {
    const response = await axios.put(
      url,
      data,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    console.log(response, "response6");
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Request failed with status: " + response.status);
    }
  } catch (error) {
    throw error;
  }
};
