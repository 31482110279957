import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomDataGrid from "../../../components/CustomDataGrid";

const ClaimPayer = ({ formik, handlePayer, openInsuredParty , handleClosePayer}) => {
  console.log(formik.values, "payerClaims");
  // const { getAllPayer } = useSelector((state) => state.payer);
  // const payerInfoLoading = useSelector((state) => state.payerInfo.loading);
  const { primaryPayers, secondaryPayer, tertiaryPayer, claimInsurances } =
    useSelector((state) => state.claim);
  const [tabValue, setTabValue] = useState(0);

  // useEffect(() => {
  //     if (formik.values?.patientId === null) {
  //         setTabValue(1)
  //     }
  // }, [])
  const groupedInsurances = {
    primary: claimInsurances?.filter(
      (item) => item.payerInfoPriorityTypeId === 1
    ),
    secondary: claimInsurances?.filter(
      (item) => item.payerInfoPriorityTypeId === 2
    ),
    tertiary: claimInsurances?.filter(
      (item) => item.payerInfoPriorityTypeId === 3
    ),
  };
  
  const handleSelectPayer = (policy) => {
    console.log(policy, "checkPolicy");
  
    // Find the index of the existing entry with the same priorityId
    const existingInd = formik.values.policyTypeDto.findIndex(
      (v) => v.priorityId === policy.payerInfoPriorityTypeId
    );
  
    if (existingInd !== -1) {
      // Replace the existing entry with the selected policy details
      formik.setFieldValue(`policyTypeDto[${existingInd}]`, {
        insuranceId: policy?.insuranceId || 0,
        insuranceName: policy?.insuranceName || "",
        insuranceSequenceNo: policy?.insuranceSequenceNo || 0,
        payerInfoId: policy?.payerInfoId || 0,
        priorityId: policy?.payerInfoPriorityTypeId,
        insuredPartyId: policy?.insuredId,
        priorityName: policy?.payerInfoPriorityType || "",
        memberId: policy?.payerInfoMemberId || "",
        groupId: policy?.payerInfoGroupId || "",
        policyTypeId: policy?.payerInfoPolicyTypeId,
        policyTypeName: policy?.payerInfoPolicyType,
        copayAmount: 0,
        claimControlNumber: "",
        authorizationNumber: "",
        referralTypeId: 0,
        referralType: "",
        claimNumber: 0,
        claimInfoId: 0,
      });
    } else {
      // Optionally handle the case if no matching priorityId is found
      alert("No matching priorityId found to replace.");
    }
    handleClosePayer()
  };
  
  
  return (
    <Box margin={"15px"}>
      {formik.values?.patientId !== null && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            sx={{
              marginBottom: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Tab
              label="Patient Payer"
              style={{ fontSize: "18px", fontWeight: "bold" }}
              className="tabsdesigning"
              value={0}
            />
            {/* <Tab
              label="All Payers"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
              className="tabsdesigning"
              value={1}
            /> */}
          </Tabs>
        </Stack>
      )}
      {/* {tabValue === 1 && <h1>Please Select Patient First</h1>} */}
      {tabValue === 0 &&
        claimInsurances?.length > 0 &&
        <Stack gap={"20px"}>
                {Object.entries(groupedInsurances).map(([type, items]) => (
          <Stack key={type} spacing={1}>
            <Typography component="h2" fontWeight={700}>
              {type} Insurance
            </Typography>
            {items.map((item, index) => (
              <Card
                sx={{
                  width: "100%",
                  cursor: "default",
                  "&:hover": {
                    transition: "all .3s ease-in-out",
                    bgcolor: "#EDF2F6",
                  },
                }}
                key={index}
                onClick={() => handleSelectPayer(item)}
              >
                <CardContent>
                  <Typography
                    fontWeight={700}
                    marginBottom="3px"
                    component="h4"
                  >
                    {item.insuranceName} ({item.insuranceSequenceNo})
                  </Typography>
                  <Divider />
                  <Stack direction="row" marginTop="10px">
                    <Stack width="40%">
                      <Typography component="h4">
                        Insured: {item.insuredFirstName} {item.insuredLastName}
                      </Typography>
                      <Typography component="h4">
                        Member ID: {item.payerInfoMemberId}
                      </Typography>
                      <Typography component="h4">
                        Group ID: {item.payerInfoGroupId}
                      </Typography>
                    </Stack>
                    <Stack width="35%">
                      <Typography component="h4">
                        Copay: {item.payerInfoCopayDue}
                      </Typography>
                      <Typography component="h4">Member ID: {0.0}</Typography>
                      <Typography component="h4">Group ID: {0.0}</Typography>
                    </Stack>
                    {item?.isDefault && (
                      <Typography component={"h4"} fontWeight={700}>
                        {type} Default
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        ))}
        </Stack>
    }
    </Box>
  );
};
export default ClaimPayer;
