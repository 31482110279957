import React from "react";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../../features/actions/userAction";
import CustomPreview from "./../../../layout/CustomPreview";
import { userCols } from "../../../utils/gridCols";
const AllUser = () => {
  const navigate = useNavigate();
  const { allUsers, loading } = useSelector((state) => state.users);
  console.log(allUsers, "checkAllUsers")
  const rows =
    allUsers && allUsers?.result?.user
      ? allUsers?.result?.user?.map((val, ind) => ({
          // id: val?.user?.id,
          id: val?.userId,
          ...val
        }))
      : [];

      console.log(rows,"rows")

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={userCols}
        loading={loading}
        handleGetAll={getAllUsers}
        paginationDetail={allUsers}
        moduleHeading="All Users"
        searchLabel="Search by name, email"
        handleCell={(params) => {
          if (params?.id) {
            navigate(`/user/update/${params?.id}`);
          }
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomButton
            isBlue={true}
            isAdd={true}
            handleClick={() => navigate("/user/new")}
          >
            New User
          </CustomButton>
        </Stack>
      </CustomPreview>
      {/* <h2>user</h2> */}
    </Box>
  );
};

export default AllUser;
