export const patientInitValues = {
  insurancePoliciesDtos: [],
  practiceId: 0,
  practiceSequenceNo: null,
  patientId: 0,
  accountNo: null,
  firstName: "",
  lastName: "",
  referenceNo: "",
  mi: "",
  suffix: "",
  ssn: "",
  isActive: true,
  email: "",
  drivingLicense: "",
  dateOfBirth: null,
  dateOfDeath: null,
  cellPhone: "",
  homePhone: "",
  workPhone: "",
  ext: null,
  address: "",
  zipCode: "",
  zipCodeId: 0,
  // dropdowns
  genderIdentityStatusId: null,
  genderIdentityStatusName:"",
  maritalStatusId: 1,
  raceStatusId: null,
  sexualOrientationId: null,
  employmentStatusId: 1,
  referralSourceId: null,
  relationShipToPatientId: 1,
  ethnicityId: null,
  studentStatusId: 1,
  accountTypeId: 1,
  cityId: null,
  cityName: "",
  countryId: 1,
  countryName: "",
  stateId: null,
  stateName: "",
  residenceTypeId: 1,
  // link family
  // linkPatientId: null,
  // linkPatientAccountNo: null,
  // linkFamilyRelationId: null,
  // linkFamilyRelation: "",
  // isLink: false,
  // Insured Information Data
  isSamePatient: false,
  isPatientInsured: false,
  showInsuranceData: false,
  selectedPriorityType: null,
  defaultICD_DiagnosisDto: [],
  defaultCPT_ProcedureDto: [],
  noteDetailDto: [],
  guarantorDto: {
    guarantorFirstName: "",
    guarantorLastName: "",
    guarantorMI: "",
    guarantorDOB: null,
    guarantorSSN: "",
    guarantorAddress: "",
    relationshipToPatientId: 1,
    relationshipToPatientName: "",
    guarantorCountryId: 1,
    guarantorCountryName: "",
    guarantorStateId: 0,
    guarantorStateName: "",
    guarantorCityId: 0,
    guarantorCityName: "",
    guarantorZipCodeId: 0,
    guarantorZipCode: "",
    guarantorHomePhone: "",
    guarantorCellPhone: "",
    guarantorWorkPhone: "",
    guarantorEmail: "",
    guarantorRemarks: "",
  },
  billingInfoDto: {
    billingInfoId: 0,
    isSendStatement: false,
    statementTypeId: 1,
    statementTypeName: "",
    mailStatementId: 1,
    mailStatementName: "",
    patientComment1: "",
    patientComment2: "",
    isFinalDemandNotice: false,
    collectionDate: null,
    collectionReason: "",
    billingRelationshipToPatientId:1,
    billingRelationshipToPatient:""
  },
  emergencyContact: {
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactAddress: "",
    emergencyContactCityId: 0,
    emergencyContactCity: "",
    emergencyContactStateId: 0,
    emergencyContactState: "",
    emergencyContactCountryId: 1,
    emergencyContactCountry: "",
    emergencyContactZipCodeId: 0,
    emergencyContactZipCode: "",
    relationshipToPatientId: 1,
    relationshipToPatientName: ""
  },
  employerDto: {
    employerName: "",
    employerAddress: "",
    countryId: 1,
    countryName: "",
    employerStateId: 0,
    employerStateName: "",
    employerCityId: 0,
    employerCityName:"",
    employerZipCodeId:0,
    employerZipCode:""
  },  
  // patientSummaryDto: {
  //   familyAccountTypeId: 0,
  //   familyAccountType: "",
  //   patientBalance: 0,
  //   lastPatientPayment: 0,
  //   insuranceBalance: 0,
  //   lastInsurancePayment: 0,
  //   primaryInsurance: "",
  //   copay: 0,
  //   lastStatement: 0,
  //   statementsMailedCount: 0,
  //   electronicStatementCount: 0,
  //   lastVisitDate: "",
  //   nextAppointment: "",
  //   followUpDate: null,
  //   recallDate: null,
  //   lastEligibilityCheck: ""
  // },
 claim_DefaultDto: {
    claimDefaultId: 0,
    defaultProviderId: 0,
    defaultProviderFirstName: "",
    defaultProviderLastName: "",
    defaultProviderSeqNo: null,
    defaultOrderingProviderId: null,
    defaultOrderingProviderFirstName: "",
    defaultOrderingProviderLastName: "",
    defaultOrderingProviderSeqNo: null,
    defaultFacilityId: null,
    defaultFacilityName: "",
    defaultFacilitySeqNo: null,
    defaultRenderingProviderId: null,
    defaultRenderingProviderFirstName: "",
    defaultRenderingProviderLastName: "",
    defaultRenderingProviderSeqNo: null,
    practiceName: "",
    practiceId: 0,
    practiceAddress: "",
    practiceSequenceNo: null,
    npiNo: null,
    assignmentOfBenefitId: 1,
    assignmentOfBenefitType: "",
    providerAcceptanceId: 1,
    providerAcceptanceType: "",
    isIncludeIllnessInfo: false,
    accidentDate: null,
    accidentState: "",
    accidentTypeId: 1,
    accidentType: "",
    otherClaimId: "",
    additionalClaimInfo: "",
    admissionDate: null,
    admissionHourId: null,
    admissionHour: "",
    lastMenstrualDate: null,
    initialTreatmentDate: null
  },

   alertMain: {
  activeCount: 0,
  alertDto: []
  }
};

export const refProviderInitVal=
{
  referringProviderId: 0,
  isReferrerSendOnClaims: false,
  referringProviderFirstName: "",
  referringProviderLastName: "",
  referringProviderMI: "",
  referringProviderCredential: "",
  referringProviderNpi: null,
  referringTypeId: 1,
  referringType: "",
  referringProviderTaxonomySpecialtyCode: "",
  referringProviderSequenceNo: null,
  referringProviderReferenceNo: null,
  referringProviderCode: "",
  referringPracticeSequenceNo: 0,
  providerTypeId: 1,
  providerType: "",
  address: "",
  zipCode: "",
  zipCodeId: 0,
  stateId: 0,
  cityId: 0,
  countryId: 1,
  cityName: "",
  countryName: "",
  stateName: "",
  homePhone:"",
  cellPhone: "",
  faxNo: "",
  pagerNo: "",
  email: "",
  taxID: null,
  taxIDTypeId: 1,
  taxIDType: "",
  upin: "",
  bcbsid: "",
  medicareId: "",
  medicaidId: "",
  champusId: "",
  specialityLicence: "",
  stateLicence: "",
  anesthesiaLicence: "",
  marketer: "",
  isActive:true,
}
export const alertInitVals = {
    alertId: 0,
    message: "",
    alertStartDate: "",
    alertEndDate: "",
    userId: "",
    userName: "",
    // isDeleted: true,
    isDeleted: false,
    accountNo: 0,
    patientId: 0,
    createdDate: null,
    isActive: true,
    displayOptions:[]
  
};


export const insuranceInfoValues = {
  isDefault: false,
  payerInfoPriorityId: 1,
  payerInfoId: 0,
  payerInfoMemberId: "",
  payerInfoGroupId: "",
  payerInfoCopayAmount: 0,
  payerInfoCoInsurancePercent: 0,
  payerInfoDeductibleAmount: 0,
  payerInfoOutOfPocketMax: 0,
  payerInfoEffectiveDate: "",
  payerInfoTerminationDate: "",
  payerInfoPriorityName: "",
  payerInfoPolicyId: 1,
  payerInfoPolicyType: "",
  // payerId: null,
  payerInfoPayerName: null,
  patientAccountNo: 0,
  payerSequenceNumber: null,
  defaultSetId: null,
  defaultSetName: "",
  insuredPartyId: null,
  insuredFirstName: "",
  insuredLastName: "",
  insuredRelationShipToPatientId: 8,
  insuredRelationShipToPatientName: "",
  insuranceDescriptionId: 0,
  insuranceDescriptionName:"",
  insuranceDescriptionSeqNo: null,
  isActive: true,
};

export const InsuredInfo = {
  insuredId: 0,
  insuredFirstName: "",
  insuredLastName: "",
  insuredDateOfBirth: "",
  insuredAddress: "",
  insuredSSN: "",
  insuredZipCode: "",
  insuredZipCodeId: 0,
  insuredHomePhone: "",
  insuredCellPhone: "",
  insuredWorkPhone: "",
  insuredExt: 0,
  insuredEmail: "",
  insuredCityId: 0,
  insuredCityName: "",
  insuredStateId: 0,
  insuredStateName: "",
  insuredCountryId: 1,
  insuredCountryName: "",
  insuredGenderIdentityId: 0,
  insuredGenderIdentityName: "",
  insuredPriorityType: "",
  insuredPriorityTypeId: 1,
  patientAccountNo: null,
};

// export const insuranceInfoValues = {
//   existingInsuredParty: false,
//   insuredDetail: {
//     insuredFirstName: "",
//     insuredLastName: "",
//     insuredDateOfBirth: "",
//     insuredAddress: "",
//     insuredSSN: null,
//     insuredZipCode: "",
//     insuredHomePhone: null,
//     insuredCellPhone: null,
//     insuredWorkPhone: null,
//     insuredExt: null,
//     insuredEmail: "",
//     insuredCityId: null,
//     insuredCityName: "",
//     insuredStateId: null,
//     insuredStateName: "",
//     insuredCountryId: null,
//     insuredCountryName: "",
//     insuredRelationShipToPatientId: 0,
//     insuredRelationShipToPatientName: "",
//     insuredGenderIdentityId: null,
//     insuredGenderIdentityName: "",
//     insuredPriorityType: "",
//     insuredPriorityTypeId: null,
//     employeeId: null,
//     employeeName: "",
//     empAddress: "",
//     empCityId: null,
//     empCityName: null,
//     empStateId: null,
//     empStateName: "",
//     empZipCode: "",
//     empEmploymentStatusId: null,
//     empEmploymentStatusName: "",
//     patientAccountNo: null,
//     isLink: false,
//     isActive: false,
//     insurancePoliciesDtos: [],
//   },
//   payerDetailVals: {
//     payerInfoId: null,
//     payerInfoMemberId: "",
//     payerInfoGroupId: "",
//     payerInfoCopayAmount: 0,
//     payerInfoCoInsurancePercent: 0,
//     payerInfoDeductibleAmount: 0,
//     payerInfoOutOfPocketMax: 0,
//     payerInfoEffectiveDate: null,
//     payerInfoTerminationDate: null,
//     payerInfoPriorityId: 1,
//     payerInfoPriorityName: "",
//     payerInfoPolicyId: 1,
//     payerInfoPolicyType: "",
//     payerId: null,
//     payerInfoPayerName: "",
//     patientPayerAccountNo: null,
//     payerInfoSequenceNumber: null,
//     defaultSetId: 1,
//     defaultSetName: "",
//     insuredFirstName: "",
//     insuredLastName: "",
//   },
// };

export const claimInitVal = {
  patientFirstName: "",
  patientLastName: "",
  patientAccountNo: null,
  EPSDT_DetailDto: [],
  icD_DiagnosisDetailDto: [],
  dxpointer: [],
  patientId: null,
  practiceId: null,
  billingProviderId: null,
  supervisorProvider:"",
  orderingProvider:"",
  referringProvider:"",
  insuredPartyId: null,
  payerInfoId: null,
  certificationFieldDetailDto: [],
  renderingProviderId: null,
  supervisingProviderId: null,
  providerId: null,
  facilityId: null,
  billingProviderFirstName: "",
  billingProviderLastName: "",
  billingProviderSequence: null,
  renderingProviderFirstName: "",
  renderingProviderLastName: "",
  renderingProviderSequence: null,
  facilityName: "",
  facilitySequence: null,
  supervisingProviderName: "",
  practiceAddress: "",
  practiceSequenceNo: null,
  primaryPayerInsuranceName:"",
  payerSequenceNo: null,
  primaryPayerMemberId: null,
  primaryPayerGroupId: null,
  primaryPayerPolicyId: null,
  primaryPayerCopayDue: 0,
  frequencyTypeId: null,
  claimFrequencyId: 1,
  typeOfBill: null,
  referenceNumber: "",
  careTypeId: null,
  facilityTypeId: null,
  claimTypeId: 1,
  policyTypeDto: [],
  // tcn: "abc",
  // totalCharges: 200,
  // totalBilled: 200,

  // additioal info init vals
  formTypeId: 1,
  isEmployment: false,
  isAutoAccident: false,
  isOtherAccident: false,
  accidentDate: null,
  lastMenstrualDate: null,
  initialTreatmentDate: null,
  lastSeenDate: null,
  unableToWorkFromDate: null,
  unableToWorkToDate: null,
  isPatientHomebound: false,
  claimCodes: "",
  otherClaimId: null,
  claimInformation: "",
  claimNote: "",
  reasonCode: "",
  reasonDelayCodeId: 1,
  hospitalizedFromDate: null,
  hospitalizedToDate: null,
  labCharges: 0,
  specialProgramCodeId: null,
  patientSignatureId: 1,
  insuredSignatureId: 1,
  providerAcceptanceId: 1,
  documentationMethodId: 1,
  documentationTypeId: null,
  patientHeight: 0,
  patientWeight: 0,
  serviceAuthorizationId: null,
  demonstrationProject: "",
  mammographyCertification: "",
  investigationalDeviceExemption: "",
  ambulatoryPatientGroup: "",

  // ambulance info
  ambulanceClaimId: 2,
  transportReasonId: null,
  transportMiles: 0,
  ambPatientWeight: 0,
  roundTripReason: "",
  stretcherReason: "",
  pickupAddress: "",
  pickupCityId: null,
  pickupCity: "",
  pickupStateId: null,
  pickupState: "",
  pickupZipCode: "",
  dropOffName: "",
  dropOffAddress: "",
  dropOffCityId: null,
  dropOffCity: "",
  dropOffStateId: null,
  dropOffState: "",
  dropOffZipCode: "",
  certificationFieldType: "",

  // institutional
  // isProfessional: 1,
  statementCoversFromDate: null,
  statementCoversToDate: null,
  admissionDate: null,
  admissionHourId: null,
  admissionTypeId: null,
  admissionSourceId: null,
  dischargeHourId: null,
  patientStatusId: null,
  delayReasonCodeId: null,
  pps: "",
  patientDueAmount: 0,
  remarks: "",
  releaseOfInfoId: null,
  assignmentOfBenefitId: null,
  // // providerAcceptAssignment: null,
  // epsdT_1: 0,
  // epsdT_2: 0,
  // epsdT_3: 0,
  principalDiagnosisId: null,
  poaId: null,
  admittingDiagnosisId: null,
  injuryCauseId: null,
  patientVisitReasonId: null,
  otherDiagnosisId: null,
  principalProcedureId: null,
  principalProcedureDate: null,
  occurrenceSpanId: null,
  occurrenceSpanFromDate: null,
  occurrenceSpanToDate: null,
  occurrenceId: null,
  occurrenceDate: null,
  valueId: null,
  valueAmount: 0,
  conditionId: null,
  claimChargesDto: [],
  // intial value of INfoCOde DTO
  insClaimInfoDetailDto: [],
  descriptionFrom: null,
  claimSummaryDto: {
    formTypeId: 1,
    formType: "CMS 1500 02-12",
    totalAmount: 0,
    insPayments: 0,
    patPayment: 0,
    adjustments: 0,
    balance: 0,
    patientCredits: 0,
    lastStatement: "",
    patientFollowUpDate: null,
    patientRecallDate: null,
    dateOfService: null,
    dateEntered: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
    lastBilledDate: null,
    followUpDate: null,
    copayRemaining: 0,
    claimNumber: 0,
    claimId: 0,
    accountNo: null,
    patientId: null,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    age: 0,
    gender: "",
  },
};

export const professionalClaimValues = {
  claimId: 0,
  claimNumber: 0,
  claimTypeId: 1,
  claimType: "",
  isClaimComplete: false,
  referenceNumber: "",
  claimFrequencyId: 1,
  claimFrequency: "",
  patientId: 0,
  patientAccountNo: 0,
  patientFirstName: "",
  patientLastName: "",
  renderingProviderId: 0,
  renderingProviderSeqNo: 0,
  renderingProviderFirstName: "",
  renderingProviderLastName: "",
  billingProviderId: 0,
  billingProviderSeqNo: 0,
  billingProviderFirstName: "",
  billingProviderLastName: "",
  supervisingProviderId: 0,
  supervisingProviderSeqNo: 0,
  supervisingProviderFirstName: "",
  supervisingProviderLastName: "",
  orderingProviderId: 0,
  orderingProviderSeqNo: 0,
  orderingProviderFirstName: "",
  orderingProviderLastName: "",
  referringProviderId: 0,
  referringProviderSeqNo: 0,
  referringProviderFirstName: "",
  referringProviderLastName: "",
  facilityId: 0,
  facilitySeqNo: 0,
  facilityName: "",
  practiceId: 0,
  practiceSeqNo: 0,
  practiceName: "",
  practiceAddress: "",
  dxpointer:[],
  policyTypeDto: [],
  claimChargesDto: [],
  icD_DiagnosisDetailDto: [],
  additionalInfoDto: {
    claimAdditionalInfoId: 0,
    formTypeId: 1,
    formType: "",
    isEmployment: false,
    isAutoAccident: false,
    isOtherAccident: false,
    accidentDate: "",
    lastMenstrualDate: "",
    initialTreatmentDate: "",
    lastSeenDate: "",
    unableToWorkFromDate: "",
    unableToWorkToDate: "",
    isPatientHomebound: false,
    claimCodes: "",
    otherClaimId: 0,
    claimInformation: "",
    claimNote: "",
    reasonCode: "",
    reasonDelayCodeId: 1,
    reasonDelayCode: "",
    hospitalizedFromDate: "",
    hospitalizedToDate: "",
    labCharges: 0,
    specialProgramCodeId: null,
    specialProgramCode: "",
    patientSignatureId: 1,
    patientSignature: "",
    insuredSignatureId: 1,
    insuredSignature: "",
    providerAcceptanceId: 1,
    providerAcceptance: "",
    documentationMethodId: 1,
    documentationMethod: "",
    documentationTypeId: null,
    documentationType: "",
    patientHeight: 0,
    patientWeight: 0,
    serviceAuthorizationId: null,
    serviceAuthorization: "",
    demonstrationProject: "",
    mammographyCertification: "",
    investigationalDeviceExemption: "",
    ambulatoryPatientGroup: ""
  },
  ambulanceInfoDto: {
    claimAmbulanceInfoId: 0,
    ambulanceClaimId: 2,
    ambulanceClaim: "",
    transportReasonId: null,
    transportReason: "",
    transportMiles: 0,
    ambPatientWeight: 0,
    roundTripReason: "",
    stretcherReason: "",
    pickupAddress: "",
    pickupCityId: 0,
    pickupCity: "",
    pickupCountryId: 1,
    pickupCountry: "",
    pickupStateId: 0,
    pickupState: "",
    pickupZipCode: "",
    pickupZipCodeId: 0,
    dropOffName: "",
    dropOffAddress: "",
    dropOffCityId: 0,
    dropOffCity: "",
    dropOffStateId: 0,
    dropOffState: "",
    dropOffCountryId: 1,
    dropOffCountry: "",
    dropOffZipCode: "",
    dropOffZipCodeId: 0
  },
  certificationFieldDetailDto: []
}

export const payerPolicyTypeInClaim = {
  payerId: null,
  copayAmount: 0,
  payerName: "",
  payerSequenceNo: null,
  priorityId: null,
  priorityName: "",
  memberId: null,
  groupId: null,
  policyTypeId: null,
  claimControlNumber:"",
  authorizationNumber:"",
  referralType:"",
  controlNumber:""
};

export const paymentInitVal3 = {
  isClaim: true,
  paymentByTypeId: 1,
  payerSequenceNo: null,
  payerId: null,
  accountNo: null,
  paymentFrom: null,
  paymentFromName: "",
  paymentBy: "",
  payerName: null,
  paymentAmount: 0,
  checkDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  checkNumber: null,
  paymentTypeId: null,
  copayServiceDate: null,
  memo: null,
  eftNumber: null,
  paymentMethodId: 1,
  creditCardTypeId: null,
  providerId: null,
  providerName: null,
  sequenceNo: null,
  totalPaymentReceived: 0,
  applied: 0,
  currentClaim: null,
};

// practice
export const practiceInitVals = {
  practiceName: "",
  practiceNpi: null,
  taxonomySpecialty: "",
  taxonomyDescription:"",
  businessNameAs:"",
  license:"",
  organizationTypeId: 1,
  organizationTypeName: "",
  sequenceNo: null,
  referenceNo: null,
  tcnPrefix: "",
  code: "",
  isPayToAddressSame: false,
  address: "",
  cityId: 0,
  city: "",
  stateId: 0,
  state: "",
  countryId:1,
  country: "",
  zipCode: "",
  zipCodeId:0,
  phone: "",
  fax: "",
  payToAddress: "",
  payToCityId: 0,
  payToCity: "",
  payToStateId: 0,
  payToState: "",
  payToZipCode: "",
  payToZipCodeId:0,
  payToCountryId:1,
  payToCountry:"",
  isActive:true,
  timeZoneId:1,
  timeZoneName:"",
  practiceOffice:[],
  createdById:0,
  createdBy:""
};
// faccility
export const facilityInitVals = {
  facilityName: "",
  facilityNpiId: null,
  facilityNpiNo: null,
  taxonomySpecialty: "",
  // sequenceNo: null,
  referenceNo: "",
  facilityAddress: "",
  facilityStateId:0,
  facilityCountryId: 1,
  facilityCountry: "",
  facilityState: "",
  facilityCityId: 0,
  facilityCity: "",
  facilityZipCode: "",
  phoneNumber: "",
  faxNumber: "",
  email: "",
  taxId: 0,
  cliaId: "",
  locationProviderId: "",
  siteId: "",
  blueCrossId: "",
  blueShieldId: "",
  medicareId: "",
  medicaidId: "",
  locatorCode: "",
  posCodeId: null,
  posCode: "",
  practiceId:0,
  practiceName:"",
  isActive: true
};

export const diagnosisInitVal = {
  diagnosisCode: null,
  diagnosisCodeId: 0,
  codeTypeId: 1,
  codeType:"",
  diagnosisDescription: null,
  effectiveDate: null,
  terminationDate: null,
  isActive:true
};

// provider
export const providerInitValues = {
  providerType: "",
  providerTypeId: 1,
  providerFirstName: "",
  providerLastName: "",
  providerMI: "",
  providerCredential: "",
  providerNPINo: null,
  providerTaxonomySpecialtyCode: "",
  providerReferenceNo: null,
  providerSequenceNo: null,
  providerCode: "",
  practiceName: "",
  billingProviderId: null,
  billingProviderFirstName: "",
  billingProviderLastName: "",
  billingProviderSeqNo: null,
  eligibilityProviderId: null,
  eligibilityProviderFirstName: "",
  eligibilityProviderLastName: "",
  eligibilityProviderSeqNo: null,
  practiceSequenceNo: null,
  practiceId: null,
  practiceAddress: "",
  id_NoId: 2,
  id_NoType: "",
  id_NoValue: null,
  billAsId: 1,
  billAsType: "",
  claimModeId: null,
  claimModeType: "",
  claimBillingId: null,
  claimBillingType: "",
  submitterNo: 0,
  homePhone: "",
  cellPhone: "",
  faxNo: "",
  email: "",
  specialtyLicenseNo: "",
  stateLicenseNo: "",
  anesthesiaLicenseNo: "",
  upinNo: "",
  blueCrossNo: "",
  tricareNo: "",
  revCodeId: null,
  billingProvider: "",
  eligibilityProvider: "",
  revCode: "",
  license: "",
  expiryDate: null,
  isActive: true
};

// register
export const register = {
  username: null,
  email: null,
  password: null,
  confirmPassword: null,
  roleName: null,
};

// remittance
export const remittanceValues = {
  remittanceCodeName: "",
  remittanceTypeId: null,
  remittanceType: "",
  remittanceInfoLevelId: null,
  remittanceInfoLevel: "",
  remittanceCodeDetailDtos: [],
  reportDescription: "",
  categoryCodeId:0,
  categoryCodeName:"",
  effectiveDateFrom:null,
  effectiveDateTo:null,
  isActive:true,
  longDescription: "",
  isMemoline: false,
  isMemolineAmount: false,
};

// adjustment codes
export const adjustmentInitVals = {
  adjustmentCode: "",
  adjustmentCodeTypeId: 0,
  adjustmentCodeType: "",
  description: "",
  isActive:true
};

// revenue
export const revenueInitVals = {
  revenueCode: "",
  revenueCodePrice: null,
  isDuplicateService: false,
  revenueCodeDescription: "",
  statementDescription: "",
  isActive:true
};

export const procedureInitValues = {
  procedureCodeId: 0,
  procedureCode: "",
  procedureCodeTypeId: null,
  procedureCodeType: null,
  procedureDepartment: "",
  procedureDescription: "",
  price: 0,
  units: 1,
  claimStatusId: null,
  claimStatus: null,
  revenueCodeId: null,
  revenueCode: null,
  posCodeId: null,
  posCode: null,
  cliA_No: null,
  tosCodeId: null,
  tosCode: null,
  narrativeNotes: null,
  drugPrice: 0,
  drugUnits: 1,
  drugUnitMeasureId: null,
  drugUnitMeasure: null,
  drugCode: null,
  drugUnitFormatId: null,
  drugUnitFormat: null,
  additionalDescription: null,
  globalPeriodId: null,
  globalPeriod: null,
  effectiveDates: null,
  terminationDates: null,
  isSuperBill: false,
  superBillCategory: null,
  superBillDescription: null,
  statementDescription: null,
  procedureSettingDetailDto: [],
  procedureModifierDetailDto: [],
  isActive:true,
};

//inventory Code
export const inventoryCode = {
  code: null,
  procedureCodeId: null,
  procedureCode: null,
  quantity: 0,
  codeDescription: null,
  billingDescription: null,
  alert: false,
  alertDescription: null,
  alertQuantity: 0,
};

// export const payerIntialVal = {
//   payerId: 0,
//   payerName: "",
//   planName: "",
//   payerGroupNumber: "",
//   payerClaimOfficeNumber: "",
//   payerIDMediaGapNumber: "",
//   payerOCNA: "",
//   payerClaimOfficeAddress: "",
//   payerAddress: "",
//   payerZipCode: "",
//   payerPhone: null,
//   payerFax: "",
//   payerEmail: "",
//   payerWebsite: "",
//   payerCityId: null,
//   payerCity: "",
//   payerStateId: null,
//   payerState: "",
//   payerTypeId: 1,
//   payerType: "",
//   payerSequenceNo: null,
//   payerReferenceNo: "",
//   networkStatusId: null,
//   networkStatusName: "",
//   defaultChargeStatusId: 1,
//   defaultChargeStatusName: "",
//   clearinghouseProcessingModeId: 1,
//   clearinghouseProcessingModeName: "",
//   useAlternatePractice: false,
//   payerAlternatePracticeDto: {
//     payerAlternatePracticeId: 0,
//     payerId: 0,
//     payerAlternatePracticeName: "",
//     payerAlternatePracticeAddress: "",
//     payerAlternatePracticeZipCode: "",
//     payerAlternatePracticeCityId: null,
//     payerAlternatePracticeStateId: null,
//     payerAlternateTaxId: "",
//     payerAlternatePracticeOverrideAddressId: 1,
//     payerAlternatePracticeNpiId: null,
//     payerAlternatePracticeTaxonomySpecialtyCode: "",
//     alternatePracticeNpiNo: "",
//     payerAlternatePracticeOverrideAddressName: "",
//   },
// };

export const payerIntialVal = {
  insuranceDescriptionId: 0,
  insuranceDescriptionName: "",
  planName: "",
  groupNumber: "",
  claimOfficeNumber: "",
  mediaGapNumber: "",
  ocna: "",
  claimOfficeAddress: "",
  insuranceGroupId: 1,
  sequenceNo: 0,
  referenceNo: "",
  insuranceTypeId:null,
  insuranceTypeName: "",
  networkStatusId: 1,
  networkStatus: "",
  defaultChargeStatusId: 1,
  defaultChargeStatus: "",
  clearinghouseProcessingModeId: 1,
  clearinghouseProcessingMode: "",
  insurance835Id: "",
  isActive:true,
  insurance837Id: "",
  insuranceAddressDtos: [],
};

export const insuranceAddresses = {
  insuranceAddressId: 0,
  address: "",
  countryId: 1,
  country: "",
  stateId: 0,
  state: "",
  cityId: 0,
  city: "",
  // zipCodeId: 112,
  zipCodeId: null,
  zipCode: null,
  phoneNumber: "",
  fax: "",
  email: "",
  website: "",
  department: "",
  insuranceDescriptionId: null,
  insuranceSequenceNo: null,
};

// charge panel
export const chargePanelInitVal = {
  chargePanelTitle: "",
  chargePanelCode: "",
  claimTypeId: 1,
  claimType: "",
  chargePanelDescription: "",
  chargePanelCodeDtos: [],
};

// charge panel procedure
export const chargePanelProcedureValues = {
  procedureCodeId: null,
  procedureCode: "",
  posCodeId: null,
  posCode: "",
  tosCodeId: null,
  tosCode: "",
  modifierOptionId: 1,
  modifierOption: "",
  mod_1: null,
  mod_Code1: "",
  mod_2: null,
  mod_Code2: "",
  mod_3: null,
  mod_Code3: "",
  mod_4: null,
  mod_Code4: "",
  priceOptionId: 1,
  priceOption: "",
  unitPrice: 0,
  units: null,
  total: 0,
};

export const searchPayment = {
  searchTerm: "",
  paymentTypeId: 2,
  number: "",
  name: "",
  ctdosId: "",
  paymentId: [],
  fromDate: "",
  toDate: "",
};

export const paymentPlanValues = {
  paymentPlanAmount: 0,
  existingChargeId: 1,
  firstPayDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  paymentDueMonthId: 1,
  paymentDueEvery: 1,
  generateInstallmentId: 1,
  generateInstallmentName: "",
  eachInstallmentAmount: 0,
  totalNumberOfPayments: 1,
  lastPaymentDate: (() => {
    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    return `${nextMonthDate.getFullYear()}-${(nextMonthDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
  })(),
  description: "",
  detailtDto: [],
};

export const statementBatchPrintVal = {
  accountTypeIds: [],
  batchPrintTypeId: 1,
  statementAmountId: 3,
  statementAmountValue1: 0,
  statementAmountValue2: 0,
  electStatementId: 1,
  electStatementValue1: 0,
  electStatementValue2: 0,
  paperStatementId: 1,
  paperStatementValue1: 0,
  paperStatementValue2: 0,
  totalStatementId: 1,
  totalStatementValue1: 0,
  totalStatementValue2: 0,
  daysLastStatementId: 1,
  daysLastStatementValue1: 0,
  daysLastStatementValue2: 0,
  chargesIncludeId: 1,
  chargeStatusIds: [],
  separateStatementId: 0,
  isSeparateStatement: false,
  practiceSequenceNumbers: [],
  providerSequenceNumbers: [],
  showLastPayment: false,
  accountCredits: false,
};

export const batchLabelPrintValues = {
  patientLastName: 1,
  patientLastName1: "",
  patientLastName2: "",
  accountType: "",
  patientState: "",
  patientAge: "",
  patientBirthMonth: "",
  totalBalance: "",
  patientBalance: "",
  insuranceBalance: "",
  paperStatementsSent: "",
  electronicStatementsSent: "",
  totalStatementsSent: "",
  payerSearch: "",
};

export const icdProcedureInitVals = {
  // icdProcedureCodesId: 0,
  icdCode: "",
  icdCodeTypeId: 1,
  description: "",
  // icdCodeType:""
};

export const debitInitialValueManageAccount = {
  debitCodeId: null,
  amount: 0,
  receivedDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  memo: "",
  providerId: null,
  applied: 0,
  remaining: 0,
  providerName: "",
  codeType: "",
  practiceId: null,
  practiceAddress: "",
  patientAccountNo: null,
  patientId: null,
};
export const creditInitialValueManageAccount = {
  paymentByTypeId: 1,
  paymentByType: "",
  creditCodeId: null,
  codeType: "",
  amount: 0,
  applied: 0,
  remaining: 0,
  receivedDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  memo: "",
  providerName: "",
  practiceAddress: "",
  providerId: null,
  practiceId: 1,
  patientAccountNo: null,
  patientId: null,
};

// status control
export const statusControlInitVal = {
  showDeleted: false,
  enteredDateId: 0,
  enteredfromDate: null,
  enteredtoDate: null,
  dosId: 0,
  doSfromDate: null,
  doStoDate: null,
  submittedId: 0,
  submittedfromDate: null,
  submittedtoDate: null,
  reviewStatusID: 0,
  // claimTypeId: 0,
  currentPayerSeqNum: 0,
  claimstatusid: 1,
  currentPayerTypeId: [],
  claimTypeId: [],
  patientAccountNo: 0,
  renderingProviderId: 0,
  facilityID: 0,
  procedureCode: null,
  trackingStatus: 0,
};

export const refundIntValManageAccount = {
  paymentByTypeId: 1,
  paymentByType: "",
  providerId: null,
  practiceId: null,
  refundAmount: null,
  practiceName: "",
  providerName: "",
  refundDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  checkNumber: "",
  memo: "PATIENT REFUND",
  patientAccountNo: null,
  patientId: null,
  amount: 0,
  applied: 0,
  remaining: 0,
};

export const claimTrackerIntVal = {
  filterSearchId: 1,
  ctDosId: 1,
  ctFromDate: null,
  ctToDate: null,
  statusDateId: null,
  sdFromDate: null,
  sdToDate: null,
  submissionTypeId: [],
  processingModeId: [],
  trackingStatusTypeId: [],
  trackingStatusSourceId: [],
  claimStatusId: [],
  claimTypeId: [],
  tcn: "",
  insuranceCheckNumber: "",
  claimNo: null,
  patientAccountNo: null,
  payer: [],
  payerTypeId: [],
  billingProvidersId: [],
  renderingProviderId: [],
  facilityId: [],
  claimHistory: false,
};

export const followUpManagementVal = {
  claimStatusId: [],
  followUpId: 0,
  followUpToDate: null,
  followUpFromDate: null,
  dateOfServiceId: 0,
  dateOfServiceToDate: null,
  dateOfServiceFromDate: null,
  payerId: [],
  practiceId: [],
  providerId: [],
  facilityId: [],
  payerTypeId: [],
  claimNumber: 0,
  patientAccountNumber: 0,
  billedInsId: 0,
  // filterByDaysOrDateId: 1,
  // noOfDays: 1,
  // claimTypeId: 1,
  // claimBalanceId: 1,
  filterByDaysOrDateId: 0,
  noOfDays: 0,
  claimTypeId: 0,
  claimBalanceId: 0,
  billedInsDateId: 0,
  billedInsFromDate: null,
  billedInsToDate: null,
  claimBalanceValue1: 0,
  claimBalanceValue2: 0,
};
export const claimBatchPrintIntVal = {
  claimFormId: 1,
  claimEnterdId: 1,
  claimEnterdFromDate: null,
  claimEnterdToDate: null,
  ctDosId: 1,
  ctFromDate: null,
  ctToDate: null,
  practiceSeqNums: [],
  providerSeqNums: [],
  facilitySeqNums: [],
  officeLocation: [],
  payerSeqNums: [],
  patientAccountNo: null,
  payerPriority: [],
  claimAmountId: null,
  claimAmountVal1: null,
  claimAmountVal2: null,
};
export const feeScheduleVal = {
  ctFeesScheduleId: 1,
};

export const userManagementIntVal={
  username:'',
  userType:'',
  userTypeId:4,
  firstName:'',
  lastName:'',
  title:'',
  email:'',
  password:'',
  confirmPassword:'',
  phone1:'',
  phone2:'',
  companyId:0,
  companyName:"",
  createdById:0,
  createdBy:"",
  ext1:null,
  ext2:null,
  mi:'',
  userPracticeDto:[],
}

export const companyInitVals={
    companyId: null,
    companyName: "",
    companyCode: 0,
    address: "",
    phoneNumber: "",
    email: "",
    description: "",
    zipCodeId: 0,
    zipCode: "",
    website: "",
    countryId: 1,
    countryName: "",
    stateId: 0,
    stateName: "",
    cityId: 0,
    cityName: "",
    isActive: true,
    planOptionsId: 1,
    planOptionsName: "" 
}