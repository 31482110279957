import { FormGroup, Grid, Paper, Stack } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomButton from "../../../components/CustomButton";
import CustomAddress from "../../../components/CustomAddress";
import CustomModal from "../../../components/CustomModal";
import SearchNpi from "./npi/SearchNpi";
import { Done } from "@mui/icons-material";

const OtherOfiice = ({formik, handleClose}) => {
  const [openSearchedNpiMod, setSearchedOpenNpiMod] = useState(false);
  const [timeZoneOpts, setTimeZoneOpts] = useState([]);
  const [npiResultData, setNpiResultData] = useState([]);
  const [openNpiPaper, setOpenNpiPaper] =  useState(false)
  const officeFormik = useFormik({
    initialValues: {
      practiceOfficeName: "",
      sequenceNo: 0,
      practiceNpi: null,
      isActive: true,
      address: "",
      cityId: 0,
      city: "",
      stateId: 0,
      state: "",
      countryId: 1,
      country: "",
      zipCode: "",
      zipCodeId: 0,
      timeZoneId: 1,
      timeZoneName: "",
      practiceId: 0,
      practiceName: "",
    },
    validationSchema: yup.object().shape({
      practiceOfficeName: yup.string().required(),
      address: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      zipCode: yup.string().required(),
    }),
    onSubmit: (values, action) => {
      console.log(values, "submittedOfficeValues");
      formik.setValues(preVals => ({
        ...preVals,
        practiceOffice: [...preVals.practiceOffice, values]
      }))
      action.resetForm();
      handleClose()

    },
  });
  

  useEffect(() => {
    let flag = false;

    const fetchTimeZoneData = async () => {
      try {
        const response = await getData(`${path}/CTTimeZone`);
        if (response) {
          console.log(response, "timeZoneData");
          setTimeZoneOpts(response.result);
        }
      } catch (e) {
        console.log(e, ": error in fetching time zone data");
      }
    };

    if (!flag) {
      flag = true;
      fetchTimeZoneData();
    }
  }, [setTimeZoneOpts]);

  const handleSelectNpi = (npiNum) => {
    officeFormik.setFieldValue("practiceNpiId", npiNum?.npiId);
    officeFormik.setFieldValue("practiceNpi", npiNum?.npiNo);
  };

  // npi
const handleNpiChange = async (e) => {
  const npiValue = e.target.value;
  officeFormik.setFieldValue("practiceNpi", npiValue);
  if (officeFormik.values.practiceNpi.length >= 4) {
    try {
      const response = await getData(
        `${path}/npi/searchNPI?npiNo=${npiValue}`
      );
      if (response.result.length > 0) {
        setNpiResultData(response.result);
        setOpenNpiPaper(true)
      }
    } catch (e) {
      console.log(e, ": Error in feting NPI");
      throw e;
    }
  }else {
    setOpenNpiPaper(false)
  }
};
  return (
    <Grid container spacing={2} onSubmit={officeFormik.handleSubmit} component={"form"} padding={"20px"}>
      <Grid item md={12}>
        <CustomField
          label={"Name"}
          value={officeFormik.values.practiceOfficeName}
          name="practiceOfficeName"
          handleChange={officeFormik.handleChange}
          handleBlur={officeFormik.handleBlur}
          type={"text"}
          isRequired={true}
          error={officeFormik.errors}
          touched={officeFormik.touched}
        />
      </Grid>
      <Grid item md={6}>

               <FormGroup
                sx={{
                  position: "relative",
                }}
              >
                <CustomSearchField
                  isRequired={true}
                  fieldVal={officeFormik.values?.practiceNpi}
                  name="practiceNpi"
                  handleChange={handleNpiChange}
                  handleBlur={officeFormik.handleBlur}
                  handleModalOpen={() => setSearchedOpenNpiMod(true)}
                  label="NPI"
                  formik={officeFormik}
                  resetField={{
                    practiceNpi: null,
                  }}
                  isTypeAble={true}
                />
                {openNpiPaper && (
                  <Paper
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 111,
                      boxShadow: 3,
                      width:"100%"
                    }}
                  >
                    {npiResultData?.map((item) => (
                      <Stack margin="5px" width="100%" key={item?.npiId}>
                        <CustomButton
                          isBlue={false}
                          handleClick={() => {
                            officeFormik.setFieldValue("practiceNpi", item?.npiNo)
                            setOpenNpiPaper(false)
                          }}
                        >
                          <Done fontSize="large" sx={{ marginRight: "5px" }} />{" "}
                          {item?.npiNo}
                        </CustomButton>
                      </Stack>
                    ))}
                  </Paper>
                )}
              </FormGroup>
      </Grid>
      <Grid item md={6}>
        <CustomSelectBox2
          label="Time Zone"
          dropdownOptions={timeZoneOpts?.map(el => ({
            id:el?.timeZoneId,
            value: el?.timeZoneName,
          }))}
          handleChange={officeFormik.handleChange}
          handleBlur={officeFormik.handleBlur}
          value={officeFormik.values.timeZoneId}
          name={"timeZoneId"}
          showDefaultOpt={true}
        />
      </Grid>
      <Grid item md={12}>
        <CustomField
          label={"Address"}
          value={officeFormik.values.address}
          name="address"
          handleChange={officeFormik.handleChange}
          handleBlur={officeFormik.handleBlur}
          type={"text"}
          isRequired={true}
          error={officeFormik.errors}
          touched={officeFormik.touched}
        />
      </Grid>

    <CustomAddress formik={officeFormik}
            fieldValues={{
              countryId: officeFormik.values.countryId,
              country: officeFormik.values.country,
              countrytryName: "country",
              stateId: "stateId",
              stateName: "state",
              state: officeFormik.values.state,
              cityId: "cityId",
              cityName: "city",
              city: officeFormik.values.city,
              zipCode: officeFormik.values.zipCode,
              zipCodeId: "zipCodeId",
              zipName: "zipCode",
            }} isRequired={true} />
      <Grid item md={12} textAlign={"right"}>
        <CustomButton isAdd={true} isBlue={true} type="submit">Add</CustomButton>
      </Grid>

        {/* npi modal */}
        <CustomModal
        open={openSearchedNpiMod}
        handleClose={() => setSearchedOpenNpiMod(false)}
        heading={"NPI Search"}
        width={"60%"}
      >
        <SearchNpi
          handleSelectNpi={handleSelectNpi}
          handleClose={() =>setSearchedOpenNpiMod(false)}
          setSearchNpiModal={setSearchedOpenNpiMod}
        />
      </CustomModal>
    </Grid>
    
  );
};

export default OtherOfiice;
