import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel,Grid } from '@mui/material';
import { getData } from '../../../config/axiosFunctions';

import path from '../../../config/apiUrl';

const NPIRegistry = () => {
  const [providerTypes, setProviderTypes] = useState([]);

  const DateSource = {
    providerType: `/ct-provideType`,

  };

  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(DateSource.providerType, setProviderTypes);

  }, []);


  return (
    <>
    <Grid container>
        <Grid item md={12}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="provider-type"
            // name="providerType"
            // value={selectedType}
            // onChange={handleChange}
          >
            {providerTypes.map((item) => (
              <FormControlLabel
                key={item.providerTypeId}
                // value={}
                control={<Radio />}
                label={item.providerType}
              />
            ))}
          </RadioGroup>
        </FormControl>
        </Grid>
    </Grid>

    </>
  );
};

export default NPIRegistry;
