import React
//  { useCallback, useEffect, useState }
  from "react";
// import { getData } from "../config/axiosFunctions";
// import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import path from "../config/apiUrl";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Stack,
  Typography,
  useTheme,
  // TextField
} from "@mui/material";
// import { Button } from "react-bootstrap";
// import { styled } from '@mui/material/styles';
// import CustomButton from "./CustomButton";
// import CloseIcon from "@mui/icons-material/Close";
// import CustomExpandIcon from "./CustomExpandIcon";
// import SummaryIcon from "./SummaryIcon";
import CustomDatePicker from "./CustomDatePicker";
// import CustomField from "./CustomField";
// import FileUpload from "./pdfs/FileUpload";
// import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
// import axios from "axios";
// import { toast } from "react-toastify";
const AccountSummary = ({ formik }) => {
  // const [files, setFiles] = useState([]);
  // const [accountSumaryData, setAccountSummaryData] = useState(null);
  const accountSumaryData = formik?.values?.patientSummaryDto
  console.log(accountSumaryData, "accountSummaryData")
  // account summary
  // const getAccountSummary = async () => {
  //   // console.log(Number(accountNo), "checkAccNo");
  //   try {
  //     const response = await getData(
  //       `${path}/patientAccountSummary?accountNo=${accountNo}`
  //     );
  //     if (response.status === "Success") {
  //       setAccountSummaryData(response.result);
  //     }
  //   } catch (err) {
  //     console.log(err, "Getting Account Summary");
  //   }
  // };

  // const getDocumentFiles = async () => {
  //   try {
  //     const response = await getData(`${path}/encryptFile/ViewPatientDocument?AccountNumber=${accountNo}`);
  //     if (response.status === "success") {
  //       console.log("response of account summary", response);
  //     }
  //   } catch (error) {
  //     console.log("error whike fetching files of patient", error);
  //   }
  // };

  // useEffect(() => {
  //   getAccountSummary();
  //   // getDocumentFiles()
  // }, [setAccountSummaryData]);

  // function handleMultipleSubmit(event) {
  //   event.preventDefault();
  //   const url = `${path}/encryptFile/UploadPatientDocument`;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("file", files[i]);
  //   }
  //   formData.set("patientId", accountNo);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };

  //   axios
  //     .post(url, formData, config)
  //     .then((response) => {
  //       console.log(response.data);
  //       toast.success("Document uploaded successfully");
  //     })
  //     .catch((error) => {
  //       toast.error("Server error");
  //       console.error("Error uploading files: ", error);
  //     });
  //   handleClose();
  // }

  const handleIncrement = (field) => {
    const currentValue = accountSumaryData?.[field] || 0;
    formik.setFieldValue(`patientSummaryDto.${field}`, currentValue + 1);
  };
  
  const handleDecrement = (field) => {
    const currentValue = accountSumaryData?.[field] || 0;
    if (currentValue > 0) {
      formik.setFieldValue(`patientSummaryDto.${field}`, currentValue - 1);
    }
  };
  
  const theme = useTheme();
  return (
    <Box>
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "scroll",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Family Account Type:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.familyAccountType === null
                  ? "No Account"
                  : accountSumaryData?.familyAccountType}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.patientBalance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Patient Payment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastPatientPayment}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Insurance Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.insuranceBalance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Insurance Payment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastInsurancePayment}
              </Typography>
            </Stack>
            {/* <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Collection Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.collectionBalance}
              </Typography>
            </Stack> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Primary Insurance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.primaryInsurance === ""
                  ? "No Primary Insurance Payer Specified"
                  : accountSumaryData?.primaryInsurance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Copay:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.copay === null
                  ? 0
                  : accountSumaryData?.copay}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Statement:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastStatement}
              </Typography>
            </Stack>

            {/* statements mailed count */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography
                type="number"
                component="h3"
                fontSize="1.4rem !important"
              >
                Statements Mailed Count:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.lastStatement === null
                  ? 0
                  : accountSumaryData?.lastStatement}
              </Typography> */}
                <div style={{ position: "relative", width: "190px", height: "30px"}}>
      <input
        type="number"
        name='patientSummaryDto.statementsMailedCount' 
        value={accountSumaryData?.statementsMailedCount}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        style={{
          width: "100%",
          height: "100%",
          padding:'10px',
          borderRadius:'5px',
          fontSize:'1.3rem',
          border:'1px solid grey'
        }}
      />
      <ArrowDropUpIcon
        onClick={() => handleIncrement('statementsMailedCount')}
        style={{
          position: "absolute",
          top: "5%",
          left: "90%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
      />
      <ArrowDropDownIcon
         onClick={() => handleDecrement('statementsMailedCount')}
        style={{
          position: "absolute",
          bottom: "5%",
          left: "90%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
      />
    </div>
            </Stack>

            {/* Electronic Statement Count: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Electronic Statement Count:
              </Typography>
              {/* <Typography type='number'component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.lastStatement === null
                  ? 0
                  : accountSumaryData?.lastStatement}
              </Typography> */}

<div style={{ position: "relative", width: "190px", height: "30px"}}>
      <input
        type="number"
        name='patientSummaryDto.electronicStatementCount'
        value={accountSumaryData?.electronicStatementCount}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        style={{
          width: "100%",
          height: "100%",
          padding:'10px',
          borderRadius:'5px',
          fontSize:'1.3rem',
          border:'1px solid grey'
        }}
      />
      <ArrowDropUpIcon
        onClick={() => handleIncrement('electronicStatementCount')}
        style={{
          position: "absolute",
          top: "5%",
          left: "90%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
      />
      <ArrowDropDownIcon
        onClick={() => handleDecrement('electronicStatementCount')}
        style={{
          position: "absolute",
          bottom: "5%",
          left: "90%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
      />
    </div>

            </Stack>

            {/* Last Visit: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Visit:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastVisitDate === null
                  ? 0
                  : accountSumaryData?.lastVisitDate}
              </Typography>
            </Stack>

            {/* Next Appointment: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Next Appointment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.nextAppointment === null
                  ? 0
                  : accountSumaryData?.nextAppointment}
              </Typography>
            </Stack>

            {/* Follow-Up Date: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Follow-Up Date:
              </Typography>
              <CustomDatePicker formik={formik} name='patientSummaryDto.followUpDate' dateValue={accountSumaryData?.followUpDate} 
              isFromTo={true} 
              handleDateBlur={formik.handleBlur} handleDateChange={formik.handleChange} />
            </Stack>

            {/* Recall Date: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Recall Date:
              </Typography>
              <CustomDatePicker formik={formik} name='patientSummaryDto.recallDate' dateValue={accountSumaryData?.recallDate} 
              isFromTo={true} 
              handleDateBlur={formik.handleBlur} handleDateChange={formik.handleChange}   />
            </Stack>
            {/* Last Eligibility Check: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                <span style={{ color: "#216FED" }}>?</span> Last Eligibility
                Check:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
             
                {accountSumaryData?.lastEligibilityCheck === null
                  ? 0
                  : accountSumaryData?.lastEligibilityCheck}
              </Typography>
            </Stack>
          </Box>
    </Box>
 
  );
};

export default AccountSummary;
