import React, { useState } from 'react';
import { Box,Stack,Typography } from '@mui/material';
import MainHeading from '../../../components/MainHeading';
import CustomButton from '../../../components/CustomButton';
import StatusControlInfo from './StatusControlInfo';
import { useFormik } from 'formik';
import { statusControlInitVal } from '../../../utils/formikInitValues';
import { useDispatch } from 'react-redux';
import { statusControlAction } from '../../../features/actions/statusControlAction';
import { useConfirm } from 'material-ui-confirm';
import SearchedControls from './SearchedControls';

const StatusControl = () => {
  const moduleName = "Status_Control"
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const [searchedStatusData, setSearchedStatusData] = useState([])
  const [isDataFethed, setIsDataFetched] = useState(false)
  const [payload, setPayload] = useState({})
  const formik = useFormik({
    initialValues:statusControlInitVal,
    onSubmit: (values, action) => {
      // console.log(values, "checkStatusControlVals")
      const currentPayerTypeIdsOnly = formik.values.currentPayerTypeId?.map(el => el?.value)
      const claimTypeIdsOnly = formik.values.claimTypeId?.map(el => el?.value)
      const updatedValues = {
        ...values,
        currentPayerTypeId:currentPayerTypeIdsOnly,
        claimTypeId:claimTypeIdsOnly
      }
      dispatch(statusControlAction(updatedValues)).then(({payload}) => {
        if(payload?.result?.statusControlMain?.statusControl?.length > 0) {
          setPayload(updatedValues)
          setSearchedStatusData(payload?.result?.statusControlMain?.statusControl)
          setIsDataFetched(true)
        }else {
            confirm({
              title:payload?.result?.message,
              confirmationText:"Ok"
            })
          setIsDataFetched(false)
        }
      })
    }
  })
  return (
    <>
       <Box margin="20px">
        {/* <MainHeading mainheading={"Status Control"} /> */}
          <Box
            display="grid"
            // gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
          
          </Box>
          {
            isDataFethed ? <SearchedControls payload={payload} moduleName={moduleName} handleClose={() => setIsDataFetched(false)} searchedStatusData={searchedStatusData} formik={formik} setSearchedStatusData={setSearchedStatusData} /> :  <StatusControlInfo  moduleName={moduleName} formik={formik}/>
          }
         
      </Box>
    </>
  )
}
export default StatusControl
