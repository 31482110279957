import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getInventoryCodeAction = createAsyncThunk("getInventoryCode", async (page) => {
  try {
    console.log(page);
    const newPage = page?.page+1
    const response = await getData(`${path}/InventoryCode?page=${newPage}&pageSize=10`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const newInventoryCodeAction = createAsyncThunk(
  "createInventoryCode",
  async (data) => {
    try {
      const response = await postData(`${path}/InventoryCode`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "check Error");
      throw new Error(error);
    }
  }
);

// search
export const getInventoryCodeBySearchTermAction = createAsyncThunk("getInventoryCode", async ({searchTerm,page}) => {
  try {
    const response = await getData(`${path}/InventoryCode/SearchInventoryCode?searchValue=${searchTerm}&page=${page}&pageSize=10`);
    console.log(response, "action InventoryCode by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});


export const getInventoryCodeIdAction = createAsyncThunk(
  "getInventoryCodeById",
  async (id) => {
    try {

      const response = await getData( `${path}/InventoryCode/${id}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateInventoryCodeAction = createAsyncThunk(
  "updateInventoryCode",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/InventoryCode/${data.inventoryCodeId}`,
        data
      );
      console.log(response, "update prac action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
