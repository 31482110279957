import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import RCMLogo from "../../assets/rcmLogo.png";
import Toggle from "../../components/Toggle";
import RCMDark from "../../assets/rcmdark.png";
import ProfileDropdown from "../../components/ProfileDropdown";
import { FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getPracticeAction,
  getPracticeListAction,
} from "../../features/actions/practiceAction";
import CustomDialog from "../../components/CustomDialog";
import { switchPracticeAction } from "../../features/actions/userAction";
import { useNavigate } from "react-router-dom";
const Topbar = ({ handleThemeToggle, isLoginPage }) => {
  let flag = false;
  const navigate = useNavigate();
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const theme = useTheme();
  const dispatch = useDispatch();
  const logo = theme.palette.mode === "dark" ? RCMDark : RCMLogo;
  const title = theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode";
  const token = localStorage.getItem("token");
  const getId = localStorage.getItem("practiceId");
  const { getPracticeListData } = useSelector((state) => state.practices);
  const [practiceIdVal, setPracticeIdVal] = useState("");
  // const handleClick = () => {
  //   handleThemeToggle();
  // };

  const handlePracticeSelect = (event, newValue) => {
    console.log(newValue);

    // dispatch(handleSetPracticeSeq(newValue.sequenceNo));
    // setSelectedPractice(newValue.sequenceNo)

    // localStorage.setItem("practiceSequenceNo", newValue.practiceSeqNo);
    // localStorage.setItem("practiceId", newValue.practiceId);
    setPracticeIdVal((preval) => preval = newValue.practiceId);

    setOpenConfirmBox(true);
  };

  const handleSwitchPractice = async () => {
    localStorage.setItem("practiceId", practiceIdVal);
    try {
      
      await dispatch(switchPracticeAction(practiceIdVal === "" ?  getId : practiceIdVal)).then(({ payload }) => {
        localStorage.setItem("token", payload.result.token);
      });
        // Navigate to the dashboard after switching practices
    navigate("/dashboard");
      window.location.reload();
    } catch (err) {
      console.log("Error switching practice", err);
    }
    setOpenConfirmBox(false);
    return;
  };

  useEffect(() => {
    if (token) {
      if (!flag) {
        flag = true;
        dispatch(getPracticeListAction());
      }
    }
  }, [token]);
  return (
    <Box
      sx={{
        // height: "68px",
        height: "60px",
        background: theme.palette.topBarBackground.primary,
        boxShadow: "7px 0px 19px 0px rgba(0, 0, 0, 0.15)",
        display: "flex",
        position: "fixed",
        top: 0,
        zIndex: 3333,
        width: "100%",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <img style={{ width: "58%", height: "85%" }} src={logo} alt="logo" />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        alignItems={"center"}
      >
        {/* <Grid item>
          <Autocomplete
            disablePortal
            options={userDetails?.userPractices?.map((item) => ({
              label: item?.practiceName,
              ...item,
            }))}
            isOptionEqualToValue={(option, value) =>
              option.sequenceNo === value.sequenceNo
            }
            value={
              userDetails?.userPractices?.find(
                (item) => item?.sequenceNo === parseInt(getSeq)
              )?.practiceName || null
            }
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} label="Practices" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.sequenceNo}>
                {option.practiceName}
              </li>
            )}
            onChange={handlePracticeSelect}
          />
        </Grid> */}
        <Grid item md={2}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              color: theme.palette.fieldsText.primary,
              textAlign: "right",
              // marginTop: "23px",
            }}
          >
            Practices :
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            disablePortal
            options={getPracticeListData?.result?.map((item) => ({
              label: item?.practiceName,
              ...item,
            }))}
            isOptionEqualToValue={(option, value) =>
              // option.practiceSeqNo === value.practiceSeqNo
              option.practiceId === value.practiceId
            }
            value={
              getPracticeListData?.result?.find(
                // (item) => item?.practiceSeqNo === parseInt(getSeq)
                (item) => item?.practiceId === parseInt(getId)
              )?.practiceName || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  background: theme.palette.customFieldsBackground.primary,
                  color: theme.palette.fieldsText.primary,
                  width: "80%",
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    marginTop: "-10px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "35px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                  // marginTop: "15px",
                }}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                }}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                // key={option.practiceSeqNo}
                key={option.practiceId}
                style={{
                  fontSize: "14px",
                  padding: "4px 12px",
                  borderBottom: "1px solid lightgray",
                }}
              >
                {option.practiceName}
              </li>
            )}
            onChange={handlePracticeSelect}
          />
        </Grid>

        <Grid item>{!isLoginPage && <ProfileDropdown />}</Grid>
      </Grid>

      {/* confirm box here */}
      <CustomDialog
        open={openConfirmBox}
        confirmButtonTitle="Yes"
        isWarning={true}
        handleClose={() => setOpenConfirmBox(false)}
        handleConfirm={handleSwitchPractice}
        handleCancel={() => setOpenConfirmBox(false)}
      >
        <Typography component={"h3"}>
          Are you sure, you want to switch?
        </Typography>
      </CustomDialog>
    </Box>
  );
};
export default Topbar;
