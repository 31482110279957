import {
  AccordionDetails,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomAddress from "../../../components/CustomAddress";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import CustomAccordion from "../../../components/CustomAccordion";
let certificationFieldDetailList = [
  {
    certificationFieldCodeId: 1,
    certificationFieldType: "Patient was admitted to a hospital",
  },
  {
    certificationFieldCodeId: 2,
    certificationFieldType: "Patient was unconcious or in shock",
  },
  {
    certificationFieldCodeId: 3,
    certificationFieldType: "Patient had to be physically restrained",
  },
  {
    certificationFieldCodeId: 4,
    certificationFieldType: "Ambulance service was medically necessary",
  },
  {
    certificationFieldCodeId: 5,
    certificationFieldType: "Patient was moved by stretcher",
  },

  {
    certificationFieldCodeId: 6,
    certificationFieldType: "Patient was transported in an emergency situation",
  },

  {
    certificationFieldCodeId: 7,
    certificationFieldType: "Patient had visible hemorrhaging",
  },
  {
    certificationFieldCodeId: 8,
    certificationFieldType: "Patient was confined to a bed or chair",
  },
];
const AmbulanceInfo = ({ formik }) => {
  const [transportReason, setTransportReason] = useState([]);
  const dataFetchUrls = {
    transportReason: `${path}/ct-transportReason`,
    cities: `${path}/city`,
    states: `${path}/state`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.transportReason, setTransportReason);
  }, []);

  const handleChangeCertificationField = (e) => {
    let val = [...formik.values.certificationFieldDetailDto];
    let findVal = val.findIndex(
      (v) => v?.certificationFieldCodeId === e?.certificationFieldCodeId
    );
    if (findVal !== -1) {
      val.splice(findVal, 1);
    } else {
      if (val.length < 5) {
        val.push(e);
      } else {
        val.pop();
        val.push(e);
      }
    }
    formik.setFieldValue("certificationFieldDetailDto", val);
  };
  const theme = useTheme();
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: "20px",
          background: theme.palette.innerBox.primary,
          padding: "20px",
        }}
      >
        <Grid item md={2} xs={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ fontSize: "1.5rem", fontWeight: "600" }}
          >
            Ambulamce Claim
          </FormLabel>
        </Grid>
        <Grid item md={10} xs={12}>
          <RadioGroup
            sx={{
              marginLeft: "20px",
            }}
            row
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  checked={
                    formik.values.ambulanceInfoDto.ambulanceClaimId === 1
                  }
                  onChange={() =>
                    formik.setFieldValue("ambulanceInfoDto.ambulanceClaimId", 1)
                  }
                  style={{ color: theme.palette.radioButtons.primary }}
                />
              }
              label={
                <Typography variant="h5" fontWeight="600">
                  Yes
                </Typography>
              }
            />
            <FormControlLabel
              value="No"
              control={
                <Radio
                  checked={
                    formik.values.ambulanceInfoDto.ambulanceClaimId === 2
                  }
                  onChange={() =>
                    formik.setFieldValue("ambulanceInfoDto.ambulanceClaimId", 2)
                  }
                  style={{ color: theme.palette.radioButtons.primary }}
                />
              }
              label={
                <Typography variant="h5" fontWeight="600">
                  No
                </Typography>
              }
            />
          </RadioGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomSelectBox2
            value={formik.values.ambulanceInfoDto.transportReasonId}
            name="ambulanceInfoDto.transportReasonId"
            dropdownOptions={transportReason?.map((opt) => ({
              value: opt.transportReasonType,
              id: opt.transportReasonId,
            }))}
            label="Transport Reason"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            type="text"
            value={formik.values.ambulanceInfoDto.roundTripReason}
            name="ambulanceInfoDto.roundTripReason"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            label="Round Trip Reason"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomField
            label="Stretcher Reason"
            type="text"
            value={formik.values.ambulanceInfoDto.stretcherReason}
            name="ambulanceInfoDto.stretcherReason"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomField
            label="Transport Miles"
            type="number"
            value={formik.values.ambulanceInfoDto.transportMiles}
            name="ambulanceInfoDto.transportMiles"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            isDecimal={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomField
            label="Patient Weight"
            type="number"
            value={formik.values.ambulanceInfoDto.ambPatientWeight}
            name="ambulanceInfoDto.ambPatientWeight"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Pickup Address"}
            hasErrors={
              formik.errors.ambulanceInfoDto?.pickupAddress ||
              formik.errors.ambulanceInfoDto?.pickupZipCode
            }
          >
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <CustomField
                    label="Address"
                    type="text"
                    value={formik.values.ambulanceInfoDto.pickupAddress}
                    name="ambulanceInfoDto.pickupAddress"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={formik.errors}
                    touched={formik.touched}
                    isRequired={
                      formik.values.ambulanceInfoDto.ambulanceClaimId === 1
                        ? true
                        : false
                    }
                  />
                </Grid>
                <CustomAddress
                  formik={formik}
                  fieldValues={{
                    countryId: formik.values.ambulanceInfoDto.pickupCountryId,
                    country: formik.values.ambulanceInfoDto.pickupCountry,
                    countryName: "ambulanceInfoDto.pickupCountry",
                    stateId: "ambulanceInfoDto.pickupStateId",
                    stateName: "ambulanceInfoDto.pickupState",
                    state: formik.values.ambulanceInfoDto.pickupState,
                    cityId: "ambulanceInfoDto.pickupCityId",
                    cityName: "ambulanceInfoDto.pickupCity",
                    city: formik.values.ambulanceInfoDto.pickupCity,
                    zipCode: formik.values.ambulanceInfoDto.pickupZipCode,
                    zipCodeId: "ambulanceInfoDto.pickupZipCodeId",
                    zipName: "ambulanceInfoDto.pickupZipCode",
                  }}
                  isRequired={
                    formik.values.ambulanceInfoDto.ambulanceClaimId === 1
                      ? true
                      : false
                  }
                />
              </Grid>

              {/* <Grid item xs={12}>
                <div style={{ marginTop: "10px" }}>
                  <CheckBox label={"Institutional"} />
                </div>
              </Grid> */}
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Dropoff Address"}
            hasErrors={
              formik.errors.ambulanceInfoDto?.dropOffAddress ||
              formik.errors.ambulanceInfoDto?.dropOffZipCode
            }
          >
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CustomField
                    label="Address"
                    type="text"
                    value={formik.values.ambulanceInfoDto.dropOffAddress}
                    name="ambulanceInfoDto.dropOffAddress"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    error={formik.errors}
                    touched={formik.touched}
                    isRequired={
                      formik.values.ambulanceInfoDto.ambulanceClaimId === 1
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomField
                    label="Name"
                    type="text"
                    value={formik.values.ambulanceInfoDto.dropOffName}
                    name="ambulanceInfoDto.dropOffName"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </Grid>

                <CustomAddress
                  formik={formik}
                  fieldValues={{
                    countryId: formik.values.ambulanceInfoDto.dropOffCountryId,
                    country: formik.values.ambulanceInfoDto.dropOffCountry,
                    dropOffCountry: "ambulanceInfoDto.countryName",
                    stateId: "ambulanceInfoDto.dropOffStateId",
                    stateName: "ambulanceInfoDto.dropOffState",
                    state: formik.values.ambulanceInfoDto.dropOffState,
                    cityId: "ambulanceInfoDto.dropOffCityId",
                    cityName: "ambulanceInfoDto.dropOffCity",
                    city: formik.values.ambulanceInfoDto.dropOffCity,
                    zipCode: formik.values.ambulanceInfoDto.zipCode,
                    zipCodeId: "ambulanceInfoDto.dropOffZipCodeId",
                    zipName: "ambulanceInfoDto.dropOffZipCode",
                  }}
                  isRequired={
                    formik.values.ambulanceInfoDto.ambulanceClaimId === 1
                      ? true
                      : false
                  }
                />
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
        <Grid item md={12}>
          <CustomAccordion
            defaultExpanded={false}
            heading={"Certification Fields"}
          >
            <AccordionDetails>
              <Typography
                variant="h5"
                style={{
                  marginTop: "-20px",
                  marginBottom: "10px",
                  fontWeight: "600",
                }}
                fontWeight={"500"}
              >
                Select up to 5
              </Typography>
              <Grid container>
                {certificationFieldDetailList.map((val, ind) => {
                  return (
                    <Grid item md={4} xs={12}>
                      <CheckBox
                        key={ind}
                        val={val}
                        handleChange={() => handleChangeCertificationField(val)}
                        label={val?.certificationFieldType}
                        CheckedValue={formik.values?.certificationFieldDetailDto.some(
                          (v) =>
                            v?.certificationFieldCodeId ===
                            val?.certificationFieldCodeId
                        )}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </CustomAccordion>
        </Grid>
      </Grid>
    </>
  );
};

export default AmbulanceInfo;
