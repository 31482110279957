import React, { useState } from "react";
import "./newlogin.css";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  // TextField,
  Typography,
  Link,
  // useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import CustomField from "../components/CustomField";
import path from "../config/apiUrl";
import RCMLogo from "../assets/rcmLogo.png";
import { toast } from "react-toastify";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

const NewLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  // const theme = useTheme();

  // formik here
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      toast.loading("Logging in RCM MATTER ... Please wait!");
      let response;
      if (formik.values.email && formik.values.password) {
        try {
          response = await axios.post(`${path}/authenticate/login`, values);
          if (response.status === 200) {
            // let expireDate = new Date(response.data.expiration);
            // document.cookie = `token=${
            //   response.data.result.token
            // }; expires=${expireDate.toUTCString()}`;
            localStorage.setItem("token", response.data.result.token);
            localStorage.setItem("userName", response.data.result.userName);
            localStorage.setItem("userId", response.data.result.id);
            localStorage.setItem("userDetails", JSON.stringify({
              userName: response.data.result.userName,
              userId: response.data.result.id,
              userPractices: response.data.result.userPractices,
              userType: response.data.result.userType,
              userTypeId: response.data.result.userTypeId,
            }));
            localStorage.setItem("practiceSequenceNo", response.data.result.practiceSeq);
            navigate("/dashboard");
            toast.dismiss();
            toast.success("Login Successful");
          }
        } catch (error) {
          console.log(error?.response?.data?.message || "Server Error");
          toast.dismiss();
          toast.error(error?.response?.data?.message || "Server Error");
        }
      } else {
        toast.error("Please Enter Valid Credentials");
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          // background: 'blue',
          backgroundColor: "#EDF2F6",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          margin: 0,
          padding: 0,
          overflow: "hidden", // Hide the scroll bar
        }}
      >
        <Box
          className="background"
          sx={{
            width: "100%",
            height: "400px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "400px" },
              position: "absolute",
              top: { xs: "150px", sm: "150px", md: "-60px" },
              right: { xs: "0", sm: "0", md: "70px" },
              height: "18%",
            }}
          >
            <Box
              className="inner"
              sx={{
                borderRadius: {
                  xs: "38px 38px 0px 0px",
                  sm: "10px",
                  md: "10px",
                },
              }}
            >
              <img style={{ marginLeft: "20%" }} src={RCMLogo} alt="logo" />
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "400px" },
              position: "absolute",
              top: { xs: "150px", sm: "150px", md: "45px" },
              right: { xs: "0", sm: "0", md: "70px" },
              height: "85%",
            }}
          >
            <Box
              component={"form"}
              className="inner"
              sx={{
                borderRadius: {
                  xs: "38px 38px 0px 0px",
                  sm: "10px",
                  md: "10px",
                },
              }}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "40px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Login{" "}
                <span
                  style={{
                    fontWeight: "lighter",
                    fontSize: "40px",
                    color: "black",
                  }}
                >
                  Here
                </span>
              </Typography>

              <Typography
                variant="h6"
                fontWeight="600"
                color="#216FED"
                fontFamily="Radio Canada"
                marginTop={1}
              >
                Email
              </Typography>

              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="loginFields"
                type="text"
                name="email"
                min={0}
              />

              <Typography
                variant="h6"
                fontWeight="600"
                color="#216FED"
                fontFamily="Radio Canada"
                marginTop={2}
              >
                Password 
              </Typography>

              <div style={{ position: "relative" }}>
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="loginFields"
                  id="outlined"
                  type={showPassword ? "text" : "password"}
                  name="password"
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
              <br />

              {/* <Link
                sx={{
                  position: "absolute",
                  top: { sm: "220px", xs: "280px" },
                  left: { lg: "270px", md: "280px", sm: "500px", xs: "180px" },
                }}
                href="#"
                underline="none"
                color="#DE1D1D"
                fontFamily="Radio Canada"
                fontSize={"1.4rem"}
              >
                {"Forgot Password"}
              </Link>
              <br /> */}

              {/* <label
                style={{
                  fontSize: "1.4rem",
                  color: "#696969",
                  fontFamily: "Radio Canada",
                }}
              >
                <input
                  type="checkbox"
                  style={{
                    color: "#216FED",
                    height: "15px",
                    width: "15px",
                    marginLeft: "2px",
                    marginTop: "27%",
                  }}
                />
                <span style={{ marginLeft: "5px" }}>Remember me</span>
              </label>
              <br /> */}

              <Button
                type="submit"
                sx={{
                  color: "#FFF",
                  position: "absolute",
                  top: "310px",
                  left: { xs: "80px", lg: "100px" },
                  background: "#0464FF",
                  borderRadius: "10px",
                  border: "1px solid #0464FF",
                  width: "48%",
                  fontFamily: "Radio Canada",
                  fontSize: { xs: "1rem", lg: "1.3rem" },
                  "&:hover": {
                    background: "#0464FF",
                    color: "white",
                    border: "1px solid #0464FF",
                  },
                }}
                variant="outlined"
                size="medium"
              >
                Get started
              </Button>
              <Typography
                sx={{
                  position: "absolute",
                  top: "93%",
                  left: { lg: "37%", sm: "45%", md: "39%", xs: "37%" },
                  color: "black",
                }}
              >
                TechMatter PVT.LTD
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewLogin;
