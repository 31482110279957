import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomSearchField from "./../../components/CustomSearchField";
import { useNavigate } from "react-router-dom";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomField from "../../components/CustomField";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";
import { useEffect } from "react";
import { useState } from "react";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomAccordion from "../../components/CustomAccordion";
import CustomModal from "../../components/CustomModal";
import Provider from "../custom-setup/provider/Provider";
import Facility from "../custom-setup/facility/Facility";
import Diagnosis from "../custom-setup/diagnosis/Diagnosis";
import CheckBox from "../../components/CheckBox";
import ReferringProvider from "../custom-setup/referring-provider/ReferringProvider";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../features/actions/providerAction";
import ProcedureCodes from "../custom-setup/procedure-codes/ProcedureCodes";
import Loading from "./../../components/Loading";
import CustomPreview from "../../layout/CustomPreview";
import { facilityCols, refProvider } from "../../utils/gridCols";
import { useSelector } from "react-redux";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../features/actions/facilityAction";
import { providerCols } from "../../utils/gridCols";
import {
  getDiagnosisAction,
  getDiagnosisBySearchTermAction,
} from "../../features/actions/diagnosisAction";
import { diagnosisCodesCols } from "../../utils/gridCols";
import { procedureCodesCols } from "../../utils/gridCols";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "../../features/actions/procedureCodeAction";
import CustomDialog from "../../components/CustomDialog";

const ClaimDefaults = ({ formik }) => {
  console.log(formik?.values, "<<<<<<>>>>>");

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { getDiagnosisData } = useSelector((state) => state.diagnosis);
  const { getFacilityData, loading } = useSelector((state) => state.facility);
  const { getProviders } = useSelector((state) => state.provider);
  const { getProcedureCodes } = useSelector((state) => state.procedureCodes);
  const [assignmentBenefits, setAssignmentBenefits] = useState([]);
  const [providerAssignment, setProviderAssignment] = useState([]);
  console.log(providerAssignment);
  
  const [accidentTypes, setAccidentTypes] = useState([]);
  const [admissionHour, setAdmissionHour] = useState([]);
  const [openDefProvider, setOpenDefProvider] = useState(false);
  const [openRefferingModal, setOpenRefferingModal] = useState(false);
  const [openFacilityModal, setOpenFacilityModal] = useState(false);
  const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
  const [openProcedureModal, setOpenProcedureModal] = useState(false);
  const [openOrdProvider, setOpenOrdProvider] = useState(false);
  const [providerData, setProviderData] = useState([]);
  const [identifier, setIdentifier] = useState("");
  const [cptIdentifier, setCptIdentifier] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [renderingProviderInfo, setRenderingProviderInfo] = useState([]);

  // Define data fetching URLs
  const dataFetchUrls = {
    assignmentBenefits: `${path}/ct-claimDefaultBenefit`,
    providerAssignment: `${path}/ct-claimDefaultAcceptance`,
    admissionHour: `${path}/ct-admissionHour`,
    accidentType: `${path}/ct-accidentType`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.assignmentBenefits, setAssignmentBenefits);
    fetchDataOptions(dataFetchUrls.providerAssignment, setProviderAssignment);
    fetchDataOptions(dataFetchUrls.admissionHour, setAdmissionHour);
    fetchDataOptions(dataFetchUrls.accidentType, setAccidentTypes);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await getData(dataFetchUrls.provider);
  //       console.log(response, "check response in default provider");
  //       if (
  //         response.result?.providers.length > 0 &&
  //         formik.values.defaultProviderId === null
  //       ) {
  //         const defaultProvider = response?.result?.providers[0];
  //         formik.setValues((prevValues) => ({
  //           ...prevValues,
  //           defaultProviderId: defaultProvider.providerId,
  //           defaultProviderName: `${defaultProvider.providerFirstName} ${defaultProvider.providerLastName}`,
  //           defaultProviderSeqNo: defaultProvider.providerSequenceNo,
  //           claimDefaultOfficeLocation: defaultProvider.practiceAddress,
  //           defaultNpiNo: defaultProvider.providerNPINo,
  //           defaultPractice: `${defaultProvider.practiceName} (${defaultProvider.practiceSequenceNo})`,
  //         }));
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // provider handle cell click

  const handleDefaultProvider = (paramValues) => {
    console.log(paramValues, "paramValuesparamValuesparamValuesparamValues");

    formik.setFieldValue("claim_DefaultDto.defaultProviderId", paramValues.id);
    formik.setFieldValue(
      "claim_DefaultDto.defaultProviderFirstName",
      `${paramValues.providerFirstName} `
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultProviderLastName",
      `${paramValues.providerLastName ? paramValues.providerLastName : ""}`
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultProviderSeqNo",
      paramValues.providerSequenceNo
    );
    formik.setFieldValue(
      "claim_DefaultDto.practiceAddress",
      paramValues.practiceAddress
    );
    formik.setFieldValue("claim_DefaultDto.npiNo", paramValues.providerNPINo);
    formik.setFieldValue(
      "claim_DefaultDto.practiceName",
      `${paramValues?.practiceName}`
    );
    formik.setFieldValue(
      "claim_DefaultDto.practiceSequenceNo",
      paramValues?.practiceSequenceNo
    );
    formik.setFieldValue(
      "claim_DefaultDto.practiceId",
      paramValues?.practiceId
    );
    setOpenDefProvider(false);
  };
  console.log(formik?.values);
  
  // facilty handle cell
  const handleDefaultFacility = (paramsValues) => {
    formik.setFieldValue("claim_DefaultDto.defaultFacilityId", paramsValues.id);
    formik.setFieldValue(
      "claim_DefaultDto.defaultFacilityName",
      paramsValues.facilityName
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultFacilitySeqNo",
      paramsValues.sequenceNo
    );
    setOpenFacilityModal(false);
  };
  // handle referring provider
  const handleReferringProvider = (val) => {
    formik.setFieldValue(
      "claim_DefaultDto.defaultRenderingProviderId",
      val?.id
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultRenderingProviderFirstName",
      `${val.providerFirstName} `
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultRenderingProviderLastName",
      `${val.providerLastName ? val.providerLastName : ""}`
    );
    formik.setFieldValue(
      "claim_DefaultDto.defaultRenderingProviderSeqNo",
      val?.providerSequenceNo
    );
    setOpenRefferingModal(false);
  };

  // handle default diagnosis
  const handleDefaultDiagnosis = (paramValue) => {
    let formikVal = [...formik.values.defaultICD_DiagnosisDto];
    let findCode = formikVal.find(
      (v) => v?.diagnosisCodesId === paramValue?.id
    );
    if (!findCode) {
      let value = {
        diagnosisCodesId: paramValue.id,
        diagnosisCode: paramValue.diagnosisCode,
        diagnosisDescription: paramValue.diagnosisDescription,
        defaultDxPointerLabel: identifier,
      };
      let finddxPointerLabel = formikVal.findIndex(
        (v) => v?.defaultDxPointerLabel === identifier
      );
      if (finddxPointerLabel === -1) {
        formikVal.push(value);
      } else {
        formikVal.splice(finddxPointerLabel, 1);
        formikVal.push(value);
      }
      formik.setFieldValue("defaultICD_DiagnosisDto", formikVal);
      setOpenDiagnosisModal(false);
    } else {
      setOpenDiagnosisModal(false);
      setOpenCancelDialog(true);
    }
  };

  // handle default procedure
  const handleDefaultProcedure = (paramVal) => {
    let formikVal = [...formik.values.defaultCPT_ProcedureDto];
    let findCode = formikVal.find(
      (val) => val?.procedureCodesId === paramVal?.id
    );
    if (!findCode) {
      let value = {
        procedureCodesId: paramVal.id,
        procedureCode: paramVal.procedureCode,
        procedureDescription: paramVal.procedureDescription,
        procedurePrice: paramVal.price,
        defaultCPTPointerLabel: cptIdentifier,
      };
      let findcptPointerLabel = formikVal.findIndex(
        (v) => v?.defaultCPTPointerLabel === cptIdentifier
      );
      if (findcptPointerLabel === -1) {
        formikVal.push(value);
      } else {
        formikVal.splice(findcptPointerLabel, 1);
        formikVal.push(value);
      }
      formik.setFieldValue("defaultCPT_ProcedureDto", formikVal);
    } else {
      // window.alert("CPT Code already selected");
    }
    setOpenProcedureModal(false);
  };
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        sx={{ padding: "10px" }}
      >
        <Stack gap="20px">
          <FormControl>
            <CustomSearchField
              label="Default Provider"
              fieldVal={
                formik.values.claim_DefaultDto.defaultProviderFirstName &&
                `${formik.values.claim_DefaultDto.defaultProviderFirstName} ${
                  formik.values.claim_DefaultDto.defaultProviderLastName
                    ? formik.values.claim_DefaultDto.defaultProviderLastName
                    : ""
                } (${formik.values.claim_DefaultDto.defaultProviderSeqNo})`
              }
              name="defaultProviderName"
              handleBlur={formik.handleBlur}
              resetField={{
                defaultProviderId: null,
                defaultProviderFirstName: "",
                defaultProviderLastName: ``,
                defaultProviderSeqNo: null,
                practiceAddress: "",
                npiNo: null,
                practiceName: ``,
                practiceId: 0,
                practiceSequenceNo: null,
              }}
              formik={formik}
              handleChange={formik.handleChange}
              handleModalOpen={() => setOpenDefProvider(true)}
            />
          </FormControl>

          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
            gap="10px"
            sx={{
              borderRadius: "7px",
              padding: "6px",
            }}
          >
            <Typography
              component="h3"
              sx={{ color: theme.palette.textMainHeading.primary }}
            >
              Default provider details
            </Typography>
            <Typography component="h4">
              <strong>NPI: </strong>
              <span>{formik.values.claim_DefaultDto.npiNo}</span>
            </Typography>
            <Typography component="h5">
              <strong>Practice: </strong>
              <span>
                {`${
                  formik.values.claim_DefaultDto.practiceName
                    ? `${formik.values.claim_DefaultDto.practiceName} (${formik.values.claim_DefaultDto.practiceSequenceNo})`
                    : ""
                }`}
              </span>
            </Typography>
          </Stack>

          <FormControl>
            <CustomField
              label="Office Location"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.claim_DefaultDto.practiceAddress}
              name="claim_DefaultDto.practiceAddress"
            />
          </FormControl>

          <FormControl>
            <CustomSearchField
              label="Default Ordering Provider"
              fieldVal={formik.values.defaultOrderingProviderId}
              name="defaultOrderingProviderId"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              handleModalOpen={() => setOpenOrdProvider(true)}
              isRequired={true}
              formik={formik}
              resetField={{
                defaultOrderingProviderId: null,
              }}
            />
          </FormControl>

          <FormControl>
            <CustomSearchField
              label="Default Facility"
              fieldVal={`${
                formik.values.claim_DefaultDto.defaultFacilityName
                  ? `${formik.values.claim_DefaultDto.defaultFacilityName} (${formik.values.claim_DefaultDto.defaultFacilitySeqNo})`
                  : ""
              }`}
              name="defaultFacilityName"
              resetField={{
                defaultFacilityId: null,
                defaultFacilityName: "",
                defaultFacilitySeqNo: "",
              }}
              formik={formik}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              handleModalOpen={() => setOpenFacilityModal(true)}
            />
          </FormControl>

          <FormControl>
            <CustomSearchField
              label="Default Referring Provider"
              fieldVal={
                formik.values.claim_DefaultDto
                  .defaultRenderingProviderFirstName &&
                `${`${
                  formik.values.claim_DefaultDto
                    .defaultRenderingProviderFirstName
                } ${
                  formik.values.claim_DefaultDto
                    .defaultRenderingProviderLastName
                    ? formik.values.claim_DefaultDto
                        .defaultRenderingProviderLastName
                    : ""
                } (${
                  formik.values.claim_DefaultDto.defaultRenderingProviderSeqNo
                })`}`
              }
              resetField={{
                defaultRenderingProviderId: null,
                defaultRenderingProviderFirstName: "",
                defaultRenderingProviderLastName: "",
                defaultRenderingProviderSeqNo: null,
              }}
              formik={formik}
              name="defaultRenderingProviderId"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              handleModalOpen={() => setOpenRefferingModal(true)}
            />
          </FormControl>
        </Stack>

        <CustomAccordion heading={"Assignment"}>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" gap="20px">
              <FormControl>
                <CustomSelectBox2
                  showDefaultOpt={true}
                  label="Assignment of benefits"
                  value={formik.values.claim_DefaultDto.assignmentOfBenefitId}
                  name="claim_DefaultDto.assignmentOfBenefitId"
                  formik={formik}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={assignmentBenefits?.map((opt) => ({
                    value: opt.claimDefaultBenefitType,
                    id: opt.claimDefaultBenefitId,
                  }))}
                />
              </FormControl>
              <FormControl>
                <CustomSelectBox2
                  showDefaultOpt={true}
                  label="Provider Accept Assignment"
                  value={formik.values.claim_DefaultDto.providerAcceptanceId}
                  name="claim_DefaultDto.providerAcceptanceId"
                  formik={formik}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={providerAssignment?.map((opt) => ({
                    value: opt.claimDefaultAcceptanceType,
                    id: opt.claimDefaultAcceptanceId,
                  }))}
                />
              </FormControl>
            </Box>
          </AccordionDetails>
        </CustomAccordion>
        {/* default codes */}
        <CustomAccordion heading={"Default Codes"}>
          <AccordionDetails>
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map(
                (code, ind) => {
                  return (
                    <>
                      <CustomSearchField
                        type="text"
                        label={`ICD ${code}`}
                        handleModalOpen={() => {
                          setIdentifier(code);
                          setOpenDiagnosisModal(true);
                        }}
                        fieldVal={
                          formik.values?.defaultICD_DiagnosisDto.find(
                            (val) => val?.defaultDxPointerLabel === code
                          )?.diagnosisCode || ""
                        }
                        formik={formik}
                        resetField={() => {
                          let defaultICD_DiagnosisDto =
                            formik.values?.defaultICD_DiagnosisDto || [];
                          let findIndex = defaultICD_DiagnosisDto.findIndex(
                            (val) => val.defaultDxPointerLabel === code
                          );
                          if (findIndex !== -1) {
                            defaultICD_DiagnosisDto.splice(findIndex, 1);
                            return {
                              defaultICD_DiagnosisDto: defaultICD_DiagnosisDto,
                            };
                          }
                          return {};
                        }}
                        name={`icd_${code}`}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                    </>
                  );
                }
              )}
            </Box>
            {/* cpts */}
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
              marginTop="70px"
            >
              {[1, 2, 3, 4, 5, 6].map((cpt, ind) => {
                return (
                  <>
                    {console.log(
                      "defaultICD_DiagnosisDto",
                      formik.values.defaultCPT_ProcedureDto
                    )}
                    <CustomSearchField
                      type="text"
                      handleModalOpen={() => {
                        setCptIdentifier(`CPT_${cpt}`);
                        setOpenProcedureModal(true);
                      }}
                      label={`CPT #${cpt}`}
                      fieldVal={
                        formik.values?.defaultCPT_ProcedureDto.find(
                          (val) => val?.defaultCPTPointerLabel === `CPT_${cpt}`
                        )?.procedureCode || ""
                      }
                      formik={formik}
                      resetField={() => {
                        let defaultCPT_ProcedureDto =
                          formik.values?.defaultCPT_ProcedureDto || [];
                        let findIndex = defaultCPT_ProcedureDto.findIndex(
                          (val) => val.defaultCPTPointerLabel === `CPT_${cpt}`
                        );
                        if (findIndex !== -1) {
                          defaultCPT_ProcedureDto.splice(findIndex, 1);
                          return {
                            defaultCPT_ProcedureDto: defaultCPT_ProcedureDto,
                          };
                        }
                        return {};
                      }}
                      name={`cpT_${cpt}`}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </>
                );
              })}
            </Box>
          </AccordionDetails>
        </CustomAccordion>
        {/* illness & accident information */}
        <CustomAccordion heading={"Illness & Accident Information"}>
          <AccordionDetails>
            <Stack gap="20px">
              <FormGroup>
                <FormControlLabel
                  control={
                    <CheckBox
                      CheckedValue={
                        formik.values.claim_DefaultDto.isIncludeIllnessInfo
                      }
                      handleChange={() =>
                        formik.setFieldValue(
                          "claim_DefaultDto.isIncludeIllnessInfo",
                          !formik.values.claim_DefaultDto.isIncludeIllnessInfo
                        )
                      }
                    />
                  }
                  label="Include accident and illness information on claims "
                />
              </FormGroup>

              <Stack gap="20px" flexDirection={{ xs: "column", sm: "row" }}>
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Illnes/Accident Date"
                  dateValue={formik.values.claim_DefaultDto.accidentDate}
                  name="claim_DefaultDto.accidentDate"
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
                <FormControl sx={{ marginLeft: "20px" }}>
                  <CustomField
                    label="Accident State"
                    value={formik.values.claim_DefaultDto.accidentState}
                    name="claim_DefaultDto.accidentState"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>

              <FormControl>
                <CustomSelectBox2
                  showDefaultOpt={true}
                  label="Accident Type"
                  formik={formik}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={accidentTypes?.map((opt) => ({
                    value: opt.accidentType,
                    id: opt.accidentTypeId,
                  }))}
                  name="claim_DefaultDto.accidentTypeId"
                  value={formik.values.claim_DefaultDto.accidentTypeId}
                />
              </FormControl>
            </Stack>
          </AccordionDetails>
        </CustomAccordion>
        {/* other */}
        <CustomAccordion heading={"Other"}>
          <AccordionDetails>
            <Stack gap="20px">
              <CustomField
                type="text"
                label="Box 11b"
                value={formik.values.claim_DefaultDto.otherClaimId}
                name="claim_DefaultDto.otherClaimId"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              <CustomField
                type="text"
                label="Box 19"
                value={formik.values.claim_DefaultDto.additionalClaimInfo}
                name="claim_DefaultDto.additionalClaimInfo"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <Box
                display="grid"
                gap="20px"
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(2, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                  },
                }}
              >
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Admission Date"
                  dateValue={formik.values.claim_DefaultDto.admissionDate}
                  name="claim_DefaultDto.admissionDate"
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />

                <CustomSelectBox2
                  dropdownOptions={admissionHour?.map((opt) => ({
                    value: opt.admissionHour,
                    id: opt.admissionHourId,
                  }))}
                  label="Admission Hour"
                  name="claim_DefaultDto.admissionHourId"
                  value={formik.values.claim_DefaultDto.admissionHourId}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  handleChange={formik.handleChange}
                />

                <CustomDatePicker
                  formik={formik}
                  dateLabel="Last Menstrual Date"
                  dateValue={formik.values.claim_DefaultDto.lastMenstrualDate}
                  name="claim_DefaultDto.lastMenstrualDate"
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />

                <CustomDatePicker
                  formik={formik}
                  dateLabel="Initial Treatment Date"
                  dateValue={
                    formik.values.claim_DefaultDto.initialTreatmentDate
                  }
                  name="claim_DefaultDto.initialTreatmentDate"
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </Box>
            </Stack>
          </AccordionDetails>
        </CustomAccordion>
        <CustomDialog
          open={openCancelDialog}
          isWarning={true}
          handleClose={() => setOpenCancelDialog(false)}
          // handleConfirm={handleConfirmCancel}
          handleCancel={() => setOpenCancelDialog(false)}
          confirmButtonTitle="OK"
          cancelButtonTitle="Cancel"
          isCancel={true}
        />
      </Box>

      {/* default provider modal */}
      <CustomModal
        open={openDefProvider}
        handleClose={() => setOpenDefProvider(false)}
        heading={"All Providers"}
        showAddButton={true}
      >
        <CustomPreview
          rows={
            getProviders && getProviders.result?.providers.length > 0
              ? getProviders.result?.providers?.map((el) => ({
                  id: el.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          loading={loading}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          searchLabel="Search by Provider Name and Sequence Number"
          handleCell={handleDefaultProvider}
        />
      </CustomModal>

      {/* ordering provider */}
      <CustomModal
        open={openOrdProvider}
        handleClose={() => setOpenOrdProvider(false)}
      >
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <h1>No ordering provider were found</h1>
        </div>
      </CustomModal>

      {/* facility modal */}
      <CustomModal
        open={openFacilityModal}
        handleClose={() => setOpenFacilityModal(false)}
        heading={"All Facilities"}
      >
        <CustomPreview
          rows={
            getFacilityData?.result &&
            getFacilityData.result?.facility?.length > 0
              ? getFacilityData.result?.facility?.map((el) => ({
                  id: el.facilityId,
                  facilityName: el.facilityName,
                  phoneNumber: el.phoneNumber,
                  sequenceNo: el.sequenceNo,
                  taxonomySpecialty: el.taxonomySpecialty,
                }))
              : []
          }
          columns={facilityCols}
          loading={loading}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          isModal={true}
          searchLabel="Search by First Name, Phone ,or Sequence Number"
          handleCell={handleDefaultFacility}
        />
        {/* comment facility and work on custom preview */}
      </CustomModal>
      {/* diagnosis modal */}
      <CustomModal
        open={openDiagnosisModal}
        handleClose={() => setOpenDiagnosisModal(false)}
        heading={"Diagnosis Codes"}
      >
        {/* <Diagnosis handleCellClick={handleDefaultDiagnosis}/> */}
        <CustomPreview
          rows={
            getDiagnosisData && getDiagnosisData?.result
              ? getDiagnosisData?.result?.diagnosisCode?.map((v, i) => ({
                  id: v?.diagnosisCodeId,
                  diagnosisCode: v?.diagnosisCode,
                  diagnosisDescription: v?.diagnosisDescription,
                  isActive: v?.isActive,
                  effectiveDate: v?.effectiveDate,
                  terminationDate: v?.terminationDate,
                }))
              : []
          }
          columns={diagnosisCodesCols}
          loading={loading}
          handleGetAll={getDiagnosisAction}
          handleBySearch={getDiagnosisBySearchTermAction}
          isModal={true}
          handleCell={handleDefaultDiagnosis}
          searchLabel={"Search by Description and Diagnosis Code"}
          paginationDetail={getDiagnosisData}
        />
      </CustomModal>
      {/* reffering modal */}
      <CustomModal
        open={openRefferingModal}
        handleClose={() => setOpenRefferingModal(false)}
        heading={"Referring Providers"}
      >
        {/* <ReferringProvider
          isUpdate={false}
          handleCellClick={handleReferringProvider}
        /> */}
        <CustomPreview
          rows={
            getProviders && getProviders.result?.providers.length > 0
              ? getProviders.result?.providers?.map((el) => ({
                  id: el.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          paginationDetail={getProviders}
          loading={loading}
          handleCell={handleReferringProvider}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          searchLabel="Search by Provider Name and Sequence Number"
        />
      </CustomModal>

      {/* procedure modal */}
      <CustomModal
        open={openProcedureModal}
        handleClose={() => setOpenProcedureModal(false)}
        heading={"Procedures"}
      >
        <CustomPreview
          rows={
            getProcedureCodes?.result?.procedureCodes?.length > 0
              ? getProcedureCodes?.result?.procedureCodes?.map((el) => ({
                  id: el.procedureCodeId,
                  units: el.units === null ? 1 : el.units,
                  ...el,
                }))
              : []
          }
          columns={procedureCodesCols}
          paginationDetail={getProcedureCodes}
          handleCell={handleDefaultProcedure}
          loading={loading}
          handleGetAll={getProcedureCodeAction}
          handleBySearch={getProcedureCodesBySearchTermAction}
          isModal={true}
          searchLabel="Search by Provider Name and Sequence Number"
        />
      </CustomModal>
      {/* loading modal */}
      <CustomModal
        open={isLoading}
        handleClose={() => setOpenProcedureModal(false)}
      >
        <Loading />
      </CustomModal>
    </>
  );
};

export default ClaimDefaults;
