import React from "react";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { insuranceAddresses } from "../../../utils/formikInitValues";
import { useState, useEffect } from "react";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import CustomAddress from "../../../components/CustomAddress";
import {payerAddressSchema} from "../../../schemas/patientSchema"
const InsuranceAddress = ({ formik, handleClose, editData, editIndex }) => {
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [countryOpt, setCountryOpt] = useState([]);

  const DateSource = {
    countries: "/country",
    stateType: "/state",
    cityType: "/city",
  };
  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData(DateSource.cityType, setCityOpt);
    fetchData(DateSource.stateType, setStateOpt);
    fetchData(DateSource.countries, setCountryOpt);
  });

  const addressFormik = useFormik({
    initialValues: editData || insuranceAddresses,
    validationSchema:payerAddressSchema,
    onSubmit: (values) => {
      if (editData) {
        let updatedInsureanceAddress = [...formik.values.insuranceAddressDtos];
        console.log(updatedInsureanceAddress, "updatedInsurance");
        updatedInsureanceAddress[editIndex] = values;
        formik.setValues((preVals) => ({
          ...preVals,
          insuranceAddressDtos: updatedInsureanceAddress,
        }));

        // updatedInsuranceAddresses = [
        //   ...formik.values.insuranceAddressDtos.slice(0, editIndex),
        //   values,
        //   ...formik.values.insuranceAddressDtos.slice(editIndex + 1)
        // ];
      } else {
        // updatedInsuranceAddresses = [...formik.values.insuranceAddressDtos, values];
        formik.setValues((preVals) => ({
          ...preVals,
          insuranceAddressDtos: [...formik.values.insuranceAddressDtos, values],
        }));
      }
      // formik.setFieldValue('insuranceAddressDtos', updatedInsuranceAddresses);
      handleClose();
    },
  });

  return (
    <form onSubmit={addressFormik.handleSubmit}>
      <Grid container spacing={2} padding={"15px"}>
        <Grid item xs={12} md={8}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Address"
            isRequired={true}
            error={addressFormik.errors}
            touched={addressFormik.touched}
            name="address"
            type="text"
            value={addressFormik.values.address}
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Department"
            name="department"
            type="text"
            value={addressFormik.values.department}
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            type="text"
            label="Phone"
            handleBlur={formik.handleBlur}
            error={formik.errors}
            touched={formik.touched}
            handleChange={formik.handleChange}
            value={formik.values.phoneNumber}
            name="phoneNumber"
            mask="(999) 999-9999"
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Fax"
            name="fax"
            type="text"
            value={addressFormik.values.fax}
            mask="(999) 999-9999"
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Email"
            name="email"
            type="text"
            value={addressFormik.values.email}
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Website"
            name="website"
            type="text"
            value={addressFormik.values.website}
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Insurance Description Id"
            name="insuranceDescriptionId"
            type="text"
            value={addressFormik.values.insuranceDescriptionId}
          />
        </Grid>
        <Grid item md={4}>
          <CustomField
            handleBlur={addressFormik.handleBlur}
            handleChange={addressFormik.handleChange}
            label="Insurance Sequence Number"
            name="insuranceSequenceNo"
            type="text"
            value={addressFormik.values.insuranceSequenceNo}
          />
        </Grid>

        <CustomAddress
          formik={addressFormik}
          fieldValues={{
            countryId: addressFormik.values.countryId,
            country: addressFormik.values.countryName,
            countryName: "countryName",
            stateId: "stateId",
            stateName: "stateName",
            state: addressFormik.values.stateName,
            cityId: "cityId",
            cityName: "cityName",
            city: addressFormik.values.cityName,
            zipCode: addressFormik.values.zipCode,
            zipCodeId: "zipCodeId",
            zipName: "zipCode",
          }}
          isRequired={true}
        />
        <Grid item md={12}>
          <CustomButton
            isAdd={!editData}
            isBlue={true}
            type="submit"
            margin={"10px 0 0"}
          >
            {editData ? "Update" : "Add"}
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default InsuranceAddress;
