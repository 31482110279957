import { Box,Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import RevenueInfo from "./RevenueInfo";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueAction, getRevenueyByIdAction } from "../../../features/actions/revenueAction";
import { useFormik } from "formik";
import { revenueInitVals } from "../../../utils/formikInitValues";
import { updateRevenueAction } from "./../../../features/actions/revenueAction";
import { revenueSchema } from "../../../schemas/patientSchema";


const UpdateRevenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {revenueById, loading } = useSelector((state) => state.revenue);
  useEffect(() => {
    dispatch(getRevenueyByIdAction(id));
  }, [dispatch, id]);

  const findRevenue = revenueById?.result;
  console.log("findRevenue", findRevenue);

  const formik = useFormik({
    initialValues: findRevenue || revenueInitVals,
    validationSchema: revenueSchema,
    enableReinitialize: true,
    onSubmit: (values,action) => {
      console.log("Submitting form with values:", values); 
      dispatch(
        updateRevenueAction({
          revenueCodeId: findRevenue?.revenueCodeId,
          ...values,
        })
      )
      navigate("/revenues");
    },
  });
  return (
    <>
          <RevenueInfo formik={formik} />
    </>
  );
};

export default UpdateRevenue;
